import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthNotification, AuthStateEvent } from '../../../utils/auth-types';

@Component({
  selector: 'app-mfa-required-form',
  templateUrl: './mfa-required-form.component.html',
  styleUrls: ['../../login.component.scss']
})
export class MfaRequiredFormComponent implements AfterViewInit {
  @Input() smsMfaRequiredForm: UntypedFormGroup;
  @Input() submitInProgress: boolean;
  @Input() sendSmsInProgress: boolean;

  @Output() smsMfaSubmitEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() setViewEvent: EventEmitter<AuthStateEvent> = new EventEmitter<AuthStateEvent>();
  @Output() resendMfaCodeEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() notifyEvent: EventEmitter<AuthNotification> = new EventEmitter<null>();

  @ViewChild('codeInput')
  public codeInput: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.focusForm();
  }

  focusForm() {
    setTimeout(() => {
      this.codeInput.nativeElement.focus();
    });
  }

  smsMfaSubmit() {
    this.smsMfaSubmitEvent.emit();
  }

  setView() {
    this.setViewEvent.emit(AuthStateEvent.StartSignIn);
  }

  resendCode() {
    this.resendMfaCodeEvent.emit();
    this.notifyEvent.emit(AuthNotification.NewMfaCodeSent);
  }
}
