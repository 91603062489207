import { BehaviorSubject } from 'rxjs';
import { DestroyRef, EventEmitter, inject, Injectable } from '@angular/core';
import { TextboxQuestionParams, TextboxQuestionViewModel } from './textbox.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUpdate } from '../question.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TRANSLATIONS_PROVIDER } from '../../translations.token';
import { I18nKeys } from '@jump-tech-frontend/app-config';

@Injectable()
export class TextboxPresenter {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  public readonly translationsProvider = inject(TRANSLATIONS_PROVIDER);

  load(
    vm: BehaviorSubject<TextboxQuestionViewModel | null>,
    params: TextboxQuestionParams,
    formChange: EventEmitter<FormUpdate>
  ) {
    const form = this.getForm(params);
    const translations = this.getTranslations();
    this.subscribeToFormChanges(form, formChange, params);
    vm.next({
      ...params,
      form,
      translations
    });
  }

  private getTranslations(): Partial<I18nKeys> {
    return {
      required: this.translationsProvider.getTranslation('required')
    };
  }

  private getForm(params: TextboxQuestionParams): FormGroup {
    const { key, value, required } = params;
    return new FormGroup({
      [key]: new FormControl(value, required ? [Validators.required] : [])
    });
  }

  private subscribeToFormChanges(form: FormGroup, formChange: EventEmitter<FormUpdate>, params: TextboxQuestionParams) {
    form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      formChange?.next({ key: params.key, value: value[params.key] });
    });
  }
}
