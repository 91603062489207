import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-checkbox-field-wrapper',
  template: `
    <div class="field-wrap">
      <label class="wrapper-label">&nbsp;</label>
      <div class="flex-fill">
        <ng-template #fieldComponent></ng-template>
        <div *ngIf="field.templateOptions.hint" class="mt-1 alert alert-info" role="alert">
          {{ field.templateOptions.hint }}
        </div>
      </div>
    </div>
    <div *ngIf="showError" class="mb-1 invalid-feedback d-block">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  `,
  styles: [
    `
      .field-wrap {
        display: flex;
        align-items: center;
        column-gap: var(--jds-space-lg);
      }

      .wrapper-label {
        min-width: 80px;
      }

      ::ng-deep .form-check {
        margin: var(--jds-space-stack-sm);
        padding-left: var(--jds-space-xxl);
      }

      ::ng-deep .form-check-label {
        margin-left: var(--jds-space-sm);
      }

      ::ng-deep .form-check-input, ::ng-deep .form-check-label  {
        cursor: pointer;
      }

      ::ng-deep .field-wrap {
        margin: var(--jds-space-stack-sm);
      }
    `
  ]
})
export class CheckboxFieldWrapperComponent extends FieldWrapper {}
