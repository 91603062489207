<div class="modal-wrapper">
  <ngx-spinner
    bdColor="var(--jds-theme-spinner-bd-rgba)"
    size="large"
    type="line-scale"
    [attr.data-qa]="'statusTransitionSpinner'"
    name="statusTransitionModal"
  >
  </ngx-spinner>
  <div class="modal-header-wrapper">
    <div class="modal-header">
      <div>{{ action.label }}</div>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-banner">{{ action.confirmation.layout.label }}</div>
  </div>
  <div class="modal-body p-0 pb-2">
    <div [ngSwitch]="action.confirmation.layout.dataSource">
      <app-modal-layout
        *ngSwitchCase="'data'"
        [data]="projectAndJobData"
        [layout]="action.confirmation.layout"
        [mode]="action.confirmation.layout['mode'] || 'readonly'"
        (formUpdate)="setFormData($event)"
      ></app-modal-layout>
      <app-modal-layout
        *ngSwitchCase="'all'"
        [data]="projectAndResources"
        [layout]="action.confirmation.layout"
        [mode]="action.confirmation.layout['mode'] || 'readonly'"
        (formUpdate)="setFormData($event)"
      ></app-modal-layout>
      <div *ngSwitchDefault>
        <app-modal-layout
          *ngIf="resources[action.confirmation.layout['dataSource']]"
          [data]="mergedResources"
          [layout]="action.confirmation.layout"
          [mode]="action.confirmation.layout['mode'] || 'readonly'"
          (formUpdate)="setFormData($event)"
        >
        </app-modal-layout>
      </div>
    </div>
  </div>
  <jui-alert-block *ngIf="action.confirmation.alert" color="warning" [attr.data-qa]="'warning'">
    <span [innerHtml]="action.confirmation.alert"></span>
  </jui-alert-block>
  <div class="modal-footer" [attr.data-qa]="action.id" *ngIf="form">
    <jui-button [disabled]="form.invalid" size="sm" [attr.data-qa]="'submitButton'" (click)="submit()">
      {{ action.confirmation.submitLabel || ('common.submit' | transloco) }}
    </jui-button>
  </div>
</div>
