import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RelayError } from '../../domain/error';
import { SpinnerService } from '../../spinner.service';

@Component({
  selector: 'error-modal-component',
  template: ` <div [attr.data-qa]="'error'" class="modal-wrapper" *ngIf="error">
    <div class="modal-header">
      <div>{{ error.title }}</div>
    </div>
    <div class="modal-body">
      <div [innerHTML]="error.message"></div>
    </div>
    <div class="modal-footer text-center">
      <jui-button
        *ngIf="!error.action"
        size="md"
        title="{{ error.okLabel }}"
        color="primary"
        (click)="close($event)"
        [attr.data-qa]="'errorOkButton'"
      >
        {{ error.okLabel }}
      </jui-button>
      <jui-button
        *ngIf="error.action"
        size="md"
        title="{{ error.cancelLabel }}"
        color="secondary"
        (click)="close($event)"
        [attr.data-qa]="'errorCancelButton'"
      >
        {{ error.cancelLabel }}
      </jui-button>
      <jui-button
        *ngIf="error.action"
        size="md"
        title="{{ error.actionLabel }}"
        color="primary"
        (click)="action($event)"
        [attr.data-qa]="'errorActionButton'"
      >
        {{ error.actionLabel }}
      </jui-button>
    </div>
  </div>`,
  styles: [
    `
      .modal-body {
        background-color: var(--jds-theme-color-primary-contrast-color, white);
        color: var(--jds-theme-color-primary, var(--jds-theme-color-state-danger));
      }

      .modal-header {
        background-color: var(--jds-theme-color-primary, var(--jds-theme-color-state-danger));
        height: 3.25rem;
        color: var(--jds-theme-color-primary-contrast-color, white);
      }
    `
  ]
})
export class ErrorModalComponent {
  @Input() error!: RelayError;

  constructor(public activeModal: NgbActiveModal, private spinnerService: SpinnerService) {
    this.spinnerService.hideSpinner();
  }

  action($event: Event) {
    $event.preventDefault();
    this.activeModal.close(true);
  }

  close($event: Event) {
    $event.preventDefault();
    this.activeModal.close(false);
  }
}
