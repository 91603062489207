import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';

@Component({
  selector: `crds-question-html`,
  template: `
    <div [innerHTML]="rawHtml"></div>
    <!-- QUESTION HINT -->
    <crds-question-hint [question]="question"></crds-question-hint>
  `
})
export class HtmlQuestionComponent implements OnChanges {
  @Input() question: QuestionBase<string>;
  rawHtml;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.rawHtml = this.question?.html;
  }
}
