<ng-container *ngIf="vm">
  <div class="doc-manager" [class.doc-manager--working]="vm.inProgress" [attr.data-qa]="vm.qaComponent">
    <div class="doc-manager__no-document" *ngIf="vm.showRetryButton">
      <jui-button *ngIf="vm.showRetryButton" size="sm" (click)="retry()" [attr.data-qa]="vm.qaRetry">
        {{ vm.retryButtonLabel }}
      </jui-button>
    </div>

    <!-- grouped documents display -->
    <div *ngFor="let stageVm of vm.stages">
      <app-document-pack-document-stage
        [isMoving]="isMoving"
        [vm]="stageVm"
        [projectId]="vm.projectId"
        [acceptedFileTypes]="vm.acceptedFileTypes"
        [actionButtonDisabled]="vm.actionButtonDisabled"
        [inProgress]="vm.inProgress"
        (actionClick)="handleActionClick($event.fileInput, $event.docVm)"
        (requiredToggle)="handleRequired($event)"
        (fileChange)="onFileChange($event.event, $event.docVm, vm.projectId)"
        (toggleStage)="toggleStage(stageVm)"
      ></app-document-pack-document-stage>
    </div>

    <!-- default documents display -->
    <div *ngFor="let docVm of vm.documents">
      <app-document-pack-document-row
        [doc]="docVm"
        [projectId]="vm.projectId"
        [acceptedFileTypes]="vm.acceptedFileTypes"
        [actionButtonDisabled]="vm.actionButtonDisabled"
        [inProgress]="vm.inProgress"
        (actionClick)="handleActionClick($event, docVm)"
        (requiredToggle)="handleRequired(docVm)"
        (fileChange)="onFileChange($event, docVm, vm.projectId)"
      ></app-document-pack-document-row>
    </div>

    <div class="doc-manager__no-document" *ngIf="vm.showAddDocumentPack">
      <app-document-pack-main-action
        [inProgress]="vm.addDocumentPackInProgress"
        [isDisabled]="vm.actionButtonDisabled"
        [label]="vm.addDocumentPackLabel"
        [qaHook]="vm.qaAddDocPack"
        (actionClick)="addDocumentPack()"
      ></app-document-pack-main-action>
    </div>

    <div class="doc-manager__no-document" *ngIf="vm.showRemoveDocumentPack">
      <app-document-pack-main-action
        [inProgress]="vm.removeDocumentPackInProgress"
        [isDisabled]="vm.actionButtonDisabled"
        [label]="vm.removeDocumentPackLabel"
        [qaHook]="vm.qaRemoveDocPack"
        (actionClick)="removeDocumentPack()"
      ></app-document-pack-main-action>
    </div>
  </div>

  <app-document-pack-progress></app-document-pack-progress>

  <div *ngIf="vm.showMainSpinner" class="doc-manager__no-document" [attr.data-qa]="vm.qaMainSpinner">
    <app-document-pack-loader></app-document-pack-loader>
  </div>
</ng-container>
