<ng-container *ngIf="vm">
  <div class="doc-manager-missing-info">
    <div class="doc-manager-missing-info__header">
      <button
        type="button"
        class="close doc-manager-missing-info__close"
        aria-describedby="modal-title"
        (click)="closeModal('Dismiss')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="doc-manager-missing-info__title">
        {{ vm.title }} <span class="doc-manager-missing-info__state">{{ vm.state }}</span>
      </div>
    </div>
    <div
      [class.doc-manager-missing-info__banner--uploading]="vm.isUploadingFile"
      class="doc-manager-missing-info__banner"
    >
      {{ vm.message }}
    </div>

    <div class="doc-manager-missing-info__content">
      <div class="doc-manager-missing-info__section" *ngFor="let item of vm.data">
        <div class="doc-manager-missing-info__section-name">
          <strong>{{ item.section }}</strong>
        </div>
        <div class="doc-manager-missing-info__field" *ngFor="let missingData of item.fields">
          <span class="material-icons doc-manager-missing-info__icon">report_problem</span
          ><span class="doc-manager-missing-info__field-msg">{{ missingData }}</span>
        </div>
      </div>
    </div>
    <div class="doc-manager-missing-info__actions">
      <div class="doc-manager-missing-info__upload">
        <span class="doc-manager-missing-info__hint">{{ vm.uploadBtnHint }}</span>
        <jui-button
          size="xs"
          color="secondary"
          class="doc-manager-missing-info__upload-btn"
          (click)="actionClick()"
          [attr.data-qa]="vm.qaUpload"
        >
          {{ vm.uploadBtnLabel }}
        </jui-button>
      </div>

      <jui-button size="sm" (click)="closeModal('Close')" [attr.data-qa]="vm.qaClose">
        {{ vm.closeBtnLabel }}
      </jui-button>
    </div>
    <app-document-pack-progress></app-document-pack-progress>
  </div>
</ng-container>
