<form [formGroup]="forgotPasswordForm">
  <div class="login-card__row">
    <div class="login-card__heading">
      <span>{{ 'auth.title.resetPassword' | transloco }}</span>
    </div>
  </div>
  <p class="login-card__txt">
    <span>{{ 'auth.resetPassword' | transloco }}</span>
  </p>
  <label class="login-card__lbl" for="current-username">{{ 'auth.formFields.email.label' | transloco }}</label>
  <div class="login-card__row">
    <input
      #usernameInput
      class="form-control login-card__ctrl"
      type="email"
      placeholder="{{ 'auth.formFields.email.placeholder' | transloco }}"
      formControlName="username"
      id="current-username"
      data-qa="currentUserNameInput"
      (keyup.enter)="forgotPassword()"
    />
  </div>
  <div class="login-card__btn-row" [class.login-card__btn--disabled]="forgotPasswordForm.invalid">
    <jui-button
      size="xl"
      expand
      data-qa="forgotPasswordButton"
      [disabled]="forgotPasswordForm.invalid || submitInProgress"
      (click)="forgotPassword()"
      [loading]="submitInProgress"
    >
      <span *ngIf="!submitInProgress">{{ 'Buttons.forgotPasswordResend.text' | transloco }}</span>
      <span *ngIf="submitInProgress">{{ 'Buttons.forgotPasswordResending.text' | transloco }}</span>
    </jui-button>
  </div>
  <jui-button size="sm" color="subtle" expand (click)="setView()">
    {{ 'Buttons.backToSignIn.text' | transloco }}
  </jui-button>
</form>
