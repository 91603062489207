import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProjectAuditLogsVm } from './project-audit-logs.vm';
import { ProjectAuditLogsRepository } from './project-audit-logs.repository';
import { ProjectUpdateService } from '../../core/project-update.service';
import { AuditLog, AuditLogType } from '@jump-tech-frontend/domain';

@Injectable()
export class ProjectAuditLogsPresenter {
  constructor(
    private projectAuditLogsRepository: ProjectAuditLogsRepository,
    private projectUpdateService: ProjectUpdateService
  ) {}

  public vm$: BehaviorSubject<ProjectAuditLogsVm>;
  private auditLogUpdateSubscription: Subscription;
  private logExclusions = [AuditLogType.RELAY_PROGRESS];
  private filterLogs = true;

  async load(projectId: string, vm: BehaviorSubject<ProjectAuditLogsVm>, filterLogs = true): Promise<void> {
    this.vm$ = vm;
    this.filterLogs = filterLogs;
    this.auditLogUpdateSubscription = this.projectUpdateService.auditLogUpdates.subscribe(async () => {
      await this.updateAuditLogs(projectId);
    });
    await this.updateAuditLogs(projectId);
  }

  async updateAuditLogs(projectId) {
    const auditLogs = await this.projectAuditLogsRepository.list(projectId);
    this.vm$.next({
      allAuditLogs: auditLogs,
      filteredAuditLogs: this.filterAuditLogs(auditLogs)
    });
  }

  filterAuditLogs(auditLogs: AuditLog[]): AuditLog[] {
    return auditLogs
      .filter(auditLog => !this.logExclusions.includes(<AuditLogType>auditLog.type))
      .map(auditLog => {
        if (!Array.isArray(auditLog.data)) {
          const data = [];
          for (const dataKey of Object.keys(auditLog.data)) {
            if (auditLog.data[dataKey] && typeof auditLog.data[dataKey] === 'string') {
              data.push({ key: dataKey, value: auditLog.data[dataKey] });
            }
          }
          auditLog.data = data;
        }
        return auditLog;
      });
  }
  unload(): void {
    this.vm$.next(null);
    this.auditLogUpdateSubscription.unsubscribe();
  }
}
