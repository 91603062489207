import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContactLogRepository } from '../contact-log.repository';
import { ContactLog, IContactLogActionVm } from '../contact-log.model';

@Injectable()
export class ContactLogActionPresenter {
  constructor(private repository: ContactLogRepository) {}

  async load(vm$: BehaviorSubject<IContactLogActionVm>) {
    const translations = this.repository.getActionTranslations();
    vm$.next({
      addContactLog: translations.addContactLog
    });
  }

  saveContactLog(projectId: string, contactLog: ContactLog) {
    this.repository.saveContactLogAndNotify(projectId, contactLog);
  }
}
