import { Address, AddressMapping } from '../../types';

export class AddressMapperService {
  applyMaps(providerAddresses: any[], addressMappings?: AddressMapping[]): Address[] {
    for (const address of providerAddresses) {
      for (const addressMapping of addressMappings || []) {
        if (address?.[addressMapping.from]) {
          address[addressMapping.to] = address[addressMapping.from];
          delete address[addressMapping.from];
        }
      }
    }

    return !providerAddresses?.length
      ? providerAddresses
      : (providerAddresses.sort((a, b) => {
          const first = a?.summaryline || a?.line1 || '';
          const second = b?.summaryline || b?.line1 || '';
          return first.localeCompare(second);
        }) as Address[]);
  }
}
