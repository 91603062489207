import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { SearchResult } from '../search-result';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { DelegationShare } from '../../admin/user-management/domain/types';
import { checkIfExpression } from '../../core/utils/filter';
import { DelegationService } from '../../core/delegate/delegation.service';

@Component({
  selector: 'app-un-delegate-project-button',
  template: `
    <div *ngIf="this.showUndelegationAction()">
      <button
        class="icon-button icon-button--warning"
        type="button"
        [disabled]="submitInProgress"
        (click)="this.undelegateProject()"
        (mouseenter)="hovering = true"
        (mouseleave)="hovering = false"
      >
        <i class="material-icons">reply</i>
      </button>
      <div class="delegate-button-hover" *ngIf="hovering">
        <div>{{ 'Buttons.unassignFromInstaller.text' | transloco }}</div>
      </div>
    </div>
  `,
  styleUrls: ['delegate-project-button.component.scss']
})
export class UndelegateProjectButtonComponent {
  private _project: SearchResult;
  private _userTenant: string;
  private _delegates: DelegationShare[];
  hovering = false;

  @Output()
  undelegationSuccess: EventEmitter<string> = new EventEmitter(true);

  get project(): SearchResult {
    return this._project;
  }

  @Input() set project(project: SearchResult) {
    this._project = project;
  }

  @Input() submitInProgress = false;

  get userTenant(): string {
    return this._userTenant;
  }

  @Input() set userTenant(userTenant: string) {
    this._userTenant = userTenant;
  }

  get delegates(): DelegationShare[] {
    return this._delegates.filter(d => {
      return checkIfExpression(d.showIf, this.project);
    });
  }

  showUndelegationAction(): boolean {
    return (
      this.delegationEnabled &&
      this.delegationService.canUndelegate(this.project, this.userTenant, this.delegates) &&
      this.delegationService.isDelegated(this.project, this.userTenant)
    );
  }

  @Input() set delegates(delegates: DelegationShare[]) {
    this._delegates = delegates;
    this._delegates = this.delegates.filter(o => o.tenant !== 'ALL');
  }

  get delegationEnabled() {
    return this._delegationEnabled;
  }

  set delegationEnabled(value: boolean) {
    this._delegationEnabled = value;
  }

  private _delegationEnabled = false;

  constructor(
    private apiService: ApiService,
    private featureAccessService: AccessService,
    public delegationService: DelegationService
  ) {}

  async ngOnInit() {
    this.delegationEnabled = this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.DelegationV2);
  }

  async undelegateProject() {
    this.submitInProgress = true;
    this.hovering = false;
    const success = await this.delegationService.undelegateProject(this.project.id, this.project.tenant);
    if (success) {
      this.undelegationSuccess.emit();
    }
    this.submitInProgress = true;
  }
}
