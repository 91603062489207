<div class="working a-fade-in">
  <div class="working__message">
    {{ 'auth.refreshingCredentials' | transloco }}
  </div>
  <div class="working__loader">
    <crds-inline-spinner
      size="medium"
      colour="var(--jds-theme-color-brand-primary)"
      type="ball-atom"
      name="submitSpinner"
    ></crds-inline-spinner>
  </div>
</div>
<p class="login-card__msg">
  {{ 'auth.notRedirected' | transloco }}
</p>
<div class="login-card__row">
  <jui-button size="xl" expand (click)="goBackOrHome()">
    {{ 'Buttons.goBack.text' | transloco }}
  </jui-button>
</div>
