import { ISearchProvider } from '../domain/search-provider';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getPostcoderSearchProvider } from './postcoder/postcoder.provider';
import { getLoqateSearchProvider } from './loqate/loqate.provider';
import { AddressProvider } from '../domain/address-provider';
import { getGetAddressIOSearchProvider } from './getAddressIO/get-address-io.provider';

export interface ProviderFactory {
  getProvider(countryCodes: string[], provider?: AddressProvider): ISearchProvider;
}

export class SearchProviderFactory implements ProviderFactory {
  private readonly httpClient: HttpClient = inject(HttpClient);
  getProvider(countryCodes: string[], provider?: AddressProvider): ISearchProvider {
    if (provider) {
      return this.getProviderByProvider(provider, countryCodes);
    }
    return this.getProviderByCountryCode(countryCodes);
  }

  private getProviderByCountryCode(countryCodes: string[]) {
    if (countryCodes.length === 1) {
      const firstCountryCode = countryCodes[0].toLowerCase();
      if (firstCountryCode === 'gb') {
        return getGetAddressIOSearchProvider(this.httpClient);
      }
      if (firstCountryCode === 'ie') {
        return getPostcoderSearchProvider(this.httpClient, 'ie');
      }
    }
    return getLoqateSearchProvider(this.httpClient, countryCodes);
  }

  private getProviderByProvider(provider: AddressProvider, countryCodes: string[]) {
    switch (provider) {
      case 'postcoder':
        return getPostcoderSearchProvider(this.httpClient, 'uk');
      case 'loqate':
        return getLoqateSearchProvider(this.httpClient, countryCodes);
      case 'getaddressio':
        return getGetAddressIOSearchProvider(this.httpClient);
      default:
        throw new Error(`Unknown address provider: ${provider}`);
    }
  }
}
