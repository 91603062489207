import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpAuthInterceptor } from './http-auth.interceptor.service';

export const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpAuthInterceptor,
    multi: true
  }
];
