import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreModule } from './core/core.module';
import { OcrService } from './core/ocr.service';
import { HttpClientModule } from '@angular/common/http';
import { DefaultFormComponent } from './card/default/default-form.component';
import { OcrFormComponent } from './card/ocr/ocr-form.component';
import { ImageCaptureFormComponent } from './card/image-capture/image-capture-form.component';
import { CarouselMultipleFormComponent } from './card/carousel/carousel-multiple-form.component';
import { AddressFormComponent } from './card/address/address-form.component';
import { QuestionsComponent } from './core/questions.component';
import { NguCarouselModule } from '@ngu/carousel';
import { CardComponent } from './core/card.component';
import { InformationFormComponent } from './card/information/information-form.component';
import { InformationGroupsComponent } from './card/information/information-groups.component';
import { ConsentFormComponent } from './card/consent/consent-form.component';
import { CarouselFormComponent } from './card/carousel/carousel-form.component';
import { AddressMyLocationFormComponent } from './card/my-location/address-my-location-form.component';
import { ApiService } from './core/api.service';
import { PostCodeLookupModule } from '@jump-tech-frontend/address-lookup';
import { SignaturePadModule } from '@jump-tech-frontend/angular-common';
import { AddressLookupV2Component } from '@jump-tech-frontend/address-lookup-v2';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule,
    NgxSpinnerModule,
    HttpClientModule,
    SignaturePadModule,
    NguCarouselModule,
    PostCodeLookupModule,
    AddressLookupV2Component
  ],
  declarations: [
    DefaultFormComponent,
    OcrFormComponent,
    ImageCaptureFormComponent,
    CarouselFormComponent,
    CarouselMultipleFormComponent,
    AddressFormComponent,
    AddressMyLocationFormComponent,
    CardComponent,
    QuestionsComponent,
    InformationGroupsComponent,
    InformationFormComponent,
    ConsentFormComponent
  ],
  providers: [OcrService, ApiService],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    NguCarouselModule,
    CoreModule,
    DefaultFormComponent,
    OcrFormComponent,
    ImageCaptureFormComponent,
    CarouselFormComponent,
    CarouselMultipleFormComponent,
    AddressFormComponent,
    AddressMyLocationFormComponent,
    CardComponent,
    QuestionsComponent,
    InformationGroupsComponent,
    InformationFormComponent,
    ConsentFormComponent
  ]
})
export class JumptechCardsLibModule {}
