import { ISearchProvider } from '../../domain/search-provider';
import { IAddressV2 } from '../../domain/address-v2';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import {
  LoqateRetrieveResponse,
  LoqateRetrieveResponseItem,
  LoqateErrorResponse,
  LoqateSearchResponse
} from './loqate.model';

export const getLoqateSearchProvider = (httpClient: HttpClient, countryCodes: string[]): ISearchProvider => {
  const apiKey = 'GX92-UJ96-AP96-HF79';
  const apiUrlBase = 'https://api.addressy.com/Capture/Interactive';
  return {
    name: 'loqate',
    search: async (searchTerm: string, container?: string) => {
      let params: HttpParams = new HttpParams({
        fromObject: {
          Key: apiKey,
          Text: searchTerm,
          Countries: countryCodes.join(','),
          Language: 'en'
        }
      });
      if (container) {
        params = params.append('Container', container);
      }

      const loqateSearchResponse = await firstValueFrom(
        httpClient.get<LoqateSearchResponse | LoqateErrorResponse>(`${apiUrlBase}/Find/v1.1/json3.ws`, {
          params
        })
      );
      if (isErrorResponse(loqateSearchResponse)) {
        throw new Error(loqateSearchResponse.Items[0].Description);
      } else {
        return loqateSearchResponse?.Items.map(result => ({
          id: result.Id,
          description: result.Text,
          type: result.Type
        }));
      }
    },
    getResult: async (id: string) => {
      const params: HttpParams = new HttpParams({
        fromObject: {
          Key: apiKey,
          Id: id,
          Field1Format: '{Latitude}',
          Field2Format: '{Longitude}'
        }
      });
      const loqateRetrieveResponse = await firstValueFrom(
        httpClient
          .get<LoqateRetrieveResponse>(`${apiUrlBase}/Retrieve/v1.2/json3.ws`, { params })
          .pipe(catchError(throwError))
      );
      if (isErrorResponse(loqateRetrieveResponse)) {
        throw new Error(loqateRetrieveResponse.Items[0].Description);
      }
      return addressResponseToAddressV2Transform(loqateRetrieveResponse.Items[0]);
    }
  };
};

function isErrorResponse(
  response: LoqateSearchResponse | LoqateRetrieveResponse | LoqateErrorResponse
): response is LoqateErrorResponse {
  return (response as LoqateErrorResponse).Items?.[0]?.Error !== undefined;
}

function addressResponseToAddressV2Transform(address: LoqateRetrieveResponseItem): IAddressV2 {
  const county = address.Province ?? address.AdminAreaName ?? '';
  return {
    line1: address.Line1 ?? '',
    line2: address.Line2 ?? '',
    line3: [address.Line3, address.Line4, address.Line5].filter(a => a).join(', '),
    town: address.City ?? '',
    county: county && county === address.City ? '' : county ?? '',
    country: address.CountryName ?? '',
    postCode: address.PostalCode ?? '',
    latitude: address?.Field1 ?? undefined,
    longitude: address?.Field2 ?? undefined,
    countryCode: address.CountryIso2.toLowerCase()
  };
}
