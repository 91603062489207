<div class="contact-log-modal modal-wrapper" *ngIf="(vm$ | async) as vm">
  <div class="modal-header-wrapper">
    <div class="modal-banner">{{ vm.addContactLog }}</div>
  </div>
  <div class="modal-body">
    <p class="contact-log-modal__message">{{ vm.pleaseCompleteMsg }}</p>
    <alert class="contact-log-modal__info" type="info">
      <div class="contact-log-modal__info__content">
        <jui-icon
          class="contact-log-modal__info__icon"
          name="info"
          size="xl"
        ></jui-icon>
        <div>
          <div class="contact-log-modal__info__title">{{ vm.infoImportant }}</div>
          <ul class="contact-log-modal__info__ulist">
            <li class="contact-log-modal__info__list">{{ vm.infoImportantEdit }}</li>
            <li class="contact-log-modal__info__list">{{ vm.infoImportantActivity }}</li>
          </ul>
        </div>
      </div>
    </alert>
    <div [formGroup]="vm.form">
      <label class="form-label" for="addContactLogCommunicationType">{{ vm.communicationType }}: <span class="contact-log-modal__error" *ngIf="vm.form.controls.communicationType.invalid">{{ vm.formErrors['communicationType'] }}</span></label>
      <div class="btn-group btn-group-toggle" ngbRadioGroup name="addContactLogOutcome" formControlName="communicationType" [attr.data-qa]="'addContactLogCommunicationType'">
        <label ngbButtonLabel data-qa="phone" [class]="'btn-sm ' + (isSelected('communicationType', 'phone') ? 'btn-primary' : 'btn-default')">
          <input ngbButton type="radio" [value]="'phone'"> {{ vm.phone }}
        </label>
        <label ngbButtonLabel data-qa="email" [class]="'btn-sm ' + (isSelected('communicationType', 'email') ? 'btn-primary' : 'btn-default')">
          <input ngbButton type="radio" [value]="'email'"> {{ vm.email }}
        </label>
        <label ngbButtonLabel data-qa="phoneEmail" [class]="'btn-sm ' + (isSelected('communicationType', 'phoneEmail') ? 'btn-primary' : 'btn-default')">
          <input ngbButton type="radio" [value]="'phoneEmail'"> {{ vm.phoneEmail }}
        </label>
      </div>
      <label class="form-label" for="addContactLogOutcome">{{ vm.outcome }}: <span class="contact-log-modal__error" *ngIf="vm.form.controls.outcome.invalid">{{ vm.formErrors['outcome'] }}</span></label>
      <div class="btn-group btn-group-toggle" ngbRadioGroup name="addContactLogOutcome" formControlName="outcome" [attr.data-qa]="'addContactLogOutcome'">
        <label ngbButtonLabel data-qa="notReached" [class]="'btn-sm ' + (isSelected('outcome', 'notReached') ? 'btn-primary' : 'btn-default')">
          <input ngbButton type="radio" [value]="'notReached'"> {{ vm.notReached }}
        </label>
        <label ngbButtonLabel data-qa="reached" [class]="'btn-sm ' + (isSelected('outcome', 'reached') ? 'btn-primary' : 'btn-default')">
          <input ngbButton type="radio" [value]="'reached'"> {{ vm.reached }}
        </label>
      </div>
      <label class="form-label" for="note">{{ vm.note }}:</label>
      <textarea
        class="form-control"
        formControlName="note"
        id="note"
        rows="4"
        maxlength="1000"
        [attr.data-qa]="'addContactLogNote'"
      ></textarea>
    </div>
  </div>
  <div class="modal-footer contact-log-modal__footer">
    <jui-button class="contact-log-modal__footer__cancel" color="secondary" size="sm" [attr.data-qa]="'cancelButton'" (click)="dismiss()">
      {{ vm.cancel }}
    </jui-button>
    <jui-button size="sm" [disabled]="vm.form.invalid" [attr.data-qa]="'saveButton'" (click)="save()">
      {{ vm.submit }}
    </jui-button>
  </div>
</div>
