import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractLayout } from './abstract.layout';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectCardLayout } from '../domain/project-card-layout';
import { CardControlService } from '../card-control.service';
import { AccessService } from '../../auth/services/access.service';

@Component({
  selector: 'app-gallery-layout',
  template: `
    <div *ngFor="let galleryItem of layout.items; index as i" class="card gallery__card">
      <div class="card-body gallery__card-body">
        <div class="row">
          <div class="col col-11">
            <div class="gallery__title">{{ galleryItem.key }}</div>
          </div>
          <div
            *ngIf="!readOnly && hasGalleryAccess"
            class="gallery__edit-card col col-1 text-right"
            (click)="setForm(galleryItem, i)"
          >
            <jui-icon
              size="xs"
              name="edit"
              [attr.data-qa]="galleryItem.editConfig.label + '_edit'"
              *ngIf="project.data[galleryItem.editConfig.key]"
            ></jui-icon>
            <jui-icon
              size="sm"
              name="playlist_add"
              [attr.data-qa]="galleryItem.editConfig.label + '_add'"
              *ngIf="!project.data[galleryItem.editConfig.key]"
            ></jui-icon>
          </div>
        </div>
        <div *ngIf="editItems[i]">
          <app-edit-layout-questions
            *ngIf="galleryItem.editConfig && forms"
            [question]="galleryItem.editConfig"
            [form]="forms[galleryItem.key]"
          >
          </app-edit-layout-questions>
        </div>
        <div *ngIf="!editItems[i] && project.data[galleryItem.editConfig.key]">
          <crds-http-image
            *ngIf="!isArray(project.data[galleryItem.editConfig.key])"
            [src]="getImageSrc(project.data[galleryItem.editConfig.key])"
            [downloadUrl]="getImageSrc(project.data, 'o')"
            imageClass="card-img-bottom"
            (clicked)="openImagesModal(project.data, galleryItem.editConfig.key)"
            style="cursor: pointer"
            [qaHook]="'galleryImg_' + galleryItem.editConfig.key"
          ></crds-http-image>
          <div *ngIf="isArray(project.data[galleryItem.editConfig.key])">
            <div *ngFor="let projectData of project.data[galleryItem.editConfig.key]; index as i">
              <crds-http-image
                *ngIf="!isAttachment(projectData)"
                [src]="getImageSrc(projectData)"
                [downloadUrl]="getImageSrc(projectData, 'o')"
                imageClass="card-img-bottom"
                style="cursor: pointer"
                (clicked)="openImagesModal(project.data, galleryItem.editConfig.key + '_' + i)"
                [qaHook]="'galleryImg_' + galleryItem.editConfig.key"
              ></crds-http-image>
              <div *ngIf="isAttachment(projectData)" class="attachment-filename" style="display: flex">
                <i class="material-icons material-icons-md">article</i>
                <span class="pt-1">{{ getAttachmentName(galleryItem.editConfig.key, projectData) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="editItems[i]" class="card-footer">
        <div class="list-layout__footer">
          <div class="list-layout__footer-item">
            <jui-button color="low" size="sm" (click)="cancelEdit(i)">
              {{ 'common.cancel' | transloco }}
            </jui-button>
          </div>
          <div class="list-layout__footer-item">
            <jui-button
              color="primary"
              size="sm"
              (click)="save(forms[galleryItem.key], i)"
              [attr.data-qa]="'saveButton'"
            >
              <span slot="icon" class="material-icons">check</span>
              {{ 'common.save' | transloco }}
            </jui-button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../../project-detail/gallery/gallery.component.scss']
})
export class GalleryLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {
  @Input() readOnly: boolean;
  @Input() useLayoutGroups: boolean;
  @Input() layout: ProjectCardLayout;
  @Input() data: any;
  @Input() galleryIdx: number;

  forms: UntypedFormGroup[] = [];
  editItems: boolean[] = [];

  constructor(
    sanitizer: DomSanitizer,
    apiService: ApiService,
    featureAccessService: AccessService,
    modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    private cardControlService: CardControlService
  ) {
    super(sanitizer, apiService, featureAccessService, modalService);
  }

  ngOnInit() {
    this.initialiseGallery(this.project.data);
  }

  ngOnDestroy() {
    this.cancelEdit();
  }

  setForm(galleryItem, index) {
    const formControls = {};
    if (galleryItem.editConfig) {
      formControls[galleryItem.editConfig.key] = new UntypedFormControl(
        this.getFormValue(galleryItem.editConfig.key) || ''
      );
    }
    this.forms[galleryItem.key] = new UntypedFormGroup(formControls);
    this.editItems[index] = true;
  }

  cancelEdit(index?) {
    if (index || index === 0) {
      this.editItems[index] = false;
    }
  }

  getFormValue(editConfigKey: string) {
    if (editConfigKey.indexOf('.') === -1) {
      return this.data[editConfigKey];
    }
    const parts = editConfigKey.split('.');
    return this.data[parts[0]][parts[1]];
  }

  save(form: UntypedFormGroup, index: number) {
    this.cancelEdit(index);
    const formValue = this.cardControlService.dataFromForm(form);
    if (Object.keys(formValue).length) {
      this.spinnerService.show().catch();
      this.apiService.updateProject(this.project, { data: formValue }).subscribe(() => {
        Object.assign(this.project.data, formValue);
        this.initialiseGallery(this.project.data);
        this.spinnerService.hide().catch();
      });
    }
  }

  isArray(value: any) {
    return Array.isArray(value);
  }

  getAttachmentName(key, projectData) {
    return projectData && projectData.name;
  }
}
