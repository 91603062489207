<div class="document-progress" *ngIf="vm.inProgress">
  <div class="document-progress__label" [attr.data-qa]="vm.qaProgressLbl">{{ vm.label }}</div>
  <div
    class="document-progress__bar progress-bar"
    [class.document-progress__complete]="vm.isComplete"
    [class.bg-success]="!vm.isComplete"
    [style.width]="vm.percentageValue"
    [attr.aria-valuenow]="vm.percentageValue"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
