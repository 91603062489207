export class Address {
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  locality?: string;
  subLocality?: string;
  stateRegion?: string;
  town?: string;
  county?: string;
  latitude?: number;
  longitude?: number;
  postCode?: string;
  country?: string;

  constructor(options: {
    line1?: string;
    line2?: string;
    line3?: string;
    line4?: string;
    locality?: string;
    subLocality?: string;
    stateRegion?: string;
    town?: string;
    county?: string;
    latitude?: number;
    longitude?: number;
    postCode?: string;
    country?: string;
  }) {
    this.line1 = options.line1;
    this.line2 = options.line2;
    this.line3 = options.line3;
    this.line4 = options.line4;
    this.locality = options.locality;
    this.subLocality = options.subLocality;
    this.stateRegion = options.stateRegion;
    this.town = options.town;
    this.county = options.county;
    this.latitude = options.latitude;
    this.longitude = options.longitude;
    this.postCode = options.postCode;
    this.country = options.country || '';
  }
}
