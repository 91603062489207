<ng-container *ngIf="vm$ | async as vm">
  <div *ngIf="vm.form" [formGroup]="vm.form" [class]="'text-box-question ' + (vm.inline ? 'text-box-question-inline' : '')">
    <label *ngIf="vm.label" class="form-label text-box-question__label" [attr.data-qa]="vm.key">
      <div>{{ vm.label }} {{ vm.required ? '*' : '' }} <span class="required">{{ vm.form.invalid ? vm.translations?.required : ''}}</span></div>
    </label>
    <input
      [formControlName]="vm.key"
      [id]="vm.key"
      [type]="vm.type ?? 'text'"
      class="form-control"
      [attr.data-qa]="vm.key + 'Input'"
      [value]="vm.value ?? ''"
      autocomplete="off"
    />
  </div>
</ng-container>
