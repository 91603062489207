import { Component, Input } from '@angular/core';
import { MyOrdersVm } from '../../my-orders.model';

@Component({
  selector: 'pathway-my-orders-address-type-selection',
  templateUrl: './address-type-selection.component.html',
  styleUrls: ['../../my-orders.component.scss']
})
export class MyOrdersAddressTypeSelectionComponent {
  @Input() vm: MyOrdersVm;
}
