import { BehaviorSubject } from 'rxjs';
import { Inject, Injectable, Optional } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private readonly defaultEnv: string;

  public currentEnv = new BehaviorSubject(this.env);

  constructor(@Optional() @Inject('stage') private stage: string, @Inject(Window) private _window: Window) {
    this.defaultEnv = this.stage;
    if (!sessionStorage.getItem('jt:env')) {
      this.env = this.stage;
    }
  }

  set env(value) {
    if (!value) {
      return;
    }
    sessionStorage.setItem('jt:env', value);
    this.currentEnv.next(value);
  }

  get env() {
    return sessionStorage.getItem('jt:env') || this.defaultEnv;
  }

  apiHost(env: string = this.env): string {
    return 'production' === env ? `https://api.nucleus.jumptech.tools` : `https://api.nucleus.${env}.jumptech.tools`;
  }

  staticDistributionHost(env: string = this.env): string {
    // return 'production' === env ? `https://static.jt.eco` : `https://static.${env}.jt.eco`;
    return 'https://static.jt.eco';
  }

  getEnvironments() {
    if (this._window.location.hostname === 'localhost') {
      return ['local', 'dev'];
    } else {
      return ['local', 'dev', 'staging', 'production'];
    }
  }

  getExportReadyData(data: any) {
    let dataString = JSON.stringify(data);
    dataString = dataString.replace(/api\.(local|dev|staging)\.jumptech/g, 'api.jumptech');
    dataString = dataString.replace(/relay-assets-(local|dev|staging|production)/g, 'jumptech-shared-assets');
    return JSON.parse(dataString);
  }

  getImportReadyData(data: any) {
    let dataString = JSON.stringify(data);
    if (this.env !== 'production') {
      dataString = dataString.replace(/api\.jumptech/g, `api.${this.env}.jumptech`);
    }
    dataString = dataString.replace(/relay-assets-(local|dev|staging|production)/g, 'jumptech-shared-assets');
    return JSON.parse(dataString);
  }
}
