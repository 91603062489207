import { GenericTransformerInterface } from './generic-transformer.interface';
import { DnoNotification } from '../domain/dno-notification';
import { Injectable } from '@angular/core';
import { Project } from '../domain/project';

@Injectable()
export class ProjectToDnoNotificationTransformer implements GenericTransformerInterface<{}, DnoNotification> {
  transform(project: Project) {
    const dnoDemandSummaryList = project.resources.filter(resource => resource.type === 'DnoDemandSummary');

    const images = [];
    if (project.data.importElecMeterPhoto) {
      images.push({ name: 'Electric Meter', source: project.data.importElecMeterPhoto });
    }
    if (project.data.fuseCutoutPhoto) {
      images.push({ name: 'Fuse Cutout', source: project.data.fuseCutoutPhoto });
    }
    if (project.data.chargerLocationPhoto) {
      images.push({ name: 'Proposed Location', source: project.data.chargerLocationPhoto });
    }

    return {
      dno: dnoDemandSummaryList[0].summary.dno,
      mpan: project.data.mpan,
      email: project.data.email,
      projectId: project.id,
      address: project.data.address,
      postCode: project.data.postCode,
      summary: dnoDemandSummaryList.length ? dnoDemandSummaryList[0].summary : {},
      images: images
    } as DnoNotification;
  }
}
