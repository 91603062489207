import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitiseValue'
})
export class ValueSanitiserPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'true':
      case true: {
        return 'Yes';
      }
      case 'false':
      case false: {
        return 'No';
      }
      default: {
        return value.replace(/_/g, ' ');
      }
    }
  }
}
