import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MyOrdersPresenter } from './my-orders.presenter';
import { BehaviorSubject } from 'rxjs';
import { MyOrdersVm, ProductResource, ShippingAddress } from './my-orders.model';

@Component({
  selector: 'pathway-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyOrdersComponent implements OnChanges, OnDestroy {
  @Input() products: ProductResource[];
  @Input() orders: any[];
  @Input() driverAddress: ShippingAddress;
  @Input() defaultAddress: ShippingAddress;
  @Input() projectId: string;
  @Input() refresh: boolean;

  vm$: BehaviorSubject<MyOrdersVm> = new BehaviorSubject<MyOrdersVm>(null);
  constructor(private presenter: MyOrdersPresenter) {}

  ngOnChanges(): void {
    this.presenter.load(
      this.vm$,
      this.products,
      this.orders,
      this.driverAddress,
      this.defaultAddress,
      this.projectId,
      this.refresh
    );
  }

  orderHardware(): void {
    this.presenter.submitOrder();
  }

  startNewOrder(): void {
    this.presenter.startNewOrder();
  }

  stopNewOrder(): void {
    this.presenter.stopNewOrder();
  }

  ngOnDestroy(): void {
    this.presenter.cleanUp();
  }
}
