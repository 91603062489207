import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { LoginComponent } from './login/login.component';
import { NewPasswordRequiredFormComponent } from './login/components/new-password-required-form/new-password-required-form.component';
import { ForgotPasswordFormComponent } from './login/components/forgot-password-form/forgot-password-form.component';
import { PasswordStrengthCheckerComponent } from './login/components/password-strength-checker/password-strength-checker.component';
import { SignInFormComponent } from './login/components/sign-in-form/sign-in-form.component';
import { SignInSuccessComponent } from './login/components/sign-in-success/sign-in-success.component';
import { ForgotPasswordSubmitFormComponent } from './login/components/forgot-password-submit-form/forgot-password-submit-form.component';
import { MfaRequiredFormComponent } from './login/components/mfa-required-form/mfa-required-form.component';
import { AuthenticationService } from './services/authentication.service';
import { CanActivateRoute } from './guards/can-activate-route';
import { CanDeactivateRoute } from './guards/can-deactivate-route';
import { ResetPasswordSuccessComponent } from './login/components/reset-password-success/reset-password-success.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ChangePasswordFormComponent } from './login/components/change-password-form/change-password-form.component';
import { TokenRefreshComponent } from './login/components/token-refresh/token-refresh.component';
import { CoreModule } from '../core/core.module';
import { CanActivateLeadsRoute } from './guards/can-activate-leads-route';

@NgModule({
  declarations: [
    LoginComponent,
    SignInFormComponent,
    ForgotPasswordFormComponent,
    ForgotPasswordSubmitFormComponent,
    SignInSuccessComponent,
    NewPasswordRequiredFormComponent,
    MfaRequiredFormComponent,
    PasswordStrengthCheckerComponent,
    ResetPasswordSuccessComponent,
    ChangePasswordFormComponent,
    TokenRefreshComponent
  ],
  imports: [CommonModule, JumptechCardsLibModule, TranslocoModule, CoreModule],
  providers: [AuthenticationService, CanActivateRoute, CanDeactivateRoute, CanActivateLeadsRoute],
  exports: [
    LoginComponent,
    SignInFormComponent,
    ForgotPasswordFormComponent,
    ForgotPasswordSubmitFormComponent,
    SignInSuccessComponent,
    NewPasswordRequiredFormComponent,
    MfaRequiredFormComponent,
    PasswordStrengthCheckerComponent
  ]
})
export class AuthModule {}
