<div id="toast-container" [ngClass]="[toasterConfig.positionClass]">
  <div
    toastComponent
    *ngFor="let toast of toasts"
    class="toast"
    [toast]="toast"
    [@toastState]="toasterConfig.animation"
    [iconClass]="toasterConfig.iconClasses[toast.type]"
    [iconValue]="toasterConfig.iconValues[toast.type]"
    [titleClass]="toasterConfig.titleClass"
    [messageClass]="toasterConfig.messageClass"
    [ngClass]="toasterConfig.typeClasses[toast.type]"
    (click)="click(toast)"
    (clickEvent)="childClick($event)"
    (mouseover)="stopTimer(toast)"
    (mouseout)="restartTimer(toast)"
  ></div>
</div>
