import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import * as JmesPath from 'jmespath';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { Datasource } from '../../domain/datasource';
import { I18nKeys } from '../../domain/i18n-keys';
import { ApiService } from '../api.service';
import { ListQuestionComponent } from '../list-question.component';
import { ScrollService } from '../scroll.service';

@Component({
  selector: `crds-question-button-group`,
  template: `
    <div class="crds-question-button-group" *ngIf="optionsPopulated && show">
      <div *ngIf="!initialised">
        <label *ngIf="question.label" class="form-label" [attr.for]="question.key">
          {{ question.label }}
          <span *ngIf="question.required"> *</span>
        </label>
        <crds-inline-spinner
          [name]="question.key"
          [qaHook]="question.loadingQaHook"
          [type]="question.loadingType"
          [message]="question.loadingMessage"
          [colour]="question.loadingColour"
          [bgColour]="question.loadingBgColour"
          [textColour]="question.loadingTextColour"
          [center]="question.loadingCenter"
        >
        </crds-inline-spinner>
      </div>

      <div *ngIf="initialised">
        <label *ngIf="question.label" class="form-label" [attr.for]="question.key"
          >{{ question.label + (question.required ? ' *' : '') }}
          <crds-form-error
            *ngIf="isInvalid"
            [question]="question"
            [message]="question.errorMessage"
            [i18ns]="i18ns"
          ></crds-form-error>
          <div [formGroup]="form">
            <div
              *ngIf="!question.vertical"
              class="btn-group btn-group-toggle"
              ngbRadioGroup
              [name]="question.key"
              [formControlName]="question.key"
              [attr.data-qa]="question.key"
            >
              <label
                *ngFor="let opt of question['options']"
                ngbButtonLabel
                [class]="'btn button-group-spaced ' + getButtonClass(opt)"
                [attr.data-qa]="opt.key"
              >
                <input ngbButton type="radio" [value]="opt.key" #buttonGroupInput />
                {{ opt.value }}
              </label>
            </div>
            <div
              *ngIf="question.vertical"
              class="btn-group-vertical btn-group-toggle"
              ngbRadioGroup
              [name]="question.key"
              [formControlName]="question.key"
              [attr.for]="question.key"
              [attr.data-qa]="question.key"
            >
              <table>
                <tr *ngFor="let opt of question['options']">
                  <td>
                    <label
                      ngbButtonLabel
                      [class]="'btn button-group-spaced ' + getButtonClass(opt)"
                      [attr.data-qa]="opt.key"
                    >
                      <input class="inner-button" ngbButton type="radio" [value]="opt.key" #buttonGroupInput />
                      {{ opt.value }}
                    </label>
                  </td>
                  <td>
                    <div class="vertical-description">
                      {{ opt.description }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </label>

        <!-- QUESTION HINT -->
        <crds-question-hint [question]="question"></crds-question-hint>

        <!-- QUESTION IMAGE -->
        <div class="image-wrapper">
          <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
        </div>

        <p *ngIf="alert">
          <jui-alert-block iconName="info" size="sm">{{
            question.alert[form.get(question.key).value]
          }}</jui-alert-block>
        </p>
      </div>
    </div>
  `,
  styles: [
    `
      .btn-group-vertical .inner-button {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
      }

      .btn-group-vertical .vertical-description {
        display: flex;
        align-items: center;
        padding: 10px;
      }

      .btn-group,
      .btn-group-vertical {
        display: block;
      }
    `
  ]
})
export class ButtonGroupQuestionComponent extends ListQuestionComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @ViewChild('buttonGroupInput') input;
  type: string;
  valueWhenDisabled;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;
  initialised = false;

  private _unsubscribe$ = new Subject();

  constructor(
    private imageAssetFactory: ImageAssetFactory,
    private scrollService: ScrollService,
    private spinnerService: NgxSpinnerService,
    protected apiService: ApiService
  ) {
    super(apiService);
  }

  /**
   * Initialise the default form values.
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.question['options'] = (this.question['options'] || []).map(item => {
      if (item.usei18n) {
        const i18nkey = (item.value as string).toLowerCase() as keyof I18nKeys;
        item.value = this.i18ns[i18nkey] as string;
      }
      return item;
    });
    if (this.question.datasource) {
      if (!this.question.datasource.transform) {
        this.question.datasource.transform =
          '[].{ text: join(`" - "`, [parent.name, name]), value: join(`" - "`, [parent.name, name]) }';
      }

      await this.spinnerService.show(this.question.key);
      await this.getData(this.question.datasource);
      this.render();
      await this.spinnerService.hide(this.question.key);
    } else {
      this.render();
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }

  render() {
    this.initialised = true;
    this.scrollService.scrollObservable.subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
    this.images = this.imageAssetFactory.create(this.question.image, this.form);
  }

  public mapData(result: any[], datasource: Datasource) {
    const options = [];
    const transformed = JmesPath.search(result, datasource.transform);
    transformed
      .sort((a, b) => (typeof a.order === 'number' && typeof b.order === 'number' ? a.order - b.order : 0))
      .forEach(item => {
        const initialText = item.text;
        const initialValue = item.value;

        if (typeof initialValue === 'string') {
          options.push({
            key: initialValue,
            value: initialText
          });
        } else if (Array.isArray(initialValue)) {
          for (const child of initialValue) {
            options.push({
              key: `${initialText} - ${child}`,
              value: `${initialText} - ${child}`
            });
          }
        }
      });

    if (options && options.length > 0) {
      this.question.options = options;
    } else {
      if (!this.question.options || !this.question.options.length) {
        this.question.options = [];
      }
    }
  }

  ngAfterViewInit() {
    this.form?.get(this.question.key)?.valueChanges.subscribe(() => {
      this.updateDependantFields(this.formValue);
    });
    this.form
      ?.get(this.question.key)
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(value => {
        this.updateDependantFields(value);
      });
  }

  get optionsPopulated(): boolean {
    if (this.question.datasource) {
      return true;
    }
    return this.question.options && this.question.options.length > 0;
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.dirty && this.form?.get(this.question.key)?.invalid;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  get alert() {
    return this.question.alert && this.question.alert[this.form?.get(this.question.key)?.value];
  }

  get formValue() {
    return this.form?.get(this.question.key)?.value;
  }

  /**
   * Enable or disable any fields that need updating based on the value selected.
   * We temporarily store any values when disabling.
   * We restore any previously set values when enabling.
   * If no previously set value, then mark field as untouched to avoid form error on changes.
   * @param value
   */
  updateDependantFields(value: any) {
    const opt = this.question.options.filter(option => option.key === value)[0];
    if (typeof opt === 'undefined') {
      return;
    }
    let scrollToField = null;
    if (opt.enables) {
      opt.enables.forEach(enable => {
        const enableControl = this.form.get(enable);
        if (enableControl) {
          enableControl.enable();
          if (this.valueWhenDisabled) {
            enableControl.patchValue(this.valueWhenDisabled);
          } else {
            enableControl.markAsUntouched();
          }
          if (!scrollToField && opt.scrollTo) {
            scrollToField = enable;
          }
        }
      });
    }
    if (opt.disables) {
      opt.disables.forEach(disable => {
        const disableControl = this.form.get(disable);
        if (disableControl) {
          this.valueWhenDisabled = disableControl.value;
          disableControl.reset();
          disableControl.disable();
        }
      });
    }
    if (scrollToField !== null) {
      this.scrollService.next(scrollToField);
    }
  }

  getButtonClass(opt) {
    return this.form?.get(this.question.key)?.value === opt.key ? 'btn-primary' : 'btn-default';
  }
}
