import { Injectable } from '@angular/core';

import { ComponentCanDeactivate } from '@jump-tech-frontend/app-config';
import { Observable } from 'rxjs';

@Injectable()
export class CanDeactivateRoute {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component && component.canDeactivate) {
      return component.canDeactivate();
    } else {
      return true;
    }
  }
}
