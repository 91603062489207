import { inject, InjectionToken } from '@angular/core';
import { get } from 'lodash';
import { CommonsTranslationService } from './commons-translation.service';

export interface TranslationProvider {
  getTranslation(path: string): string;
}

export const COMMONS_TRANSLATIONS_PROVIDER = new InjectionToken<TranslationProvider>('TranslationsProvider', {
  providedIn: 'root',
  factory: () => {
    const translationsService = inject(CommonsTranslationService);
    translationsService.loadTranslations();
    return {
      getTranslation(path: string): string {
        return get(translationsService.i18ns, path) as string;
      }
    };
  }
});
