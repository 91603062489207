import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-accordion-wrapper-panel',
  template: `
    <div class="accordion" [id]="to.id">
      <div class="card">
        <h2 class="card-header" [id]="'heading' + to.id">
          <button
            class="btn btn-link btn-block text-left"
            type="button"
            data-toggle="collapse"
            [attr.data-target]="'#collapse' + to.id"
            aria-expanded="true"
            [attr.aria-controls]="'collapse' + to.id"
          >
            {{ to.label }}
          </button>
        </h2>
        <div
          id="collapse{{ to.id }}"
          class="collapse show"
          [attr.aria-labelledby]="'heading' + to.id"
          [attr.data-parent]="'#' + to.id"
        >
          <div class="card-body">
            <ng-container #fieldComponent></ng-container>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .accordion {
        line-height: 1.5;
        padding-bottom: 5px;
      }

      .btn {
        padding: 0px;
        font-size: 12px;
        line-height: 1.5;
      }
    `
  ]
})
export class AccordionWrapperComponent extends FieldWrapper {}
