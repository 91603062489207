import { Component, Input, OnInit } from '@angular/core';
import { IStandardDataVm, StandardData } from '../../../domain';
import { StandardQuotePresenter } from './standard-quote.presenter';

@Component({
  selector: 'app-standard-quote-component',
  templateUrl: './standard-quote.component.html',
  styleUrls: ['./standard-quote.component.scss']
})
export class StandardQuoteComponent implements OnInit {
  vm: IStandardDataVm;

  constructor(private presenter: StandardQuotePresenter) {}

  async ngOnInit() {
    await this.presenter.load(async generatedModel => {
      this.vm = generatedModel;
    });
  }

  async changeName(name: string) {
    this.presenter.changeName(name);
  }

  async changeProjectType(projectType: string[]) {
    const newProjectType = projectType.length ? projectType[0] : null;
    await this.presenter.changeProjectType(newProjectType);
  }

  async changeData(value) {
    await this.presenter.changeData(value);
  }

  async actionClick() {
    await this.presenter.actionClicked(this.vm);
  }

  closeModal() {
    this.presenter.closeModal();
  }
}
