<div class="welcome" [class.u-opacity-none]="hasAppBooted" [class.a-fade-in--fast]="hasAppBooted">
  <h1 class="welcome__title" [attr.data-qa]="'welcomeTitle'">
    <span>{{ 'welcome.welcomeTitle' | transloco }}</span>
    <img class="welcome__logo" alt="Jumptech" [src]="themeService.brandLogo$ | async" />
  </h1>

  <div class="welcome__actions" [class.u-opacity-none]="!hasAppBooted" [class.a-fade-in--delayed]="!hasAppBooted">
    <jui-button
      expand
      size="xl"
      *ngIf="showScheduleButton"
      title="{{ 'Buttons.viewSchedule.title' | transloco }}"
      class="welcome__action"
      routerLink="/schedule"
      [attr.data-qa]="'viewScheduleButton'"
    >
      {{ 'Buttons.viewSchedule.text' | transloco }}
    </jui-button>

    <jui-button
      expand
      size="xl"
      *ngIf="showProjectsButton && !invalidProjectType"
      [color]="!readyToRenderProjects ? 'low' : 'primary'"
      title="{{ 'Buttons.viewProjects.title' | transloco }}"
      class="welcome__action"
      routerLink="/projects"
      [disabled]="!readyToRenderProjects"
      [attr.data-qa]="'viewProjectsButton'"
      [loading]="!readyToRenderProjects"
    >
      {{ 'Buttons.viewProjects.text' | transloco }}
    </jui-button>

    <jui-button
      expand
      size="xl"
      *ngIf="showSearchButton"
      title="{{ 'Buttons.viewSearch.title' | transloco }}"
      class="welcome__action"
      routerLink="/projects-search"
      [attr.data-qa]="'searchButton'"
    >
      {{ 'Buttons.viewSearch.text' | transloco }}
    </jui-button>
    <jui-button
      expand
      size="xl"
      *ngIf="showSetupAtomButton"
      class="welcome__action"
      (click)="openAtomConfig()"
      title="{{ 'Buttons.atomApp.title' | transloco }}"
      [attr.data-qa]="'setupAtomConfigButton'"
    >
      {{ 'Buttons.atomApp.text' | transloco }}
    </jui-button>
  </div>
  <jui-alert-block *ngIf="invalidProjectType" color="danger">
    {{ 'welcome.validation.invalidProjectConfig' | transloco }}
  </jui-alert-block>
</div>

<div class="home-data u-opacity-none" [class.a-fade-in--fast]="hasAppBooted" [class.a-fade-in--delayed]="!hasAppBooted">
  <div class="home-data__item">
    <app-user-history [userHistory$]="userHistory$" (clearHistory)="clearRouteHistory()" [hasAppBooted]="hasAppBooted">
    </app-user-history>
  </div>
</div>

<div class="jt-help">
  <div class="jt-help__inner">
    <button
      class="btn btn-outline-primary jt-help__btn"
      title="{{ 'Buttons.helpCenter.title' | transloco }}"
      (click)="openHelp()"
      [attr.data-qa]="'helpCenterButton'"
    >
      <img class="jt-help__logo" alt="Jumptech" [src]="themeService.brandLogo$ | async" />
      {{ 'Buttons.helpCenter.text' | transloco }}
    </button>
  </div>
</div>
