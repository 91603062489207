import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDocumentVm } from '../../document-pack.model';

@Component({
  selector: 'app-document-pack-document-row',
  templateUrl: './document-pack-document-row.component.html',
  styleUrls: ['../../document-pack.component.scss']
})
export class DocumentPackDocumentRowComponent {
  @Input() doc: IDocumentVm;
  @Input() projectId;
  @Input() acceptedFileTypes;
  @Input() actionButtonDisabled;
  @Input() inProgress;
  @Input() locked = false;

  @Output() actionClick: EventEmitter<HTMLInputElement> = new EventEmitter();
  @Output() requiredToggle: EventEmitter<IDocumentVm> = new EventEmitter();
  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  @Output() onAttachmentSaved: EventEmitter<any> = new EventEmitter();
}
