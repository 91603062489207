import { User } from './user';
import { get } from 'lodash';

export class ReportUser {
  private user: User;

  constructor(user: User) {
    this.user = user;
  }

  getPrincipalId() {
    return this.user.id;
  }

  getTeamId() {
    return this.user?.accessInfo?.teams?.length > 0 && this.user.accessInfo.teams[0].id;
  }

  isFeatureEnabled(featureName: string): boolean {
    return (
      this.user?.accessInfo?.features?.length > 0 &&
      this.user.accessInfo.features.filter(feature => feature.name === featureName).length > 0
    );
  }

  isSubTenant(): boolean {
    return this.user?.accessInfo?.subTenants?.length > 0;
  }

  getSubTenantId(): string | null {
    return this.user?.accessInfo?.subTenants?.length ? this.user.accessInfo.subTenants[0].id : null;
  }

  getSubTenantName(): string {
    return (this.user && get(this.user, 'accessInfo.subTenants[0].configuration.name')) || '';
  }

  getInstaller(): string {
    return (this.user && get(this.user, 'accessInfo.subTenants[0].configuration.tenantConfiguration.installer')) || '';
  }

  getInstallerEvhs(): string {
    return (
      (this.user && get(this.user, 'accessInfo.subTenants[0].configuration.tenantConfiguration.installerEvhs')) || ''
    );
  }

  getInstallerContact(): string {
    return (
      (this.user && get(this.user, 'accessInfo.subTenants[0].configuration.tenantConfiguration.installerContact')) || ''
    );
  }

  getInstallerPhone(): string {
    return (
      (this.user && get(this.user, 'accessInfo.subTenants[0].configuration.tenantConfiguration.installerPhone')) || ''
    );
  }

  getInstallerEmail(): string {
    return (
      (this.user && get(this.user, 'accessInfo.subTenants[0].configuration.tenantConfiguration.installerEmail')) || ''
    );
  }

  getInstallerAddress(): string {
    if (!this.user) {
      return '';
    }
    const address = get(this.user, 'accessInfo.subTenants[0].configuration.tenantConfiguration.address');
    if (address) {
      const fullAddress = ['line1', 'line2', 'town', 'county', 'postCode']
        .map(addressElement => {
          return address[addressElement] || null;
        })
        .filter(addressElement => addressElement !== null)
        .join(', ');
      return `${this.getInstaller()}, ${fullAddress}`;
    }
    return '';
  }

  getAccessInfo() {
    return this.user && this.user.accessInfo;
  }
}
