import { QuestionBase } from '@jump-tech-frontend/domain';
import { LikertQuestionParams } from './questions/likert/likert.model';
import { TextareaQuestionParams } from './questions/textarea/textarea.model';
import { TextboxQuestionParams } from './questions/textbox/textbox.model';
import { ModalQuestionParams } from './questions/modal/modal.model';
import { Questions } from './questions/question.model';
import { TextboxQuestion } from '../../../domain/src/lib/question-components/text-box-question';

export function getQuestionParams(question: QuestionBase<string> | TextboxQuestion) {
  switch (question.controlType) {
    case Questions.TextareaQuestion:
      return cardQuestionToTextareaQuestionParamsTransformer(question);
    case Questions.LikertQuestion:
      return cardQuestionToLikertQuestionParamsTransformer(question);
    case Questions.ModalQuestion:
      return cardQuestionToModalQuestionParamsTransformer(question);
    default:
      return cardQuestionToTextboxQuestionParamsTransformer(question as TextboxQuestion);
  }
}

export function cardQuestionToTextboxQuestionParamsTransformer(question: TextboxQuestion): TextboxQuestionParams {
  return {
    key: question.key,
    label: question.label,
    required: question.required,
    value: question.value,
    inline: question.inline
  };
}

export function cardQuestionToTextareaQuestionParamsTransformer(
  question: QuestionBase<string>
): TextareaQuestionParams {
  return {
    key: question.key,
    label: question.label,
    required: question.required,
    value: question.value
  };
}

export function cardQuestionToModalQuestionParamsTransformer(question: QuestionBase<string>): ModalQuestionParams {
  return {
    key: question.key,
    label: question.label,
    content: question.content,
    hint: question.hint,
    required: question.required,
    actionLabel: question.actionLabel
  };
}

export function cardQuestionToLikertQuestionParamsTransformer(question: QuestionBase<string>): LikertQuestionParams {
  return {
    key: question.key,
    label: question.label,
    required: question.required,
    value: question.value,
    bottomHint: question.bottomHint,
    topHint: question.topHint
  };
}
