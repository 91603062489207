import { Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { LOGIN_PATH } from '../app.routes';
import { AccessService } from '../auth/services/access.service';
import { AuthenticationService } from '../auth/services/authentication.service';
import { UserService } from '../auth/services/user.service';

import { ApiService, UserLookupResultItem } from '../core/api.service';
import { CustomLookupService } from '../core/custom-lookup.service';
import { DelegationService } from '../core/delegate/delegation.service';
import { Delegate } from '../core/domain/delegate';
import { ProjectTypeType } from '../core/domain/project-configuration';
import { User } from '../core/domain/user';
import { ProjectConfigurationService } from '../core/project-configuration.service';
import { IfDefinition } from '../core/utils/filter';
import { DropDownElement } from '../shared/form-components/multiple-selection-dropdown.component';
import {
  ProgressIndicatorState,
  ProgressIndicatorStatus
} from '../shared/progress-indicator/progress-indicator.component';
import { ToasterService } from '../toast/toast-service';
import { CustomDashboardFilter } from './custom-dashboard-filters/custom-dashboard-filter.component';
import { Dashboard } from './dashboard';
import { ExportService } from './dashboard-export/export-service';
import { DashboardType } from './dashboard-type';
import { SearchContext } from './search-context';
import {
  ApiDocumentState,
  DOCUMENT_STATE_KEY_PREFIX,
  IDocumentPackDefinition,
  OUTSTANDING_DOCUMENT_STATES
} from '../project-detail/document-pack/document-pack.model';
import { FeatureFlagService } from '../core/feature-flag/feature-flag.service';
import * as moment from 'moment-timezone';

export interface SearchParameters {
  eventFilterType?: string;
  eventFilterDateRange?: {
    start?: Date;
    end?: Date;
  };
  projectType?: string;
  statuses?: string[];
  owner?: string;
  documents?: string[];
  documentStates?: string[];
  delegate?: string;
  subTenant?: string;
  contactAttempts?: number | number[];
}

export interface ExportField {
  header: string;
  field: string;
  after?: string;
  before?: string;
  pos?: number;
  remove?: boolean;
  alias?: string;
  context?: {
    [key: string]: IfDefinition;
  };
}

export interface ExportConfiguration {
  prefix: string;
  fields: ExportField[];
}

export interface DashboardField {
  field: string;
  display?: string;
  context?: {
    [key: string]: IfDefinition;
  };
  after?: string;
  before?: string;
  pos?: number;
  sortField?: string;
  remove?: boolean;
  noSort?: boolean;
  maxLength?: number;
}

export const DashboardSearchSettings = 'DashboardSearchSettings';

@Directive()
export abstract class AbstractDashboard implements Dashboard, OnInit {
  savedSearchParams: SearchParameters;
  projectStates: ProgressIndicatorState[] = [];
  projectsConfiguration: {
    states: any[];
    actions: any[];
    layouts: any[];
    customDashboardFields?: DashboardField[];
    customDashboardFilters?: CustomDashboardFilter[];
    exportConfiguration?: ExportConfiguration;
    documentPackDefinition?: IDocumentPackDefinition;
    managerRole?: string;
  } = null;
  projectConfigurationTypes: { projectType: string }[];
  projectStatuses: { status: string; label: string }[];
  outstandingDocumentTypes = OUTSTANDING_DOCUMENT_STATES;
  currentProjectType: string;
  allStatuses = 'ALL';
  allOwners = 'ALL';
  ownerUnassigned = 'UNASSIGNED';
  allInstallers = 'ALL';
  defaultAdminUser = 'Admin User';
  currentProjectStatuses: string[] = [];
  currentProjectOwner = this.allOwners;
  currentDocuments: string[] = [];
  currentDocumentStates: string[] = [];
  eventFilterType: string;
  eventFilterDateRange: { start?: Date; end?: Date } = {};
  contactAttempts: number | number[];
  user: User;
  exporting = false;
  delegates: Delegate[] = [];
  dashboardFields: DashboardField[] = [];
  dashboardMainCustomFilters: CustomDashboardFilter[] = [];
  dashboardAdvancedCustomFilters: CustomDashboardFilter[] = [];
  eventFilterTypeElements: DropDownElement[] = [];
  dropDownElements: DropDownElement[] = [];
  documents: DropDownElement[] = [];
  documentStates: DropDownElement[] = [];
  locale = {
    applyLabel: this.translocoService.translate('common.ok'),
    displayFormat: moment.localeData().longDateFormat('L'),
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort()
  };

  showAllStatusOption = {
    status: this.allStatuses,
    label: this.translocoService.translate('dashboard.anyStatus'),
    sticky: true
  };

  protected constructor(
    protected apiService: ApiService,
    protected router: Router,
    protected userService: UserService,
    protected toasterService: ToasterService,
    protected exportService: ExportService,
    protected authenticationService: AuthenticationService,
    protected projectConfigurationService: ProjectConfigurationService,
    protected customLookupService: CustomLookupService,
    protected spinnerService: NgxSpinnerService,
    protected featureAccessService: AccessService,
    protected translocoService: TranslocoService,
    protected delegationService: DelegationService,
    protected featureFlagService: FeatureFlagService,
    public searchContext: SearchContext
  ) {}

  private static mergeCustomExportFields(baseFields: ExportField[], exportFields: ExportField[]) {
    const removeIfExisting = customField => {
      const existingIdx = baseFields.findIndex(f => f.header === customField.header);
      if (existingIdx > -1) {
        baseFields.splice(existingIdx, 1);
      }
    };

    const insertOrAdd = (baseFields, customField, beforeAfterPos: 'before' | 'after' | 'pos') => {
      if (beforeAfterPos === 'pos') {
        baseFields.splice(customField.pos, 0, customField);
      } else {
        let idx = baseFields.findIndex(f => f.header === customField[beforeAfterPos]);
        idx = idx === -1 ? baseFields.length : beforeAfterPos === 'after' ? idx + 1 : idx;
        baseFields.splice(idx, 0, customField);
      }
    };

    for (const customField of exportFields) {
      removeIfExisting(customField);
      if (customField.remove) {
        // Already removed
      } else if (customField.after) {
        insertOrAdd(baseFields, customField, 'after');
      } else if (customField.before) {
        insertOrAdd(baseFields, customField, 'before');
      } else if (typeof customField.pos !== 'undefined') {
        insertOrAdd(baseFields, customField, 'pos');
      } else {
        baseFields.push(customField);
      }
    }
    return baseFields;
  }

  fetchConfiguration(): Promise<any> {
    // fetch data in parallel
    const projectType = this.savedSearchParams?.projectType
      ? this.savedSearchParams?.projectType
      : this.projectConfigurationTypes[0].projectType;

    const configCalls: Array<Promise<any>> = [
      this.delegationService.getDelegatesForProjectType(projectType),
      this.featureAccessService.getTeamsForProjectType(projectType),
      this.projectConfigurationService.getProjectConfiguration(projectType)
    ];
    return Promise.all(configCalls).then(values => {
      this.currentProjectType = projectType;
      this.projectsConfiguration = values.pop();
    });
  }

  async fetchDocumentPackDefinitionDetails() {
    try {
      if (this.projectsConfiguration.documentPackDefinition) {
        const definitionDetails = await this.apiService.getDocumentPackDefinition(
          this.projectsConfiguration.documentPackDefinition.id,
          this.projectsConfiguration.documentPackDefinition.version
        );
        if (definitionDetails.documents && definitionDetails.documents.length) {
          this.documents = definitionDetails.documents.map(doc => ({ id: doc.displayName, name: doc.displayName }));
          this.documentStates = Object.keys(ApiDocumentState).map(state => ({
            id: state,
            name: this.translocoService.translate(`${DOCUMENT_STATE_KEY_PREFIX}.${ApiDocumentState[state]}`)
          }));
        }
      } else {
        this.documents = [];
        this.documentStates = [];
      }
    } catch (e) {
      // fail gracefully
      this.currentDocuments = [];
      this.documents = [];
      this.documentStates = [];
    }
  }

  async ngOnInit() {
    this.subscribeToUser();
    await this.initialise();
  }

  async initialise() {
    try {
      await this.spinnerService.show('projectsSpinner');
      this.projectConfigurationTypes = await this.getProjectTypes();
      this.authenticationService.signOutObservable.subscribe(() => localStorage.removeItem(DashboardSearchSettings));
      this.savedSearchParams = this.getSavedSearchParameters(this.projectConfigurationTypes);

      await this.fetchConfiguration();
      await this.fetchDocumentPackDefinitionDetails();
      await this.setProjectStatuses();

      const managerRole = (this.projectsConfiguration && this.projectsConfiguration.managerRole) || 'Account Manager';
      const managersList: UserLookupResultItem[] = await this.customLookupService.customLookup(
        `core/users/${encodeURIComponent(managerRole)}`
      );
      const managersDropDownElements: DropDownElement[] = managersList
        .filter(e => {
          return e.key !== this.defaultAdminUser;
        })
        .map(e => {
          return { id: e.key, name: e.key };
        });
      const stickyDropDownElements = [
        { id: this.ownerUnassigned, name: this.translocoService.translate('common.unassigned'), sticky: true },
        { id: this.allOwners, name: this.translocoService.translate('dashboard.anyOwner'), sticky: true }
      ];
      this.dropDownElements = [...managersDropDownElements, ...stickyDropDownElements];
      this.eventFilterTypeElements = [
        { id: 'created_on', name: this.translocoService.translate('dashboard.createdOn') },
        { id: 'updated_on', name: this.translocoService.translate('dashboard.updatedOn') },
        { id: 'scheduledDate', name: this.translocoService.translate('dashboard.scheduledDate') }
      ];
      this.getProjects(this.savedSearchParams);
    } catch {
      // Only hide the spinner if there's an error, otherwise it will be hidden
      // when we have all the projects
      await this.spinnerService.hide('projectsSpinner');
    }
  }

  public subscribeToUser() {
    this.userService.userObservable.subscribe(async (user: User) => {
      if (user !== null) {
        if (AuthenticationService.getTier() === 'support') {
          this.projectConfigurationService.flush();
        }

        this.user = user;
        await this.initialise();
      }
    });
  }

  protected getSavedSearchParameters(projectConfigurationTypes) {
    const settings = localStorage.getItem(DashboardSearchSettings);
    if (!settings) {
      return null;
    }

    const parsedSettings = JSON.parse(settings);
    if (parsedSettings?.status) {
      parsedSettings.statuses = [parsedSettings.status];
      delete parsedSettings.status;
    }

    if (
      parsedSettings.projectType &&
      projectConfigurationTypes.filter(pct => pct.projectType === parsedSettings.projectType).length > 0
    ) {
      return parsedSettings;
    }

    return null;
  }

  protected saveSearchSettings(searchParams?: SearchParameters) {
    if (searchParams) {
      localStorage.setItem(DashboardSearchSettings, JSON.stringify(searchParams));
    }
  }

  /**
   * @param searchParams
   */
  abstract getProjects(searchParams?: SearchParameters);

  abstract getDashboardType(): DashboardType;

  async updateProjectsConfigurationAndFetchProjects(projectType: string[]) {
    this.searchContext.withPage(1);
    await this.spinnerService.show('projectsSpinner');
    await this.setProjectsConfiguration(projectType[0]);
    await this.fetchDocumentPackDefinitionDetails();

    this.getProjects({ projectType: projectType[0], delegate: null, subTenant: null });
  }

  async updateEventFilterType(eventFilterType: string[]) {
    this.searchContext.withPage(1);
    this.getProjects({ eventFilterType: eventFilterType?.[0] });
  }

  async updateEventFilterDateRange() {
    this.searchContext.withPage(1);
    const start = this.eventFilterDateRange?.start;
    const end = this.eventFilterDateRange?.end;
    this.getProjects({ eventFilterDateRange: { start, end } });
  }

  updateStatusAndSearch(statuses: string[]) {
    this.searchContext.withPage(1);
    this.getProjects({ statuses: statuses });
  }

  updateContactAttemptsAndSearch(attempts: number | number[]) {
    this.searchContext.withPage(1);
    this.getProjects({ contactAttempts: attempts });
  }

  updateOwnerAndSearch(owner: string[]) {
    this.searchContext.withPage(1);
    this.getProjects({ owner: owner[0] });
  }

  updateDocumentsAndSearch(documents: string[]) {
    this.searchContext.withPage(1);
    this.getProjects({ documents });
  }

  updateDocumentStatesAndSearch(documentStates: string[]) {
    this.searchContext.withPage(1);
    this.getProjects({ documentStates });
  }

  postUpdate = (result: any) => {
    this.searchContext.postUpdate(this.translocoService, result, this.user, this.projectsConfiguration.states);
    this.projectStates = this.getStates();
    this.spinnerService.hide('projectsSpinner').then();
  };

  abstract getProjectTypes(): Promise<ProjectTypeType[]>;

  private async setProjectStatuses() {
    this.projectStatuses = (this.projectsConfiguration.states || []).map(state => ({
      status: state.status,
      label: state.label,
      showProgress: state.showProgress
    }));
    this.projectStatuses.push(this.showAllStatusOption);
    this.projectStates = this.getStates();
  }

  private async setProjectsConfiguration(projectType: string) {
    this.currentProjectType = projectType;
    this.currentDocuments = [];
    this.projectsConfiguration = await this.projectConfigurationService.getProjectConfiguration(projectType);
    await this.setProjectStatuses();
  }

  handleError = (error: any) => {
    this.spinnerService.hide('projectsSpinner').then();
    if (!environment.production) {
      console.log('redirecting to login page because of error', error);
    }
    this.router.navigate([LOGIN_PATH], { replaceUrl: true });
    this.searchContext.handleError(error);
  };

  viewProject(evt, projectId: string) {
    const url = [`/project/${projectId}`];
    // check ctrl or cmd key / middle mouse click to open in new tab
    if (evt.ctrlKey || evt.metaKey || evt.button === 1) {
      evt.preventDefault();
      evt.stopPropagation();
      window.open(url.pop(), projectId);
    } else {
      this.router.navigate(url);
    }
  }

  private getStates(): ProgressIndicatorState[] {
    if (!this.projectsConfiguration) {
      console.log('No projectsConfiguration');
      return []; // In case we haven't fetched the configuration yet
    }

    return (this.projectsConfiguration.states || [])
      .filter(state => {
        return !state.hidden;
      })
      .map(state => ({
        label: state.label,
        count: this._getStatusCount(state.status),
        status: ProgressIndicatorStatus.todo,
        statusData: null,
        showSkipped: false
      }));
  }

  private _getStatusCount(status: string) {
    const aggregationCount = this.searchContext.getAggregationCount('status', status);

    if (aggregationCount === null) {
      return !this.searchContext.hasResults()
        ? 0
        : this.searchContext.getResults().filter(project => project.status === status).length;
    }

    return aggregationCount;
  }

  getDefaultExportConfiguration(): {
    prefix: string;
    fields: ExportField[];
  } {
    return {
      prefix: this.translocoService.translate('dashboard.project'),
      fields: [
        {
          header: this.translocoService.translate('dashboard.projectId'),
          field: '{id}'
        },
        {
          header: this.translocoService.translate('dashboard.createdOn'),
          field: "{created_on | date: 'DD MMMM YYYY'}"
        },
        {
          header: this.translocoService.translate('dashboard.updatedOn'),
          field: "{updated_on | date: 'DD MMMM YYYY'}"
        },
        {
          header: this.translocoService.translate('dashboard.installationDate'),
          field: '{{jobs[installation].scheduledDate | date: "DD/MM/YYYY HH:mm"}}',
          context: {
            installation: {
              jobTypeCategory: {
                $eq: 'Installation'
              }
            }
          }
        },
        {
          header: this.translocoService.translate('dashboard.createdBy'),
          field: '{created_by_name}',
          alias: 'created_by'
        },
        {
          header: this.translocoService.translate('dashboard.projectType'),
          field: '{type}'
        },
        {
          header: this.translocoService.translate('dashboard.projectStatus'),
          field: 'status'
        },
        {
          header: this.translocoService.translate('dashboard.customerFirstName'),
          field: 'firstName'
        },
        {
          header: this.translocoService.translate('dashboard.customerLastName'),
          field: 'lastName'
        },
        {
          header: this.translocoService.translate('dashboard.customerEmail'),
          field: '{email}'
        },
        {
          header: this.translocoService.translate('dashboard.customerPhoneNumber'),
          field: '{phoneNumber}'
        }
      ]
    };
  }

  getExportConfiguration() {
    const defaultExportConfiguration = this.getDefaultExportConfiguration();
    if (this.projectsConfiguration && this.projectsConfiguration.exportConfiguration) {
      defaultExportConfiguration.fields = AbstractDashboard.mergeCustomExportFields(
        defaultExportConfiguration.fields,
        this.projectsConfiguration.exportConfiguration.fields
      );
    }

    return defaultExportConfiguration;
  }

  exportToCsv() {
    this.exporting = true;
    this.toasterService.pop(
      'info',
      this.translocoService.translate('common.export'),
      this.translocoService.translate('dashboard.exportWillDownloadOnceComplete')
    );
    const exportConfig = this.getExportConfiguration();
    this.exportService
      .export(
        exportConfig,
        this.searchContext,
        this.user,
        this.projectsConfiguration,
        this.delegates && this.delegates.length > 0,
        this.eventFilterType
      )
      .then(() => {
        this.exporting = false;
      })
      .catch(err => {
        this.toasterService.pop(
          'error',
          this.translocoService.translate('common.export'),
          `${this.translocoService.translate('dashboard.exportFailed')}: ${err}`
        );
        this.exporting = false;
      });
  }

  updateMaxResults(target: any) {
    this.searchContext.withPageSize(target.value);
    if (this.searchContext.hasResults()) {
      this.getProjects();
    }
  }

  changePage(page: number) {
    if (this.projectConfigurationTypes.length) {
      this.searchContext.withPage(page);
      this.getProjects();
    }
  }

  changeSort(field: DashboardField) {
    if (field.noSort) {
      return;
    }
    const sort = field.sortField || field.field;
    this.searchContext.changeSort(sort);
    if (this.searchContext.hasResults()) {
      this.getProjects();
    }
  }

  abstract onDelegateChange(delegate: string): Promise<void>;

  abstract changeSuccess(message: string): Promise<void>;
}
