import { Component, OnInit } from '@angular/core';
import { SummaryPresenter } from './summary.presenter';
import { SummaryViewModel } from './summary.view.model';

@Component({
  selector: 'summary-component',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  vm: SummaryViewModel;
  constructor(private progressPresenter: SummaryPresenter) {}

  ngOnInit() {
    this.progressPresenter.load(vm => {
      this.vm = vm;
    });
  }
}
