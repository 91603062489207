import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateInput, EventInput } from '@fullcalendar/core';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  createTemporaryEvent,
  generateEvent,
  pad,
  standardCalendarConfiguration,
  validateEvent
} from '../../schedule/utils/schedule.helper';
import * as moment from 'moment';
import * as Analytics from '../../app.analytics';
import { TranslocoService } from '@ngneat/transloco';
import { TimeSlotHelper } from './time-slot.helper';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import {
  DateRangeSelectInfo,
  EventDropInfo,
  EventInfo,
  Resource,
  ResourceInfo,
  ScheduleQuestion,
  ViewInfo
} from '../../schedule/utils/schedule-types';
import { ScheduleEventTooltipComponent } from '../../schedule/components/schedule-event-tooltip/schedule-event-tooltip.component';
import { ScheduleService } from '../../schedule/services/schedule.service';
import {
  CURRENT_EVENT_BACKGROUND,
  CURRENT_EVENT_TEXT,
  TEMP_EVENT_ID,
  TIME_SLOTS
} from '../../schedule/utils/schedule-constants';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

@Component({
  selector: 'app-date-time-duration-select',
  styleUrls: ['../../schedule/schedule.component.scss'],
  template: `
    <div class="date-time-selector-body">
      <ngx-spinner
        bdColor="var(--jds-theme-spinner-bd-rgba)"
        size="large"
        [fullScreen]="false"
        type="line-scale"
        name="scheduleModalSpinner"
        [attr.data-qa]="'scheduleModalSpinner'"
      >
      </ngx-spinner>
      <div class="dts-toolbar row legend-container" *ngIf="question.preferredDate">
        <i class="material-icons preferred-date-legend">circle</i>
        <span
          >{{ 'common.preferred' | transloco }} - {{ question.preferredDate }} {{ question.preferredTimeslot }}</span
        >
        <i class="material-icons unavailable-date-legend">circle</i>
        <span>{{ 'common.unavailable' | transloco }}</span>
      </div>
      <div class="dts-toolbar row legend-container" *ngIf="question.preferredDate"></div>
      <div class="dts-toolbar row">
        <div class="resource-filter">
          <input
            type="text"
            name="resourceFilter"
            id="resourceFilter"
            (keyup)="resourceFilterKeyUp($event)"
            placeholder="{{ 'common.filterEngineers' | transloco }}"
            class="form-control"
            [disabled]="resourceFilterDisabled"
          />
        </div>
        <div class="tag-filter" *featureFlag="'user-tags'">
          <ng-select
            placeholder="{{ 'common.filterByTags' | transloco }}"
            [items]="allTags"
            [addTag]="false"
            [multiple]="true"
            [selectOnTab]="true"
            (change)="onChangeTags($event)"
          >
          </ng-select>
        </div>
      </div>

      <div *ngIf="hasScheduleAccess" class="calendar-container">
        <div class="calendar-wrapper">
          <full-calendar #calendar [options]="calendarConfig"></full-calendar>
        </div>

        <div class="event-details">
          <div class="event-details__header">{{ 'schedule.dateTimeSelector.editFormTitle' | transloco }}</div>
          <div class="event-details__body">
            <div *ngIf="!editForm">
              <div class="event-details__loader">
                <crds-inline-spinner
                  [name]="'editFormSpinner'"
                  [qaHook]="'editFormSpinner'"
                  [colour]="'var(--jds-theme-color-text)'"
                  [type]="'ball-clip-rotate'"
                  [center]="true"
                  size="small"
                >
                </crds-inline-spinner>
              </div>
            </div>
            <div class="event-details__form" *ngIf="editForm" [formGroup]="editForm">
              <div class="form-group">
                <label class="event-modal__lbl">{{ 'schedule.dateTimeSelector.engineerLabel' | transloco }}</label>
                <ng-select
                  placeholder="{{ 'schedule.formFields.engineers.placeholder' | transloco }}"
                  [items]="allResources"
                  formControlName="engineer"
                  [bindLabel]="'title'"
                  [bindValue]="'id'"
                  [multiple]="false"
                  [clearable]="false"
                  [attr.data-qa]="'engineerFilter'"
                >
                </ng-select>
                <div *ngIf="editForm" class="form-error">
                  <div *ngIf="editForm.errors?.engineerNotFound">
                    {{ 'schedule.errors.engineerNotFound' | transloco }}
                  </div>
                </div>
              </div>

              <!--  START DATE  -->
              <div class="event-details__row-split">
                <div class="form-group event-details__left">
                  <label for="startDate" class="event-modal__lbl">{{
                    'schedule.formFields.startDate.label' | transloco
                  }}</label>
                  <div class="input-group">
                    <input
                      class="form-control event-modal__ctrl"
                      [placeholder]="datePickerPlaceholder"
                      name="dp"
                      id="startDate"
                      formControlName="startDate"
                      ngbDatepicker
                      #startDate="ngbDatepicker"
                      [maxDate]="getMaxStartDate"
                      [attr.data-qa]="'startDateInput'"
                    />
                    <div class="input-group-append">
                      <button
                        class="event-modal__input-btn calendar"
                        (click)="startDate.toggle()"
                        type="button"
                        [attr.data-qa]="'startDateButton'"
                      >
                        <span class="event-modal__input-btn-icon material-icons">event</span>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="formControls.startDate.errors && formControls.startDate.dirty" class="form-error">
                    <div *ngIf="formControls.startDate.errors?.required">
                      {{ 'schedule.errors.startDateRequired' | transloco }}
                    </div>
                    <div *ngIf="formControls.startDate.errors?.ngbDate?.invalid">
                      {{ 'schedule.errors.invalidDateFormat' | transloco }}
                    </div>
                    <div *ngIf="formControls.startDate.errors?.ngbDate?.maxDate">
                      {{ 'schedule.errors.startDateAfterEnd' | transloco }}
                    </div>
                  </div>
                </div>
                <div class="form-group w-100 event-details__right">
                  <label for="startTime" class="event-modal__lbl">{{
                    'schedule.formFields.startTime.label' | transloco
                  }}</label>
                  <ng-select
                    class="w-100"
                    formControlName="startTime"
                    id="startTime"
                    bindLabel="label"
                    [items]="timeSlots"
                    [clearable]="false"
                    [attr.data-qa]="'startTimeSelect'"
                  ></ng-select>
                  <div *ngIf="formControls.startTime.errors" class="form-error">
                    <div *ngIf="formControls.startTime.errors?.required">
                      {{ 'common.isRequired' | transloco }}
                    </div>
                  </div>
                </div>
              </div>

              <!--  END DATE  -->
              <div class="event-details__row-split">
                <div class="form-group event-details__left">
                  <label for="endDate" class="event-modal__lbl">{{
                    'schedule.formFields.endDate.label' | transloco
                  }}</label>
                  <div class="input-group">
                    <input
                      class="form-control event-modal__ctrl"
                      [placeholder]="datePickerPlaceholder"
                      name="dp"
                      id="endDate"
                      formControlName="endDate"
                      ngbDatepicker
                      [minDate]="getMinEndDate"
                      #endDate="ngbDatepicker"
                      [attr.data-qa]="'endDateInput'"
                    />
                    <div class="input-group-append">
                      <button
                        class="event-modal__input-btn calendar"
                        (click)="endDate.toggle()"
                        type="button"
                        [attr.data-qa]="'endDateButton'"
                      >
                        <span class="event-modal__input-btn-icon material-icons">event</span>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="formControls.endDate.errors && formControls.endDate.dirty" class="form-error">
                    <div *ngIf="formControls.endDate.errors?.required">
                      {{ 'schedule.errors.endDateRequired' | transloco }}
                    </div>
                    <div *ngIf="formControls.endDate.errors?.ngbDate?.invalid">
                      {{ 'schedule.errors.invalidDateFormat' | transloco }}
                    </div>
                    <div *ngIf="formControls.endDate.errors?.ngbDate?.minDate">
                      {{ 'schedule.errors.endDateBeforeStart' | transloco }}
                    </div>
                  </div>
                </div>
                <div class="form-group w-100 event-details__right">
                  <label for="endTime" class="event-modal__lbl">{{
                    'schedule.formFields.endTime.label' | transloco
                  }}</label>
                  <ng-select
                    class="w-100"
                    formControlName="endTime"
                    id="endTime"
                    bindLabel="label"
                    [items]="timeSlots"
                    [clearable]="false"
                    [attr.data-qa]="'endTimeSelect'"
                  ></ng-select>
                  <div *ngIf="formControls.endTime.errors" class="form-error">
                    <div *ngIf="formControls.endTime.errors?.required">
                      {{ 'common.isRequired' | transloco }}
                    </div>
                  </div>
                  <div *ngIf="editForm.errors" class="form-error">
                    <div *ngIf="editForm.errors?.eventEndTimeBeforeStartTime">
                      {{ 'schedule.errors.eventEndTimeBeforeStartTime' | transloco }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <jui-button
                  size="sm"
                  *ngIf="!existingEvent"
                  color="secondary"
                  [attr.data-qa]="'addToCalendarButton'"
                  [disabled]="editForm.invalid"
                  (click)="addEvent()"
                  >{{ 'schedule.dateTimeSelector.addToCalendarBtn' | transloco }}</jui-button
                >
                <jui-button
                  size="sm"
                  *ngIf="!isExistingEventStartVisible"
                  color="secondary"
                  [attr.data-qa]="'goToEventButton'"
                  (click)="goToCurrentEvent()"
                  >{{ 'schedule.dateTimeSelector.goToEventBtn' | transloco }}</jui-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!hasScheduleAccess" class="access-error">
        <i class="material-icons">error</i>
        <span>{{ 'common.noAccess' | transloco }}</span>
      </div>
    </div>
  `
})
export class AppDateTimeDurationSelectComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent; // the #calendar in the template
  @ViewChild('eventTooltip') eventTooltip: ScheduleEventTooltipComponent; // the #calendar in the template
  @Input() form: UntypedFormGroup;
  @Input() question: ScheduleQuestion;
  @Input() project?: any;

  defaultDate: Date = undefined;
  calendarEvents: EventInput[] = [];
  allResources: Resource[] = [];
  resources: Resource[] = [];
  resourceFilter: string = null;
  currentEngineer: string = null;
  viewStart: string;
  viewEnd: string;
  existingEvent: EventInput;
  existingJobId?: string;
  jobType?: string;
  hasScheduleAccess: boolean;
  eventConstraint: EventInput | EventInput[] = undefined;
  allDayTranslation: string;
  newEventTranslation: string;
  startTimeTranslation: string;
  endTimeTranslation: string;
  scheduleEventTypeList;
  tenant: string;
  selectedTags: string[] = [];
  allTags: string[];

  timeSlots = TIME_SLOTS;
  editForm: UntypedFormGroup;
  resourceFilterDisabled = false;
  datePickerPlaceholder = moment.localeData().longDateFormat('L');
  currentEventBackgroundStyle = CURRENT_EVENT_BACKGROUND;
  currentEventTextStyle = CURRENT_EVENT_TEXT;
  invalidScheduleInfoError = { 'invalid schedule info': true };
  isExistingEventStartVisible = true;

  calendarConfig: CalendarOptions = {
    ...standardCalendarConfiguration,
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      resourceTimeGridPlugin,
      resourceTimelinePlugin,
      momentPlugin,
      momentTimezonePlugin
    ],
    initialView: 'resourceTimelineWeek',
    resourceAreaHeaderContent: this.translocoService.translate('common.engineers'),
    resources: this.resources,
    events: this.calendarEvents,
    scrollTime: '00:00',
    eventDurationEditable: true,
    editable: false,
    resourceAreaWidth: '15em',
    contentHeight: '55vh',
    selectable: true,
    initialDate: this.defaultDate,
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    },
    eventConstraint: this.eventConstraint,
    selectConstraint: this.eventConstraint,
    resourceLabelDidMount: this.handleResourceRender.bind(this),
    stickyHeaderDates: true,
    stickyFooterScrollbar: true,
    select: this.select.bind(this),
    eventDrop: this.eventDrop.bind(this),
    eventResize: this.eventResize.bind(this),
    eventDidMount: this.handleEventRender.bind(this),
    viewDidMount: this.handleViewRender.bind(this),
    datesSet: this.handleDateChange.bind(this),
    dayCellContent: this.dayRender.bind(this),
    timeZone: moment().tz()
  };

  constructor(
    private apiService: ApiService,
    private spinnerService: NgxSpinnerService,
    private accessService: AccessService,
    private scheduleService: ScheduleService,
    private translocoService: TranslocoService,
    private fb: UntypedFormBuilder
  ) {}

  get startDateControl() {
    return this.editForm.get('startDate');
  }

  get endDateControl() {
    return this.editForm.get('endDate');
  }

  get startDate() {
    return this.startDateControl.value;
  }

  get endDate() {
    return this.endDateControl.value;
  }

  get startTimeControl() {
    return this.editForm.get('startTime');
  }

  get endTimeControl() {
    return this.editForm.get('endTime');
  }

  get engineerControl() {
    return this.editForm.get('engineer');
  }

  get formControls() {
    return this.editForm.controls;
  }

  get getMinEndDate(): NgbDateStruct {
    return this.startDate;
  }

  get getMaxStartDate(): NgbDateStruct {
    return this.endDate;
  }

  async ngOnInit(): Promise<void> {
    this.existingEvent = null;
    this.hasScheduleAccess = this.accessService.isFeatureAccessAllowed(PathwayFeature.ScheduleAccess);
    this.calendarConfig.locale = this.translocoService.getActiveLang();
    this.calendarConfig.views.resourceTimelineWeek.buttonText = this.translocoService.translate('common.week');
    if (!this.hasScheduleAccess) {
      return;
    }
    this.allDayTranslation = this.translocoService.translate('common.allDay');
    this.startTimeTranslation = this.translocoService.translate('schedule.formFields.startTime.label');
    this.endTimeTranslation = this.translocoService.translate('schedule.formFields.endTime.label');
    // This.question.resources also has the resources but this is neater
    // TODO Once deployed we could remove the `resources` section of all schedule and reschedule questions
    this.allResources = (await this.scheduleService.fetchEngineers()).all;
    this.getAllTags();
    this.filterResources();
    this.currentEngineer = this.question.assignedTo;
    this.calendarConfig.initialDate = this.question.scheduleDate
      ? this.question.scheduleDate
      : this.question.preferredDate
      ? new Date(this.question.preferredDate)
      : new Date();

    this.calendarComponent.getApi().gotoDate(this.calendarConfig.initialDate);
    for (const jobKey of Object.keys(this.project.jobs)) {
      if (this.project.jobs[jobKey].type === this.question.jobType) {
        this.existingJobId = this.project.jobs[jobKey].id;
      }
    }
    this.jobType = this.question.jobType || (this.project && this.project.projectType);
    if (this.question.eventConstraint) {
      if (typeof this.question.eventConstraint === 'string') {
        this.calendarConfig.eventConstraint = this.project[this.question.eventConstraint];
      } else {
        this.calendarConfig.eventConstraint = this.question.eventConstraint;
      }
    }
  }

  private initEditForm() {
    this.editForm = this.fb.group({
      engineer: [this.existingEvent ? this.existingEvent.resourceId : null, Validators.required],
      startDate: [
        this.existingEvent ? this.isoStringToDateStruct(this.existingEvent.startDate) : null,
        Validators.required
      ],
      endDate: [
        this.existingEvent ? this.isoStringToDateStruct(this.existingEvent.endDate) : null,
        Validators.required
      ],
      startTime: [
        this.existingEvent ? this.isoStringToTime(this.existingEvent.startDate) : this.timeSlots[0],
        Validators.required
      ],
      endTime: [
        this.existingEvent
          ? this.isoStringToTime(this.existingEvent.endDate)
          : this.timeSlots[this.timeSlots.length - 1],
        Validators.required
      ]
    });
    this.listenForFormChanges();
  }

  private listenForFormChanges(): void {
    const calendarApi = this.calendarComponent.getApi();

    this.editForm.valueChanges.subscribe(val => {
      if (this.existingEvent) {
        const currentCalendarEvent = calendarApi.getEventById(this.existingEvent.id);

        if (currentCalendarEvent) {
          if (!val.startDate || !val.endDate || !val.startTime || !val.endTime) {
            this.form.get(this.question.key).setErrors(this.invalidScheduleInfoError);
            return;
          }

          this.validateEngineerExists(val);
          this.validateSameDayTimes(val);

          const start = this.formDateTimesToJsDate(val.startDate, val.startTime);
          const end = this.formDateTimesToJsDate(val.endDate, val.endTime);
          const selectedEngineer = this.allResources.filter(eng => eng.id === val.engineer)?.pop();
          const resource = selectedEngineer ? `${selectedEngineer.title}|${selectedEngineer.id}` : null;

          this.updateExistingEventDetails(start, end, selectedEngineer, val);
          this.renderCurrentCalendarEvents();
          this.notifyJobChange(start, end, resource, true);
          this.checkExistingEventStartVisible();
        }
      }
      this.validateParentForm();
    });
  }

  private validateEngineerExists(formValue) {
    const engineerInResources = this.allResources.find(eng => eng.id === formValue.engineer);
    if (!engineerInResources) {
      this.editForm.setErrors({ engineerNotFound: true });
    }
  }

  private checkExistingEventStartVisible() {
    const calendarApi = this.calendarComponent.getApi();
    const startMoment = moment(this.existingEvent.startDate);
    const calStartMoment = moment(calendarApi.view.activeStart);
    const calEndMoment = moment(calendarApi.view.activeEnd);

    this.isExistingEventStartVisible = startMoment.isBetween(calStartMoment, calEndMoment);
  }

  public goToCurrentEvent() {
    this.calendarComponent.getApi().gotoDate(this.existingEvent.startDate);
  }

  private updateExistingEventDetails(start: Date, end: Date, selectedEngineer: Resource, val): void {
    this.existingEvent.startDate = start.toISOString();
    this.existingEvent.endDate = end.toISOString();
    this.existingEvent.end = end;
    this.existingEvent.start = start;
    if (selectedEngineer) {
      this.existingEvent.assignedTo = val.engineer;
      this.existingEvent.assignedToDisplayName = selectedEngineer.title;
      this.existingEvent.resourceId = val.engineer;
    }
  }

  private renderCurrentCalendarEvents(): void {
    const updatedEvents = this.calendarEvents.map(x =>
      x.id === this.existingEvent.id ? { ...this.existingEvent } : x
    );

    if (this.existingEvent.id === TEMP_EVENT_ID) {
      const tempEventFound = updatedEvents.find(x => x.id === TEMP_EVENT_ID);
      if (!tempEventFound) {
        updatedEvents.push(this.existingEvent);
      }
    }
    this.calendarConfig.events = updatedEvents;
    if (this.existingEvent.id) {
      setTimeout(() => {
        const resource = {
          id: this.existingEvent.resourceId,
          title: this.existingEvent.extendedProps.assignedToDisplayName
        };
        this.rerenderExistingCalendarEvent(this.existingEvent.start, this.existingEvent.end, resource );
      });
    }
  }

  private validateSameDayTimes(formValue): void {
    const startMoment = moment(this.existingEvent.startDate);
    const endMoment = moment(this.existingEvent.endDate);

    if (startMoment.isSame(endMoment, 'day')) {
      if (parseInt(formValue.endTime.id) <= parseInt(formValue.startTime.id)) {
        this.editForm.setErrors({ eventEndTimeBeforeStartTime: true });
      }
    }
  }

  private validateParentForm(): void {
    if (this.editForm.invalid) {
      this.form.get(this.question.key).setErrors(this.invalidScheduleInfoError);
    } else {
      if (this.existingEvent) {
        this.form.get(this.question.key).setErrors(null);
      }
    }
  }

  private formDateTimesToJsDate(date, time): Date {
    return new Date(date.year, date.month - 1, date.day, time.name.split(':')[0], time.name.split(':')[1]);
  }

  public addEvent(): void {
    const start = this.formDateTimesToJsDate(this.startDate, this.startTimeControl.value);
    const end = this.formDateTimesToJsDate(this.endDate, this.endTimeControl.value);
    const startDate = start.toISOString();
    const endDate = end.toISOString();

    const selectedEngineer = this.allResources.filter(eng => eng.id === this.engineerControl.value)?.pop();

    this.newEventTranslation = this.translocoService.translate('common.newEvent');
    const newEvent = createTemporaryEvent(
      {
        start,
        end,
        startDate,
        endDate,
        resource: { id: selectedEngineer.id, title: selectedEngineer.title },
        extendedProps: { background: this.currentEventBackgroundStyle, color: this.currentEventTextStyle }
      },
      this.newEventTranslation,
      this.question.jobType || (this.project && this.project.projectType)
    );
    this.calendarConfig.events = [...(this.calendarConfig.events as EventInput[]), newEvent];
    this.existingEvent = newEvent;
    this.calendarComponent.getApi().gotoDate(startDate);

    this.form.get(this.question.key).setErrors(null);
  }

  private isoStringToDateStruct(isoString: string): NgbDateStruct {
    if (isoString) {
      const date = new Date(isoString);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }
  }

  private isoStringToTime(isoString: string): DropDownElement {
    if (isoString) {
      const date = new Date(isoString);
      const hrs = pad(date.getHours());
      const mins = pad(date.getMinutes());
      return this.timeSlots.find(slot => slot.id === hrs + mins);
    }
  }

  private filterResources() {
    this.calendarConfig['resources'] = this.allResources.filter(
      r =>
        (!this.resourceFilter || r.title.toLowerCase().includes(this.resourceFilter)) &&
        (!this.selectedTags.length || this.selectedTags.find(t => r.tags?.includes(t)))
    );
  }

  dayRender(event) {
    if (!this.eventConstraint) {
      return;
    }

    if (!Array.isArray(this.eventConstraint)) {
      if (moment(event.date).isBetween(this.eventConstraint.start, this.eventConstraint.end, undefined, '[]')) {
        return;
      }
      event.el.setAttribute('class', `${event.el.getAttribute('class')} unavailable-date`);
    } else {
      for (const eventConstraint of this.eventConstraint) {
        if (moment(event.date).isBetween(eventConstraint.start, eventConstraint.end, undefined, '[]')) {
          return;
        }
        event.el.setAttribute('class', `${event.el.getAttribute('class')} unavailable-date`);
      }
    }
  }

  private async fetchEvents() {
    await this.spinnerService.show('scheduleModalSpinner');
    await this.spinnerService.show('editFormSpinner');
    try {
      this.tenant = (await this.accessService.getTenantConfig()).tenant;
      this.scheduleEventTypeList = await this.scheduleService.getScheduleEventTypes(this.tenant);
      const allEvents = await this.apiService.listCalendarEvents(this.viewStart, this.viewEnd).toPromise();
      let existingEventAdded = false;
      const events = allEvents
        .filter(evt => {
          return this.scheduleService.isValidEventType(evt.eventType);
        })
        .map(evt => {
          const isExistingEvent = evt.id === this.existingJobId;
          const eventType = this.scheduleService.getEventType(evt.eventType);
          const event = generateEvent(evt, isExistingEvent, eventType?.colour, eventType?.textColour);
          if (isExistingEvent) {
            existingEventAdded = true;
            if (this.existingEvent) {
              // if we've already got an updated version, ensure we use this one
              this.currentEngineer = `${this.existingEvent.assignedToDisplayName}|${this.existingEvent.assignedTo}`;
              return this.existingEvent;
            }
            this.existingEvent = event;
            this.existingEvent.extendedProps = {
              background: this.currentEventBackgroundStyle,
              color: this.currentEventTextStyle
            };
            this.currentEngineer = `${this.existingEvent.assignedToDisplayName}|${this.existingEvent.assignedTo}`;
          }
          return event;
        });
      if (!existingEventAdded && this.existingEvent) {
        events.push(this.existingEvent);
      }
      if (this.question.preferredDate) {
        if (moment(this.question.preferredDate).isBetween(this.viewStart, this.viewEnd, undefined, '[]')) {
          const preferredEvent: EventInput = {
            title: this.translocoService.translate('common.preferred'),
            start: TimeSlotHelper.getStart(new Date(this.question.preferredDate), this.question.preferredTimeslot),
            end: TimeSlotHelper.getEnd(new Date(this.question.preferredDate), this.question.preferredTimeslot),
            allDay: !this.question.preferredTimeslot,
            classNames: ['preferred-date'],
            rendering: 'background'
          };
          events.push(preferredEvent);
        }
      }
      this.calendarConfig.events = events;
      this.calendarEvents = events;
      this.initEditForm();
    } catch (e) {
      console.error('Unable to access job list', e);
    }
    await this.spinnerService.hide('scheduleModalSpinner');
  }

  async handleEventRender(info: EventInfo) {
    await this.scheduleService.handleEventRender(info);
    if (info.event.extendedProps.background) {
      info.el.style.background = info.event.extendedProps.background;
    }
    if (info.event.extendedProps.color) {
      info.el.style.color = info.event.extendedProps.color;
    }
    this.calendarComponent.getApi().updateSize();
  }

  handleViewRender(view) {
    this.resourceFilterDisabled = view.view.type === 'dayGridMonth';
  }

  async handleDateChange(info: ViewInfo) {
    this.viewStart = info.view.activeStart.toISOString();
    this.viewEnd = info.view.activeEnd.toISOString();
    await this.fetchEvents();
  }

  private notifyJobChange(start: DateInput, end: DateInput, resource?: string, isEditFormChange?: boolean) {
    this.form.get(this.question.key).patchValue({
      start,
      end,
      assignedTo: resource || this.currentEngineer,
      jobType: this.jobType
    });
    if (this.existingEvent) {
      this.existingEvent.start = start;
      this.existingEvent.end = end;
      const startIso = (start as Date).toISOString();
      const endIso = (end as Date).toISOString();

      // update the start/end dates and patch the edit form
      this.existingEvent.startDate = startIso;
      this.existingEvent.endDate = endIso;

      if (!isEditFormChange) {
        this.editForm.patchValue({
          startDate: this.isoStringToDateStruct(startIso),
          startTime: this.isoStringToTime(startIso),
          endDate: this.isoStringToDateStruct(endIso),
          endTime: this.isoStringToTime(endIso),
          engineer: resource ? resource.split('|').pop() : this.engineerControl.value
        });
      }
    }
  }

  eventResize(info: EventInfo) {
    const { event } = info;
    this.notifyJobChange(event.start, event.end);
  }

  eventDrop(info: EventDropInfo) {
    const { event, newResource } = info;
    if (newResource) {
      this.currentEngineer = `${newResource.title}|${newResource.id}`;
      this.notifyJobChange(event.start, event.end, this.currentEngineer);
    } else {
      this.notifyJobChange(event.start, event.end);
    }
    this.handleEventRender(info).then();
  }

  select(evt: DateRangeSelectInfo) {
    const { start, end, resource } = validateEvent(evt, this.existingEvent, this.question);
    if (!resource) {
      return;
    }
    this.currentEngineer = `${resource.title}|${resource.id}`;
    if (this.existingEvent) {
      const newEvent = { ...this.existingEvent, resourceId: resource.id, start: start, end: end };
      this.calendarConfig.events = [
        ...(this.calendarConfig.events as EventInput[]).filter(e => e.id !== this.existingEvent.id),
        newEvent
      ];
      this.existingEvent = newEvent;
      this.rerenderExistingCalendarEvent(start, end, resource);
    } else {
      this.newEventTranslation = this.translocoService.translate('common.newEvent');
      const newEvent = createTemporaryEvent(
        {
          start,
          end,
          resource,
          extendedProps: { background: this.currentEventBackgroundStyle, color: this.currentEventTextStyle }
        },
        this.newEventTranslation,
        this.question.jobType || (this.project && this.project.projectType)
      );
      this.calendarConfig.events = [...(this.calendarConfig.events as EventInput[]), newEvent];
      this.existingEvent = newEvent;
    }
    this.notifyJobChange(start, end, this.currentEngineer);
  }

  private rerenderExistingCalendarEvent(start: Date | DateInput, end: Date | DateInput, resource: Resource) {
    const newEvt = createTemporaryEvent(
      {
        start,
        end,
        resource,
        extendedProps: {
          background: this.currentEventBackgroundStyle,
          color: this.currentEventTextStyle,
          userIds: [resource.id],
          eventType: 'Job',
          address: this.existingEvent.address ?? null
        }
      },
      this.newEventTranslation,
      this.question.jobType || (this.project && this.project.projectType)
    );
    const currentEl = document.querySelector('.jt-current-event');
    const evtInfo = {
      newResource: null,
      oldResource: null,
      event: newEvt,
      jsEvent: null,
      view: this.calendarComponent.getApi().view,
      el: currentEl
    } as unknown as EventInfo;
    this.handleEventRender(evtInfo).then();
  }

  resourceFilterKeyUp($event: Event) {
    const target = $event.target;
    if (target instanceof HTMLInputElement) {
      this.resourceFilter = target.value.toLowerCase();
      Analytics.logEvent('ResourceSearch', { query: this.resourceFilter });
      this.filterResources();
    }
  }

  onChangeTags(tags: string[]) {
    this.selectedTags = tags;
    this.filterResources();
  }

  handleResourceRender(evt: ResourceInfo) {
    const { resource, el } = evt;
    // const elements: HTMLCollection = el.getElementsByClassName('fc-cell-content');
    const elements: HTMLCollection = el.getElementsByClassName('fc-datagrid-cell-main');
    const cellContents = elements[0];
    const tags = resource._resource.extendedProps.tags?.map(tag => `<span class="tag">${tag}</span>`).join(' ') ?? '';
    cellContents.outerHTML = `
      <div class="resource-cell">
        ${resource._resource.title}
        <div class="tags">${tags}</div>
      </div>`;
  }

  private getAllTags() {
    const tags = new Set<string>();
    for (const resource of this.allResources) {
      for (const tag of resource.tags ?? []) {
        tags.add(tag);
      }
    }
    this.allTags = [...tags].sort();
  }
}
