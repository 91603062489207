import { Injectable } from '@angular/core';
import { RelayRepository } from '../../relay.repository';
import { ActionsViewModel } from './actions.view.model';

@Injectable()
export class ActionPresenter {
  constructor(private relayRepository: RelayRepository) {}
  load(readOnly: boolean, callback) {
    let vm: ActionsViewModel;
    this.relayRepository.getCurrentCard(relayCard => {
      vm = {
        newStyle: relayCard.newStyle,
        hasSubmit: readOnly ? false : relayCard?.hasSubmit,
        submitLabel: relayCard?.submitLabel,
        hasPrevious: relayCard?.hasPrevious && !relayCard.newStyle,
        previousLabel: relayCard?.previousLabel,
        hasNext: relayCard?.hasNext,
        nextLabel: relayCard?.nextLabel,
        nextDisabled: readOnly ? false : relayCard?.nextDisabled,
        submitDisabled: relayCard?.submitDisabled,
        hasReset: false,
        showNav: false
      };
      vm.showNav = vm.hasPrevious || vm.hasNext || vm.hasSubmit;
      callback(vm);
    });
  }

  async previous(readOnly = false) {
    await this.relayRepository.previousCard(readOnly);
  }

  async next(readOnly = false) {
    await this.relayRepository.nextCard(false, readOnly);
  }

  async submit(readOnly = false) {
    await this.relayRepository.nextCard(true, readOnly);
  }

  async reset() {
    await this.relayRepository.reset();
  }
}
