import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { IStandardQuotesVm, StandardData } from '../../../domain';
import { StandardQuotesRepository } from '../../standard-quotes.repository';

@Injectable()
export class StandardQuotesPresenter {
  constructor(private repository: StandardQuotesRepository, private translocoService: TranslocoService) {}

  async load(callback) {
    const vm: IStandardQuotesVm = {
      titleLabel: this.translocoService.translate('standardData.quotes.title'),
      nameLabel: this.translocoService.translate('standardData.quotes.name'),
      projectTypeLabel: this.translocoService.translate('standardData.quotes.projectType'),
      standardQuotes: [],
      noDataErrorMessage: this.translocoService.translate('standardData.quotes.noData'),
      pageSize: 20
    };
    await this.repository.listStandardQuotes(results => {
      vm.standardQuotes = results;
    });
    await callback(vm);
  }

  async filterStandardQuotes(searchTerm: string) {
    await this.repository.filterStandardQuotes(searchTerm);
  }

  async addStandardQuote() {
    await this.repository.createStandardQuoteModal();
  }

  async editStandardQuote(standardData: StandardData) {
    await this.repository.updateStandardQuoteModal(standardData);
  }

  async deleteStandardQuote(id: string) {
    await this.repository.deleteStandardQuote(id);
  }
}
