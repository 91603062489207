<div class="card sub-tenants-card">
  <div class="filters">
    <div class="filters__item">
      <div class="filters__control">
        <label>{{ 'common.name' | transloco }}</label>
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="{{ 'subTenant.placeholders.filterByName' | transloco }}"
          [attr.data-qa]="'nameFilter'"
          (keyup)="updateFilter($event, 'name')"
        />
      </div>
    </div>
    <div class="filters__item">
      <div class="filters__control">
        <label>{{ 'common.contact' | transloco }}</label>
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="{{ 'subTenant.placeholders.filterByContact' | transloco }}"
          [attr.data-qa]="'contactFilter'"
          (keyup)="updateFilter($event, 'contact')"
        />
      </div>
    </div>
    <div class="filters__item">
      <div class="filters__control">
        <label>{{ 'common.email' | transloco }}</label>
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="{{ 'subTenant.placeholders.filterByEmail' | transloco }}"
          [attr.data-qa]="'emailFilter'"
          (keyup)="updateFilter($event, 'email')"
        />
      </div>
    </div>
    <div class="filters__item">
      <div class="filters__control">
        <label>{{ 'subTenant.fields.associatedTenant' | transloco }}</label>
        <app-multi-select
          [items]="tenantList"
          placeholder="{{ 'subTenant.placeholders.filterByAssociatedTenant' | transloco }}"
          bindId="id"
          bindLabel="id"
          allowSearchFilter="true"
          [singleSelection]="false"
          [clearable]="true"
          [attr.data-qa]="'tenantFilter'"
          (itemsChanged)="updateFilterFromSelect($event, 'tenant')"
        ></app-multi-select>
      </div>
    </div>
    <div class="filters__item">
      <div class="filters__control">
        <jui-button size="sm" color="secondary" [attr.data-qa]="'addSubTenantButton'" (click)="openSubTenantModal()">
          <span slot="icon" class="material-icons">add</span>
          <span>{{ 'common.addSubTenant' | transloco }}</span>
        </jui-button>
      </div>
    </div>
  </div>

  <ngx-datatable
    #subTenantTable
    [rows]="rows"
    class="bootstrap"
    columnMode="force"
    rowHeight="50"
    headerHeight="40"
    footerHeight="50"
    reorderable="false"
    [limit]="rowLimit"
  >
    <ngx-datatable-column
      name="{{ 'userManagement.name' | transloco }}"
      prop="configuration.name"
      [resizeable]="false"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'common.contact' | transloco }}"
      prop="configuration.tenantConfiguration.installerContact"
      [resizeable]="false"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'common.email' | transloco }}"
      prop="configuration.tenantConfiguration.installerEmail"
      [resizeable]="false"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'subTenant.fields.associatedTenant' | transloco }}"
      prop="srcTenant"
      [resizeable]="false"
    ></ngx-datatable-column>
    <ngx-datatable-column name="{{ 'common.actions' | transloco }}" [sortable]="false" [resizeable]="false">
      <ng-template ngx-datatable-cell-template let-row="row">
        <jui-icon
          titleText="{{ 'Buttons.editSubTenant.title' | transloco }}"
          size="xs"
          name="edit"
          (click)="openSubTenantModal(row)"
          [attr.data-qa]="'editSubTenantButton'"
        >
        </jui-icon>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage">
        <div class="table-data">
          <div class="table-data__size">{{ 'common.pageSize' | transloco }}</div>
          <select class="form-control form-control-sm table-data__size-ctrl" (change)="rowLimit = $event.target.value">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25" selected>25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <span class="table-data__count">
            {{ rowCount.toLocaleString() }}
            <ng-container *ngIf="rowCount == 1; else singleResult">{{ 'common.result' | transloco }}</ng-container>
            <ng-template #singleResult>{{ 'common.results' | transloco }}</ng-template>
          </span>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="subTenantTable.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>

  <ngx-spinner
    bdColor="var(--jds-theme-spinner-bd-rgba)"
    size="large"
    [name]="spinnerName"
    type="line-scale"
    [attr.data-qa]="'spinner'"
  >
  </ngx-spinner>
</div>
