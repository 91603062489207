import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { I18nKeys } from '../../domain/i18n-keys';
import { DocumentViewerModalComponent } from '../modals/document-viewer-modal.component';
import { BaseQuestionComponent } from '../question.component';
import { ScrollService } from '../scroll.service';

@Component({
  selector: `crds-question-doc-viewer`,
  template: `
    <div *ngIf="show && form" [formGroup]="form" class="view-document">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key"
        >{{ question.label }}:
        <span *ngIf="question.required"> *</span>
        <crds-form-error
          *ngIf="isInvalid"
          [question]="question"
          [message]="question.errorMessage"
          [i18ns]="i18ns"
        ></crds-form-error>
      </label>
      <button (click)="showDocument()" type="button" class="btn btn-primary view-document-button" #viewDocumentButton>
        <span>{{ question.buttonLabel || i18ns.viewDocument }}</span>
        <i class="material-icons button-icon">{{ question.buttonIcon || 'description' }}</i>
      </button>
      <ngx-spinner name="document-loader" bdColor="rgba(51, 51, 51, 0.8)" size="large" type="line-scale"> </ngx-spinner>
    </div>
  `,
  styles: [
    `
      .material-icons {
        color: #ffffff;
      }
      .view-document {
        padding-bottom: 0.6rem;
      }
      .view-document-button > span {
        line-height: 2rem;
        padding-right: 0.5rem;
        vertical-align: middle;
      }
      .view-document-button > .material-icons {
        vertical-align: middle;
      }
    `
  ]
})
export class DocumentViewerQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @ViewChild('viewDocumentButton') input;

  constructor(
    private spinnerService: NgxSpinnerService,
    private scrollService: ScrollService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.scrollService.scrollObservable.subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get value() {
    return this.form?.get(this.question.key)?.value;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.form?.get(this.question.key)?.value) &&
      showIfQuestionPopulated &&
      this.question.show !== false
    );
  }

  showDocument() {
    const documentViewerRef = this.modalService.open(DocumentViewerModalComponent);
    documentViewerRef.componentInstance.url = this.value;
    documentViewerRef.componentInstance.config = {
      title: this.question.buttonLabel,
      confirm: this.i18ns.closeBtn
    };
  }
}
