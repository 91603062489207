import { Injectable } from '@angular/core';

@Injectable()
export class MimeTypeService {
  isAllowedFileType(file: File, accept: string) {
    if (accept && file) {
      // Get the file extension with a leading dot
      const fileExt = `.${file.name.split('.').pop()}`;
      // each type could be an extension, or a mimeType so try those in order
      const typesToCheck = accept.split(',').map(t => t.trim());
      for (const typeToCheck of typesToCheck) {
        if (fileExt === typeToCheck) {
          return true;
        }
        // Try wildcard match
        if (typeToCheck.endsWith('/*') && file.type.startsWith(typeToCheck.slice(0, -2))) {
          return true;
        }
        // Try exact match
        if (file.type === typeToCheck) {
          return true;
        }
      }
      return false;
    }
    return true; // if there's no accept filter, all files are fine
  }
}
