import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { User, UserPreferenceType } from '../domain/user';
import { LocaleService } from '@jump-tech-frontend/app-config';
import * as Analytics from '../../app.analytics';
import { ProjectConfigurationService } from '../project-configuration.service';
import { HomeService } from '../home.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { RouteAccessService } from '../../auth/services/route-access.service';
import { PathwayConfigurationService } from '../../auth/services/pathway-configuration.service';
import { UserService } from '../../auth/services/user.service';
import { UserPreferencesService } from '../user-preferences.service';
import { TranslocoService } from '@ngneat/transloco';
import { menuItem } from './menu-item/menu-item.component';
import { PROJECTS_PATH } from '../../app.routes';
import { UserThemeService } from '../user-theme.service';
import { EnvironmentService } from '../../../environments/environment.service';
import { ApiEnvironmentListItem } from '../../../environments/pathway-api-environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  @Input() isSmallScreen: boolean;
  @Input() isPreview: boolean;
  @Input() isLocal: boolean;
  @Input() branchName: string;
  @Input() branchShortName: string;
  @Output() navToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onBranchReset: EventEmitter<void> = new EventEmitter<void>();
  menuOpen = true;
  isClosed = false;
  logo = null;
  activeUrl: string = null;
  private user: User;
  PathwayFeature = PathwayFeature;
  prefetching$: Observable<boolean>;
  isPrefetchingProjects = false;
  navPreference = UserPreferenceType.NavShrink;
  menuList: menuItem[];
  hasProjectsAccess = false;
  activeTheme: string;
  logoSrc = 'assets/Jumptech-Digital-Logo-V2-512px.png';
  apiEnvironmentName: string;
  apiEnvironmentList: ApiEnvironmentListItem[];
  selectedApiEnvironment: ApiEnvironmentListItem;


  ngOnInit() {
    this.isClosed = this.userPref.getUserPreference(this.navPreference);
    if (!this.isUserSignedIn) {
      this.isClosed = false;
    }

    this.userService.userObservable.subscribe(() => {
      this.isClosed = this.userPref.getUserPreference(this.navPreference);
    });

    this.router.events.subscribe((event: unknown) => {
      if (event?.['url']) {
        this.activeUrl = event['url'].toString();
      }
    });

    this.apiEnvironmentName = this.environmentService.getCurrentApiEnvironment().name;
    this.apiEnvironmentList = this.environmentService.getApiEnvironmentList();
    this.selectedApiEnvironment = this.apiEnvironmentList.find(env => env.id === this.apiEnvironmentName);
  }

  onNavToggle() {
    this.isClosed = !this.isClosed;
    this.navToggle.emit(this.isClosed);
  }

  onChangeEnvironment() {
    this.apiEnvironmentName = this.selectedApiEnvironment.id;
    this.environmentService.setApiEnvironment(this.apiEnvironmentName);
  }

  isRouteActive(route: string): boolean {
    return this.router.url === route;
  }
  constructor(
    private authenticationService: AuthenticationService,
    private routeAccessService: RouteAccessService,
    private router: Router,
    private configurationService: PathwayConfigurationService,
    private projectConfigurationService: ProjectConfigurationService,
    private userService: UserService,
    private featureAccessService: AccessService,
    private homeService: HomeService,
    private userPref: UserPreferencesService,
    public localeService: LocaleService,
    public translocoService: TranslocoService,
    private themeService: UserThemeService,
    private environmentService: EnvironmentService
  ) {
    this.userService.userObservable.subscribe((user: User) => {
      if (user !== null) {
        this.user = user;
        this.buildMenuList();
      }
    });
    this.configurationService.customerLogoObservable.subscribe(logo => {
      this.logo = logo;
    });

    this.prefetching$ = this.homeService.prefetching$;
    // todo unsub
    this.homeService.prefetching$.subscribe(x => (this.isPrefetchingProjects = x));
    this.themeService.theme$.subscribe(theme => {
      this.activeTheme = theme;
    });
  }

  get isUserSignedIn() {
    return this.authenticationService.isUserSignedIn;
  }

  isAccessAllowed(route: string, allowAdmin = false) {
    return this.routeAccessService.isRouteAccessAllowed(route, allowAdmin);
  }

  isSuperAdmin() {
    return this.user && this.user.isSuperAdmin;
  }

  openExternalLink(url) {
    Analytics.logNavigation(url);
    window.open(url, '_blank');
  }

  buildMenuList() {
    this.hasProjectsAccess = this.routeAccessService.isRouteAccessAllowed(PROJECTS_PATH, false);
    this.menuList = [
      {
        label: this.translocoService.translate('navbar.dashboard'),
        icon: 'dashboard',
        qaHook: 'menuItemDashboard',
        hasAccess: this.isAccessAllowed('insights'),
        routeLink: '/dashboard',
        isBetaFeature: true
      },
      {
        label: this.translocoService.translate('navbar.searchProjects'),
        icon: 'search',
        qaHook: 'menuItemSearchProjects',
        hasAccess: this.isAccessAllowed('projects-search'),
        routeLink: '/projects-search'
      },
      {
        label: this.translocoService.translate('navbar.schedule'),
        icon: 'today',
        qaHook: 'menuItemSchedule',
        hasAccess: this.isAccessAllowed('schedule'),
        routeLink: '/schedule'
      },
      {
        label: this.translocoService.translate('navbar.map'),
        icon: 'room',
        qaHook: 'menuItemPlanningMap',
        hasAccess: this.isAccessAllowed('planning-map'),
        routeLink: '/planning-map',
        isBetaFeature: false
      },
      {
        label: this.translocoService.translate('navbar.reports'),
        icon: 'bar_chart',
        qaHook: 'menuItemReports',
        hasAccess: this.isAccessAllowed('reports'),
        routeLink: '/reports'
      },
      {
        label: this.translocoService.translate('navbar.reports'),
        icon: 'bar_chart',
        qaHook: 'menuItemReports',
        hasAccess: true,
        routeLink: '/reports-beta',
        isBetaFeature: true,
        useFeatureToggle: 'beta-reports'
      },
      {
        label: this.translocoService.translate('navbar.userManagement'),
        icon: 'group',
        qaHook: 'menuItemUserManagement',
        hasAccess: this.isAccessAllowed('user-management', true),
        routeLink: '/user-management'
      },
      {
        label: this.translocoService.translate('navbar.settings'),
        icon: 'settings',
        qaHook: 'menuItemConfiguration',
        hasAccess: this.isAccessAllowed('configuration', true),
        routeLink: '/configuration'
      },
      {
        label: this.translocoService.translate('navbar.support'),
        icon: 'contact_support',
        qaHook: 'menuItemSupport',
        hasAccess: true,
        externalLink: 'https://support.jumptech.co.uk/'
      }
    ];
  }
}
