import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { diff } from 'deep-object-diff';
import { get, merge as lodashMerge } from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

import { BehaviorSubject, combineLatest, filter, retryWhen, Subscription, switchMap, timer } from 'rxjs';
import { ProjectFilter, ProjectStatus } from '../admin/user-management/domain/types';
import * as Analytics from '../app.analytics';

import { PROJECTS_PATH } from '../app.routes';
import { AccessService } from '../auth/services/access.service';
import { PathwayConfigurationService } from '../auth/services/pathway-configuration.service';
import { UserService } from '../auth/services/user.service';

import { ApiService } from '../core/api.service';

import { AuditLog, AuditLogStatus } from '@jump-tech-frontend/domain';
import { Project } from '../core/domain/project';
import { LayoutDisplayCriteria, ProjectCardLayout } from '../core/domain/project-card-layout';
import { ProjectConfiguration, ProjectState } from '../core/domain/project-configuration';
import { ProjectDlType } from '../core/domain/project-dl.type';
import { HomeService } from '../core/home.service';
import { LayoutUpdateService } from '../core/layout-update.service';
import { LocalStorageGateway } from '../core/local-storage-gateway.service';
import { ProjectConfigurationService } from '../core/project-configuration.service';
import { ProjectOwnerService } from '../core/project-owner.service';
import { ProjectUpdateService } from '../core/project-update.service';
import { checkIfExpression } from '../core/utils/filter';
import { createRenderer } from '../core/utils/mustache.renderer';
import { LoggerService } from '../error/logger.service';

import { FirstCharsPipe } from '../shared/pipes/first-chars.pipe';
import { RemoveUnderscorePipe } from '../shared/pipes/remove-underscore.pipe';
import {
  ProgressIndicatorState,
  ProgressIndicatorStatus
} from '../shared/progress-indicator/progress-indicator.component';
import { ToasterService } from '../toast/toast-service';
import {
  AUTO_ADD_DOC_PACK_FEATURE_KEY,
  DOC_PACK_MANAGER_LD_FEATURE_KEY,
  IDocumentPackManagerConfig,
  LINKED_PROJECTS_LD_FEATURE_KEY
} from './document-pack/document-pack.model';
import { JobSummaryItem } from './jobs/jobs.model';

import { Note } from './notes/note';
import { ProjectAttachment } from './project-attachments/project-attachments.component';
import { RelayComponent } from './relay/relay.component';
import { FeatureFlagService } from '../core/feature-flag/feature-flag.service';
import { OrderFulfilment, OrderPartStatus, OrderResource, ProductResource } from './my-orders/my-orders.model';

export interface ExpandedCardLayout extends ProjectCardLayout {
  data: {
    [key: string]: any;
  };
}

export interface ExpandedCardLayoutCollection {
  editFilter?: boolean | ProjectFilter[];
  layouts: ExpandedCardLayout[];
}

export interface TabLayout extends LayoutDisplayCriteria {
  tabName: string;
  tabIcon: string;
  layouts: ProjectCardLayout[];
  editFilter?: boolean | ProjectFilter[];
  type?: string;
}

export interface DisplayTabLayout extends LayoutDisplayCriteria {
  tabName: string;
  tabIcon: string;
  layouts: ExpandedCardLayoutCollection[];
  editFilter?: boolean | ProjectFilter[];
  type?: string;
}

export enum TaskStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export interface Task {
  id: string;
  task: string;
  filter?: any[];
  status: TaskStatus;
  questions?: any[];
  tenant?: string;
  description?: string;
  taskOptions?: string[];
  due_by?: Date;
  assigned_to?: string;
  updated_on?: Date;
  updated_by?: string;
  created_on?: Date;
  created_by?: string;
  removable?: boolean;
}

interface StatusChange {
  status: string;
  date: Date;
  actionedBy: string;
  stamp: string;
  show: boolean;
}

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
  providers: [RemoveUnderscorePipe, FirstCharsPipe]
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
  project: Project = null;
  tenant: string;
  auditLogs: AuditLog[] | null = [];
  jobSummaryItems: JobSummaryItem[] = [];

  statusChanges: StatusChange[] = [];
  currentStatusPosition = 0;
  statusChangeTypes = ['PROJECT_STATUS_CHANGE', 'JOB_STATUS_CHANGE'];
  changeEventName = 'status change';

  delayWsUpdate$ = new BehaviorSubject(false);
  downloadTypes = ProjectDlType;
  additionalDownloads = [];
  isPrimaryOwner = false;
  archiveReason = null;
  spinnerName = 'ProjectDetail'; // todo - when we remove the spinner well want to let regression know when page is ready
  appName = 'Pathway';
  commercialMarket = 'Commercial';

  tabLayouts: DisplayTabLayout[] = [];
  projectStates: ProjectState[] = [];
  projectState: ProjectState = null;
  projectProgress: ProgressIndicatorState[] = [];
  projectConfiguration: ProjectConfiguration = null;
  dockTasks = false;
  isCommercialMultiJob = false;
  userLayoutProjectDetailsLeft = 'USER_PREF--LAYOUT-PD-LEFT';
  userPrefLayoutLeft = ['summary', 'jobs', 'tasks', 'document-pack', 'linked-projects'];

  defaultStatesNumber = 12;
  quietReloadLinkedProjects = false;

  progressCircleSize = '40px';
  progressLabelSize = '65px';

  showAuditLogsLoader = true;
  showTabsLoader = true;
  showSummaryLoader = true;
  showJobsLoader = true;

  showTasks = false;

  hasDocumentPackDefinition = false;
  documentPackConfig: IDocumentPackManagerConfig;
  DOC_PACK_MANAGER_LD_FEATURE_KEY = DOC_PACK_MANAGER_LD_FEATURE_KEY;
  AUTO_ADD_DOC_PACK_FEATURE_KEY = AUTO_ADD_DOC_PACK_FEATURE_KEY;
  LINKED_PROJECTS_LD_FEATURE_KEY = LINKED_PROJECTS_LD_FEATURE_KEY;
  HARDWARE_ORDERING_LD_FEATURE_KEY = 'hardware-ordering';

  autoAddDocumentPack: boolean;

  refreshOrders = false;
  canOrderProducts = false;
  productResources: ProductResource[] = null;
  orderResources: OrderResource[] = null;
  customerAddress = null;
  tenantAddress = null;
  isShippingOrder = false;

  /**
   * The current value of the subscribeToUpdates timeout.
   * Should be stored in order to cancel it during edits.
   */
  updateSubscription: Subscription;

  resources = {};
  infoMessage: string = null;
  offline = false;

  @ViewChild('layoutTabSet') layoutTabSet: NgbNav;
  @ViewChild('tabSetContainer') tabSetContainer: ElementRef<HTMLElement>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private modalService: NgbModal,
    private layoutUpdateService: LayoutUpdateService,
    private projectUpdateService: ProjectUpdateService,
    private spinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver,
    private pathwayConfigurationService: PathwayConfigurationService,
    private projectConfigurationService: ProjectConfigurationService,
    private loggerService: LoggerService,
    private projectOwnerService: ProjectOwnerService,
    private toasterService: ToasterService,
    private httpClient: HttpClient,
    private featureAccessService: AccessService,
    private titleService: Title,
    private homeService: HomeService,
    private userService: UserService,
    private localStorageGateway: LocalStorageGateway,
    private translocoService: TranslocoService,
    private featureFlagService: FeatureFlagService
  ) {
    combineLatest([this.route.params, this.route.queryParams], (routeParams, routeQueryParams) => ({
      routeParams,
      routeQueryParams
    })).subscribe(async join => {
      this.ngOnDestroy();
      this.initialise(join);
    });
  }

  private static getDataSource(layoutSpec) {
    return layoutSpec.dataSource === 'data' || layoutSpec.dataSource === 'imageData' ? 'data' : layoutSpec.dataSource;
  }

  async ngOnInit(): Promise<void> {
    const savedUserPrefLayoutLeft = JSON.parse(this.localStorageGateway.getItem(this.userLayoutProjectDetailsLeft));
    if (savedUserPrefLayoutLeft && this.userPrefLayoutLeft.length === savedUserPrefLayoutLeft.length) {
      this.userPrefLayoutLeft = [...savedUserPrefLayoutLeft];
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler() {
    this.ngOnDestroy();
  }

  @HostListener('window:online', ['$event']) onOnline() {
    this.subscribeToUpdates().then(() => console.log('Reconnected to WSS'));
    this.offline = false;
  }

  @HostListener('window:offline', ['$event']) onOffline() {
    this.offline = true;
  }

  reload() {
    if (this.project) {
      this.initialise({
        routeParams: {
          projectId: this.project.id
        },
        routeQueryParams: {
          o: false
        }
      });
    }
  }

  initialise(join: any) {
    this.hasDocumentPackDefinition = false;
    this.canOrderProducts = false;
    const projectId = join.routeParams['projectId'];
    this.quietReloadLinkedProjects = join.routeParams['linkProjectsQuietReload'];
    this.isPrimaryOwner = /true/i.test(join.routeQueryParams['o']);
    this.projectOwnerService.setForProjectId(projectId, this.isPrimaryOwner);
    this.fetchProject(projectId).then(() => {
      this.tenant = this.pathwayConfigurationService.tenant;
    });
    this.breakpointObserver
      .observe(['(min-width: 768px)', '(min-width: 992px)', '(min-width: 1200px)'])
      .subscribe((state: BreakpointState) => {
        // Dock the tasks if below md width
        this.progressCircleSize = state.breakpoints['(min-width: 992px)'] ? '40px' : '28px';
        this.progressLabelSize = state.breakpoints['(min-width: 992px)'] ? '65px' : '35px';
        this.dockTasks = !state.breakpoints['(min-width: 768px)'];
      });
  }

  setDetailTitle(project) {
    this.titleService.setTitle(
      `${project.data.firstName} ${project.data.lastName} - ${project.type} | ${this.appName}`
    );
    this.homeService.saveRouteHistory(
      this.router.url,
      `${project.data.firstName} ${project.data.lastName} - ${project.type}`
    );
  }

  ngOnDestroy() {
    this.unsubscribeWs();
  }

  private unsubscribeWs() {
    if (this.project) {
      this.projectUpdateService.close();
    }
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  private showLoaders() {
    this.showAuditLogsLoader = true;
    this.showTabsLoader = true;
    this.showSummaryLoader = true;
    this.showJobsLoader = true;
  }

  private hideLoaders(loaderName: string) {
    if (loaderName === 'auditLogs') {
      this.showAuditLogsLoader = false;
    }
    if (loaderName === 'tabs') {
      this.showTabsLoader = false;
    }
    if (loaderName === 'summary') {
      this.showSummaryLoader = false;
    }
    if (loaderName === 'jobs') {
      this.showJobsLoader = false;
    }
  }

  private async showSpinner() {
    await this.spinnerService.show(this.spinnerName);
  }

  private async hideSpinner() {
    await this.spinnerService.hide(this.spinnerName);
  }

  private async fetchProject(projectId: string) {
    if (!this.quietReloadLinkedProjects) {
      this.showTasks = false;
      this.showLoaders();
    }

    const project: Project = await this.apiService.getProject(projectId).toPromise();
    if (!project) {
      this.loggerService.log('Project not found');
      await this.router.navigate([PROJECTS_PATH], { replaceUrl: true });
    } else {
      this.project = { ...project };
      this.showTasks = true;
      this.setDetailTitle(project);
      this.setJobSummary();
      await this.setProjectConfiguration();
      this.setProjectResources();
      this.setAdditionalDownloads();
      await this.subscribeToUpdates();
      setTimeout(() => {
        this.layoutUpdateService.cancel();
        this.projectUpdateService.nextProjectUpdates(this.project);
        this.hideLoaders('jobs');
      });
    }
  }

  public hasArchiveReason() {
    return (
      this.isArchived && this.project.data && (this.project.data.archiveReason || this.project.data.reasonForArchive)
    );
  }

  public getArchiveStatusLabel() {
    const statusConfig: ProjectState = this.projectConfiguration.states.find(s => s.status === ProjectStatus.ARCHIVED);
    return statusConfig?.label?.toLowerCase() ?? ProjectStatus.ARCHIVED.toLowerCase();
  }

  public getArchiveReason() {
    if (this.project?.data?.archiveReason) {
      return this.project?.data?.archiveReason;
    }

    const projectArchiveReasonKey = this.project?.data?.reasonForArchive;
    const projectStatus = this.project.status.status;
    const statusConfig: ProjectState = this.projectConfiguration.states.find(s => s.status === projectStatus);

    for (const action of statusConfig.actions) {
      const item = action.confirmation?.layout?.items?.find(item => (item.content = '{reasonForArchive}'));

      if (item) {
        const reasonForArchiveOption = item.editConfig?.options?.find(option => option.key === projectArchiveReasonKey);
        if (reasonForArchiveOption) {
          // Return localised version of reasonForArchive
          return reasonForArchiveOption.value;
        }
      }
    }

    // Return non-localised version of reasonForArchive
    return projectArchiveReasonKey;
  }

  private async setProjectConfiguration(docPackShowLoading = true, docPackRefreshing = false) {
    this.projectConfiguration = await this.projectConfigurationService.getProjectConfiguration(this.project.type, true);

    this.autoAddDocumentPack = await this.autoCreateDocumentPack();

    this.initDocumentPacks(docPackShowLoading, docPackRefreshing);
    this.setState();
    this.setCardLayouts();
    this.setCommercialMultiJob();
    this.hideLoaders('tabs');
    this.hideLoaders('summary');
    await this.initOrders();
    await this.hideSpinner();
  }

  public initDocumentPacks(showLoading = true, isRefreshing = false) {
    if (this.projectConfiguration?.documentPackDefinition) {
      this.hasDocumentPackDefinition = true;
      this.documentPackConfig = {
        documentPackDefinition: this.projectConfiguration.documentPackDefinition,
        projectId: this.project.id,
        projectStates: this.projectConfiguration.states,
        currentProjectState: this.getStateByStatus(this.projectStatus).status,
        user: this.userService.currentUser,
        tenant: this.project.tenant,
        showLoading,
        isRefreshing
      };
      if (this.project.documentPackId) {
        this.documentPackConfig.autoAddInProgress = false;
        this.documentPackConfig.documentPackId = this.project.documentPackId;
      } else if (this.autoAddDocumentPack) {
        const secondsSinceProjectCreation = (new Date().getTime() - new Date(this.project.created_on).getTime()) / 1000;

        this.documentPackConfig.autoAddInProgress = secondsSinceProjectCreation < 12;
      }
    } else {
      this.hasDocumentPackDefinition = false;
    }
  }

  private async initOrders(refreshResources = false): Promise<void> {
    this.isShippingOrder = false;
    if (refreshResources) {
      this.refreshOrders = refreshResources;
    }

    const metaStatusReached = this.isHardwareOrderingMetaStatusReached();

    const productResources = this.project.resources.find(resource => resource.type === 'Products');
    if (productResources) {
      this.productResources = productResources.summary?.packages;
      this.orderResources = this.project.resources.filter(resource => resource.type === 'order');
      this.checkForOrderShippingStatus();
      // driver details
      this.customerAddress = this.project.data['address'];
      if (this.customerAddress) {
        this.customerAddress.firstName = this.project.data.firstName;
        this.customerAddress.lastName = this.project.data.lastName;
        this.customerAddress.email = this.project.data.email;
        this.customerAddress.phone = this.project.data.phoneNumber;
      }
      // default address
      const tenantConfig = await this.featureAccessService.getTenantConfig();
      this.tenantAddress = tenantConfig.address;
      this.tenantAddress.name = tenantConfig.installerContact;
      this.tenantAddress.company = tenantConfig.installer;
      this.tenantAddress.email = tenantConfig.installerEmail;
      this.tenantAddress.phone = tenantConfig.installerPhone;

      this.canOrderProducts = this.productResources && this.customerAddress && this.tenantAddress && metaStatusReached;
    }
  }

  private checkForOrderShippingStatus(): void {
    if (this.orderResources.length > 0) {
      // find most recent order and check for a Shipping status
      const openOrder: OrderResource = this.orderResources.sort(
        (o1: OrderResource, o2: OrderResource) => o2.summary.createdOn - o1.summary.createdOn
      )[0];

      openOrder.fulfilments?.forEach((f: OrderFulfilment): void => {
        if (f.fulfilmentOrderStatus === OrderPartStatus.SHIPPING) {
          this.isShippingOrder = true;
        }
      });
    }
  }

  private isHardwareOrderingMetaStatusReached(): boolean {
    // default show tab always
    let metaStatusReached = true;

    // if we have set a meta status, then only show tab when we have reached the meta status
    if (this.projectConfiguration.hardwareOrderingMetaStatus) {
      const currentStatusIndex = this.projectStates.findIndex(x => x.status === this.projectState.status);
      const orderDisplayStatusIndex = this.projectStates.findIndex(
        x => x.metaStatus === this.projectConfiguration.hardwareOrderingMetaStatus
      );
      metaStatusReached = currentStatusIndex >= orderDisplayStatusIndex;
    }
    return metaStatusReached;
  }

  public setCommercialMultiJob() {
    const isCommercial = this.projectConfiguration?.projectMarket === this.commercialMarket;
    const isMultiJobProject = this.projectConfiguration?.jobTypes?.length > 1;
    this.isCommercialMultiJob = isCommercial && isMultiJobProject;
  }

  public getStateByStatus(status: string): ProjectState | null {
    return this.projectConfiguration?.states.find(state => state.status === status) || null;
  }

  get projectStatus() {
    return this.project.status.status;
  }

  private setCardLayouts() {
    this.tabLayouts = this.projectConfiguration.layouts
      .filter(tl => this.showLayout(tl))
      .map(tl => ({ ...tl, layouts: [] }));

    for (const tabLayout of this.tabLayouts) {
      const configTab = this.projectConfiguration.layouts.find(cl => cl.tabName === tabLayout.tabName);
      if (configTab?.layouts?.length) {
        const filteredLayouts = configTab.layouts
          .filter(layout => this.showLayout(layout))
          .map(layout => this.augmentLayout(layout));
        tabLayout.layouts = filteredLayouts.filter(filteredLayout => filteredLayout.layouts.length);
      }
    }
  }

  private render(template: string, context: any) {
    const currentUser = this.userService.currentUser;
    const renderer = createRenderer({
      timezone: currentUser?.accessInfo?.configuration?.timezone,
      locale: currentUser?.accessInfo?.configuration?.locale
    });

    return template ? renderer.render('{{={ }=}} ' + template, context) : template;
  }

  /**
   * Take in a ProjectCardLayout, calculating the grid position and style and fetch the data
   * NB a project will have several resources of the same type if blocks have been
   * repeated in Atom. To deal with this we add each resource to the layouts
   * @param layoutSpec
   */
  private augmentLayout(layoutSpec) {
    const projectSource = get(this.project, ProjectDetailComponent.getDataSource(layoutSpec)) || null;
    if (projectSource) {
      // If it's project.data or similar, just add the one layout
      return {
        layouts: [
          {
            ...layoutSpec,
            data: projectSource
          }
        ]
      };
    } else {
      const resources = this.project.resources.filter(resource => resource.type === layoutSpec.dataSource);
      return {
        // For resources, add each one to the layouts and expand out the label for each one
        layouts: resources.map((r, i) => ({
          ...layoutSpec,
          data: this.getResourceData(r),
          // If the resource has an id, then use that, otherwise use layoutSpec.updateResource which is the type
          updateResource: r.id || layoutSpec.updateResource,
          // Rendering the label allows us to add an index (starting at 1)
          label: this.render(layoutSpec.label, { index: i + 1 })
        }))
      };
    }
  }

  private getResourceData(resource) {
    const resourceData = { ...resource.summary };
    const resourcePrefix = `${resource.id}__`;
    Object.keys(this.project.data)
      .filter(key => key.startsWith(resourcePrefix))
      .forEach(key => {
        resourceData[key.substr(resourcePrefix.length)] = this.project.data[key];
      });
    return resourceData;
  }

  private showLayout(layout: LayoutDisplayCriteria): boolean {
    if (layout.requiredStatus && !this.isAtStatus(layout.requiredStatus)) {
      return false;
    }
    if (layout.showIf) {
      if (typeof layout.showIf === 'object') {
        return checkIfExpression(layout.showIf, this.project);
      } else if (typeof layout.showIf === 'string') {
        const requiredValue = layout.showIfValue || 'Yes';
        if (this.project.data[layout.showIf] !== requiredValue) {
          return false;
        }
      }
    }
    return true;
  }

  private isAtStatus(status: string) {
    const currentStatusIdx = this.projectStates.findIndex(projectState => projectState === this.projectState);
    const requiredStatusIdx = this.projectStates.findIndex(projectState => projectState.status === status);
    return currentStatusIdx === -1 || currentStatusIdx >= requiredStatusIdx;
  }

  private setState() {
    this.projectStates = this.projectConfiguration.states;
    this.projectState = this.projectStates.filter(state => state.status === this.project.status.status).pop();
  }

  private setJobSummary() {
    this.jobSummaryItems = (this.project.jobs || [])
      .filter(x => x.scheduledDate)
      .map(job => ({
        jobType: job.type,
        assignedTo: job.assignedToDisplayName,
        scheduledDate: moment(job.scheduledDate).format('LL - LT A'),
        status: job.status,
        statusLog: job.statusLog.map(log => ({ ...log, timestamp: moment(log.timestamp).format('LL - LT A') })),
        showLogs: false
      }));
  }

  private setAdditionalDownloads() {
    this.additionalDownloads = this.project.resources.filter(resource => resource.location !== undefined);
  }

  private setProjectResources() {
    this.project.resources.forEach(resource => {
      if (resource.summary) {
        this.resources[resource.type] = resource.summary;
      }
    });
  }

  downloadProject(downloadType: ProjectDlType) {
    this.showSpinner();
    return this.apiService.getDownloadUrl(this.project.id, downloadType).subscribe(
      (downloadLocation: string) => {
        this.hideSpinner();
        // open the download url in a hidden iframe so we don't see a flash as we download
        window.open(downloadLocation, 'downloadTarget');
        this.toasterService.pop(
          'info',
          this.translocoService.translate('common.export'),
          `${this.translocoService.translate('common.project')} ${
            downloadType == ProjectDlType.ALL ? '' : downloadType + ' '
          }${this.translocoService.translate('common.exported')}`
        );
      },
      error => {
        this.hideSpinner().then(() => {
          this.loggerService.error(error);
        });
        const message =
          (error.error && error.error.errorMessage) ||
          this.translocoService.translate('project.modals.exportError.messages.unable');
        this.toasterService.pop('error', this.translocoService.translate('project.modals.exportError.title'), message);
      }
    );
  }

  private getProgress(): ProgressIndicatorState[] {
    // If the project is archived, the "current" status is the status before archiving
    const status =
      this.isArchived && this.project.status.previousStatus
        ? this.project.status.previousStatus
        : this.project.status.status;

    const projectStateIndex = this.projectStates.findIndex(state => state.status === status);

    return this.projectStates
      .filter(state => {
        return !state.hidden;
      })
      .map((step, stepIndex) => {
        let status: ProgressIndicatorStatus;
        if (projectStateIndex == stepIndex) {
          if (this.isArchived) {
            status = ProgressIndicatorStatus.archived;
          } else {
            status = step.showProgress ? ProgressIndicatorStatus.progressing : ProgressIndicatorStatus.current;
          }
          this.currentStatusPosition = stepIndex;
        } else if (stepIndex == projectStateIndex + 1) {
          status = this.isArchived ? ProgressIndicatorStatus.todo : ProgressIndicatorStatus.next;
        } else {
          // compare with our list of statuses and determine if we have been skipped or not
          if (stepIndex < projectStateIndex) {
            status = this.statusChanges.map(x => x.status).includes(step.status)
              ? ProgressIndicatorStatus.completed
              : ProgressIndicatorStatus.skipped;
          } else {
            status = ProgressIndicatorStatus.todo;
          }
        }
        return {
          label: step.label,
          showProgress: !!step.showProgress,
          status: status,
          statusData: this.statusChanges.find(x => x.status === step.status) || null,
          showSkipped: false
        };
      });
  }

  async subscribeToUpdates() {
    this.updateSubscription?.unsubscribe();
    this.updateSubscription = (
      await this.projectUpdateService.makeJsonWebSocketObservable(this.project.id, this.project.tenant)
    )
      .pipe(
        filter(update => update == 'true'),
        switchMap(() => {
          return timer(2000).pipe(
            switchMap(() => {
              return this.apiService.getProject(this.project.id);
            }),
            retryWhen(this.delayWsUpdate$.asObservable)
          );
        })
      )
      .subscribe((updatedProject: Project) => {
        this.processUpdatedProject(updatedProject);
      });
  }

  processUpdatedProject(updatedProject: Project) {
    let shouldSetCardLayouts = false;
    const originalProject = this.project;
    const projectDiff: Partial<Project> = diff(originalProject, updatedProject);
    const filtered = Object.keys(projectDiff).filter(k => {
      const value = projectDiff[k];
      if (typeof value === 'undefined') {
        return false;
      }
      if (k === 'updated_on') {
        return false;
      }
      if (k === 'attachments') {
        return true;
      }

      function checkSubDiffs(value, parentKeys) {
        const subDiffs = Object.keys(value).filter(j => {
          const subValue = value[j];
          if (subValue && typeof subValue === 'object') {
            return checkSubDiffs(subValue, [...parentKeys, j]);
          }

          if (subValue?.toString().startsWith('https')) {
            const firstKey = parentKeys[0];
            let data = originalProject[firstKey];
            for (let i = 1; i < parentKeys.length; i++) {
              data = data[parentKeys[i]];
            }
            const imageUrl = data?.[j];
            return imageUrl?.replace(/\?.*/, '') !== subValue?.replace(/\?.*/, '');
          }
          return true;
        });

        return subDiffs.length;
      }

      if (value && typeof value === 'object') {
        return checkSubDiffs(value, [k]);
      }
      return true;
    });
    const isDifferent = filtered.length > 0;
    if (!isDifferent) {
      return;
    }
    this.project = { ...lodashMerge(this.project, updatedProject) };

    // Source properties that resolve to undefined are skipped if a destination value exists,
    // that's why we need to manually set documentPackId to null if it doesn't exist in the
    // updated project object. https://lodash.com/docs/#merge
    if (!updatedProject.documentPackId) {
      this.project.documentPackId = null;
    }

    if (updatedProject?.owner && typeof updatedProject?.owner !== 'string') {
      this.project.owner = null;
    }
    if (filtered.find(x => x === 'documentPackId')) {
      this.initDocumentPacks(false, false);
    }
    if (filtered.find(x => x === 'resources')) {
      // There might be fewer resources than before, so as a special case, let's replace the resources
      this.project.resources = updatedProject.resources;
      this.initOrders(true).then();
      shouldSetCardLayouts = true;
    }
    if (filtered.find(x => x === 'status')) {
      this.setState();
      shouldSetCardLayouts = true;
      this.initDocumentPacks(false, true);
      this.initOrders().then();
    } else if (filtered.some(x => ['resources', 'data'].includes(x))) {
      shouldSetCardLayouts = true;
      if (filtered.find(x => x === 'data')) {
        this.initDocumentPacks(false, true);
      }
    }
    if (filtered.find(x => x === 'attachments')) {
      this.project.attachments = updatedProject.attachments;
    }
    this.setState();
    this.setProjectResources();
    if (shouldSetCardLayouts) {
      this.setCardLayouts();
    }
    this.setJobSummary();
    this.projectUpdateService.nextAuditLogUpdates();
    this.setAdditionalDownloads();
  }

  /**
   * If a tab is changed, make sure that none of the child layouts being edited are
   * still in edit mode.
   * @param $event
   */
  onTabChange($event: NgbNavChangeEvent) {
    this.tabLayouts.forEach(tabLayout => {
      tabLayout.layouts.forEach(layout => {
        layout.layouts.forEach(l => (l.edit = false));
      });
    });
  }

  onDelayWebHook() {
    this.delayWsUpdate$.next(true);
  }

  onInfoMessage(message: string) {
    this.infoMessage = message;
  }

  onSwitchTab(id: string) {
    if (id && this.layoutTabSet.items.find(x => x.id === id)) {
      this.layoutTabSet.select(id);
      this.tabSetContainer.nativeElement.scrollIntoView(true);
    }
  }

  onActioned(action) {
    if (action.transitionType === 'ScheduleJob') {
      this.setProjectConfiguration(false, true).then();
    }
  }

  onSave(data: unknown) {
    if (data) {
      const update = Object.assign(this.project.data, data);
      this.project = { ...this.project, ...{ data: update } };
    }
  }

  saveDocumentToAttachments(documentAttachment: ProjectAttachment) {
    const idx = this.project.attachments.findIndex(attachment => attachment.key === documentAttachment.key);
    if (idx > -1) {
      this.project.attachments[idx] = documentAttachment;
    } else {
      this.project.attachments.push(documentAttachment);
    }
    this.updateAttachments(this.project.attachments);
  }

  updateAttachments($event: ProjectAttachment[]) {
    if (this.isArchived) {
      return;
    }

    ($event || []).map(x => delete x.url);

    const update = { attachments: $event };
    this.project = { ...this.project, ...update };
    this.apiService.updateProject(this.project, update).toPromise().catch(this.loggerService.error);
  }

  async onAuditLogs(auditLogs: AuditLog[] | null) {
    this.auditLogs = auditLogs?.length ? auditLogs : null;
    if (auditLogs !== null) {
      this.setStatusChanges();
      this.projectProgress = this.getProgress();
      this.hideLoaders('auditLogs');
    }
  }
  setStatusChanges(): void {
    if (!this.auditLogs) {
      return;
    }
    const changes = this.auditLogs
      .filter(log => {
        return (
          this.statusChangeTypes.includes(log.type) || log.eventName.toLowerCase().indexOf(this.changeEventName) !== -1
        );
      })
      .map(log => {
        return {
          status: log.newValue,
          date: new Date(log.created_on),
          actionedBy: log.userName || log.resourceName,
          stamp: log.timestamp,
          show: false
        };
      })
      .sort((a, b) => {
        if (a.stamp < b.stamp) {
          return 1;
        }
        if (a.stamp > b.stamp) {
          return -1;
        }
        return 0;
      });

    // filter out all but the most recent changes for each status type
    this.statusChanges = changes.filter((log, pos) => {
      return changes.map(x => x.status).indexOf(log.status) === pos;
    });
  }

  getNotesCount() {
    return this.project.notes?.filter(note => note.type !== 'CONTACT_LOG')?.length || 0;
  }

  updateNotes($event: { notes: Note[]; projectId: string }) {
    if (this.isArchived || $event.projectId !== this.project.id) {
      return;
    }

    const update = { notes: $event.notes };
    this.project = { ...this.project, ...update };
    this.apiService.updateProject(this.project, update).toPromise().catch(this.loggerService.error);
  }

  isLayoutReadOnly(layout: TabLayout | DisplayTabLayout | ExpandedCardLayoutCollection) {
    return !this.layoutUpdateService.allowEdit(layout);
  }

  updateOwner($event: any) {
    this.project.owner = $event.owner;
    this.project.owner_name = $event.owner_name;
  }

  drop(event: CdkDragDrop<string[]>) {
    const previousLayout = [...this.userPrefLayoutLeft];
    moveItemInArray(this.userPrefLayoutLeft, event.previousIndex, event.currentIndex);
    if (previousLayout.join() !== this.userPrefLayoutLeft.join()) {
      Analytics.logEvent('User preference update | Project Details layout (left)', {
        from: previousLayout.join(', '),
        to: this.userPrefLayoutLeft.join(', ')
      });
    }
    this.localStorageGateway.setItem(this.userLayoutProjectDetailsLeft, JSON.stringify(this.userPrefLayoutLeft));
  }

  /**
   * Tell whether at least one of the audit logs is a failure.
   */
  hasFailureLogs(): boolean {
    if (!this.auditLogs) {
      return;
    }
    for (const log of this.auditLogs) {
      if (log.status === AuditLogStatus.FAILURE) {
        return true;
      }
    }
    return false;
  }

  selected(_state) {
    const modalRef = this.modalService.open(RelayComponent, {
      windowClass: 'relay-modal-dialog relay-container'
    });
    modalRef.componentInstance.project = this.project;
  }

  async changeSuccess() {
    await this.fetchProject(this.project.id);
    this.projectUpdateService.nextAuditLogUpdates();
  }

  private async autoCreateDocumentPack(): Promise<boolean> {
    const createDocumentPackOnAddProject = this.projectConfiguration?.eventActions?.onAddProject?.asyncActions?.some(
      asyncAction => asyncAction.eventName === 'CREATE_DOCUMENT_PACK'
    );

    const featureFlagEnabled = await this.featureFlagService.isFeatureEnabled(this.AUTO_ADD_DOC_PACK_FEATURE_KEY);
    return featureFlagEnabled && createDocumentPackOnAddProject;
  }

  get isArchived(): boolean {
    return this.project?.status?.status === ProjectStatus.ARCHIVED;
  }

  /**
   * For the time being, a project is readonly only when it's archived.
   */
  get isReadOnly(): boolean {
    return this.isArchived;
  }

  updateData(event: { layout: ExpandedCardLayout; data: any }) {
    const { layout, data } = event;
    layout.data = { ...layout.data, ...data };
  }
}
