import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { routing } from './app.routing';
import { DatadogLoggerService } from './error/datadog-logger.service';
import { ErrorComponent } from './error/error.component';
import { ErrorModalComponent } from './error/error-modal.component';

import { SharedModule } from './shared/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { GlobalErrorHandler } from './error/global-error.handler';
import { environment } from '../environments/environment';
import { AlertModalComponent } from './shared/modals/alert-modal.component';
import { ConfirmModalComponent } from './shared/modals/confirm-modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ToastContainerModule } from './toast/toast-container.module';
import { ProjectDetailModule } from './project-detail/project-detail.module';

import { HomePageComponent } from './home/home-page.component';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { UserHistoryComponent } from './home/user-history/user-history.component';
import { AuthModule } from './auth/auth.module';
import { interceptorProviders } from './core/interceptors';
import { FeatureFlagService } from './core/feature-flag/feature-flag.service';
import { EnvironmentToken } from '@jump-tech-frontend/app-config';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { defineCustomElements } from '@jump-tech-frontend/core-components/loader';

defineCustomElements();

const featureFactory = (featureFlagsService: FeatureFlagService) => () => featureFlagsService.loadConfig();

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    ErrorModalComponent,
    AlertModalComponent,
    ConfirmModalComponent,
    HomePageComponent,
    UserHistoryComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AuthModule,
    ProjectDetailModule,
    LayoutModule,
    CommonModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    TranslocoRootModule,
    ToastContainerModule,
    routing,
    JumptechCardsLibModule,
    CoreComponentsAngularModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: featureFactory,
      deps: [FeatureFlagService, DatadogLoggerService],
      multi: true
    },
    Title,
    {
      provide: ErrorHandler,
      useClass: environment.analytics ? GlobalErrorHandler : ErrorHandler
    },
    {
      provide: 'stage',
      useValue: environment.name
    },
    { provide: EnvironmentToken, useValue: environment },
    ...interceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
