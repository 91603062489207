import { Component, Input } from '@angular/core';
import { BaseQuestionComponent } from '../question.component';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { I18nKeys } from '../../domain/i18n-keys';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: `crds-address-list-question-dropdown`,
  template: ``
})
export class AddressListDropdownQuestionComponent extends BaseQuestionComponent {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
}
