import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpGateway } from '../../core/http-gateway.service';
import {
  DEFAULT_SUMMARY_FIELDS,
  ErrorType,
  IGetTenantGroupDto,
  IGroupDm,
  ILinkedProjectsDm,
  ILinkedProjectsErrorDm,
  ILinkedProjectsI18n,
  IMessageDm,
  IProjectGroupDto,
  ISummaryDataDto,
  ISummaryDataVm,
  MESSAGES_SPINNER
} from './linked-projects.model';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import {FormBuilder, UntypedFormControl, Validators} from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class LinkedProjectsRepository {
  linkedProjectsSubscription: Subscription;
  errorsSubscription: Subscription;
  messagesSubscription: Subscription;

  linkedProjects$: Subject<ILinkedProjectsDm>;
  errors$: Subject<ILinkedProjectsErrorDm>;
  messages$: Subject<IMessageDm>;

  projectId: string;
  i18n: ILinkedProjectsI18n = {};
  defaultLinkedProjects: ILinkedProjectsDm = {} as ILinkedProjectsDm;
  cachedLinkedProjects: ILinkedProjectsDm = {} as ILinkedProjectsDm;

  constructor(
    private httpGateway: HttpGateway,
    private i18nService: TranslocoService,
    private spinnerService: NgxSpinnerService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.init();
  }

  private init() {
    this.linkedProjects$ = new Subject();
    this.errors$ = new Subject();
    this.messages$ = new Subject();
    this.initI18ns();
    this.initDefaults();
  }

  private initDefaults() {
    this.defaultLinkedProjects = {
      groups: [],
      projectGroups: [],
      linkedData: [],
      groupId: null,
      groupType: this.i18n.defaultGroupType,
      groupNamePrefix: this.i18n.groupNamePrefix,
      groupNameMultiPrefix: this.i18n.groupNameMultiPrefix,
      searchPlaceholder: this.i18n.searchPlaceholder,
      unlinkButton: this.i18n.unlinkButton,
      createLabel: this.i18n.createLabel,
      addressLabel: this.i18n.addressLabel,
      statusLabel: this.i18n.statusLabel,
      customerLabel: this.i18n.customerLabel,
      deleteGroupTooltip: this.i18n.deleteGroupTooltip,
      srLoading: this.i18n.srLoading,
      createNewLoadingText: this.i18n.createGroupInProgress,
      deleteInProgressText: this.i18n.deleteGroupInProgressMessage,
      showMainSpinner: true,
      isCreatingGroup: false,
      hasError: false,
      inProgress: false,
      form: this.fb.group({ findOrCreate: new UntypedFormControl(null)}),
    };
  }

  public load(callback, projectId, quietReload): void {
    this.projectId = projectId;
    this.linkedProjectsSubscription = this.linkedProjects$.subscribe(callback);
    this.loadGroupList(quietReload);
  }

  private loadGroupList(quietReload: boolean): void {
    let initialLinkedProjects = this.defaultLinkedProjects;
    if (quietReload && this.cachedLinkedProjects.linkedData) {
      initialLinkedProjects = this.cachedLinkedProjects;
    }
    this.linkedProjects$.next(initialLinkedProjects);
    this.cachedLinkedProjects = { ...initialLinkedProjects };
    this.getGroupData().then();
  }

  private async getGroupData() {
    const groupDataCalls: Array<Promise<IGetTenantGroupDto[] | IProjectGroupDto[]>> = [
      this.fetchTenantGroups(),
      this.fetchGroupsByProjectId()
    ];
    return Promise.all(groupDataCalls)
      .then(async values => {
        const projectGroupDto = values[1] as IProjectGroupDto[];
        let linkedProjectsData: ISummaryDataDto[] = [];
        if (projectGroupDto.length && projectGroupDto[0].projectIds) {
          linkedProjectsData = await this.fetchLinkedProjectsData(projectGroupDto[0].projectIds);
        }
        const dataDm: ILinkedProjectsDm = this.transformGroupedData(values, linkedProjectsData);
        this.cachedLinkedProjects = { ...dataDm };
        this.linkedProjects$.next(dataDm);
        this.clearMessages();
        this.enableForm();
      })
      .catch(e => {
        this.handleErrors(ErrorType.fetchGroupList, e);
      });
  }

  private transformGroupedData(
    dto: Array<IGetTenantGroupDto[] | IProjectGroupDto[]>,
    linkedProjectsData: ISummaryDataDto[]
  ): ILinkedProjectsDm {
    const groupDto = dto[0] as IGetTenantGroupDto[];
    const projectGroupDto = dto[1] as IProjectGroupDto[];
    return {
      ...this.cachedLinkedProjects,
      groups: [...(groupDto as IGroupDm[])].sort(this.sortGroups),
      projectGroups: [...projectGroupDto],
      linkedData: linkedProjectsData.sort(this.sortLinkedData),
      groupId: projectGroupDto.length ? projectGroupDto[0].id : '',
      groupType: projectGroupDto.length
        ? projectGroupDto[0].type
        : groupDto.length
        ? groupDto[0].type
        : this.i18n.defaultGroupType,
      showMainSpinner: false,
      isCreatingGroup: false,
    };
  }

  private sortLinkedData(a: ISummaryDataDto, b: ISummaryDataDto) {
    if (a.type < b.type) {
      return -1;
    }
    if (a.type > b.type) {
      return 1;
    }
    return 0;
  }

  private sortGroups(a: IGroupDm, b: IGroupDm) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  private async fetchTenantGroups(): Promise<IGetTenantGroupDto[]> {
    return await this.httpGateway.get(environment.apiProjectGroups, { parseBody: false }, {});
  }

  private async fetchGroupsByProjectId(): Promise<IProjectGroupDto[]> {
    return await this.httpGateway.get(
      `${environment.apiProjectUrl}/${this.projectId}/groups`,
      { parseBody: false },
      {}
    );
  }

  private async fetchLinkedProjectsData(projectIds: string[]): Promise<any[]> {
    return await this.httpGateway.get(
      environment.apiProjectGroupsSummary,
      { parseBody: false, projectIds: projectIds.join(), fields: DEFAULT_SUMMARY_FIELDS.join() },
      {}
    );
  }

  notifyProgress(inProgress = true) {
    if (inProgress) {
      this.disableForm();
    } else {
      this.enableForm();
    }
    this.linkedProjects$.next({ ...this.cachedLinkedProjects, inProgress });
  }

  public async linkToGroup(groupId: string) {
    try {
      this.notifyProgress();
      this.setMessage(this.i18n.linkInProgressMessage, true);
      await this.linkProjectToGroup(groupId);
      this.getGroupData().then();
    } catch (e) {
      this.handleErrors(ErrorType.linkToGroup, e);
    }
  }

  public async unlinkFromGroup(groupId: string, projectId: string) {
    try {
      this.notifyProgress();
      this.setMessage(this.i18n.unlinkInProgressMessage, true);
      await this.unlinkProjectFromGroup(groupId, projectId);
      this.resetForm();
      this.enableForm();
      this.getGroupData().then();
    } catch (e) {
      this.handleErrors(ErrorType.unlinkFromGroup, e);
    }
  }

  private resetForm() {
    this.cachedLinkedProjects.form.patchValue({ findOrCreate: null });
  }

  private enableForm() {
    this.cachedLinkedProjects.form.get('findOrCreate').enable();
  }

  private disableForm() {
    this.cachedLinkedProjects.form.get('findOrCreate').disable();
  }

  public async deleteGroup(groupId: string) {
    try {
      this.disableForm();
      this.notifyDeletingGroup(groupId);
      await this.deleteOrphanedGroup(groupId);
      this.getGroupData().then();
    } catch (e) {
      this.handleErrors(ErrorType.deleteGroup, e);
    }
  }

  private notifyDeletingGroup(groupId) {
    // set group item in progress
    const data = {
      ...this.cachedLinkedProjects,
      inProgress: true,
      groups: this.cachedLinkedProjects.groups.map(group => {
        if (group.id === groupId) {
          return { ...group, isDeleting: true };
        }
        return { ...group };
      })
    };

    this.linkedProjects$.next({ ...data });
  }

  public async createGroup(name: string) {
    this.linkedProjects$.next({ ...this.cachedLinkedProjects, isCreatingGroup: true });
    try {
      this.setMessage(this.i18n.createGroupInProgressMessage, true);
      await this.createNewGroup(name);
      this.getGroupData().then();
    } catch (e) {
      this.handleErrors(ErrorType.createNewGroup, e);
    }
  }

  goToProject(link: ISummaryDataVm) {
    if (!link.isCurrentProject) {
      this.router.navigate([`project/${link.projectId}`, { linkProjectsQuietReload: true }]).then();
    } else {
      return false;
    }
  }

  private async linkProjectToGroup(groupId: string) {
    return await this.httpGateway.post(`${environment.apiProjectUrl}/${this.projectId}/groups/${groupId}`, null, {});
  }

  private async unlinkProjectFromGroup(groupId: string, projectId: string) {
    return await this.httpGateway.delete(`${environment.apiProjectUrl}/${projectId}/groups/${groupId}`, {});
  }

  private async deleteOrphanedGroup(groupId: string) {
    return await this.httpGateway.delete(`${environment.apiProjectGroups}/${groupId}`, {});
  }

  private async createNewGroup(name: string): Promise<IGetTenantGroupDto[]> {
    return await this.httpGateway.post(
      environment.apiProjectGroups,
      { parseBody: false, projectId: this.projectId, name },
      {}
    );
  }

  public getErrors(callback): void {
    this.errorsSubscription = this.errors$.subscribe(callback);
  }

  public clearErrors(): void {
    this.errors$.next({} as ILinkedProjectsErrorDm);
  }

  private setError(e: ErrorType): void {
    const message = this.i18nService.translate(e);
    this.errors$.next({
      message,
      qaClearErrorsButton: this.i18n.qaClearErrorsButton,
      qaErrorMessage: this.i18n.qaErrorMessage
    });
  }

  public getMessages(callback) {
    this.messagesSubscription = this.messages$.subscribe(callback);
  }

  private setMessage(message, showLoader) {
    if (showLoader) {
      this.spinnerService.show(MESSAGES_SPINNER).then();
    } else {
      this.spinnerService.hide(MESSAGES_SPINNER).then();
    }
    this.messages$.next({ message, showLoader, spinnerName: MESSAGES_SPINNER });
  }

  public clearMessages() {
    this.messages$.next(null);
  }

  private initI18ns(): void {
    this.i18n.searchPlaceholder = this.i18nService.translate('linkedProjects.searchPlaceholder');
    this.i18n.createLabel = this.i18nService.translate('linkedProjects.createLabel');
    this.i18n.groupNamePrefix = this.i18nService.translate('linkedProjects.groupNamePrefix');
    this.i18n.groupNameMultiPrefix = this.i18nService.translate('linkedProjects.groupNameMultiPrefix');
    this.i18n.defaultGroupType = this.i18nService.translate('linkedProjects.defaultGroupType');
    this.i18n.createGroupInProgress = this.i18nService.translate('linkedProjects.createGroupInProgress');
    this.i18n.unlinkButton = this.i18nService.translate('linkedProjects.unlinkButton');
    this.i18n.deleteGroupTooltip = this.i18nService.translate('linkedProjects.deleteGroupTooltip');

    this.i18n.qaClearErrorsButton = this.i18nService.translate('linkedProjects.qaClearErrorsButton');
    this.i18n.qaErrorMessage = this.i18nService.translate('linkedProjects.qaErrorMessage');

    this.i18n.linkInProgressMessage = this.i18nService.translate('linkedProjects.message.linkInProgress');
    this.i18n.unlinkInProgressMessage = this.i18nService.translate('linkedProjects.message.unlinkInProgress');
    this.i18n.createGroupInProgressMessage = this.i18nService.translate('linkedProjects.message.createGroupInProgress');
    this.i18n.deleteGroupInProgressMessage = this.i18nService.translate('linkedProjects.message.deleteGroupInProgress');

    this.i18n.srLoading = this.i18nService.translate('common.loading');
    this.i18n.addressLabel = this.i18nService.translate('common.formFields.address');
    this.i18n.statusLabel = this.i18nService.translate('common.status');
    this.i18n.customerLabel = this.i18nService.translate('common.customer');
  }

  private handleErrors(type: ErrorType | unknown, e): void {
    if (!environment.production) {
      console.error(e);
    }
    this.clearMessages();
    switch (type) {
      case ErrorType.fetchGroupList:
        this.setError(ErrorType.fetchGroupList);
        this.linkedProjects$.next({
          ...this.defaultLinkedProjects,
          hasError: true,
          showMainSpinner: false
        });
        break;
      case ErrorType.createNewGroup:
        this.setError(ErrorType.createNewGroup);
        this.linkedProjects$.next({
          ...this.cachedLinkedProjects,
          hasError: true,
          showMainSpinner: false
        });
        break;
      case ErrorType.linkToGroup:
        this.setError(ErrorType.linkToGroup);
        this.linkedProjects$.next({
          ...this.cachedLinkedProjects,
          hasError: true,
          showMainSpinner: false
        });
        break;
      case ErrorType.unlinkFromGroup:
        this.setError(ErrorType.unlinkFromGroup);
        this.linkedProjects$.next({
          ...this.cachedLinkedProjects,
          hasError: true,
          showMainSpinner: false
        });
        break;
      case ErrorType.deleteGroup:
        this.setError(ErrorType.deleteGroup);
        this.linkedProjects$.next({
          ...this.cachedLinkedProjects,
          hasError: true,
          showMainSpinner: false
        });
        break;
      default:
        this.setError(ErrorType.unknown);
    }
  }

  public unsubscribe(): void {
    if (this.errorsSubscription) {
      this.errorsSubscription.unsubscribe();
    }
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
    if (this.linkedProjectsSubscription) {
      this.linkedProjectsSubscription.unsubscribe();
    }
  }
}
