import { DatePipe } from '@angular/common';
import * as moment from 'moment';

export function createLocalisedDateValue(date: string): string {
  if (moment(date, moment.ISO_8601).isValid()) {
    return new DatePipe('en-US').transform(date, 'dd/MM/yyyy HH:mm');
  }
  // Fallback for audit logs that were written with isoDate type but invalid isoDate values
  return date;
}
