import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentPackErrorsPresenter } from './document-pack-errors.presenter';

@Component({
  selector: 'app-document-pack-errors',
  templateUrl: './document-pack-errors.component.html',
  styleUrls: ['./document-pack-errors.component.scss']
})
export class DocumentPackErrorsComponent implements OnInit, OnDestroy {
  vm: string = null;

  constructor(private presenter: DocumentPackErrorsPresenter) {}

  ngOnInit() {
    this.presenter.load(generatedVm => {
      this.vm = generatedVm;
    });
  }

  clearError() {
    this.presenter.clearErrors();
  }

  ngOnDestroy() {
    this.presenter.cleanup();
  }
}
