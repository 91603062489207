import { ISearchProvider } from '../../domain/search-provider';
import { IAddressV2 } from '../../domain/address-v2';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import { GetAddressIOSearchResponse, GetAddressIORetrieveResponse } from './get-address-io.model';

export const getGetAddressIOSearchProvider = (httpClient: HttpClient): ISearchProvider => {
  const apiKey = 'rq2ptHwF7kSgWAB2R-N5Tw13357';
  const apiUrlBase = 'https://api.getAddress.io';
  return {
    name: 'getaddressio',
    search: async (searchTerm: string, container?: string) => {
      let params: HttpParams = new HttpParams({
        fromObject: {
          'api-key': apiKey
        }
      });
      if (container) {
        params = params.append('Container', container);
      }

      const getAddressIOSearchResponse = await firstValueFrom(
        httpClient.get<GetAddressIOSearchResponse>(`${apiUrlBase}/autocomplete/${searchTerm}`, {
          params
        })
      );
      return getAddressIOSearchResponse?.suggestions.map(result => ({
        id: result.id,
        description: result.address
      }));
    },
    getResult: async (id: string) => {
      const params: HttpParams = new HttpParams({
        fromObject: {
          'api-key': apiKey
        }
      });
      const getAddressIORetrieveResponse = await firstValueFrom(
        httpClient.get<GetAddressIORetrieveResponse>(`${apiUrlBase}/get/${id}`, { params }).pipe(catchError(throwError))
      );
      return addressResponseToAddressV2Transform(getAddressIORetrieveResponse);
    }
  };
};

function addressResponseToAddressV2Transform(address: GetAddressIORetrieveResponse): IAddressV2 {
  const county = address.county ?? '';
  return {
    line1: address.line_1 ?? '',
    line2: address.line_2 ?? '',
    line3: [address?.line_3 ?? '', address.line_4 ?? '', address.locality ?? ''].filter(a => a).join(', '),
    town: address.town_or_city ?? '',
    county: county && county === address.town_or_city ? '' : county ?? '',
    country: address.country ?? '',
    postCode: address.postcode ?? '',
    latitude: address?.latitude ? address.latitude.toString() : undefined,
    longitude: address?.longitude ? address.longitude.toString() : undefined,
    countryCode: 'gb'
  };
}
