import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { EnvironmentService, ROOT_CONFIG, RootConfig } from '@jump-tech-frontend/app-config';
import i18next, { i18n } from 'i18next';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ObjectHelper } from '../../utils/object-helper';
import { isTranslations, Translations } from './translations';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private _namespace: string;
  private _languages: Record<string, any> = {};

  public currentLang = new BehaviorSubject<string | null>(null);

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
    @Optional() @Inject(ROOT_CONFIG) private rootConfig: RootConfig
  ) {}

  get currentLanguage(): string {
    return this.currentLang.value;
  }

  set currentLanguage(value: string) {
    if (Object.keys(this._languages).includes(value)) {
      this.currentLang.next(value);
    }
  }

  get namespace(): string {
    return this._namespace || null;
  }

  private set namespace(value: string) {
    if (value === this._namespace) {
      return;
    }
    if (!value) {
      this._namespace = null;
    }
    value = `${value.replace(/ /g, '-').toLowerCase()}`;
    this._namespace = value;
  }

  get languages(): string[] {
    return Object.keys(this._languages).sort();
  }

  public async loadLanguages(namespace: string) {
    this.namespace = namespace;
    this._languages = {};
    const languages = await this.getLanguages();
    const languageSet = new Set<string>();
    for (const lang of languages) {
      let value = lang;
      const index = lang.indexOf('-');
      if (index > -1) {
        value = value.substring(0, index);
      }
      languageSet.add(value);
    }
    const languageArray = Array.from(languageSet.values());

    for (const language of languageArray) {
      this._languages[language] = await this.loadNamespace(language, namespace);
    }
    const keys = Object.keys(this._languages);

    if (!keys.length) {
      this.addLanguage('en');
      this.currentLanguage = 'en';
    } else {
      if (keys.includes('en')) {
        this.currentLanguage = 'en';
      } else {
        this.currentLanguage = keys[0];
      }
    }
  }

  public getDefaultValue(key: string, current?: any): Translations {
    const value: Translations = {
      translation: true,
      translationKey: key
    };
    for (const language of Object.keys(this._languages)) {
      value[language] = current || null;
    }
    return value;
  }

  public addLanguage(language: string) {
    if (Object.keys(this._languages).includes(language)) {
      return;
    }
    this._languages[language] = {};
  }

  public async augmentConfiguration(configuration: any) {
    for (const language of Object.keys(this._languages)) {
      configuration = await this.resolveTranslations(language, configuration);
    }
    return configuration;
  }

  public async resolveConfiguration(configuration: any) {
    return await this.resolveKeys(configuration);
  }

  public async resolvePreview(configuration: any) {
    return await this.extractPreview(this.currentLanguage, configuration);
  }

  public async saveLanguages(configuration: any, env: string = this.environmentService.env) {
    for (const language of Object.keys(this._languages)) {
      this._languages[language] = this.extractTranslations(language, configuration);
      if (this._languages[language]) {
        await this.persistNamespace(language, this.namespace, env);
      }
    }
  }

  public exportCurrentLanguage(configuration: any) {
    return this.extractTranslations(this.currentLanguage, configuration);
  }

  public importLanguage(language: string, languageFile: any) {
    if (!this.languages.includes(language)) {
      this.addLanguage(language);
    }
    this._languages[language] = languageFile;
  }

  private async resolveTranslations(language: string, value: any): Promise<any> {
    if (!value || !this.namespace) {
      return value;
    }

    const translator = i18next.createInstance();
    try {
      const clone = JSON.parse(JSON.stringify(value));
      await translator.init({
        lng: language,
        ns: [this.namespace],
        defaultNS: this.namespace,
        returnObjects: true
      });

      translator.addResourceBundle(language, this.namespace, this._languages[language], true, true);
      await translator.reloadResources();

      return this.traverseResolveTranslations(language, translator, clone);
    } catch (e) {
      console.error('Error', e);
      return value;
    } finally {
      translator.removeResourceBundle(language, this.namespace);
    }
  }

  private traverseResolveTranslations(language: string, translator: i18n, value: any) {
    if (value === null || value === undefined) {
      return value;
    }

    if (isTranslations(value)) {
      const translationKey = value.translationKey.replace(/\$t\((.*)\)/g, '$1');
      const translated = translator.t(translationKey);
      if (translationKey === translated) {
        value[language] = '';
      } else {
        value[language] = translated;
      }
      return value;
    }

    if (typeof value === 'string') {
      if (value.match(/\$t\((.*)\)/g)) {
        const key = value.replace(/\$t\((.*)\)/g, '$1');
        const newValue: Translations = {
          translation: true,
          translationKey: value
        };
        try {
          const translated = translator.t(key);
          if (key === translated) {
            newValue[language] = '';
          } else {
            newValue[language] = translated;
          }
          return newValue;
        } catch (e) {
          console.log('Translation does not exist', key);
          newValue[language] = '';
          return newValue;
        }
      }
      return value;
    }

    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        value[i] = this.traverseResolveTranslations(language, translator, value[i]);
      }
      return value;
    }

    if (typeof value === 'object' && !isTranslations(value)) {
      for (const key of Object.keys(value)) {
        value[key] = this.traverseResolveTranslations(language, translator, value[key]);
      }
      return value;
    }

    return value;
  }

  private extractPreview(language: string, value: any) {
    if (!value || !this.namespace) {
      return value;
    }

    try {
      const clone = JSON.parse(JSON.stringify(value));
      return this.traverseExtractPreview(language, clone);
    } catch (e) {
      console.error('Error', e);
      return value;
    }
  }

  private traverseExtractPreview(language: string, value: any) {
    if (value === null || value === undefined) {
      return value;
    }

    if (isTranslations(value)) {
      return value[language];
    }

    if (Array.isArray(value)) {
      const result = [];
      for (let i = 0; i < value.length; i++) {
        result[i] = this.traverseExtractPreview(language, value[i]);
      }
      return result;
    }

    if (typeof value === 'object' && !isTranslations(value)) {
      const result = {};
      for (const key of Object.keys(value)) {
        result[key] = this.traverseExtractPreview(language, value[key]);
      }
      return result;
    }
    return value;
  }

  private extractTranslations(language: string, value: any) {
    if (!value || !this.namespace) {
      return value;
    }

    try {
      const clone = JSON.parse(JSON.stringify(value));
      const extracted = this.traverseExtractTranslations(language, clone);
      if (!extracted) {
        return null;
      }
      return JSON.parse(
        JSON.stringify(extracted, (_k, value) => {
          if (value) {
            return value;
          }
          return undefined;
        })
      );
    } catch (e) {
      console.error('Error', e);
      return value;
    }
  }

  private traverseExtractTranslations(language: string, value: any) {
    if (value === null || value === undefined) {
      return undefined;
    }

    if (isTranslations(value)) {
      return value[language] || undefined;
    }

    if (Array.isArray(value)) {
      const result = {};
      for (let i = 0; i < value.length; i++) {
        let index = i;
        if (Object.prototype.hasOwnProperty.call(value[i], 'key') && value[i].key) {
          index = value[i].key;
        }
        const extracted = this.traverseExtractTranslations(language, value[i]);
        if (extracted) {
          result[index] = extracted;
        }
      }
      if (!Object.keys(result).length) {
        return undefined;
      }
      return result;
    }

    if (typeof value === 'object' && !isTranslations(value)) {
      const result = {};
      for (const key of Object.keys(value)) {
        const extracted = this.traverseExtractTranslations(language, value[key]);
        if (extracted) {
          result[key] = extracted;
        }
      }
      if (!Object.keys(result).length) {
        return undefined;
      }
      return result;
    }

    return undefined;
  }

  private resolveKeys(value: any) {
    if (!value || !this.namespace) {
      return value;
    }
    try {
      const clone = JSON.parse(JSON.stringify(value));
      return this.traverseResolveKeys(clone);
    } catch (e) {
      console.error('Error', e);
      return value;
    }
  }

  private traverseResolveKeys(value: any, keyPath = '') {
    if (value === null || value === undefined) {
      return value;
    }

    if (isTranslations(value)) {
      return `$t(${keyPath})`;
    }

    if (typeof value === 'string') {
      return value;
    }

    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        let path = keyPath ? `${keyPath}.${i}` : `${i}`;
        if (Object.prototype.hasOwnProperty.call(value[i], 'key') && value[i].key) {
          path = keyPath ? `${keyPath}.${value[i].key}` : value[i].key;
        }
        value[i] = this.traverseResolveKeys(value[i], path);
      }
      return value;
    }

    if (typeof value === 'object' && !isTranslations(value)) {
      for (const key of Object.keys(value)) {
        const path = keyPath ? `${keyPath}.${key}` : key;
        value[key] = this.traverseResolveKeys(value[key], path);
      }
      return value;
    }

    return value;
  }

  /* DATA ACCESS */
  private baseUrl(env = this.environmentService.env) {
    return env === 'production' ? `https://api.nucleus.jumptech.tools` : `https://api.nucleus.${env}.jumptech.tools`;
  }

  private async getLanguages() {
    return await this.httpClient
      .get(`${this.baseUrl()}/i18n/${this.environmentService.env}/languages`, {
        headers: { tenant: 'default' },
        params: {
          namespace: this.namespace
        }
      })
      .pipe(
        map((response: string[]) => {
          return response || [];
        })
      )
      .toPromise();
  }

  private async loadNamespace(lang: string, namespace: string) {
    return await this.httpClient
      .get(`${this.baseUrl()}/i18n/${this.environmentService.env}/${lang}/${namespace}`, {
        headers: { tenant: 'default' }
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(e => {
          console.log('Failed to fetch', e);
          return of(null);
        })
      )
      .toPromise();
  }

  private async persistNamespace(language: string, namespace: string, env: string = this.environmentService.env) {
    const payload = ObjectHelper.sanitise(this._languages[language]);
    if (!payload) {
      return Promise.resolve();
    }

    return this.httpClient
      .post(`${this.baseUrl(env)}/i18n/${env}/${language}/${namespace}`, payload, {
        headers: { 'Content-Type': 'application/json', tenant: 'default' }
      })
      .pipe(
        catchError(e => {
          console.log(e);
          return throwError(() => {
            return e;
          });
        })
      )
      .toPromise();
  }
}
