import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomDashboardFilter } from '../dashboards/custom-dashboard-filters/custom-dashboard-filter.component';
import {
  DashboardField,
  DashboardSearchSettings,
  ExportConfiguration,
  SearchParameters
} from '../dashboards/abstract.dashboard';
import { CustomLookupService } from '../core/custom-lookup.service';
import { ProjectConfigurationService } from '../core/project-configuration.service';
import { User } from '../core/domain/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslocoService } from '@ngneat/transloco';
import { ProjectTypeType } from '../core/domain/project-configuration';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { HomeService } from '../core/home.service';
import { AuthenticationService } from '../auth/services/authentication.service';
import { AccessService, PathwayFeature } from '../auth/services/access.service';
import { RouteAccessService } from '../auth/services/route-access.service';
import { PathwayConfigurationService } from '../auth/services/pathway-configuration.service';
import { UserService } from '../auth/services/user.service';
import { atomSetupPageUrl } from '../core/utils/atom';
import { LocaleService } from '@jump-tech-frontend/app-config';
import { DelegationService } from '../core/delegate/delegation.service';
import { UserThemeService } from '../core/user-theme.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
  savedSearchParams: SearchParameters;
  projectsConfiguration: {
    states: any[];
    actions: any[];
    layouts: any[];
    customDashboardFields?: DashboardField[];
    customDashboardFilters?: CustomDashboardFilter[];
    exportConfiguration?: ExportConfiguration;
    managerRole?: string;
  } = null;
  projectConfigurationTypes: { projectType: string }[];
  user: User;

  showProjectsButton = false;
  showScheduleButton = false;
  showSearchButton = false;
  showSetupAtomButton = false;

  readyToRenderProjects = false;
  appName = 'Pathway';
  authSubscription: Subscription;
  userSubscription: Subscription;
  hasAppBooted = false;
  userHistory = [];
  userHistory$;
  pathwaySupportUrl = 'https://support.jumptech.co.uk/pathway';
  invalidProjectType = false;
  logo = '';

  constructor(
    private authenticationService: AuthenticationService,
    public userService: UserService,
    protected customLookupService: CustomLookupService,
    protected projectConfigurationService: ProjectConfigurationService,
    protected featureAccessService: AccessService,
    protected routeAccessService: RouteAccessService,
    private pathwayConfiguration: PathwayConfigurationService,
    private spinnerService: NgxSpinnerService,
    private translationService: TranslocoService,
    private homeService: HomeService,
    public localeService: LocaleService,
    private delegationService: DelegationService,
    public themeService: UserThemeService
  ) {}

  ngOnInit() {
    this.hasAppBooted = JSON.parse(localStorage.getItem('appBooted'));

    if (this.authenticationService.isUserSignedIn) {
      this.initUserSubscription();
      this.userHistory$ = this.homeService.userHistory$;
      this.homeService.getRouteHistory();
      this.authenticationService.signOutObservable.subscribe(() => {
        localStorage.removeItem('appBooted');
        this.homeService.clearRouteHistory();
      });
      localStorage.setItem('appBooted', JSON.stringify(true));
    }
  }

  initUserSubscription() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.userObservable.subscribe(async (user: User) => {
        if (user) {
          this.user = user;

          this.showProjectsButton = this.isAccessAllowed('projects');
          this.showSearchButton = this.isAccessAllowed('projects-search');
          this.showScheduleButton = this.isInstaller() && this.isAccessAllowed('schedule');
          this.showSetupAtomButton = this.isInstaller();

          if (this.showProjectsButton) {
            this.homeService.prefetchStarted();
            // Signed in user with access to projects, so pre fetch data for projects
            this.prefetchData().then(() => {
              this.spinnerService.hide('viewProjectsSpinner');
              this.homeService.prefetchDone();
            });
          }
        }
      });
    }
  }

  isInstaller() {
    return this.isFeatureAllowed(PathwayFeature.ShowSetupAtom);
  }

  fetchConfiguration(): Promise<any> {
    // fetch data in parallel
    // call as many config calls as we can now
    // so when we getProjects() we already have config in memory
    const projectType = this.savedSearchParams?.projectType || this.projectConfigurationTypes?.[0]?.projectType;

    if (projectType) {
      this.invalidProjectType = false;
      const configCalls: Array<Promise<any>> = [
        this.delegationService.getDelegatesForProjectType(projectType),
        this.featureAccessService.getTeamsForProjectType(projectType),
        this.projectConfigurationService.getProjectConfiguration(projectType)
      ];
      return Promise.all(configCalls).then(values => {
        this.projectsConfiguration = values.pop();
      });
    } else {
      this.invalidProjectType = true;
      this.homeService.prefetchDone();
      return Promise.resolve();
    }
  }

  fetchLookups() {
    const managerRole = (this.projectsConfiguration && this.projectsConfiguration.managerRole) || 'Account Manager';
    return this.customLookupService.customLookup(`core/users/${encodeURIComponent(managerRole)}`);
  }

  fetchProjectTypes(): Promise<ProjectTypeType[]> {
    return this.projectConfigurationService.getProjectTypes(PathwayFeature.MainDashboard);
  }

  async prefetchData() {
    await this.spinnerService.show('viewProjectsSpinner');
    this.projectConfigurationTypes = await this.fetchProjectTypes();
    this.savedSearchParams = this.getSavedSearchParams();
    await this.fetchConfiguration();
    await this.fetchLookups();
    this.readyToRenderProjects = true;
  }

  isAccessAllowed(route: string, allowAdmin = false) {
    return this.routeAccessService.isRouteAccessAllowed(route, allowAdmin);
  }

  isFeatureAllowed(feature: PathwayFeature) {
    return this.featureAccessService.isFeatureAccessAllowed(feature);
  }

  getSavedSearchParams() {
    const settings = localStorage.getItem(DashboardSearchSettings);
    if (!settings) {
      return null;
    }

    const parsedSettings = JSON.parse(settings);
    if (
      parsedSettings.projectType &&
      this.projectConfigurationTypes.filter(pct => pct.projectType === parsedSettings.projectType).length > 0
    ) {
      return parsedSettings;
    }

    return null;
  }

  clearRouteHistory() {
    this.homeService.clearRouteHistory();
  }

  openAtomConfig() {
    window.open(
      atomSetupPageUrl(this.pathwayConfiguration.tenant, environment.name, this.localeService.lang),
      'atomConfig'
    );
  }

  openHelp() {
    window.open(this.pathwaySupportUrl, 'helpCenter');
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
