<ng-container *ngIf="vm">
  <div class="doc-manager-response">
    <input
      class="doc-manager-response__file-input"
      type="file"
      [id]="'responseEvidence'"
      (change)="upload($event)"
      [accept]="vm.accept"
      #fileInput
    />
    <div class="doc-manager-response__header">
      <button
        type="button"
        class="close doc-manager-response__close"
        aria-describedby="modal-title"
        (click)="closeModal('Dismiss')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="doc-manager-response__title">
        <div>
          {{ vm.title }}
          <span class="doc-manager-response__state">{{ vm.stateLabel }}</span>
        </div>
      </div>
    </div>
    <div class="doc-manager-response__banner" [class.doc-manager-response__banner--updating]="vm.isUpdating">
      {{ vm.message }}
    </div>

    <div class="doc-manager-response__content">
      <div class="doc-manager-response__action">
        <div class="doc-manager-response__action-lbl doc-manager-response__action-lbl--approved">
          {{ vm.approvedLabel }}
        </div>
        <jui-button
          size="sm"
          color="secondary"
          (click)="fileInput.click()"
          [attr.data-qa]="vm.qaUpload"
          [disabled]="vm.isUpdating"
          >{{ vm.uploadLabel }}</jui-button
        >
      </div>
      <div class="doc-manager-response__action">
        <div class="doc-manager-response__action-lbl doc-manager-response__action-lbl--rejected">
          {{ vm.rejectedLabel }}
        </div>
        <jui-button
          size="sm"
          color="secondary"
          (click)="revise()"
          [attr.data-qa]="vm.qaRevise"
          [disabled]="vm.isUpdating"
          [loading]="vm.isRevising"
          >{{ vm.reviseLabel }}</jui-button
        >
      </div>
    </div>
    <div class="doc-manager-response__actions">
      <div class="doc-manager-response__complete">
        <span class="doc-manager-response__hint">{{ vm.completeHint }}</span>
        <jui-button
          size="xs"
          color="secondary"
          class="doc-manager-response__complete-btn"
          (click)="complete()"
          [attr.data-qa]="vm.qaComplete"
          [disabled]="vm.isUpdating"
          [loading]="vm.isCompleting"
        >
          {{ vm.completeLabel }}
        </jui-button>
      </div>
      <jui-button size="sm" (click)="closeModal('Close')" color="low" [attr.data-qa]="vm.qaCloseModal">
        {{ vm.closeLabel }}
      </jui-button>
    </div>
    <app-document-pack-progress></app-document-pack-progress>
  </div>
</ng-container>
