import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private _scrollSubject = new Subject<any>();
  private _scrollObservable = this._scrollSubject.asObservable();

  /**
   * Broadcast the documentId to all subscribers
   * Delay (SetTimeout) to enable fields to come into existence before attempting to scroll.
   * @param documentId
   */
  next(documentId: any) {
    setTimeout(() => {
      this._scrollSubject.next(documentId);
    }, 100);
  }

  get scrollObservable(): Observable<any> {
    return this._scrollObservable;
  }
}
