import * as MoustacheRenderer from 'mustache-formats';
import { InstallationImagesModalComponent } from '../../project-detail/installation-images-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Directive, Input } from '@angular/core';
import { Project } from '../domain/project';
import * as url from 'url';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { get } from 'lodash';

enum RenderTypes {
  MOUSTACHE,
  STANDARD,
  NO_OP
}

export interface Attachment {
  name: string;
  data: string;
}

@Directive()
export abstract class AbstractLayout {
  @Input() project: Project;

  galleryImages = [];
  hasGalleryAccess = false;

  constructor(
    protected sanitizer: DomSanitizer,
    protected apiService: ApiService,
    protected featureAccessService: AccessService,
    protected modalService: NgbModal
  ) {}

  replaceTemplateVariables(template: string, projectData: Record<string, unknown>, returnAsList?: boolean): string {
    switch (this._getRenderType(template)) {
      case RenderTypes.MOUSTACHE:
        return this.moustacheRender(template, projectData);
      case RenderTypes.STANDARD:
        return this.standardRender(template, projectData, returnAsList);
      default:
        return this.noOpRender(template);
    }
  }

  private noOpRender(template: string) {
    return template;
  }

  private standardRender(template: string, projectData: unknown, returnAsList?: boolean) {
    const matches = this._getReplaceableMatches(template);
    const defaultReplacement = '';
    if (returnAsList && matches.length === 1 && template[0] === '{' && template[template.length - 1] === '}') {
      const key = matches[0].replace(/[{}]/g, '');
      return get(projectData, key) || defaultReplacement;
    }
    if (matches) {
      matches.forEach(match => {
        const matchParts = match.replace(/[{}]/g, '').split('.');
        const webFormValue = this._getFormPartValue(projectData, matchParts);
        template = template.replace(match, webFormValue || defaultReplacement);
      });
    }
    return template;
  }

  private moustacheRender(template: string, projectData: Record<string, unknown>) {
    return MoustacheRenderer.render(template, projectData);
  }

  private _getRenderType(template) {
    const moustacheRenderer = /\{\{.+\}\}/g.test(template);
    const standardRenderer = /\{[A-Za-z0-9._]+\}/g.test(template);

    return moustacheRenderer ? RenderTypes.MOUSTACHE : standardRenderer ? RenderTypes.STANDARD : RenderTypes.NO_OP;
  }

  private _getReplaceableMatches(template: string) {
    return template.match(/\{[A-Za-z0-9._]+\}/g);
  }

  private _getFormPartValue(formPart: any, matchParts: string[], idx = 0) {
    if (!formPart) {
      return '';
    }
    const value = formPart[matchParts[idx]];

    if (Array.isArray(value)) {
      let text = '';
      for (const item of value) {
        if (item.key && item.value) {
          text += `<div>${item.key || ''}: ${item.value || ''}</div>`;
        }
      }
      return text;
    } else {
      return typeof value === 'object' ? this._getFormPartValue(value, matchParts, idx + 1) : value;
    }
  }

  protected initialiseGallery(data) {
    this.hasGalleryAccess = this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.GalleryAccess, true);
    this.galleryImages = [];
    this.processImages(data);
  }

  private processImages(data) {
    const dataKeys = Object.keys(data);
    for (const key of dataKeys) {
      if (!data[key]) {
        continue;
      }
      if (this.isGalleryImage(data[key])) {
        this.galleryImages.push(key);
      }

      if (data[key] instanceof Array && data[key].length) {
        let i = 0;
        for (const projectDataItem of data[key]) {
          if (this.isGalleryImage(projectDataItem)) {
            this.galleryImages.push(`${key}_${i}`);
            i++;
          }
        }
      }
    }
  }

  private isGalleryImage(data) {
    return (
      typeof data === 'string' &&
      (data.indexOf('https://s3') === 0 ||
        data.indexOf('data:image') === 0 ||
        data.indexOf(`https://relay-uploads`) === 0 ||
        (data.indexOf('https://api') === 0 && data.indexOf('store/') > -1))
    );
  }

  openImagesModal(data, currentImage: string) {
    this.initialiseGallery(data);
    const modalRef = this.modalService.open(InstallationImagesModalComponent, { size: 'lg' });
    modalRef.componentInstance.galleryImages = this.galleryImages;
    modalRef.componentInstance.currentImage = currentImage;
    modalRef.componentInstance.projectData = data;
  }

  isUrl(dataItem) {
    return typeof dataItem === 'string' && dataItem.indexOf('http') === 0;
  }

  getImageSrc(dataItem: string | Attachment, size = 's'): string | null {
    if (this.isAttachment(dataItem)) {
      return null; // No thumbnails for attachments
    }
    if (this.isUrl(dataItem)) {
      const urlParts = url.parse(dataItem as string);
      let urlRoot = `${dataItem}${urlParts.query ? '&' : '?'}`;
      if (size !== 'o') {
        urlRoot += `size=${size}`;
      }
      return urlRoot;
    }
    return dataItem as string;
  }

  isAttachment(dataItem) {
    return (
      dataItem &&
      typeof dataItem === 'object' &&
      Object.prototype.hasOwnProperty.call(dataItem, 'data') &&
      Object.prototype.hasOwnProperty.call(dataItem, 'name')
    );
  }
}
