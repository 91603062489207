import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class W3wService {
  constructor(private httpClient: HttpClient) {}

  lookupByLatLng() {
    return this.httpClient.get(environment.w3wEndpoint, { headers: { 'X-Api-Key': environment.w3wApiKey } });
  }
}
