import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { FeatureFlagService } from './feature-flag.service';
import { MAIN_PATH } from '../../app.routes';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard {
  constructor(private featureFlagsService: FeatureFlagService, private router: Router) {}
  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    const {
      data: { feature }
    } = route;

    if (feature) {
      const isFeatureEnabled = await this.featureFlagsService.isFeatureEnabled(feature);
      if (isFeatureEnabled) {
        return true;
      }
    }
    this.router.navigate([MAIN_PATH]).then();
    return false;
  }
}
