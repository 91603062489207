import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpGateway {
  constructor(private httpClient: HttpClient) {}

  public async get(path: string, options) {
    const parseBody = options.parseBody;
    delete options.parseBody;
    return await firstValueFrom(
      this.httpClient.get<string>(path, options).pipe(
        map((response: HttpResponse<string>) => {
          if (parseBody) {
            return JSON.parse(response.body);
          }
          return response;
        })
      )
    );
  }

  public async post(path: string, form, options) {
    return await firstValueFrom(this.httpClient.post(path, form, options).pipe(map((response: any) => response)));
  }

  public async put(path: string, params, options) {
    return await firstValueFrom(this.httpClient.put(path, params, options).pipe(map((response: any) => response)));
  }

  public putObservable(path: string, params, options) {
    return this.httpClient.put(path, params, options).pipe(map((response: any) => response));
  }

  public async delete(path: string, options) {
    return await firstValueFrom(this.httpClient.delete(path, options).pipe(map((response: any) => response)));
  }
}
