import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from '../core/domain/project';
import { ApiService } from '../core/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectAction } from '../core/domain/project.action';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AlertModalComponent } from '../shared/modals/alert-modal.component';
import { CardControlService } from '../core/card-control.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-status-transition-modal',
  templateUrl: './status-transition-modal.component.html',
  styleUrls: ['project-detail.component.scss']
})
export class StatusTransitionModalComponent implements OnInit {
  @Input() project: Project;
  @Input() action: ProjectAction;
  @Input() resources: any;
  @Output() submitCompleteHook: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  projectAndResourceData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private cardControlService: CardControlService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private el: ElementRef,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({});
  }

  get mergedResources() {
    return {
      ...this.resources[this.action.confirmation.layout['dataSource']],
      ...{ ...this.project, ...this.project.data }
    };
  }

  get projectAndJobData() {
    const jobObject = {
      jobs: {}
    };
    for (let index = 0; index < this.project.jobs.length; index++) {
      jobObject.jobs[this.project.jobs[index].type] = this.project.jobs[index];
      jobObject.jobs[index] = this.project.jobs[index];
    }
    return { ...this.project, ...this.project.data, ...jobObject };
  }

  get projectAndResources() {
    if (!this.projectAndResourceData) {
      this.projectAndResourceData = {
        ...this.projectAndJobData,
        resources: {}
      };
      for (const resource of this.project.resources) {
        this.projectAndResourceData.resources[resource.type] = resource.summary;
      }
    }
    return this.projectAndResourceData;
  }

  setFormData(form: UntypedFormGroup) {
    this.form = form;
  }

  submit() {
    if (this.form && this.form.invalid) {
      this.validateAllFormFields(this.form);
      document.querySelector('.modal').scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    this.spinnerService.show('statusTransitionModal').catch();
    const data = this.cardControlService.dataFromForm(this.form);
    this.apiService.projectAction(this.project.id, this.action, data).subscribe(
      () => {
        if (this.action.transition) {
          this.project.status.status = this.action.transition;
        }
        this.submitCompleteHook.emit({ transitionType: this.action.action, data: this.form.value });
        this.activeModal.close({ data, action: this.action, project: this.project });
        this.spinnerService.hide('statusTransitionModal').catch();
      },
      error => {
        console.error(error);
        const modalRef = this.modalService.open(AlertModalComponent, { size: 'sm' });
        modalRef.componentInstance.title = this.translocoService.translate('project.modals.error.title');
        modalRef.componentInstance.messages = [
          error.error?.errorMessage || this.translocoService.translate('project.modals.error.messages.unknown')
        ];
        this.spinnerService.hide('statusTransitionModal').catch();
      }
    );
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
