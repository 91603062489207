import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../../alert.service';
import { IProjectTypeAssignmentVm, ProjectAssignmentDisplay } from '../../domain';
import { ProjectTypeAssignmentPresenter } from './project-type-assignment.presenter';

@Component({
  selector: 'app-assignment-project-types',
  templateUrl: './project-type-assignment.component.html',
  styleUrls: ['./project-type-assignment.component.scss']
})
export class ProjectTypeAssignmentComponent implements OnInit, OnDestroy {
  vm: IProjectTypeAssignmentVm;

  constructor(
    private presenter: ProjectTypeAssignmentPresenter,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService
  ) {}

  async ngOnInit() {
    try {
      await this.spinnerService.show('projectTypeAssignmentSpinner');
      await this.presenter.load(generatedVm => {
        this.vm = generatedVm;
      });
    } finally {
      await this.spinnerService.hide('projectTypeAssignmentSpinner');
    }
  }

  ngOnDestroy() {
    this.presenter.cleanup();
  }

  async updateAction(row: ProjectAssignmentDisplay, selected: string) {
    row.actionId = selected?.length > 0 ? selected[0] : null;
    try {
      await this.spinnerService.show('projectTypeAssignmentSpinner');
      await this.presenter.saveProjectAssignment(row);
    } catch (e) {
      console.error('Unable to save mappings', e.message);
      await this.alertService.alert('common.error', 'autoAssignment.unableToSaveProjectTypeSetting');
    } finally {
      await this.spinnerService.hide('projectTypeAssignmentSpinner');
    }
  }

  async updateEnabled(row: ProjectAssignmentDisplay, value: boolean) {
    row.enabled = value;
    try {
      await this.spinnerService.show('projectTypeAssignmentSpinner');
      await this.presenter.saveProjectAssignment(row);
    } catch (e) {
      console.error('Unable to save mappings', e.message);
      await this.alertService.alert('common.error', 'autoAssignment.unableToSaveProjectTypeSetting');
    } finally {
      await this.spinnerService.hide('projectTypeAssignmentSpinner');
    }
  }
}
