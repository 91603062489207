import { UntypedFormGroup } from '@angular/forms';

export interface ISearchDataVm {
  searchTerm: string;
  tenant: string;
  results: ISearchResultVm[];
  showResults: boolean;
  showNoResults: boolean;
  resultFoundText: string;
  isMaxResultsLimit: boolean;
  maxResultText: string;
}

export interface ISearchBarVm {
  isSearching: boolean;
}

export interface ISearchBarFVm {
  form: UntypedFormGroup;
}

export interface ISearchResultVm {
  id: string;
  name: string;
  ref: string;
  address: string;
  email: string;
  projectType: string;
  status: string;
  phone: string;
  type: string;
  owner: string;
  assignedTo: string;
  scheduledDisplayDate?: string;
}

export enum SearchTarget {
  inline = 'inline',
  main = 'main'
}
