import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root' // Ensures we get a singleton even with lazy-loading
})
export class CustomLookupService {
  constructor(private apiService: ApiService) {}

  private lookups: { [path: string]: any } = {};

  async customLookup(urlPath: string): Promise<any[]> {
    if (!this.lookups[urlPath]) {
      this.lookups[urlPath] = await this.apiService.customLookup(urlPath);
    }
    return this.lookups[urlPath];
  }

  flush() {
    this.lookups = {};
  }
}
