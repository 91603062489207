import { Injectable } from '@angular/core';
import { LinkedProjectsRepository } from '../linked-projects.repository';
import { IMessageDm, IMessageVm } from '../linked-projects.model';

@Injectable()
export class LinkedProjectsMessagesPresenter {
  constructor(private repository: LinkedProjectsRepository) {}

  load(callback) {
    this.repository.getMessages((dataDm: IMessageDm) => {
      const viewModel: IMessageVm = {
        message: dataDm ? dataDm.message : null,
        showLoader: dataDm ? dataDm.showLoader : null,
        spinnerName: dataDm ? dataDm.spinnerName : null,
        qaSpinner: dataDm ? dataDm.spinnerName : null,
        qaMessage: 'linkedProjectsMessageText'
      };
      callback(viewModel);
    });
  }

  cleanUp() {
    this.repository.unsubscribe();
  }
}
