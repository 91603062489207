import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContactLogRepository } from '../contact-log.repository';
import { IContactLogModalVm } from '../contact-log.model';

@Injectable()
export class ContactLogModalPresenter {
  constructor(private repository: ContactLogRepository) {}

  async load(vm$: BehaviorSubject<IContactLogModalVm>) {
    const translations = this.repository.getModalTranslations();
    const { form, formErrors } = this.repository.getContactLogForm();
    vm$.next({
      ...translations,
      form,
      formErrors
    });
  }
}
