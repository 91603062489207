<div>
  <div>
    <table class="styled-table">
      <thead>
        <tr>
          <td class="no-wrap">{{ 'common.createdBy' | transloco }}</td>
          <td class="no-wrap">{{ 'common.editedBy' | transloco }}</td>
          <td>{{ 'common.note' | transloco }}</td>
          <td class="action-row-header">{{ 'common.actions' | transloco }}</td>
        </tr>
      </thead>
      <tbody *ngIf="notes.length">
      <ng-container *ngFor="let note of notes; index as i">
        <tr *ngIf="note.type !== 'CONTACT_LOG'">
          <td>{{ note.created_by_name }}<br />{{ note.created_on | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td>
            <span *ngIf="note.edited_on"
              >{{ note.edited_by_name }}<br />{{ note.edited_on | date: 'dd/MM/yy HH:mm' }}</span
            >
          </td>
          <td [attr.data-qa]="'note'">{{ note.note }}</td>
          <td>
            <div *ngIf="!readOnly && note.canEdit" class="row-actions">
              <jui-button
                class="row-actions__item"
                color="secondary"
                size="xs"
                [attr.data-qa]="'editNoteButton'"
                (click)="editNote(note, i)"
                >{{ 'common.edit' | transloco }}
              </jui-button>
              <jui-button
                class="row-actions__item"
                size="xs"
                color="danger"
                display="ghost"
                [attr.data-qa]="'deleteNoteButton'"
                (click)="deleteNote(i)"
                >{{ 'common.delete' | transloco }}
              </jui-button>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div class="main-action">
    <div *ngIf="!readOnly" class="add-note">
      <jui-button size="sm" (click)="addNote()" [attr.data-qa]="'addNoteButton'">
        <span slot="icon" class="material-icons">playlist_add</span>
        {{ 'notes.Buttons.addNote' | transloco }}
      </jui-button>
    </div>
  </div>
</div>
