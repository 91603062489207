export const ERROR_PATH = 'error';
export const SEARCH_PATH = 'projects-search';
export const PROJECTS_PATH = 'projects';
export const PROJECTS_DETAILS_PATH = 'project';
export const DASHBOARD_PATH = 'dashboard';
export const MAIN_PATH = 'home';
export const PLANNING_MAP_PATH = 'planning-map';
export const LOGIN_PATH = 'login';
export const RESET_PASSWORD_PATH = 'resetPassword';
export const CHANGE_PASSWORD_PATH = 'changePassword';
export const REGISTER_PATH = 'register';
export const CONFIGURATION_PATH = 'configuration';
export const FREE_LEADS_PATH = 'lead-management';
export const FREE_LEADS_REGISTER_PATH = 'lead-management-register';
export const IS_FREE_LEADS_DOMAIN = window.location.hostname.substring(0, 6) === 'leads.';
export const APP_HOME_PATH = IS_FREE_LEADS_DOMAIN ? FREE_LEADS_PATH : MAIN_PATH;
export const APP_VERSION_FILE = 'app-version.json';
export const CLEAR_SITE_DATA = 'clear-site-data';
