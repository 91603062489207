import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { LinkedProjectsPresenter } from './linked-projects.presenter';
import {
  IGroupVm,
  ILinkedProjectsVm,
  MAX_GROUP_NAME_LENGTH,
  MIN_GROUP_NAME_LENGTH,
  SHOW_REMAINING_GROUP_BUFFER
} from './linked-projects.model';

@Component({
  selector: 'app-linked-projects',
  templateUrl: './linked-projects.component.html',
  styleUrls: ['./linked-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedProjectsComponent implements OnChanges, OnDestroy {
  @Input() projectId: string;
  @Input() quietReload: boolean;
  vm: ILinkedProjectsVm;

  // UI Ephemeral state
  uiCanCreate = false;
  uiCurrentSearchTerm = null;
  uiMaxGroupLen = MAX_GROUP_NAME_LENGTH.toString();

  constructor(private presenter: LinkedProjectsPresenter, private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (Object.hasOwnProperty.call(changes, 'projectId')) {
      this.presenter.load(
        generatedVm => {
          this.vm = generatedVm;
          this.cd.markForCheck();
          this.uiCurrentSearchTerm = null;
        },
        this.projectId,
        this.quietReload
      );
    }
  }

  ngOnDestroy() {
    this.presenter.cleanUp();
  }

  async linkToGroup(evt: IGroupVm) {
    await this.presenter.linkProjectToGroup(evt.id);
  }

  unlinkProject(groupId, projectId) {
    this.presenter.unlinkProjectFromGroup(groupId, projectId);
  }

  goToProject(link) {
    this.presenter.goToProject(link);
  }

  deleteGroup(evt, groupId) {
    evt.stopPropagation();
    this.presenter.deleteGroup(groupId);
    this.cd.markForCheck();
  }

  search(evt) {
    // UI Ephemeral state
    this.uiCanCreate = evt.term.length >= MIN_GROUP_NAME_LENGTH;
  }

  get showRemaining(): boolean {
    return this.uiCurrentSearchTerm && this.uiCurrentSearchTerm.length > MIN_GROUP_NAME_LENGTH + SHOW_REMAINING_GROUP_BUFFER;
  }

  get remainingText(): string {
    return `${MAX_GROUP_NAME_LENGTH - this.uiCurrentSearchTerm.length} / ${MAX_GROUP_NAME_LENGTH}`;
  }

  get isLimitReached(): boolean {
    return this.uiCurrentSearchTerm && this.uiCurrentSearchTerm.length === MAX_GROUP_NAME_LENGTH;
  }

  updateSearchTerm(evt) {
    this.uiCurrentSearchTerm = evt.target.value;
  }

  // arrow function to keep the 'this' context for the class
  addGroupFn = async term => {
    await this.presenter.createGroup(term);
  };
}
