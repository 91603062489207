import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MyOrdersHistoryPresenter } from './order-history.presenter';
import { OrderHistoryVm } from '../my-orders.model';

@Component({
  selector: 'pathway-my-orders-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyOrdersHistoryComponent implements OnChanges {
  @Input() refresh = false;
  vm$: BehaviorSubject<OrderHistoryVm> = new BehaviorSubject<OrderHistoryVm>(null);

  constructor(private presenter: MyOrdersHistoryPresenter) {}

  viewOrder(order): void {
    this.presenter.viewOrder(order);
  }

  ngOnChanges() {
    this.presenter.load(this.vm$);
  }
}
