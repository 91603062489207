import { Injectable } from '@angular/core';
import { EVENT_TYPE_FIELD_LIST } from '../utils/schedule-constants';
import { CustomLookupService } from '../../core/custom-lookup.service';
import { TranslocoService } from '@ngneat/transloco';
import { ToasterService } from '../../toast/toast-service';
import { environment } from '../../../environments/environment';
import { renderEvent, resourcesFromUsers } from '../utils/schedule.helper';
import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';
import { EventInfo, Resource, ScheduleTypeListItem } from '../utils/schedule-types';
import { Job } from '../../core/domain/job';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  typeList: ScheduleTypeListItem[];
  engineersDropdown: DropDownElement[];
  allEngineers: Resource[] = [];
  selectedEngineers: Resource[] = [];

  constructor(
    private customLookupService: CustomLookupService,
    private translationService: TranslocoService,
    private toast: ToasterService
  ) {}

  async getScheduleEventTypes(tenant) {
    if (!this.typeList) {
      const ds = {
        type: 'ScheduleEventTypes',
        tenant: tenant,
        fields: EVENT_TYPE_FIELD_LIST
      };
      const url = `tenant-settings/list/${ds.type}?tenant=${ds.tenant}&fields=${ds.fields.join(',')}`;
      this.typeList = await this.customLookupService.customLookup(url);

      // translate and sort
      this.typeList = this.typeList
        .map(x => {
          const translatedName = this.translationService.translate(x.translation);
          return { ...x, name: translatedName };
        })
        .sort((a, b) => {
          return a.sort - b.sort;
        });
    }
    return this.typeList;
  }

  getEventType(eventType) {
    if (this.typeList) {
      return this.typeList.find(x => x.id === eventType);
    }
  }

  isValidEventType(eventType): boolean {
    if (eventType === 'Job') {
      return true;
    }
    return !!this.getEventType(eventType);
  }

  async fetchEngineers() {
    if (!this.engineersDropdown) {
      let resourcePath = 'core/users/engineer';
      if (environment.name === 'docker') {
        resourcePath = resourcePath.replace(/^core\/|projects\//, '');
      }
      this.allEngineers = resourcesFromUsers(
        (await this.customLookupService.customLookup(resourcePath)).sort((a, b) => a.key.localeCompare(b.key))
      );
      this.engineersDropdown = this.allEngineers.map(r => ({ id: r.id, name: r.title }));
      this.selectedEngineers = this.allEngineers;
    }
    return { dropdown: this.engineersDropdown, all: this.allEngineers };
  }

  getEngineerName(engineerId: string) {
    return this.engineersDropdown.find(x => x.id === engineerId)?.name;
  }

  getEventTitleFromProps(event: any) {
    if (event.title) {
      return event.title;
    } else {
      return this.typeList.find(x => x.id === event.eventType)?.name;
    }
  }

  getEventTitleFromForm(formData: any) {
    if (formData.title) {
      return formData.title;
    } else {
      return this.typeList.find(x => x.id === formData.type)?.name;
    }
  }

  showDeleteSuccessToast(formData) {
    const deleteSuccessMessageSuffix = this.translationService.translate('schedule.deleteSuccessMessage');
    const deleteSuccessTitle = this.translationService.translate('schedule.saveSuccessTitle');
    const eventTitle = this.getEventTitleFromForm(formData);
    const successMessage = `<strong>${eventTitle} (${this.getEngineerName(
      formData.engineer
    )})</strong> ${deleteSuccessMessageSuffix}`;
    this.toast.pop('success', deleteSuccessTitle, successMessage);
  }

  showSuccessToast(formData) {
    const saveSuccessMessagePrefix = this.translationService.translate('schedule.saveSuccessMessagePrefix');
    const saveSuccessTitle = this.translationService.translate('schedule.saveSuccessTitle');
    const eventTitle = this.getEventTitleFromForm(formData);
    const successMessage = `<strong>${saveSuccessMessagePrefix}: ${eventTitle} (${this.getEngineerName(
      formData.engineer
    )})</strong>`;
    this.toast.pop('success', saveSuccessTitle, successMessage);
  }

  showErrorToast() {
    const message = this.translationService.translate('schedule.saveErrorMessage');
    const title = this.translationService.translate('schedule.saveErrorTitle');
    this.toast.pop('error', title, message);
  }

  showDeleteErrorToast() {
    const message = this.translationService.translate('schedule.deleteErrorMessage');
    const title = this.translationService.translate('schedule.deleteErrorTitle');
    this.toast.pop('error', title, message);
  }

  async handleEventRender(evt: EventInfo) {
    const allDayTranslation = this.translationService.translate('common.allDay');
    try {
      await this.fetchEngineers();
      let eventType: any = evt?.event?.extendedProps?.eventType;
      let engineerName = (evt?.event?.extendedProps as Job).assignedToDisplayName;
      if (eventType !== 'Job') {
        eventType = this.getEventType(evt.event.extendedProps.eventType);
        engineerName = this.getEngineerName(evt.event.extendedProps.userIds[0]);
      }
      renderEvent(evt, eventType, engineerName, { allDay: allDayTranslation });
    } catch (e) {
      console.log(`There was an issue rendering events: ${e}`);
    }
  }
}
