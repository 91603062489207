import { Injectable } from '@angular/core';
import { SearchRepository } from '../search.repository';
import { SearchTarget } from '../search.model';

@Injectable()
export class SearchResultsInlinePresenter {
  constructor(private repository: SearchRepository) {}

  public async load(callback) {
    await this.repository.getSearchResults(SearchTarget.inline, callback);
  }

  public async viewProject(id: string) {
    await this.repository.viewProject(id);
  }
}
