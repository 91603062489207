import { ISearchProvider } from '../../domain/search-provider';
import { IAddressV2 } from '../../domain/address-v2';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import { PostcoderRetrieveResponse, PostcoderSearchResponse } from './postcoder.model';

export function getPostcoderSearchProvider(httpClient: HttpClient, countryCode: 'gb' | 'uk' | 'ie'): ISearchProvider {
  const apiKeys = {
    uk: 'PCWNZ-X257D-QJCWC-M36YM',
    ie: 'PCWKX-6SMCZ-6ZVHC-T7F5R'
  };
  if (countryCode === 'gb') {
    countryCode = 'uk';
  }
  const apiUrlBase = 'https://ws.postcoder.com/pcw/autocomplete';
  const enableFacets = false;
  return {
    name: 'postcoder',
    search: async (searchTerm: string, pathFilter?: string) => {
      let params: HttpParams = new HttpParams({
        fromObject: {
          query: searchTerm,
          country: countryCode,
          maximumresults: 30,
          apikey: apiKeys[countryCode as 'uk' | 'ie'],
          enablefacets: enableFacets
        }
      });
      // Only applicable if enablefacets is true
      if (pathFilter) {
        params = params.append('pathfilter', pathFilter);
      }
      const postcoderSearchResponse = await firstValueFrom(
        httpClient.get<PostcoderSearchResponse[]>(`${apiUrlBase}/find`, { params }).pipe(catchError(throwError))
      );
      return postcoderSearchResponse?.map(result => ({
        id: result.id,
        description: getSummaryForType(result),
        type: result.type
      }));
    },
    getResult: async (id: string, searchTerm: string) => {
      const params: HttpParams = new HttpParams({
        fromObject: {
          id,
          query: searchTerm,
          country: countryCode,
          apikey: apiKeys[countryCode as 'uk' | 'ie'],
          lines: 4,
          addtags: 'latitude,longitude'
        }
      });
      const loqateRetrieveResponse = await firstValueFrom(
        httpClient.get<PostcoderRetrieveResponse[]>(`${apiUrlBase}/retrieve`, { params }).pipe(catchError(throwError))
      );
      return addressResponseToAddressV2Transform(loqateRetrieveResponse[0], countryCode as 'uk' | 'ie');
    }
  };
}

function addressResponseToAddressV2Transform(address: PostcoderRetrieveResponse, countryCode: 'uk' | 'ie'): IAddressV2 {
  return {
    line1: address.addressline1,
    line2: address.addressline2,
    line3: [address.addressline3, address.addressline4].filter(a => a).join(', '),
    town: address.posttown ?? '',
    county: address.posttown === address.county ? '' : address.county ?? '',
    postCode: address.postcode ?? '',
    latitude: address?.latitude ?? undefined,
    longitude: address?.longitude ?? undefined,
    countryCode: countryCode === 'uk' ? 'gb' : countryCode
  };
}

function getSummaryForType(result: PostcoderSearchResponse) {
  const locationSummaryParts = result.locationsummary.split(',');
  return [result.summaryline, locationSummaryParts[0], locationSummaryParts?.[1] ?? ''].join(', ');
}
