import { ModuleWithProviders, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigOption, FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LanguageService } from './types/i18n/language.service';
import { ArrayTypeComponent } from './types/array-type.component';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CodeTypeComponent } from './types/code-type.component';
import { RepeatTypeComponent } from './types/repeat-section.type';
import { CardWrapperComponent } from './wrappers/card-wrapper.component';
import { QuestionWrapperComponent } from './wrappers/question-wrapper.component';
import { ACE_CONFIG, AceConfigInterface, AceModule } from 'ngx-ace-wrapper';
import { HorizontalFieldWrapperComponent } from './wrappers/horizontal-field-wrapper.component';
import { HtmlTypeComponent } from './types/html-type.component';
import { InfoGroupWrapperComponent } from './wrappers/info-group-wrapper.component';
import { MultiSelectTypeComponent } from './types/multi-select-type.component';
import { MultipleSelectionDropdownComponent } from './types/multi-select/multiple-selection-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionWrapperComponent } from './wrappers/accordion-wrapper.component';
import { AddressWrapperComponent } from './wrappers/address.wrapper';
import { RepeatStateTypeComponent } from './types/repeat-state.type';
import { I18nLanguageComponent } from './types/i18n/i18n-language.component';
import { I18nInputTypeComponent } from './types/i18n-input-type.component';
import { I18nCodeTypeComponent } from './types/i18n-code-type.component';
import { I18nTypeComponent } from './types/i18n/i18n-type.component';
import { I18nHtmlTypeComponent } from './types/i18n-html-type.component';
import { I18nTextTypeComponent } from './types/i18n-text-type.component';
import { RemoveRepeatedItemService } from './types/remove-repeated-item.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { CheckboxFieldWrapperComponent } from './wrappers/checkbox-wrapper.component';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {};

const formlyConfig: ConfigOption = {
  extras: { checkExpressionOn: 'modelChange', lazyRender: true, resetFieldOnHide: true },
  types: [
    { name: 'code', component: CodeTypeComponent },
    { name: 'html', component: HtmlTypeComponent },
    { name: 'repeat', component: RepeatTypeComponent },
    { name: 'repeat-state', component: RepeatStateTypeComponent },
    { name: 'array', component: ArrayTypeComponent },
    { name: 'multi-select', component: MultiSelectTypeComponent }
  ],
  validationMessages: [{ name: 'required', message: 'This field is required' }],
  wrappers: [
    { name: 'address-field', component: AddressWrapperComponent },
    { name: 'card', component: CardWrapperComponent },
    { name: 'question', component: QuestionWrapperComponent },
    { name: 'infoGroup', component: InfoGroupWrapperComponent },
    { name: 'horizontal-field', component: HorizontalFieldWrapperComponent },
    { name: 'checkbox-field', component: CheckboxFieldWrapperComponent },
    { name: 'accordion', component: AccordionWrapperComponent }
  ]
};

@NgModule({
  declarations: [
    ArrayTypeComponent,
    CodeTypeComponent,
    HtmlTypeComponent,
    HorizontalFieldWrapperComponent,
    CheckboxFieldWrapperComponent,
    RepeatTypeComponent,
    AddressWrapperComponent,
    CardWrapperComponent,
    QuestionWrapperComponent,
    RepeatStateTypeComponent,
    InfoGroupWrapperComponent,
    MultipleSelectionDropdownComponent,
    MultiSelectTypeComponent,
    AccordionWrapperComponent,
    I18nLanguageComponent,
    I18nInputTypeComponent,
    I18nCodeTypeComponent,
    I18nTypeComponent,
    I18nHtmlTypeComponent,
    I18nTextTypeComponent
  ],
  providers: [
    LanguageService,
    RemoveRepeatedItemService,
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG
    }
  ],
  imports: [
    CommonModule,
    AceModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot(formlyConfig),
    DragDropModule,
    NgSelectModule,
    NgxSpinnerModule,
    FormsModule,
    NgbModule,
    CoreComponentsAngularModule
  ],
  exports: [
    FormlyModule,
    ArrayTypeComponent,
    CodeTypeComponent,
    HtmlTypeComponent,
    HorizontalFieldWrapperComponent,
    CheckboxFieldWrapperComponent,
    RepeatTypeComponent,
    AddressWrapperComponent,
    CardWrapperComponent,
    QuestionWrapperComponent,
    RepeatStateTypeComponent,
    InfoGroupWrapperComponent,
    MultiSelectTypeComponent,
    AccordionWrapperComponent,
    I18nLanguageComponent,
    I18nInputTypeComponent,
    I18nCodeTypeComponent,
    I18nTypeComponent,
    I18nHtmlTypeComponent,
    I18nTextTypeComponent
  ]
})
export class CoreFormlyModule {
  public static forChild(config: ConfigOption): ModuleWithProviders<CoreFormlyModule | FormlyModule>[] {
    return [
      { ngModule: CoreFormlyModule, providers: [] },
      FormlyModule.forChild({
        ...formlyConfig,
        types: [...formlyConfig.types, ...config.types]
      })
    ];
  }
}
