import { Component } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { DataSharing, SubTenant, TenantSettingType } from '../domain/types';
import { ApiService } from '../../../core/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectConfigurationService } from '../../../core/project-configuration.service';
import { AccessService, PathwayFeature } from '../../../auth/services/access.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-data-sharing',
  template: `
    <div>
      <div *ngIf="form" [formGroup]="form">
        <div class="data-sharing-form">
          <div class="data-sharing-form__top">
            <div>{{ 'userManagement.enableSharing' | transloco }}:</div>
            <div class="pt-2 btn-group btn-group-toggle" ngbRadioGroup formControlName="enabled">
              <label
                *ngFor="let opt of [false, true]"
                ngbButtonLabel
                [class]="'data-sharing-form__radio btn btn-sm button-group-spaced ' + getEnabledButtonClass(opt)"
              >
                <input ngbButton type="radio" [value]="opt" #buttonGroupInput />{{
                  opt ? ('common.yes' | transloco) : ('common.no' | transloco)
                }}
              </label>
            </div>
          </div>
          <div class="data-sharing-form__actions">
            <ng-container *ngIf="dataSharingEnabled">
              <jui-button
                [disabled]="form.invalid"
                size="sm"
                [color]="form.invalid ? 'low' : 'primary'"
                class="data-sharing-form__action"
                (click)="onSubmit()"
              >
                <span slot="icon" class="material-icons">check</span>
                {{ 'userManagement.saveDelegation' | transloco }}
              </jui-button>
              <jui-button size="sm" color="secondary" (click)="addDelegation()" class="data-sharing-form__action">
                <span slot="icon" class="material-icons">playlist_add</span>
                {{ 'userManagement.addRow' | transloco }}
              </jui-button>
            </ng-container>
          </div>
        </div>

        <div *ngIf="dataSharingEnabled" class="data-sharing-form__rows" formArrayName="projectDelegation">
          <div
            *ngFor="let projectDelegationItem of asFormArray(form, 'projectDelegation').controls; index as i"
            [formGroup]="asFormGroup(projectDelegationItem)"
            class="data-sharing-form__row"
          >
            <div class="data-sharing-form__row-item">
              <label class="data-sharing-form__label">{{ 'common.projectType' | transloco }}</label>
              <app-multi-select
                *ngIf="projectTypes && projectTypes.length"
                [singleSelection]="true"
                [selectedItems]="[projectDelegationItem.get('projectType').value]"
                [items]="toSelectItems(projectTypes)"
                (itemsChanged)="setFromDropdown($event, 'projectType', i)"
              ></app-multi-select>
            </div>

            <div class="data-sharing-form__row-item">
              <label class="data-sharing-form__label">{{ 'common.showIf' | transloco }}</label>
              <textarea class="data-sharing-form__text" id="showIf" formControlName="showIf" rows="5"></textarea>
              <div class="text-danger" *ngIf="projectDelegationItem?.get('showIf')?.invalid">
                {{ 'userManagement.validation.invalidJson' | transloco }}
              </div>
            </div>

            <div class="data-sharing-form__row-item">
              <label class="data-sharing-form__label">{{ 'common.tenant' | transloco }}</label>
              <app-multi-select
                *ngIf="tenants && tenants.length"
                [singleSelection]="true"
                [selectedItems]="[projectDelegationItem.get('tenant').value]"
                [items]="toSelectItems(tenants)"
                (itemsChanged)="setTenantFromDropdown($event, 'tenant', i)"
              ></app-multi-select>
              <div *ngIf="subTenantsForTenant(projectDelegationItem)">
                <label class="data-sharing-form__label data-sharing-form__label--multi">{{
                  'common.subTenant' | transloco
                }}</label>
                <app-multi-select
                  [singleSelection]="false"
                  [selectedItems]="toSelectedSubTenantsItems(projectDelegationItem)"
                  [items]="subTenantsToSelectItems(projectDelegationItem)"
                  (itemsChanged)="setSubTenantsFromDropdown($event, i)"
                ></app-multi-select>
              </div>
            </div>

            <div class="data-sharing-form__row-item data-sharing-form__row-item--flex">
              <div class="data-sharing-form__show">
                <label class="data-sharing-form__label">{{ 'userManagement.showOnCreation' | transloco }}</label>
                <div
                  class="data-sharing-form__btn-group btn-group btn-group-toggle"
                  ngbRadioGroup
                  formControlName="showOnCreation"
                >
                  <label
                    *ngFor="let opt of [false, true]"
                    ngbButtonLabel
                    [class]="'btn btn-xs button-group-spaced ' + getDelegationButtonClass(projectDelegationItem, opt)"
                  >
                    <input ngbButton type="radio" [value]="opt" />{{
                      opt ? ('common.yes' | transloco) : ('common.no' | transloco)
                    }}
                  </label>
                </div>
              </div>

              <div class="data-sharing-form__remove">
                <label class="data-sharing-form__label">&nbsp;</label>
                <jui-button size="xs" color="danger" display="ghost" (click)="removeDelegation(i)">
                  {{ 'common.delete' | transloco }}
                </jui-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ngx-spinner
        bdColor="var(--jds-theme-spinner-bd-rgba)"
        size="large"
        [name]="spinnerName"
        type="line-scale"
      ></ngx-spinner>
    </div>
  `,
  styleUrls: ['./data-sharing.component.scss']
})
export class DataSharingComponent {
  projectTypes: string[];
  tenants: any[];
  subTenants: { [key: string]: SubTenant[] } = {};
  defaultDataSharing: DataSharing[];
  existingSharingData: DataSharing[];
  form: UntypedFormGroup;
  spinnerName = 'dataSharingSpinner';

  get dataSharingEnabled() {
    return this.form.get('enabled').value === true;
  }

  constructor(
    private apiService: ApiService,
    protected projectConfigurationService: ProjectConfigurationService,
    private spinnerService: NgxSpinnerService,
    private featureAccessService: AccessService,
    private translocoService: TranslocoService
  ) {}

  private async getSettings() {
    try {
      await this.spinnerService.show(this.spinnerName);
      const [defaultSettings, projectConfigurationTypes, existingSharingData, tenants] = await Promise.all([
        this.apiService.getTenantSettingsByType(TenantSettingType.DEFAULT_SETTINGS).toPromise(),
        this.projectConfigurationService.getProjectTypes(PathwayFeature.MainDashboard),
        this.apiService.getTenantSettingsByType(TenantSettingType.DATA_SHARING).toPromise(),
        this.getTenantList()
      ]);
      this.defaultDataSharing = defaultSettings[0]['dataSharing'];
      this.tenants = tenants;
      this.projectTypes = projectConfigurationTypes.length
        ? projectConfigurationTypes.reduce((a, pct) => {
            return [...a, pct.projectType];
          }, [])
        : [];
      this.existingSharingData = existingSharingData;
      this.form = await this.getForm();
    } finally {
      await this.spinnerService.hide(this.spinnerName);
    }
  }

  async ngOnInit() {
    await this.getSettings();
  }

  private async getTenantList() {
    const settings = await firstValueFrom(this.apiService.getGlobalSettings());
    if (!settings?.length) {
      return [];
    }
    return settings[0].tenants
      .map(tenant => tenant.name)
      .filter(tenant => tenant !== 'default')
      .sort((a, b) => a.localeCompare(b));
  }

  private async getForm() {
    const projectDelegation = new UntypedFormArray([this.getDelegationForm()]);
    let parameters = this.getParameters();

    const hasExistingData = this.existingSharingData && this.existingSharingData.length;

    if (hasExistingData) {
      projectDelegation.removeAt(0);
      for (const sharing of this.existingSharingData) {
        for (const data of sharing.data) {
          const tenant = data.tenant;
          await this.getDelegationSubTenantSettingsList(tenant);
          projectDelegation.push(this.getDelegationForm(data));
        }
        parameters = this.getParameters(sharing);
      }
    }

    return new UntypedFormGroup({
      enabled: new UntypedFormControl(hasExistingData ? this.existingSharingData[0].enabled : false),
      parameters: parameters,
      projectDelegation: projectDelegation
    });
  }

  async onSubmit() {
    this.featureAccessService.resetDataSharing();
    await this.spinnerService.show(this.spinnerName);
    await this.apiService
      .bulkUpdateTenantSettingsByType(TenantSettingType.DATA_SHARING, this.toDataSharing())
      .toPromise();
    await this.spinnerService.hide(this.spinnerName);
  }

  toDataSharing() {
    const enabled = this.form.get('enabled').value;

    if (!enabled) {
      return [this.defaultDataSharing.filter(data => data.name === 'ProjectDelegation')[0]];
    }

    const dataSharing: DataSharing[] = [];

    dataSharing.push({
      id: this.defaultDataSharing.filter(data => data.name === 'ProjectDelegation')[0].id,
      name: 'ProjectDelegation',
      enabled: true,
      type: 'Delegation',
      parameters: this.form.get('parameters').value,
      data: (this.form.get('projectDelegation') as UntypedFormArray).controls.reduce((d, control) => {
        const rawShowIfValue = control.get('showIf').value;
        const showIfValue = rawShowIfValue?.length ? JSON.parse(rawShowIfValue) : rawShowIfValue;
        return [
          ...d,
          {
            projectType: control.get('projectType').value,
            tenant: control.get('tenant').value,
            subTenants: control.get('subTenants').value ?? [],
            showIf: showIfValue || undefined,
            showOnCreation: control.get('showOnCreation').value
          }
        ];
      }, [])
    });
    return dataSharing;
  }

  asFormArray(form: AbstractControl, field: string) {
    return form.get(field) as UntypedFormArray;
  }

  asFormGroup(form: AbstractControl) {
    return form as UntypedFormGroup;
  }

  addDelegation() {
    (this.form.get('projectDelegation') as UntypedFormArray).push(this.getDelegationForm());
  }

  removeDelegation(i: number) {
    (this.form.get('projectDelegation') as UntypedFormArray).removeAt(i);
  }

  getEnabledButtonClass(opt) {
    return this.form.get('enabled').value === opt ? 'btn-primary' : 'btn-default';
  }

  toSelectItems(stringArray: string[]) {
    return stringArray.map(item => {
      return { id: item, name: item };
    });
  }

  subTenantsToSelectItems(projectDelegationItem: AbstractControl) {
    const tenant = (projectDelegationItem as UntypedFormGroup).get('tenant').value;
    return this.subTenants[tenant].map(subTenant => {
      return { id: subTenant.id, name: subTenant.configuration.name };
    });
  }

  getDelegationButtonClass(projectDelegationItem: AbstractControl, opt) {
    return (projectDelegationItem as UntypedFormGroup).get('showOnCreation').value === opt
      ? 'btn-primary'
      : 'btn-default';
  }

  setFromDropdown($event: any[], field: string, index: number) {
    if ($event) {
      const formArray = this.form.get('projectDelegation') as UntypedFormArray;
      (formArray.controls[index] as UntypedFormGroup).get(field).patchValue($event[0]);
    }
  }

  async setTenantFromDropdown($event: any[], field: string, index: number) {
    this.setFromDropdown($event, field, index);
    const formArray = this.form.get('projectDelegation') as UntypedFormArray;
    const tenant = (formArray.controls[index] as UntypedFormGroup).get('tenant').value;
    this.patchFormSubTenants(formArray, index, []);
    await this.getDelegationSubTenantSettingsList(tenant);
    const filteredSubTenants = (this.subTenants[tenant] || []).filter(subTenant =>
      $event.includes(subTenant.configuration.name)
    );
    this.patchFormSubTenants(formArray, index, filteredSubTenants);
  }

  setSubTenantsFromDropdown($event: any[], index: number) {
    const formArray = this.form.get('projectDelegation') as UntypedFormArray;
    const tenant = (formArray.controls[index] as UntypedFormGroup).get('tenant').value;
    const filteredSubTenants = (this.subTenants[tenant] || []).filter(subTenant => $event.includes(subTenant.id));
    this.patchFormSubTenants(formArray, index, filteredSubTenants);
  }

  private patchFormSubTenants(formArray, index, filteredSubTenants) {
    (formArray.controls[index] as UntypedFormGroup).get('subTenants').patchValue(
      filteredSubTenants.length
        ? filteredSubTenants.map(subTenant => {
            return { id: subTenant.id, name: subTenant.configuration.name };
          })
        : null
    );
  }

  toSelectedSubTenantsItems(projectDelegationItem: AbstractControl) {
    if (!projectDelegationItem) {
      return [];
    }
    const subTenantsValue = (projectDelegationItem as UntypedFormGroup).get('subTenants').value;
    return subTenantsValue ? subTenantsValue.map(subTenant => subTenant.id) : [];
  }

  subTenantsForTenant(projectDelegationItem: AbstractControl) {
    const tenant = (projectDelegationItem as UntypedFormGroup).get('tenant').value;
    return tenant && this.subTenants && this.subTenants[tenant] && this.subTenants[tenant].length;
  }

  get invalid() {
    return this.form.invalid;
  }

  validJSONValidator = (control: UntypedFormControl) => {
    if (!control.value || typeof control.value !== 'string') {
      return null;
    }
    try {
      JSON.parse(control.value);
      return null;
    } catch (e) {
      return { showIf: this.translocoService.translate('userManagement.validation.invalidJson') };
    }
  };

  private getDelegationForm(data?: any) {
    return new UntypedFormGroup({
      projectType: new UntypedFormControl(data?.projectType || null, [Validators.required]),
      tenant: new UntypedFormControl(data?.tenant || null, [Validators.required]),
      subTenants: new UntypedFormControl(data?.subTenants || []),
      showIf: new UntypedFormControl(
        data?.showIf ? JSON.stringify(data.showIf, null, 4) : null,
        this.validJSONValidator
      ),
      showOnCreation: new UntypedFormControl(data?.showOnCreation ?? true)
    });
  }

  private getParameters(data?: any) {
    return new UntypedFormControl(data?.parameters || {});
  }

  private async getDelegationSubTenantSettingsList(tenant: string) {
    if (!this.subTenants.hasOwnProperty(tenant)) {
      this.subTenants[tenant] = await firstValueFrom(this.apiService.getDelegationSubTenantSettingsList(tenant));
    }
  }
}
