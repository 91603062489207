import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address } from '../types';
import { HttpClient } from '@angular/common/http';
import { PostCodeLookupService } from './PostCodeLookupService';
import { PostCodeLookupServiceFactory } from './post-code-lookup-service.factory';
import { startWith } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-post-code-lookup',
  template: `
    <ng-container *ngIf="isNotEnteringManually">
      <div class="input-group mb-2">
        <input class="postcode-input" [attr.data-qa]="'postCodeInput'" [formControl]="addressLookupInputValue" />
        <div class="input-group-append">
          <button
            class="btn btn-primary"
            [attr.data-qa]="'findButton'"
            [disabled]="disableFindButton"
            (click)="lookup()"
            type="button"
          >
            {{ buttonText || 'Find' }}
          </button>
        </div>
      </div>
      <div *ngIf="addressLookupInputValue.touched && addressLookupInputValue.invalid" class="form-error">
        {{ question?.errorMessage || i18ns['required'] }}
      </div>
    </ng-container>
    <ng-container *ngIf="zeroResults">
      <label class="zero-results form-label" [attr.data-qa]="'noResults'">{{
        i18ns['noResultsFound'] || 'No results found'
      }}</label>
    </ng-container>
    <div class="mb-2" *ngIf="isNotEnteringManually && hasAddressList">
      <select
        class="form-control address-select"
        [attr.data-qa]="'address'"
        #addressSelect
        (change)="onAddressSelected(addressSelect.value)"
      >
        <option *ngIf="addresses.length > 1" [ngValue]="null">{{ pleaseSelect || 'Please select...' }}</option>
        <option *ngFor="let address of addresses; index as i" [value]="i" [selected]="i === selectedAddressIndex">
          {{ address.summaryline || defaultAddress(address) }}
        </option>
      </select>
    </div>
    <div class="selected-address" *ngIf="canShowSelectedAddress" [attr.data-qa]="'selectedAddress'">
      <div
        *ngIf="addressForm?.value?.line1"
        [innerHtml]="addressForm.value.line1"
        [attr.data-qa]="'selectedLine1'"
      ></div>
      <div
        *ngIf="addressForm?.value?.line2"
        [innerHtml]="addressForm.value.line2"
        [attr.data-qa]="'selectedLine2'"
      ></div>
      <div
        *ngIf="addressForm?.value?.line3"
        [innerHtml]="addressForm.value.line3"
        [attr.data-qa]="'selectedLine3'"
      ></div>
      <div
        *ngIf="addressForm?.value?.locality"
        [innerHtml]="addressForm.value.locality"
        [attr.data-qa]="'selectedLocality'"
      ></div>
      <div *ngIf="addressForm?.value?.town" [innerHtml]="addressForm.value.town" [attr.data-qa]="'selectedTown'"></div>
      <div
        *ngIf="addressForm?.value?.county"
        [innerHtml]="addressForm.value.county"
        [attr.data-qa]="'selectedCounty'"
      ></div>
      <div
        *ngIf="addressForm?.value?.state"
        [innerHtml]="addressForm.value.state"
        [attr.data-qa]="'selectedState'"
      ></div>
      <div
        *ngIf="addressForm?.value?.postCode"
        [innerHtml]="addressForm.value.postCode"
        [attr.data-qa]="'selectedPostCode'"
      ></div>
    </div>
    <div class="address" *ngIf="useManualAddressEntry" [formGroup]="addressForm" [attr.data-qa]="'addressForm'">
      <div class="address__item">
        <div>
          <span>{{ i18ns['address']['line1'] }} *</span>
          <span *ngIf="addressForm.controls['line1'].invalid" class="form-error">{{ i18ns['required'] }}</span>
        </div>
        <input name="line1" formControlName="line1" [attr.data-qa]="'input_line1'" />
      </div>
      <div class="address__item">
        <span>{{ i18ns['address']['line2'] }}</span
        ><input name="line2" formControlName="line2" [attr.data-qa]="'input_line2'" />
      </div>
      <div class="address__item">
        <span>{{ i18ns['address']['line3'] }}</span
        ><input name="line3" formControlName="line3" [attr.data-qa]="'input_line3'" />
      </div>
      <div class="address__item">
        <span>{{ i18ns['address']['locality'] }}</span
        ><input name="locality" formControlName="locality" [attr.data-qa]="'input_locality'" />
      </div>
      <div class="address__item">
        <div>
          <span>{{ i18ns['address']['town'] }} *</span>
          <span *ngIf="addressForm.controls['town'].invalid" class="form-error">{{ i18ns['required'] }}</span>
        </div>
        <input name="town" formControlName="town" [attr.data-qa]="'input_town'" />
      </div>
      <div class="address__item">
        <span>{{ i18ns['address']['county'] }}</span
        ><input name="county" formControlName="county" [attr.data-qa]="'input_county'" />
      </div>
      <div class="address__item">
        <div>
          <span>{{ i18ns['address']['postCode'] }} *</span>
          <span *ngIf="postCodeForm?.invalid" class="form-error">{{ i18ns['required'] }}</span>
        </div>
        <input class="postcode-input" name="postCode" formControlName="postCode" [attr.data-qa]="'input_postCode'" />
      </div>
    </div>
    <button
      *ngIf="isEnabledForCountryCode"
      class="enter-address-manually btn btn-sm btn-primary"
      type="button"
      id="manualEdit-toggle-button"
      [attr.data-qa]="'addressInputToggle'"
      (click)="toggleManualAddressEntry()"
    >
      {{ useManualAddressEntry ? i18ns?.['lookupAddress'] : i18ns?.['enterAddressManually'] }}
    </button>
  `,
  providers: [PostCodeLookupServiceFactory],
  styleUrls: ['post-code-lookup.component.scss']
})
export class PostCodeLookupComponent implements OnInit {
  @Input() addressForm: UntypedFormGroup;
  @Input() postCodeForm: UntypedFormControl;
  @Input() question: QuestionBase<string>;
  @Input() i18ns: { [key: string]: unknown };
  @Input() countryCodes?: string[];
  @Output() addressSelected = new EventEmitter<Address>();

  addressLookupInputValue: UntypedFormControl;
  addressLookupService: PostCodeLookupService;
  addresses: Address[];
  buttonText: string;
  currentPostCode: string;
  readonly defaultCountryCodes: string[] = ['uk'];
  pleaseSelect?: string;
  selectedAddressIndex: number | null;
  useManualAddressEntry = false;
  zeroResults = false;

  get canShowSelectedAddress() {
    return this.addressForm.value && this.addressForm.valid && !this.useManualAddressEntry;
  }

  get hasAddressList() {
    return this.addresses?.length && !this.zeroResults;
  }

  get isNotEnteringManually() {
    return !this.useManualAddressEntry;
  }

  get isEnteringManually() {
    return this.useManualAddressEntry;
  }

  get isEnabledForCountryCode() {
    return (this.countryCodes || []).indexOf('uk') === 0;
  }

  get disableFindButton() {
    return this.addressLookupInputValue.invalid;
  }

  constructor(private postCodeLookupServiceFactory: PostCodeLookupServiceFactory, private httpClient: HttpClient) {}

  ngOnInit(): void {
    this.addressLookupInputValue = new UntypedFormControl(this.postCodeForm?.value, this.postCodeForm?.validator ?? []);
    this.countryCodes = this.countryCodes || this.question?.countryCodes || this.defaultCountryCodes;
    this.addressLookupService = this.postCodeLookupServiceFactory.getForCountries(this.countryCodes, this.httpClient);
    this.buttonText = this.question?.targetLabel || 'Find';
    this.currentPostCode = this.addressForm?.get('postCode')?.value;
    this.pleaseSelect = this.question?.value;

    this.addressForm.valueChanges.pipe(startWith(null)).subscribe(async c => {
      if (this.shouldLookupForPostCodeChange(c)) {
        this.currentPostCode = c?.postCode;
        const latLng = await this.lookupForValue(c.postCode);
        this.addressForm.patchValue({ ...this.addressForm.value, ...latLng });
      }
    });
  }

  private shouldLookupForPostCodeChange(c: { postCode?: string }) {
    return this.isEnteringManually && this.postCodeForm?.valid && c?.postCode && this.currentPostCode != c.postCode;
  }

  getProviderForAddressCode(addressCode: string) {
    return (
      this.addressLookupService.addressProviders.find(ap => {
        const regex = new RegExp(ap.match, 'i');
        return regex.test(addressCode);
      }) || null
    );
  }

  async lookup(): Promise<void> {
    this.zeroResults = false;
    let addressCode = this.addressLookupInputValue.value;
    const provider = this.getProviderForAddressCode(addressCode);
    if (!provider) {
      this.zeroResults = true;
      return;
    }
    addressCode = provider.formatAddressCode(addressCode);
    this.addresses = await provider.getAddresses(addressCode);
    this.selectedAddressIndex = null;
    if (!this.addresses.length) {
      this.zeroResults = true;
    }
    if (this.addresses.length === 1) {
      this.onAddressSelected(0);
    }
  }

  async lookupForValue(lookupValue: string): Promise<{ latitude: string; longitude: string }> {
    let addressCode = lookupValue.trim();
    const provider = this.getProviderForAddressCode(addressCode);
    if (provider) {
      addressCode = provider.formatAddressCode(addressCode);
      const addresses = await provider.getAddresses(addressCode);
      return { latitude: addresses?.[0]?.latitude ?? '', longitude: addresses?.[0]?.longitude ?? '' };
    }
    return { latitude: '', longitude: '' };
  }

  onAddressSelected(selectedAddressIndex?: number | string): void {
    this.selectedAddressIndex =
      typeof selectedAddressIndex === 'string' ? parseInt(selectedAddressIndex) : selectedAddressIndex ?? 0;
    this.addressForm.reset(this.addresses[this.selectedAddressIndex]);
    this.addressSelected.emit(this.addresses[this.selectedAddressIndex]);
  }

  defaultAddress(address: Address) {
    return [address.line1, address.line2, address.locality]
      .filter(addressPart => addressPart && addressPart !== '')
      .join(', ');
  }

  toggleManualAddressEntry() {
    this.useManualAddressEntry = !this.useManualAddressEntry;
    if (!this.useManualAddressEntry && this.addresses?.length) {
      this.onAddressSelected(this.selectedAddressIndex || 0);
    }
    if (this.useManualAddressEntry) {
      this.addressForm.get('line1').setValidators([Validators.required]);
      this.addressForm.get('line1').updateValueAndValidity();
      this.addressForm.get('town').setValidators([Validators.required]);
      this.addressForm.get('town').updateValueAndValidity();
      const postCodeValidators = [Validators.required];
      if (this.question.pattern) {
        postCodeValidators.push(Validators.pattern(this.question.pattern));
      }
      this.addressForm.get('postCode').setValidators(postCodeValidators);
      this.addressForm.get('postCode').updateValueAndValidity();
    }
    if (!this.useManualAddressEntry && !this.question.required) {
      this.addressForm.get('line1').setValidators([]);
      this.addressForm.get('line1').updateValueAndValidity();
      this.addressForm.get('town').setValidators([]);
      this.addressForm.get('town').updateValueAndValidity();
      this.addressForm.get('postCode').setValidators([]);
      this.addressForm.get('postCode').updateValueAndValidity();
    }
  }
}
