import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { RelayEnvironment } from '../domain/relay-environment';
import { Observable } from 'rxjs';

import * as XRegExp from 'xregexp';
import { EnvironmentToken } from '@jump-tech-frontend/app-config';
import { ActivatedRoute, Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthInterceptor implements HttpInterceptor {
  private suid: string;
  private apiInterceptPattern: string;

  constructor(private activatedRoute: ActivatedRoute, @Inject(EnvironmentToken) private environment: RelayEnvironment) {
    this.subscribeToQueryParams();
    this.apiInterceptPattern = `https://api.${
      environment.production ? '' : environment.name + '.'
    }jumptech.co.uk/store`;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipIntercept = req.headers.has('skip');
    if (skipIntercept) {
      req = req.clone({
        headers: req.headers.delete('skip')
      });
    }

    if (!XRegExp(this.apiInterceptPattern).test(req.url) || !this.suid) {
      return next.handle(req);
    }

    const authReq = req.clone({
      setHeaders: {
        suid: this.suid
      }
    });
    return next.handle(authReq);
  }

  private subscribeToQueryParams() {
    this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      this.suid = params['suid'] || '';
    });
  }
}
