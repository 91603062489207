import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Card } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';

@Component({
  selector: `crds-consent-form`,
  template: `
    <div class="pb-5">
      <div class="card-wrapper">
        <div class="card">
          <div class="card-body">
            <div
              class="card-text primary-description-lg"
              [innerHTML]="description"
              [attr.data-qa]="'description'"
            ></div>
          </div>
        </div>
      </div>
      <div class="card-wrapper">
        <div class="card card-light">
          <div class="card-body">
            <div class="card-text" [innerHTML]="description2"></div>
          </div>
        </div>
      </div>
      <div class="inline-form-wrapper">
        <form [formGroup]="form.get(formKey)">
          <crds-questions [form]="form.get(formKey)" [card]="card" [i18ns]="i18ns"></crds-questions>
        </form>
      </div>
      <div class="card-wrapper">
        <div class="card card-light">
          <div class="card-body">
            <div class="card-text" [innerHTML]="description3"></div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ConsentFormComponent implements OnChanges {
  @Input() card: Card<null>;
  @Input() form: UntypedFormGroup;
  @Input() formKey: string;
  @Input() i18ns: I18nKeys;
  description: SafeHtml;
  description2: SafeHtml;
  description3: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.description = this.getCardDescription('description');
    this.description2 = this.getCardDescription('description2');
    this.description3 = this.getCardDescription('description3');
  }

  getCardDescription(descriptionText) {
    const text = this.card[descriptionText];
    if (text) {
      const match = text.match(/\{(.+)\}/);
      if (!match) {
        return this.sanitizer.bypassSecurityTrustHtml(text);
      }
      const formRouteParts = match[1].split('.');
      let formControl = this.form.get(formRouteParts[0]);

      if (formControl && formRouteParts.length === 2) {
        formControl = formControl.get(formRouteParts[1]);
      }

      const formValue = formControl && formControl.value ? formControl.value : '';
      const description = text.replace(/\{.+\}/, formValue);
      return this.sanitizer.bypassSecurityTrustHtml(description);
    }
    return null;
  }
}
