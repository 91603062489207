import { Injectable } from '@angular/core';
import { RelaySummary } from '../../domain/relay-summary';
import { RelayRepository } from '../../relay.repository';
import { SummaryViewModel } from './summary.view.model';

@Injectable()
export class SummaryPresenter {
  constructor(private relayRepository: RelayRepository) {}

  load(callback) {
    let vm: SummaryViewModel;
    this.relayRepository.getSummary((summary: RelaySummary) => {
      if (!summary) {
        return;
      }
      vm = { ...summary };
      callback(vm);
    });
  }
}
