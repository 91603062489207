<div class="modal-wrapper">
  <div class="modal-header-wrapper">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-banner">{{ 'notes.addNote.headings.addEditNote' | transloco }}</div>
  </div>
  <div class="modal-body">
    <div [formGroup]="form">
      <label class="form-label" for="note">{{ 'common.note' | transloco }} *</label>
      <textarea
        #noteTextArea
        class="note-textarea form-control"
        formControlName="note"
        id="note"
        rows="8"
        [attr.data-qa]="'addNoteForm'"
      ></textarea>
    </div>
  </div>
  <div *ngIf="form" class="modal-footer">
    <jui-button size="sm" [disabled]="form.invalid" [attr.data-qa]="'saveButton'" (click)="save()">
      {{ 'common.save' | transloco }}
    </jui-button>
  </div>
</div>
