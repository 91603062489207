import { Injectable } from '@angular/core';
import { TasksRepository } from '../../tasks.repository';

@Injectable()
export class ErrorPresenter {
  constructor(private repository: TasksRepository) {}

  load(callback) {
    this.repository.getError(vm => {
      callback(vm);
    });
  }

  clearError() {
    this.repository.clearError();
  }
}
