import { isJobArray, Job } from './domain/job';

export function asJobArray(body: string | null): Job[] {
  if (body) {
    try {
      const result = JSON.parse(body);
      if (isJobArray(result)) {
        return result;
      }
    } catch (e) {
      console.log('Unexpected response:', body);
    }
  }
  throw new Error('Not a job array');
}
