import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import * as XRegExp from 'xregexp';
import { IfCheck, IfDefinition } from '../domain/datasource';

interface QuestionComponent {
  question: QuestionBase<any>;
  form: UntypedFormGroup;

  get isInvalid(): boolean;

  get show(): boolean;

  get value(): boolean;
}

export class BaseQuestionComponent implements QuestionComponent {
  question: QuestionBase<any>;
  form: UntypedFormGroup;

  get isInvalid() {
    return this.form.touched && this.form.invalid;
  }

  get show() {
    return (this.question.showIfPopulated !== true || this.form.value) && this.question.show !== false;
  }

  get value() {
    return this.form?.get(this.question.key)?.value || [];
  }

  set value(value: any) {
    this.form?.get(this.question.key)?.patchValue(value);
  }

  get showIf() {
    let actualValue = null;
    const showIf: any = this.question.showIf;
    if (!Object.keys(showIf).length) {
      return true;
    }
    let expression = showIf;
    const form = this.form;

    if (showIf && showIf.key) {
      if (this.form.get(showIf.key)) {
        actualValue = this.form.get(showIf.key)?.value;
      } else if (this.form.parent && this.form.parent.get(showIf.key)) {
        actualValue = this.form.parent.get(showIf.key)?.value;
      }
    }

    let result;
    if (showIf && showIf.value && typeof showIf.value === 'string') {
      result = actualValue === showIf.value;
    } else {
      if (showIf.value) {
        expression = showIf.value;
      }
      result = this.checkIfExpression(expression, form, actualValue);
    }

    // Make sure form validation respects visibility
    if (result) {
      if (!this.form?.get(this.question.key)?.enabled) {
        this.form?.get(this.question.key)?.enable();
      }
    } else {
      if (this.form?.get(this.question.key)?.enabled) {
        this.form?.get(this.question.key)?.disable();
      }
    }

    return result;
  }

  protected checkIfExpression(expression: IfDefinition, target: any, actualValue?: any) {
    if (!expression) {
      return true;
    }

    const key = (expression as IfDefinition)?.['key'];
    const value = (expression as IfDefinition)?.['value'];

    if (expression && key && typeof key === 'string') {
      actualValue = this.getPropertyValue(target, key);
    }

    if (expression && value && typeof value === 'string') {
      return actualValue === value;
    }

    let expressionOrCheck: IfDefinition = expression;
    if (typeof value === 'object') {
      expressionOrCheck = value as IfCheck;
    }

    return this.evaluateExpression(expressionOrCheck, target, actualValue);
  }

  private evaluateExpression(expression: any, target: AbstractControl, actualValue?: any): boolean {
    if (typeof expression !== 'object') {
      return false;
    }

    const keys = Object.keys(expression);
    const propertyOrOperator = keys[0];
    const targetValue = this.getTargetValue(target, expression[propertyOrOperator]);
    if (propertyOrOperator) {
      switch (propertyOrOperator) {
        case '$or': {
          return (
            Array.isArray(targetValue) &&
            targetValue.some(thisExpression => this.evaluateExpression(thisExpression, target))
          );
        }
        case '$and': {
          return (
            Array.isArray(targetValue) &&
            targetValue.every(thisExpression => this.evaluateExpression(thisExpression, target))
          );
        }
        case '$eq': {
          // Also allow matching on wildcard, but there HAS to be an actual value, null or undefined don't match
          return actualValue === targetValue || (actualValue && targetValue === '*');
        }
        case '$ne': {
          return actualValue !== targetValue;
        }
        case '$gt': {
          return Array.isArray(actualValue) ? actualValue.length > targetValue : actualValue > targetValue;
        }
        case '$gte': {
          return Array.isArray(actualValue) ? actualValue.length >= targetValue : actualValue >= targetValue;
        }
        case '$lt': {
          return Array.isArray(actualValue) ? actualValue.length < targetValue : actualValue < targetValue;
        }
        case '$lte': {
          return Array.isArray(actualValue) ? actualValue.length <= targetValue : actualValue <= targetValue;
        }
        case '$in': {
          const targetList = targetValue
            .toString()
            .split(',')
            .map((e: string) => e.trim());
          return targetList.includes(actualValue);
        }
        case '$nin': {
          const targetList = targetValue
            .toString()
            .split(',')
            .map((e: string) => e.trim());
          return !targetList.includes(actualValue);
        }
        case '$mr':
          return XRegExp(targetValue, 'gi').test(actualValue);
        case '$nmr':
          return !XRegExp(targetValue, 'gi').test(actualValue);
        case '$inc': {
          return Array.isArray(actualValue) ? actualValue.includes(targetValue) : true;
        }
        default: {
          const av = this.getPropertyValue(target, propertyOrOperator);
          return this.evaluateExpression(targetValue, target, av);

          actualValue = null;
        }
      }
    }
    return false;
  }

  private isEmpty(value: any) {
    return value === undefined || value === null || value.length === 0;
  }

  private getTargetValue(target: AbstractControl, targetValue: any) {
    if (targetValue?.property) {
      return this.getPropertyValue(target, targetValue?.property);
    }

    return targetValue;
  }

  private getPropertyValue(value: AbstractControl, propertyKey: string) {
    let actualValue = null;
    try {
      if (value.get(propertyKey)) {
        actualValue = value?.get(propertyKey)?.value;
      } else if (value.parent && value.parent.get(propertyKey)) {
        actualValue = value.parent.get(propertyKey)?.value;
      }

      if (actualValue && !this.isEmpty(actualValue)) {
        return actualValue;
      }
      return '';
    } catch (e) {
      console.log('Path does not exist', propertyKey);
      return '';
    }
  }
}
