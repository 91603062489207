import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { BaseQuestionComponent } from '../question.component';
import { I18nKeys } from '../../domain/i18n-keys';

/**
 * @Deprecated - redirecting to PostcodeQuestionV2Component
 */
@Component({
  selector: `crds-question-postcode`,
  template: `
    <crds-question-v2-postcode
      [question]="question"
      [i18ns]="i18ns"
      [form]="form"
      [attr.data-qa]="question.key"
    ></crds-question-v2-postcode>
  `
})
export class PostcodeQuestionComponent extends BaseQuestionComponent {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<string>;
  @Input() i18ns: I18nKeys;
  type: string;
}
