import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { I18nKeys } from '../../../domain/i18n-keys';
import { BaseQuestionComponent } from '../../question.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'crds-question-file-upload',
  template: `
    <div *ngIf="show && form" [formGroup]="form">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key"
        >{{ question.label }}
        <span *ngIf="question.required"> *</span>
        <crds-form-error
          *ngIf="isInvalid"
          [question]="question"
          [message]="question.errorMessage"
          [i18ns]="i18ns"
        ></crds-form-error>
      </label>
      <div class="inline-buttons">
        <crds-question-file-upload-input
          (fileUploaded)="onFileUploaded($event)"
          [hasUploadedFiles]="hasUploadedFiles"
          [form]="form"
          [question]="question"
          [i18ns]="i18ns"
        ></crds-question-file-upload-input>
      </div>
      <div class="file-wrapper">
        <crds-question-hint *ngIf="!hasUploadedFiles" [question]="question"></crds-question-hint>
        <div *ngIf="hasUploadedFiles">
          <div class="file-placeholder-container pt-1" *ngFor="let uploadedFile of files; index as i">
            <div class="file-placeholder-icon">
              <i class="material-icons">article</i>
            </div>
            <div class="file-placeholder">
              <span [id]="i + '_temp'">{{ uploadedFile.name }}</span>
            </div>
            <jui-icon (click)="clearFile(i)" isButton="true" name="delete_forever" #fileUploadButton> </jui-icon>
          </div>
        </div>
      </div>
      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" type="line-scale"> </ngx-spinner>
    </div>
  `,
  styles: [
    `
      .material-icons {
        color: #ffffff;
      }
      .file-placeholder-container {
        padding-right: 1em;
        margin-bottom: 0.5em;
        display: grid;
        grid-template-columns: 1fr 8fr 1fr;
        align-items: center;
        border-bottom-style: solid;
        border-width: thin;
      }
      .file-placeholder-container.file-upload-button {
      }

      .file-placeholder-icon {
        padding: 0;
      }

      .file-placeholder-icon i {
        font-size: 3rem;
        color: var(--primary-color) !important;
      }

      .file-placeholder {
      }

      .file-upload-button {
        font-size: 0.8rem;
        display: inline-flex;
        margin-bottom: 0.75rem;
        width: max-content;
      }

      .inline-buttons {
        display: grid;
        grid-template-columns: 50% 50%;
      }
    `
  ]
})
export class FileUploadQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;

  files: any[];

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    /**
     * Check for a non-array value
     */
    if (this.value && !(this.value instanceof Array)) {
      setTimeout(() => {
        this.form?.get(this.question.key)?.patchValue([this.value]);
      });
    }
    this.setUploadedFiles();
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  get hasUploadedFiles() {
    return this.files.length > 0;
  }

  clearFile(index: number) {
    this.setUploadedFiles();
    this.files.splice(index, 1);
    this.form?.get(this.question.key)?.patchValue(this.files);
  }

  onFileUploaded($event: { name: string; data: string }) {
    this.setUploadedFiles();
    const { name } = $event;
    const index = this.files.indexOf(x => x.name === name);
    if (index > -1) {
      this.files.splice(index, 1);
    }

    if (this.question.sort && this.question.sort === 'desc') {
      this.files.unshift($event);
    } else {
      this.files.push($event);
    }
    this.form?.get(this.question.key)?.patchValue(this.files);
  }

  setUploadedFiles() {
    const values =
      this.form.get(this.question.key) && this.form?.get(this.question.key)?.value
        ? this.form?.get(this.question.key)?.value
        : [];
    this.files = [...values];
  }
}
