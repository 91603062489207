import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AddressEditorViewModel } from './address-editor.vm';
import { IAddressV2 } from '../../domain/address-v2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getAddressFieldsForCountry, getPostcodeValidatorForCountry, isFormFieldRequired } from '../address.utils';
import { FormUpdate, TextboxQuestionParams } from '@jump-tech-frontend/question-components';
import { COMMONS_TRANSLATIONS_PROVIDER } from '@jump-tech-frontend/app-config';

@Injectable()
export class AddressEditorPresenter {
  private readonly translationsProvider = inject(COMMONS_TRANSLATIONS_PROVIDER);
  load(vm: BehaviorSubject<AddressEditorViewModel | null>, countryCodes: string[], address?: IAddressV2): void {
    if (!address) {
      address = {
        line1: '',
        line2: '',
        line3: '',
        town: '',
        county: '',
        countryCode: '',
        country: '',
        postCode: ''
      };
    }
    const form = this.setUpForm(address, countryCodes);
    const originalAddress: IAddressV2 = { ...address };
    const addressFields = getAddressFieldsForCountry(address.countryCode || countryCodes[0]);
    vm.next({
      form,
      originalAddress,
      addressFieldParams: addressFields.map(af => {
        return this.getFieldParams(af, form, address);
      }),
      i18n: {
        cancel: this.translationsProvider.getTranslation('cancel'),
        ok: this.translationsProvider.getTranslation('ok')
      }
    });
  }

  private getFieldParams(addressField: string, form: FormGroup, address?: IAddressV2): TextboxQuestionParams {
    const addressMap = address ? Object.entries(address) : null;
    return {
      label: this.getTranslationForField(addressField),
      key: addressField,
      required: isFormFieldRequired(form, addressField),
      value: addressMap?.find(am => am.find(a => a === addressField))?.[1] ?? '',
      inline: false
    };
  }

  private getTranslationForField(field: string) {
    return this.translationsProvider.getTranslation(`address.${field}`);
  }

  private setUpForm(address: IAddressV2, countryCodes: string[]) {
    return new FormGroup({
      line1: new FormControl<string>(address.line1 ?? '', Validators.required),
      line2: new FormControl<string>(address.line2 ?? ''),
      line3: new FormControl<string>(address.line3 ?? ''),
      town: new FormControl<string>(address.town ?? '', Validators.required),
      county: new FormControl<string>(address.county ?? ''),
      country: new FormControl<string>(address.country ?? ''),
      countryCode: new FormControl<string>(address.countryCode ?? ''),
      postCode: new FormControl<string>(
        address.postCode ?? '',
        getPostcodeValidatorForCountry(countryCodes, address.countryCode)
      ),
      latitude: new FormControl<string>(address?.latitude ?? ''),
      longitude: new FormControl<string>(address?.longitude ?? '')
    });
  }

  fieldChanged(vm$: BehaviorSubject<AddressEditorViewModel>, $event: FormUpdate) {
    const form: FormGroup = vm$.value?.form;
    if (typeof $event.value === 'string') {
      $event.value = $event.value.trim();
    }
    form.get($event.key)?.setValue($event.value);
    vm$.next({ ...vm$.value, form });
  }
}
