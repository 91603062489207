import { Component, Input } from '@angular/core';
import { JobSummaryItem } from './jobs.model';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent {
  @Input() jobs: JobSummaryItem[];
}
