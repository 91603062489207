export const DnoAreaIds = {
  '10': 'UK Power Networks',
  '11': 'Western Power Distribution',
  '12': 'UK Power Networks',
  '13': 'Scottish Power Energy Networks',
  '14': 'Western Power Distribution',
  '15': 'Northern Powergrid (Northeast)',
  '16': 'Electricity North West',
  '17': 'SSE (Scottish Hydro Electric)',
  '18': 'Scottish Power Energy Networks',
  '19': 'UK Power Networks',
  '20': 'SSE (Southern Electric)',
  '21': 'Western Power Distribution',
  '22': 'Western Power Distribution',
  '23': 'Northern Powergrid (Yorkshire)',
  '24': 'GTC',
  '25': 'ESP Electricity',
  '26': 'Energetics',
  '27': 'GTC',
  '28': 'EDF IDNO',
  '29': 'Harlaxton Energy Networks Ltd.',
  '30': 'Leep Electricity Networks Ltd (Peel)',
  '31': 'UK Power Distribution Limited',
  '32': 'Energy Assets Networks Ltd',
  '33': 'G2 Energy IDNO Limited',
  '34': 'Murphy Power Distribution Limited',
  '35': 'Fulcrum Electricity Assets Ltd',
  '36': 'Vattenfall Networks Ltd',
  '81': 'Northern Ireland Electricity',
  '98': 'PW Test'
};

export const DnoAreaDomains = {
  '10': 'ukpn',
  '11': 'wpd',
  '12': 'ukpn',
  '13': 'spen',
  '14': 'wpd',
  '15': 'np',
  '16': 'enw',
  '17': 'sse',
  '18': 'spen',
  '19': 'ukpn',
  '20': 'sse',
  '21': 'wpd',
  '22': 'wpd',
  '23': 'np',
  '24': 'gtc',
  '25': 'esp',
  '26': 'energetics',
  '27': 'gtc',
  '28': 'edf',
  '29': 'harlaxton',
  '30': 'leep',
  '31': 'ukpd',
  '32': 'ean',
  '33': 'gei',
  '34': 'mpd',
  '35': 'fea',
  '36': 'vn',
  '81': 'nie',
  '98': 'pwtest'
};
