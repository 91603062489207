export enum AuditLogStatus {
  SUCCESS = 'Success',
  FAILURE = 'Failed'
}

interface LatLng {
  lng?: number;
  lat?: number;
}

export enum AuditLogType {
  PROJECT_STATUS_CHANGE = 'PROJECT_STATUS_CHANGE',
  JOB_STATUS_CHANGE = 'JOB_STATUS_CHANGE',
  JOB_SCHEDULE_CHANGE = 'JOB_SCHEDULE_CHANGE',
  JOB_ASSIGNMENT_CHANGE = 'JOB_ASSIGNMENT_CHANGE',
  SMS_DELIVERY = 'SMS_DELIVERY',
  SMS_SENT = 'SMS_SENT',
  RELAY_PROGRESS = 'RELAY_PROGRESS',
  DATA_TRANSFER = 'DATA_TRANSFER',
  ATTACHMENT_GENERATED = 'ATTACHMENT_GENERATED',
  EMAIL_SENT = 'EMAIL_SENT',
  PUBLISH_TO_WEBHOOK = 'PUBLISH_TO_WEBHOOK',
  SCHEDULED_EVENT = 'SCHEDULED_EVENT',
  FORM_SUBMITTED = 'FORM_SUBMITTED',
  ADD_TO_AUDIT_LOG = 'ADD_TO_AUDIT_LOG',
  PROJECT_DELEGATION = 'PROJECT_DELEGATION',
  PROJECT_UN_DELEGATION = 'PROJECT_UN_DELEGATION'
}

type MessageDataTypes =
  | 'string'
  | 'isoDate'
  | 'projectStatus'
  | 'jobStatus'
  | 'metastatus'
  | 'tenant'
  | 'subTenant'
  | 'messageKey';

export interface AuditLogMessageData {
  /**
   * The key code represents the title for this value and could relate to a translation string if viewed
   */
  key: string;
  /**
   * The value for the key should be presented based on the 'type' parameter
   */
  value?: string;
  /**
   * The type of the data in the value parameter. Renderers can use this as a hint for how to
   * format or present the value.
   * Suggested types are given in MessageDataTypes
   */
  type: MessageDataTypes | string;
}

export interface AuditLogData {
  key: string;
  value?: string | number | string[] | LatLng;
}

export interface AuditLog {
  tenant: string;
  subTenant?: string;
  /**
   * Unique ID for the entry
   */
  id: string;
  projectId: string;
  projectType?: string;
  /**
   * Human-readable description of the entry
   */
  eventName: string;
  created_on: string;
  created_by: string;
  status: AuditLogStatus | 'Success' | 'Failed';
  /**
   * Provided time of the audit in ISO string format, not the same as the time the audit log was written (created_on)
   */
  timestamp: string;
  /**
   * Well-known name for this class of event. Actual values are defined by each system.
   * Values should be SCREAMING_SNAKE_CASE, for example 'PROJECT_STATUS_CHANGE'
   */
  type: AuditLogType | string;
  jobId?: string;
  jobType?: string;
  /**
   * Reference to a resource in the project
   */
  resourceId?: string;
  resourceName?: string;
  /**
   * Original value can be provided for some event types. Not displayed.
   */
  oldValue?: string;
  /**
   * Original value can be provided for some event types. Not displayed.
   */
  newValue?: string;
  userId?: string;
  userName?: string;
  /**
   * Internal data, used for reporting. Originally shown to users as raw audit logs, user-visible data is now
   * provided in message fields
   */
  data: AuditLogData[];
  messageOptions?: {
    /**
     * Fallback text used if there is no key set or the key cannot be translated.
     */
    text: string;
    /**
     * A unique key for an audit entry. One type may have more than one messageKey associated.
     * The key should relate to a translation string e.g. messageCode of project.status.changed might
     * relate to locale key auditLog.project.status.changed with value "Project status changed" in english.
     * Translations should not include replacement strings.
     *
     * Optional for events where the text is already translated.
     */
    key?: string;
    /**
     * Optional parameters for the event are raw text, localisable values or translation strings, e.g.
     * <code>
     * [
     *  {key: 'toStatus', value: 'CREATED', type: 'projectStatus' },
     *  {key: 'fromStatus', value: 'SURVEY_SENT', type: 'projectStatus' },
     *  {key: 'date', value: '2023-07-13T14:23:39.655Z', type: 'isoDate' },
     *  {key: 'value', value: 'Some user supplied text', type: 'string' }
     * ]
     * </code>
     */
    data?: AuditLogMessageData[];
  };
}

export interface AuditLogProgress {
  progress: string;
  progressDate: string;
  cardLabel: string;
  status?: string;
}
