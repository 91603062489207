import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AutoAssignmentPresenter } from './auto-assignment.presenter';
import { AssignmentMappingDisplay } from './domain';

@Component({
  selector: 'app-auto-assignment',
  template: ` <div class="auto-assignment-card card">
      <div class="auto-assignment card-body" [attr.data-qa]="'auto-assignment'">
        <div class="assignment-mappings">
          <app-assignment-mappings-component
            (mappingsChanged)="mappingsChanged($event)"
          ></app-assignment-mappings-component>
        </div>
        <div class="assignments">
          <app-assignment-project-types></app-assignment-project-types>
          <app-assignment-mappings-tester [mappings]="transientMappings"></app-assignment-mappings-tester>
        </div>
      </div>
    </div>
    <ngx-spinner
      bdColor="var(--jds-theme-spinner-bd-rgba)"
      size="large"
      type="line-scale"
      name="autoAssignmentSpinner"
      [attr.data-qa]="'assignmentMappingsSpinner'"
    ></ngx-spinner>`,
  styleUrls: ['./auto-assignment.scss']
})
export class AutoAssignmentComponent implements OnDestroy {
  transientMappings: AssignmentMappingDisplay[] = null;

  constructor(private presenter: AutoAssignmentPresenter) {}

  ngOnDestroy(): void {
    this.presenter.cleanup();
  }

  mappingsChanged(mappings: AssignmentMappingDisplay[]) {
    this.transientMappings = [...mappings];
  }
}
