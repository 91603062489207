import { Injectable } from '@angular/core';
import { SearchRepository } from '../search.repository';
import { ISearchBarVm, SearchTarget } from '../search.model';

@Injectable()
export class SearchBarPresenter {
  viewModel: ISearchBarVm;

  constructor(private repository: SearchRepository) {
    this.viewModel = {
      isSearching: false
    };
  }

  load(callback, target: SearchTarget) {
    this.repository.getSearchTermUpdates(callback, target);
    return this.viewModel;
  }

  async search(callback, searchTerm, target, max) {
    callback({ ...this.viewModel, isSearching: true });
    await this.repository.searchProjects(searchTerm, target, max);
    callback({ ...this.viewModel, isSearching: false });
  }

  isSignedIn() {
    return this.repository.isSignedIn();
  }

  clearResults(target: SearchTarget) {
    this.repository.clearResults(target);
  }
}
