import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../../domain/card';
import { I18nKeys } from '../../../domain/i18n-keys';
import { BaseQuestionComponent } from '../../question.component';

@Component({
  selector: `crds-question-multi-image-upload`,
  template: `
    <div *ngIf="show && form" [formGroup]="form">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key"
        >{{ question.label }}
        <span *ngIf="question.required"> *</span>
        <crds-form-error
          *ngIf="isInvalid"
          [question]="question"
          [message]="question.errorMessage"
          [i18ns]="i18ns"
        ></crds-form-error>
      </label>

      <!-- QUESTION INPUT -->
      <div class="btn-wrapper">
        <crds-question-multi-image-upload-input
          (imageUploaded)="onImageUploaded($event)"
          [hasUploadedImages]="hasUploadedImages"
          [form]="form"
          [i18ns]="i18ns"
          [question]="question"
          class="test"
          [attr.data-qa]="'multiImageUploadInput'"
          [clearInput]="clearInputValue"
        ></crds-question-multi-image-upload-input>
      </div>
      <!-- QUESTION EXAMPLE IMAGE OR USER UPLOADED -->
      <div class="image-wrapper">
        <crds-question-hint *ngIf="!hasUploadedImages" [question]="question"></crds-question-hint>
        <div *ngIf="!hasUploadedImages && images && images.length">
          <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
        </div>
        <div *ngIf="hasUploadedImages">
          <div class="uploaded-image" *ngFor="let uploadedImage of value; index as i">
            <div class="uploaded-image__img">
              <crds-http-image [id]="i + '_temp'" [src]="uploadedImage"></crds-http-image>
              <div class="uploaded-image__remove">
                <jui-icon isButton="true" name="clear" size="sm" (click)="clearImage(i)"></jui-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" type="line-scale"> </ngx-spinner>
    </div>
  `,
  styles: [
    `
      .btn-wrapper {
        margin: var(--jds-space-stack);
      }
    `
  ]
})
export class MultiImageUploadQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @ViewChild('imageUploadButton') input;
  type: string;
  images: ImageAsset[];
  uploadedImages: any[] = [];
  imageAssetType = ImageAssetType;
  clearInputValue = false;

  constructor(private imageAssetFactory: ImageAssetFactory) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    /**
     * Check for a non-array value
     */
    if (this.value && !(this.value instanceof Array)) {
      setTimeout(() => {
        this.form?.get(this.question.key)?.patchValue([this.value]);
      });
    }
    this.images = this.imageAssetFactory.create(this.question.image, this.form);
    this.setUploadedImages();
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  get hasUploadedImages() {
    return this.uploadedImages.length > 0;
  }

  clearImage(index: number) {
    this.setUploadedImages();
    this.uploadedImages.splice(index, 1);
    this.form?.get(this.question.key)?.patchValue(this.uploadedImages);
    this.cleanInputValue();
  }

  cleanInputValue() {
    this.clearInputValue = true;
    setTimeout(() => (this.clearInputValue = false));
  }

  onImageUploaded($event: string) {
    this.setUploadedImages();
    // R.B hardcoded sort here for now - I couldn't find the sort for a question in nucleus, I think it makes sense to reverse the order for UX
    if (!this.question.sort) {
      this.question.sort = 'desc';
    }

    if (this.question.sort && this.question.sort === 'desc') {
      this.uploadedImages.unshift($event);
    } else {
      this.uploadedImages.push($event);
    }
    this.form?.get(this.question.key)?.patchValue(this.uploadedImages);
  }

  setUploadedImages() {
    const values =
      this.form.get(this.question.key) && this.form?.get(this.question.key)?.value
        ? this.form?.get(this.question.key)?.value
        : [];
    this.uploadedImages = [...values];
  }
}
