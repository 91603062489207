import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModel } from '../../task.model';
import { ErrorModalComponent } from './error-modal.component';
import { ErrorPresenter } from './error.presenter';

@Component({
  selector: 'task-error-component',
  template: `<div [attr.data-qa]="'errorPlaceholder'"></div>`
})
export class ErrorComponent implements OnInit {
  private modalRef?: NgbModalRef;

  constructor(private errorPresenter: ErrorPresenter, private modalService: NgbModal) {}
  ngOnInit() {
    this.errorPresenter.load((vm: ErrorModel) => {
      if (vm && !this.modalRef) {
        this.modalRef = this.modalService.open(ErrorModalComponent, { size: 'sm' });
        this.modalRef.componentInstance.vm = vm;
        this.modalRef.result
          .then(() => {
            this.errorPresenter.clearError();
            this.modalRef = null;
          })
          .catch(() => {
            this.errorPresenter.clearError();
            this.modalRef = null;
          });
      }
    });
  }
}
