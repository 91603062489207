import { ApiService } from '../../core/api.service';
import { firstValueFrom } from 'rxjs';
import { AuditLog } from '@jump-tech-frontend/domain';
import { Injectable } from '@angular/core';

@Injectable()
export class ProjectAuditLogsRepository {
  constructor(private apiService: ApiService) {}
  async list(projectId: string): Promise<AuditLog[]> {
    const search = [{ key: 'projectId', value: projectId }];
    const searchOptions = {
      sort: 'created_on',
      dir: 'desc',
      max: '200'
    };
    const { results } = await firstValueFrom(this.apiService.searchAuditLogs(search, searchOptions));
    return results;
  }
}
