import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';

import { NguCarouselModule } from '@ngu/carousel';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ActionsComponent } from './components/actions/actions.component';
import { ActionPresenter } from './components/actions/actions.presenter';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ErrorModalComponent } from './components/error/error-modal.component';
import { ErrorComponent } from './components/error/error.component';
import { ErrorPresenter } from './components/error/error.presenter';
import { FormComponent } from './components/form/form.component';
import { FormPresenter } from './components/form/form.presenter';
import { LogoComponent } from './components/logo/logo.component';
import { LogoPresenter } from './components/logo/logo.presenter';
import { SummaryComponent } from './components/summary/summary.component';
import { SummaryPresenter } from './components/summary/summary.presenter';
import { UploadProgressModalComponent } from './components/upload-progress/upload-progress-modal.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { UploadProgressPresenter } from './components/upload-progress/upload-progress.presenter';
import { PersistenceService } from './persistence.service';

import { defineCustomElements } from '@jump-tech-frontend/core-components/loader';
import { SignaturePadModule } from '@jump-tech-frontend/angular-common';
import {
  CardToQuestionsComponent,
  LikertComponent,
  ModalComponent,
  QuestionGroupComponent,
  TextareaComponent,
  TextboxComponent
} from '@jump-tech-frontend/question-components';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SpinnerService } from './spinner.service';

defineCustomElements();

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslocoModule,
    SignaturePadModule,
    NgxSpinnerModule,
    NguCarouselModule,
    JumptechCardsLibModule,
    CoreComponentsAngularModule,
    QuestionGroupComponent,
    TextboxComponent,
    LikertComponent,
    TextareaComponent,
    ModalComponent,
    CardToQuestionsComponent
  ],
  declarations: [
    FormComponent,
    ActionsComponent,
    SummaryComponent,
    LogoComponent,
    ErrorComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    UploadProgressComponent,
    UploadProgressModalComponent,
    SpinnerComponent
  ],
  providers: [
    FormPresenter,
    ActionPresenter,
    SummaryPresenter,
    LogoPresenter,
    ErrorPresenter,
    UploadProgressPresenter,
    PersistenceService,
    NgbActiveModal,
    SpinnerService
  ],
  exports: [
    FormComponent,
    ActionsComponent,
    SummaryComponent,
    LogoComponent,
    ErrorComponent,
    ErrorModalComponent,
    UploadProgressComponent,
    UploadProgressModalComponent
  ]
})
export class RelayFormModule {}
