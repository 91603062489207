import { UntypedFormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LanguageService } from './language.service';
import { Translations } from './translations';

export function hasTranslationValidationConfig(service: LanguageService) {
  return {
    extras: { lazyRender: true },
    validationMessages: [{ name: 'hasTranslation', message: `Missing translation` }],
    validators: [
      {
        name: 'hasTranslation',
        validation: async (control: UntypedFormControl, field: FormlyFieldConfig) => {
          if (!field.templateOptions.required) {
            return null;
          }
          const value: Translations = control.value;
          const languageValue = value[service.currentLanguage];
          return languageValue ? null : { hasTranslation: true };
        }
      }
    ]
  };
}
