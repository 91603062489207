import { NgModule } from '@angular/core';
import { ToasterContainerComponent } from './toaster-container.component';
import { ToastComponent } from './toast-message.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ToasterContainerComponent, ToastComponent],
  imports: [CommonModule, BrowserAnimationsModule],
  providers: [],
  exports: [ToasterContainerComponent]
})
export class ToastContainerModule {}
