import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { activeState$ } from '../utils/active-state';
import { sharedClipBoardContentsSubject$ } from './clipboard';

@Component({
  selector: 'lib-formly-repeat-section',
  template: `
    <ng-container *ngFor="let field of field.fieldGroup; let i = index">
      <div class="d-flex px-3 py-2 bg-light border align-items-center">
        <div class="pt-1 mr-2">
          <i class="material-icons-outlined" (click)="toggleShow(i)">
            <ng-container>{{ show(i) ? 'visibility_off' : 'visibility' }} </ng-container>
          </i>
        </div>
        <span class="heading" (click)="toggleShow(i)">
          {{ to.label }}: {{ model[i][to.field] || model[i].key || model[i].label || model[i].groupLabel || '' }}
        </span>
        <span *ngIf="invalid(field, i)" class="invalid-section pt-1">!</span>
        <div class="ml-auto">
          <jui-icon
            color="high"
            size="sm"
            name="expand_more"
            title="Move down"
            (click)="moveSection(i, 'down')"
            *ngIf="i < model.length - 1"
          >
          </jui-icon>
        </div>
        <jui-icon
          [class]="i > 0 ? 'ml-2' : ''"
          color="high"
          size="sm"
          name="expand_less"
          title="Move up"
          (click)="moveSection(i, 'up')"
          *ngIf="i > 0"
        >
        </jui-icon>
        <jui-icon
          class="ml-2"
          color="high"
          size="sm"
          name="content_copy"
          title="Duplicate {{ to.label }}"
          (click)="addSectionAndShow(i)"
        >
        </jui-icon>
        <jui-icon
          class="ml-2"
          color="high"
          size="sm"
          name="imagesearch_roller"
          type="outlined"
          title="Copy {{ to.label }}"
          (click)="copySection(i)"
        >
        </jui-icon>
        <jui-icon
          class="ml-2"
          color="danger"
          size="sm"
          name="delete"
          type="outlined"
          title="Delete {{ to.label }}"
          (click)="removeSection(i)"
        >
        </jui-icon>
      </div>
      <div class="card-body" *ngIf="show(i)">
        <formly-field [field]="field"></formly-field>
      </div>
    </ng-container>
    <div class="formly-repeat__action">
      <jui-button size="sm" (click)="addSectionAndShow()">
        <span class="material-icons" slot="icon">add</span>
        Add {{ to.label }}
      </jui-button>
    </div>

    <ng-template [ngIf]="(clipBoardContentsSubject$ | async)?.type === key">
      <div class="btn btn-secondary bt-sm ml-1 mt-2" (click)="addSectionFromClipboardAndShow()">
        Paste {{ to.label }}
      </div>
    </ng-template>
  `,
  styles: [
    `
      i.material-icons,
      i.material-icons-outlined,
      span.heading {
        cursor: pointer;
      }

      .card-body {
        background-color: #00000015;
      }

      .invalid-section {
        color: red;
      }

      .formly-repeat__action {
        margin-top: 5px;
      }
    `
  ]
})
export class RepeatTypeComponent extends FieldArrayType implements OnInit {
  showCollection = {};

  clipBoardContentsSubject$ = sharedClipBoardContentsSubject$;

  @Output() showToggledEvent: EventEmitter<number> = new EventEmitter<number>();

  @HostListener('window:focus', ['$event'])
  async onFocus(event: any): Promise<void> {
    const clipboard = await navigator?.clipboard?.readText();
    this.sendClipboardChange(clipboard);
  }

  async ngOnInit() {
    const clipboard = await navigator?.clipboard?.readText();
    this.sendClipboardChange(clipboard);
  }

  sendClipboardChange(clipboard: string) {
    if (clipboard && typeof clipboard === 'string') {
      try {
        this.clipBoardContentsSubject$.next(JSON.parse(clipboard));
      } catch (e) {
        // ignore
      }
    }
  }

  show(index: number): boolean {
    return this.showCollection?.[this.to.label] === index;
  }

  toggleShow(index: number): void {
    if (this.showCollection[this.to.label] === index) {
      this.showCollection[this.to.label] = undefined;
    } else {
      this.showCollection[this.to.label] = index;
      if (this.showToggledEvent) {
        this.showToggledEvent.emit(index);
      }
      if (this.field?.templateOptions?.onShowToggled) {
        this.field.templateOptions.onShowToggled(index);
      }
    }
  }

  removeSection(index: number, moveOnly = false) {
    const confirmRemove = moveOnly || confirm(`Are you sure you want to remove this ${this.to.label}`);
    if (!confirmRemove) {
      return;
    }
    if (this.field.templateOptions?.onDeleteItem && !moveOnly) {
      this.field.templateOptions.onDeleteItem(this.field?.key, super.model[index]);
    }
    if (this.showCollection[this.to.label] === index) {
      this.showCollection[this.to.label] = undefined;
    }
    super.remove(index);
  }

  addSectionAndShow(index?: number, destIndex?: number) {
    if (index === undefined) {
      index = this.model?.length ?? 0;
      this.addToFieldArray(index, this.getDefaultModelForSectionType(this.to.label));
      this.toggleShow(index);
    } else {
      const modelCopy = this.cloneModelItem(index);
      if (!modelCopy) {
        this.addToFieldArray(index, this.getDefaultModelForSectionType(this.to.label));
      } else {
        modelCopy.label = modelCopy.label + ' (Copy)';
        if (modelCopy.key) {
          modelCopy.key = modelCopy.key + 'Copy';
        }
        this.addToFieldArray(destIndex || index + 1, modelCopy);
      }
      this.toggleShow(destIndex || index + 1);
    }
  }

  private cloneModelItem(index: number) {
    const modelElement = this.model?.[index];
    return !modelElement ? null : JSON.parse(JSON.stringify(modelElement));
  }

  moveSection(index: number, direction: 'up' | 'down'): void {
    setTimeout(() => {
      const modelCopy = this.cloneModelItem(index);
      this.removeSection(index, true);
      this.addToFieldArray(direction === 'up' ? index - 1 : index + 1, modelCopy);
    });
  }

  getDefaultModelForSectionType(sectionType: string) {
    switch (sectionType) {
      case 'Cards': {
        return {
          questions: [],
          description: '',
          section: 1,
          label: `New ${sectionType}`,
          type: 'text',
          key: `new${sectionType}`
        };
      }
      default: {
        return {
          label: '',
          key: ''
        };
      }
    }
  }

  invalid(field: FormlyFieldConfig, index: number) {
    return field.form.controls[index].invalid;
  }

  async copySection(index: number) {
    const clipBoardContents = {
      type: this.key.toString(),
      data: this.cloneModelItem(index)
    };
    console.log('clipBoardContents', clipBoardContents);
    const clipBoardContentsString = JSON.stringify(clipBoardContents);
    await navigator.clipboard.writeText(clipBoardContentsString);
    this.sendClipboardChange(clipBoardContentsString);
  }

  addSectionFromClipboardAndShow() {
    const index = this.model?.length || 0;
    const clipboardValueData = this.clipBoardContentsSubject$.value.data;
    console.log('clipboardValueData', clipboardValueData);
    this.addToFieldArray(index, clipboardValueData);
    this.toggleShow(index);
  }

  addToFieldArray(index: number, value: unknown) {
    if (this.key === 'blocks' || this.key === 'cards' || this.key === 'layouts') {
      activeState$.next({ updating: true });
      super.add(index, value);
      activeState$.next({ updating: false });
    } else {
      super.add(index, value);
    }
  }
}
