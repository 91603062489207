import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { SubTenant, TenantSettingType } from '../domain/types';
import { UserManagementService } from '../user-management.service';
import { UserManagementUpdateService } from '../user-management-update.service';
import { CustomLookupService } from '../../../core/custom-lookup.service';

@Injectable({
  providedIn: 'root'
})
export class SubTenantsService extends UserManagementService<SubTenant> {
  protected data: SubTenant[] = [];
  protected paginationTokens = {
    previous: [null],
    current: null,
    next: null
  };

  constructor(
    protected apiService: ApiService,
    protected userManagementUpdateService: UserManagementUpdateService,
    protected customLookupService: CustomLookupService
  ) {
    super(apiService, userManagementUpdateService, customLookupService);
  }

  async lookup(force: boolean, paginationToken: string = this.paginationTokens.current, limit = 20) {
    if (this.data.length && !force) {
      return this.data;
    }

    return await this.apiService
      .getTenantSettingsByType(TenantSettingType.SUB_TENANT)
      .toPromise()
      .then(subTenantsResult => {
        this.data = this.sortedResult(subTenantsResult);
        return this.data;
      });
  }

  sortedResult(subTenantsResult: SubTenant[]) {
    return subTenantsResult.sort((st1, st2) => st1.configuration?.name?.localeCompare(st2.configuration.name));
  }
}
