import { Injectable } from '@angular/core';
import { AutoAssignmentRepository } from './auto-assignment.repository';

@Injectable()
export class AutoAssignmentPresenter {
  constructor(private repository: AutoAssignmentRepository) {}

  cleanup() {
    this.repository.cleanup();
  }
}
