import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { APP_HOME_PATH, LOGIN_PATH } from '../../app.routes';
import { environment } from '../../../environments/environment';
import { RouteAccessService } from '../services/route-access.service';
import { setReturnUrl } from '../../core/utils/location.helper';

@Injectable()
export class CanActivateRoute {
  constructor(
    private routeAccessService: RouteAccessService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    setReturnUrl(state.url);

    return new Promise(resolve => {
      this.routeAccessService
        .isAuthenticatedForRoute(route)
        .then(result => {
          if (result) {
            resolve(true);
          } else {
            if (!environment.production) {
              console.log('Need to log in before navigating to', state.url);
            }
            if (this.authenticationService.isUserSignedIn) {
              this.router.navigate([APP_HOME_PATH], { replaceUrl: true }).then();
            } else {
              this.router.navigate([LOGIN_PATH], { replaceUrl: true }).then();
            }
            resolve(false);
          }
        })
        .catch(error => {
          if (environment.production) {
            console.log(error);
          }
        });
    });
  }
}
