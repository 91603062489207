import { FormGroup } from '@angular/forms';

export interface IGetTenantGroupDto {
  id: string;
  linkId: string;
  tenant: string;
  name: string;
  type: string;
  deletable: boolean;
}

export interface IProjectGroupDto {
  id: string;
  name: string;
  projectIds: string[];
  tenant: string;
  type: string;
}

export interface ISummaryDataDto {
  id: string;
  firstName: string;
  lastName: string;
  statusName: string;
  type: string;
  line1: string;
  town: string;
  postCode: string;
}

export interface ILinkedProjectsErrorDm {
  message: string;
  qaClearErrorsButton: string;
  qaErrorMessage: string;
}

export interface ILinkedProjectsErrorVm {
  message: string;
  qaClearErrorsButton: string;
  qaErrorMessage: string;
}

export interface ILinkedProjectsDm {
  groups: IGroupDm[];
  groupId: string;
  groupType: string;
  projectGroups: IProjectGroupDm[];
  groupNamePrefix: string;
  groupNameMultiPrefix: string;
  linkedData: ISummaryDataDm[];
  unlinkButton: string;
  createLabel: string;
  addressLabel: string;
  statusLabel: string;
  customerLabel: string;
  deleteGroupTooltip: string;
  deleteInProgressText: string;
  searchPlaceholder: string;
  showMainSpinner: boolean;
  srLoading: string;
  createNewLoadingText: string;
  isCreatingGroup: boolean;
  hasError: boolean;
  inProgress: boolean;
  form: FormGroup;
}

export interface ISummaryDataDm {
  id: string;
  firstName: string;
  lastName: string;
  statusName: string;
  type: string;
  line1: string;
  town: string;
  postCode: string;
}

export interface IGroupDm {
  id: string;
  linkId: string;
  tenant: string;
  name: string;
  type: string;
  deletable: boolean;
  isDeleting: boolean;
}

export interface IProjectGroupDm {
  id: string;
  name: string;
  type: string;
  tenant: string;
  projectIds: string[];
}

export interface ILinkedProjectsVm {
  groupList: IGroupVm[];
  linkedData: ISummaryDataVm[];
  groupId: string;
  linkedGroupNamePrefix: string;
  linkedGroupName: string;
  showGroupList: boolean;
  showLinks: boolean;
  groupListLabel: string;
  addNewGroupText: string;
  searchPlaceholder: string;
  showMainSpinner: boolean;
  unlinkButton: string;
  addressLabel: string;
  statusLabel: string;
  customerLabel: string;
  deleteGroupTooltip: string;
  deleteInProgressText: string;
  qaMainSpinner: string;
  qaGroupSelectControl: string;
  screenReaderLoadingLabel: string;
  isCreatingGroup: boolean;
  createNewLoadingText: string;
  inProgress: boolean;
  useScroll: boolean;
  numberInGroup: number;
  form: FormGroup;
}

export interface ISummaryDataVm {
  projectId: string;
  name: string;
  status: string;
  location: string;
  projectType: string;
  isCurrentProject: boolean;
  qaLinkSummaryItem: string;
  qaUnlinkButton: string;
}

export interface IGroupVm {
  displayName: string;
  displayNameShort: string;
  isLongGroupName: boolean;
  id: string;
  linkId: string;
  canDelete: boolean;
  deleteInProgress: boolean;
  qaGroupDeleteButton: string;
}
export interface IMessageDm {
  message: string;
  showLoader: boolean;
  spinnerName: string;
}

export interface IMessageVm {
  message: string;
  showLoader: boolean;
  qaMessage: string;
  qaSpinner: string;
  spinnerName: string;
}

export enum ErrorType {
  fetchGroupList = 'linkedProjects.error.fetchGroupList',
  createNewGroup = 'linkedProjects.error.createNewGroup',
  linkToGroup = 'linkedProjects.error.linkToGroup',
  unlinkFromGroup = 'linkedProjects.error.unlinkFromGroup',
  deleteGroup = 'linkedProjects.error.deleteGroup',
  unknown = 'linkedProjects.error.unknown'
}

export interface ILinkedProjectsI18n {
  [key: string]: string;
}

// constants
export const DEFAULT_SUMMARY_FIELDS = ['firstName', 'lastName', 'statusName', 'type', 'line1', 'town', 'postCode'];
export const MESSAGES_SPINNER = 'linkedProjectsMessageSpinner';
export const MAX_GROUP_NAME_LENGTH: number = 70;
export const MIN_GROUP_NAME_LENGTH: number = 3;
export const SHOW_REMAINING_GROUP_BUFFER: number = 5;
export const CHARACTERS_SHOWN_WHEN_DELETING: number = 12;
export const CHARACTERS_SHOWN_WHEN_LONG_NAME_START: number = 15;
export const CHARACTERS_SHOWN_WHEN_LONG_NAME_END: number = 5;
export const LONG_NAME_MIN: number = 22;
