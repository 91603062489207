import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import 'brace';
import 'brace/mode/json';
import 'brace/mode/html';
import 'brace/mode/markdown';
import 'brace/theme/dracula';
import { AceComponent } from 'ngx-ace-wrapper';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-code-section',
  template: `
    <form *ngIf="form" [formGroup]="form">
      <ace
        style="height: 150px"
        [mode]="'json'"
        [theme]="'dracula'"
        [value]="value"
        (change)="onValueChange(aceEditor)"
        #aceEditor
      >
      </ace>
    </form>
  `
})
export class CodeTypeComponent extends FieldType implements OnInit {
  private _value;
  private _unsubscribe$ = new Subject();

  get value() {
    return this._value && JSON.stringify(this._value, null, 2);
  }

  ngOnInit() {
    this._value = this.formControl.value;
    this.formControl.valueChanges
      .pipe(debounceTime(1000))
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(value => {
        this._value = value;
      });
  }

  onValueChange(aceEditor: AceComponent) {
    setTimeout(() => {
      try {
        let value = null;
        if (aceEditor.value && this.value !== aceEditor.value) {
          value = JSON.parse(aceEditor.value);
          this.formControl.patchValue(value);
          this.formControl.markAsDirty();
        } else if (!aceEditor.value && this.value !== null) {
          value = null;
          this.formControl.patchValue(value);
          this.formControl.markAsDirty();
        }
      } catch (e) {
        // Do nothing as code input is invalid.
      }
    }, 0);
  }
}
