import { Address, AddressMapping, PostCodeLookupProvider } from '../../../types';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PostalCodeMatchers } from '../../../domain/PostalCodeMatchers';
import { AddressMapperService } from '../../service/address-mapper.service';
import { AddressIoAddress } from './types';

export class AddressIoPostCodeLookupProvider implements PostCodeLookupProvider {
  name = 'getAddressIO';
  apiKey = {
    name: 'api-key',
    value: 'rq2ptHwF7kSgWAB2R-N5Tw13357'
  };
  addressEndpoint = 'https://api.getAddress.io/find';
  addressMappings: AddressMapping[] = [
    {
      from: 'line_1',
      to: 'line1'
    },
    {
      from: 'line_2',
      to: 'line2'
    },
    {
      from: 'line_3',
      to: 'line3'
    },
    {
      from: 'line_4',
      to: 'line4'
    },
    {
      from: 'town_or_city',
      to: 'town'
    }
  ];
  match: string;
  endpointParams = {
    sort: 'true',
    expand: 'true'
  };

  constructor(
    private countryCode: string,
    private httpClient: HttpClient,
    private addressMapperService = new AddressMapperService()
  ) {
    this.match = PostalCodeMatchers[countryCode];
  }

  async getAddresses(addressCode: string): Promise<Address[]> {
    const addressIoAddresses = await firstValueFrom(this.getRawAddresses(addressCode));

    return this.addressMapperService.applyMaps(addressIoAddresses, this.addressMappings);
  }

  private getRawAddresses(addressCode: string): Observable<
    (AddressIoAddress & {
      latitude: number;
      longitude: number;
      postCode: string;
    })[]
  > {
    return this.httpClient
      .get(`${this.addressEndpoint}/${addressCode}`, {
        headers: {
          skip: 'true'
        },
        params: {
          [this.apiKey.name]: this.apiKey.value,
          ...(this.endpointParams || {})
        }
      })
      .pipe(
        map((response: { addresses: AddressIoAddress[]; latitude: number; longitude: number; postcode: string }) => {
          return response.addresses.map(address => {
            return {
              ...address,
              latitude: response.latitude,
              longitude: response.longitude,
              postCode: response.postcode
            };
          });
        }),
        catchError(() => {
          return of([]);
        })
      );
  }

  formatAddressCode(postcode: string): string {
    return postcode;
  }
}
