import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import 'brace';
import 'brace/mode/json';
import 'brace/mode/html';
import 'brace/mode/markdown';
import 'brace/theme/dracula';
import { AceComponent } from 'ngx-ace-wrapper';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-html-section',
  template: `
    <form [formGroup]="form">
      <ace
        style="height: 100px"
        [mode]="'html'"
        [theme]="'dracula'"
        [value]="value"
        (change)="onValueChange(aceEditor)"
        #aceEditor
      >
      </ace>
    </form>
  `
})
export class HtmlTypeComponent extends FieldType implements OnInit, OnDestroy {
  private _value;
  private _unsubscribe$ = new Subject();

  get value() {
    return this._value;
  }

  ngOnInit() {
    this._value = this.formControl.value;
    this.formControl.valueChanges
      .pipe(debounceTime(1000))
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(value => {
        this._value = value;
      });
  }

  onValueChange(aceEditor: AceComponent) {
    setTimeout(() => {
      try {
        this.formControl.patchValue(aceEditor?.value ?? null);
        this.form.updateValueAndValidity();
      } catch (e) {
        // Do nothing as code input is invalid.
      }
    }, 0);
  }

  ngOnDestroy() {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }
}
