import { environment } from '../../../environments/environment';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import * as XRegExp from 'xregexp';

const interceptPattern = `https:\\/\\/.*\\.jumpte\\.ch`;

@Injectable({
  providedIn: 'root'
})
export class HttpBranchHeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Retrieve the preview branch header from local storage
    if (environment.name != 'preview' || !XRegExp(interceptPattern).test(req.url)) {
      return next.handle(req);
    }
    const branchName = localStorage.getItem('previewBranch') || 'develop';
    // Clone the request to add the new header
    console.log(`Setting branch as ${branchName}`);
    const clonedRequest = req.clone({ headers: req.headers.append('x-jt-branch', branchName) });
    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}
