import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SearchBarPresenter } from './search-bar.presenter';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ISearchBarFVm, ISearchBarVm, SearchTarget } from '../search.model';

@Component({
  selector: 'app-search',
  templateUrl: 'search-bar.component.html',
  styleUrls: ['../search.component.scss']
})
export class SearchBarComponent implements AfterViewInit {
  @ViewChild('searchInput')
  public searchInput: ElementRef;

  @Input() maxResults = 10;
  @Input() minSearchLength = 2;
  @Input() target: SearchTarget;

  vm: ISearchBarVm;
  fVm: ISearchBarFVm = {} as ISearchBarFVm;

  constructor(public presenter: SearchBarPresenter) {
    this.fVm.form = new UntypedFormGroup({ searchString: new UntypedFormControl('') });
  }

  get isSearchActive() {
    return this.searchString.length >= this.minSearchLength;
  }

  get searchString() {
    return this.searchControl.value;
  }

  get searchControl() {
    return this.fVm.form.get('searchString');
  }

  get isButtonPrimary() {
    return this.isSearchActive || this.vm.isSearching;
  }

  get isButtonLight() {
    return !this.isSearchActive && !this.vm.isSearching;
  }

  get isButtonDisabled() {
    return !this.isSearchActive || this.vm.isSearching;
  }

  get isSignedIn() {
    return this.presenter.isSignedIn();
  }

  clearResults() {
    this.presenter.clearResults(this.target);
    this.clearSearchTerm();
    this.focusOnSearch();
  }

  clearSearchTerm() {
    this.searchControl.patchValue('');
  }

  focusOnSearch() {
    this.searchInput.nativeElement.focus();
  }

  async search(searchTerm) {
    if (searchTerm.length >= this.minSearchLength) {
      await this.presenter.search(
        generatedViewModel => {
          this.vm = generatedViewModel;
        },
        searchTerm,
        this.target,
        this.maxResults
      );
    }
  }

  ngAfterViewInit() {
    this.target === SearchTarget.main ? this.focusOnSearch() : null;
    this.vm = this.presenter.load(generatedFormViewModel => {
      this.fVm.form.patchValue({ searchString: generatedFormViewModel });
    }, this.target);
  }
}
