import { CardActionBase } from './card-action.base';

export class OcrActionRegex {
  patterns: string[];
  type: string;
}

export class OcrAction extends CardActionBase {
  targetField: string;
  targetOcr: string;
  regexs: OcrActionRegex[];
  manualTarget: string;
}
