import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface menuItem {
  label: string;
  icon: string;
  qaHook: string;
  hasAccess: boolean;
  routeLink?: string;
  externalLink?: string;
  isDisabled?: boolean;
  isBetaFeature?: boolean;
  useFeatureToggle?: string;
}

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html'
})
export class MenuItemComponent implements OnInit {
  @Input() isActive: boolean;
  @Input() hasAccess: boolean;
  @Input() routeLink: string;
  @Input() externalLink: string;
  @Input() isClosed: boolean;
  @Input() isSmallScreen: boolean;
  @Input() qaHook: string;
  @Input() label: string;
  @Input() icon: string;
  @Input() isDisabled = false;
  @Input() isBetaFeature = false;

  @Output() menuItemClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() externalLinkClick: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  onMenuItemClick() {
    if (this.externalLink) {
      this.externalLinkClick.emit(this.externalLink);
    } else {
      this.menuItemClick.emit();
    }
  }

  ngOnInit(): void {}
}
