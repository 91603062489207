export interface FileResource {
  getValue(): string;
  setValue(value: string): void;
  toJson(): any;
}

export const makeFileResource = (src: any): FileResource | null => {
  if (!src) {
    return null;
  }

  if (typeof src === 'string') {
    return ImageResource.from(src);
  } else if (typeof src === 'object') {
    return AttachmentResource.from(src);
  }

  return null;
};

/**
 * An image resource is a simple string.
 */
export class ImageResource implements FileResource {
  constructor(private value: string) {}

  static from(value: string): ImageResource {
    return new ImageResource(value);
  }

  getValue(): string {
    return this.value;
  }

  setValue(value: string) {
    this.value = value;
  }

  toJson(): string {
    return this.value;
  }
}

/**
 * An attachment resource is an object with a name and a data field.
 */
export class AttachmentResource implements FileResource {
  constructor(private name: string, private data: string) {}

  static from(src: any): AttachmentResource | null {
    if (src === null || !('name' in src) || !('data' in src)) {
      return null;
    }
    return new AttachmentResource(src.name, src.data);
  }

  getValue(): string {
    return this.data;
  }

  setValue(value: string) {
    this.data = value;
  }

  getName(): string {
    return this.name;
  }

  toJson(): any {
    return {
      name: this.name,
      data: this.data
    };
  }
}
