import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndicatorComponent } from './progress-indicator.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [ProgressIndicatorComponent],
  imports: [CommonModule, RoundProgressModule, NgbModule, TranslocoModule],
  exports: [ProgressIndicatorComponent]
})
export class ProgressIndicatorModule {}
