import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { Subject, takeUntil } from 'rxjs';
import { I18nKeys } from '../../domain/i18n-keys';
import { AbstractDropdownQuestionComponent } from './abstract-dropdown-question.component';

interface MultiQuestionItem {
  key: string;
  value: any;
  type: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'crds-question-multi-dropdown-value',
  template: `
    <div *ngIf="optionsPopulated && show">
      <div *ngIf="!initialised">
        <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
          {{ question.label }}
          <span *ngIf="question.required"> *</span>
        </label>
        <div class="form-control">
          <crds-inline-spinner
            [name]="question.key"
            [qaHook]="question.loadingQaHook"
            [type]="question.loadingType"
            [message]="question.loadingMessage"
            [colour]="question.loadingColour"
            [bgColour]="question.loadingBgColour"
            [textColour]="question.loadingTextColour"
            [center]="question.loadingCenter"
          >
          </crds-inline-spinner>
        </div>
      </div>
      <div *ngIf="initialised">
        <div class="multiple-item-question" [attr.data-qa]="question.key + '_section'">
          <hr />
          <label *ngIf="question.label" class="form-label"
            >{{ question.label }}
            <span *ngIf="question.required"> *</span>
            <crds-form-error
              *ngIf="isInvalid"
              [question]="question"
              [message]="question.errorMessage"
              [i18ns]="i18ns"
            ></crds-form-error>
          </label>
          <div class="multiple-item" *ngFor="let multiQuestion of multiQuestions; index as i">
            <div class="multiple-item-row">
              <div class="multiple-item-key">
                <div>{{ question.dropdownItemLabel || 'Item' }}:</div>
                <input
                  type="text"
                  [attr.list]="'dataList_' + question.key + '_' + i"
                  class="form-control multiple-item-key"
                  [(ngModel)]="multiQuestion.key"
                  [placeholder]="question.placeholder || 'Click to select or type in a label'"
                  [attr.data-qa]="question.key + '_item_input' + '_' + i"
                  (change)="updateFormValue()"
                />
                <datalist id="{{ 'dataList_' + question.key + '_' + i }}">
                  <option *ngFor="let opt of question.options" [value]="opt.key">{{ opt.value }}</option>
                </datalist>
              </div>
              <div class="multiple-item-value">
                <div>{{ question.dropdownValueLabel || 'Value' }}:</div>
                <input
                  class="form-control multiple-item-value"
                  [(ngModel)]="multiQuestion.value"
                  (change)="updateFormValue()"
                  [type]="multiQuestion.type || 'number'"
                  [attr.data-qa]="question.key + '_value_input' + '_' + i"
                />
              </div>
              <div class="multiple-item-action">
                <jui-icon
                  (click)="deleteQuestion(i)"
                  name="delete_forever"
                  size="sm"
                  [attr.data-qa]="question.key + '_delete' + '_' + i"
                >
                </jui-icon>
              </div>
            </div>
          </div>

          <jui-icon (click)="addQuestion()" name="playlist_add" size="sm" [attr.data-qa]="question.key + '_add'">
          </jui-icon>

          <!-- QUESTION HINT -->
          <crds-question-hint [question]="question"></crds-question-hint>

          <p *ngIf="alert">
            <jui-alert-block iconName="info" size="sm">
              {{ question.alert[form.get(question.key).value] }}
            </jui-alert-block>
          </p>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .btn-sm-add {
        border-radius: 15px;
        width: 30px;
        height: 30px;
        margin-left: 0.25rem;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        cursor: pointer;
      }
      .btn-sm-add > i {
        padding-top: 0.25rem;
        font-size: 1.2rem;
        color: white;
      }
      .btn-sm-delete {
        margin-top: 1.3rem;
        cursor: pointer;
      }
      .multiple-item {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
      }
      .multiple-item-value {
        margin-left: 0.5rem;
        margin-right: 0.8rem;
      }
      .multiple-item-value > div {
        margin-left: 8px;
      }
      .multiple-item-key {
        width: 100%;
      }
      .multiple-item-key input::placeholder {
        color: grey;
        font-style: italic;
      }
      .multiple-item-row {
        background-color: inherit;
        padding: 0;
        display: flex;
        width: 100%;
        align-items: end;
      }
      hr {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
      }
    `
  ]
})
export class MultiDropdownWithValueQuestionComponent
  extends AbstractDropdownQuestionComponent
  implements OnChanges, OnDestroy
{
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  multiQuestions: MultiQuestionItem[] = [];
  private _unsubscribeFormChanges$ = new Subject();

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    if (this.question.datasource) {
      if (!this.question.datasource.transform) {
        this.question.datasource.transform =
          '[].{ text: join(`" - "`, [parent.name, name]), value: join(`" - "`, [parent.name, name]) }';
      }
      await this.spinnerService.show(this.question.key);
      await this.getData(this.question.datasource);
      this.render();
      await this.spinnerService.hide(this.question.key);
    } else {
      this.render();
    }
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeFormChanges$)).subscribe(x => {
      if (this.hasChanged()) {
        this.multiQuestions =
          this.form?.get(this.question.key)?.value && Array.isArray(this.form?.get(this.question.key)?.value)
            ? this.form?.get(this.question.key)?.value
            : [];
      }
    });
  }

  ngOnDestroy() {
    this._unsubscribeFormChanges$.next(null);
    this._unsubscribeFormChanges$.complete();
  }

  render() {
    this.multiQuestions =
      this.form?.get(this.question.key)?.value && Array.isArray(this.form?.get(this.question.key)?.value)
        ? this.form?.get(this.question.key)?.value
        : [];

    this.initialised = true;

    setTimeout(() => {
      this.updateFormValue();
    });
  }

  updateFormValue() {
    const answers = this.getAnswers(this.multiQuestions);
    this.form?.get(this.question.key)?.patchValue(answers);
  }

  private getAnswers(values: MultiQuestionItem[]) {
    const answers = values.filter(a => a.key);
    for (const answer of answers) {
      const standardAnswer = this.question.options.find(a => a.key === answer.key);
      answer.type = standardAnswer ? standardAnswer.type : this.question.defaultType || 'number';
    }
    return answers;
  }

  hasChanged() {
    const newMultiQuestions =
      this.form?.get(this.question.key)?.value && Array.isArray(this.form?.get(this.question.key)?.value)
        ? this.form?.get(this.question.key)?.value
        : [];
    const newAnswers = this.getAnswers(newMultiQuestions);
    const answers = this.getAnswers(this.multiQuestions);
    const One = newAnswers.every(x => answers.some(y => x.key === y.key && x.value === y.value));
    const Two = answers.every(x => newAnswers.some(y => x.key === y.key && x.value === y.value));
    return !One || !Two;
  }

  addQuestion() {
    this.multiQuestions.push({
      key: null,
      value: null,
      type: null
    });
    this.updateFormValue();
  }

  deleteQuestion(i) {
    this.multiQuestions.splice(i, 1);
    this.updateFormValue();
  }

  get optionsPopulated(): boolean {
    if (this.question.datasource) {
      return true;
    }
    return this.question.options && this.question.options.length > 0;
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  get alert() {
    return this.question.alert && this.question.alert[this.form?.get(this.question.key)?.value];
  }
}
