<div [formGroup]="newPasswordRequiredForm">
  <p class="login-card__txt">{{ 'auth.newPasswordRequired' | transloco }}</p>
  <label class="login-card__lbl" for="new-password">{{ 'auth.formFields.newPassword.label' | transloco }}</label>
  <div class="login-card__row" [class.login-card__row-slim]="newPasswordRequiredForm.controls['newPassword'].dirty">
    <input
      #newPasswordInput
      class="form-control login-card__ctrl login-card__ctrl--has-icon"
      [type]="showPassword ? 'text' : 'password'"
      placeholder="{{ 'auth.formFields.newPassword.placeholder' | transloco }}"
      formControlName="newPassword"
      autocomplete="new-password"
      id="new-password"
      data-qa="newPasswordInput"
    />
    <button class="login-card__toggle" (click)="togglePassword()">
      <span *ngIf="!showPassword" class="material-icons login-card__toggle-icon"> visibility </span>
      <span *ngIf="showPassword" class="material-icons login-card__toggle-icon"> visibility_off </span>
    </button>
  </div>
  <app-password-strength-checker [form]="newPasswordRequiredForm"></app-password-strength-checker>
  <label class="login-card__lbl" for="new-password-confirm">{{
    'auth.formFields.newPasswordConfirm.label' | transloco
  }}</label>
  <div class="login-card__row">
    <input
      class="form-control login-card__ctrl login-card__ctrl--has-icon"
      [type]="showPassword ? 'text' : 'password'"
      placeholder="{{ 'auth.formFields.newPasswordConfirm.placeholder' | transloco }}"
      formControlName="newPasswordConfirm"
      id="new-password-confirm"
      data-qa="newPasswordConfirmInput"
      autocomplete="new-password"
      (keyup.enter)="newPasswordSubmit()"
    />
    <button class="login-card__toggle" (click)="togglePassword()">
      <span *ngIf="!showPassword" class="material-icons login-card__toggle-icon"> visibility </span>
      <span *ngIf="showPassword" class="material-icons login-card__toggle-icon"> visibility_off </span>
    </button>
  </div>
  <div
    class="login-card__row"
    *ngIf="
      newPasswordRequiredForm.errors?.mismatch &&
      (newPasswordRequiredForm.controls['newPasswordConfirm'].dirty ||
        newPasswordRequiredForm.controls['newPassword'].touched)
    "
  >
    <div class="login-card__error a-fade-in-fast">
      {{ 'auth.error.passwordMisMatch' | transloco }}
    </div>
  </div>
  <div class="login-card__row">
    <div [class.login-card__btn--disabled]="newPasswordRequiredForm.invalid">
      <jui-button
        size="xl"
        expand
        data-qa="newPasswordRequiredButton"
        [disabled]="newPasswordRequiredForm.invalid || submitInProgress"
        (click)="newPasswordSubmit()"
        [loading]="submitInProgress"
      >
        <span *ngIf="!submitInProgress">{{ 'Buttons.setNewPassword.text' | transloco }}</span>
        <span *ngIf="submitInProgress">{{ 'Buttons.settingNewPassword.text' | transloco }}</span>
      </jui-button>
    </div>
  </div>
</div>
