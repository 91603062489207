import { Injectable } from '@angular/core';
import { AccessService } from '../../auth/services/access.service';
import { UserService } from '../../auth/services/user.service';
import { User } from '../../core/domain/user';
import { Tab } from './configuration.module';

@Injectable()
export class ConfigurationPresenter {
  public currentUser: User;

  constructor(private userService: UserService, private accessService: AccessService) {}

  show(tab: Tab) {
    if (tab.requiresSuperAdmin) {
      return this.currentUser?.isSuperAdmin;
    }
    let result = true;
    if (tab.requiresAdmin) {
      result = result && this.currentUser?.isAdmin;
    }
    if (tab.requiresFeature) {
      result = result && this.accessService.isFeatureAccessAllowed(tab.requiresFeature);
    }
    return result;
  }
}
