<div *ngIf="states" class="progress-wrapper text-center" [attr.data-qa]="'statusCounters'">
  <div
    *ngFor="let state of states; index as i"
    [class]="'progress-point ' + state.status"
    [attr.data-qa]="state.status"
  >
    <div
      *ngIf="isCurrentStatusType(state.status); else notCurrent"
      title="{{ 'common.progress' | transloco }} {{ state.label }}"
    >
      <div
        class="progress-label"
        *ngIf="state.status === progressingStatus && progress > 0; else notProgressing"
        [innerHtml]="state.label"
        [attr.data-qa]="'progressLabel'"
      ></div>
      <ng-template #notProgressing>
        <div class="progress-label" [innerHtml]="state.label" [attr.data-qa]="'progressLabel'"></div>
      </ng-template>
    </div>

    <ng-template #notCurrent>
      <div class="progress-label" [innerHtml]="state.label" [attr.data-qa]="'progressLabel'"></div>
    </ng-template>

    <div *ngIf="i < states.length - 1" [class]="'progress-bar-left ' + (isArchived ? 'is-archived' : '')"></div>
    <div *ngIf="i > 0" [class]="'progress-bar-right ' + (isArchived ? 'is-archived' : '')"></div>

    <div
      (click)="clicked(state)"
      *ngIf="!isProgressing(state.status)"
      [class.cursor-pointer]="state.showProgress"
      [class.cursor-help]="shouldShowStatusData(state.statusData, i)"
    >
      <ng-container *ngIf="shouldShowStatusData(state.statusData, i); else noStatusData">
        <div (mouseenter)="state.statusData.show = true" (mouseleave)="state.statusData.show = false">
          <div class="progress-icon-background" [attr.data-qa]="state.label + '_count'">{{ state.count }}</div>
          <div [class]="'progress-icon ' + (isArchived ? 'is-archived' : '')">
            <i class="material-icons">{{ getIconForStatus(state.status) }}</i>
          </div>
          <ng-container *ngIf="state.statusData.show">
            <div class="status-data">
              <div class="status-data__title">{{ state.label }}</div>
              <div class="status-data__item">
                {{ state.statusData.actionedBy || '- - -' }}
              </div>
              <div class="status-data__item">
                <span class="status-data__emp">{{ state.statusData.date | date: 'dd/MM/yyyy - HH:mm' }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #noStatusData>
        <ng-container *ngIf="state.status === skippedStatus">
          <div (mouseenter)="state.showSkipped = true" (mouseleave)="state.showSkipped = false" class="cursor-help">
            <div class="progress-icon-background" [attr.data-qa]="state.label + '_count'">{{ state.count }}</div>
            <div [class]="'progress-icon ' + (isArchived ? 'is-archived' : '')">
              <i class="material-icons">{{ getIconForStatus(state.status) }}</i>
            </div>
            <ng-container *ngIf="state.showSkipped">
              <div class="status-data status-data--skip">
                <div class="status-data__title">{{ state.label }}</div>
                <div class="status-data__item u-text-upper">{{ 'progress.skippedStatusMsg' | transloco }}</div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="state.status !== skippedStatus">
          <div class="progress-icon-background" [attr.data-qa]="state.label + '_count'">{{ state.count }}</div>
          <div [class]="'progress-icon ' + (isArchived ? 'is-archived' : '')">
            <i class="material-icons">{{ getIconForStatus(state.status) }}</i>
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div
      (mouseenter)="hovering = true"
      (mouseleave)="hovering = false"
      (click)="clicked(state)"
      class="circle-progress-wrapper"
      *ngIf="isProgressing(state.status)"
    >
      <round-progress
        [current]="progress"
        [max]="100"
        [radius]="25"
        [stroke]="8"
        [background]="getColor('--jds-theme-color-secondary')"
        [color]="getColor('--jds-theme-color-primary')"
      >
      </round-progress>
      <div class="progress-percent" [attr.data-qa]="'inProgress'">{{ progress }}%</div>
      <div class="status-data status-data--progress" *ngIf="hovering">
        <div class="status-data__title" *ngIf="cardLabel">Section: {{ cardLabel }}</div>
        <div class="status-data__item">
          {{ 'common.complete' | transloco }} <span class="status-data__emp">{{ progress }}%</span>
        </div>
        <div class="status-data__item">
          {{ 'common.lastUpdate' | transloco }} <span class="status-data__emp">{{ progressDate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
