<div class="modal-wrapper" *ngIf="form">
  <div class="modal-header-wrapper">
    <div class="modal-header">
      <div *ngIf="mode === 'edit'">{{ 'common.formFields.id' | transloco }} {{ form.get('id').value }}</div>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-banner">
      {{ mode === 'edit' ? ('common.update' | transloco) : ('common.add' | transloco) }}
      {{ 'common.subTenant' | transloco }}
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="form.controls.reference" class="form-group" [formGroup]="form">
      <div class="form-row mb-2">
        <div class="col-12">
          <label for="reference">{{ 'subTenant.addSubTenant.fields.reference' | transloco }}</label>
          <input class="form-control" type="text" id="reference" formControlName="reference" />
        </div>
      </div>
    </div>
    <div *ngIf="form.controls.srcTenant" class="form-group" [formGroup]="form">
      <div class="form-row mb-2">
        <div class="col-12">
          <label for="srcTenant">{{ 'subTenant.addSubTenant.fields.sourceTenant' | transloco }}</label>
          <input class="form-control" type="text" id="srcTenant" formControlName="srcTenant" />
        </div>
      </div>
    </div>
    <div class="form-group" [formGroup]="asFormGroup(form.controls.configuration)">
      <div class="form-row mb-2">
        <div class="col-12">
          <label for="name">{{ 'common.formFields.name' | transloco }} *</label>
          <app-form-error *ngIf="isFieldInvalid('name')" field="Name"></app-form-error>
          <input class="form-control" type="text" id="name" formControlName="name" />
        </div>
      </div>

      <div class="form-divider"></div>

      <div class="form-row mb-2">
        <div class="col-12">
          <h6 class="form-section-title">
            {{ 'subTenant.addSubTenant.headings.tenantConfiguration' | transloco }}
          </h6>
          <div class="form-group" [formGroup]="asFormGroup(form.get('configuration').get('tenantConfiguration'))">
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="installer">{{ 'common.installer' | transloco }} *</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('installer', 'tenantConfiguration')"
                  field="Installer"
                ></app-form-error>
                <input class="form-control" type="text" id="installer" formControlName="installer" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="registeredCompanyName">{{
                  'subTenant.addSubTenant.fields.registeredCompanyName' | transloco
                }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('registeredCompanyName', 'tenantConfiguration')"
                  field="Registered Company Name"
                ></app-form-error>
                <input
                  class="form-control"
                  type="text"
                  id="registeredCompanyName"
                  formControlName="registeredCompanyName"
                />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="installerContact">{{ 'subTenant.addSubTenant.fields.installerContact' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('installerContact', 'tenantConfiguration')"
                  field="Installer Contact"
                ></app-form-error>
                <input class="form-control" type="text" id="installerContact" formControlName="installerContact" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="installerEmail">{{ 'subTenant.addSubTenant.fields.installerEmail' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('installerEmail', 'tenantConfiguration')"
                  field="Installer Email"
                ></app-form-error>
                <input class="form-control" type="text" id="installerEmail" formControlName="installerEmail" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="installerSignature" class="uploaded-image-label">{{
                  'subTenant.addSubTenant.fields.signature' | transloco
                }}</label>

                <div class="image-drop">
                  <button
                    *ngIf="hasSignature()"
                    title="{{ 'Buttons.removeImage.title' | transloco }}"
                    class="btn btn-xs btn-link image-drop__remove"
                    type="button"
                    (click)="this.form.get('configuration.tenantConfiguration.installerSignature').patchValue(null)"
                  >
                    <span class="material-icons u-icon-sm">delete_outline</span>
                  </button>
                  <div class="image-drop__target">
                    <textarea
                      class="image-drop__input"
                      id="installerSignature"
                      formControlName="installerSignature"
                      placeholder="{{ 'subTenant.addSubTenant.placeholders.signatureDragAndDrop' | transloco }}"
                      rows="6"
                      appDragDrop
                      (onFileDropped)="setSignature($event)"
                    ></textarea>
                  </div>
                  <div class="image-drop__preview">
                    <ng-container *ngIf="hasSignature(); else placeholderImage">
                      <img
                        class="image-drop__preview-img"
                        [src]="this.form.get('configuration.tenantConfiguration.installerSignature')?.value"
                        alt=""
                      />
                    </ng-container>
                    <ng-template #placeholderImage>
                      <div class="image-drop__placeholder"></div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <strong>{{ 'common.formFields.address' | transloco }}</strong>
                <div
                  class="form-group"
                  [formGroup]="asFormGroup(form.get('configuration').get('tenantConfiguration').get('address'))"
                >
                  <div class="form-row mb-2">
                    <div class="col-6">
                      <label for="line1">{{ 'common.formFields.line1' | transloco }}</label>
                      <input class="form-control" type="text" id="line1" formControlName="line1" />
                    </div>
                    <div class="col-6">
                      <label for="line2">{{ 'common.formFields.line2' | transloco }}</label>
                      <input class="form-control" type="text" id="line2" formControlName="line2" />
                    </div>
                  </div>
                  <div class="form-row mb-2">
                    <div class="col-6">
                      <label for="line3">{{ 'common.formFields.line3' | transloco }}</label>
                      <input class="form-control" type="text" id="line3" formControlName="line3" />
                    </div>
                    <div class="col-6">
                      <label for="line4">{{ 'common.formFields.line4' | transloco }}</label>
                      <input class="form-control" type="text" id="line4" formControlName="line4" />
                    </div>
                  </div>
                  <div class="form-row mb-2">
                    <div class="col-6">
                      <label for="locality">{{ 'common.formFields.locality' | transloco }}</label>
                      <input class="form-control" type="text" id="locality" formControlName="locality" />
                    </div>
                    <div class="col-6">
                      <label for="town">{{ 'common.formFields.town' | transloco }}</label>
                      <input class="form-control" type="text" id="town" formControlName="town" />
                    </div>
                  </div>
                  <div class="form-row mb-2">
                    <div class="col-6">
                      <label for="county">{{ 'common.formFields.county' | transloco }}</label>
                      <input class="form-control" type="text" id="county" formControlName="county" />
                    </div>
                    <div class="col-6">
                      <label for="country">{{ 'common.formFields.country' | transloco }}</label>
                      <input class="form-control" type="text" id="country" formControlName="country" />
                    </div>
                  </div>
                  <div class="form-row mb-2">
                    <div class="col-6">
                      <label for="postCode">{{ 'common.formFields.postCode' | transloco }}</label>
                      <input class="form-control" type="text" id="postCode" formControlName="postCode" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="installerEvhs">{{ 'subTenant.addSubTenant.fields.installerEVHSNumber' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('installerEvhs', 'tenantConfiguration')"
                  field="Installer EVHS"
                ></app-form-error>
                <input class="form-control" type="text" id="installerEvhs" formControlName="installerEvhs" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="installerEvhs">{{
                  'subTenant.addSubTenant.fields.installerAccreditationBody' | transloco
                }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('installerAccreditationBody', 'tenantConfiguration')"
                  field="Installer Accreditation Body"
                ></app-form-error>
                <input
                  class="form-control"
                  type="text"
                  id="installerAccreditationBody"
                  formControlName="installerAccreditationBody"
                />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="installerPhone">{{ 'subTenant.addSubTenant.fields.installerPhone' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('installerPhone', 'tenantConfiguration')"
                  field="InstallerPhone"
                ></app-form-error>
                <input class="form-control" type="text" id="installerPhone" formControlName="installerPhone" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="installerWebsite">{{ 'subTenant.addSubTenant.fields.installerWebsite' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('installerWebsite', 'tenantConfiguration')"
                  field="InstallerWebsite"
                ></app-form-error>
                <input class="form-control" type="text" id="installerWebsite" formControlName="installerWebsite" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="smsMessage">{{ 'subTenant.addSubTenant.fields.smsMessage' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('smsMessage', 'tenantConfiguration')"
                  field="SMS Message"
                ></app-form-error>
                <textarea class="form-control" id="smsMessage" formControlName="smsMessage"></textarea>
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="smsSenderId">{{ 'subTenant.addSubTenant.fields.smsSenderID' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('subTenant.addSubTenant.fields.smsSenderId', 'tenantConfiguration')"
                  field="SMS Sender Id"
                ></app-form-error>
                <input class="form-control" type="text" id="smsSenderId" formControlName="smsSenderId" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="outgoingCustomerEmail">{{
                  'subTenant.addSubTenant.fields.outgoingCustomerEmail' | transloco
                }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('outgoingCustomerEmail', 'tenantConfiguration')"
                  field="Outgoing Customer Email"
                ></app-form-error>
                <input
                  class="form-control"
                  type="text"
                  id="outgoingCustomerEmail"
                  formControlName="outgoingCustomerEmail"
                />
              </div>
            </div>
          </div>
          <h6>
            {{ 'subTenant.addSubTenant.headings.qualifiedSupervisor' | transloco }}
          </h6>
          <div class="form-group" [formGroup]="qualifiedSupervisorConfigForm">
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="qualifiedSupervisorConfig-name"
                  >{{ 'subTenant.addSubTenant.fields.qualifiedSupervisorConfig.name' | transloco }}
                </label>
                <input class="form-control" type="text" id="qualifiedSupervisorConfig-name" formControlName="name" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="qualifiedSupervisorConfig-tradeAssociationName"
                  >{{ 'subTenant.addSubTenant.fields.qualifiedSupervisorConfig.tradeAssociationName' | transloco }}
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="qualifiedSupervisorConfig-tradeAssociationName"
                  formControlName="tradeAssociationName"
                />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="qualifiedSupervisorConfig-membershipNumber"
                  >{{ 'subTenant.addSubTenant.fields.qualifiedSupervisorConfig.membershipNumber' | transloco }}
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="qualifiedSupervisorConfig-membershipNumber"
                  formControlName="membershipNumber"
                />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="qualifiedSupervisorConfig-signature" class="uploaded-image-label">{{
                  'subTenant.addSubTenant.fields.qualifiedSupervisorConfig.signature' | transloco
                }}</label>
                <div class="image-drop">
                  <button
                    *ngIf="hasQualifiedSupervisorSignature()"
                    title="{{ 'Buttons.removeImage.title' | transloco }}"
                    class="btn btn-xs btn-link image-drop__remove"
                    type="button"
                    (click)="
                      this.form
                        .get('configuration.tenantConfiguration.qualifiedSupervisorConfig.signature')
                        .patchValue(null)
                    "
                  >
                    <span class="material-icons u-icon-sm">delete_outline</span>
                  </button>
                  <div class="image-drop__target">
                    <textarea
                      class="image-drop__input"
                      id="qualifiedSupervisorConfig-signature"
                      formControlName="signature"
                      placeholder="{{ 'subTenant.addSubTenant.placeholders.signatureDragAndDrop' | transloco }}"
                      rows="6"
                      appDragDrop
                      (onFileDropped)="setQualifiedSupervisorSignature($event)"
                    ></textarea>
                  </div>
                  <div class="image-drop__preview">
                    <ng-container *ngIf="hasQualifiedSupervisorSignature(); else placeholderImage">
                      <img
                        class="image-drop__preview-img"
                        [src]="
                          this.form.get('configuration.tenantConfiguration.qualifiedSupervisorConfig.signature')?.value
                        "
                        alt=""
                      />
                    </ng-container>
                    <ng-template #placeholderImage>
                      <div class="image-drop__placeholder"></div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-divider"></div>

      <div class="form-row mb-2">
        <div class="col-12">
          <h6 class="form-section-title">
            {{ 'subTenant.addSubTenant.headings.pathwayConfiguration' | transloco }}
          </h6>
          <div class="form-group" [formGroup]="asFormGroup(form.get('configuration').get('pathwayConfiguration'))">
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="logo">{{ 'subTenant.addSubTenant.fields.pathwayLogo' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('logo', 'pathwayConfiguration')"
                  field="Pathway Logo Url"
                ></app-form-error>
                <input class="form-control" type="text" id="logo" formControlName="logo" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="styles">{{ 'subTenant.addSubTenant.fields.stylesURL' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('styles', 'pathwayConfiguration')"
                  field="Pathway Styles Url"
                ></app-form-error>
                <input class="form-control" type="text" id="styles" formControlName="styles" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="font">{{ 'subTenant.addSubTenant.fields.font' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('font', 'pathwayConfiguration')"
                  field="Font (Google font name)"
                ></app-form-error>
                <input class="form-control" type="text" id="font" formControlName="font" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-divider"></div>

      <div class="form-row mb-2">
        <div class="col-12">
          <h6 class="form-section-title">
            {{ 'subTenant.addSubTenant.headings.relayConfiguration' | transloco }}
          </h6>
          <div class="form-group" [formGroup]="asFormGroup(form.get('configuration').get('relayConfiguration'))">
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="customerLogo">{{ 'subTenant.addSubTenant.fields.relayLogo' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('customerLogo', 'relayConfiguration')"
                  field="Relay Logo Url"
                ></app-form-error>
                <input class="form-control" type="text" id="customerLogo" formControlName="customerLogo" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="relayStyles">{{ 'subTenant.addSubTenant.fields.stylesURL' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('styles', 'relayConfiguration')"
                  field="Relay Styles Url"
                ></app-form-error>
                <input class="form-control" type="text" id="relayStyles" formControlName="styles" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="relayFont">{{ 'subTenant.addSubTenant.fields.font' | transloco }}</label>
                <app-form-error
                  *ngIf="isConfigFieldInvalid('font', 'relayConfiguration')"
                  field="Font (Google font name)"
                ></app-form-error>
                <input class="form-control" type="text" id="relayFont" formControlName="font" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-divider"></div>

      <div class="form-row mb-2">
        <div class="col-12">
          <h6 class="form-section-title">
            {{ 'subTenant.addSubTenant.headings.internationalConfiguration' | transloco }}
          </h6>
          <div class="form-group" [formGroup]="asFormGroup(form.get('configuration').get('tenantConfiguration'))">
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="locale">{{ 'subTenant.addSubTenant.fields.locale' | transloco }}</label>
                <input class="form-control" type="text" id="locale" formControlName="locale" />
              </div>
            </div>
            <div class="form-row mb-2">
              <div class="col-12">
                <label for="timezone">{{ 'common.timezone' | transloco }}</label>
                <input class="form-control" type="text" id="timezone" formControlName="timezone" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <jui-button
      size="sm"
      type="button"
      [disabled]="form.invalid || isFormInvalid()"
      [attr.data-qa]="'saveSubTenantButton'"
      (click)="submit()"
    >
      {{ mode === 'edit' ? ('common.update' | transloco) : ('common.add' | transloco) }}
    </jui-button>
  </div>
</div>
