import { Component, OnInit, ViewChild } from '@angular/core';
import { SubTenant, TenantSettingType } from '../domain/types';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserManagementLayoutComponent } from '../user-management-layout.component';
import { UserManagementService } from '../user-management.service';
import { ApiService } from '../../../core/api.service';
import { SubTenantsService } from './sub-tenants.service';
import { SubTenantModalComponent } from './sub-tenant-modal.component';
import { CustomLookupService } from '../../../core/custom-lookup.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DropDownElement } from '../../../shared/form-components/multiple-selection-dropdown.component';
import { UserService } from '../../../auth/services/user.service';
import { AuthenticationService } from '../../../auth/services/authentication.service';

@Component({
  selector: 'app-sub-tenants',
  templateUrl: './sub-tenants.component.html',
  styleUrls: ['./sub-tenants.component.scss'],
  providers: [
    {
      provide: UserManagementService,
      useClass: SubTenantsService
    }
  ]
})
export class SubTenantsComponent extends UserManagementLayoutComponent implements OnInit {
  data: SubTenant[];
  private tempData: SubTenant[] = [];
  public rows: SubTenant[] = [];
  public tenantList: DropDownElement[] = [];
  public rowLimit = 25;

  @ViewChild(DatatableComponent) subTenantTable: DatatableComponent;

  constructor(
    protected userManagementService: UserManagementService<SubTenant>,
    protected modalService: NgbModal,
    protected spinnerService: NgxSpinnerService,
    protected apiService: ApiService,
    protected customLookupService: CustomLookupService,
    protected auth: AuthenticationService,
    private userService: UserService
  ) {
    super(userManagementService, modalService, spinnerService, customLookupService, auth);
  }

  async ngOnInit() {
    await this.spinnerService.show(this.spinnerName);
    await this.getData();
    this.refreshDataTable();
  }

  refreshDataTable() {
    this.tempData = [...this.data];
    this.rows = this.data;
    this.buildFilterLists();
  }

  buildFilterLists() {
    const tempTenantList = this.data.filter(d => d.srcTenant).map(d => d.srcTenant);
    this.tenantList = [...new Set(tempTenantList)].map(d => {
      return { id: d };
    });
  }

  resetPage() {
    if (this.subTenantTable) {
      this.subTenantTable.offset = 0;
    }
  }

  updateFilterFromSelect(value, type) {
    if (value && value.length) {
      this.rows = this.tempData.filter(function (d) {
        if (type === 'tenant' && d.srcTenant) {
          return value.includes(d.srcTenant);
        }
      });
    } else {
      this.rows = [...this.tempData];
    }
    this.resetPage();
  }

  updateFilter(event, type) {
    const val = event.target.value.toLowerCase();
    if (val) {
      this.rows = this.tempData.filter(function (d) {
        if (type === 'name' && d.configuration.name) {
          return d.configuration.name.toLowerCase().indexOf(val) !== -1 || !val;
        } else if (type === 'contact' && d.configuration.tenantConfiguration.installerContact) {
          return d.configuration.tenantConfiguration.installerContact.toLowerCase().indexOf(val) !== -1 || !val;
        } else if (type === 'email' && d.configuration.tenantConfiguration.installerEmail) {
          return d.configuration.tenantConfiguration.installerEmail.toLowerCase().indexOf(val) !== -1 || !val;
        }
      });
    } else {
      this.rows = [...this.tempData];
    }
    this.resetPage();
  }

  async toggleEnabledSubTenant(subTenant: SubTenant) {
    subTenant.enabled = !subTenant.enabled;
    await this.updateSubTenant(subTenant);
  }

  private async updateSubTenant(subTenant: SubTenant) {
    await this.userManagementService.updateTenantSetting(TenantSettingType.SUB_TENANT, subTenant);
  }

  openSubTenantModal(subTenant?: SubTenant) {
    const modalRef = this.modalService.open(SubTenantModalComponent);
    if (subTenant) {
      modalRef.componentInstance.subTenant = subTenant;
    }
    modalRef.componentInstance.mode = subTenant ? 'edit' : 'add';
    modalRef.result
      .then(async (resultSubTenant: SubTenant) => {
        await this.updateSubTenant(resultSubTenant);
        await this.userManagementService.subTenantUpdated(resultSubTenant);
        await Promise.all([this.getData(true), this.userService.updateUser()]);
        this.refreshDataTable();
      })
      .catch(() => {});
  }
}
