import { Injectable } from '@angular/core';
import { AlertModalComponent } from './alert-modal.component';
import { TranslocoService } from '@ngneat/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private modalService: NgbModal,
    private translocoService: TranslocoService,
    private spinnerService: NgxSpinnerService
  ) {}

  async alert(title: string, message: string, okLabel = 'common.ok', showCancel = false) {
    await this.spinnerService.hide('autoAssignmentSpinner');
    const modalRef = this.modalService.open(AlertModalComponent);
    const instance = modalRef.componentInstance as AlertModalComponent;
    instance.title = await this.translocoService.translate(title);
    instance.messages = [await this.translocoService.translate(message)];
    instance.okLabel = await this.translocoService.translate(okLabel);
    instance.showCancel = showCancel;
    return await modalRef.result;
  }
}
