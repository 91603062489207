import { Address, AddressMapping, PostCodeLookupProvider } from '../../../types';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiKeys } from '../../../domain/ApiKeys';
import { AddressMapperService } from '../../service/address-mapper.service';
import { PostcoderAddress } from './types';

export abstract class AbstractPostcoderPostCodeLookupProvider implements PostCodeLookupProvider {
  name = 'postcoder';
  addressEndpoint = 'https://ws.postcoder.com/pcw';
  endpointParams = { lines: 3, geo: true };
  match = '^.*$';

  addressMappings: AddressMapping[] = [
    {
      from: 'number',
      to: 'line1'
    },
    {
      from: 'organisation',
      to: 'line1'
    },
    {
      from: 'addressline1',
      to: 'line1'
    },
    {
      from: 'street',
      to: 'line2'
    },
    {
      from: 'addressline2',
      to: 'line2'
    },
    {
      from: 'dependentlocality',
      to: 'locality'
    },
    {
      from: 'buildinggroupname',
      to: 'locality'
    },
    {
      from: 'posttown',
      to: 'town'
    },
    {
      from: 'postcode',
      to: 'postCode'
    }
  ];
  apiKey: { name: string; value: string | null } = {
    name: 'api-key',
    value: null
  };

  protected constructor(
    protected countryCode: string,
    protected httpClient: HttpClient,
    protected addressMapperService = new AddressMapperService()
  ) {
    this.apiKey.value = ApiKeys[countryCode] ?? null;
  }

  async getAddresses(addressCode: string): Promise<Address[]> {
    const postcoderAddresses = await firstValueFrom(this.getPostcoderAddresses(addressCode));

    const transformedAddresses = this.transformAddresses(postcoderAddresses);

    return this.addressMapperService.applyMaps(transformedAddresses, this.addressMappings);
  }

  private getPostcoderAddresses(addressCode: string): Observable<PostcoderAddress[]> {
    return this.httpClient
      .get(
        `${this.addressEndpoint}/${this.apiKey.value}/address/${this.countryCode}/${encodeURIComponent(addressCode)}`,
        {
          headers: {
            skip: 'true'
          },
          params: {
            ...(this.endpointParams || {})
          }
        }
      )
      .pipe(
        map((response: PostcoderAddress[]) => {
          return response;
        }),
        catchError(() => of([]))
      );
  }

  private transformAddresses(postcoderAddresses: PostcoderAddress[]): Partial<PostcoderAddress>[] {
    switch (this.countryCode.toLowerCase()) {
      case 'de':
      case 'nl':
        return postcoderAddresses.map(postcoderAddress => {
          if (postcoderAddress.addressline2 === `${postcoderAddress.postcode} ${postcoderAddress.posttown}`) {
            delete postcoderAddress['addressline2'];
            delete postcoderAddress['street'];
          }
          if (postcoderAddress.dependentlocality === postcoderAddress.posttown) {
            delete postcoderAddress['dependentlocality'];
          }
          return postcoderAddress;
        });
      default:
        return postcoderAddresses;
    }
  }

  formatAddressCode(postcode: string): string {
    return postcode;
  }
}
