import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpGateway {
  constructor(private httpClient: HttpClient) {}

  public async get(path: string, params, headers?) {
    const parseBody = params.parseBody ?? true;
    delete params.parseBody;

    return await firstValueFrom(
      this.httpClient
        .get(path, {
          headers: { ...headers },
          params
        })
        .pipe(
          map((response: HttpResponse<string>) => {
            if (parseBody) {
              return JSON.parse(response.body);
            }
            return response;
          })
        ),
      {
        defaultValue: []
      }
    );
  }

  public async getStaticResponse(path: string, headers?) {
    return await firstValueFrom(
      this.httpClient
        .get(path, {
          headers: { ...headers },
          responseType: 'text' as 'json',
          observe: 'response',
        })
        .pipe(
          map((response: HttpResponse<string>) => {
            return response;
          })
        )
    );
  }

  public async getBlobAsPlainText(path: string, headers?) {
    return await firstValueFrom(
      this.httpClient
        .get(path, {
          headers: { ...headers },
          responseType: 'blob' as 'json',
          observe: 'response'
        })
        .pipe(
          map((response: HttpResponse<string>) => {
            return response;
          })
        )
    );
  }

  public async post(path: string, params, headers) {
    const options = { headers };
    return await firstValueFrom(this.httpClient.post(path, params, options).pipe(map((response: any) => response)));
  }

  public async put(path: string, params, headers) {
    const options = { headers };
    return await firstValueFrom(this.httpClient.put(path, params, options).pipe(map((response: any) => response)));
  }

  public async delete(path: string, headers) {
    const options = { headers };
    return await firstValueFrom(this.httpClient.delete(path, options).pipe(map((response: any) => response)));
  }
}
