import { environment } from '../../../environments/environment';
import { AlertModalComponent } from '../../shared/modals/alert-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserManagementService, UserManagementType } from './user-management.service';
import { CustomLookupService } from '../../core/custom-lookup.service';
import { AuthenticationService } from '../../auth/services/authentication.service';

export class UserManagementLayoutComponent {
  data: UserManagementType[];
  spinnerName = 'userManagementData';

  constructor(
    protected lookupService: UserManagementService<UserManagementType>,
    protected modalService: NgbModal,
    protected spinnerService: NgxSpinnerService,
    protected customLookupService: CustomLookupService,
    protected authenticationService: AuthenticationService
  ) {}

  async getData(force = false) {
    try {
      this.data = await this.lookupService.lookup(force);
    } catch (error) {
      await this.spinnerService.hide('userManagementData');
      if (this.authenticationService.isUserSignedIn) {
        this.showError(error);
      }
    } finally {
      await this.spinnerService.hide('userManagementData');
    }
  }

  get canGoNext() {
    return this.lookupService.canGoNext;
  }

  get canGoPrevious() {
    return this.lookupService.canGoPrevious;
  }

  async next() {
    this.data = await this.lookupService.next();
  }

  async previous() {
    this.data = await this.lookupService.previous();
  }

  showError(error: any) {
    let errorMessage;
    if (error.hasOwnProperty('error')) {
      const errorObject = error.error;
      if (errorObject.hasOwnProperty('errorMessage')) {
        errorMessage = errorObject.errorMessage;
      } else if (errorObject.hasOwnProperty('error')) {
        errorMessage = errorObject.error;
      } else {
        errorMessage = JSON.stringify(errorObject);
      }
    } else {
      errorMessage = JSON.stringify(error);
    }
    if (!environment.production) {
      console.log(error);
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.title = 'Error';
    modalRef.componentInstance.messages = [errorMessage.replace(/\[\d+] ?/g, '')];
  }
}
