import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('newPassword').value;
    const confirmPassword: string = control.get('newPasswordConfirm').value;
    if (password !== confirmPassword) {
      return { mismatch: true };
    }
    return null;
  }

  static requiredNoEmptyWhitespace(control: AbstractControl): ValidationErrors {
    if(!control.value || control.value.trim() == '')
    {
      return {'required' : true };
    }
    return null;
  }
}

export const AUTH_REGEX_NUMBER = /\d/;
export const AUTH_REGEX_UPPER = /[A-Z]/;
export const AUTH_REGEX_LOWER = /[a-z]/;
export const AUTH_REGEX_SYMBOL = /[\^$*.\[\]{}()?\-"!@#%&\/\\,><':;|_~`]/;
// ^$*.[]{}()?-"!@#%&/\,><':;|_~`+=
export const AUTH_MIN_LEN = 9;
