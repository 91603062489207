import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Card } from '../domain/card';
import { CardActionBase } from '../domain/card-actions/card-action.base';
import { I18nKeys } from '../domain/i18n-keys';

@Component({
  selector: 'crds-card',
  template: `
    <div [ngSwitch]="card.type" [attr.data-qa]="'cardKey_' + card.key">
      <crds-carousel-form
        *ngSwitchCase="'carouselSingle'"
        [form]="formGroup"
        [card]="card"
        [i18ns]="i18ns"
      ></crds-carousel-form>
      <crds-carousel-multiple-form
        *ngSwitchCase="'carousel'"
        [form]="formGroup"
        [card]="card"
        [i18ns]="i18ns"
      ></crds-carousel-multiple-form>
      <crds-image-capture-form
        *ngSwitchCase="'imageCapture'"
        [form]="formGroup"
        [card]="card"
        [i18ns]="i18ns"
      ></crds-image-capture-form>
      <crds-ocr-form *ngSwitchCase="'ocr'" [form]="formGroup" [card]="card" [i18ns]="i18ns"></crds-ocr-form>
      <crds-address-form *ngSwitchCase="'address'" [form]="formGroup" [card]="card" [i18ns]="i18ns"></crds-address-form>
      <crds-information-form *ngSwitchCase="'info'" [form]="form" [card]="card"></crds-information-form>
      <crds-consent-form
        *ngSwitchCase="'consent'"
        [form]="form"
        [formKey]="card.key"
        [card]="card"
        [i18ns]="i18ns"
      ></crds-consent-form>
      <crds-default-form
        *ngSwitchDefault
        [form]="form"
        [formKey]="card.key"
        [card]="card"
        [i18ns]="i18ns"
      ></crds-default-form>
    </div>
  `,
  styleUrls: ['../sass/relay.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnChanges {
  @Input() card: Card<CardActionBase>;
  @Input() form: UntypedFormGroup;
  @Input() i18ns: I18nKeys;

  formGroup: UntypedFormGroup;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.formGroup = this.form.get(this.card.key) as UntypedFormGroup;
  }
}
