<div class="modal-wrapper">
  <ngx-spinner
    bdColor="var(--jds-theme-spinner-bd-rgba)"
    size="small"
    type="line-scale"
    name="modalImageSpinner"
    [attr.data-qa]="'spinner'"
  ></ngx-spinner>
  <div class="modal-header-wrapper">
    <div class="modal-header">
      <div>{{ currentImageKey | camelToReadable }}</div>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="gallery-navigation">
      <i class="material-icons" (click)="galleryLeft()" title="{{ 'common.previous' | transloco }}"
        >keyboard_arrow_left</i
      >
      <i class="material-icons" (click)="galleryRight()" title="{{ 'common.next' | transloco }}"
        >keyboard_arrow_right</i
      >
    </div>
    <div style="width: 100%">
      <pinch-zoom backgroundColor="transparent">
        <crds-http-image [src]="getSrc()" [downloadUrl]="getSrc()" imageClass="card-img-bottom"></crds-http-image>
      </pinch-zoom>
    </div>
  </div>
</div>
