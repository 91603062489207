import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FormErrorComponent } from './form-error/form-error.component';
import { NgSelectDropdownComponent } from './form-components/ng-select-dropdown.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { MultipleSelectionDropdownComponent } from './form-components/multiple-selection-dropdown.component';
import { AttachmentsHelper } from './attachments.helper';
import { ProgressIndicatorModule } from './progress-indicator/progress-indicator.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslocoModule } from '@ngneat/transloco';
import { ImageUploadComponent } from './form-components/image-upload/image-upload.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { PIPES } from './pipes';

@NgModule({
  declarations: [
    FormErrorComponent,
    MultipleSelectionDropdownComponent,
    NgSelectDropdownComponent,
    ProgressBarComponent,
    ImageUploadComponent,
    ...PIPES
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(51, 51, 51, 0.8)'
    }),
    NgSelectModule,
    NgOptionHighlightModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    ProgressIndicatorModule,
    TranslocoModule,
    CoreComponentsAngularModule,
    JumptechCardsLibModule
  ],
  exports: [
    NgSelectDropdownComponent,
    CommonModule,
    FormErrorComponent,
    FormsModule,
    MultipleSelectionDropdownComponent,
    NgbModule,
    NgSelectModule,
    NgxIntlTelInputModule,
    NgxLoadingModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    ProgressBarComponent,
    ReactiveFormsModule,
    ProgressIndicatorModule,
    ImageUploadComponent,
    ...PIPES
  ],
  providers: [AttachmentsHelper]
})
export class SharedModule {}
