import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { updateProjectAttachments } from './edit-layout-questions.component';
import { BaseMultiFieldGroupComponent } from './base-multi-field-group.component';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: `app-multi-text-with-attachment`,
  template: `
    <div *ngIf="show">
      <div class="form-label">
        <strong>{{ question.label }}{{ question.required ? ' *' : '' }}</strong>
      </div>

      <form [formGroup]="group.form" *ngFor="let group of groups">
        <div class="card mb-3">
          <div class="card-header text-right">
            <i (click)="deleteGroup(group)" class="material-icons btn-sm-delete">delete_forever</i>
          </div>

          <div class="card-body">
            <div *ngFor="let field of fields" class="row mb-1">
              <label class="col-5 form-label" [for]="field.name + '_' + group.id" *ngIf="field.name !== 'chargerSpec'">
                {{ field.label }} ({{ group.id + 1 }}): *
              </label>
              <input
                class="col-7 form-control"
                [id]="field.name + '_' + group.id"
                [formControlName]="field.name + '_' + group.id"
                [hidden]="field.name === 'chargerSpec'"
                (change)="updateQuestionValue($event, field.name + '_' + group.id)"
              />
            </div>

            <app-attachment-question
              [form]="group.form"
              [question]="toAttachmentQuestion(fields[3].question, group.id)"
              [project]="project"
              [notify]="true"
              [id]="fields[3].name + '_' + group.id"
              (valueUpdated)="updateQuestionValue($event, fields[3].name + '_' + group.id)"
            ></app-attachment-question>
          </div>
        </div>
      </form>

      <div *ngIf="groups.length < limit" class="add-text" (click)="addQuestion()">Add...</div>
    </div>
  `,
  styles: [
    `
      .add-text {
        cursor: pointer;
      }

      .btn-sm-add > i {
        padding-top: 0.25rem;
        font-size: var(--jds-theme-icon-fs);
        color: var(--jds-theme-color-primary-contrast-color);
      }

      .btn-sm-delete {
        cursor: pointer;
      }

      hr {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
      }

      .row {
        background-color: var(--jds-theme-color-bg-surface) !important;
      }

      .card-header {
        padding: 0.5rem !important;
      }
    `
  ]
})
export class MultiTextWithAttachment extends BaseMultiFieldGroupComponent implements OnInit {
  ngOnInit() {
    this.fields = this.question?.config?.fields ?? [];
    this.addQuestion();
  }

  updateQuestionValue($event: any, field: any) {
    const fieldIndex = field.charAt(field.length - 1);
    const existingControl = this.form.get(field);
    if ($event && $event.target) {
      const newValue = $event && $event.target ? $event.target['value'] : $event;
      if (existingControl) {
        existingControl.patchValue(newValue);
      } else {
        this.form.addControl(field, new UntypedFormControl(newValue, [Validators.required]));
      }
    } else {
      updateProjectAttachments($event, this.project, field);
      if (existingControl) {
        existingControl.patchValue(true);
      } else {
        this.form.addControl(field, new UntypedFormControl(true, [Validators.required]));
      }
    }
    this.validate(parseInt(fieldIndex));
  }

  validate(fieldIndex: number) {
    const requiredQuestion = this.question.required ?? false;
    let isOneGroupValid = true;
    for (const field of this.fields) {
      if (requiredQuestion && (!this.form.get(`${field.name}_0`) || this.form.get(`${field.name}_0`)?.invalid)) {
        this.form.get(this.question.key).setValue(null);
        isOneGroupValid = false;
      }
    }

    this.form.get(this.question.key).setValue(isOneGroupValid ? true : null);
    let hasFieldSet = false;
    for (const field of this.fields) {
      if (this.form.get(`${field.name}_${fieldIndex}`)) {
        hasFieldSet = true;
      }
    }
    for (const field of this.fields) {
      if (
        hasFieldSet &&
        (!this.form.get(`${field.name}_${fieldIndex}`) || this.form.get(`${field.name}_${fieldIndex}`)?.invalid)
      ) {
        this.form.get(this.question.key).setValue(null);
      }
    }
  }

  toAttachmentQuestion(question: any, i: number) {
    const newQuestion = Object.assign({}, question);
    if (/_\d+$/g.test(newQuestion.key)) {
      return newQuestion;
    }
    newQuestion.key = `${newQuestion.key}_${i}`;
    return newQuestion;
  }
}
