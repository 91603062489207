import { Address } from '../../../core/domain/address';
import { IfDefinition } from '../../../core/utils/filter';
import { QualifiedSupervisorConfig } from '../../../../../../../libs/domain/src/lib/qualified-supervisor';

export interface SubTenant {
  id: string;
  type: string;
  tenant: string;
  configuration: SubTenantConfiguration<SubTenantConfigurationType>;
  enabled: boolean;
  srcTenant?: string;
  reference?: string;
}

export enum SubTenantConfigurationType {
  EVHS = 'EVHS'
}

export interface SubTenantConfiguration<T> {
  name: string;
  type: T;
  tenantConfiguration: SubTenantTenantConfiguration;
  pathwayConfiguration: SubTenantPathwayConfiguration;
  relayConfiguration: SubTenantRelayConfiguration;
}

export interface SubTenantTenantConfiguration {
  address: Address;
  installer?: string;
  registeredCompanyName?: string;
  installerContact?: string;
  installerEmail?: string;
  installerEvhs?: string;
  installerAccreditationBody?: string;
  installerPhone?: string;
  installerWebsite?: string;
  smsSenderId?: string;
  smsMessage?: string;
  outgoingCustomerEmail?: string;
  timezone?: string;
  locale?: string;
  qualifiedSupervisorConfig?: QualifiedSupervisorConfig;
}

export interface SubTenantPathwayConfiguration {
  logo?: string;
  styles?: string;
  font?: string;
}

export interface SubTenantRelayConfiguration {
  smsMessage?: string;
  customerLogo: string;
  customer?: string;
}

export interface Team {
  id: string;
  name: string;
  roles: string[];
  users: string[];
  enabled: boolean;
  subTenants?: string[];
}

export interface Role {
  id: string;
  name: string;
  paths?: any[];
  features?: Feature[];
}

export type ProjectFilter = { [key: string]: { [operator: string]: string } };

export interface Settings {
  features: SettingsFeature[];
  data: SettingsData[];
}

export interface SettingsFeature {
  id: string;
  name: string;
  enabled: boolean;
  type: string;
  path?: string;
  data?: any[];
  filter?: ProjectFilter[];
}

export interface SettingsData {
  id: string;
  name: string;
  data: any[];
}

export interface Feature {
  id: string;
  name: string;
  enabled: boolean;
  type: string;
  path: string;
  filter?: ProjectFilter[];
}

export interface DataSharing {
  id: string;
  name: string;
  enabled: boolean;
  type: string;
  data?: DelegationShare[];
  parameters?: {
    [delegationChain: string]: {
      [value: string]: string;
    };
  };
}

export interface DelegationShare {
  projectType: string;
  tenant: string;
  subTenants?: DelegationShareSubTenant[];
  showIf?: IfDefinition;
  showOnCreation?: boolean;
}

export interface ProjectAssignment {
  id: string;
  name: string;
  enabled: boolean;
  type: string;
  label: string;
  data?: ProjectAssignmentItem[];
}

export interface ProjectAssignmentItem {
  projectType: string;
  label?: string;
  teams: ProjectAssignmentTeam[];
  showOnCreation?: boolean;
}

export interface ProjectAssignmentTeam {
  id: string;
  name: string;
}

export interface DelegationShareSubTenant {
  id: string;
  name: string;
}

export interface AssignmentTarget {
  delegate: string;
  subTenant: { id: string; name: string };
}

export enum TenantSettingType {
  USER = 'user',
  TEAM = 'team',
  ROLE = 'role',
  DATA_SHARING = 'dataSharing',
  PROJECT_ASSIGNMENT = 'projectAssignment',
  DEFAULT_SETTINGS = 'settings',
  SUB_TENANT = 'subTenant',
  EMAIL_TEMPLATE = 'emailTemplate',
  LIST = 'list'
}

// TODO: this is not (yet) a fully comprehensive list of project statuses; add statuses as needed.
export enum ProjectStatus {
  INSTALLED = 'INSTALLED',
  SCHEDULED = 'SCHEDULED',
  ARCHIVED = 'ARCHIVED'
}
