<div class="linked-projects" *ngIf="vm">
  <div class="loader-spacer" *ngIf="vm.showMainSpinner" [attr.data-qa]="vm.qaMainSpinner">
    <jui-skeleton-loader width="50px"></jui-skeleton-loader>
    <jui-skeleton-loader height="30px"></jui-skeleton-loader>
  </div>

  <div class="linked-projects__search" *ngIf="vm.showGroupList" [formGroup]="vm.form">
    <span
      class="linked-projects__remaining"
      [class.linked-projects__remaining--max]="isLimitReached"
      *ngIf="showRemaining"
    >
      {{ remainingText }}
    </span>
    <label class="linked-projects__lbl">{{ vm.groupListLabel }}</label>

    <ng-select
      [attr.data-qa]="vm.qaGroupSelectControl"
      appendTo="app-linked-projects"
      class="linked-projects__select"
      bindLabel="displayName"
      [placeholder]="vm.searchPlaceholder"
      [items]="vm.groupList"
      [addTag]="uiCanCreate ? addGroupFn : false"
      (search)="search($event)"
      (change)="linkToGroup($event)"
      [loading]="vm.isCreatingGroup"
      [loadingText]="vm.createNewLoadingText"
      (keyup)="updateSearchTerm($event)"
      [clearable]="false"
      [inputAttrs]="{ maxlength: uiMaxGroupLen }"
      formControlName="findOrCreate"
    >
      <ng-template ng-tag-tmp let-uiSearch="searchTerm">
        <span class="linked-projects__create">{{ vm.addNewGroupText }}</span>
        <span class="linked-projects__term">"{{ uiSearch }}"</span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index" let-uiSearch="searchTerm">
        <span class="linked-projects__opt">
          <ng-container *ngIf="item.isLongGroupName; else standardOption">
            <span
              [attr.data-qa]="'linked-projects__opt'"
              [ngbTooltip]="item.displayName"
              container="body"
              tooltipClass="jt-tooltip--secondary-darker"
            >
              {{ item.displayNameShort }}
              <span class="linked-projects__opt-message" *ngIf="item.deleteInProgress">
                {{ vm.deleteInProgressText }}
              </span>
            </span>
          </ng-container>

          <ng-template #standardOption>
            <span [attr.data-qa]="'linked-projects__opt'">
              {{ item.displayName }}
              <span class="linked-projects__opt-message" *ngIf="item.deleteInProgress">
                {{ vm.deleteInProgressText }}
              </span>
            </span>
          </ng-template>

          <jui-icon
            [attr.data-qa]="item.qaGroupDeleteButton"
            *ngIf="item.canDelete"
            [ngbTooltip]="vm.deleteGroupTooltip"
            [disabled]="item.deleteInProgress"
            class="linked-projects__delete"
            tooltipClass="jt-tooltip--secondary-darker"
            container="body"
            isButton="true"
            color="danger"
            name="delete"
            size="xs"
            (click)="deleteGroup($event, item.id)"
          ></jui-icon>
        </span>
      </ng-template>
    </ng-select>
  </div>

  <app-linked-projects-summary
    [vm]="vm"
    (unlinkBtnClick)="unlinkProject($event.groupId, $event.projectId)"
    (linkClicked)="goToProject($event)"
  ></app-linked-projects-summary>
</div>
