import { BasePathwayLayout } from './base-pathway.layout';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Directive, Input, OnInit } from '@angular/core';

@Directive()
export abstract class BaseMultiFieldGroupComponent extends BasePathwayLayout implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() question: any;
  @Input() project!: {
    id: string;
    attachments: any[];
  };
  @Input() limit = 5;

  groups: {
    form: UntypedFormGroup;
    id: number;
  }[] = [];
  currentGroupIndexes = [];
  fields: any = null;

  ngOnInit() {
    this.fields = this.question?.config?.fields ?? [];
    this.limit = this.question?.config?.limit ?? 5;
    this.addQuestion();
  }

  abstract updateQuestionValue($event: any, field: any);

  abstract validate(fieldIndex: number);

  _findNextAvailableIndex() {
    for (let i = 0; i < this.limit; i++) {
      if (this.currentGroupIndexes.indexOf(i) === -1) {
        return i;
      }
    }
  }

  addQuestion() {
    const index = this._findNextAvailableIndex();
    const formN = new UntypedFormGroup({});
    for (const field of this.fields) {
      formN.addControl(`${field.name}_${index}`, new UntypedFormControl(null, [Validators.required]));
    }
    this.groups.push({
      id: index,
      form: formN
    });
    this.currentGroupIndexes.push(index);
    for (const field of this.fields) {
      this.validate(index);
    }
  }

  deleteGroup(group) {
    const indexToDelete = this.groups.findIndex(groupItem => groupItem.id === group.id);
    this.groups.splice(indexToDelete, 1);
    this.currentGroupIndexes.splice(indexToDelete, 1);

    for (const field of this.fields) {
      const controlName = `${field.name}_${indexToDelete}`;
      const existingControl = this.form.get(controlName);
      if (existingControl) {
        this.form.removeControl(controlName);
      }
    }
    for (let i = 0; i < this.limit; i++) {
      this.updateQuestionValue(null, `${i}`);
    }
  }

  get isInvalid() {
    return this.form.get(this.question.key).touched && this.form.get(this.question.key).invalid;
  }

  get alert() {
    return this.question.alert && this.question.alert[this.form.get(this.question.key).value];
  }
}
