import { Component, Input } from '@angular/core';
import { ImageAsset, ImageAssetType } from '../domain/card';

@Component({
  selector: 'crds-image-asset',
  template: `
    <div *ngIf="images && images.length">
      <div *ngFor="let image of images; index as i">
        <div *ngIf="image && image.type" [ngSwitch]="image.type">
          <i
            *ngSwitchCase="ImageAssetType.ICON"
            [class]="'material-icons ' + image.size"
            [id]="key + '_' + i + '_temp'"
          >
            {{ image.getSrc() }}
          </i>
          <crds-http-image
            *ngSwitchDefault
            class="asset-image"
            [src]="image.getSrc()"
            [alt]="key + ' ' + i + 1"
            [id]="key + '_' + i + '_temp'"
          ></crds-http-image>
        </div>
      </div>
    </div>
  `
})
export class ImageAssetComponent {
  @Input() images: ImageAsset[];
  @Input() key: string;

  ImageAssetType = ImageAssetType;
}
