export enum ImageAssetType {
  S3 = 'S3',
  LOCAL = 'LOCAL',
  ICON = 'ICON',
  S3_FORM = 'S3_FORM'
}

export interface ImageAsset {
  type: ImageAssetType;
  name?: string;
  bucket?: string;
  size?: string;
  formPath?: string;

  getSrc(): string;
}
