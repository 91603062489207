export enum AuthStateEvent {
  StartSignIn = 'startSignIn',
  StartForgotPassword = 'startForgotPassword',
  StartChangePassword = 'startChangePassword',
  ChangePasswordSubmit = 'changePasswordSubmit',
  ChangePasswordSubmitFail = 'changePasswordSubmitFail',
  SignInFail = 'signIn_failure',
  ForgotPassword = 'forgotPassword',
  ForgotPasswordFail = 'forgotPassword_failure',
  ForgotPasswordSubmit = 'forgotPasswordSubmit',
  ForgotPasswordSubmitFail = 'forgotPasswordSubmit_failure',
  CompleteNewPasswordFail = 'completeNewPassword_failure',
  NewPasswordRequired = 'newPasswordRequired',
  SmsMfaRequired = 'smsMfaRequired',
  SignIn = 'signIn',
  SignOut = 'signOut',
  TokenRefresh = 'tokenRefresh',
  TokenRefreshFail = 'tokenRefresh_failure',
  Configured = 'configured'
}

export enum AuthEventException {
  CodeMisMatch = 'CodeMismatchException',
  CodeExpired = 'ExpiredCodeException',
  PasswordResetRequired = 'PasswordResetRequiredException',
  InvalidPassword = 'InvalidPasswordException',
  LimitExceeded = 'LimitExceededException',
  NotAuthorized = 'NotAuthorizedException'
}

export enum AuthErrorMessage {
  Generic = 'auth.error.generic',
  PasswordResetSent = 'auth.error.passwordResetSent',
  CodeExpired = 'auth.error.codeExpired',
  CodeMismatch = 'auth.error.codeMisMatch',
  InvalidPassword = 'auth.error.invalidPassword',
  LimitExceeded = 'auth.error.limitExceeded'
}

export enum AuthUserChallenge {
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  SmsMfaRequired = 'SMS_MFA'
}

export enum AuthNotification {
  NewResetEmailSent = 'auth.notify.newResetEmailSent',
  NewMfaCodeSent = 'auth.notify.newMfaCodeSent'
}
