<div class="user-panel-container">
  <div *ngIf="user && isSignedIn" class="user-panel-wrapper">
    <app-add-project-button [full]="!isMobileScreen" class="user-panel-wrapper__btn"> </app-add-project-button>
    <div ngbDropdown placement="bottom-right" container="body" class="user-panel-menu d-inline-block">
      <button
        class="actions-dropdown actions-dropdown--secondary"
        ngbDropdownToggle
        [attr.data-qa]="'userPanelDropdownTrigger'"
      >
        <span class="user-panel-label" [attr.data-qa]="'userPanelLabel'">
          {{ user.label }}
          {{ isUserSubTenant ? ' (' + user.accessInfo.subTenants[0].configuration.name + ')' : '' }}
        </span>
        <i class="material-icons">keyboard_arrow_down</i>
      </button>
      <div ngbDropdownMenu aria-labelledby="actionDropdown">
        <button class="dropdown-item" (click)="changePassword()">{{ 'common.changePassword' | transloco }}</button>
        <ng-container *ngIf="isInstaller()">
          <hr class="dropdown-menu__hr" />
          <button class="dropdown-item" (click)="openAtomConfig()" title="{{ 'Buttons.atomApp.title' | transloco }}">
            {{ 'Buttons.atomApp.text' | transloco }}
          </button>
        </ng-container>

        <hr class="dropdown-menu__hr" />
        <div class="theme-chooser">
          <div class="theme-chooser__lbl">{{ 'theme.label' | transloco }}</div>
          <ng-select
            class="theme-chooser__select"
            [clearable]="false"
            [items]="themeOptions"
            [(ngModel)]="userThemePreference"
            (change)="switchTheme()"
          >
            <ng-template ng-option-tmp let-item="item">
              <div class="theme-chooser__opt">
                <span class="theme-chooser__opt-icon"
                  ><jui-icon [name]="item.icon" isButton="false" color="secondary" size="xs"></jui-icon></span
                ><span>{{ item.label }}</span>
              </div>
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
              <div class="theme-chooser__opt">
                <span class="theme-chooser__opt-icon"
                  ><jui-icon [name]="item.icon" isButton="false" color="secondary" size="xs"></jui-icon
                ></span>
                <span>{{ item.label }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>

        <hr class="dropdown-menu__hr" />
        <button class="dropdown-item" (click)="signOut()" [attr.data-qa]="'signOut'">
          {{ 'Buttons.signOut.text' | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>
