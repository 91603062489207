import { Directive, Input, ViewChild } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { ScrollService } from '../scroll.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ListQuestionComponent } from '../list-question.component';
import { ApiService } from '../api.service';
import { Datasource } from '../../domain/datasource';
import * as JmesPath from 'jmespath';
import { UntypedFormGroup } from '@angular/forms';

@Directive()
export class AbstractDropdownQuestionComponent extends ListQuestionComponent {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @ViewChild('dropDownInput') input;
  type: string;
  initialised = false;

  /**
   * The original options which may need to be used as a base
   * to filter if this question is subscribed to the value of another question.
   */
  currentFilter: any[] = [];

  constructor(
    protected scrollService: ScrollService,
    protected override apiService: ApiService,
    protected spinnerService: NgxSpinnerService
  ) {
    super(apiService);
  }

  override get value() {
    return this.form?.get(this.question.key)?.value || null;
  }

  override set value(value: any) {
    this.form?.get(this.question.key)?.patchValue(value);
  }

  public mapData(result: any[], datasource: Datasource) {
    const options: { key: string; value: string; type: string; order?: number }[] = [];
    const transformed = JmesPath.search(result, datasource.transform);
    transformed.forEach(item => {
      const initialText = item.text;
      const initialValue = item.value;
      const initialType = item.type || 'text';
      const order = item.order || null;
      if (Array.isArray(initialValue)) {
        initialValue.forEach(child => {
          options.push({
            key: `${initialText} - ${child}`,
            value: `${initialText} - ${child}`,
            type: initialType
          });
        });
      } else {
        options.push({
          key: initialValue,
          value: initialText,
          type: initialType,
          order: order
        });
      }
    });
    if (options.length) {
      this.question.options = options.sort((a, b) => {
        if (typeof a.order === 'number' && typeof b.order === 'number') {
          return a.order - b.order;
        }
        return a.key?.localeCompare(b.key) ?? 0;
      });

      // If no option is selected and there's no placeholder,
      // then select the first available option.
      if (!this.question.placeholder && !this.form?.get(this.question.key)?.value) {
        this.form?.get(this.question.key)?.setValue(options[0].key);
      }
    } else if (!this.question.options || !this.question.options.length) {
      this.question.options = [];
    }
  }
}
