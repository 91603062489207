import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberToArray' })
export class NumberToArrayPipe implements PipeTransform {
  transform(value): any {
    return Array(value)
      .fill(1)
      .map((x, i) => i);
  }
}
