export enum EvDomesticInstallationStatuses {
  CREATED = 'CREATED',
  QUOTE_REQUESTED = 'QUOTE REQUESTED',
  IN_REVIEW = 'IN REVIEW',
  DNO_SUBMITTED = 'DNO SUBMITTED',
  DNO_APPROVED = 'DNO APPROVED',
  DNO_REJECTED = 'DNO REJECTED',
  SCHEDULED = 'SCHEDULED',
  INSTALLED = 'INSTALLED',
  PAID = 'PAID',
  OLEV_GRANT_RECEIVED = 'OLEV GRANT RECEIVED'
}
