import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Card } from '../../domain/card';
import { CarouselMultipleAction } from '../../domain/card-actions/carousel-multiple.action';
import { ApiService } from '../../core/api.service';
import { Datasource } from '../../domain/datasource';
import * as JmesPath from 'jmespath';
import { AbstractCarouselComponent } from './abstract-carousel.component';
import { I18nKeys } from '../../domain/i18n-keys';

@Component({
  selector: 'crds-carousel-multiple-form',
  templateUrl: './carousel-multiple-form.component.html'
})
export class CarouselMultipleFormComponent extends AbstractCarouselComponent implements OnChanges, AfterViewInit {
  @Input() form: UntypedFormGroup;
  @Input() card: Card<CarouselMultipleAction>;
  @Input() i18ns: I18nKeys;
  public currentDynamicSlide: number;
  public carouselDynamicTile: NguCarouselConfig;
  @ViewChild('carouselMain') carouselMain: NguCarousel<any>;
  @ViewChild('carouselDynamic') carouselDynamic: NguCarousel<any>;

  dynamicSlides = {
    name: '',
    slides: []
  };
  get currentDynamicSlides() {
    return this.card.action.dynamicSlides.find(dS => dS.name === this.getCurrentMainSlideName()).slides;
  }

  constructor(protected apiService: ApiService) {
    super(apiService);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.action = this.card.action;

    if (this.action.datasource) {
      if (!this.action.datasource.transform) {
        this.action.datasource.transform =
          '[*].{ text: name, name: name, content: description, slides: children[*].{ text: name, name: name, content: description } }';
      }
      await this.getData(this.action.datasource);
    }

    this.setCurrentMainSlide(this.getCurrentMainSlideFromForm());
    this.setDynamicSlides(this.getCurrentMainSlideName());
    this.updateTargetField(this.getCurrentDynamicSlideFromForm());
    this.carouselMainTile = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      point: {
        visible: false
      },
      load: 5,
      touch: true,
      loop: false
    };
    this.carouselDynamicTile = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      point: {
        visible: true
      },
      load: 3,
      touch: true,
      loop: false
    };

    this.initialisedSubject.next(true);
  }

  ngAfterViewInit() {
    const self = this;
    this.initialisedSubject.asObservable().subscribe(initialised => {
      if (initialised) {
        setTimeout(() => {
          if (self.carouselMain) {
            self.carouselMain.moveTo(self.getCurrentMainSlideFromForm(), false);
          }
          if (self.carouselDynamic) {
            self.carouselDynamic.moveTo(self.getCurrentDynamicSlideFromForm(), false);
          }
        }, 10);
      }
    });
  }

  mapData(result: any[], datasource: Datasource) {
    const slides: [] = JmesPath.search(result, datasource.transform) || [];

    if (slides && slides.length) {
      this.card.action.dynamicSlides = slides;
      this.card.action.mainSlides = slides;
    } else {
      if (!this.card.action.dynamicSlides || !this.card.action.dynamicSlides.length) {
        this.card.action.dynamicSlides = [];
      }
      if (!this.card.action.mainSlides || !this.card.action.mainSlides.length) {
        this.card.action.mainSlides = [];
      }
    }
  }

  getSlides(result: any[]) {
    return result.map(item => {
      return {
        text: item.name,
        name: item.name,
        content: item.description,
        slides: item.children && item.children.length > 0 ? this.getSlides(item.children) : []
      };
    });
  }

  getCurrentDynamicSlideName() {
    const currentDynamicSlide = this.card.action.dynamicSlides.filter(
      slide => slide.name === this.getCurrentMainSlideName()
    )[0];
    return currentDynamicSlide.slides[this.currentDynamicSlide]
      ? currentDynamicSlide.slides[this.currentDynamicSlide].text
      : '';
  }

  setCurrentDynamicSlide(slide: number) {
    this.currentDynamicSlide = slide;
  }

  setCurrentDynamicSlideName(name: string) {
    this.dynamicSlides.name = name;
  }

  setDynamicSlides(mainSlideName: string) {
    this.setCurrentDynamicSlideName(mainSlideName);
    const currentDynamicSlide = this.card.action.dynamicSlides.filter(
      slide => slide.name === this.getCurrentMainSlideName()
    )[0];
    this.dynamicSlides.slides = Object.assign([], currentDynamicSlide.slides);
  }

  updateMainSlide(currentSlide: number) {
    if (currentSlide !== this.currentMainSlide) {
      this.carouselDynamic.reset();
      this.setCurrentMainSlide(currentSlide);
      this.setCurrentDynamicSlide(0);
      this.setDynamicSlides(this.getCurrentMainSlideName());
      this.updateTargetField(0);
    }
  }

  updateTargetField(currentSlide: number) {
    this.setCurrentDynamicSlide(currentSlide);
    setTimeout(() => {
      this.form
        .get(this.action.targetField)
        .patchValue(this.getCurrentMainSlideName() + ' - ' + this.getCurrentDynamicSlideName());
    });
  }

  private getCurrentDynamicSlideFromForm() {
    const currentFormValue = this.form.get(this.action.targetField).value;
    if (!currentFormValue) {
      return 0;
    }
    const currentDynamicSlideValue = currentFormValue.split(' - ')[1];
    const slideIndex = this.dynamicSlides.slides.findIndex(
      dynamicSlide => dynamicSlide.text === currentDynamicSlideValue
    );
    return slideIndex > -1 ? slideIndex : 0;
  }
}
