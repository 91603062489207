<div class="toaster-content" [ngClass]="iconClass">
  <div class="toaster-content__icon">
    <i class="material-icons toaster-icon">{{ iconValue }}</i>
  </div>
  <div class="toaster-content__message">
    <div [ngClass]="titleClass">{{ toast.title }}</div>
    <div [ngClass]="messageClass" [ngSwitch]="toast.bodyOutputType">
      <div *ngSwitchCase="bodyOutputType.Component" #componentBody></div>
      <div *ngSwitchCase="bodyOutputType.TrustedHtml" [innerHTML]="safeBodyHtml"></div>
      <div *ngSwitchCase="bodyOutputType.Default">{{ toast.body }}</div>
    </div>
  </div>
  <div class="toaster-content__close">
    <div class="toast-close-button" *ngIf="true" (click)="click($event, toast)" [innerHTML]="safeCloseHtml"></div>
  </div>
</div>
