import { Injectable } from '@angular/core';
import { Observable, Subject, Observer, debounceTime, share } from 'rxjs';
import { IClearWrapper, Toast } from './toast.model';
import { v4 as uuidv4 } from 'uuid';
import { LoggerService } from '../error/logger.service';
import { TranslocoService } from '@ngneat/transloco';

export type ToastResultConfig = { success?: Toast; failure?: Toast };

export const defaultSuccessToast: Toast = {
  type: 'info',
  title: 'Success',
  body: 'Operation successful'
};

export const defaultFailureToast: Toast = {
  type: 'error',
  title: 'Error',
  body: 'Unknown error occurred'
};

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  addToast: Observable<Toast>;
  private _addToast: Observer<Toast>;

  clearToasts: Observable<IClearWrapper>;
  private _clearToasts: Observer<IClearWrapper>;

  removeToast: Observable<IClearWrapper>;
  /** @internal */
  _removeToastSubject: Subject<IClearWrapper>;

  /**
   * Creates an instance of ToasterService.
   */
  constructor(private loggerService: LoggerService, private translocoService: TranslocoService) {
    this.addToast = new Observable<Toast>((observer: any) => (this._addToast = observer)).pipe(share());
    this.clearToasts = new Observable<IClearWrapper>((observer: any) => (this._clearToasts = observer)).pipe(share());
    this._removeToastSubject = new Subject<IClearWrapper>();
    this.removeToast = this._removeToastSubject.pipe(share());
  }

  /**
   * Synchronously create and show a new toast instance.
   *
   * @param {(string | Toast)} type The type of the toast, or a Toast object.
   * @param {string=} title The toast title.
   * @param {string=} body The toast body.
   * @returns {Toast}
   *          The newly created Toast instance with a randomly generated GUID Id.
   */
  pop(type: string | Toast, title?: string, body?: string): Toast {
    const toast = typeof type === 'string' ? { type: type, title: title, body: body } : type;

    if (!toast.toastId) {
      toast.toastId = uuidv4();
    }

    if (!this._addToast) {
      throw new Error('No Toaster Containers have been initialized to receive toasts.');
    }

    this._addToast.next(toast);
    return toast;
  }

  /**
   * Asynchronously create and show a new toast instance.
   *
   * @param {(string | Toast)} type The type of the toast, or a Toast object.
   * @param {string=} title The toast title.
   * @param {string=} body The toast body.
   * @returns {Observable<Toast>}
   *          A hot Observable that can be subscribed to in order to receive the Toast instance
   *          with a randomly generated GUID Id.
   */
  popAsync(type: string | Toast, title?: string, body?: string): Observable<Toast> {
    setTimeout(() => {
      this.pop(type, title, body);
    }, 0);

    return this.addToast;
  }

  /**
   * Clears a toast by toastId and/or toastContainerId.
   *
   * @param {string} toastId The toastId to clear.
   * @param {number=} toastContainerId
   *        The toastContainerId of the container to remove toasts from.
   */
  clear(toastId?: string, toastContainerId?: number) {
    const clearWrapper: IClearWrapper = {
      toastId: toastId,
      toastContainerId: toastContainerId
    };

    this._clearToasts.next(clearWrapper);
  }

  /**
   * Handle an Observable and pop a toast based on success/failure
   * @param {Observable} observable
   * @param {ToastResultConfig} toastResultConfig
   */
  observe(observable: Observable<unknown>, toastResultConfig: ToastResultConfig): Observable<unknown> {
    observable.pipe(debounceTime(1000)).subscribe(
      () => {
        this.pop(
          toastResultConfig?.success || {
            type: 'info',
            title: this.translocoService.translate('toasts.success.heading'),
            body: this.translocoService.translate('toasts.success.body')
          }
        );
      },
      error => {
        const failureToast = {
          ...{
            type: 'error',
            title: this.translocoService.translate('toasts.error.heading'),
            body: this.translocoService.translate('toasts.error.body')
          },
          ...(toastResultConfig?.failure || {})
        };
        failureToast.body = [failureToast.body || '', error.message || ''].join(' | ').trim();
        this.pop(failureToast);
        this.loggerService.error(failureToast.body);
      }
    );
    return observable;
  }
}
