
<ng-container *ngIf="vm.newStyle; else standardActions">
  <div class="static-button-wrapper">
    <ng-container *ngTemplateOutlet="actionRows"></ng-container>
  </div>
</ng-container>

<ng-template #standardActions>
  <div class="button-wrapper" *ngIf="vm">
    <ng-container *ngTemplateOutlet="actionRows"></ng-container>
  </div>
</ng-template>



<ng-template #actionRows>
  <div class="row" *ngIf="vm.showNav">
    <div *ngIf="vm.hasPrevious" class="col">
      <jui-button
        size="lg"
        title="{{ vm.previousLabel }}"
        expand
        color="secondary"
        (click)="previousCard()"
        [attr.data-qa]="'backButton'"
        [disableUserSelect]="vm.newStyle"
      >
        {{ vm.previousLabel }}
      </jui-button>
    </div>
    <div *ngIf="vm.hasNext" class="col">
      <jui-button
        size="lg"
        title="{{ vm.nextLabel }}"
        expand
        color="primary"
        [disabled]="vm.nextDisabled"
        (click)="nextCard()"
        [attr.data-qa]="'forwardButton'"
        [disableUserSelect]="vm.newStyle"
      >
        {{ vm.nextLabel }}
      </jui-button>
    </div>
    <div *ngIf="vm.hasSubmit" class="col">
      <jui-button
        size="lg"
        title="{{ vm.submitLabel }}"
        expand
        color="primary"
        [disabled]="vm.submitDisabled"
        (click)="submitCards()"
        [attr.data-qa]="'forwardButton'"
        [disableUserSelect]="vm.newStyle"
      >
        {{ vm.submitLabel }}
      </jui-button>
    </div>
  </div>
  <div class="row">
    <div *ngIf="vm.hasReset" class="col">
      <jui-button
        size="lg"
        title="Reset"
        expand
        color="secondary"
        (click)="resetCards()"
        [attr.data-qa]="'resetButton'"
        [disableUserSelect]="vm.newStyle"
      >
        Reset
      </jui-button>
    </div>
  </div>
</ng-template>
