import { Injectable } from '@angular/core';
import { DocumentPackRepository } from '../document-pack.repository';
import { IMessageDm, IMessageVm } from '../document-pack.model';

@Injectable()
export class DocumentPackMessagesPresenter {
  constructor(private repository: DocumentPackRepository) {}

  load(callback) {
    this.repository.getMessages((dataDm: IMessageDm) => {
      const viewModel: IMessageVm = {
        message: dataDm ? dataDm.message : null,
        showLoader: dataDm ? dataDm.showLoader : null,
        qaMessage: 'dmMessageText',
        qaSpinner: 'dmMessageSpinner'
      };
      callback(viewModel);
    });
  }

  cleanup() {
    this.repository.unsubscribe();
  }
}
