<div [formGroup]="signInForm">
  <label class="login-card__lbl" for="current-username">{{ 'auth.formFields.email.label' | transloco }}</label>
  <div class="login-card__row">
    <input
      #usernameInput
      class="form-control login-card__ctrl"
      type="email"
      placeholder="{{ 'auth.formFields.email.placeholder' | transloco }}"
      formControlName="username"
      autocomplete="current-username"
      id="current-username"
      data-qa="currentUserNameInput"
      (keyup.enter)="signIn()"
    />
  </div>
  <label class="login-card__lbl" for="current-password">{{ 'auth.formFields.password.label' | transloco }}</label>
  <div class="login-card__row">
    <input
      class="form-control login-card__ctrl login-card__ctrl--has-icon"
      [type]="showPassword ? 'text' : 'password'"
      placeholder="{{ 'auth.formFields.password.placeholder' | transloco }}"
      formControlName="password"
      autocomplete="current-password"
      id="current-password"
      data-qa="currentPasswordInput"
      (keyup.enter)="signIn()"
    />
    <button class="login-card__toggle" (click)="togglePassword()">
      <span *ngIf="!showPassword" class="material-icons login-card__toggle-icon"> visibility </span>
      <span *ngIf="showPassword" class="material-icons login-card__toggle-icon"> visibility_off </span>
    </button>
  </div>
  <div class="login-card__btn-row">
    <jui-button
      size="xl"
      expand
      data-qa="signInButton"
      [class.login-card__btn--disabled]="signInForm.invalid"
      [disabled]="signInForm.invalid || submitInProgress"
      (click)="signIn()"
      [loading]="submitInProgress"
    >
      <span *ngIf="!submitInProgress">{{ 'Buttons.signIn.text' | transloco }}</span>
      <span *ngIf="submitInProgress">{{ 'Buttons.signingIn.text' | transloco }}</span>
    </jui-button>
  </div>
  <jui-button size="sm" color="subtle" expand (click)="setView()">
    {{ 'Buttons.forgotPassword.text' | transloco }}
  </jui-button>
</div>
