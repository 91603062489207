import { HttpClient } from '@angular/common/http';
import { AbstractPostcoderPostCodeLookupProvider } from './AbstractPostcoderPostCodeLookupProvider';
import { PostalCodeMatchers } from '../../../domain/PostalCodeMatchers';

export class DefaultPostCoderPostCodeLookupProvider extends AbstractPostcoderPostCodeLookupProvider {
  constructor(protected override countryCode: string, protected override httpClient: HttpClient) {
    super(countryCode, httpClient);
    this.match = PostalCodeMatchers[countryCode] || PostalCodeMatchers['default'];
  }
}
