import { Component, Input, OnChanges } from '@angular/core';
import { Project } from '../../../../../core/domain/project';
import { IAssignmentVm } from '../../domain';

@Component({
  selector: 'app-assignment-label',
  templateUrl: './assignment-label.component.html',
  styleUrls: ['./assignment-label.component.scss']
})
export class AssignmentLabelComponent implements OnChanges {
  vm: IAssignmentVm;
  _project: Partial<Project>;

  @Input()
  set project(project: Partial<Project>) {
    this._project = project;
  }

  ngOnChanges() {
    const target = this._project?.data?.preAssignment?.target;
    if (target) {
      this.vm = {
        show: !!target,
        tenant: target?.subTenant ? target?.subTenant?.name : target?.delegate
      };
    }
  }
}
