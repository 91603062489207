import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { UserService } from '../auth/services/user.service';
import { filter } from 'rxjs/operators';
import * as appVersion from '../../app-version.json';
const versionFile = appVersion;
import { getTenantSubdomain } from '../core/utils/location.helper';

/**
 * object keys are environment names, value is array of tenants that should not be tracked in that environment
 */
const DO_NOT_TRACK_TENANTS = { dev: ['regression', 'test'], production: ['regression', 'test'] };

type Environment = typeof environment & { datadog?: DatadogConfig };

const envConfig = environment as Environment;

@Injectable({
  providedIn: 'root'
})
export class DatadogLoggerService {
  private loggingInitialized = false;
  private rumInitialized = false;
  private datadogLoggerConfig = {
    env: envConfig.name,
    forwardConsoleLogs: ['log', 'info', 'warn', 'error'] as const,
    forwardErrorsToLogs: true,
    sampleRate: envConfig?.datadog?.sampleRate,
    service: envConfig?.datadog?.service,
    site: envConfig?.datadog?.site,
    useSecureSessionCookie: true,
    version: versionFile.appVersion
  };

  private datadogRumConfig = {
    applicationId: envConfig.datadog?.applicationId,
    premiumSampleRate: envConfig.datadog?.replaySampleRate,
    sampleRate: envConfig.datadog?.sampleRate,
    trackInteractions: true,
    trackFrustrations: true,
    actionNameAttribute: envConfig.datadog?.actionNameAttribute ?? undefined,
    allowedTracingOrigins: [/https:\/\/api.*\.jumptech\.co\.uk/]
  };

  constructor(private userService: UserService) {
    const tenant = getTenantSubdomain();
    if (!this.allowLoggingForTenant(tenant)) {
      return;
    }
    this.initDataDogLogs(tenant);

    this.userService.userObservable.pipe(filter(user => user !== null)).subscribe(user => {
      const isJumptechUser = user.email?.endsWith('@jumptech.co.uk');
      const ddUser = {
        id: user.id,
        name: isJumptechUser ? user.label : '',
        email: isJumptechUser ? user.email : '',
        tenant: user.tenant,
        subTenantId: user.subTenant?.id,
        subTenantName: user.subTenant?.name,
        isAdmin: user.isAdmin,
        isSuperAdmin: user.isSuperAdmin
      };
      this.refreshDataDogGlobalContext(ddUser);

      this.initDataDogRum(ddUser);
      datadogLogs.logger.info('User refreshed', { user: ddUser });
    });
  }

  private isDatadogConfigured(env: Environment): env is Environment & { datadog: DatadogConfig } {
    return Object.prototype.hasOwnProperty.call(env, 'datadog') && envConfig?.datadog.applicationId !== undefined;
  }

  private allowLoggingForTenant(tenant: string): boolean {
    if (tenant === undefined) {
      return false;
    }
    if (Object.prototype.hasOwnProperty.call(DO_NOT_TRACK_TENANTS, envConfig.name)) {
      return !DO_NOT_TRACK_TENANTS[envConfig.name].includes(tenant);
    }
    return true;
  }

  private initDataDogLogs(tenant: string): void {
    if (!this.loggingInitialized && this.allowLoggingForTenant(tenant) && this.isDatadogConfigured(envConfig)) {
      datadogLogs.init({
        ...this.datadogLoggerConfig,
        clientToken: envConfig?.datadog?.clientTokenLogging
      });
      datadogLogs.setGlobalContextProperty('context.tenant', tenant);
      this.loggingInitialized = true;
    }
  }
  private refreshDataDogGlobalContext(user: UserContext): void {
    if (!this.loggingInitialized && this.allowLoggingForTenant(user.tenant) && this.isDatadogConfigured(envConfig)) {
      this.initDataDogLogs(user.tenant);
    }
    datadogLogs.setGlobalContextProperty('context.tenant', user.tenant);
    datadogLogs.setGlobalContextProperty('context.userId', user.id);
    datadogLogs.setGlobalContextProperty('context.userName', user.name);
    datadogLogs.setGlobalContextProperty('context.email', user.email);
    datadogLogs.setGlobalContextProperty('context.isAdmin', user.isAdmin);
    datadogLogs.setGlobalContextProperty('context.isSuperAdmin', user.isSuperAdmin);
  }

  private initDataDogRum(user: UserContext): void {
    if (!this.isDatadogConfigured(envConfig) || !this.allowLoggingForTenant(user.tenant)) {
      return;
    }
    if (!this.rumInitialized) {
      datadogRum.init({
        beforeSend: event => {
          event.context = { ...event.context, user: user, tenant: user.tenant };
        },
        ...this.datadogLoggerConfig,
        ...this.datadogRumConfig,
        clientToken: envConfig.datadog?.clientToken
      });
      this.rumInitialized = true;
    }
    datadogRum.setUser(user);

    if (envConfig.datadog.replaySampleRate > 0) {
      datadogRum.startSessionReplayRecording();
    }
  }
}

interface UserContext {
  id: string;
  name: string;
  email: string;
  tenant: string;
  subTenantId?: string;
  subTenantName: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  [key: string]: string | boolean | undefined;
}

interface DatadogConfig {
  applicationId: string;
  actionNameAttribute: string;
  clientToken: string;
  clientTokenLogging: string;
  env: string;
  service: string;
  replaySampleRate: number;
  sampleRate: number;
  site: string;
  [key: string]: string | number | boolean;
}
