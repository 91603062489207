import { Injectable } from '@angular/core';
import { PathwayApiEnvironment, BuildEnvironmentMap, ApiEnvironmentListItem } from './pathway-api-environment';

export const SESSION_STORAGE_KEY = 'environmentName';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  getCurrentApiEnvironment(): PathwayApiEnvironment {
    const defaultName = this.getBuildTimeEnvironment();
    // Look for an override in session storage, or default to build time environment
    const envName = sessionStorage.getItem(SESSION_STORAGE_KEY) || defaultName;
    return new PathwayApiEnvironment(BuildEnvironmentMap[envName]);
  }

  setApiEnvironment(env: string): void {
    sessionStorage.setItem(SESSION_STORAGE_KEY, env);
    location.reload();
  }

  // These are the selectable environments in the UI
  // which is not necessarily the same as all the available build time environments.
  getApiEnvironmentList(): ApiEnvironmentListItem[] {
    return [
      { id: 'local', label: 'local' },
      { id: 'preview', label: 'preview' },
      { id: 'dev', label: 'dev' },
      { id: 'staging', label: 'staging' }
    ];
  }

  // Read environment variables from browser window, set by the env.js files
  // These are copied in at build time, as before. The rest of the env is dynamically loaded at runtime.
  getBuildTimeEnvironment(): string {
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['__env'] || {};
    return browserWindowEnv.environmentName;
  }
}
