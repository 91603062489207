<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.form" [formGroup]="vm.form">
    <textarea
      [formControlName]="vm.key"
      [id]="vm.key"
      class="form-control"
      rows="7"
      [attr.data-qa]="vm.key + 'Input'"
      [placeholder]="vm?.label ?? undefined"
    ></textarea>
  </ng-container>
</ng-container>
