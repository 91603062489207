import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserService } from '../../auth/services/user.service';
import { User } from '../domain/user';
import { FeatureConfig } from './feature';
import { initialize, LDClient, LDContext } from 'launchdarkly-js-client-sdk';
import { anonymousLdUserContext, toLaunchDarklyUserContext } from './launch-darkly-user';
import { firstValueFrom, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private config: FeatureConfig = null;
  private ldClient?: LDClient;
  public mergedFlags;
  public featureFlag$ = new ReplaySubject();

  public constructor(private userService: UserService) {
    this.loadConfig().then(() => {
      this.userService.userObservable.pipe(filter(user => user !== null)).subscribe(async (user: User) => {
        const ldUserContext = toLaunchDarklyUserContext(user);
        await this.setUserContext(ldUserContext);
        this.mergeFeatureFlags();
      });
    });
  }

  public mergeFeatureFlags() {
    if (this.ldClient) {
      this.mergedFlags = { ...this.ldClient.allFlags(), ...this.config };
    } else {
      this.mergedFlags = { ...this.config };
    }
    // merge and notify LS and LD flag options
    this.featureFlag$.next(this.mergedFlags);
  }

  public mergeFeatureFlagsFromUpdate(update) {
    Object.keys(update).forEach(u => {
      if (this.mergedFlags && this.mergedFlags[u]) {
        this.mergedFlags[u] = update[u].current;
      }
    });
  }

  public async loadConfig(user?: LDContext): Promise<FeatureConfig> {
    try {
      const clientToken = environment.launchDarkly?.clientToken;
      if (clientToken) {
        const client = initialize(clientToken, user ?? anonymousLdUserContext());
        await client.waitForInitialization();
        this.ldClient = client;
        this.ldClient.on('change', (update: unknown) => {
          this.mergeFeatureFlagsFromUpdate(update);
        });
      } else {
        console.log('Local feature flags only');
      }
    } catch (err) {
      console.error('Failed to load LD client', err);
    }
    this.config = JSON.parse(localStorage.getItem('featureFlags')) ?? {};
    return this.config;
  }

  private async setUserContext(user: LDContext): Promise<void> {
    if (!this.ldClient) {
      await this.loadConfig();
    }
    await this.ldClient?.identify(user);
  }

  public async isFeatureEnabled(key: string): Promise<boolean> {
    if (!this.ldClient) {
      await this.loadConfig();
    }
    let isEnabled = false;

    if (!this.mergedFlags) {
      await firstValueFrom(this.featureFlag$);
    }

    Object.entries(this.mergedFlags).forEach((itm: [string, boolean]) => {
      if (itm[0] === key) {
        isEnabled = itm[1];
      }
    });
    return isEnabled;
  }
}

/*
*
*
*   This is a simple service that can be loaded when the app boots via APP_INITIALIZER
*   in future it might be good to have this call out to an API rather than fetch from local Storage?
*
*   This checks for and parses a localStorage key 'featureFlags'
*
*   The LS item example:
             { "schedule-beta": true }

*   This would allow the user to load the schedule V2 module
*   The key name should match a route data['feature'] in app.routing eg:
*
*            data: { title: 'pageTitle.schedule', feature: 'schedule-beta' }
*
*
*  */
