import { Injectable } from '@angular/core';
import { RelayParameters } from '../../domain/relay-parameters';
import { RelaySummary } from '../../domain/relay-summary';
import { RelayRepository } from '../../relay.repository';
import { FormViewModel } from './form.view.model';

@Injectable()
export class FormPresenter {
  constructor(private relayRepository: RelayRepository) {}

  load(callback): void {
    let vm: FormViewModel;
    this.relayRepository.getCurrentCard(relayCard => {
      vm = {
        newStyle: relayCard?.newStyle,
        useBranding: relayCard?.useBranding,
        i18nKeys: relayCard?.i18nKeys,
        customStyle: relayCard?.customStyle,
        customFont: relayCard?.customFont,
        readOnly: false,
        demo: false,
        state: 'inline',
        form: relayCard?.form,
        card: relayCard?.card,
        wrapperClass: '',
        showClose: false
      };
      this.relayRepository.getSummary((summary: RelaySummary) => {
        if (!summary) {
          return;
        }
        vm.total = summary.total;
        vm.step = summary.step;
        vm.complete = summary.step > summary.total;

        callback(vm);
      });
    });
  }

  setParameters(parameters: RelayParameters) {
    this.relayRepository.subscribeToParameters();
    this.relayRepository.setParameters(parameters);
  }

  cleanup() {
    this.relayRepository.unsubscribe();
  }
}
