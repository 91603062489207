/**
 * Builds the URL to the Atom setup page, given tenant, stage and language.
 * For backwards compatibility, if the language is empty/null
 * (might not be ever the case) defaults to 'en'.
 * @param tenant
 * @param stage
 * @param language
 */
export const atomSetupPageUrl = (tenant: string, stage: string, language: string): string => {
  if (!language) {
    language = 'en';
  }
  const filenameRoot = stage === 'production' ? tenant : `${tenant}-${stage}`;
  return `https://atomsetup.co.uk/${filenameRoot}.${language}.html`;
};
