import { Delegate } from '../core/domain/delegate';
import { DelegationShareSubTenant } from '../admin/user-management/domain/types';
import { SearchResult } from './search-result';
import { User } from '../core/domain/user';
import { DashboardFieldsHelper } from './main-dashboard/dashboard-fields.helper';
import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';

export interface ScrollOptions {
  scroll: string;
  scrollId?: string;
}

export interface KeyValue {
  key: string;
  value: unknown | unknown[];
  operator?: string;
}

export type SearchCriteria = KeyValue | string;

export interface SearchContextInterface {
  withPage(page: number);
  withPageSize(pageSize: number);
  withScroll(scrollTime: string, scrollId?: string);
  withSort(sort: string, dir: string);
  withDelegates(delegates: Delegate[]);

  withCriteria(criteria: KeyValue);
  withAggregation(field: string);
  withUrl(url: string);

  getSearchOptions(): any;
  getUrl(): string;

  handleError(error: any);
  postUpdate(translocoService: TranslocoService, result: any);

  getResults(): any;
  hasResults(): boolean;
  getAggregationCount(field: string, key: string): number | null;
}

@Injectable({
  providedIn: 'root'
})
export class SearchContext implements SearchContextInterface {
  private criteria: SearchCriteria[] = [];
  private aggregations: KeyValue[] = [];
  private delegates: Delegate[];
  private subTenants: DelegationShareSubTenant[];
  private url: string;

  private aggregationResults: any[] = [];
  private scrollOptions: ScrollOptions = null;
  private searchResults: SearchResult[] = [];
  private exportSearchResults: SearchResult[] = [];
  private zeroResults = false;

  page = 1;
  pageSize = 25;
  exportPage = 1;
  exportPageSize = 25;
  isExport = false;
  sort = 'created_on';
  sortDirection = 'desc';
  total = 0;
  showBoundary = false;
  loadingData = false;

  constructor() {}

  reset() {
    this.criteria = [];
    this.aggregations = [];
    return this;
  }

  withPage(page: number) {
    this.page = page;
    return this;
  }

  withPageSize(pageSize: number) {
    this.pageSize = pageSize;
    return this;
  }

  withExport(isExport: boolean) {
    this.isExport = isExport;
    return this;
  }

  withExportPage(page: number) {
    this.exportPage = page;
    return this;
  }

  withExportPageSize(pageSize: number) {
    this.exportPageSize = pageSize;
    return this;
  }

  withScroll(scrollTime: string, scrollId?: string) {
    this.scrollOptions = {
      scroll: scrollTime,
      scrollId: scrollId
    };
    return this;
  }

  withoutScroll() {
    this.scrollOptions = null;
    return this;
  }

  withSort(sort: string, dir: string) {
    this.sort = sort;
    this.sortDirection = dir;
    return this;
  }

  changeSort(sort: string) {
    if (sort != this.sort) {
      this.sort = sort;
      this.sortDirection = 'asc';
    } else {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
    }
    return this;
  }

  withDelegates(delegates: Delegate[]) {
    this.delegates = delegates;
    return this;
  }

  withSubtenants(subTenants: DelegationShareSubTenant[]) {
    this.subTenants = subTenants;
    return this;
  }

  withCriteria(criteria: SearchCriteria) {
    this.criteria.push(criteria);
    return this;
  }

  withAggregation(field: string) {
    this.aggregations.push({
      key: `${field}_aggregation`,
      value: field
    });
    return this;
  }

  withUrl(url: string) {
    this.url = url;
    return this;
  }

  getResults(): SearchResult[] {
    return this.searchResults;
  }

  getExportResults(): SearchResult[] {
    return this.exportSearchResults;
  }

  hasResults(): boolean {
    return this.searchResults.length > 0;
  }

  hasExportResults(): boolean {
    return this.exportSearchResults.length > 0;
  }

  getAggregationCount(field: string, key: string): number | null {
    if (!this.aggregationResults || this.aggregationResults.length == 0) {
      return null;
    }

    const foundAggregation = this.aggregationResults.find(aggregationResult => {
      return aggregationResult.name === `${field}_aggregation`;
    });

    if (foundAggregation) {
      const found = foundAggregation.value.find(item => item.key === key);
      if (found) {
        return found.doc_count;
      }
    }

    return 0;
  }

  getSearchOptions(): any {
    return {
      start: this.isExport
        ? ((this.exportPage - 1) * this.exportPageSize).toString()
        : ((this.page - 1) * this.pageSize).toString(),
      sort: this.sort,
      dir: this.sortDirection,
      max: this.isExport ? this.exportPageSize.toString() : this.pageSize.toString(),
      search: JSON.stringify(this.criteria),
      aggregations: JSON.stringify(this.aggregations),
      scrollOptions: JSON.stringify(this.scrollOptions)
    };
  }

  getUrl(): string {
    return this.url;
  }

  postUpdate(translocoService: TranslocoService, result: any, user?: User, states?: any[], isExport = false) {
    if (isExport) {
      this.exportSearchResults = this.toSearchResults(translocoService, result.results, user, states);
    } else {
      this.searchResults = this.toSearchResults(translocoService, result.results, user, states);
    }
    this.aggregationResults = result.aggregations;
    this.scrollOptions = isExport ? result.scrollOptions : null;
    this.total = result.total;
    this.showBoundary = this.total / this.pageSize > 8;
    this.zeroResults = this.searchResults && this.searchResults.length === 0;
    this.loadingData = false;
  }

  handleError(error: any) {
    this.loadingData = false;
  }

  toSearchResults(translocoService: TranslocoService, results: any[] = [], user, states): SearchResult[] {
    return results.map(r => new SearchResult(translocoService, this.criteria, r, user, states));
  }
}
