<!-- FORGOT PASSWORD FLOW INIT / EMAIL SENT -->
<div *ngIf="displaySplash">
  <div class="login-card__splash a-fade-in">
    <div class="login-card__splash-title">{{ 'auth.title.checkEmail' | transloco }}</div>
    <div class="login-card__splash-icon">
      <!-- todo - nicer success svg -->
      <span class="material-icons">mail_outline</span>
    </div>
    <div class="login-card__splash-msg">
      {{ 'auth.sentEmailMessage' | transloco }}:
      <span class="login-card__splash-msg&#45;&#45;accent">{{ emailAddress }}</span>
    </div>
    <div class="login-card__splash-msg">{{ 'auth.checkSpamMessage' | transloco }}</div>
    <jui-button color="subtle" expand (click)="resendCode()">
      {{ 'Buttons.forgotPasswordResend.text' | transloco }}
    </jui-button>
  </div>
</div>

<!-- FORGOT PASSWORD FLOW CONFIRM / RESET PASSWORD FLOW -->
<div class="revealed a-fade-in" [formGroup]="forgotPasswordSubmitForm" *ngIf="!displaySplash">
  <input type="hidden" data-qa="usernameHiddenInput" formControlName="username" autocomplete="off" />
  <input type="hidden" data-qa="codeHiddenInput" formControlName="code" autocomplete="off" />

  <label class="login-card__lbl" for="new-password">{{ 'auth.formFields.newPassword.label' | transloco }}</label>
  <div class="login-card__row" [class.login-card__row-slim]="forgotPasswordSubmitForm.controls['newPassword'].dirty">
    <input
      #newPasswordInput
      class="form-control login-card__ctrl login-card__ctrl--has-icon"
      [type]="showPassword ? 'text' : 'password'"
      placeholder="{{ 'auth.formFields.newPassword.placeholder' | transloco }}"
      formControlName="newPassword"
      autocomplete="new-password"
      id="new-password"
      data-qa="newPasswordInput"
    />
    <button class="login-card__toggle" (click)="togglePassword()">
      <span *ngIf="!showPassword" class="material-icons login-card__toggle-icon"> visibility </span>
      <span *ngIf="showPassword" class="material-icons login-card__toggle-icon"> visibility_off </span>
    </button>
  </div>

  <app-password-strength-checker [form]="forgotPasswordSubmitForm"></app-password-strength-checker>
  <label class="login-card__lbl" for="new-password-confirm">{{
    'auth.formFields.newPasswordConfirm.label' | transloco
  }}</label>
  <div class="login-card__row">
    <input
      class="form-control login-card__ctrl login-card__ctrl--has-icon"
      [type]="showPassword ? 'text' : 'password'"
      placeholder="{{ 'auth.formFields.newPasswordConfirm.placeholder' | transloco }}"
      formControlName="newPasswordConfirm"
      autocomplete="new-password"
      id="new-password-confirm"
      data-qa="newPasswordConfirmInput"
      (keyup.enter)="forgotPasswordSubmit()"
    />
    <button class="login-card__toggle" (click)="togglePassword()">
      <span *ngIf="!showPassword" class="material-icons login-card__toggle-icon"> visibility </span>
      <span *ngIf="showPassword" class="material-icons login-card__toggle-icon"> visibility_off </span>
    </button>
  </div>
  <div
    class="login-card__row"
    *ngIf="
      forgotPasswordSubmitForm.errors?.mismatch &&
      (forgotPasswordSubmitForm.controls['newPasswordConfirm'].dirty ||
        forgotPasswordSubmitForm.controls['newPassword'].touched)
    "
  >
    <div class="login-card__error a-fade-in-fast">
      {{ 'auth.error.passwordMisMatch' | transloco }}
    </div>
  </div>
  <div class="login-card__row">
    <jui-button
      size="xl"
      expand
      data-qa="forgotPasswordSubmitButton"
      [class.login-card__btn--disabled]="forgotPasswordSubmitForm.invalid"
      [disabled]="forgotPasswordSubmitForm.invalid || submitInProgress"
      (click)="forgotPasswordSubmit()"
      [loading]="submitInProgress"
    >
      <span *ngIf="!submitInProgress">{{ 'Buttons.passwordReset.text' | transloco }}</span>
      <span *ngIf="submitInProgress">{{ 'Buttons.passwordResetting.text' | transloco }}</span>
    </jui-button>
  </div>
  <jui-button size="sm" color="subtle" expand (click)="resendCode()">
    {{ 'Buttons.passwordResetResend.text' | transloco }}
  </jui-button>
</div>
