import { Injectable } from '@angular/core';
import { MyOrdersRepository } from '../my-orders.repository';
import {
  OrderPartStatus,
  OrderViewDm,
  OrderViewVm,
  ProductCategory,
  ResourceOrderStatus,
  OrderFulfilment
} from '../my-orders.model';

@Injectable()
export class MyOrdersViewPresenter {
  constructor(private repository: MyOrdersRepository) {}

  load(vmSubject$): void {
    this.repository.loadViewOrder((dataDm: OrderViewDm): void => {
      const viewModel: OrderViewVm = {
        // labels
        id: dataDm.id,
        messageAwaitingFulfilment: dataDm.i18n.message.orderNotFulfilled,
        labelSummary: `${dataDm.i18n.label.summary} | `,
        labelFulfilment: dataDm.i18n.label.fulfilment,
        labelShippingAddress: dataDm.i18n.label.shippingAddress,
        labelShippedDate: dataDm.i18n.label.shippedDate,
        labelPreferredShipmentDate: dataDm.i18n.label.preferredShipmentDate,
        labelHardware: dataDm.i18n.label.hardware,
        labelPackage: dataDm.i18n.label.package,
        labelFoC: `(${dataDm.i18n.label.freeOfCharge})`,

        labelOrderNumber: dataDm.i18n.label.orderNumber,
        labelOrderStatus: dataDm.i18n.label.orderStatus,
        labelItems: dataDm.i18n.label.items,
        labelCarrier: dataDm.i18n.label.carrier,
        labelTracking: dataDm.i18n.label.tracking,
        labelExternalError: dataDm.i18n.label.externalError,

        // buttons
        buttonCancelOrder: dataDm.i18n.button.cancelOrder,

        // summary
        summaryStatus: dataDm.summary.orderStatusDisplay,
        summaryAddress: dataDm.summary.displayAddress,
        summaryPreferredShipDate: dataDm.summary.requestedShippingDate,
        summaryDefaultPackage: dataDm.summary.items.find(itm => itm.category === ProductCategory.Installation)
          ?.productName,
        summaryHardwareOrdered: dataDm.summary.items
          .filter(itm => itm.category !== ProductCategory.Installation)
          .map(itm => itm.productName)
          .join(', \n'),
        showPackage: !!dataDm.summary.items.find(itm => itm.category === ProductCategory.Installation),
        isCancelled: dataDm.summary.orderStatus === ResourceOrderStatus.CANCELLED,

        // fulfilment
        hasFulfilmentParts: !!dataDm.fulfilments.length,
        canCancel:
          dataDm.fulfilments.length &&
          !!dataDm.fulfilments.every((f: OrderFulfilment) => f.isCancellable) &&
          dataDm.summary.orderStatus !== ResourceOrderStatus.CANCELLED &&
          dataDm.summary.orderStatus !== ResourceOrderStatus.CANCELLATION_REQUESTED,

        fulfilmentParts: dataDm.fulfilments.map((f: OrderFulfilment, i) => ({
          ...f,
          showPendingItems: f.fulfilmentOrderStatus === OrderPartStatus.PENDING_FULFILMENT || f.fulfilmentOrderStatus === OrderPartStatus.SHIPPING,
          shipments: [
            ...f.shipments.map(s => ({
              ...s,
              carrier: s.carrier
                ? s.carrier
                : f.fulfilmentOrderStatus === OrderPartStatus.PENDING_FULFILMENT
                ? OrderPartStatus.PENDING_FULFILMENT
                : null,
              trackingReference: s.trackingReference
                ? s.trackingReference
                : f.fulfilmentOrderStatus === OrderPartStatus.PENDING_FULFILMENT
                ? OrderPartStatus.PENDING_FULFILMENT
                : null,
              shippedDate: s.shippedDate
                ? s.shippedDate
                : f.fulfilmentOrderStatus === OrderPartStatus.PENDING_FULFILMENT
                ? OrderPartStatus.PENDING_FULFILMENT
                : null,
              items: s.items.filter(itm => itm.shippedQty),
              itemsDisplay: s.items
                .filter(itm => itm.shippedQty)
                .map(itm => itm.productName)
                .join(', \n'),
              pendingItemsDisplay: s.items
                .filter(itm => itm.orderedQty)
                .map(itm => itm.productName)
                .join(', \n')
            }))
          ],
          title: `${dataDm.i18n.label.fulfilmentPart} ${i + 1}`
        })),
        isOptimistic: dataDm.isOptimistic,
        isCancelling: dataDm.isCancelling,
        qaCancelOrderButton: 'moCancelOrderButton'
      };
      vmSubject$.next(viewModel);
    });
  }

  cancelOrder(id: string): void {
    this.repository.cancelOrder(id).then();
  }
}
