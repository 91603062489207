import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionPresenter } from './actions.presenter';
import { ActionsViewModel } from './actions.view.model';

@Component({
  selector: 'actions-component',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  @Input() readOnly = false;
  @Input() demo = false;
  @Output() previous: EventEmitter<any> = new EventEmitter<any>();
  @Output() next: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetAll: EventEmitter<any> = new EventEmitter<any>();

  vm: ActionsViewModel;

  constructor(private actionPresenter: ActionPresenter) {}

  ngOnInit() {
    this.actionPresenter.load(this.readOnly, vm => {
      this.vm = vm;
      this.vm.hasReset = this.demo;
    });
  }

  async previousCard() {
    await this.actionPresenter.previous(this.readOnly);
    if (this.previous) {
      this.previous.emit();
    }
  }

  async nextCard() {
    if (this.vm.nextDisabled) {
      return;
    }
    this.vm.nextDisabled = true;
    await this.actionPresenter.next(this.readOnly);
    if (this.next) {
      this.next.emit();
    }
  }

  async submitCards() {
    if (this.vm.submitDisabled) {
      return;
    }
    this.vm.submitDisabled = true;
    await this.actionPresenter.submit(this.readOnly);
    if (this.submitted) {
      this.submitted.emit();
    }
  }

  async resetCards() {
    await this.actionPresenter.reset();
    if (this.resetAll) {
      this.resetAll.emit();
    }
  }
}
