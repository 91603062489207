import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-array-type',
  templateUrl: './array-type.component.html',
  styleUrls: ['array-type.component.scss']
})
export class ArrayTypeComponent extends FieldArrayType {
  private readonly _spinnerName: string;

  get spinnerName(): string {
    return this._spinnerName;
  }

  constructor(private spinnerService: NgxSpinnerService) {
    super();
    this._spinnerName = uuidv4();
  }
  drop(event: CdkDragDrop<any>) {
    this.spinnerService.show(this.spinnerName).then();
    this.move(event.previousIndex, event.currentIndex);
    this.spinnerService.hide(this.spinnerName).then();
  }

  private cloneModelItem(index: number) {
    return JSON.parse(JSON.stringify(this.model[index]));
  }

  move(previousIndex: number, currentIndex: number): void {
    const modelCopy = this.cloneModelItem(previousIndex);
    super.remove(previousIndex, { markAsDirty: false });
    super.add(currentIndex, modelCopy, { markAsDirty: true });
  }
}
