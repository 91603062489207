import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormUpdate, IQuestionComponent } from '../question.model';
import { BehaviorSubject } from 'rxjs';
import { TextareaPresenter } from './textarea.presenter';
import { AsyncPipe, NgIf } from '@angular/common';
import { QUESTIONS } from '../questions.token';
import { TextareaQuestionParams, TextareaQuestionViewModel } from './textarea.model';

@Component({
  selector: 'question-textarea',
  templateUrl: './textarea.component.html',
  standalone: true,
  imports: [AsyncPipe, ReactiveFormsModule, NgIf],
  providers: [TextareaPresenter, { provide: QUESTIONS, useExisting: TextareaComponent, multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      textarea::placeholder {
        user-select: none;
      }
    `
  ]
})
export class TextareaComponent implements OnInit, IQuestionComponent {
  private readonly presenter = inject(TextareaPresenter);

  public readonly vm$: BehaviorSubject<TextareaQuestionViewModel | null> =
    new BehaviorSubject<TextareaQuestionViewModel | null>(null);

  @Input() params: TextareaQuestionParams;
  @Output() formChange: EventEmitter<FormUpdate> = new EventEmitter<FormUpdate>();

  ngOnInit() {
    this.presenter.load(this.vm$, this.params, this.formChange);
  }
}
