import { Component, Input, ViewChild } from '@angular/core';
import { CalendarEvent } from '../../../core/domain/event';
import { Job } from '../../../core/domain/job';
import { getStyleForStatus } from '../../utils/schedule.helper';
import { FullCalendarEvent, TooltipInfo } from '../../utils/schedule-types';
import * as moment from 'moment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'schedule-event-tooltip',
  templateUrl: 'schedule-event-tooltip.component.html',
  styleUrls: ['../../schedule.component.scss']
})
export class ScheduleEventTooltipComponent {
  showTooltip = '';
  tooltipStyle: Record<string, unknown>;
  info?: TooltipInfo;

  @ViewChild('tooltip') tooltip: HTMLDivElement;

  @Input() currentEvent: FullCalendarEvent;
  @Input() engineer: string;
  @Input() eventTypeData: any;
  @Input() translations: any;

  @Input() set trigger(event: MouseEvent) {
    if (event) {
      this.hydrateInfo();
      const target = event.target as HTMLElement;
      const { offsetLeft, offsetTop } = ScheduleEventTooltipComponent.getOffset(target);
      const { scrollLeft, scrollTop } = ScheduleEventTooltipComponent.getScroll(target);
      this.tooltipStyle = {
        left: `${offsetLeft - scrollLeft}px`,
        top: `${offsetTop - scrollTop}px`,
        display: 'block'
      };
      this.showTooltip = 'show';
    } else {
      this.info = null;
      this.showTooltip = '';
      this.tooltipStyle = { left: '0px', top: '0px', display: 'none' };
    }
  }

  private static getOffset(target: HTMLElement) {
    let offsetLeft = 0;
    let offsetTop = target.offsetHeight;
    while (target && target.className !== 'card') {
      offsetLeft += target.offsetLeft;
      offsetTop += target.offsetTop;
      target = target.offsetParent as HTMLElement;
    }
    return { offsetLeft, offsetTop };
  }

  private static getScroll(target: HTMLElement) {
    let scrollLeft = 0;
    let scrollTop = 0;
    while (target && target.className !== 'card') {
      scrollLeft += target.scrollLeft;
      scrollTop += target.scrollTop;
      target = target.parentElement as HTMLElement;
    }
    return { scrollLeft, scrollTop };
  }

  private hydrateJob(job) {
    const bodyItems = [];

    const style = getStyleForStatus(job.status);
    if (job.firstName) {
      bodyItems.push(`${this.translations.name} ${job.firstName} ${job.lastName}`);
    }
    if (job.phoneNumber) {
      bodyItems.push(`${this.translations.phone} ${job.phoneNumber}`);
    }
    if (job.address) {
      bodyItems.push(`${job.address.line1} ${job.address.postCode}`);
    }
    if (job.riskAssessor) {
      bodyItems.push(`${this.translations.riskAssessor} ${job.riskAssessor}`);
    }
    if (job.siteSupervisor) {
      bodyItems.push(`${this.translations.siteSupervisor} ${job.siteSupervisor}`);
    }
    if (job.installChargePoint) {
      bodyItems.push(`${this.translations.chargePoint} ${job.installChargePoint}`);
    }
    if (job.natureOfWorks) {
      bodyItems.push(job.natureOfWorks);
    }
    this.info = {
      title: this.currentEvent.title,
      status: style.label,
      bodyItems,
      assignedTo: job.assignedToDisplayName,
      colour: '#000'
    };
  }

  private getDisplayDate(dateString) {
    const dt = moment(dateString);
    return dt.format('L');
  }

  private getDisplayTime(dateString) {
    const dt = moment(dateString);
    return dt.format('LT');
  }

  private hydrateAbsence(absence) {
    const bodyItems = [];
    const startDate = this.getDisplayDate(absence.startIso);
    const endDate = this.getDisplayDate(absence.endIso);
    const startTime = this.getDisplayTime(absence.startIso);
    const endTime = this.getDisplayTime(absence.endIso);

    const title = this.currentEvent.title !== 'null' && this.currentEvent.title ? this.currentEvent.title : null;

    if (absence.description) {
      bodyItems.push(absence.description);
    }

    if (startDate && endDate && !absence.allDayEvent) {
      bodyItems.push(`${this.translations.start} ${startDate} : ${startTime}`);
      bodyItems.push(`${this.translations.end} ${endDate} : ${endTime}`);
    }

    if (startDate && endDate && absence.allDayEvent) {
      if (startDate === endDate) {
        bodyItems.push(`${startDate} (<span class="u-text-upper">${this.translations.allDay}</span>)`);
      } else {
        bodyItems.push(`<span class="u-text-upper">${this.translations.allDay}</span> <br> ${startDate} - ${endDate}`);
      }
    }

    this.info = {
      title: title,
      showTitle: this.eventTypeData.title,
      status: this.eventTypeData.name,
      bodyItems,
      assignedTo: this.engineer,
      icon: this.eventTypeData.icon,
      isAbsence: true,
      colour: this.eventTypeData.colour,
      textColour: this.eventTypeData.textColour
    };
  }

  private hydrateInfo() {
    const { extendedProps } = this.currentEvent;
    if (extendedProps.eventType === 'Job') {
      const job = extendedProps as Job;
      return this.hydrateJob(job);
    } else {
      const absence = extendedProps as CalendarEvent;
      return this.hydrateAbsence(absence);
    }
  }
}
