import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';
import { BaseQuestionComponent } from '../question.component';
import { ScrollService } from '../scroll.service';

@Component({
  selector: `crds-question-textbox`,
  template: `
    <div *ngIf="show && form" [formGroup]="form">
      <!-- QUESTION LABLE -->
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key"
        >{{ question.label }}
        <span *ngIf="question.required"> *</span>
        <crds-form-error
          *ngIf="isInvalid"
          [question]="question"
          [message]="question.errorMessage"
          [i18ns]="i18ns"
        ></crds-form-error>
      </label>

      <!-- QUESTION INPUT -->
      <input
        [formControlName]="question.key"
        [id]="question.key"
        [type]="question['type'] || 'text'"
        [readOnly]="question.readOnly"
        (change)="onChange()"
        [ngStyle]="{ 'text-transform': this.question.forceUpper && 'uppercase' }"
        class="form-control mb-2"
        [attr.data-qa]="question.key + 'Input'"
        #textInput
      />

      <!-- QUESTION HINT -->
      <crds-question-hint [question]="question"></crds-question-hint>

      <!-- QUESTION IMAGE -->
      <div class="image-wrapper">
        <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
      </div>
    </div>
  `,
  styles: [
    `
      [readonly] {
        color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
        cursor: default;
        background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
        border-color: rgba(118, 118, 118, 0.3);
        pointer-events: none;
      }
    `
  ]
})
export class TextboxQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<string>;
  @Input() i18ns: I18nKeys;
  @ViewChild('textInput') input: ElementRef;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;

  constructor(private imageAssetFactory: ImageAssetFactory, private scrollService: ScrollService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    if (this.question.disabled === true && !this.show) {
      setTimeout(() => this.form?.get(this.question.key)?.disable());
    }
    this.images = this.imageAssetFactory.create(this.question.image, this.form);
    this.scrollService.scrollObservable.subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  /** Though we can force to uppercase using CSS, that doesn't change the actual value
   *  so when we leave the field, we also need to upperCase the value too
   */
  onChange() {
    if (this.question.forceUpper) {
      const field = this.form.get(this.question.key);
      if (field?.value) {
        field.setValue(field.value.toUpperCase());
      }
    }
  }

  override get isInvalid() {
    return (this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid) ?? false;
  }

  override get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }
}
