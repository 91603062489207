import { NgModule } from '@angular/core';
import { PostCodeLookupComponent } from './post-code-lookup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [PostCodeLookupComponent],
  imports: [CommonModule, HttpClientModule, FormsModule, NgbButtonsModule, ReactiveFormsModule],
  exports: [PostCodeLookupComponent]
})
export class PostCodeLookupModule {}
