import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchBarService {
  public closeEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {}

  closeResults() {
    this.closeEvent$.next(true);
  }
}
