import { Injectable } from '@angular/core';
import { DocumentPackRepository } from '../document-pack.repository';

@Injectable()
export class DocumentPackErrorsPresenter {
  constructor(private repository: DocumentPackRepository) {}

  load(callback) {
    this.repository.getErrors(dataDm => {
      const viewModel = dataDm;
      callback(viewModel);
    });
  }

  clearErrors() {
    this.repository.clearErrors();
  }

  cleanup() {
    this.repository.unsubscribe();
  }
}
