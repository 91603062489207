import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-token-refresh',
  templateUrl: './token-refresh.component.html',
  styleUrls: ['../../login.component.scss']
})
export class TokenRefreshComponent {
  @Output() goToRouteEvent: EventEmitter<null> = new EventEmitter<null>();

  constructor() {}

  goBackOrHome(): void {
    this.goToRouteEvent.emit();
  }
}
