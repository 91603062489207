import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToReadable'
})
export class CamelToReadablePipe implements PipeTransform {
  static capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1).replace('_', ' ');
  }

  transform(value: any, args?: any): any {
    const words = value.match(/[A-Za-z]\w+/g);
    return words ? words.map(CamelToReadablePipe.capitalize).join(' ') : value;
  }
}
