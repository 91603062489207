import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LinkedProjectsErrorsPresenter } from './linked-projects-errors.presenter';
import { ILinkedProjectsErrorVm } from '../linked-projects.model';

@Component({
  selector: 'app-linked-projects-errors',
  templateUrl: './linked-projects-errors.component.html',
  styleUrls: ['./linked-projects-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedProjectsErrorsComponent implements OnInit, OnDestroy {
  vm: ILinkedProjectsErrorVm = {} as ILinkedProjectsErrorVm;

  constructor(private presenter: LinkedProjectsErrorsPresenter, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.presenter.load(generatedVm => {
      this.vm = generatedVm;
      this.cd.markForCheck();
    });
  }

  clearError() {
    this.presenter.clearErrors();
  }

  ngOnDestroy() {
    this.presenter.cleanUp();
  }
}
