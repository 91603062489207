import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IStandardQuoteSelectVm } from '../../../domain';
import { StandardQuoteSelectPresenter } from './standard-quote-select.presenter';

@Component({
  selector: 'app-standard-quote-select-component',
  templateUrl: './standard-quote-select.component.html',
  styleUrls: ['./standard-quote-select.component.scss']
})
export class StandardQuoteSelectComponent implements OnInit {
  vm: IStandardQuoteSelectVm;

  @Input() projectType: string;

  @Output() standardQuoteChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private presenter: StandardQuoteSelectPresenter) {}

  async ngOnInit() {
    await this.presenter.load(this.projectType, generatedModel => {
      this.vm = generatedModel;
    });
  }

  changeStandardQuote(standardQuoteId: string) {
    if (standardQuoteId && standardQuoteId !== this.vm?.standardQuote?.id) {
      this.vm.standardQuote = this.vm.standardQuotes.find(x => x.id === standardQuoteId);
      if (this.standardQuoteChanged && this.vm.standardQuote) {
        this.standardQuoteChanged.emit(JSON.parse(JSON.stringify(this.vm.standardQuote.data)));
      }
    }
  }
}
