import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as XRegExp from 'xregexp';
import { Task, TaskStatus } from './project-detail.component';

@Component({
  selector: 'app-new-project-task',
  templateUrl: './new-project-task.component.html',
  styleUrls: ['./project-detail.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          display: 'block',
          opacity: 1
        })
      ),
      state(
        'closed',
        style({
          display: 'none',
          opacity: 0
        })
      ),
      transition('open => closed', [animate('0.3s')]),
      transition('closed => open', [animate('0.5s')])
    ])
  ]
})
export class NewProjectTaskComponent implements OnInit, OnDestroy {
  @Output() newTask: EventEmitter<Task> = new EventEmitter<Task>();
  form: UntypedFormGroup;
  isCreating = false;

  ngOnInit() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(uuidv4()),
      task: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern("^[\\p{L}0-9\\ \\'\\.\\,\\/\\-\\?]{0,30}$")
      ]),
      description: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(XRegExp("[\\p{L}0-9\\ \\'\\.\\,\\/\\-\\?]{1,100}"))
      ]),
      status: new UntypedFormControl(TaskStatus.OPEN),
      removable: new UntypedFormControl(true)
    });
  }

  ngOnDestroy() {}

  toggleIsCreating() {
    this.isCreating = !this.isCreating;
  }

  addTask() {
    this.newTask.emit(this.form.value as Task);
    this.form.reset();
    this.isCreating = false;
  }

  isInvalid(formField: string) {
    const formControl = this.form.get(formField);
    return formControl.dirty && formControl.invalid;
  }
}
