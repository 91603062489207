import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { AlertService } from '../../../alert.service';
import { AutoAssignmentRepository } from '../../auto-assignment.repository';
import { AssignmentMappingDisplay, IAutoAssignMappingsVm, SubTenantLookUp, TenantEntry } from '../../domain';
import { getAllTenants } from '../../mapping.utility';

@Injectable()
export class AssignmentMappingsPresenter {
  constructor(
    private repository: AutoAssignmentRepository,
    private translocoService: TranslocoService,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService
  ) {}

  async load(callback, testResultCallback) {
    await this.spinnerService.show('autoAssignmentSpinner');
    try {
      const vm: IAutoAssignMappingsVm = {
        mappings: [],
        filteredMappings: [],
        tenants: [],
        subTenants: null,
        searchFilter: null,
        copyLabel: this.translocoService.translate('common.copy'),
        topHitId: null,
        pageSize: 15,
        mappingsValid: true,
        noDataErrorMessage: this.translocoService.translate('autoAssignment.noData')
      };
      this.repository.getTestResults(results => {
        vm.topHitId = results?.[0]?.id;
        testResultCallback(vm.topHitId);
      });
      await this.repository.getAssignments(assignments => {
        const allTenants = getAllTenants(assignments);
        vm.tenants = allTenants.tenants;
        vm.subTenants = allTenants.subTenants;
      });
      await this.repository.getMappings(mappings => {
        vm.mappings = mappings;
        callback(vm);
      });
    } finally {
      await this.spinnerService.hide('autoAssignmentSpinner');
    }
  }

  async copy(vm: IAutoAssignMappingsVm) {
    const text = await this.convertToTSV(vm.mappings);
    vm.copyLabel = this.translocoService.translate('common.copied');
    await navigator.clipboard.writeText(text);
    setTimeout(() => {
      vm.copyLabel = this.translocoService.translate('common.copy');
    }, 2000);
  }

  async paste(vm: IAutoAssignMappingsVm, text: string) {
    if (text) {
      try {
        if (
          !(await this.alertService.alert('autoAssignment.pasteTitle', 'autoAssignment.areYouSure', 'common.yes', true))
        ) {
          return;
        }
        vm.mappings = await this.convertFromTSV(text, vm.tenants, vm.subTenants);
        this.checkingMappingsValid(vm);
      } catch (err) {
        console.error('Error parsing TSV data', err);
        await this.alertService.alert('common.error', err.message);
      }
    } else {
      await this.alertService.alert('common.error', 'common.nothingToPaste');
    }
  }

  async save(vm: IAutoAssignMappingsVm) {
    try {
      await this.spinnerService.show('autoAssignmentSpinner');
      await this.saveMappings(vm.mappings, vm.subTenants);
    } catch (e) {
      console.error('Unable to save mappings', e.message);
      await this.alertService.alert('common.error', 'autoAssignment.unableToSaveMappings');
    } finally {
      await this.spinnerService.hide('autoAssignmentSpinner');
    }
  }

  private async saveMappings(mappings: AssignmentMappingDisplay[], subTenants: SubTenantLookUp) {
    return await this.repository.saveMappings(mappings, subTenants);
  }

  async convertToTSV(mappings: AssignmentMappingDisplay[]): Promise<string> {
    const rows = mappings.map(m => `${m.match}\t${m.tenantDisplay}\t${m.rank}`);
    rows.unshift(await this.getHeaderRow());
    return rows.join(`\n`);
  }

  async convertFromTSV(
    text: string,
    tenants: TenantEntry[],
    subTenants: SubTenantLookUp
  ): Promise<AssignmentMappingDisplay[]> {
    if (typeof text !== 'string') {
      throw new Error('autoAssignment.noRowsFound');
    }
    const rows = text.split(/\r?\n/).filter(r => r !== '');
    if (rows.length <= 1) {
      throw new Error('autoAssignment.noRowsFound');
    }
    if (rows[0] !== (await this.getHeaderRow())) {
      throw new Error('autoAssignment.headerRowDoesNotMatch');
    }

    rows.shift();
    return rows.map(r => {
      const row = r.split('\t');
      if (row.length !== 3) {
        throw new Error('autoAssignment.rowHasWrongNumberOfColumns');
      }
      const [match, tenantName, rank] = row;
      if (!match && !tenantName && !rank) {
        throw new Error('autoAssignment.rowHasWrongNumberOfColumns');
      }
      const mapping: Partial<AssignmentMappingDisplay> = {
        id: this.createNewMappingId(),
        match,
        rank: Number.parseInt(rank) ?? 0
      };
      const subTenant = subTenants[tenantName];
      if (subTenant) {
        mapping.targetTenant = subTenant.tenant;
        mapping.targetSubTenant = {
          name: subTenant.subTenantName,
          id: subTenant.subTenantId
        };
        mapping.tenantDisplay = subTenant.subTenantName;
      } else {
        const tenant = tenants.find(t => t.id === tenantName);
        if (tenant) {
          mapping.targetTenant = tenantName;
          mapping.tenantDisplay = tenantName;
        }
      }
      return mapping as AssignmentMappingDisplay;
    });
  }

  checkingMappingsValid(vm) {
    vm.mappingsValid = !vm.mappings.find(
      mapping => isNaN(mapping.rank) || mapping.rank === null || !mapping.targetTenant?.length || !mapping.match?.length
    );
  }

  addRow(vm: IAutoAssignMappingsVm) {
    vm.mappings = [this.newAssignmentMapping(), ...vm.mappings];
  }

  matchChange(row: AssignmentMappingDisplay, match: string) {
    row.match = match;
  }

  rankChange(row: AssignmentMappingDisplay, rank: number) {
    if (isNaN(rank)) {
      rank = 1;
    }
    row.rank = rank;
  }

  tenantChange(row: AssignmentMappingDisplay, tenant: string, tenants: TenantEntry[], subTenants: SubTenantLookUp) {
    const tenantInfo = tenants.find(t => t.id === tenant);
    row.targetTenant = tenantInfo?.name;
    const subTenant = subTenants[row.targetTenant];
    if (subTenant) {
      row.targetTenant = subTenant.tenant;
      row.targetSubTenant = {
        name: subTenant.subTenantName,
        id: subTenant.subTenantId
      };
    } else {
      delete row.targetSubTenant;
    }
    row.tenantDisplay = tenantInfo?.name;
  }

  deleteRow(vm: IAutoAssignMappingsVm, row: AssignmentMappingDisplay) {
    vm.mappings = vm.mappings.filter(r => r !== row);
  }

  private async getHeaderRow() {
    const headers = await Promise.all(
      ['autoAssignment.match', 'common.tenant', 'autoAssignment.rank'].map(
        async c => await this.translocoService.translate(c)
      )
    );
    return headers.join('\t');
  }

  private newAssignmentMapping() {
    return {
      id: this.createNewMappingId(),
      targetTenant: undefined,
      match: '',
      rank: 1,
      tenantDisplay: undefined
    };
  }

  private createNewMappingId() {
    return `aam_${uuidv4()}`;
  }
}
