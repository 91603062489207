import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { IStandardQuotesVm, StandardData } from '../../../domain';
import { StandardQuotesPresenter } from './standard-quotes.presenter';

@Component({
  selector: 'app-standard-quotes-component',
  templateUrl: './standard-quotes.component.html',
  styleUrls: ['./standard-quotes.component.scss']
})
export class StandardQuotesComponent implements OnInit {
  vm: IStandardQuotesVm;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private presenter: StandardQuotesPresenter) {}

  async ngOnInit() {
    await this.presenter.load(async generatedModel => {
      this.vm = generatedModel;
    });
  }

  async searchFilterChange() {
    await this.presenter.filterStandardQuotes(this.vm.searchFilter);
  }

  async add() {
    await this.presenter.addStandardQuote();
  }

  async editRow(row: StandardData) {
    await this.presenter.editStandardQuote(row);
  }

  async deleteRow(row: StandardData) {
    await this.presenter.deleteStandardQuote(row.id);
  }
}
