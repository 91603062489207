import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  AUTH_MIN_LEN,
  AUTH_REGEX_LOWER,
  AUTH_REGEX_NUMBER,
  AUTH_REGEX_SYMBOL,
  AUTH_REGEX_UPPER,
  CustomValidators
} from '../utils/custom-validators';
import { APP_HOME_PATH } from '../../app.routes';
import { AuthenticationService } from '../services/authentication.service';
import { AuthStateEvent, AuthUserChallenge } from '../utils/auth-types';
import {UserThemeService} from "../../core/user-theme.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly routeData: Data;
  private readonly notificationTimeMs = 3000;
  private readonly autoSignInTimeMs = 500;

  private userId: string;
  private currentAuthStateSubject: BehaviorSubject<AuthStateEvent> = new BehaviorSubject<AuthStateEvent>(null);
  private notificationSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private stateSub: Subscription;
  private allowedEntryStates = [AuthStateEvent.ForgotPassword, AuthStateEvent.StartChangePassword];

  public signInForm: UntypedFormGroup;
  public forgotPasswordForm: UntypedFormGroup;
  public forgotPasswordSubmitForm: UntypedFormGroup;
  public newPasswordRequiredForm: UntypedFormGroup;
  public changePasswordForm: UntypedFormGroup;
  public smsMfaRequiredForm: UntypedFormGroup;
  public currentAuthState$ = this.currentAuthStateSubject.asObservable();
  public authNotification$ = this.notificationSubject.asObservable();
  public submitSpinner = 'submitSpinner';
  public initialState: AuthStateEvent = null;
  public code: string;
  public submitInProgress = false;
  public sendSmsInProgress = false;
  public authError$: Observable<string>;
  public AuthStateEvent = AuthStateEvent;
  public logo = '';

  constructor(
    private spinnerService: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public themeService: UserThemeService
  ) {
    this.routeData = this.activatedRoute.snapshot.data;
    this.activatedRoute.queryParams.subscribe(params => {
      this.userId = params['id'];
      this.code = params['code'];
    });
    this.initialState = this.routeData.state;
    this.currentAuthStateSubject.next(this.initialState ?? AuthStateEvent.StartSignIn);
  }

  ngOnInit() {
    this.authenticationService.clearErrors();
    this.authError$ = this.authenticationService.authErrorObservable;
    this.stateSub = this.authenticationService.authStateObservable.subscribe(state => {
      this.currentAuthStateSubject.next(state);
    });

    if (this.authenticationService.isUserSignedIn && !this.allowedEntryStates.includes(this.initialState)) {
      this.router.navigate([APP_HOME_PATH]).then();
    }
    this.spinnerService.show(this.submitSpinner).then();
    this.initAuthForms();
  }

  initAuthForms() {
    this.signInForm = this.fb.group({
      username: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required])
    });

    this.forgotPasswordForm = this.fb.group({
      username: new UntypedFormControl('', [Validators.required])
    });

    this.forgotPasswordSubmitForm = this.fb.group(
      {
        username: new UntypedFormControl(this.userId ?? '', [Validators.required]),
        code: new UntypedFormControl(this.code ?? '', [Validators.required]),
        newPassword: new UntypedFormControl('', this.passwordStrengthValidator()),
        newPasswordConfirm: new UntypedFormControl('', [Validators.required])
      },
      { validator: this.passwordMatchValidator }
    );

    this.newPasswordRequiredForm = this.fb.group(
      {
        user: new UntypedFormControl(null, [Validators.required]),
        newPassword: new UntypedFormControl('', this.passwordStrengthValidator()),
        newPasswordConfirm: new UntypedFormControl('', [Validators.required])
      },
      { validator: this.passwordMatchValidator }
    );

    this.changePasswordForm = this.fb.group(
      {
        oldPassword: new UntypedFormControl('', [Validators.required]),
        newPassword: new UntypedFormControl('', this.passwordStrengthValidator()),
        newPasswordConfirm: new UntypedFormControl('', [Validators.required])
      },
      { validator: this.passwordMatchValidator }
    );

    this.smsMfaRequiredForm = this.fb.group({
      user: new UntypedFormControl(null, [Validators.required]),
      code: new UntypedFormControl('', [Validators.required])
    });
  }

  async signIn() {
    if (this.signInForm.valid) {
      this.submitInProgress = true;
      await this.authenticationService
        .signIn(this.signInForm.get('username').value, this.signInForm.get('password').value)
        .then((user: any) => {
          if (user && user.challengeName === AuthUserChallenge.NewPasswordRequired) {
            this.currentAuthStateSubject.next(AuthStateEvent.NewPasswordRequired);
            this.newPasswordRequiredForm.get('user').patchValue(user);
          }
          if (user && user.challengeName === AuthUserChallenge.SmsMfaRequired) {
            this.currentAuthStateSubject.next(AuthStateEvent.SmsMfaRequired);
            this.smsMfaRequiredForm.get('user').patchValue(user);
          }
        })
        .catch(e => {
          console.log(`Sign in error: ${e}`);
          this.submitInProgress = false;
        });
      this.submitInProgress = false;
    }
  }

  async changePassword() {
    this.authenticationService.clearErrors();
    if (this.changePasswordForm.valid) {
      const oldPassword = this.changePasswordForm.get('oldPassword').value;
      const newPassword = this.changePasswordForm.get('newPassword').value;
      this.submitInProgress = true;
      await this.authenticationService.changePassword(oldPassword, newPassword);
      this.submitInProgress = false;
    }
  }

  async forgotPassword(username: string = null) {
    this.authenticationService.clearErrors();
    if (this.forgotPasswordForm.valid || username) {
      // if we pass a username that means we have asked for a new code
      // we reset the initial state so we display the email sent splash
      if (username) {
        this.initialState = null;
      }
      const user = username ?? this.forgotPasswordForm.get('username').value;
      this.submitInProgress = true;
      await this.authenticationService.forgotPassword(user);
      this.submitInProgress = false;
      this.forgotPasswordSubmitForm.get('username').patchValue(user);
    }
  }

  async forgotPasswordSubmit() {
    this.authenticationService.clearErrors();
    if (this.forgotPasswordSubmitForm.valid) {
      const user = this.forgotPasswordSubmitForm.get('username').value;
      const pwd = this.forgotPasswordSubmitForm.get('newPassword').value;
      const code = this.forgotPasswordSubmitForm.get('code').value;
      this.submitInProgress = true;
      this.authenticationService
        .forgotPasswordSubmit(user, code, pwd)
        .then(
          () => {
            setTimeout(() => {
              this.signInForm.patchValue({ username: user, password: pwd });
              this.signIn();
            }, this.autoSignInTimeMs);
          },
          rej => {
            console.log(`Reset password error: ${rej}`);
            this.submitInProgress = false;
          }
        )
        .catch(e => {
          console.log(`Error: ${e}`);
          this.submitInProgress = false;
        });
    }
  }

  async newPasswordRequired() {
    this.authenticationService.clearErrors();
    if (this.newPasswordRequiredForm.valid) {
      const user = this.newPasswordRequiredForm.get('user').value;
      const pwd = this.newPasswordRequiredForm.get('newPassword').value;
      this.submitInProgress = true;
      this.authenticationService
        .forceNewPassword(user, pwd)
        .then(
          () => {
            setTimeout(() => {
              this.signInForm.patchValue({ username: user.username, password: pwd });
              this.signIn();
            }, this.autoSignInTimeMs);
          },
          rej => {
            console.log(`Force new password error: ${rej}`);
            this.submitInProgress = false;
          }
        )
        .catch(e => {
          console.log(`Error: ${e}`);
          this.submitInProgress = false;
        });
    }
  }

  async confirmSmsMfa() {
    if (this.smsMfaRequiredForm.valid) {
      const user = this.smsMfaRequiredForm.get('user').value;
      const code = this.smsMfaRequiredForm.get('code').value;
      this.submitInProgress = true;
      await this.authenticationService.confirmSmsMfa(user, code);
      this.submitInProgress = false;
    }
  }

  async resendSmsMfa() {
    this.sendSmsInProgress = true;
    await this.signIn();
    this.sendSmsInProgress = false;
  }

  resendCode() {
    const user = this.forgotPasswordSubmitForm.get('username').value;
    this.authenticationService.resendCode(user).then();
  }

  notification(message: string) {
    this.notificationSubject.next(message);
    setTimeout(() => {
      this.notificationSubject.next(null);
    }, this.notificationTimeMs);
  }

  goBackToRoute() {
    this.authenticationService.navigateBackToRoute();
  }

  setAuthState(state: AuthStateEvent) {
    this.authenticationService.clearErrors();
    this.currentAuthStateSubject.next(state);
  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    return CustomValidators.passwordMatchValidator(frm);
  }

  passwordStrengthValidator() {
    return Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(AUTH_REGEX_NUMBER, { hasNumber: true }),
      CustomValidators.patternValidator(AUTH_REGEX_UPPER, { hasUpperCase: true }),
      CustomValidators.patternValidator(AUTH_REGEX_LOWER, { hasLowerCase: true }),
      CustomValidators.patternValidator(AUTH_REGEX_SYMBOL, { hasSymbol: true }),
      Validators.minLength(AUTH_MIN_LEN)
    ]);
  }

  ngOnDestroy() {
    if (this.stateSub) {
      this.stateSub.unsubscribe();
    }
  }
}
