import { Injectable } from '@angular/core';
import { TasksRepository } from '../../tasks.repository';

@Injectable()
export class InvalidItemsPresenter {
  constructor(private repository: TasksRepository) {}
  load(callback) {
    this.repository.getInvalidItems(vm => {
      callback(vm);
    });
  }

  clearInvalidItems() {
    this.repository.clearInvalidItems();
  }
}
