import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpGateway } from '../../../core/http-gateway.service';
import { ProjectConfigurationService } from '../../../core/project-configuration.service';
import {
  AssignmentAction,
  AssignmentMapping,
  AssignmentMappingDisplay,
  AssignmentMappingTestDefinition,
  AssignmentMappingTestResult,
  ProjectAssignment,
  SubTenantLookUp
} from './domain';
import { cleanMappings, tenantsToList } from './mapping.utility';

const skipHeader = new HttpHeaders({
  skip: 'true'
});

@Injectable({
  providedIn: 'root'
})
export class AutoAssignmentRepository {
  subscriptionList: Subscription[] = [];

  assignments$: Subject<ProjectAssignment[] | null> = new Subject<ProjectAssignment[] | null>();
  mappings$: Subject<AssignmentMapping[] | null> = new Subject<AssignmentMapping[] | null>();
  testResults$: Subject<AssignmentMappingTestResult[] | null> = new Subject<AssignmentMappingTestResult[] | null>();

  constructor(private projectConfigurationService: ProjectConfigurationService, private httpGateway: HttpGateway) {}

  async initialise() {
    this.assignments$ = new Subject<ProjectAssignment[] | null>();
    this.mappings$ = new Subject<AssignmentMapping[] | null>();
    this.testResults$ = new Subject<AssignmentMappingTestResult[] | null>();
  }

  async getAssignments(callback) {
    this.subscriptionList.push(this.assignments$.subscribe(callback));
    await this.hydrateAssignments();
  }

  async hydrateAssignments(): Promise<void> {
    const assignments = await this.httpGateway.get(`${environment.apiAssignmentsUrl}`, { parseBody: false });
    for (const assignment of assignments) {
      assignment.tenantsDisplay = tenantsToList(assignment.tenants);
      assignment.actions = await this.getAssignmentActions(assignment.projectType);
    }
    this.assignments$?.next(assignments);
  }

  async getMappings(callback) {
    this.subscriptionList.push(this.mappings$.subscribe(callback));
    await this.hydrateMappings();
  }

  async hydrateMappings(): Promise<void> {
    let mappings = await this.httpGateway.get(`${environment.apiAssignmentsUrl}/mappings`, { parseBody: false });
    mappings = mappings.map(m => ({
      ...m,
      tenantDisplay: m.targetSubTenant?.name ?? m.targetTenant
    }));
    this.mappings$?.next(mappings);
  }

  getTestResults(callback) {
    this.subscriptionList.push(this.testResults$.subscribe(callback));
  }

  async test(definition: AssignmentMappingTestDefinition): Promise<AssignmentMappingTestResult[]> {
    let results = await this.httpGateway.post(`${environment.apiAssignmentsUrl}/auto`, definition, skipHeader);
    results = results.map(m => ({
      ...m,
      tenantDisplay: m.targetSubTenant?.name ?? m.targetTenant
    }));
    this.testResults$?.next(results);
    return results;
  }

  async getAssignmentActions(projectType): Promise<AssignmentAction[]> {
    return await this.httpGateway.get(`${environment.apiAssignmentsUrl}/actions/${projectType}`, { parseBody: false });
  }

  public cleanup(): void {
    this.subscriptionList.forEach(sub => sub.unsubscribe());
    this.subscriptionList = [];
  }

  async saveMappings(mappings: AssignmentMappingDisplay[], subTenants: SubTenantLookUp) {
    const cleanedMappings = cleanMappings(mappings, subTenants);
    return await this.httpGateway.post(`${environment.apiAssignmentsUrl}/mappings/bulk`, cleanedMappings, skipHeader);
  }

  async saveProjectAssignment(projectAssignment: ProjectAssignment) {
    return await this.httpGateway.put(
      `${environment.apiAssignmentsUrl}/${projectAssignment.id}`,
      projectAssignment,
      skipHeader
    );
  }

  clearTestResults() {
    this.testResults$?.next(null);
  }
}
