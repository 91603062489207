import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeHtml } from '@angular/platform-browser';
import { PrintingService } from '../core/print.service';
import { ProjectAction } from '../core/domain/project.action';

@Component({
  selector: 'app-status-transition-result-modal',
  template: ` <div class="modal-wrapper">
    <div class="modal-header-wrapper">
      <div class="modal-header">
        <div>{{ action.label }}</div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body html-result-body p-0 pb-2">
      <div [innerHtml]="result" #printEl></div>
    </div>
    <div class="modal-footer">
      <button *ngIf="action.action !== 'GenerateDoc'" type="button" class="btn btn-sm btn-primary" (click)="print()">
        Print
      </button>
      <button type="button" class="btn btn-sm btn-primary" (click)="activeModal.dismiss('Cross click')">
        {{ 'common.close' | transloco }}
      </button>
    </div>
  </div>`,
  styleUrls: ['project-detail.component.scss']
})
export class StatusTransitionResultModalComponent {
  /**
   * {
   *   'label': 'Submit to DNO',
   *   'order': 0,
   *   'status': 'DNO SUBMITTED',
   *   'alert': 'This will notify the DNO, requesting approval to go ahead with the installation, do you want to submit?',
   *   'confirmation': {
   *     'type': 'modal',
   *     'layout': {
   *       'label': 'Submit to DNO',
   *       'dataSource': 'DnoDemandSummary',
   *       'items': [
   *         {
   *           'order': 0,
   *           'key': 'Max. Demand',
   *           'content': '<strong>{maximumDemand}</strong>'
   *         }...
   *       ]
   *     }
   *   }
   * }
   */
  @Input() action: ProjectAction;
  @Input() result: SafeHtml;
  @ViewChild('printEl') printEl: ElementRef;

  constructor(public activeModal: NgbActiveModal, private printingService: PrintingService) {}

  public print(): void {
    this.printingService.print(this.printEl.nativeElement);
  }
}
