import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { NgxSpinnerService } from 'ngx-spinner';
import { I18nKeys } from '../../../domain/i18n-keys';
import { ImageError, ImageManipulationService } from '../../image-manipulation.service';
import { BaseQuestionComponent } from '../../question.component';
import { ScrollService } from '../../scroll.service';

@Component({
  selector: 'crds-question-file-upload-input',
  template: `
    <!-- QUESTION INPUT -->
    <input
      [id]="'file_' + question.key"
      [name]="'file_' + question.key"
      (change)="setFromFile($event)"
      class="fileCapture"
      style="display:none;"
      type="file"
      [accept]="accept"
    />
    <jui-button
      (click)="clickFile(question.key)"
      size="sm"
      expand
      #fileUploadButton
      [attr.data-qa]="question.key + '_fileUploadButton'"
    >
      <span>{{ hasUploadedFiles ? i18ns.add : i18ns.upload }} {{ i18ns.file }}</span>
      <span slot="icon" class="material-icons">{{ hasUploadedFiles ? 'note_add' : 'insert_drive_file' }}</span>
    </jui-button>
    <crds-form-error
      *ngIf="uploadError"
      [message]="uploadError"
      [i18ns]="i18ns"
      class="file-upload-error"
    ></crds-form-error>
  `,
  styles: [
    `
      .material-icons {
        color: #ffffff;
      }

      .file-placeholder-icon i {
        font-size: 3rem;
        color: var(--primary-color) !important;
      }

      ::ng-deep .file-upload-error > span {
        padding-left: 0;
        line-height: 1.2;
        margin-bottom: 10px;
        display: block;
      }
    `
  ]
})
export class FileUploadQuestionInputComponent extends BaseQuestionComponent {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;

  private _hasUploadedFiles: boolean;
  @Input() set hasUploadedFiles(value: boolean) {
    this._hasUploadedFiles = value;
  }
  get hasUploadedFiles(): boolean {
    return this._hasUploadedFiles;
  }
  uploadError: string;
  imageError: ImageError | null;

  @ViewChild('fileUploadButton') input;
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private spinnerService: NgxSpinnerService,
    private scrollService: ScrollService,
    private imageService: ImageManipulationService
  ) {
    super();
  }
  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  get accept() {
    return this.question.accept || '.doc,.docx,.xls,.xlsx,.pdf';
  }

  clickFile(questionKey) {
    (document.getElementById(`file_${questionKey}`) as HTMLElement).click();
  }

  setFromFile(event) {
    this.uploadError = null;
    try {
      const file: File = event.target.files[0];
      if (!file) {
        return; // User cancelled
      }

      this.imageError = this.imageService.checkFileTypeAndSize(file, this.accept);
      if (this.imageError) {
        this.uploadError = `${this.imageError.fileName}: ${this.i18ns[this.imageError.type]}`;
        return;
      }

      this.spinnerService.show().then();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const name = this.getSanitisedFilename(file.name);
        this.fileUploaded.emit({
          name: name,
          data: reader?.result?.toString()
        });
        this.spinnerService.hide().then(() => this.reset(event.target));
      };
      reader.onerror = () => {
        // Swallowing error as likely comes from Cancelled upload
        this.spinnerService.hide().then(() => this.reset(event.target));
      };
    } catch (error) {
      // Swallowing error as likely comes from Cancelled upload
      this.spinnerService.hide().then(() => this.reset(event.target));
    }
  }

  reset(target) {
    target.value = null;
  }

  getSanitisedFilename(fileName: string) {
    return fileName.replace(/_\d{13}(\..*)$/, (match, p1) => {
      return p1;
    });
  }
}
