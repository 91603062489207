import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

export const CONTACT_LOGGING_LD_FEATURE_KEY = 'contact-logging';

export interface ContactLog {
  note?: string;
  communicationType: string;
  outcome: string;
}

export interface IContactLogActionVm {
  addContactLog: string;
}

export interface IContactLogModalVm {
  addContactLog: string;
  cancel: string;
  communicationType: string;
  form: FormGroup<ContactLogForm>;
  formErrors: { [key: string]: string };
  infoImportant: string;
  infoImportantEdit: string;
  infoImportantActivity: string;
  notReached: string;
  note: string;
  outcome: string;
  email: string;
  phone: string;
  phoneEmail: string;
  pleaseCompleteMsg: string;
  reached: string;
  submit: string;
}

export interface ContactLogForm {
  communicationType: FormControl<string>;
  note?: FormControl<string>;
  outcome: FormControl<string>;
}
