<div class="pd-summary">
  <div class="pd-summary__col">
    <span class="pd-summary__label">{{ 'common.owner' | transloco }}:</span>
    <span class="pd-summary__val">
      <app-multi-select
        class="pd-summary__select"
        *ngIf="dropDownElements.length"
        [singleSelection]="true"
        [selectedItems]="[project.owner]"
        [items]="dropDownElements"
        (itemsChanged)="updateOwner($event)"
      ></app-multi-select
      ><jui-skeleton-loader
        class="pd-summary__select"
        *ngIf="dropDownElements.length === 0"
        height="32px"
      ></jui-skeleton-loader>
    </span>
  </div>

  <ng-container *ngIf="extendedUserDetail && extendedUserDetail.email">
    <div class="pd-summary__row">
      <span class="pd-summary__label">{{ 'projectDetail.ownerEmail' | transloco }}:</span>
      <span class="pd-summary__val">{{ extendedUserDetail.email || ('common.unknown' | transloco) }}</span>
    </div>
    <div class="pd-summary__row">
      <span class="pd-summary__label">{{ 'projectDetail.ownerPhone' | transloco }}:</span>
      <span class="pd-summary__val">{{ extendedUserDetail.phoneNumber || ('common.unknown' | transloco) }}</span>
    </div>
  </ng-container>

  <jui-skeleton-loader
    *ngIf="loadingExtendedUserDetails && !extendedUserDetail"
    height="12px"
    width="80%"
  ></jui-skeleton-loader>
  <jui-skeleton-loader
    *ngIf="loadingExtendedUserDetails && !extendedUserDetail"
    height="12px"
    width="65%"
  ></jui-skeleton-loader>

  <app-assignment-label [project]="project"></app-assignment-label>

  <ng-container *ngFor="let item of summaryItems">
    <div class="pd-summary__row">
      <span class="pd-summary__label">{{ item.label }}</span>
      <span class="pd-summary__val" [innerHtml]="item.value"></span>
    </div>
  </ng-container>
</div>
