import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiService {
  inFlight: { [key: string]: Promise<any[]> } = {};

  constructor(protected httpClient: HttpClient) {}

  get(url: string) {
    return this.notConcurrent(url, this.getData)();
  }

  async getData(self: ApiService, url: string) {
    const params: HttpParams = new HttpParams();
    const request = self.httpClient
      .get(url, { params: params })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));

    return await request
      .toPromise()
      .then((result: any[]) => {
        return result;
      })
      .catch(error => {
        console.error('Failed to retrieve list', error);
        return [];
      });
  }

  notConcurrent(url, proc: (self: ApiService, url: string) => PromiseLike<any[]>) {
    const self = this;
    return () => {
      if (!self.inFlight[url]) {
        self.inFlight[url] = (async () => {
          try {
            return await proc(self, url);
          } finally {
            self.inFlight[url] = null;
          }
        })();
      }
      return self.inFlight[url];
    };
  }
}
