import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AuditLog, AuditLogStatus } from '@jump-tech-frontend/domain';
import { ProjectAuditLogsPresenter } from './project-audit-logs.presenter';
import { BehaviorSubject } from 'rxjs';
import { ProjectAuditLogsVm } from './project-audit-logs.vm';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoPipe } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { JumptechAngularCommonModule } from '@jump-tech-frontend/angular-common';
import { ProjectAuditLogsRepository } from './project-audit-logs.repository';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatureFlagService } from '../../core/feature-flag/feature-flag.service';
import { AUDIT_LOG_I18N_FEATURE_KEY, LOCALE_CONTEXT } from './project-audit-logs.model';

@Component({
  selector: 'app-project-last-audit-log',
  templateUrl: 'project-last-audit-log.component.html',
  styleUrls: ['project-last-audit-log.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslocoModule, CoreComponentsAngularModule, JumptechAngularCommonModule, NgbAlertModule],
  providers: [ProjectAuditLogsPresenter, ProjectAuditLogsRepository, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectLastAuditLogComponent implements OnInit, OnDestroy, OnChanges {
  isLoading: boolean;
  private initialised = false;
  public featureFlagEnabled = false;
  public localeContext = LOCALE_CONTEXT;

  public vm$: BehaviorSubject<ProjectAuditLogsVm> = new BehaviorSubject<ProjectAuditLogsVm>(null);

  constructor(
    private presenter: ProjectAuditLogsPresenter,
    private featureFlagService: FeatureFlagService,
    private transloco: TranslocoPipe
  ) {}

  @Input() projectId: string;
  @Input() set loading(loading) {
    this.isLoading = loading;
  }

  @Output() auditLogs: EventEmitter<AuditLog[] | null> = new EventEmitter<AuditLog[] | null>();

  async ngOnInit() {
    await this.presenter.load(this.projectId, this.vm$);
    this.vm$.subscribe(vm => {
      this.emitAuditLogs(vm?.allAuditLogs || null);
    });
    this.initialised = true;
    this.featureFlagEnabled = await this.featureFlagService.isFeatureEnabled(AUDIT_LOG_I18N_FEATURE_KEY);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.initialised && changes['projectId'] !== undefined) {
      this.ngOnDestroy();
      await this.presenter.load(this.projectId, this.vm$);
    }
  }
  ngOnDestroy() {
    this.presenter.unload();
  }

  private emitAuditLogs(auditLogs: AuditLog[] | null) {
    this.auditLogs.emit(auditLogs);
  }

  public displayAuditStatus(status: AuditLogStatus) {
    return this.transloco.transform(`${LOCALE_CONTEXT}.${status.toLowerCase()}`);
  }
}
