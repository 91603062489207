import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss']
})
export class UserHistoryComponent {
  @Input() userHistory$;
  @Input() hasAppBooted;
  @Output() clearHistory = new EventEmitter();

  constructor() {}
}
