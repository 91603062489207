import { Injectable } from '@angular/core';
import { DocumentPackRepository } from '../document-pack.repository';
import { IMissingInfoDm, IMissingInfoVm } from '../document-pack.model';

@Injectable()
export class DocumentPackMissingInfoPresenter {
  constructor(private repository: DocumentPackRepository) {}

  load(callback) {
    this.repository.loadMissingInfo((dataDm: IMissingInfoDm) => {
      const viewModel: IMissingInfoVm = {
        title: dataDm.documentName ? `${dataDm.documentName} - ` : null,
        state: dataDm.label,
        data: dataDm.missing,
        closeBtnLabel: dataDm.closeLabel,
        uploadBtnLabel: dataDm.uploadLabel,
        uploadBtnHint: dataDm.uploadHint,
        message: dataDm.message,
        isUploadingFile: dataDm.isUploading,
        qaClose: `dmCloseButton--${dataDm.documentName}`,
        qaUpload: `dmUploadButton--${dataDm.documentName}`
      };
      callback(viewModel);
    });
  }

  closeModal(reason) {
    this.repository.closeModal(reason);
  }
}
