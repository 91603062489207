import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../spinner.service';

@Component({
  selector: 'spinner-component',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  public show = true;

  public constructor(private readonly spinnerService: SpinnerService) {}

  public ngOnInit() {
    this.spinnerService.spinnerObservable.subscribe(val => {
      this.show = val;
    });
  }
}
