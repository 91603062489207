import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import 'brace';
import 'brace/mode/html';
import 'brace/mode/json';
import 'brace/mode/markdown';
import 'brace/theme/dracula';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { isTranslations, Translations } from './translations';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'i18n-type',
  template: ``
})
export class I18nTypeComponent extends FieldType implements OnInit {
  private _unsubscribe$ = new Subject();
  private _translations: Translations;
  protected _value: any;

  get value() {
    return this._value || '';
  }

  constructor(protected languageService: LanguageService) {
    super();
  }

  async ngOnInit() {
    this.languageService.currentLang.pipe(takeUntil(this._unsubscribe$)).subscribe(language => {
      if (!language) {
        return;
      }
      this._translations = this.getValueOrDefault();
      this.getLanguageValue(language);
    });

    this._translations = this.getValueOrDefault();
    this.getLanguageValue(this.languageService.currentLanguage);
  }

  getValueOrDefault() {
    let value = this.formControl?.value;
    if (!value || !isTranslations(value)) {
      value = this.languageService.getDefaultValue(this.key as string, value);
    }
    return value;
  }

  getLanguageValue(language: string) {
    this._value = this._translations[language] || '';
  }

  setLanguageValue(value: any) {
    if (value.value !== this._value) {
      this._value = value.value;
      this._translations[this.languageService.currentLanguage] = value.value;
      this.formControl.patchValue(this._translations);
    }
  }
}
