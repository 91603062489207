import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-card-wrapper-panel',
  template: ` <ng-container #fieldComponent></ng-container> `
})
export class CardWrapperComponent extends FieldWrapper {
  show = true;

  toggle() {
    // this.show = !this.show;
  }
}
