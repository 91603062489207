import { Component, HostListener, OnInit } from '@angular/core';
import { User, UserPreferenceType } from '../domain/user';
import { FirstCharsPipe } from '../../shared/pipes/first-chars.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { CHANGE_PASSWORD_PATH, RESET_PASSWORD_PATH } from '../../app.routes';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AlertModalComponent } from '../../shared/modals/alert-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { PathwayConfigurationService } from '../../auth/services/pathway-configuration.service';
import { UserService } from '../../auth/services/user.service';
import { TranslocoService } from '@ngneat/transloco';
import { atomSetupPageUrl } from '../utils/atom';
import { LocaleService } from '@jump-tech-frontend/app-config';
import { ThemeOption, UserThemeService } from '../user-theme.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  providers: [FirstCharsPipe]
})
export class UserPanelComponent implements OnInit {
  get isUserSubTenant(): boolean {
    return this._isUserSubTenant;
  }

  set isUserSubTenant(value: boolean) {
    this._isUserSubTenant = value;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    return this.isMobileScreen;
  }

  get isMobileScreen(): boolean {
    return window.innerWidth <= 500;
  }

  user: User;
  themeOptions: ThemeOption[];
  userThemePreference: ThemeOption = null;

  private _isUserSubTenant = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private pathwayConfiguration: PathwayConfigurationService,
    private modalService: NgbModal,
    private featureAccessService: AccessService,
    private translocoService: TranslocoService,
    public localeService: LocaleService,
    private themeService: UserThemeService
  ) {}

  ngOnInit(): void {
    this.initUserTheme();
    this.userService.userObservable.subscribe((user: User) => {
      if (user !== null) {
        this.user = user;
        this.checkForSubTenant();
      }
    });
  }

  get isSignedIn() {
    return this.authenticationService.isUserSignedIn;
  }

  signOut() {
    this.authenticationService.signOut().then();
  }

  changePassword() {
    this.router.navigate([CHANGE_PASSWORD_PATH]).then();
  }

  resetPassword() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { size: 'sm' });
    modalRef.componentInstance.config = {
      title: this.translocoService.translate('common.modals.resetPassword.title'),
      messages: [
        this.translocoService.translate('common.modals.resetPassword.messages.message'),
        this.translocoService.translate('common.modals.resetPassword.messages.description')
      ]
    };
    const self = this;
    modalRef.result
      .then(async () => {
        await self.spinnerService.show();
        self.userService.resetPassword().subscribe(
          async () => {
            await self.spinnerService.hide();
            // Now we have to navigate to the reset password page so they can reset everything
            await self.router.navigate([RESET_PASSWORD_PATH], {
              replaceUrl: true,
              queryParams: { id: self.user.userName }
            });
          },
          async error => {
            self.showError(error);
            await self.spinnerService.hide();
          }
        );
      })
      .catch(() => console.log('Reset Password Modal dismissed'));
  }

  isInstaller() {
    return this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.ShowSetupAtom);
  }

  openAtomConfig() {
    window.open(
      atomSetupPageUrl(this.pathwayConfiguration.tenant, environment.name, this.localeService.lang),
      '_blank'
    );
  }

  showError(error: any) {
    let errorMessage;
    if (error.hasOwnProperty('error')) {
      const errorObject = error.error;
      if (errorObject.hasOwnProperty('errorMessage')) {
        errorMessage = errorObject.errorMessage;
      } else {
        errorMessage = errorObject.toString();
      }
    } else {
      errorMessage = error.toString();
    }
    if (!environment.production) {
      console.log(error);
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.title = this.translocoService.translate('common.error');
    modalRef.componentInstance.messages = [errorMessage];
  }

  private checkForSubTenant() {
    this.isUserSubTenant =
      this.user.accessInfo && this.user.accessInfo.subTenants && this.user.accessInfo.subTenants.length > 0;
  }

  initUserTheme() {
    this.themeOptions = this.themeService.getAvailableThemes();
    this.userThemePreference = this.themeService.getActiveTheme();
  }

  switchTheme() {
    this.themeService.setTheme(this.userThemePreference);
  }
}
