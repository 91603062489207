import { Component, Input, OnInit } from '@angular/core';
import { IMissingInfoVm } from '../document-pack.model';
import { DocumentPackMissingInfoPresenter } from './document-pack-missing-info.presenter';

@Component({
  selector: 'app-document-pack-missing-info',
  templateUrl: './document-pack-missing-info.component.html',
  styleUrls: ['./document-pack-missing-info.component.scss']
})
export class DocumentPackMissingInfoComponent implements OnInit {
  @Input() fileInput: HTMLInputElement;
  vm: IMissingInfoVm = null;

  constructor(private presenter: DocumentPackMissingInfoPresenter) {}

  ngOnInit() {
    this.presenter.load(generatedVm => {
      this.vm = generatedVm;
    });
  }

  closeModal(reason) {
    this.presenter.closeModal(reason);
  }

  actionClick() {
    this.fileInput.click();
  }
}
