<ngx-spinner
  [name]="spinnerName"
  bdColor="var(--jds-theme-spinner-bd-rgba)"
  size="large"
  type="line-scale"
  [attr.data-qa]="'spinner'"
></ngx-spinner>

<div class="row project-title-row">
  <div class="col col-10 pl-0">
    <h6 class="project-title mb-0" [attr.data-qa]="'projectTitle'" *ngIf="project">
      <strong>{{ project.type }}</strong>
      | <strong>{{ project.data?.firstName }} {{ project.data?.lastName }}</strong>
    </h6>
    <jui-skeleton-loader *ngIf="!project" glass="true" height="20px" width="350px"></jui-skeleton-loader>
  </div>
  <div class="col col-2 text-right p-0">
    <div ngbDropdown placement="bottom-right" class="d-inline-block">
      <jui-icon
        class="actions-dropdown"
        name="more_vert"
        title="{{ 'Buttons.downloadOptions.title' | transloco }}"
        ngbDropdownToggle
        [attr.data-qa]="'projectDownloadsMenuIcon'"
      ></jui-icon>
      <div ngbDropdownMenu aria-labelledby="actionDropdown" [attr.data-qa]="'actionDropdown'">
        <button (click)="downloadProject(downloadTypes.DATA)" class="dropdown-item" [attr.data-qa]="'downloadProject'">
          {{ 'projectDetail.downloadProject' | transloco }}
        </button>
        <button
          (click)="downloadProject(downloadTypes.GALLERY)"
          class="dropdown-item"
          [attr.data-qa]="'downloadGallery'"
        >
          {{ 'projectDetail.downloadGallery' | transloco }}
        </button>
        <hr class="dropdown-menu__hr" />
        <button (click)="downloadProject(downloadTypes.ALL)" class="dropdown-item" [attr.data-qa]="'downloadAll'">
          {{ 'projectDetail.downloadAll' | transloco }}
        </button>
        <div *ngFor="let download of additionalDownloads">
          <hr class="dropdown-menu__hr" />
          <button (click)="downloadProject(download.type)" class="dropdown-item">
            {{ download.type | removeUnderscore }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card status-card">
  <div class="card-body text-center p-3">
    <h6 class="card-title text-left">
      {{ 'projectDetail.projectProgress' | transloco }}
      <span *ngIf="isArchived" class="label label-info" id="archived-label" [attr.data-qa]="'archivedLabel'"
        >{{ getArchiveStatusLabel() }}<span *ngIf="hasArchiveReason()" [attr.data-qa]="'archiveReason'"
          >: {{ getArchiveReason() }}</span
        ></span
      >
    </h6>
    <div *ngIf="showAuditLogsLoader; else renderProgress" class="text-center">
      <div class="progress-loader">
        <div class="progress-loader__item" *ngFor="let item of defaultStatesNumber | numberToArray; let i = index">
          <jui-skeleton-loader
            class="progress-loader__item-label"
            [width]="progressLabelSize"
            height="8px"
          ></jui-skeleton-loader>
          <jui-skeleton-loader
            color="primary"
            type="circle"
            [height]="progressCircleSize"
            [width]="progressCircleSize"
          ></jui-skeleton-loader>
        </div>
      </div>
    </div>
    <ng-template #renderProgress>
      <progress-indicator
        [projectId]="project.id"
        [states]="projectProgress"
        [currentStatusPosition]="currentStatusPosition"
        [auditLogs]="auditLogs"
        [isArchived]="isArchived"
        (selected)="selected($event)"
      ></progress-indicator>
    </ng-template>
  </div>
</div>

<div class="latest-log">
  <app-project-last-audit-log
    *ngIf="projectConfiguration && project"
    [projectId]="project.id"
    [loading]="showAuditLogsLoader"
    (auditLogs)="onAuditLogs($event)"
  ></app-project-last-audit-log>
</div>

<jui-alert-block *ngIf="offline" color="warning" [attr.data-qa]="'warning'">
  <span>{{ 'common.errors.offline' | transloco }}</span>
</jui-alert-block>

<jui-alert-block *ngIf="infoMessage" clearable="true">
  <span>{{ infoMessage }}</span>
</jui-alert-block>

<div class="row">
  <div *ngIf="!dockTasks" class="col-3 m-0 p-0">
    <!--  reorder list -->
    <div cdkDropList class="reorder-list" (cdkDropListDropped)="drop($event)">
      <div
        class="reorder-list__item"
        *ngFor="let item of userPrefLayoutLeft; let i = index"
        cdkDrag
        cdkDragLockAxis="y"
        cdkDragPreviewContainer="parent"
      >
        <ng-container *ngIf="item === 'summary' && !isArchived">
          <div class="reorder-list__item-content">
            <div class="reorder-list__placeholder" *cdkDragPlaceholder>
              <span class="reorder-list__placeholder-text">
                {{ 'common.move' | transloco }} {{ 'projectDetail.projectSummary' | transloco }}
              </span>
            </div>
            <jui-icon
              class="reorder-list__handle"
              cursor="move"
              name="drag_handle"
              [attr.data-qa]="'sortable-panel--' + i"
              cdkDragHandle
              [openDelay]="1000"
              ngbTooltip="{{ 'common.move' | transloco }} {{ 'projectDetail.projectSummary' | transloco }}"
              placement="right"
              tooltipClass="jt-tooltip--secondary-darker"
            >
            </jui-icon>
            <div class="card pd-card">
              <div class="card-header">
                <div class="task-card-header-title">
                  <div>{{ 'projectDetail.projectSummary' | transloco }}</div>
                </div>
              </div>
              <div *ngIf="!showSummaryLoader" class="card-body pd-card__body">
                <app-project-detail-summary
                  [isPrimaryOwner]="isPrimaryOwner"
                  [project]="project"
                  [configuration]="projectConfiguration.summaryConfiguration || null"
                  (ownerChanged)="updateOwner($event)"
                >
                </app-project-detail-summary>
              </div>
              <div *ngIf="showSummaryLoader" class="card-body pd-card__body text-center">
                <jui-skeleton-loader width="50px" height="12px"></jui-skeleton-loader>
                <jui-skeleton-loader height="32px"></jui-skeleton-loader>
                <jui-skeleton-loader height="12px" width="80%"></jui-skeleton-loader>
                <jui-skeleton-loader height="12px" width="65%"></jui-skeleton-loader>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="item === 'jobs' && jobSummaryItems.length && !isArchived">
          <div class="reorder-list__item-content">
            <div class="reorder-list__placeholder" *cdkDragPlaceholder>
              <span class="reorder-list__placeholder-text">
                {{ 'common.move' | transloco }} {{ 'projectDetail.jobs' | transloco }}
              </span>
            </div>
            <jui-icon
              class="reorder-list__handle"
              cursor="move"
              name="drag_handle"
              [attr.data-qa]="'sortable-panel--' + i"
              cdkDragHandle
              [openDelay]="1000"
              ngbTooltip="{{ 'common.move' | transloco }} {{ 'projectDetail.jobs' | transloco }}"
              placement="right"
              tooltipClass="jt-tooltip--secondary-darker"
            >
            </jui-icon>
            <div class="card pd-card">
              <div class="card-header">
                <div class="task-card-header-title">
                  <div>{{ 'projectDetail.jobs' | transloco }}</div>
                </div>
              </div>
              <div *ngIf="!showJobsLoader" class="card-body pd-card__body">
                <app-jobs [jobs]="jobSummaryItems"></app-jobs>
              </div>
              <div *ngIf="showJobsLoader" class="card-body pd-card__body" [attr.data-qa]="'summaryLoader'">
                <app-jobs-loader></app-jobs-loader>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="item === 'tasks' && project && showTasks">
          <div class="reorder-list__item-content reorder-list__item-content--no-space">
            <div class="reorder-list__placeholder" *cdkDragPlaceholder>
              <span class="reorder-list__placeholder-text">
                {{ 'common.move' | transloco }} {{ 'common.actions' | transloco }}
              </span>
            </div>
            <app-tasks
              [project]="project"
              [qaIndex]="i + 1"
              (stateUpdate)="onDelayWebHook()"
              (infoMessage)="onInfoMessage($event)"
              (goto)="onSwitchTab($event)"
              (delegated)="changeSuccess()"
              (actioned)="onActioned($event)"
            >
            </app-tasks>
          </div>
        </ng-container>

        <ng-container *featureFlag="DOC_PACK_MANAGER_LD_FEATURE_KEY">
          <ng-container *ngIf="item === 'document-pack' && hasDocumentPackDefinition && !isArchived">
            <div class="reorder-list__item-content">
              <div class="reorder-list__placeholder" *cdkDragPlaceholder>
                <span class="reorder-list__placeholder-text">
                  {{ 'common.move' | transloco }} {{ 'documentManager.documentPack' | transloco }}
                </span>
              </div>

              <jui-icon
                class="reorder-list__handle"
                cursor="move"
                name="drag_handle"
                [attr.data-qa]="'sortable-panel--' + i"
                cdkDragHandle
                [openDelay]="1000"
                ngbTooltip="{{ 'common.move' | transloco }} {{ 'documentManager.documentPack' | transloco }}"
                placement="right"
                tooltipClass="jt-tooltip--secondary-darker"
              >
              </jui-icon>

              <div class="card pd-card">
                <div class="card-header">
                  <div class="task-card-header-title">
                    <div>{{ 'documentManager.documentPack' | transloco }}</div>
                  </div>
                </div>
                <app-document-pack-errors></app-document-pack-errors>
                <app-document-pack-messages></app-document-pack-messages>
                <app-document-pack
                  [config]="documentPackConfig"
                  (attachmentUpdated)="saveDocumentToAttachments($event)"
                ></app-document-pack>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *featureFlag="LINKED_PROJECTS_LD_FEATURE_KEY">
          <ng-container *ngIf="item === 'linked-projects' && project">
            <div class="reorder-list__item-content">
              <div class="reorder-list__placeholder" *cdkDragPlaceholder>
                <span class="reorder-list__placeholder-text">
                  {{ 'common.move' | transloco }} {{ 'projectDetail.linkedProjects' | transloco }}
                </span>
              </div>

              <jui-icon
                class="reorder-list__handle"
                cursor="move"
                name="drag_handle"
                [attr.data-qa]="'sortable-panel--' + i"
                cdkDragHandle
                [openDelay]="1000"
                ngbTooltip="{{ 'common.move' | transloco }} {{ 'projectDetail.linkedProjects' | transloco }}"
                placement="right"
                tooltipClass="jt-tooltip--secondary-darker"
              >
              </jui-icon>

              <div class="card pd-card">
                <div class="card-header">
                  <div class="task-card-header-title">
                    <div>{{ 'projectDetail.linkedProjects' | transloco }}</div>
                  </div>
                </div>
                <app-linked-projects-errors></app-linked-projects-errors>
                <app-linked-projects-messages></app-linked-projects-messages>
                <app-linked-projects
                  [projectId]="project.id"
                  [quietReload]="quietReloadLinkedProjects"
                ></app-linked-projects>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <!--  /reorder list -->
  </div>

  <app-tabs-loader
    [class]="(dockTasks ? 'col-12 pl-0' : 'col-9') + ' pr-0'"
    *ngIf="showTabsLoader; else renderTabs"
  ></app-tabs-loader>

  <ng-template #renderTabs>
    <div id="project-detail-tabs" #tabSetContainer [class]="(dockTasks ? 'col-12 pl-0' : 'col-9') + ' pr-0'">
      <ul
        ngbNav
        #layoutTabSet="ngbNav"
        [activeId]="tabLayouts[0]?.tabName"
        (navChange)="onTabChange($event)"
        class="nav-tabs nav-tabs--shadow nav-tabs--active-bord project-detail-tab-list"
      >
        <li *ngIf="dockTasks" [ngbNavItem]="'Tasks'">
          <a ngbNavLink
            ><i class="material-icons">list_alt</i><b>{{ 'projectDetail.tasks' | transloco }}</b></a
          >
          <ng-template ngbNavContent>
            <app-tasks
              [project]="project"
              (stateUpdate)="onDelayWebHook()"
              (infoMessage)="onInfoMessage($event)"
              (goto)="onSwitchTab($event)"
              (delegated)="changeSuccess()"
              (actioned)="onActioned($event)"
            >
            </app-tasks>
          </ng-template>
        </li>

        <li *ngFor="let tabLayout of tabLayouts" [ngbNavItem]="tabLayout.tabName">
          <a ngbNavLink [attr.data-qa]="'tab_' + tabLayout.tabName"
            ><i class="material-icons">{{ tabLayout.tabIcon }}</i
            ><b [attr.data-qa]="tabLayout.tabName">{{ tabLayout.tabName }}</b>
          </a>
          <ng-template ngbNavContent>
            <div class="row" [ngSwitch]="tabLayout.type">
              <div *ngSwitchCase="'gallery'" class="pathway-layout">
                <app-gallery
                  [project]="project"
                  [layoutCollection]="tabLayout.layouts"
                  [useLayoutGroups]="isCommercialMultiJob"
                  [isArchived]="isReadOnly"
                  [isTabReadOnly]="isLayoutReadOnly(tabLayout)"
                >
                </app-gallery>
              </div>
              <div *ngSwitchCase="'attachments'" class="attachments" [attr.data-qa]="'attachments'">
                <app-project-attachments
                  [readOnly]="isReadOnly || isLayoutReadOnly(tabLayout)"
                  [attachments]="project.attachments || []"
                  [customAttachments]="projectConfiguration.customAttachments || []"
                  [project]="project"
                  [tenant]="tenant"
                  (onUpdateAttachments)="updateAttachments($event)"
                >
                </app-project-attachments>
              </div>
              <ng-container *ngSwitchDefault>
                <app-layout
                  [project]="project"
                  [readonly]="isReadOnly"
                  [tabLayout]="tabLayout"
                  (tabLayoutSaved)="onSave($event)"
                  (standardQuoteChanged)="updateData($event)"
                ></app-layout>
              </ng-container>
            </div>
          </ng-template>
        </li>

        <ng-container *featureFlag="HARDWARE_ORDERING_LD_FEATURE_KEY">
          <ng-container *ngIf="canOrderProducts">
            <li [ngbNavItem]="'MyOrders'" class="myOrdersTab">
              <jui-badge *ngIf="isShippingOrder" type="marker" announce="true"></jui-badge>
              <a ngbNavLink
                ><i class="material-icons">local_shipping</i><b>{{ 'projectDetail.myOrders' | transloco }}</b></a
              >
              <ng-template ngbNavContent>
                <pathway-my-orders
                  [products]="productResources"
                  [orders]="orderResources"
                  [driverAddress]="customerAddress"
                  [defaultAddress]="tenantAddress"
                  [projectId]="project.id"
                  [refresh]="refreshOrders"
                ></pathway-my-orders>
              </ng-template>
            </li>
          </ng-container>
        </ng-container>

        <li *ngIf="tabLayouts.length" [ngbNavItem]="'Notes'">
          <a ngbNavLink [attr.data-qa]="'tab_Notes'">
            <i class="material-icons">list_alt</i
            ><b [attr.data-qa]="'Notes'">{{ 'projectDetail.notes' | transloco }}</b>
            <span *ngIf="project.notes?.length" class="badge ml-1">{{ getNotesCount() }}</span>
          </a>
          <ng-template ngbNavContent>
            <app-notes
              [readOnly]="isReadOnly"
              [projectId]="project.id"
              [notes]="project.notes"
              (onNotesUpdate)="updateNotes($event)"
            >
            </app-notes>
          </ng-template>
        </li>
        <li *ngIf="tabLayouts.length" [ngbNavItem]="'Activity'">
          <a ngbNavLink [attr.data-qa]="'tab_Activity'">
            <i class="material-icons">history</i
            ><b [attr.data-qa]="'Activity'">{{ 'projectDetail.activity' | transloco }}</b>
            <sup *ngIf="hasFailureLogs()" class="ml-1"><i class="error-badge material-icons">error</i></sup>
          </a>
          <ng-template ngbNavContent>
            <div class="logs">
              <app-project-audit-logs [projectId]="project.id" [states]="projectStates"></app-project-audit-logs>
            </div>
          </ng-template>
        </li>
      </ul>
      <div class="tab-content--shadow" [ngbNavOutlet]="layoutTabSet"></div>
    </div>
  </ng-template>
</div>
<iframe name="downloadTarget" style="display: none"></iframe>
