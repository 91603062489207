import { IfDefinition } from './expression';

export class Datasource {
  url!: string;
  type!: string;
  tenant!: string;
  transform?: string; // transform into component structure
  fields?: string[]; // list of fields to return
  filters?: IfDefinition; // filters to apply
  projectionList?: string; // child list to use
}
