import { Component } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectAssignment, TenantSettingType } from '../domain/types';
import { ApiService } from '../../../core/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectConfigurationService } from '../../../core/project-configuration.service';
import { AccessService, PathwayFeature } from '../../../auth/services/access.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-project-assignment',
  template: `
    <ngx-spinner
      bdColor="var(--jds-theme-spinner-bd-rgba)"
      size="large"
      [name]="spinnerName"
      type="line-scale"
    ></ngx-spinner>
    <div *ngIf="form" [formGroup]="form">
      <div class="project-assignment-form">
        <div class="project-assignment-form__top">
          <div>
            <label class="project-assignment-form__label">{{
              'userManagement.enableProjectAssignment' | transloco
            }}</label>
            <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="enabled">
              <label
                *ngFor="let opt of [false, true]"
                ngbButtonLabel
                [class]="'project-assignment-form__radio btn btn-sm button-group-spaced ' + getEnabledButtonClass(opt)"
              >
                <input ngbButton type="radio" [value]="opt" #buttonGroupInput />{{
                  opt ? ('common.yes' | transloco) : ('common.no' | transloco)
                }}
              </label>
            </div>
          </div>
          <div>
            <label class="project-assignment-form__label" for="label">{{ 'common.label' | transloco }}</label>
            <input class="form-control form-control-sm" type="text" id="label" formControlName="label" />
          </div>
        </div>
        <div class="project-assignment-form__actions">
          <jui-button
            [disabled]="form.invalid"
            size="sm"
            [color]="form.invalid ? 'low' : 'primary'"
            class="project-assignment-form__action"
            (click)="onSubmit()"
          >
            <span slot="icon" class="material-icons">check</span>
            {{ 'userManagement.saveAssignment' | transloco }}
          </jui-button>
          <ng-container *ngIf="getAssignmentEnabled">
            <jui-button
              size="sm"
              color="secondary"
              class="project-assignment-form__action"
              (click)="addProjectAssignment()"
            >
              <span slot="icon" class="material-icons">playlist_add</span>
              {{ 'userManagement.addRow' | transloco }}
            </jui-button>
          </ng-container>
        </div>
      </div>
      <div *ngIf="getAssignmentEnabled()" class="project-assignment-form__rows" formArrayName="projectAssignment">
        <div
          *ngFor="let projectAssignmentItem of asFormArray(form, 'projectAssignment').controls; index as i"
          [formGroup]="asFormGroup(projectAssignmentItem)"
          class="project-assignment-form__row"
        >
          <div class="project-assignment-form__row-item">
            <label class="project-assignment-form__label">{{ 'common.projectType' | transloco }}</label>
            <app-multi-select
              *ngIf="projectTypes && projectTypes.length"
              [singleSelection]="true"
              [selectedItems]="[projectAssignmentItem.get('projectType').value]"
              [items]="toSelectItems(projectTypes)"
              (itemsChanged)="setFromDropdown($event, 'projectType', i)"
            ></app-multi-select>
          </div>

          <div class="project-assignment-form__row-item">
            <label class="project-assignment-form__label">{{ 'common.team' | transloco }}</label>
            <app-multi-select
              *ngIf="teams && teams.length"
              [singleSelection]="false"
              [selectedItems]="projectAssignmentItem.get('teams').value"
              [items]="teams"
              (itemsChanged)="setArrayFromDropdown($event, 'teams', i)"
            ></app-multi-select>
          </div>

          <div class="project-assignment-form__row-item project-assignment-form__row-item--flex">
            <div class="project-assignment-form__show">
              <label class="project-assignment-form__label">{{ 'userManagement.showOnCreation' | transloco }}</label>
              <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="showOnCreation">
                <label
                  *ngFor="let opt of [false, true]"
                  ngbButtonLabel
                  [class]="'btn btn-xs button-group-spaced ' + getAssignmentButtonClass(projectAssignmentItem, opt)"
                >
                  <input ngbButton type="radio" [value]="opt" />{{
                    opt ? ('common.yes' | transloco) : ('common.no' | transloco)
                  }}
                </label>
              </div>
            </div>
            <div class="project-assignment-form__remove">
              <label class="project-assignment-form__label">&nbsp;</label>
              <jui-button size="xs" color="danger" display="ghost" type="button" (click)="removeProjectAssignment(i)">
                {{ 'common.delete' | transloco }}
              </jui-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./project-assignment.component.scss']
})
export class ProjectAssignmentComponent {
  projectTypes: string[];
  teams: any[];
  defaultProjectAssignment: ProjectAssignment[];
  existingTeamAssignment: ProjectAssignment[];
  form: UntypedFormGroup;
  spinnerName = 'projectAssignmentData';

  constructor(
    private apiService: ApiService,
    private projectConfigurationService: ProjectConfigurationService,
    private spinnerService: NgxSpinnerService,
    private featureAccessService: AccessService,
    private translocoService: TranslocoService
  ) {}

  private async getSettings() {
    await this.spinnerService.show(this.spinnerName);
    const [defaultSettings, projectConfigurationTypes, existingTeamAssignment, teams] = await Promise.all([
      await this.apiService.getTenantSettingsByType(TenantSettingType.DEFAULT_SETTINGS).toPromise(),
      await this.projectConfigurationService.getProjectTypes(PathwayFeature.MainDashboard),
      await this.apiService.getTenantSettingsByType(TenantSettingType.PROJECT_ASSIGNMENT).toPromise(),
      await this.apiService.getTenantSettingsByType(TenantSettingType.TEAM).toPromise()
    ]);
    this.defaultProjectAssignment = defaultSettings[0]['projectAssignment'];
    this.teams = teams.length
      ? teams.map(t => {
          return { id: t.id, name: t.name };
        })
      : [];
    this.projectTypes = projectConfigurationTypes.length
      ? projectConfigurationTypes.reduce((a, pct) => {
          return [...a, pct.projectType];
        }, [])
      : [];
    this.existingTeamAssignment = existingTeamAssignment;
  }

  async ngOnInit() {
    await this.getSettings();
    this.form = await this.getForm();
    await this.spinnerService.hide(this.spinnerName);
  }

  private async getForm() {
    const projectAssignment = new UntypedFormArray([
      new UntypedFormGroup({
        projectType: new UntypedFormControl(null),
        teams: new UntypedFormControl(null),
        showOnCreation: new UntypedFormControl(true)
      })
    ]);

    const hasExistingTeamData = this.existingTeamAssignment && this.existingTeamAssignment.length;
    if (hasExistingTeamData) {
      projectAssignment.removeAt(0);
      for (const assignment of this.existingTeamAssignment) {
        for (const data of assignment.data) {
          projectAssignment.push(
            new UntypedFormGroup({
              projectType: new UntypedFormControl(data.projectType, Validators.required),
              teams: new UntypedFormControl(
                data.teams.map(x => x.id),
                Validators.required
              ),
              showOnCreation: new UntypedFormControl(data.showOnCreation === null ? true : data.showOnCreation)
            })
          );
        }
      }
    }

    return new UntypedFormGroup({
      enabled: new UntypedFormControl(hasExistingTeamData ? this.existingTeamAssignment[0].enabled : false),
      label: new UntypedFormControl(
        hasExistingTeamData ? this.existingTeamAssignment[0].label : this.translocoService.translate('common.team')
      ),
      projectAssignment: projectAssignment
    });
  }

  async onSubmit() {
    this.featureAccessService.resetDataSharing();
    await this.spinnerService.show(this.spinnerName);
    await this.apiService
      .bulkUpdateTenantSettingsByType(TenantSettingType.PROJECT_ASSIGNMENT, this.toProjectAssignment())
      .toPromise();
    await this.spinnerService.hide(this.spinnerName);
  }

  toProjectAssignment() {
    const enabled = this.form.get('enabled').value;

    if (!enabled) {
      return [this.defaultProjectAssignment.filter(data => data.name === 'ProjectAssignment')[0]];
    }

    const projectAssignment: ProjectAssignment[] = [];

    projectAssignment.push({
      id: this.defaultProjectAssignment.filter(data => data.name === 'ProjectAssignment')[0].id,
      name: 'ProjectAssignment',
      enabled: true,
      type: 'projectAssignment',
      label: this.form.get('label') ? this.form.get('label').value : this.translocoService.translate('common.team'),
      data: (this.form.get('projectAssignment') as UntypedFormArray).controls.reduce((d, control) => {
        const projectType = control.get('projectType').value;
        const teamsIds = control.get('teams').value;
        const showOnCreation = control.get('showOnCreation').value;

        const teams = teamsIds.map(x => {
          return this.teams.find(t => t.id === x);
        });

        const index = d.findIndex(x => x.projectType === projectType);

        if (index > -1) {
          for (const team of teams) {
            if (d[index].teams.find(t => t.id === team.id)) {
              continue;
            }
            d[index].teams.push(team);
          }
          return d;
        }

        return [
          ...d,
          {
            projectType: projectType,
            teams: teams,
            showOnCreation: showOnCreation
          }
        ];
      }, [])
    });

    return projectAssignment;
  }

  asFormArray(form: AbstractControl, field: string) {
    return form.get(field) as UntypedFormArray;
  }

  asFormGroup(form: AbstractControl) {
    return form as UntypedFormGroup;
  }

  addProjectAssignment() {
    (this.form.get('projectAssignment') as UntypedFormArray).push(
      new UntypedFormGroup({
        projectType: new UntypedFormControl(null, [Validators.required]),
        teams: new UntypedFormControl(null, [Validators.required]),
        showOnCreation: new UntypedFormControl(true)
      })
    );
  }

  removeProjectAssignment(i: number) {
    (this.form.get('projectAssignment') as UntypedFormArray).removeAt(i);
  }

  getAssignmentEnabled() {
    return this.form.get('enabled').value === true;
  }

  getEnabledButtonClass(opt: boolean) {
    return this.form.get('enabled').value === opt ? 'btn-primary' : 'btn-default';
  }

  toSelectItems(stringArray: string[]) {
    return stringArray.map(item => {
      return { id: item, name: item };
    });
  }

  getAssignmentButtonClass(projectAssignmentItem: AbstractControl, opt) {
    return (projectAssignmentItem as UntypedFormGroup).get('showOnCreation').value === opt
      ? 'btn-primary'
      : 'btn-default';
  }

  async setFromDropdown($event: any[], field: string, index: number) {
    const formArray = this.form.get('projectAssignment') as UntypedFormArray;
    (formArray.controls[index] as UntypedFormGroup).get(field).patchValue($event[0]);
  }

  async setArrayFromDropdown($event: any[], field: string, index: number) {
    const formArray = this.form.get('projectAssignment') as UntypedFormArray;
    (formArray.controls[index] as UntypedFormGroup).get(field).patchValue($event);
  }
}
