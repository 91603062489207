import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { IStandardQuoteSelectVm } from '../../../domain';
import { StandardQuotesRepository } from '../../standard-quotes.repository';

@Injectable()
export class StandardQuoteSelectPresenter {
  constructor(private repository: StandardQuotesRepository, private translocoService: TranslocoService) {}

  async load(projectType: string, callback) {
    const vm: IStandardQuoteSelectVm = {
      titleLabel: await this.translocoService.translate('standardData.quotes.modalTitle'),
      customLabel: await this.translocoService.translate('standardData.quotes.custom'),
      standardQuote: null,
      standardQuotes: []
    };
    await this.repository.listStandardQuotes(results => {
      vm.standardQuotes = results;
    }, projectType);
    callback(vm);
  }
}
