import { Injectable } from '@angular/core';
import { LinkedProjectsRepository } from '../linked-projects.repository';

@Injectable()
export class LinkedProjectsErrorsPresenter {
  constructor(private repository: LinkedProjectsRepository) {}

  load(callback) {
    this.repository.getErrors(dataDm => {
      const viewModel = { ...dataDm };
      callback(viewModel);
    });
  }

  clearErrors() {
    this.repository.clearErrors();
  }

  cleanUp() {
    this.repository.unsubscribe();
  }
}
