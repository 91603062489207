import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import { Router } from '@angular/router';
import { BaseQuestionComponent } from '@jump-tech-frontend/cards';
import { v4 as uuidv4 } from 'uuid';
import { Project } from '../domain/project';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

@Component({
  selector: `crds-availability`,
  template: `
    <div class="card">
      <div class="card-body">
        <ngx-spinner
          bdColor="var(--jds-theme-spinner-bd-rgba)"
          size="large"
          [fullScreen]="false"
          type="line-scale"
          name="calendarSpinner"
        >
        </ngx-spinner>
        <div>
          <h6>{{ question.label }}</h6>
        </div>
        <full-calendar #calendar [options]="calendarConfig"></full-calendar>
      </div>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep .event-cancel {
        display: flex;
        min-height: 1.5rem;
      }

      :host ::ng-deep .preferred {
        background-color: green;
      }

      :host ::ng-deep .event-cancel button {
        font-size: var(--jds-theme-fs);
      }

      :host ::ng-deep .slot-text {
        font-size: var(--jds-theme-fs);
        text-align: center;
        color: var(--jds-theme-color-text-contrast-100);
      }
    `
  ]
})
export class AvailabilityQuestionComponent extends BaseQuestionComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  // TODO: align QuestionBase from Jumptech Cards Lib with @jump-tech-frontend/domain
  @Input() question: any;
  @Input() project?: Project;

  @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent; // the #calendar in the template
  targetArg: string;
  calendarEvents = [];

  calendarConfig: CalendarOptions = {
    ...CalendarConfiguration,
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      resourceTimeGridPlugin,
      resourceTimelinePlugin,
      momentPlugin,
      momentTimezonePlugin
    ],
    schedulerLicenseKey: '0173554956-fcs-1657206500',
    initialView: 'timeGridWeek',
    events: this.calendarEvents,
    eventDidMount: this.handleEventRender.bind(this),
    select: this.addEvent.bind(this),
    eventClick: this.setPreference.bind(this),
    timeZone: moment().tz()
  };
  initialised = false;
  private calendarApi: Calendar;

  constructor(protected router: Router, private translocoService: TranslocoService) {
    super();
  }

  ngOnInit(): void {
    this.targetArg = this.question.targetArg;
    this.calendarConfig.events = this.form.get(this.question.key).value || [];

    if (this.targetArg && !this.form.get(this.targetArg)) {
      this.form.addControl(this.targetArg, new UntypedFormControl(this.project[this.targetArg], [Validators.required]));
    }
  }

  ngAfterViewInit() {
    this.calendarApi = this.calendarComponent.getApi();
    this.initialised = true;
  }

  setPreference(evt) {
    const { event } = evt;
    if (!this.targetArg) {
      return;
    }
    const events = this.form.get(this.question.key).value || [];
    events.forEach(e => {
      if (e.id === event.id) {
        e.preferred = true;
        this.form.get(this.targetArg).setValue(e);
      } else {
        e.preferred = false;
      }
    });
    this.form.get(this.question.key).setValue(events);
    this.calendarConfig.events = events;
  }

  addEvent(model) {
    if (
      this.targetArg ||
      (this.form.get(this.question.key) && (this.form.get(this.question.key).value || []).length > 2)
    ) {
      return;
    }

    const events = this.form.get(this.question.key).value || [];
    events.push({
      id: uuidv4(),
      start: model.start,
      end: model.end,
      allDay: model.allDay
    });
    this.form.get(this.question.key).setValue(events);
    this.calendarConfig.events = events;
  }

  handleEventRender(evt: any) {
    const { event, el } = evt;
    const elements: HTMLCollectionOf<Element> = el.getElementsByClassName('fc-content');
    if (elements.length !== 1) {
      return;
    }

    const that = this;
    const close = document.createElement('button');
    close.setAttribute('type', 'button');
    close.setAttribute('class', 'close');
    close.setAttribute('aria-label', this.translocoService.translate('common.close'));
    close.innerHTML = `<span aria-hidden="true">&times;</span>`;
    close.onclick = function () {
      let events = that.form.get(that.question.key).value || [];
      events = events.filter(x => x.id !== event.id);
      that.form.get(that.question.key).setValue(events);
      that.calendarConfig.events = events;
    };

    const actionWrapper = document.createElement('div');
    actionWrapper.setAttribute('class', 'event-cancel');
    if (!this.targetArg) {
      actionWrapper.appendChild(close);
    }

    const textWrapper = document.createElement('div');
    textWrapper.setAttribute('class', 'slot-text');
    const events = that.form.get(that.question.key).value || [];
    const index = events.findIndex(x => x.id === event.id);
    textWrapper.innerHTML = `<span>Slot ${index + 1}</span>`;

    const wrapper = document.createElement('div');
    wrapper.appendChild(actionWrapper);
    wrapper.appendChild(textWrapper);
    elements[0].replaceWith(wrapper);

    if (this.targetArg && this.form.get(this.targetArg)) {
      const preferred = this.form.get(this.targetArg).value;
      if (preferred && preferred.id && preferred.id === event.id) {
        const classes = wrapper.parentElement.getAttribute('class');
        wrapper.parentElement.setAttribute('class', `${classes} preferred`);
      }
    }
  }
}

export const CalendarConfiguration: any = {
  slotLabelInterval: '01:00',
  slotDuration: '01:00:00',
  dayHeaderFormat: 'ddd DD',
  slotLabelFormat: 'HH',
  editable: true,
  selectable: true,
  slotEventOverlap: false,
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'timeGridWeek'
  },
  weekends: true,
  businessHours: {
    // days of week. an array of zero-based day of week integers (0=Sunday)
    daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Thursday
    startTime: '08:00', // a start time (10am in this example)
    endTime: '18:00' // an end time (6pm in this example)
  },
  slotMinTime: '08:00:00',
  slotMaxTime: '18:00:00',
  firstDay: 1,
  dayMaxEventRows: 3,
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  },
  resourceOrder: 'title'
};
