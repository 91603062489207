function ensureKeys(value: any) {
  if (!value || typeof value === 'string') {
    return value;
  }

  if (value && Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      if (typeof value[i] === 'object') {
        if (!Object.prototype.hasOwnProperty.call(value[i], 'key')) {
          let key = '';
          if (Object.prototype.hasOwnProperty.call(value[i], 'label')) {
            key = value[i].label?.toLowerCase();
          }
          if (Object.prototype.hasOwnProperty.call(value[i], 'groupLabel')) {
            key = value[i].groupLabel?.toLowerCase();
          }
          if (Object.prototype.hasOwnProperty.call(value[i], 'controlType')) {
            key = value[i].controlType?.toLowerCase();
          }
          value[i].key = key;
        }
        this.ensureKeys(value[i]);
      }
    }
    return value;
  }

  if (typeof value === 'object') {
    for (const key of Object.keys(value)) {
      this.ensureKeys(value[key]);
    }
  }

  return value;
}

function sanitise(value: any) {
  if (!value || typeof value === 'string') {
    return value;
  }

  if (value && Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      value[i] = this.sanitise(value[i]);
    }
    value = value.filter(x => !!x);
    return value?.length ? value : undefined;
  }

  if (typeof value === 'object') {
    for (const key of Object.keys(value)) {
      value[key] = this.sanitise(value[key]);
      if (value[key] === undefined || value[key] === null) {
        delete value[key];
      }
    }
    return value && Object.keys(value).length ? value : undefined;
  }

  return value;
}

function replaceStageAndTenant(data: any, env: string, tenant?: string) {
  let dataString = JSON.stringify(data);
  dataString = dataString.replace(/api\.(local|dev|staging)\.jumptech/g, `api.{{stage}}.jumptech`);
  dataString = dataString.replace(/api\.jumptech/g, `api.{{stage}}.jumptech`);
  dataString = dataString.replace(/relay\.(local|dev|staging)\.jumptech/g, `relay.{{stage}}.jumptech`);
  dataString = dataString.replace(/relay\.jumptech/g, `relay.{{stage}}.jumptech`);
  dataString = dataString.replace(/relay-assets-(local|dev|staging|production)/g, 'jumptech-shared-assets');
  dataString = dataString.replace(/\:\/\/\w+-?(\w+)?-relay/g, '://{{tenant}}-relay');
  dataString = dataString.replace(/"tenant":"\w+-?(\w+)?"/g, '"tenant":"{{tenant}}"');
  const config = JSON.parse(dataString);
  if (data.tenant || tenant) {
    config.tenant = tenant ? 'default' : data.tenant;
  }
  return config;
}

function populateStageAndTenant(data: any, stage: string, tenant?: string) {
  let dataString = JSON.stringify(data);

  let regex = /\{\{stage\}\}/g;
  if (stage === 'production') {
    stage = '';
    regex = /\.?\{\{stage\}\}/g;
  }
  dataString = dataString.replace(regex, stage);

  dataString = dataString.replace(/\{\{tenant\}\}/g, tenant);
  const config = JSON.parse(dataString);
  if (data.tenant || tenant) {
    config.tenant = tenant ? 'default' : data.tenant;
  }
  return config;
}

export const ObjectHelper = {
  ensureKeys,
  sanitise,
  replaceStageAndTenant,
  populateStageAndTenant
};
