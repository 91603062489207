import { Directive, Input } from '@angular/core';

@Directive()
export class BasePathwayLayout {
  @Input() form;
  @Input() question: any;

  get show() {
    const showIfQuestionPopulated = this.question.showIf
      ? this.form.get(this.question.showIf.key).value === this.question.showIf.value
      : true;
    return (
      (this.question.showIfPopulated !== true || this.form.get(this.question.key).value) &&
      showIfQuestionPopulated &&
      this.question.show !== false
    );
  }
}
