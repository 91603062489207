import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskModel } from '../../task.model';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent {
  @Input() vm: TaskModel;

  @Output() toggleClick: EventEmitter<string> = new EventEmitter();
  @Output() actionClick: EventEmitter<string> = new EventEmitter();

  toggle(id: string) {
    if (this.toggleClick) {
      this.toggleClick.emit(id);
    }
  }

  action(id: string) {
    this.vm.enabled = false;
    if (this.actionClick) {
      this.actionClick.emit(id);
    }
  }
}
