<div class="assignment-mappings-component-container">
  <div class="assignment-mappings-component" *ngIf="vm" [attr.data-qa]="'assignment-mappings-component'">
    <div class="mappings-header">
      <div class="mappings-filter">
        <input
          id="searchFilter"
          class="w-100 form-control form-control-sm"
          [(ngModel)]="vm.searchFilter"
          (ngModelChange)="searchFilterChange()"
          placeholder="{{ 'common.filter' | transloco }}"
          type="text"
          [attr.data-qa]="'searchFilter'"
        />
      </div>
      <div class="button-list">
        <div class="button-list__btn">
          <jui-button size="sm" color="secondary" display="ghost" [attr.data-qa]="'copy-button'" (click)="copy()">{{
            vm.copyLabel
          }}</jui-button>
        </div>
        <div class="button-list__btn">
          <jui-button size="sm" color="secondary" display="ghost" [attr.data-qa]="'paste-button'" (click)="paste()">{{
            'common.paste' | transloco
          }}</jui-button>
        </div>
        <div class="button-list__btn">
          <jui-button
            [color]="!vm.mappingsValid ? 'low' : 'primary'"
            size="sm"
            [attr.data-qa]="'save-button'"
            (click)="save()"
            [disabled]="!vm.mappingsValid"
          >
            <span slot="icon" class="material-icons">check</span>
            {{ 'common.save' | transloco }}
          </jui-button>
        </div>
      </div>
    </div>
    <ngx-datatable
      #assignmentMappingsTable
      [rows]="vm.filteredMappings"
      [rowClass]="getTestMatchClass(vm.topHitId)"
      class="bootstrap mappings-table"
      columnMode="force"
      reorderable="false"
      swapColumns="false"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [messages]="{ emptyMessage: vm.noDataErrorMessage }"
      (change)="mappingsUpdated()"
      [limit]="vm.pageSize"
      [attr.data-qa]="'assignment-mappings-table'"
    >
      <ngx-datatable-column name="{{ 'autoAssignment.match' | transloco }}" prop="match" maxWidth="122">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-idx="rowIndex">
          <input
            class="form-control form-control-sm postcode-match"
            value="{{ value }}"
            (focusout)="matchChange(row, $event)"
            [attr.data-qa]="'assignment-mapping-postcode-' + idx"
          />
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'common.tenant' | transloco }}" prop="tenantDisplay" width="50">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-idx="rowIndex">
          <app-multi-select
            [singleSelection]="true"
            [selectedItems]="[value]"
            [items]="vm.tenants"
            (itemsChanged)="tenantChange($event, row)"
            [allowSearchFilter]="false"
            [attr.data-qa]="'assignment-mapping-tenant-' + idx"
            [alphaSort]="true"
            appendTo="app-assignment-mappings-component"
          ></app-multi-select>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'autoAssignment.rank' | transloco }}" prop="rank" maxWidth="80">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-idx="rowIndex">
          <input
            class="form-control form-control-sm ranking"
            value="{{ value }}"
            (focusout)="rankChange(row, $event)"
            [attr.data-qa]="'assignment-mapping-rank-' + idx"
          />
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" maxWidth="80" [sortable]="false">
        <ng-template ngx-datatable-header-template>
          <jui-button color="high" size="xs" [attr.data-qa]="'add-button'" (click)="addRow()">
            <span slot="icon" class="material-icons">playlist_add</span>
            {{ 'common.add' | transloco }}
          </jui-button>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row" let-idx="rowIndex">
          <jui-button
            color="danger"
            display="ghost"
            size="xs"
            [attr.data-qa]="'delete-button-' + idx"
            (click)="deleteRow(row)"
          >
            {{ 'common.delete' | transloco }}
          </jui-button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
