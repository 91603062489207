import { HttpClient, HttpResponse } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { pickLanguage } from '@jump-tech-frontend/app-config';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  TranslocoConfig,
  translocoConfig,
  TranslocoLoader,
  TranslocoMissingHandler,
  TranslocoModule,
  TranslocoService
} from '@ngneat/transloco';
import { firstValueFrom, map, merge, reduce } from 'rxjs';
import { TranslocoHttpLoader } from '@jump-tech-frontend/app-config';
import { environment } from '../../environments/environment';
import { PathwayConfigurationService } from '../auth/services/pathway-configuration.service';
import { datadogLogs } from '@datadog/browser-logs';

const DEFAULT_FALLBACK_LANG = 'en-GB';

@Injectable({ providedIn: 'root' })
export class PathwayTranslationsLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  /**
   * Fetches the translation file from the existing API endpoint.
   * @param lang
   */
  getTranslation(lang: string) {
    /** todo -  NOTE: serves the local i18ns from: assets/i18n/en-GB/pathway.json
     *          this does not have the merged _commons i18ns as it would if calling for the actual i18ns so
     *          Cards lib translations will be missing.
     *
     *          todo - see if we can serve from 2 files locally/merge
     *                 to avoid missing keys in this scenario
     * **/
    if (environment.name === 'local') {
      const pathway = new TranslocoHttpLoader(this.http).getTranslation(`${lang}/pathway`);
      const commons = this.getTranslationsFromServer(lang, 'commons');
      return merge(pathway, commons).pipe(
        reduce((pathway, commons) => {
          const x = { ...pathway, _commons: commons };
          return x;
        })
      );
    }

    return this.getTranslationsFromServer(lang, 'pathway');
  }

  getTranslationsFromServer(lang: string, namespace: string) {
    return this.http
      .get(`${environment.apiGetTranslationUrl}/${environment.translationPath}/${lang}/${namespace}`, {
        headers: { skip: 'true' }
      })
      .pipe(map((response: HttpResponse<string>) => response.body && JSON.parse(response.body)));
  }
}

/**
 * Preloads the active (browser) language.
 * @param transloco
 * @param pathwayConfiguration
 */
export const preloadLanguage = (transloco: TranslocoService, pathwayConfiguration: PathwayConfigurationService) => {
  return function () {
    return pathwayConfiguration
      .getConfig()
      .then(config => {
        let allowedLanguageList = [];
        if (config) {
          allowedLanguageList = [...(config?.i18n?.allowedLocales || []), config?.i18n?.locale, DEFAULT_FALLBACK_LANG];
        }
        transloco.setAvailableLangs(allowedLanguageList);

        for (const desiredLang of navigator.languages) {
          const matchedLanguage = pickLanguage(allowedLanguageList, desiredLang, { loose: true });
          if (matchedLanguage) {
            transloco.setActiveLang(matchedLanguage);
            if (!environment.production) {
              console.log('\x1b[32m%s\x1b[0m', `Active LANG: [${desiredLang}]`);
            }
            break;
          } else {
            transloco.setActiveLang(DEFAULT_FALLBACK_LANG);
          }
          if (!environment.production) {
            console.log('\x1b[35m%s\x1b[0m', `Desired LANG: [${desiredLang}]`);
          }
        }
        return firstValueFrom(transloco.load(transloco.getActiveLang()));
      })
      .catch(() => {
        transloco.setAvailableLangs([DEFAULT_FALLBACK_LANG]);
        return firstValueFrom(transloco.load(DEFAULT_FALLBACK_LANG));
      });
  };
};

export class TranslocoMissingKeyHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    datadogLogs.logger.error(`i18n: Missing translation for key: ${key} in language file: ${config['activLang']}`, {
      key,
      lang: config['activeLang']
    });
    return key;
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadLanguage,
      deps: [TranslocoService, PathwayConfigurationService]
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        fallbackLang: [DEFAULT_FALLBACK_LANG],
        reRenderOnLangChange: true,
        failedRetries: 0,
        prodMode: environment.production,
        missingHandler: {
          useFallbackTranslation: true
        }
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: PathwayTranslationsLoader },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: TranslocoMissingKeyHandler }
  ]
})
export class TranslocoRootModule {}
