<div class="standard-quote-select" *ngIf="vm && vm.standardQuotes.length">
  <label class="form-label">{{ vm.titleLabel }}</label>
  <select
    class="form-control mb-2"
    [attr.data-qa]="'standard-quote-select'"
    (change)="changeStandardQuote(dropDownInput.value)"
    #dropDownInput
  >
    <option [value]="undefined" selected="selected">
      {{ vm.customLabel }}
    </option>
    <option *ngFor="let opt of vm.standardQuotes" [value]="opt.id">
      {{ opt.name }}
    </option>
  </select>
</div>
