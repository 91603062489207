import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LinkedProjectsMessagesPresenter } from './linked-projects-messages.presenter';
import { IMessageVm } from '../linked-projects.model';

@Component({
  selector: 'app-linked-projects-messages',
  templateUrl: './linked-projects-messages.component.html',
  styleUrls: ['./linked-projects-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedProjectsMessagesComponent implements OnInit, OnDestroy {
  vm: IMessageVm = { message: null, showLoader: null, qaMessage: null, qaSpinner: null, spinnerName: null };

  constructor(private presenter: LinkedProjectsMessagesPresenter, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.presenter.load(generatedVm => {
      this.vm = generatedVm;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    this.presenter.cleanUp();
  }
}
