import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NewProjectModalComponent } from '../../core/new-project/new-project-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';

@Component({
  selector: 'app-add-project-button',
  template: `
    <div *ngIf="isProjectCreationAllowed()">
      <div *ngIf="full" class="action-button-wrapper">
        <jui-button size="sm" (click)="addProject()" [attr.data-qa]="'newProjectButton'">
          <span slot="icon" class="material-icons">playlist_add</span>
          {{ 'common.newProject' | transloco }}
        </jui-button>
      </div>
      <div *ngIf="!full" class="action-button-wrapper">
        <jui-icon name="playlist_add" size="sm" (click)="addProject()" [titleText]="'common.newProject' | transloco">
        </jui-icon>
      </div>
    </div>
  `,
  styleUrls: ['dashboard-action-buttons.scss']
})
export class AddProjectButtonComponent {
  @Input() full = false;
  constructor(private modalService: NgbModal, private router: Router, private featureAccessService: AccessService) {}

  addProject() {
    const modalRef = this.modalService.open(NewProjectModalComponent, { backdrop: 'static' });
    modalRef.result
      .then((result: { id: string }) => {
        this.router.navigate([`/project/${result.id}`]);
      })
      .catch(() => {});
  }

  isProjectCreationAllowed() {
    return this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.ProjectCreation, true);
  }
}
