import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PostCodeLookupProvider } from '../types';
import { AddressIoPostCodeLookupProvider } from './providers/address-io/AddressIoPostCodeLookupProvider';
import { PostCodeLookupService } from './PostCodeLookupService';
import { DefaultPostCoderPostCodeLookupProvider } from './providers/postcoder/DefaultPostCoderPostCodeLookupProvider';

@Injectable()
export class PostCodeLookupServiceFactory {
  getForCountries(countryCodes: string[], httpClient: HttpClient) {
    const mappedProviders: PostCodeLookupProvider[] = countryCodes
      .map(countryCode => {
        switch (countryCode.toLowerCase()) {
          case 'uk':
            return new AddressIoPostCodeLookupProvider(countryCode.toLowerCase(), httpClient);
          default:
            return new DefaultPostCoderPostCodeLookupProvider(countryCode.toLowerCase(), httpClient);
        }
      })
      .filter(p => typeof p !== 'string');

    return new PostCodeLookupService(mappedProviders);
  }
}
