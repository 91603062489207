import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { CanActivateRoute } from '../../auth/guards/can-activate-route';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AssignmentLabelComponent } from './auto-assignment/components/assignment-label/assignment-label.component';
import { AssignmentMappingsTesterPresenter } from './auto-assignment/components/assignment-mappings-tester/assignment-mappings-tester.presenter';
import { AssignmentMappingsPresenter } from './auto-assignment/components/assignment-mappings/assignment-mappings.presenter';
import { ProjectTypeAssignmentPresenter } from './auto-assignment/components/project-type-assignment/project-type-assignment.presenter';

import { ConfigurationComponent } from './configuration.component';
import { ProjectAssignmentComponent } from '../../admin/user-management/project-assignment/project-assignment.component';
import { DataSharingComponent } from '../../admin/user-management/data-sharing/data-sharing.component';
import { SubTenantsComponent } from '../../admin/user-management/sub-tenants/sub-tenants.component';
import { AutoAssignmentComponent } from './auto-assignment/auto-assignment.component';
import { AutoAssignmentPresenter } from './auto-assignment/auto-assignment.presenter';
import { AssignmentMappingsComponent } from './auto-assignment/components/assignment-mappings/assignment-mappings.component';
import { ProjectTypeAssignmentComponent } from './auto-assignment/components/project-type-assignment/project-type-assignment.component';
import { AlertModalComponent } from './alert-modal.component';
import { AssignmentMappingsTesterComponent } from './auto-assignment/components/assignment-mappings-tester/assignment-mappings-tester.component';
import { PathwayFeature } from '../../auth/services/access.service';
import { ConfigurationPresenter } from './configuration.presenter';
import { StandardQuotesComponent } from './standard-data/quotes/components/list/standard-quotes.component';
import { StandardQuotesPresenter } from './standard-data/quotes/components/list/standard-quotes.presenter';
import { StandardQuoteComponent } from './standard-data/quotes/components/modal/standard-quote.component';
import { StandardQuotePresenter } from './standard-data/quotes/components/modal/standard-quote.presenter';
import { StandardQuoteSelectComponent } from './standard-data/quotes/components/selector/standard-quote-select.component';
import { StandardQuoteSelectPresenter } from './standard-data/quotes/components/selector/standard-quote-select.presenter';
import { StandardDataComponent } from './standard-data/standard-data.component';

export interface Tab {
  path: string;
  component: Type<any>;
  label: string;
  icon: string;
  requiresSuperAdmin?: boolean;
  requiresAdmin?: boolean;
  requiresFeature?: PathwayFeature;
  show?: boolean;
}

export const tabs: Tab[] = [
  {
    path: 'subTenants',
    component: SubTenantsComponent,
    label: 'common.subTenants',
    icon: 'subdirectory_arrow_right',
    requiresSuperAdmin: true,
    requiresFeature: PathwayFeature.DelegationV2
  },
  {
    path: 'dataSharing',
    component: DataSharingComponent,
    label: 'userManagement.dataSharing',
    icon: 'share',
    requiresSuperAdmin: true
  },
  {
    path: 'projectAssignment',
    component: ProjectAssignmentComponent,
    label: 'userManagement.projectAssignment',
    icon: 'swap_horizontal_circle',
    requiresSuperAdmin: true
  },
  {
    path: 'auto-assignment',
    component: AutoAssignmentComponent,
    label: 'autoAssignment.title',
    icon: 'settings',
    requiresAdmin: true,
    requiresFeature: PathwayFeature.AutoAssignment
  },
  {
    path: 'standard-data',
    component: StandardDataComponent,
    label: 'standardData.title',
    icon: 'description',
    requiresAdmin: true
  }
];

const routes: Routes = [
  {
    path: 'configuration',
    component: ConfigurationComponent,
    children: tabs.map(t => ({
      path: t.path,
      component: t.component,
      canActivate: [CanActivateRoute],
      data: {
        allowAdmin: true,
        title: t.label
      }
    }))
  }
];

@NgModule({
  declarations: [
    ConfigurationComponent,
    AutoAssignmentComponent,
    AssignmentMappingsComponent,
    ProjectTypeAssignmentComponent,
    AlertModalComponent,
    AssignmentMappingsTesterComponent,
    AssignmentLabelComponent,
    StandardDataComponent,
    StandardQuotesComponent,
    StandardQuoteComponent,
    StandardQuoteSelectComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslocoModule,
    NgxDaterangepickerMd.forRoot(),
    RouterModule.forChild(routes),
    JumptechCardsLibModule,
    CoreModule,
    CoreComponentsAngularModule
  ],
  exports: [AssignmentLabelComponent, StandardQuoteSelectComponent],
  providers: [
    ConfigurationPresenter,
    AutoAssignmentPresenter,
    AssignmentMappingsPresenter,
    ProjectTypeAssignmentPresenter,
    AssignmentMappingsTesterPresenter,
    StandardQuotesPresenter,
    StandardQuotePresenter,
    StandardQuoteSelectPresenter
  ]
})
export class ConfigurationModule {}
