<div class="working a-fade-in">
  <div class="working__message">
    {{ 'auth.pleaseWait' | transloco }}
  </div>
  <div class="working__loader">
    <crds-inline-spinner
      size="medium"
      colour="var(--jds-theme-color-brand-primary)"
      type="ball-atom"
      name="submitSpinner"
    ></crds-inline-spinner>
  </div>
</div>
