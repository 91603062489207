<div
  class="pathway-menu-item"
  [class.pathway-menu-item-highlight]="isActive"
  [class.pathway-menu-item--disabled]="isDisabled"
  [attr.data-qa]="qaHook"
  *ngIf="hasAccess"
  [routerLink]="routeLink"
  routerLinkActive="pathway-menu-item-active"
  container="body"
  placement="right"
  tooltipClass="jt-tooltip--secondary-darker"
  [ngbTooltip]="isClosed ? menuItem : null"
  (click)="onMenuItemClick()"
>
  <ng-template #menuItem>
    <div class="pathway-menu-item__tooltip">
      {{ label }} <span *ngIf="isBetaFeature" class="label label-info">{{ 'common.beta' | transloco }}</span>
    </div>
  </ng-template>
  <span class="pathway-menu-icon">
    <i class="material-icons">{{ icon }}</i>
  </span>
  <span *ngIf="!isClosed || isSmallScreen" class="pathway-menu-label" [attr.data-qa]="'pathwayMenuLabel'">
    {{ label }} <span *ngIf="isBetaFeature" class="label label-info">{{ 'common.beta' | transloco }}</span>
  </span>
</div>
