import { Injectable } from '@angular/core';
import { DocumentPackRepository } from '../document-pack.repository';
import { DocumentState, IPreviewDm, IPreviewVm } from '../document-pack.model';

@Injectable()
export class DocumentPackPreviewPresenter {
  constructor(private repository: DocumentPackRepository) {}

  load(callback) {
    this.repository.loadPreview((dataDm: IPreviewDm) => {
      const viewModel: IPreviewVm = {
        id: dataDm.id,
        name: dataDm.name,
        title: dataDm.name ? `${dataDm.name} -` : null,
        message: dataDm.message,
        state: dataDm.state,
        stateLabel: dataDm.stateLabel,
        stateTransition: dataDm.stateTransition,
        stateDone: dataDm.state === DocumentState.APPROVED || dataDm.state === DocumentState.COMPLETED,
        cancelLabel: dataDm.cancelLabel,
        actionSubmit: dataDm.actionSubmitLabel,
        actionEnabled: dataDm.actionEnabled,
        actionHidden: dataDm.state === DocumentState.COMPLETED,
        fileName: dataDm.fileName,
        isUploading: dataDm.isUploading,
        showUpload: dataDm.state === DocumentState.AWAITING_APPROVAL || dataDm.state === DocumentState.READY_TO_REVIEW,
        uploadHint: dataDm.uploadHint,
        uploadAlternativeHint: dataDm.uploadAlternativeHint,
        uploadLabel: dataDm.uploadLabel,
        regenerateHint: dataDm.regenerateHint,
        regenerateLabel: dataDm.regenerateLabel,
        showRegenerate: dataDm.canRegenerate,
        isRegenerating: dataDm.isRegenerating,
        isUpdatingDocument:
          dataDm.isRegenerating || dataDm.isUploading || dataDm.isSettingNotRequired || dataDm.isUpdatingState,
        hasMissingInfo: dataDm.hasMissingInfo,
        lastModifiedDate: dataDm.lastModifiedDate,
        lastModifiedLabel: dataDm.lastModifiedLabel,
        showNotRequired: dataDm.canSetNotRequired,
        notRequiredLabel: dataDm.notRequiredLabel,
        isSettingNotRequired: dataDm.isSettingNotRequired,
        qaMainAction: dataDm.actionSubmitLabel
          ? `${dataDm.qaMainAction}--${dataDm.actionSubmitLabel}`
          : dataDm.qaMainAction,
        qaNotRequired: dataDm.qaNotRequired,
        qaCloseModal: dataDm.qaCloseModal,
        qaUpload: dataDm.qaUpload,
        qaRegenerate: dataDm.qaRegenerate
      };
      callback(viewModel);
    });
  }

  closeModal(reason) {
    this.repository.closeModal(reason);
  }

  actionClick(doc) {
    this.repository.previewActionClick(doc);
  }

  setNotRequired(doc) {
    this.repository.setNotRequiredFromPreview(doc).then();
  }

  regenerateDocument(doc) {
    this.repository.regenerate(doc).then();
  }
}
