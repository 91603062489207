import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { IAddressV2 } from '../../domain/address-v2';
import { SearchProviderFactory } from '../../providers/search-provider.factory';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { AddressProvider } from '../../domain/address-provider';
import { AddressSearchPresenter } from './address-search.presenter';
import { AddressSearchResultType, AddressSearchViewModel } from './address-search.vm';

@Component({
  selector: 'jump-tech-frontend-address-search',
  standalone: true,
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
  providers: [SearchProviderFactory, AddressSearchPresenter],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgForOf, ReactiveFormsModule, CoreComponentsAngularModule, NgIf]
})
export class AddressSearchComponent implements OnInit {
  @Input() countryCodes: string[];
  @Input() provider: AddressProvider;
  @Input() label: string;
  @Output() selectedResult: EventEmitter<IAddressV2> = new EventEmitter<IAddressV2>();

  private readonly presenter = inject(AddressSearchPresenter);
  public vm$ = new BehaviorSubject<AddressSearchViewModel | null>(null);

  ngOnInit(): void {
    this.presenter.load(this.vm$, this.countryCodes, this.selectedResult, this.label, this.provider);
  }

  async search(searchTermEvent: string, container?: string): Promise<void> {
    await this.presenter.search(searchTermEvent, container);
  }

  async searchOrRetrieve(id: string, type: AddressSearchResultType = 'Address'): Promise<void> {
    const controlValue = this.vm$.value?.searchTermControl?.value ?? '';
    await this.presenter.searchOrRetrieve(id, controlValue, type);
  }

  async retrieve(id: string): Promise<void> {
    const controlValue = this.vm$.value?.searchTermControl?.value ?? '';
    await this.presenter.retrieve(id, controlValue);
  }
}
