import { LDContext } from 'launchdarkly-js-client-sdk';
import { User } from '../domain/user';
import { getTenantSubdomain } from '../utils/location.helper';

export function toLaunchDarklyUserContext(user: Partial<User> & { id: string }): LDContext {
  const email = user.email?.endsWith('@jumptech.co.uk') ? user.email : undefined;
  const tenant = user.tenant ?? '<unknown>';
  const name = email ? `${user.label} [${tenant}]` : tenant;
  return {
    kind: 'user',
    key: user.id,
    name,
    email,
    isAuthenticated: true,
    isAdmin: user.isAdmin ?? false,
    isSuperAdmin: user.isSuperAdmin ?? false,
    roles: user.roles ?? [],
    tenant
  };
}

export function anonymousLdUserContext(): LDContext {
  return {
    key: 'anonymous-pathway',
    isAuthenticated: false,
    tenant: getTenantSubdomain() ?? ''
  };
}
