import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  private userPreferenceChangeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public userPreferenceChanges$: Observable<any> = this.userPreferenceChangeSubject.asObservable();

  constructor() {}
  setUserPreference(key: string, val: any): void {
    localStorage.setItem(key, JSON.stringify(val));
    this.userPreferenceChangeSubject.next({ key, val });
  }

  getUserPreference(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }
}
