import { Injectable } from '@angular/core';

@Injectable()
export class PrintingService {
  public print(printEl: HTMLElement) {
    let popupWinindow;
    popupWinindow = window.open(
      '',
      '_blank',
      'width=800,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
    );
    popupWinindow.document.open();
    popupWinindow.document.write(
      '<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' +
        printEl.innerHTML +
        '</html>'
    );
    popupWinindow.document.close();
  }
}
