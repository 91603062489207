import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { I18nKeys } from '@jump-tech-frontend/cards';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardsLibTranslationService {
  /*
  initialize i18ns with all the object keys so the compiler can catch
  new keys added to I18nKeys interface (Cards lib)
   */
  i18ns: I18nKeys = {
    add: '',
    address: {
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      latitude: '',
      longitude: '',
      locality: '',
      town: '',
      county: '',
      country: '',
      postCode: ''
    },
    addressNotFound: '',
    back: '',
    clickHere: '',
    closeBtn: '',
    enterAddressManually: '',
    file: '',
    fileTooLarge: '',
    fileTypeNotAllowedError: '',
    isRequired: '',
    lookupAddress: '',
    next: '',
    no: '',
    noResultsFound: '',
    ok: '',
    cancel: '',
    retry: '',
    percentComplete: '',
    required: '',
    selectedAddress: '',
    start: '',
    submit: '',
    upload: '',
    uploadImage: '',
    uploadImageError: '',
    viewDocument: '',
    yes: '',
    yourLocation: ''
  };

  constructor(private translocoService: TranslocoService) {}

  loadTranslations() {
    this.i18ns = this.loadTranslationParts(this.i18ns);
    if (!environment.production) {
      console.log('\x1b[34m%s\x1b[0m', 'Cards Library translations: ', this.i18ns);
    }
    return this.i18ns as unknown as I18nKeys;
  }

  private loadTranslationParts(keys: I18nKeys, path: string = null) {
    Object.keys(keys).map((k: string) => {
      if (typeof keys[k] === 'string') {
        keys[k] = this.translocoService.translate(path ? `_commons.${path}.${k}` : `_commons.${k}`);
      } else {
        if (!keys?.[keys?.[k]]) {
          keys[keys[k]] = {};
        }
        this.loadTranslationParts(keys[k], path ? `${path}.${k}` : k);
      }
    });
    return keys as unknown as I18nKeys;
  }
}
