export const ApiKeys: { [key: string]: string } = {
  ae: 'PCWW4-REG7J-3DNMR-3G3CA',
  au: 'PCWKY-Q9T5L-DJFW5-BYP2C',
  be: 'PCWCH-LB7WK-XBZ7V-MHCVD',
  de: 'PCWM4-C437V-2L487-SRD75',
  dk: 'PCWLH-TS76T-5JDC4-Y8BLZ',
  fr: 'PCWT9-8RNWM-V34JF-PSHSY',
  ie: 'PCWKX-6SMCZ-6ZVHC-T7F5R',
  nl: 'PCWBX-6H267-DDRYM-RS99S',
  nz: 'PCW9C-WVE5Y-K3RY8-ABDY3',
  uk: 'PCWNZ-X257D-QJCWC-M36YM',
  us: 'PCWAU-2WYC9-T8VTZ-58EQJ'
};
