<div class="standard-quotes" *ngIf="vm">
  <h5>{{ vm.titleLabel }}</h5>
  <div class="standard-quotes__component">
    <div class="standard-quotes__header">
      <div class="standard-quotes-filter">
        <input
          id="searchFilter"
          class="w-100 form-control form-control-sm"
          [(ngModel)]="vm.searchFilter"
          (ngModelChange)="searchFilterChange()"
          placeholder="{{ 'common.filter' | transloco }}"
          type="text"
          [attr.data-qa]="'searchFilter'"
        />
      </div>
      <div class="button-list">
        <jui-button
          class="standard-quotes__action-btn"
          size="sm"
          color="primary"
          (click)="add()"
          [attr.data-qa]="'addQuote'"
        >
          {{ 'common.add' | transloco }}
        </jui-button>
      </div>
    </div>
    <ngx-datatable
      #standardQuotesTable
      [rows]="vm.standardQuotes"
      class="bootstrap standard-quotes-table"
      columnMode="force"
      reorderable="false"
      swapColumns="false"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [messages]="{ emptyMessage: vm.noDataErrorMessage }"
      [limit]="vm.pageSize"
    >
      <ngx-datatable-column name="{{ vm.nameLabel }}" prop="name">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ vm.projectTypeLabel }}" prop="projectType">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          {{ value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" maxWidth="150" [sortable]="false">
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="standard-quotes__action-list">
            <jui-icon
              class="standard-quotes__action-btn"
              titleText="{{ 'common.edit' | transloco }}"
              size="xs"
              name="edit"
              (click)="editRow(row)"
              [attr.data-qa]="'editQuote'"
            >
            </jui-icon>
            <jui-button
              class="standard-quotes__action-btn"
              display="ghost"
              titleText="{{ 'common.delete' | transloco }}"
              size="xs"
              color="danger"
              (click)="deleteRow(row)"
              [attr.data-qa]="'deleteQuote'"
            >
              {{ 'common.delete' | transloco }}
            </jui-button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
<ngx-spinner
  bdColor="var(--jds-theme-spinner-bd-rgba)"
  size="large"
  type="line-scale"
  name="standardQuoteListSpinner"
  [attr.data-qa]="'standardQuoteListSpinner'"
></ngx-spinner>
