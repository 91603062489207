import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface RemovedItem {
  parentKey: string;
  childElement: any;
}

@Injectable({ providedIn: 'root' })
export class RemoveRepeatedItemService {
  removedRepeatedItemSubject: Subject<RemovedItem> = new Subject<RemovedItem>();

  pushToRemovedRepeatedItemSubject(removeSection: RemovedItem) {
    this.removedRepeatedItemSubject.next(removeSection);
  }
}
