<div class="my-orders__driver-address" *ngIf="vm.showDriverAddress">
  <div>
    <div class="my-orders__address-row my-orders__address-row--emphasis" [attr.data-qa]="vm.qaDriverAddress_name">
      {{ vm.driverAddress.name }}
    </div>
    <div class="my-orders__address-row" [attr.data-qa]="vm.qaDriverAddress_phone">{{ vm.driverAddress.phone }}</div>
    <div class="my-orders__address-row my-orders__address-row--section" [attr.data-qa]="vm.qaDriverAddress_email">
      {{ vm.driverAddress.email }}
    </div>
    <div class="my-orders__address-row" [attr.data-qa]="vm.qaDriverAddress_line1">{{ vm.driverAddress.line1 }}</div>
    <div class="my-orders__address-row" [attr.data-qa]="vm.qaDriverAddress_line2">{{ vm.driverAddress.line2 }}</div>
    <div class="my-orders__address-row" [attr.data-qa]="vm.qaDriverAddress_locality">
      {{ vm.driverAddress.locality }}
    </div>
    <div class="my-orders__address-row" [attr.data-qa]="vm.qaDriverAddress_town">{{ vm.driverAddress.town }}</div>
    <div class="my-orders__address-row" [attr.data-qa]="vm.qaDriverAddress_county">{{ vm.driverAddress.county }}</div>
    <div class="my-orders__address-row" [attr.data-qa]="vm.qaDriverAddress_country">{{ vm.driverAddress.country }}</div>
    <div class="my-orders__address-row" [attr.data-qa]="vm.qaDriverAddress_postCode">
      {{ vm.driverAddress.postCode }}
    </div>
  </div>
</div>
