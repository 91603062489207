import { AbstractControl, ValidatorFn } from '@angular/forms';

export function mpanValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let valid = false;
    const primes = [3, 5, 7, 13, 17, 19, 23, 29, 31, 37, 41, 43];
    const mpan = control.value.toString();
    let sum = 0;

    if (mpan.length - 1 === primes.length) {
      for (let i = 0; i < primes.length; i++) {
        sum += parseInt(mpan.charAt(i), 10) * primes[i];
      }
      valid = (sum % 11) % 10 === parseInt(mpan.charAt(12), 10);
    }

    return valid ? null : { mpan: { value: control.value } };
  };
}
