import { Component, OnInit } from '@angular/core';

import 'brace';
import 'brace/mode/json';
import 'brace/mode/html';
import 'brace/mode/markdown';
import 'brace/theme/dracula';
import { AceComponent } from 'ngx-ace-wrapper';
import { I18nTypeComponent } from './i18n/i18n-type.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-i18n-code-type',
  template: `
    <form *ngIf="form" [formGroup]="form">
      <div class="i18n-wrapper">
        <div class="i18n-right">
          <ace
            class="i18n-save"
            style="height: 100px"
            [mode]="'json'"
            [theme]="'dracula'"
            [value]="translationString"
            (focusout)="setLanguageValue(aceEditor)"
            #aceEditor
          >
          </ace>
        </div>
      </div>
    </form>
  `,
  styles: [
    `
      .i18n-wrapper {
        display: flex;
      }

      .i18n-icon {
        padding-right: 2px;
      }

      .i18n-wrapper > .i18n-left {
        flex: 1;
      }

      .i18n-wrapper > .i18n-right {
        flex: 1;
      }

      .material-icons {
        cursor: pointer;
      }
    `
  ]
})
export class I18nCodeTypeComponent extends I18nTypeComponent implements OnInit {
  async ngOnInit() {
    await super.ngOnInit();
  }

  get translationString() {
    if (this.value && typeof this.value !== 'string') {
      return JSON.stringify(this.value, null, 2);
    }
    return this.value;
  }

  setLanguageValue(aceEditor: AceComponent) {
    try {
      let value = null;
      if (aceEditor.value && this.translationString !== aceEditor.value) {
        value = JSON.parse(aceEditor.value);
        this.formControl.patchValue(value);
        this.formControl.markAsDirty();
      } else if (!aceEditor.value && this.translationString !== null) {
        value = null;
        this.formControl.patchValue(value);
        this.formControl.markAsDirty();
      }
    } catch (e) {
      // Do nothing as code input is invalid.
    }
  }
}
