import { Injectable } from '@angular/core';
import { Project } from '../../core/domain/project';
import { LayoutComponentVm } from './layout-component.vm';
import { BehaviorSubject } from 'rxjs';
import { DisplayTabLayout } from '../project-detail.component';
import { LayoutComponentColumnsService } from './layout-component-columns.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutComponentPresenter {
  constructor(private layoutComponentColumnsService: LayoutComponentColumnsService) {}

  load(
    args: { project: Project; readonly: boolean; tabLayout: DisplayTabLayout },
    vm: BehaviorSubject<LayoutComponentVm>
  ): void {
    const { project, readonly, tabLayout } = args;
    vm.next({
      layoutColCount$: this.layoutComponentColumnsService.subscribe('project-detail-tabs'),
      project: project,
      readOnly: readonly,
      tabLayout: tabLayout
    });
  }

  unload(vm: BehaviorSubject<LayoutComponentVm>): void {
    vm.next(null);
    this.layoutComponentColumnsService.unsubscribe();
  }
}
