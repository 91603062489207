import { Component, Input, ViewEncapsulation } from '@angular/core';
import { InfoGroup } from '../../domain/info-group';

@Component({
  selector: 'crds-information-groups',
  template: `
    <div
      [class]="'row ' + (isOdd(i) ? 'info-group-row-odd' : 'info-group-row-even')"
      *ngFor="let infoGroup of infoGroups; index as i"
    >
      <div class="col col-3 pt-1">
        <div [class]="'row ' + (isOdd(i) ? 'info-group-row-odd' : 'info-group-row-even')">
          <div [class]="'col p-0 text-center ' + (infoGroup.groupLabel ? 'pt-1' : 'pt-3')">
            <i class="material-icons md-3r">
              {{ infoGroup.groupImage.name }}
            </i>
          </div>
        </div>
        <div
          *ngIf="infoGroup.groupLabel"
          [class]="'row pb-2 ' + (isOdd(i) ? 'info-group-row-odd' : 'info-group-row-even')"
        >
          <div class="col p-0 text-center">
            <div class="info-group-label-active">
              {{ infoGroup.groupLabel }}
            </div>
          </div>
        </div>
      </div>
      <div class="col col-9 pt-2 pb-2">
        {{ infoGroup.groupDescription }}
      </div>
    </div>
  `,
  styleUrls: ['../../sass/relay.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InformationGroupsComponent {
  @Input() infoGroups: InfoGroup[];

  isOdd(i) {
    return i % 2 === 1;
  }
}
