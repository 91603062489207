import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnderscore'
})
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value ? value.replace(/_/g, ' ') : '';
  }
}
