<ng-container *ngIf="vm">
  <div class="pageSummary">
    {{ vm.label }}
  </div>

  <div *ngIf="vm.showProgress" class="progress">
    <div
      class="progress-bar bg-success"
      role="progressbar"
      [style.width]="vm.progress"
      [attr.aria-valuenow]="vm.progress"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</ng-container>
