import { Component, Input } from '@angular/core';
import { SearchResultsInlinePresenter } from './search-results-inline-presenter';
import { ISearchResultVm } from '../search.model';
@Component({
  selector: 'app-search-result-top',
  template: `
    <div
      (click)="viewProject(vm.id)"
      class="search-result-item list-group-item list-group-item-action flex-column align-items-start"
    >
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">{{ vm.name }}</h6>
      </div>
      <p class="search-result-email">{{ vm.email }}</p>
      <p>
        {{ 'projects.ref' | transloco }}: <span class="search-result-data">{{ vm.ref }}</span>
      </p>
      <p>
        {{ 'common.projectType' | transloco }}: <span class="search-result-data">{{ vm.projectType }}</span>
      </p>
      <p>
        {{ 'common.status' | transloco }}: <span class="badge badge-secondary">{{ vm.status }}</span>
      </p>
      <p *ngIf="vm.address">
        {{ 'common.formFields.address' | transloco }}:
        <span class="search-result-data">{{ vm.address }}</span>
      </p>
      <p>
        {{ 'common.phone' | transloco }}: <span class="search-result-data">{{ vm.phone }}</span>
      </p>
    </div>
  `,
  styleUrls: ['../search.component.scss']
})
export class SearchResultInlineComponent {
  @Input() vm: ISearchResultVm;

  constructor(private presenter: SearchResultsInlinePresenter) {}

  public async viewProject(id: string) {
    await this.presenter.viewProject(id);
  }
}
