<div class="addressV2" *ngIf="(vm$ | async) as vm">

  <jump-tech-frontend-address-search
    *ngIf="(manualEdit$ | async) === false"
    [provider]="provider"
    [countryCodes]="vm.countryCodes"
    [label]="vm.label"
    (selectedResult)="addressSelected($event)"
  ></jump-tech-frontend-address-search>

  <div class="addressV2__results">

    <jump-tech-frontend-address-result
      class="addressV2__results__result"
      *ngIf="address && (manualEdit$ | async) === false"
      [address]="address"
      [countryCodes]="vm.countryCodes"
    ></jump-tech-frontend-address-result>

    <jump-tech-frontend-address-editor
      class="addressV2__results__editor"
      *ngIf="(manualEdit$ | async)"
      [address]="address"
      [countryCodes]="vm.countryCodes"
      (addressUpdated)="addressUpdatedManually($event)"
    ></jump-tech-frontend-address-editor>

  </div>
  <jui-button *ngIf="(manualEdit$ | async) === false" size="sm" (click)="toggleEditAddress()">
    {{ vm.enterManually }}
  </jui-button>

</div>
