import { CardActionBase } from './card-action.base';
import { Datasource } from '../datasource';

export class CarouselAction extends CardActionBase {
  type: string;
  targetField?: string;
  mainSlides?: {
    text: string;
    content?: string;
  }[];
  assets: string;
  datasource?: Datasource;
}
