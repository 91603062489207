export declare type SettingsFilter = {
  [key: string]: {
    [operator: string]: string;
  };
};
export interface ListDefinition {
  id: string;
  type: string;
  tenantSpecific: boolean;
  name: string;
  description: string;
  enabled: boolean;
  editScope: 'local' | 'global';
  listType: string;
  properties: ListProperty[];
  global?: ListDefinition;
}
export enum ListPropertyType {
  text = 'text',
  textArea = 'textArea',
  number = 'number',
  boolean = 'boolean',
  list = 'list',
  image = 'image',
  select = 'select',
  multiselect = 'multiselect'
}
export interface ListProperty {
  name: string;
  type: string;
  mandatory?: boolean;
  display?: string;
  properties?: ListProperty[];
  allowOverride?: boolean;
  values?: string[];
}
export interface ListUpdateResult {
  parentGlobal?: any;
  childrenGlobal?: any[];
  parentTenant?: any;
  childrenTenant?: any[];
  tenantDeletes?: any[];
}
export interface List {
  id?: string;
  type: string;
  name?: string;
  description?: string;
  enabled?: boolean;
  filter?: SettingsFilter[];
  global?: any;
  isNew?: boolean;
}
export interface ListItem {
  tenant: string;
  enabled: boolean;
  image: string | null;
  filter?: any[];
  global?: ListItem | null;
  description: string;
  id: string;
  name: string;
  type: string;
  children?: ListItem[];
}
export interface ListInterchangeFormat {
  definition: ListDefinition;
  data: ListItem[];
}
