import {
  AssignmentMapping,
  AssignmentMappingDisplay,
  ProjectAssignment,
  SubTenantLookUp,
  TenantEntry,
  TenantReference
} from './domain';

export const getAllTenants = (
  projectAssignments: ProjectAssignment[]
): {
  tenants: TenantEntry[];
  subTenants: SubTenantLookUp;
} => {
  const tenants: Record<string, { name: string; tenant: string }> = {};
  const subTenants: SubTenantLookUp = {};
  for (const pa of projectAssignments ?? []) {
    for (const t of pa.tenants) {
      if (t.subTenant) {
        tenants[t.subTenant.name] = { name: t.subTenant.name, tenant: t.tenant };
        subTenants[t.subTenant.name] = {
          tenant: t.tenant,
          subTenantId: t.subTenant.id,
          subTenantName: t.subTenant.name
        };
      } else {
        if (!tenants[t.tenant]) {
          tenants[t.tenant] = { name: t.tenant, tenant: t.tenant };
        }
      }
    }
  }
  return {
    tenants: Object.entries(tenants).map(([k, v]) => ({ id: k, name: v.name, tenant: v.tenant })),
    subTenants
  };
};

export const tenantsToList = (tenants: TenantReference[]) => {
  return tenants.map(t => (t.subTenant ? t.subTenant.name : t.tenant)).join(', ');
};

export const cleanMappings = (
  mappings: AssignmentMappingDisplay[],
  subTenants: SubTenantLookUp
): AssignmentMapping[] => {
  if (!mappings) {
    return mappings;
  }
  return mappings.map(m => {
    const mapping = {
      id: m.id,
      tenant: m.tenant,
      targetTenant: m.targetTenant,
      targetSubTenant: m.targetSubTenant,
      match: m.match,
      rank: m.rank
    };
    const subTenant = subTenants[mapping.targetSubTenant?.name];
    if (subTenant) {
      mapping.targetTenant = subTenant.tenant;
      mapping.targetSubTenant = {
        name: subTenant.subTenantName,
        id: subTenant.subTenantId
      };
    } else {
      delete mapping.targetSubTenant;
    }
    return mapping;
  });
};
