import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'util';

@Pipe({
  name: 'sanitiseValue'
})
export class ValueSanitiserPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (isNumber(value)) {
      return value;
    }

    if (typeof value === 'object' && value.hasOwnProperty('lat') && value.hasOwnProperty('lng')) {
      return `${value.lat}, ${value.lng}`;
    }

    if (Array.isArray(value) || (typeof value !== 'string' && typeof value !== 'boolean')) {
      return '';
    }

    switch (value) {
      case 'true':
      case true:
        return 'Yes';
      case 'false':
      case false:
        return 'No';
      default:
        return value.replace(/_/g, ' ');
    }
  }
}
