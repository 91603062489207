import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubTenant } from '../domain/types';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-sub-tenant-modal',
  templateUrl: 'sub-tenant-modal.component.html'
})
export class SubTenantModalComponent implements OnInit {
  @Input() subTenant: SubTenant;
  @Input() mode: 'edit' | 'add' | string;
  form: UntypedFormGroup;

  constructor(public activeModal: NgbActiveModal) {}

  private getFormControlValue(config: string, field: string, defaultValue: string = null) {
    return (
      (this.subTenant &&
        this.subTenant.configuration &&
        this.subTenant.configuration[config] &&
        this.subTenant.configuration[config][field]) ||
      defaultValue
    );
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.subTenant ? this.subTenant.id : uuidv4()),
      type: new UntypedFormControl('subTenant'),
      enabled: new UntypedFormControl(this.subTenant ? this.subTenant.enabled : true),
      reference: new UntypedFormControl(this.subTenant?.reference ?? null),
      srcTenant: new UntypedFormControl(this.subTenant?.srcTenant ?? null),
      configuration: new UntypedFormGroup({
        name: new UntypedFormControl(this.subTenant ? this.subTenant.configuration.name : null, [Validators.required]),
        type: new UntypedFormControl(this.subTenant ? this.subTenant.configuration.type : null),
        tenantConfiguration: new UntypedFormGroup({
          installer: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'installer'), [
            Validators.required
          ]),
          registeredCompanyName: new UntypedFormControl(
            this.getFormControlValue('tenantConfiguration', 'registeredCompanyName')
          ),
          installerContact: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'installerContact')),
          installerSignature: new UntypedFormControl(
            this.getFormControlValue('tenantConfiguration', 'installerSignature')
          ),
          address: new UntypedFormGroup({
            line1: this.getAddressControl('line1'),
            line2: this.getAddressControl('line2'),
            line3: this.getAddressControl('line3'),
            line4: this.getAddressControl('line4'),
            locality: this.getAddressControl('locality'),
            town: this.getAddressControl('town'),
            county: this.getAddressControl('county'),
            country: this.getAddressControl('country'),
            postCode: this.getAddressControl('postCode')
          }),
          installerEmail: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'installerEmail')),
          installerEvhs: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'installerEvhs')),
          installerAccreditationBody: new UntypedFormControl(
            this.getFormControlValue('tenantConfiguration', 'installerAccreditationBody')
          ),
          installerPhone: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'installerPhone')),
          installerWebsite: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'installerWebsite')),
          smsSenderId: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'smsSenderId')),
          smsMessage: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'smsMessage')),
          outgoingCustomerEmail: new UntypedFormControl(
            this.getFormControlValue('tenantConfiguration', 'outgoingCustomerEmail')
          ),
          timezone: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'timezone')),
          locale: new UntypedFormControl(this.getFormControlValue('tenantConfiguration', 'locale')),
          qualifiedSupervisorConfig: new UntypedFormGroup({
            tradeAssociationName: new UntypedFormControl(
              this.getQualifiedSupervisorControlValue('tradeAssociationName')
            ),
            membershipNumber: new UntypedFormControl(this.getQualifiedSupervisorControlValue('membershipNumber')),
            signature: new UntypedFormControl(this.getQualifiedSupervisorControlValue('signature')),
            name: new UntypedFormControl(this.getQualifiedSupervisorControlValue('name'))
          })
        }),
        pathwayConfiguration: new UntypedFormGroup({
          logo: new UntypedFormControl(this.getFormControlValue('pathwayConfiguration', 'logo')),
          styles: new UntypedFormControl(this.getFormControlValue('pathwayConfiguration', 'styles')),
          font: new UntypedFormControl(this.getFormControlValue('pathwayConfiguration', 'font'))
        }),
        relayConfiguration: new UntypedFormGroup({
          customer: new UntypedFormControl(this.getFormControlValue('relayConfiguration', 'customer')),
          customerLogo: new UntypedFormControl(this.getFormControlValue('relayConfiguration', 'customerLogo')),
          styles: new UntypedFormControl(this.getFormControlValue('relayConfiguration', 'styles')),
          font: new UntypedFormControl(this.getFormControlValue('relayConfiguration', 'font'))
        })
      })
    });
  }

  private getQualifiedSupervisorControlValue(field: string, defaultValue: string = null) {
    const qualifiedSupervisorConfig = this.subTenant?.configuration?.tenantConfiguration?.qualifiedSupervisorConfig;
    return qualifiedSupervisorConfig?.[field] || defaultValue;
  }

  private getAddressControl(field: string) {
    const address = this.subTenant?.configuration?.tenantConfiguration?.address;
    return new UntypedFormControl(address?.[field]);
  }

  async setSignature(signature: { ok: boolean; base64?: string }) {
    if (signature.ok) {
      this.form.get('configuration.tenantConfiguration.installerSignature')?.patchValue(signature.base64);
    }
  }

  async setQualifiedSupervisorSignature(signature: { ok: boolean; base64?: string }) {
    if (signature.ok) {
      this.form
        .get('configuration.tenantConfiguration.qualifiedSupervisorConfig.signature')
        ?.patchValue(signature.base64);
    }
  }

  hasSignature() {
    return (
      this.form.get('configuration.tenantConfiguration.installerSignature')?.valid &&
      this.form.get('configuration.tenantConfiguration.installerSignature')?.value?.length > 0
    );
  }

  hasQualifiedSupervisorSignature() {
    return (
      this.form.get('configuration.tenantConfiguration.qualifiedSupervisorConfig.signature')?.valid &&
      this.form.get('configuration.tenantConfiguration.qualifiedSupervisorConfig.signature')?.value?.length > 0
    );
  }

  formToSubTenant() {
    return this.form.getRawValue();
  }

  submit() {
    if (this.isFormInvalid()) {
      return;
    }
    this.activeModal.close(this.formToSubTenant());
  }

  isFieldInvalid(field) {
    return this.form?.controls.configuration.get(field).dirty && this.form?.controls.configuration.get(field).invalid;
  }

  isConfigFieldInvalid(field, configName: string) {
    const formField = this.form.controls.configuration.get(configName).get(field);
    return formField && formField.dirty && formField.invalid;
  }

  isFormInvalid() {
    return this.form.invalid;
  }

  asFormGroup(control: AbstractControl) {
    return control as UntypedFormGroup;
  }

  get qualifiedSupervisorConfigForm() {
    return this.form.controls.configuration
      .get('tenantConfiguration')
      .get('qualifiedSupervisorConfig') as UntypedFormGroup;
  }
}
