import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../../alert.service';
import { AutoAssignmentRepository } from '../../auto-assignment.repository';
import { AssignmentMappingDisplay, IAutoAssignMappingsTestVm } from '../../domain';
import { cleanMappings, getAllTenants } from '../../mapping.utility';

@Injectable()
export class AssignmentMappingsTesterPresenter {
  constructor(
    private repository: AutoAssignmentRepository,
    private translocoService: TranslocoService,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService
  ) {}

  async load(callback) {
    const vm = {
      projectTypes: [],
      projectType: null,
      matches: [],
      match: null,
      subTenants: null,
      noDataErrorMessage: this.translocoService.translate('autoAssignment.noMatches'),
      alert: null
    };

    await this.repository.getAssignments(assignments => {
      vm.projectTypes = assignments.map(pa => ({
        id: pa.projectType,
        name: pa.projectType
      }));
      const allTenants = getAllTenants(assignments);
      vm.subTenants = allTenants.subTenants;
    });

    this.repository.getTestResults(results => {
      vm.matches = results;
      if (results?.length && results.filter(x => x.rank === results[0].rank).length > 1) {
        vm.alert = this.translocoService.translate('autoAssignment.randomSelection');
      }
    });
    callback(vm);
  }

  async test(vm: IAutoAssignMappingsTestVm, mappings: AssignmentMappingDisplay[]): Promise<void> {
    try {
      vm.alert = null;
      await this.spinnerService.show('autoAssignmentSpinner');
      await this.repository.test({
        projectType: vm.projectType,
        match: vm.match.trim(),
        mappings: cleanMappings(mappings, vm.subTenants)
      });
    } catch (err) {
      await this.alertService.alert('common.error', 'autoAssignment.unableToTest');
    } finally {
      await this.spinnerService.hide('autoAssignmentSpinner');
    }
  }

  changeProjectType(vm: IAutoAssignMappingsTestVm, projectType: string) {
    vm.projectType = projectType;
    vm.isValid = !!vm.match && !!vm.projectType;
  }

  changeMatch(vm: IAutoAssignMappingsTestVm, match: string) {
    vm.match = match;
    vm.isValid = !!vm.match && !!vm.projectType;
  }

  clear(vm: IAutoAssignMappingsTestVm) {
    vm.match = undefined;
    vm.projectType = undefined;
    vm.matches = undefined;
    vm.isValid = false;
    vm.alert = null;
    this.repository.clearTestResults();
  }

  cleanup() {
    this.repository.cleanup();
  }
}
