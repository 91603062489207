import { Injectable } from '@angular/core';
import { RelayRepository } from '../../relay.repository';
import { UploadProgressViewModel } from './upload-progress.view.model';

@Injectable()
export class UploadProgressPresenter {
  constructor(private relayRepository: RelayRepository) {}

  load(callback) {
    let vm: UploadProgressViewModel;
    this.relayRepository.getUploadProgress(progress => {
      vm = progress;
      callback(vm);
    });
  }

  clearProgress() {
    this.relayRepository.clearUploadProgress();
  }
}
