import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { InformationCard } from '../../domain/cards/information.card';
import { InfoGroup } from '../../domain/info-group';

@Component({
  selector: `crds-information-form`,
  template: `
    <div class="pb-5">
      <div class="card-wrapper">
        <div class="card">
          <div class="card-body">
            <div class="card-text" [innerHTML]="description" [attr.data-qa]="'description'"></div>
          </div>
        </div>
      </div>
      <div class="card-wrapper-light pt-3">
        <crds-information-groups [infoGroups]="card.infoGroups"></crds-information-groups>
      </div>
    </div>
  `
})
export class InformationFormComponent implements OnChanges {
  @Input() card: InformationCard;
  @Input() form: UntypedFormGroup;
  description: SafeHtml;
  infoGroups: InfoGroup[];

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.description = this.getCardDescription('description');
    this.infoGroups = this.card.infoGroups;
  }

  getCardDescription(descriptionText) {
    const match = (this.card[descriptionText] || '').match(/\{(.+)\}/);
    if (!match) {
      return this.sanitizer.bypassSecurityTrustHtml(this.card[descriptionText]);
    }
    const formRouteParts = match[1].split('.');
    let formControl = this.form.get(formRouteParts[0]);

    if (formControl && formRouteParts.length === 2) {
      formControl = formControl.get(formRouteParts[1]);
    }

    const formValue = formControl && formControl.value ? formControl.value : '';
    const description = this.card[descriptionText].replace(/\{.+\}/, formValue);
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }
}
