import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { ContactLog, ContactLogForm } from './contact-log.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ToasterService, ToastResultConfig } from '../../toast/toast-service';

@Injectable()
export class ContactLogRepository {
  constructor(
    private httpClient: HttpClient,
    private translationService: TranslocoService,
    private toasterService: ToasterService
  ) {}

  saveContactLogAndNotify(projectId: string, contactLog: ContactLog) {
    const saveContactLogPostUrl = `${environment.apiRoot}/projects/${projectId}/contact-logs`;
    const saveTranslations = this.getActionToastTranslations();
    const toastResultConfig: ToastResultConfig = {
      success: {
        type: 'success',
        title: saveTranslations.saveSuccessTitle,
        body: saveTranslations.saveSuccessBody
      },
      failure: {
        type: 'failure',
        title: saveTranslations.saveFailureTitle,
        body: saveTranslations.saveFailureBody
      }
    };
    this.toasterService.observe(this.httpClient.post(saveContactLogPostUrl, contactLog), toastResultConfig);
  }

  getActionToastTranslations() {
    return {
      saveSuccessTitle: this.translationService.translate('contactLog.toast.saveSuccessTitle'),
      saveSuccessBody: this.translationService.translate('contactLog.toast.saveSuccessBody'),
      saveFailureTitle: this.translationService.translate('contactLog.toast.saveFailureTitle'),
      saveFailureBody: this.translationService.translate('contactLog.toast.saveFailureBody')
    };
  }

  getActionTranslations() {
    return {
      addContactLog: this.translationService.translate('contactLog.addContactLog')
    };
  }

  getModalTranslations() {
    return {
      addContactLog: this.translationService.translate('contactLog.addContactLog'),
      cancel: this.translationService.translate('common.cancel'),
      communicationType: this.translationService.translate('contactLog.communicationType'),
      infoImportant: this.translationService.translate('contactLog.infoImportant'),
      infoImportantEdit: this.translationService.translate('contactLog.infoImportantEdit'),
      infoImportantActivity: this.translationService.translate('contactLog.infoImportantActivity'),
      note: this.translationService.translate('contactLog.note'),
      notReached: this.translationService.translate('contactLog.notReached'),
      outcome: this.translationService.translate('contactLog.outcome'),
      email: this.translationService.translate('contactLog.email'),
      phone: this.translationService.translate('contactLog.phone'),
      phoneEmail: this.translationService.translate('contactLog.phoneEmail'),
      pleaseCompleteMsg: this.translationService.translate('contactLog.pleaseCompleteMsg'),
      reached: this.translationService.translate('contactLog.reached'),
      submit: this.translationService.translate('common.submit')
    };
  }

  getContactLogForm(): {
    form: FormGroup<ContactLogForm>;
    formErrors: { [key: string]: string };
  } {
    const form = new FormGroup<ContactLogForm>({
      communicationType: new FormControl(null, [Validators.required]),
      note: new FormControl(null, [Validators.maxLength(1000)]),
      outcome: new FormControl(null, [Validators.required])
    });
    const formErrors = this.getContactLogFormErrors();
    return { form, formErrors };
  }

  private getContactLogFormErrors(): { [key: string]: string } {
    const required = this.translationService.translate('common.isRequired');
    return {
      communicationType: required,
      outcome: required
    };
  }
}
