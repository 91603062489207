export enum BodyOutputType {
  Default,
  TrustedHtml,
  Component
}

export interface IClearWrapper {
  toastId?: string;
  toastContainerId?: number;
}

export interface Toast {
  type: string;
  title?: string;
  body?: any;
  toastId?: string;
  toastContainerId?: number;
  onShowCallback?: OnActionCallback;
  onHideCallback?: OnActionCallback;
  timeout?: number;
  timeoutId?: number | null;
  bodyOutputType?: BodyOutputType;
  clickHandler?: ClickHandler;
  showCloseButton?: boolean;
  closeHtml?: string;
  data?: any;
}

export type ClickHandler = (toast: Toast, isCloseButton?: boolean) => boolean;
export type OnActionCallback = (toast: Toast) => void;
