import { Role, SubTenant, Team } from './domain/types';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserManagementUpdateService {
  private teamUpdateSubject: Subject<{ team: Team; force: boolean }> = new Subject<{ team: Team; force: boolean }>();
  public teamUpdateObservable: Observable<{ team: Team; force: boolean }> = this.teamUpdateSubject.asObservable();
  private roleUpdateSubject: Subject<Role> = new Subject<Role>();
  public roleUpdateObservable: Observable<Role> = this.roleUpdateSubject.asObservable();
  private subTenantUpdateSubject: Subject<SubTenant> = new Subject<SubTenant>();
  public subTenantUpdateObservable: Observable<SubTenant> = this.subTenantUpdateSubject.asObservable();

  public async teamUpdated(team: Team, force = false) {
    await this.teamUpdateSubject.next({ team, force });
  }

  public async subTenantUpdated(subTenant: SubTenant) {
    await this.subTenantUpdateSubject.next(subTenant);
  }

  public async roleUpdated(role: Role) {
    await this.roleUpdateSubject.next(role);
  }
}
