import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { from, isObservable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DropDownElement } from './multi-select/multiple-selection-dropdown.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-multi-select-type',
  template: `
    <form *ngIf="form" [formGroup]="form">
      <lib-app-multi-select
        [items]="items"
        [selectedItems]="selected"
        (itemsChanged)="onValueChange($event)"
        [singleSelection]="false"
      ></lib-app-multi-select>
    </form>
  `,
  styles: [
    `
      :host ::ng-deep .ng-value-icon.right {
        border-left: none !important;
        padding-top: 5px !important;
      }
    `
  ]
})
export class MultiSelectTypeComponent extends FieldType implements OnInit, OnDestroy {
  private _selected: DropDownElement[];
  private _items: DropDownElement[];

  private _unsubscribe$ = new Subject();

  get items(): DropDownElement[] {
    return this._items;
  }

  get selected(): DropDownElement[] {
    return this._selected;
  }

  constructor() {
    super();
  }

  async ngOnInit() {
    if (!isObservable(this.field.templateOptions.options)) {
      this.field.templateOptions.options = from(this.field.templateOptions.options);
    }
    await this.field.templateOptions.options.pipe(takeUntil(this._unsubscribe$)).subscribe(result => {
      if (result?.length) {
        this._items = result.map(value => {
          return {
            id: value,
            name: value
          };
        });
      }
    });

    this._selected = this.formControl.value;

    this.formControl.valueChanges
      .pipe(debounceTime(1000))
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(value => {
        this._selected = value;
      });
  }

  onValueChange(value: string[]) {
    setTimeout(() => {
      try {
        this.formControl.patchValue(value);
        this.form.markAsTouched();
        this.form.updateValueAndValidity();
      } catch (e) {
        // Do nothing as code input is invalid.
      }
    }, 0);
  }

  ngOnDestroy() {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }
}
