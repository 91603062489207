import { Component } from '@angular/core';

@Component({
  selector: 'app-sign-in-success',
  templateUrl: './sign-in-success.component.html',
  styleUrls: ['../../login.component.scss']
})
export class SignInSuccessComponent {
  constructor() {}
}
