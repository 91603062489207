export interface AsyncAction {
  id: string;
  name: string;
  enabled: boolean;
  eventName: string;
  executeIf?: IfDefinition;
  asyncActions?: AsyncAction[];
  global: AsyncAction;
  scope: string;
  tenantSpecific?: boolean;
}

export interface IfCheck {
  $and?: (IfDefinition | IfCheck)[];
  $or?: (IfDefinition | IfCheck)[];
  $eq?: string;
  $ne?: string;
  $gt?: string;
  $lt?: string;
  $gte?: string;
  $lte?: string;
  $mr?: string;
  $nmr?: string;
  $in?: string;
  $nin?: string;
}

export interface IfDefinition {
  [key: string]: string | IfCheck | (IfCheck | IfDefinition)[];
}

export enum SettingPropertyType {
  text = 'text',
  textArea = 'textArea',
  number = 'number',
  boolean = 'boolean',
  list = 'list',
  object = 'object',
  code = 'code',
  image = 'image',
  select = 'select',
  multiselect = 'multiselect',
  asyncActionList = 'asyncActionList',
  array = 'array',
  html = 'html',
  json = 'json'
}

export interface SettingProperty {
  name: string;
  type: SettingPropertyType;
  isArray?: boolean;
  mandatory?: boolean;
  display?: string;
  properties?: SettingProperty[];
  allowOverride?: boolean;
  values?: string[];
  enum?: string;
  editor?: string;
  linked?: string;
}
