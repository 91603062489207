import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { Card, ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';

@Component({
  selector: `crds-default-form`,
  template: `
    <div class="card-wrapper">
      <div class="card">
        <div class="card-body">
          <p class="card-text" [innerHTML]="description" [attr.data-qa]="'description'"></p>
          <crds-image-asset [images]="images" [key]="'default'"></crds-image-asset>
        </div>
      </div>
    </div>
    <div class="form-wrapper">
      <form [formGroup]="form.get(formKey)">
        <crds-questions [form]="form.get(formKey)" [card]="card" [i18ns]="i18ns"></crds-questions>
      </form>
    </div>
    <div *ngIf="description2" class="card-wrapper">
      <div class="card">
        <div class="card-body">
          <p class="card-text" [innerHTML]="description2"></p>
        </div>
      </div>
    </div>
  `
})
export class DefaultFormComponent implements OnChanges {
  @Input() card: Card<null>;
  @Input() form: UntypedFormGroup;
  @Input() formKey: string;
  @Input() i18ns: I18nKeys;
  action: any;
  description: SafeHtml;
  description2: SafeHtml;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;

  constructor(private imageAssetFactory: ImageAssetFactory) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.action = this.card.action;
    this.images = this.imageAssetFactory.create(this.card.image, this.form);
    this.description = this.getCardDescription(this.card.description);
    this.description2 = this.getCardDescription(this.card.description2);
  }

  getCardDescription(description: string) {
    return description ? this._replaceVariables(description, this.form) : null;
  }

  private _getReplaceableMatches(description: string) {
    return description.match(/\{[A-Za-z0-9\.]+\}/g);
  }

  private _replaceVariables(description: string, form: UntypedFormGroup): string {
    const matches = this._getReplaceableMatches(description);
    const defaultReplacement = '';
    if (matches) {
      matches.forEach(match => {
        let formValue;
        const matchParts = match.replace(/[{}]/g, '').split('.');
        formValue = this._getFormPartValue(form, matchParts);
        description = description.replace(match, formValue || defaultReplacement);
      });
    }
    return description;
  }

  private _getFormPartValue(form: UntypedFormGroup, matchParts: string[], idx = 0) {
    const formPart = form.get(matchParts[idx]);
    return typeof formPart.value === 'object'
      ? this._getFormPartValue(formPart as UntypedFormGroup, matchParts, idx + 1)
      : formPart.value;
  }
}
