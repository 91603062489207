export enum Platform {
  PATHWAY = 'PATHWAY',
  PORTAL = 'PORTAL'
}

export interface UserPoolConfig {
  pool: string;
  environment: string;
  tenant: string;
  platform?: Platform;
}
