import { UntypedFormGroup } from '@angular/forms';
import { NguCarouselConfig } from '@ngu/carousel';
import { Card } from '../../domain/card';
import { CarouselAction } from '../../domain/card-actions/carousel.action';
import { ListQuestionComponent } from '../../core/list-question.component';
import { Datasource } from '../../domain/datasource';
import { BehaviorSubject } from 'rxjs';

export abstract class AbstractCarouselComponent extends ListQuestionComponent {
  form: UntypedFormGroup;
  card: Card<CarouselAction>;
  public currentMainSlide: number;
  public carouselMainTile: NguCarouselConfig;
  action: CarouselAction;
  get initialised() {
    return this.initialisedSubject.getValue();
  }
  initialisedSubject = new BehaviorSubject<boolean>(false);

  abstract mapData(result: any[], datasource: Datasource);

  abstract updateMainSlide(currentSlide: number);

  abstract updateTargetField(currentSlide?: number);

  getCurrentMainSlideName() {
    return this.card.action.mainSlides[this.currentMainSlide]
      ? this.card.action.mainSlides[this.currentMainSlide].text
      : '';
  }

  setCurrentMainSlide(slide: number) {
    this.currentMainSlide = slide;
  }

  protected getCurrentMainSlideFromForm() {
    const currentFormValue = this.form.get(this.action.targetField).value;
    if (!currentFormValue) {
      return 0;
    }
    const currentMainSlideValue = currentFormValue.split(' - ')[0];
    const slideIndex = this.card.action.mainSlides.findIndex(mainSlide => mainSlide.text === currentMainSlideValue);
    return slideIndex > -1 ? slideIndex : 0;
  }
}
