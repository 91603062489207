<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.form" [formGroup]="vm.form">
    <label *ngIf="vm.label" class="likert__label" [attr.data-qa]="vm.key">
      {{ vm.label }}
      <span *ngIf="vm.required"> *</span>
    </label>
    <div class="likert__scale-wrap">
      <jui-likert size="md" (valueChange)="selectionChanged($event.detail)">
        <span slot="bottom-hint">{{ vm.bottomHint }}</span>
        <span slot="top-hint">{{ vm.topHint }}</span>
      </jui-likert>
    </div>
  </ng-container>
</ng-container>
