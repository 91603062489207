import { DestroyRef, Injector } from '@angular/core';

export function getMockDestroyRefInjector() {
  return Injector.create({
    providers: [{ provide: DestroyRef, useValue: MockDestroyRef() }]
  });
}

function MockDestroyRef() {
  return new (class extends DestroyRef {
    onDestroy(): () => void {
      return () => undefined;
    }
  })();
}
