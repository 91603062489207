import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { Project } from '../../core/domain/project';
import { ProjectCardLayout } from '../../core/domain/project-card-layout';
import { IfDefinition } from '../../core/utils/filter';
import { ToastResultConfig } from '../../toast/toast-service';

export enum TaskStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export enum RequiredDataType {
  data = 'data',
  attachment = 'attachment',
  ownerTeam = 'ownerTeam',
  resource = 'resource'
}

interface Confirmation {
  options?: Record<string, unknown>;
  layout?: ProjectCardLayout;
  type?: string;
  alert?: string;
  submitLabel?: string;
  updateResource?: string;
}

export interface TaskShowIf {
  taskId: string;
  key: string;
  value: string | boolean;
}

export interface RequiredData {
  key?: string;
  filter?: IfDefinition;
  label: string;
  type?: RequiredDataType;
  resource?: string;
  goto?: string;
}

export interface TaskForm {
  task: Task;
  model: TaskModel;
}

export interface Task {
  id: string;
  tenant?: string;
  filter?: IfDefinition[];
  show?: TaskShowIf | IfDefinition | boolean;
  status?: TaskStatus;
  questions?: QuestionBase<unknown>[];
  position?: string;
  dynamicSave?: boolean;
  toast?: ToastResultConfig;

  requireDocumentCompletion?: boolean;
}

export interface ProjectTask extends Task {
  task: string;
  description?: string;
  taskOptions?: string[];
  due_by?: Date;
  assigned_to?: string;
  updated_on?: Date;
  updated_by?: string;
  created_on?: Date;
  created_by?: string;
  removable?: boolean;
}

export interface ProjectAction extends Task {
  label: string;
  action?: string;
  transition?: string;
  confirmation?: Confirmation;
  requiredResource?: string;
  data?: Record<string, unknown>;
  showResult?: boolean;
  dataIncludes?: string[];
  requiredData?: RequiredData[];
  infoMessage?: string;
}

export interface TasksWorkflow {
  tenant?: string;
  id?: string;
  description?: string;
  actions?: ProjectAction[];
  tasks?: ProjectTask[];
}

export interface TaskModel {
  id: string;
  type: string;
  typeClass: string;
  label?: string;
  description?: string;
  showQuestions: boolean;
  questions: QuestionBase<unknown>[];
  form: UntypedFormGroup;
  project: Project;
  visible: boolean;
  enabled?: boolean;
  showTick?: boolean;
  isComplete?: boolean;
  showCheck?: boolean;
  actionable?: boolean;
  actionLabel?: string;
  activeTheme?: string;
}

export interface TasksModel {
  isArchived: boolean;
  label: string;
  tasks: TaskModel[];
  shouldRender: boolean;
  showLoader: boolean;
  activeTheme: string;
}

export interface ITasksVm {
  label: string;
  tasks: TaskModel[];
  shouldRender: boolean;
  showLoader: boolean;
  showTasks: boolean;
  isArchived?: boolean;
  activeTheme: string;
}

export interface InvalidItemsModel {
  title: string;
  messages: string[];
  activeTheme?: string;
}

export interface ActionModel {
  options: Record<string, unknown>;
  projectAction: ProjectAction;
  project: Project;
  resources: Record<string, unknown>;
  activeTheme?: string;
}

export interface ErrorModel {
  title: string;
  errors: string[];
  activeTheme?: string;
}

export interface ActionSubmitted {
  transitionType: string;
  data?: Record<string, unknown>;
}
