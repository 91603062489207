import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { RelayFormModule } from '@jump-tech-frontend/relay/shared';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { CoreModule } from '../core/core.module';
import { DelegationService } from '../core/delegate/delegation.service';
import { DelegationModule } from '../dashboards/delegation/delegation.module';
import { ConfigurationModule } from '../feature-modules/configuration/configuration.module';
import { SharedModule } from '../shared/shared.module';
import { ProjectAuditLogsComponent } from './audit-logs/project-audit-logs.component';
import { ProjectLastAuditLogComponent } from './audit-logs/project-last-audit-log.component';
import { DocumentPackDocumentRowComponent } from './document-pack/components/document-pack-document-row/document-pack-document-row.component';
import { DocumentPackDocumentStageComponent } from './document-pack/components/document-pack-document-stage/document-pack-document-stage.component';
import { DocumentPackMainActionComponent } from './document-pack/components/document-pack-main-action/document-pack-main-action.component';
import { DocumentPackErrorsComponent } from './document-pack/document-pack-errors/document-pack-errors.component';
import { DocumentPackErrorsPresenter } from './document-pack/document-pack-errors/document-pack-errors.presenter';
import { DocumentPackMessagesComponent } from './document-pack/document-pack-messages/document-pack-messages.component';
import { DocumentPackMessagesPresenter } from './document-pack/document-pack-messages/document-pack-messages.presenter';
import { DocumentPackMissingInfoComponent } from './document-pack/document-pack-missing-info/document-pack-missing-info.component';
import { DocumentPackMissingInfoPresenter } from './document-pack/document-pack-missing-info/document-pack-missing-info.presenter';
import { DocumentPackPreviewComponent } from './document-pack/document-pack-preview/document-pack-preview.component';
import { DocumentPackPreviewPresenter } from './document-pack/document-pack-preview/document-pack-preview.presenter';
import { DocumentPackProgressComponent } from './document-pack/document-pack-progress/document-pack-progress.component';
import { DocumentPackProgressPresenter } from './document-pack/document-pack-progress/document-pack-progress.presenter';
import { DocumentPackResponseComponent } from './document-pack/document-pack-response/document-pack-response.component';
import { DocumentPackResponsePresenter } from './document-pack/document-pack-response/document-pack-response.presenter';
import { DocumentPackComponent } from './document-pack/document-pack.component';
import { DocumentPackPresenter } from './document-pack/document-pack.presenter';
import { GalleryComponent } from './gallery/gallery.component';
import { InstallationImagesModalComponent } from './installation-images-modal.component';
import { JobsComponent } from './jobs/jobs.component';
import { JobsLoaderComponent } from './jobs/jobs-loader.component';
import { NewProjectTaskComponent } from './new-project-task.component';
import { NotesModule } from './notes/notes.module';
import { ProjectAttachmentsComponent } from './project-attachments/project-attachments.component';
import { ProjectDetailComponent } from './project-detail.component';
import { RelayComponent } from './relay/relay.component';
import { StatusTransitionModalComponent } from './status-transition-modal.component';
import { StatusTransitionResultModalComponent } from './status-transition-result-modal.component';
import { ProjectDetailSummaryComponent } from './summary/project-detail-summary.component';
import { ActionModalComponent } from './tasks/components/action/action-modal.component';
import { ActionComponent } from './tasks/components/action/action.component';
import { ActionPresenter } from './tasks/components/action/action.presenter';
import { ErrorModalComponent } from './tasks/components/error/error-modal.component';
import { ErrorComponent } from './tasks/components/error/error.component';
import { ErrorPresenter } from './tasks/components/error/error.presenter';
import { InvalidItemsModalComponent } from './tasks/components/invalid-items/invalid-items-modal.component';
import { InvalidItemsComponent } from './tasks/components/invalid-items/invalid-items.component';
import { InvalidItemsPresenter } from './tasks/components/invalid-items/invalid-items.presenter';
import { TaskComponent } from './tasks/components/task/task.component';
import { TasksComponent } from './tasks/tasks.component';
import { TasksPresenter } from './tasks/tasks.presenter';
import { LinkedProjectsComponent } from './linked-projects/linked-projects.component';
import { LinkedProjectsPresenter } from './linked-projects/linked-projects.presenter';
import { LinkedProjectsErrorsComponent } from './linked-projects/linked-projects-errors/linked-projects-errors.component';
import { LinkedProjectsErrorsPresenter } from './linked-projects/linked-projects-errors/linked-projects-errors.presenter';
import { LayoutComponent } from './layouts/layout.component';
import { RouterModule } from '@angular/router';
import { LinkedProjectsMessagesComponent } from './linked-projects/linked-projects-messages/linked-projects-messages.component';
import { LinkedProjectsMessagesPresenter } from './linked-projects/linked-projects-messages/linked-projects-messages.presenter';
import { LinkedProjectsSummaryComponent } from './linked-projects/components/linked-projects-summary/linked-projects-summary.component';
import { DocumentPackLoaderComponent } from './document-pack/components/document-pack-loader/document-pack-loader';
import { TabsLoaderComponent } from './tabs-loader.component';
import { ContactLogActionComponent } from '../feature-modules/contact-logging/contact-log-action/contact-log-action.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyOrdersPresenter } from './my-orders/my-orders.presenter';
import { MyOrdersCustomAddressComponent } from './my-orders/components/custom-address/custom-address.component';
import { MyOrdersDriverAddressComponent } from './my-orders/components/driver-address/driver-address.component';
import { MyOrdersProductsSelectionComponent } from './my-orders/components/products-selection/products-selection.component';
import { MyOrdersAddressTypeSelectionComponent } from './my-orders/components/address-type-selection/address-type-selection.component';
import { MyOrdersViewComponent } from './my-orders/order-view/order-view.component';
import { MyOrdersViewPresenter } from './my-orders/order-view/order-view.presenter';
import { MyOrdersSummaryComponent } from './my-orders/components/order-summary/order-summary.component';
import { MyOrdersFulfilmentComponent } from './my-orders/components/order-fulfilment/order-fulfilment.component';
import { MyOrdersHistoryComponent } from './my-orders/order-history/order-history.component';
import { MyOrdersHistoryPresenter } from './my-orders/order-history/order-history.presenter';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

@NgModule({
  declarations: [
    ProjectAttachmentsComponent,
    ProjectDetailSummaryComponent,
    InstallationImagesModalComponent,
    NewProjectTaskComponent,
    ProjectDetailComponent,
    StatusTransitionModalComponent,
    StatusTransitionResultModalComponent,
    RelayComponent,
    JobsComponent,
    JobsLoaderComponent,
    GalleryComponent,
    DocumentPackComponent,
    DocumentPackErrorsComponent,
    DocumentPackMessagesComponent,
    DocumentPackProgressComponent,
    DocumentPackPreviewComponent,
    DocumentPackMainActionComponent,
    DocumentPackDocumentRowComponent,
    DocumentPackMissingInfoComponent,
    DocumentPackResponseComponent,
    DocumentPackDocumentStageComponent,
    DocumentPackResponseComponent,
    DocumentPackLoaderComponent,
    TaskComponent,
    TasksComponent,
    InvalidItemsComponent,
    InvalidItemsModalComponent,
    ActionComponent,
    ActionModalComponent,
    ErrorComponent,
    ErrorModalComponent,
    LinkedProjectsComponent,
    LinkedProjectsErrorsComponent,
    LinkedProjectsMessagesComponent,
    LinkedProjectsSummaryComponent,
    LayoutComponent,
    TabsLoaderComponent,
    MyOrdersComponent,
    MyOrdersCustomAddressComponent,
    MyOrdersDriverAddressComponent,
    MyOrdersProductsSelectionComponent,
    MyOrdersAddressTypeSelectionComponent,
    MyOrdersViewComponent,
    MyOrdersSummaryComponent,
    MyOrdersFulfilmentComponent,
    MyOrdersHistoryComponent
  ],
  imports: [
    NgxImageZoomModule,
    NotesModule,
    CoreModule,
    SharedModule,
    {
      ngModule: JumptechCardsLibModule
    },
    RelayFormModule,
    PinchZoomModule,
    DelegationModule,
    DragDropModule,
    NgbCollapseModule,
    ConfigurationModule,
    RouterModule,
    ContactLogActionComponent,
    ProjectAuditLogsComponent,
    ProjectLastAuditLogComponent,
    NgOptionHighlightModule
  ],
  providers: [
    DelegationService,
    DocumentPackPresenter,
    DocumentPackErrorsPresenter,
    DocumentPackMessagesPresenter,
    DocumentPackProgressPresenter,
    DocumentPackPreviewPresenter,
    DocumentPackMissingInfoPresenter,
    DocumentPackResponsePresenter,
    TasksPresenter,
    InvalidItemsPresenter,
    ActionPresenter,
    ErrorPresenter,
    LinkedProjectsPresenter,
    LinkedProjectsErrorsPresenter,
    LinkedProjectsMessagesPresenter,
    MyOrdersPresenter,
    MyOrdersViewPresenter,
    MyOrdersHistoryPresenter
  ]
})
export class ProjectDetailModule {}
