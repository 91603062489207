import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import 'moment/min/moment-with-locales';
import * as moment from 'moment-timezone';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  weekdayShortNames;
  monthShortNames;
  monthFullNames;

  constructor(private dateParser: NgbDateParserFormatter) {
    super();
    this.weekdayShortNames = moment.weekdaysMin();
    // put sunday as the last day
    this.weekdayShortNames.push(this.weekdayShortNames.shift());
    this.monthShortNames = moment.monthsShort();
    this.monthFullNames = moment.months();
  }

  getWeekdayShortName(weekday: number): string {
    return this.weekdayShortNames[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.monthShortNames[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.monthFullNames[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return this.dateParser.format(date);
  }

  getWeekdayLabel(weekday: number): string {
    return this.weekdayShortNames[weekday - 1];
  }
}
