import { Component } from '@angular/core';

@Component({
  selector: 'app-standard-data',
  template: ` <div class="card standard-data-card">
    <div class="card-body standard-data">
      <div class="standard-data-quote block">
        <app-standard-quotes-component></app-standard-quotes-component>
      </div>
    </div>
  </div>`,
  styleUrls: ['./standard-data.scss']
})
export class StandardDataComponent {}
