<div class="my-orders__order-row" [formGroup]="vm.orderForm" [attr.data-qa]="vm.qaAddressTypeControl">
  <div class="form-check form-check-inline">
    <input
      class="form-check-input"
      type="radio"
      name="selectedAddressType"
      id="selectedAddressCustom"
      [value]="vm.addressTypeCustom"
      formControlName="selectedAddressType"
    />
    <label class="form-check-label" for="selectedAddressCustom">{{ vm.labelCustomAddress }}</label>
  </div>
  <div class="form-check form-check-inline">
    <input
      class="form-check-input"
      type="radio"
      name="selectedAddressType"
      id="selectedAddressDriver"
      [value]="vm.addressTypeDriver"
      formControlName="selectedAddressType"
    />
    <label class="form-check-label" for="selectedAddressDriver">{{ vm.labelDriverAddress }}</label>
  </div>
</div>
