import { Injectable } from '@angular/core';
import { LinkedProjectsRepository } from './linked-projects.repository';
import {
  CHARACTERS_SHOWN_WHEN_DELETING,
  CHARACTERS_SHOWN_WHEN_LONG_NAME_END,
  CHARACTERS_SHOWN_WHEN_LONG_NAME_START,
  LONG_NAME_MIN,
  IGroupDm,
  IGroupVm,
  ILinkedProjectsDm,
  ILinkedProjectsVm,
  ISummaryDataDm,
  ISummaryDataVm
} from './linked-projects.model';

@Injectable()
export class LinkedProjectsPresenter {
  constructor(private repository: LinkedProjectsRepository) {}

  load(callback, projectId, quietReload) {
    this.repository.load(
      (dataDm: ILinkedProjectsDm) => {
        const viewModel: ILinkedProjectsVm = {
          addNewGroupText: `${dataDm.createLabel} ${dataDm.groupType}:`,
          unlinkButton: dataDm.unlinkButton,
          addressLabel: `${dataDm.addressLabel}:`,
          statusLabel: `${dataDm.statusLabel}:`,
          customerLabel: `${dataDm.customerLabel}:`,
          deleteGroupTooltip: `${dataDm.deleteGroupTooltip} ${dataDm.groupType}?`,
          searchPlaceholder: `${dataDm.searchPlaceholder} ${dataDm.groupType}`,
          groupListLabel: `${dataDm.groupType}:`,
          showGroupList: !dataDm.linkedData?.length && !dataDm.showMainSpinner,
          showMainSpinner: dataDm.showMainSpinner,
          screenReaderLoadingLabel: dataDm.srLoading,
          groupList: dataDm.groups.map((group: IGroupDm): IGroupVm => {
            return {
              displayName: group.isDeleting
                ? `${group.name.substring(0, CHARACTERS_SHOWN_WHEN_DELETING)}...`
                : group.name,
              displayNameShort: group.isDeleting
                ? `${group.name.substring(0, CHARACTERS_SHOWN_WHEN_DELETING)}...`
                : `${group.name.substring(0, CHARACTERS_SHOWN_WHEN_LONG_NAME_START)}...${group.name.slice(
                    -CHARACTERS_SHOWN_WHEN_LONG_NAME_END
                  )}`,
              isLongGroupName: group.name.length >= LONG_NAME_MIN,
              id: group.id,
              linkId: group.linkId,
              qaGroupDeleteButton: `linkedProjectsDeleteBtn--${group.name}`,
              canDelete: group.deletable,
              deleteInProgress: group.isDeleting ?? false
            };
          }),
          groupId: dataDm.groupId,
          linkedGroupNamePrefix:
            dataDm.linkedData.length > 1
              ? `${dataDm.groupNameMultiPrefix} ${dataDm.groupType}:`
              : `${dataDm.groupNamePrefix} ${dataDm.groupType}:`,
          linkedGroupName: dataDm.projectGroups.length > 0 ? dataDm.projectGroups[0].name : null,
          linkedData: dataDm.linkedData.map((link: ISummaryDataDm): ISummaryDataVm => {
            return {
              name: `${link.firstName} ${link.lastName}`,
              projectId: link.id,
              location: link.line1 && link.town && link.postCode ? `${link.line1}, ${link.town}, ${link.postCode}` : '',
              status: link.statusName,
              projectType: link.type,
              isCurrentProject: link.id === projectId,
              qaLinkSummaryItem: `linkedProjectsSummary--${link.id}`,
              qaUnlinkButton: `linkedProjectsUnlinkBtn--${link.id}`
            };
          }),
          numberInGroup: dataDm.linkedData.length,
          useScroll: dataDm.linkedData.length > 3,
          showLinks: dataDm.linkedData.length > 0,
          isCreatingGroup: dataDm.isCreatingGroup,
          createNewLoadingText: dataDm.createNewLoadingText,
          deleteInProgressText: dataDm.deleteInProgressText,
          qaMainSpinner: 'linkedProjectsSpinner',
          qaGroupSelectControl: 'linkedProjectsSelectControl',
          inProgress: dataDm.inProgress || dataDm.isCreatingGroup,
          form: dataDm.form
        };
        callback(viewModel);
      },
      projectId,
      quietReload
    );
  }

  async linkProjectToGroup(groupId: string) {
    await this.repository.linkToGroup(groupId);
  }

  async createGroup(name: string) {
    await this.repository.createGroup(name);
  }

  unlinkProjectFromGroup(groupId, projectId) {
    this.repository.unlinkFromGroup(groupId, projectId).then();
  }

  goToProject(link) {
    this.repository.goToProject(link);
  }

  deleteGroup(groupId: string) {
    this.repository.deleteGroup(groupId).then();
  }

  cleanUp() {
    this.repository.unsubscribe();
  }
}
