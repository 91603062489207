import { Injectable } from '@angular/core';
import { RxDatabase } from 'rxdb/dist/types/types';
import { addRxPlugin, createRxDatabase } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { isDevMode } from '@angular/core';
(window as any).global = window;

@Injectable()
export class PersistenceService {
  private database: RxDatabase;

  public async persist(id: string, data: any): Promise<void> {
    try {
      await this.load();
      await this.database.relay.upsert({
        id: id,
        data: data
      });
    } catch (err) {
      console.log(err);
    }
  }

  public async get(id: string): Promise<any> {
    try {
      await this.load();
      const item = await this.database.relay
        .findOne({
          selector: {
            id: {
              $eq: id
            }
          }
        })
        .exec();
      return item?.data || {};
    } catch (err) {
      console.log(err);
    }
  }

  public async remove(id): Promise<void> {
    try {
      await this.load();
      const item = await this.database.relay
        .findOne({
          selector: {
            id: {
              $eq: id
            }
          }
        })
        .exec();
      await item?.remove();
    } catch (err) {
      console.log(err);
    }
  }

  private async load() {
    if (!this.database) {
      if (isDevMode()) {
        await Promise.all([import('rxdb/plugins/dev-mode').then(module => addRxPlugin(module.RxDBDevModePlugin))]);
      }
      this.database = await createRxDatabase({
        name: 'jumptech-relay',
        storage: getRxStorageDexie()
      });

      const relaySchema = {
        title: 'relay schema',
        version: 0,
        primaryKey: 'id',
        type: 'object',
        properties: {
          id: {
            type: 'string',
            maxLength: 100
          },
          data: {
            type: 'object'
          }
        },
        required: ['data']
      };

      await this.database.addCollections({
        relay: {
          schema: relaySchema
        }
      });
    }
  }
}
