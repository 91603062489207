import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { I18nKeys } from '../../domain/i18n-keys';
import { ApiService } from '../api.service';
import { ScrollService } from '../scroll.service';
import { AbstractDropdownQuestionComponent } from './abstract-dropdown-question.component';

@Component({
  selector: `crds-question-dropdown`,
  template: `
    <div [formGroup]="form" *ngIf="optionsPopulated && show">
      <div *ngIf="!initialised">
        <label *ngIf="question.label" class="form-label" [attr.for]="question.key">
          {{ question.label }}
          <span *ngIf="question.required"> *</span>
        </label>
        <div class="form-control">
          <crds-inline-spinner
            [name]="question.key"
            [qaHook]="question.loadingQaHook"
            [type]="question.loadingType"
            [message]="question.loadingMessage"
            [colour]="question.loadingColour"
            [bgColour]="question.loadingBgColour"
            [textColour]="question.loadingTextColour"
            [center]="question.loadingCenter"
          >
          </crds-inline-spinner>
        </div>
      </div>
      <div *ngIf="initialised">
        <label *ngIf="question.label" class="form-label" [attr.for]="question.key"
          >{{ question.label }}
          <span *ngIf="question.required"> *</span>
          <crds-form-error
            *ngIf="isInvalid"
            [question]="question"
            [message]="question.errorMessage"
            [i18ns]="i18ns"
          ></crds-form-error>
        </label>
        <select
          [id]="question.key"
          [formControlName]="question.key"
          class="form-control mb-2"
          [attr.data-qa]="question.key"
        >
          <option *ngIf="question.placeholder" [ngValue]="undefined" selected="selected" disabled>
            {{ question.placeholder }}
          </option>
          <option
            *ngFor="let opt of currentFilter.length ? currentFilter : question.options"
            [value]="opt.key"
            [selected]="getSelected(opt.key)"
            #dropDownInput
          >
            {{ opt.value }}
          </option>
        </select>

        <!-- QUESTION HINT -->
        <crds-question-hint [question]="question"></crds-question-hint>

        <p *ngIf="alert">
          <jui-alert-block iconName="info" size="sm">
            {{ question.alert[form.get(question.key).value] }}
          </jui-alert-block>
        </p>
      </div>
    </div>
  `
})
export class DropdownQuestionComponent extends AbstractDropdownQuestionComponent implements OnChanges {
  @Input() i18ns: I18nKeys;
  constructor(scrollService: ScrollService, apiService: ApiService, spinnerService: NgxSpinnerService) {
    super(scrollService, apiService, spinnerService);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    /**
     * Make sure that we check for null, undefined or a value that is not valid
     * If one of these then we should set the value to undefined so it can optionally trigger the placeholder <option>
     */
    if (this.question.datasource) {
      if (!this.question.datasource.transform) {
        this.question.datasource.transform =
          '[].{ text: join(`" - "`, [parent.name, name]), value: join(`" - "`, [parent.name, name]) }';
      }
      await this.spinnerService.show(this.question.key);
      await this.getData(this.question.datasource);
      this.render();
      await this.spinnerService.hide(this.question.key);
    } else {
      this.render();
    }
  }

  render() {
    const options = this.question.options || [];
    const optionsIsString = typeof options === 'string'; // Using project type data - loaded post render in pathway
    if (
      !this.value ||
      (!optionsIsString &&
        !(this.question.options || []).some(o => o.key && this.value && o.key.toString() === this.value.toString()))
    ) {
      this.value = undefined;
    }
    this.initialised = true;

    this.currentFilter = Object.assign([], this.question.options);
    this.scrollService.scrollObservable.subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
    this.setSubscriptions();
  }

  getSelected(optKey: string) {
    const key = this.value;
    return key && optKey && key === optKey ? 'selected' : null;
  }

  get optionsPopulated(): boolean {
    if (this.question.datasource) {
      return true;
    }
    return this.question.options && this.question.options.length > 0;
  }

  override get isInvalid() {
    return (this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid) ?? true;
  }

  override get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  get alert() {
    return this.question.alert && this.question.alert[this.form?.get(this.question.key)?.value];
  }

  /**
   * DO NOT REMOVE - used dynamically by subscriptions "this[subscription.trigger]()"
   * Filter out question options based on the value of other form fields
   */
  private filterQuestionOptions() {
    this.currentFilter = Object.assign([], this.question.options).filter(option => {
      if (!option.filter) {
        return true;
      }
      return this.checkIfExpression(option.filter, this.form);
    });
    const patchValue = this.currentFilter[this.getOptionValueIdx()].value;
    setTimeout(() => {
      this.form?.get(this.question.key)?.patchValue(patchValue);
    }, 100);
  }

  /**
   * If there is currently a value in this dropdown then return its index within the
   * current question.options
   * @return number valueIdx
   */
  private getOptionValueIdx() {
    const currentFormValue = this.form?.get(this.question.key)?.value;
    if (currentFormValue) {
      const idx = this.currentFilter.findIndex(function (filter) {
        return filter.value === currentFormValue;
      });
      return idx > -1 ? idx : 0;
    }
    return 0;
  }

  private setSubscriptions() {
    if (this.question.subscriptions) {
      this.question.subscriptions.forEach(subscription => {
        this[subscription.trigger]();
        this.form.controls[subscription.key].valueChanges.subscribe(() => {
          this[subscription.trigger]();
        });
      });
    }
  }
}
