import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MyOrdersViewPresenter } from './order-view.presenter';
import { OrderViewVm } from '../my-orders.model';

@Component({
  selector: 'pathway-my-orders-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyOrdersViewComponent implements OnChanges {
  @Input() refresh = false;
  vm$: BehaviorSubject<OrderViewVm> = new BehaviorSubject<OrderViewVm>(null);

  constructor(private presenter: MyOrdersViewPresenter) {}

  ngOnChanges(): void {
    this.presenter.load(this.vm$);
  }
  cancelOrder(vm): void {
    this.presenter.cancelOrder(vm.id);
  }
}
