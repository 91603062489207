import { GenericTransformer } from './GenericTransformer';
import { Injectable } from '@angular/core';
import { Address } from '../domain/address';

interface GoogleAddressElement {
  shortName: string;
  types: string[];
}

interface GoogleAddressResult {
  address_components: GoogleAddressElement[];
  formatted_address: string;
  geometry: {
    location: {
      lat(): number;
      lng(): number;
    };
  };
}

@Injectable()
export class GoogleAddressToAddressTransformer implements GenericTransformer<GoogleAddressResult, Address> {
  googleAddressResult: GoogleAddressResult;

  transform(from: GoogleAddressResult): Address {
    this.googleAddressResult = from;

    const address = {
      line1: this._getAddressResultShortName('street_number'),
      line2: this._getAddressResultShortName('route'),
      subLocality: this._getAddressResultShortName('sublocality'),
      locality: this._getAddressResultShortName('locality'),
      town: this._getAddressResultShortName('postal_town'),
      stateRegion: this._getAddressResultShortName('administrative_area_level_1'),
      county: this._getAddressResultShortName('administrative_area_level_2'),
      postCode: this._getAddressResultShortName('postal_code'),
      country: this._getAddressResultShortName('country'),
      latitude: this._getAddressResultLatLng().lat,
      longitude: this._getAddressResultLatLng().lng
    };
    return new Address(address);
  }

  _getAddressResultShortName(type) {
    const filteredAddressComponents = this.googleAddressResult.address_components.filter(
      addressComponent => addressComponent.types.indexOf(type) !== -1
    );

    return filteredAddressComponents.length ? filteredAddressComponents[0]['short_name'] : '';
  }

  _getAddressResultLatLng() {
    return {
      lat: this.googleAddressResult ? this.googleAddressResult.geometry.location.lat() : null,
      lng: this.googleAddressResult ? this.googleAddressResult.geometry.location.lng() : null
    };
  }
}
