<ng-container *ngIf="(modalPending$ | async) && (vm$ | async) as vm">
  <ng-container *ngIf="vm.form" [formGroup]="vm.form">
    <div class="question-modal">
      <div class="question-modal__body">
        <h4 *ngIf="vm.label" class="question-modal__title" [attr.for]="vm.key" [attr.data-qa]="vm.key">
          {{ vm.label }}
        </h4>
        <p>
          {{ vm.content }}
        </p>
        <p>
          {{ vm.hint }}
        </p>
        <jui-button size="lg" expand="true" (click)="acceptModal()">
          {{ vm.actionLabel }}
        </jui-button>
      </div>
    </div>
  </ng-container>
</ng-container>
