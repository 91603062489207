import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';
import { BaseQuestionComponent } from '../question.component';

@Component({
  selector: 'crds-question-general',
  template: `
    <div *ngIf="show && form" [formGroup]="form">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key"
        >{{ question.label }}
        <span *ngIf="question.required"> *</span>
        <crds-form-error
          *ngIf="isInvalid"
          [question]="question"
          [message]="question.errorMessage"
          [i18ns]="i18ns"
        ></crds-form-error>
      </label>
      <div
        *ngIf="question.value"
        class="text-question"
        [innerHtml]="question.value"
        [attr.data-qa]="question.value"
      ></div>
      <!-- QUESTION HINT -->
      <crds-question-hint [question]="question"></crds-question-hint>

      <!-- QUESTION IMAGE -->
      <div class="image-wrapper">
        <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
      </div>
    </div>
  `
})
export class GeneralQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;

  constructor(private imageAssetFactory: ImageAssetFactory) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.images = this.imageAssetFactory.create(this.question.image, this.form);
    if (this.question.key && this.question.value && !this.form?.get(this.question.key)?.value) {
      this.form?.get(this.question.key)?.patchValue(this.question.value);
    }
  }

  get isInvalid() {
    return (
      this.form?.get(this.question.key) &&
      this.form?.get(this.question.key)?.touched &&
      this.form?.get(this.question.key)?.invalid
    );
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }
}
