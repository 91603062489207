import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoPresenter } from './logo.presenter';
import { LogoViewModel } from './logo.view.model';

@Component({
  selector: 'logo-component',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() showClose = false;
  vm: LogoViewModel;

  constructor(private logoPresenter: LogoPresenter, private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.logoPresenter.load(vm => {
      this.vm = vm;
      this.vm.showClose = this.showClose;
    });
  }

  close() {
    this.activeModal.dismiss();
  }
}
