import { Injectable } from '@angular/core';
import { RelayRepository } from '../../relay.repository';
import { LogoViewModel } from './logo.view.model';

@Injectable()
export class LogoPresenter {
  constructor(private relayRepository: RelayRepository) {}

  load(callback) {
    let vm: LogoViewModel;
    this.relayRepository.getCustomLogo(logo => {
      vm = {
        customLogo: logo,
        showClose: false
      };
      callback(vm);
    });
  }
}
