import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Card } from '../domain/card';
import { CardActionBase } from '../domain/card-actions/card-action.base';
import { I18nKeys } from '../domain/i18n-keys';
import { IAddressV2 } from '@jump-tech-frontend/address-lookup-v2';

@Component({
  selector: 'crds-questions',
  template: `
    <div *ngFor="let question of questions">
      <div [ngSwitch]="question.controlType" [formGroup]="form" class="form-group">
        <crds-question-postcode *ngSwitchCase="'PostcodeQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-postcode>
        <crds-question-v2-postcode
          *ngSwitchCase="'PostcodeV2Question'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-v2-postcode>
        <ng-container *ngSwitchCase="'MapsMarkerQuestion'">
          <crds-question-google-maps-marker [question]="question" [form]="form" [i18ns]="i18ns">
          </crds-question-google-maps-marker>
        </ng-container>
        <crds-question-dropdown *ngSwitchCase="'DropdownQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-dropdown>
        <crds-question-multi-dropdown-value
          *ngSwitchCase="'MultiDropdownQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-multi-dropdown-value>
        <crds-address-list-question-dropdown
          *ngSwitchCase="'AddressDropdownQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-address-list-question-dropdown>
        <crds-question-general *ngSwitchCase="'GeneralText'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-general>
        <crds-question-image-upload
          *ngSwitchCase="'ImageUploadQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-image-upload>
        <crds-question-multi-image-upload
          *ngSwitchCase="'MultiImageUploadQuestion'"
          [question]="question"
          [form]="form"
          [attr.data-qa]="'multiImageUpload'"
          [i18ns]="i18ns"
        >
        </crds-question-multi-image-upload>
        <crds-question-ocr-image-upload
          *ngSwitchCase="'OcrImageUploadQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-ocr-image-upload>
        <crds-question-signature
          *ngSwitchCase="'SignatureQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-signature>
        <crds-question-textbox *ngSwitchCase="'TextboxQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-textbox>
        <crds-question-textarea *ngSwitchCase="'TextareaQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-textarea>
        <crds-question-toggle *ngSwitchCase="'ToggleQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-toggle>
        <crds-question-button-group
          *ngSwitchCase="'ButtonGroupQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-button-group>
        <crds-question-checkbox-button-group
          *ngSwitchCase="'CheckboxButtonGroupQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-checkbox-button-group>
        <crds-question-doc-viewer
          *ngSwitchCase="'DocumentViewerQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        ></crds-question-doc-viewer>
        <crds-question-file-upload
          *ngSwitchCase="'FileUploadQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-file-upload>
        <crds-question-image-or-file-upload
          *ngSwitchCase="'ImageOrFileUploadQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-image-or-file-upload>
        <crds-question-google-maps-multi-marker
          *ngSwitchCase="'MultiMarkerQuestion'"
          [question]="question"
          [form]="form"
        >
        </crds-question-google-maps-multi-marker>
        <crds-question-date
          *ngSwitchCase="'DateQuestionComponent'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        ></crds-question-date>
        <crds-question-hint *ngSwitchCase="'QuestionHint'" [question]="question"></crds-question-hint>
        <crds-question-html *ngSwitchCase="'HtmlQuestionComponent'" [question]="question"></crds-question-html>
        <crds-google-places-autocomplete
          *ngSwitchCase="'GooglePlacesAddress'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        ></crds-google-places-autocomplete>
        <jump-tech-frontend-address-lookup-v2
          *ngSwitchCase="'AddressTypeaheadQuestion'"
          [countryCodes]="question.countryCodes"
          [label]="question.label"
          [provider]="question.provider"
          [address]="form.get(question.key).value"
          (addressUpdate)="updateAddress($event, question.key)"
        >
        </jump-tech-frontend-address-lookup-v2>
      </div>
    </div>
  `,
  styleUrls: ['../sass/relay.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionsComponent implements OnChanges {
  @Input() card: Card<CardActionBase>;
  @Input() form: UntypedFormGroup;
  @Input() i18ns: I18nKeys;

  questions: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.questions = Object.assign([], this.card.questions);
  }

  updateAddress($event: { address: IAddressV2; location: string }, key: string) {
    this.form.get(key)?.setValue($event.address);
    this.form.get('installationLatLng')?.setValue($event.location);
  }
}
