/*
 * Public API Surface of app-config
 */

export * from './lib/environment/enviroment-token';
export * from './lib/environment/environment.service';

export * from './lib/app-version-token';
export * from './lib/root-config/types';

export * from './lib/errors/bugsnag-token';
export * from './lib/errors/bugsnag.client';
export * from './lib/errors/global-error.handler';
export * from './lib/guards/component-can-deacitvate';
export * from './lib/i18n/language-parser';
export * from './lib/i18n/transloco-http.loader';
export * from './lib/i18n/locale.service';

export * from './lib/i18n/commons/translations.token';
export * from './lib/i18n/commons/commons-translation.service';
export * from './lib/i18n/commons/i18n-keys';
