<div
  class="spinner-inline {{ customCssClasses }}"
  [class.spinner-inline--center]="center"
  [style.backgroundColor]="bgColour"
>
  <span class="spinner-inline__msg" [style.color]="textColour">{{ message }}&nbsp;</span>
  <div class="spinner-inline__spinner">
    <ngx-spinner
      [name]="name"
      [size]="size"
      bdColor="rgba(255,255,255, 0.8)"
      [color]="colour"
      [fullScreen]="false"
      [type]="type"
      [attr.data-qa]="qaHook"
    >
    </ngx-spinner>
  </div>
</div>
