<div class="search-bar-wrapper">
  <form *ngIf="isSignedIn" [formGroup]="fVm.form" [attr.data-qa]="'searchBar'" class="search-bar">
    <div class="input-custom">
      <div class="input-custom__icon">
        <span class="search-bar-icon" [class.search-bar--active]="isSearchActive">
          <i class="material-icons">search</i>
        </span>
      </div>
      <input
        #searchInput
        (keyup.escape)="clearResults()"
        (keyup.enter)="search(searchString)"
        [placeholder]="'common.searchProjects' | transloco"
        [attr.data-qa]="'searchInput'"
        formControlName="searchString"
        class="search-input form-control-sm"
        type="text"
      />
      <button
        *ngIf="searchString"
        (click)="clearResults()"
        class="btn btn-sm btn-link input-custom__clear-btn"
        type="button"
      >
        <span>
          <i class="material-icons input-custom__clear-btn-icon">clear</i>
        </span>
      </button>
    </div>
    <jui-button
      expand
      size="sm"
      [color]="isButtonLight ? 'low' : 'primary'"
      *ngIf="searchString"
      (click)="search(searchString)"
      [disabled]="isButtonDisabled"
      [loading]="vm.isSearching"
      class="searchBtn"
    >
      {{ 'Buttons.search.text' | transloco }}
    </jui-button>
  </form>
  <app-search-results-inline></app-search-results-inline>
</div>
