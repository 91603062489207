<div class="doc-manager__wrap" [class.doc-manager--working]="inProgress">
  <div class="doc-manager__document-check" [class.doc-manager__document-check--icon]="locked">
    <jui-icon
      *ngIf="locked"
      [attr.data-qa]="doc.qaDocumentLocked"
      size="xs"
      color="warning"
      name="lock"
      isButton="false"
    ></jui-icon>
    <div *ngIf="!locked" class="custom-control custom-checkbox doc-manager__document-check-control">
      <input
        type="checkbox"
        class="custom-control-input"
        [disabled]="doc.requiredDisabled"
        [id]="doc.id"
        (click)="requiredToggle.emit(doc)"
        [checked]="doc.isRequired"
        [attr.data-qa]="doc.qaRequiredToggle"
      />
      <label class="custom-control-label doc-manager__document-check-label" [for]="doc.id"></label>
    </div>
  </div>
  <div class="doc-manager__document">
    <input
      class="doc-manager__file-input upload-file-input"
      type="file"
      [id]="doc.id"
      (change)="fileChange.emit($event)"
      [accept]="acceptedFileTypes"
      [attr.data-qa]="doc.qaDocUploadInput"
      #fileInput
    />
    <div class="doc-manager__document-name" [class.doc-manager__document-name--disabled]="doc.showNameInactive">
      <span class="material-icons doc-manager__document-icon">description</span>
      <span class="doc-manager__document-label">
        <span class="doc-manager__document-name">{{ doc.name }}</span>
        <span
          *ngIf="!locked"
          class="doc-manager__document-state"
          [class.doc-manager__document-state--done]="doc.actionDone"
          [class.doc-manager__document-state--no-action]="doc.noAction"
          [class.doc-manager__document-state--auto-action]="doc.isAutoActionState"
          [class.doc-manager__document-state--strong-action]="doc.isMissingInfo"
          [attr.data-qa]="doc.qaDocState"
        >
          {{ doc.stateLabel }}
        </span>
      </span>
    </div>
    <jui-button
      size="xs"
      *ngIf="doc.actionEnabled"
      [disabled]="actionButtonDisabled"
      (click)="actionClick.emit(fileInput)"
      [attr.data-qa]="doc.qaDocAction"
    >
      {{ doc.actionLabel }}
    </jui-button>
  </div>
</div>
