import { Feature, Role, Settings, SubTenant, Team, TenantSettingType } from './domain/types';
import { ApiService } from '../../core/api.service';
import { UserManagementUpdateService } from './user-management-update.service';
import { User } from '../../core/domain/user';
import { CustomLookupService } from '../../core/custom-lookup.service';

export type UserManagementType = User | Team | Role | Feature | Settings | SubTenant;

export abstract class UserManagementService<T extends UserManagementType> {
  protected abstract paginationTokens: any;
  protected abstract data: T[];

  protected lists: any = {
    user: [],
    team: [],
    role: [],
    feature: [],
    path: [],
    settings: [],
    subTenant: []
  };

  protected constructor(
    protected apiService: ApiService,
    protected userManagementUpdateService: UserManagementUpdateService,
    protected customLookupService: CustomLookupService
  ) {}

  abstract lookup(force?: boolean, paginationToken?: string, limit?: number);

  get canGoNext() {
    return this.paginationTokens.next !== null;
  }

  get canGoPrevious() {
    return this.paginationTokens.previous.length > 1;
  }

  async next() {
    this.paginationTokens.previous.push(this.paginationTokens.current);
    this.paginationTokens.current = this.paginationTokens.next;
    await this.lookup(true, this.paginationTokens.next);
    return this.data;
  }

  async previous() {
    this.paginationTokens.current = this.paginationTokens.previous.pop();
    await this.lookup(true);
    return this.data;
  }

  async getTenantSettingsListByType(type: TenantSettingType) {
    if (this.lists[type].length) {
      return this.lists[type];
    }

    return await this.apiService
      .getTenantSettingsListByType(type)
      .toPromise()
      .then(result => {
        this.lists[type] = result;
        return this.lists[type];
      });
  }

  async getTenantSettingsByType(type: TenantSettingType) {
    if (this.lists[type].length) {
      return this.lists[type];
    }

    return await this.apiService
      .getTenantSettingsByType(type)
      .toPromise()
      .then(result => {
        this.lists[type] = result;
        return this.lists[type];
      });
  }

  async updateTenantSetting(type: TenantSettingType, payload: Team | Role | Feature | SubTenant) {
    await this.apiService
      .updateTenantSettingsByType(type, payload)
      .toPromise()
      .then(() => {
        this.lists[type] = [];
      });
    this.customLookupService.flush();
  }

  async teamUpdated(team: Team) {
    await this.userManagementUpdateService.teamUpdated(team);
    this.customLookupService.flush();
  }

  async subTenantUpdated(subTenant: SubTenant) {
    await this.userManagementUpdateService.subTenantUpdated(subTenant);
    this.customLookupService.flush();
  }

  async roleUpdated(role: Role) {
    await this.userManagementUpdateService.roleUpdated(role);
    this.customLookupService.flush();
  }
}
