import { Injectable } from '@angular/core';
import Amplify, { Auth, Hub, Logger, Signer } from 'aws-amplify';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { LOGIN_PATH, MAIN_PATH, CHANGE_PASSWORD_PATH, PROJECTS_DETAILS_PATH, FREE_LEADS_PATH } from '../../app.routes';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { PathwayConfiguration } from './pathway-configuration.service';
import { AuthErrorMessage, AuthEventException, AuthStateEvent } from '../utils/auth-types';
import { ICredentials } from 'aws-amplify/lib/Common/types/types';

// @ts-ignore
// window.LOG_LEVEL = 'DEBUG'; // uncomment this line to see auth logs for development

export interface SignerAccessInfo {
  access_key: string;
  secret_key: string;
  session_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private auth: any = null;
  private isSignedIn: boolean;
  private signOutSubject: Subject<any> = new Subject<any>();
  private authSubject: BehaviorSubject<CognitoUserInterface> = new BehaviorSubject<CognitoUserInterface>(null);
  private authErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private authStateSubject: Subject<AuthStateEvent> = new Subject<AuthStateEvent>();
  private logger = new Logger('Auth-Logger');
  private delayChangePasswordSuccessMs = 1000;

  public authState: AuthStateEvent;
  public user: CognitoUserInterface | undefined;
  public signOutObservable: Observable<any> = this.signOutSubject.asObservable();
  public authObservable: Observable<CognitoUserInterface> = this.authSubject.asObservable();
  public authErrorObservable: Observable<string> = this.authErrorSubject.asObservable();
  public authStateObservable: Observable<AuthStateEvent> = this.authStateSubject.asObservable();

  constructor(private router: Router) {}

  private static getAuthToken(auth: CognitoUserInterface) {
    if (auth) {
      return Auth.currentSession().then(session => {
        return session.getIdToken().getJwtToken();
      });
    }
  }

  private static getAuthData() {
    return JSON.parse(localStorage.getItem('jtAuthData'));
  }

  // this is required for cube-js client, will need to be addressed as tech debt
  static getAuthHeader(): string {
    const authData = AuthenticationService.getAuthData();
    const cognitoAuth = new CognitoAuth(authData);
    const token = AuthenticationService.getOldAuthToken(cognitoAuth);
    return `Bearer ${token}`;
  }

  private static getOldAuthToken(auth: CognitoAuth) {
    return auth?.getSignInUserSession().getIdToken().getJwtToken();
  }

  public static getTier() {
    return localStorage.getItem('jtAuthTier');
  }

  private setTier(tier: string) {
    localStorage.setItem('jtAuthTier', tier);
  }

  public static getPlatform() {
    return localStorage.getItem('jtAuthPlatform');
  }

  private setPlatform(platform: string) {
    localStorage.setItem('jtAuthPlatform', platform);
  }

  async configureAuth() {
    const authData = this.authData;
    if (authData) {
      this.auth = Amplify.configure({
        Auth: {
          // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
          identityPoolId: authData.IdentityPoolId,
          // REQUIRED - Amazon Cognito Region
          region: authData.Region,
          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: authData.UserPoolId,
          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          userPoolWebClientId: authData.UserPoolWebClientId,
          // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
          mandatorySignIn: false
        }
      });

      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          await this.onSignInSuccess(user);
        }
      } catch (e) {
        this.logger.info(`Error: ${e}`);
      }

      return this.auth;
    }
  }

  get isUserSignedIn(): boolean {
    return this.isSignedIn;
  }

  set isUserSignedIn(val: boolean) {
    this.isSignedIn = val;
  }

  getSession() {
    return Auth.currentSession();
  }

  getIdToken() {
    return AuthenticationService.getAuthToken(this.auth);
  }

  getCurrentCredentials(): Promise<ICredentials> {
    return Auth.currentCredentials();
  }

  setAuthData(hostConfiguration: PathwayConfiguration) {
    localStorage.setItem(
      'jtAuthData',
      JSON.stringify({
        IdentityPoolId: hostConfiguration.configuration.identityPoolId,
        Region: hostConfiguration.configuration.identityPoolId.split(':').shift(),
        UserPoolId: hostConfiguration.configuration.poolId,
        UserPoolWebClientId: hostConfiguration.configuration.cognitoClientId,
        ClientId: hostConfiguration.configuration.cognitoClientId,
        AppWebDomain: hostConfiguration.configuration.authDomain,
        TokenScopesArray: hostConfiguration.configuration.scopes,
        RedirectUriSignIn: hostConfiguration.configuration.redirectSignIn,
        RedirectUriSignOut: hostConfiguration.configuration.redirectLogOut,
        MandatorySignIn: false,
        AdvancedSecurityDataCollectionFlag: false
      })
    );
  }

  get authData(): any {
    return AuthenticationService.getAuthData();
  }

  // ACTIONS
  async signIn(user: string, pwd: string) {
    try {
      return await Auth.signIn(user, pwd);
    } catch (e) {
      this.logger.info(`Error signing out: ${e}`);
    }
  }

  public async signOut() {
    try {
      await Auth.signOut();
    } catch (e) {
      this.logger.info(`Error signing out: ${e}`);
    }
  }

  public signUrl(url: string, credentials: ICredentials): string {
    const signerAccessInfo: SignerAccessInfo = {
      access_key: credentials.accessKeyId,
      secret_key: credentials.secretAccessKey,
      session_token: credentials.sessionToken
    };
    return Signer.signUrl(url, signerAccessInfo);
  }

  startChangePassword() {
    this.authStateSubject.next(AuthStateEvent.StartChangePassword);
  }

  async changePassword(oldPassword: string, newPassword: string) {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      if (user) {
        await Auth.changePassword(user, oldPassword, newPassword);
        this.authStateSubject.next(AuthStateEvent.ChangePasswordSubmit);
        this.onChangePasswordSuccess();
      }
    } catch (e) {
      this.authStateSubject.next(AuthStateEvent.ChangePasswordSubmitFail);
      this.handleCognitoException(e);
      this.logger.info(`Error with change password flow: ${e}`);
    }
  }

  async forgotPassword(user: string) {
    try {
      await Auth.forgotPassword(user);
    } catch (e) {
      this.logger.info(`Error with forgot password flow: ${e}`);
    }
  }

  forgotPasswordSubmit(user: string, code: string, pwd: string) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(user, code, pwd)
        .then(() => {
          resolve(null);
        })
        .catch(err => {
          const message = !err.message ? err : err.message;
          reject(message);
        });
    });
  }

  async resendCode(username: string) {
    Auth.verifyUserAttribute(username, 'email')
      .then(() => {
        this.logger.info('Verification code is sent');
      })
      .catch(e => {
        this.logger.info(`Failed with error: ${e}`);
      });
  }

  async forceNewPassword(user: CognitoUser, pwd: string) {
    return new Promise((resolve, reject) => {
      Auth.completeNewPassword(user, pwd)
        .then(() => {
          resolve(null);
        })
        .catch(err => {
          const message = !err.message ? err : err.message;
          reject(message);
        });
    });
  }

  async confirmSmsMfa(user: CognitoUser, code: string) {
    try {
      return await Auth.confirmSignIn(user, code);
    } catch (e) {
      this.logger.info(`Error with SMS MFA confirm flow: ${e}`);
      this.authErrorSubject.next(AuthErrorMessage.Generic);
    }
  }

  clearErrors() {
    this.authErrorSubject.next(null);
  }

  // EVENT LISTENER
  initAuthEventListener() {
    /* istanbul ignore next */
    const listener = data => {
      this.authState = data.payload.event;
      this.authStateSubject.next(this.authState);

      switch (this.authState) {
        case AuthStateEvent.SignIn:
          this.logger.info('user signed in');
          this.onSignInSuccess(data.payload.data).then();
          break;
        case AuthStateEvent.SignOut:
          this.logger.info('user signed out');
          this.onSignOutSuccess();
          break;
        case AuthStateEvent.SignInFail:
          this.logger.warn('user sign in failed');
          this.onSignInFailure(data.payload.data);
          break;
        case AuthStateEvent.ForgotPasswordFail:
          this.logger.warn('forgot password failure');
          this.onForgotPasswordFailure();
          break;
        case AuthStateEvent.ForgotPasswordSubmitFail:
        case AuthStateEvent.CompleteNewPasswordFail:
          this.logger.warn('forgot password submit failure');
          this.onNewPasswordFailure(data.payload.data, this.authState);
          break;
        case AuthStateEvent.ForgotPasswordSubmit:
          this.logger.info('forgot password submit success');
          break;
        case AuthStateEvent.TokenRefresh:
          this.onSignInSuccess(data).then();
          this.logger.info('token refresh succeeded');
          break;
        case AuthStateEvent.TokenRefreshFail:
          this.logger.warn('token refresh failed');
          break;
        case AuthStateEvent.Configured:
          this.logger.info('the Auth module is configured');
      }
    };
    Hub.listen('auth', listener);
  }

  // EVENT HANDLERS
  async onSignInSuccess(user) {
    this.user = user;
    this.authErrorSubject.next(null);

    const info = await Auth.currentUserInfo();
    this.setTier(info?.attributes?.['custom:tier']);
    this.setPlatform(info?.attributes?.['custom:platform']);

    return Auth.currentSession()
      .then(() => {
        this.isUserSignedIn = true;
        this.authSubject.next(this.user);
        // allow logged in users to stay on the change password page
        if (this.router.url.indexOf(CHANGE_PASSWORD_PATH) !== -1) {
          return;
        }
        this.navigateBackToRoute();
      })
      .catch(e => {
        this.logger.info(`Error getting user session: ${e}`);
      });
  }

  onSignInFailure(e) {
    this.handleCognitoException(e);
  }

  onForgotPasswordFailure() {
    this.logger.info('onForgotPasswordFailure');
    this.authStateSubject.next(AuthStateEvent.ForgotPassword);
  }

  onNewPasswordFailure(e, state: AuthStateEvent) {
    this.handleCognitoException(e);
    if (state === AuthStateEvent.CompleteNewPasswordFail) {
      this.authStateSubject.next(AuthStateEvent.NewPasswordRequired);
    }
  }

  onChangePasswordSuccess() {
    setTimeout(() => {
      this.navigateBackToRoute();
    }, this.delayChangePasswordSuccessMs);
  }

  onSignOutSuccess() {
    this.isUserSignedIn = false;
    this.signOutSubject.next(null);
    this.authSubject.next(null);
    localStorage.removeItem('returnUrl');
    this.setTier(null);
    this.setPlatform(null);
    this.router.navigate([LOGIN_PATH]).then();
    this.authStateSubject.next(AuthStateEvent.StartSignIn);
  }

  navigateBackToRoute() {
    if (AuthenticationService.getPlatform() === 'leads') {
      this.router.navigate([FREE_LEADS_PATH]).then();
      return;
    }

    let returnUrl = localStorage.getItem('returnUrl');
    if (window.location.pathname.startsWith(`/${PROJECTS_DETAILS_PATH}/`)) {
      returnUrl = window.location.pathname;
    }
    if (returnUrl) {
      this.router.navigateByUrl(returnUrl).catch(e => {
        this.logger.info(`issue navigating to a route: ${e}`);
      });
      localStorage.removeItem('returnUrl');
    } else {
      this.router.navigate([MAIN_PATH]).then();
    }
  }

  handleCognitoException(e) {
    let exceptionType = null;
    if (e?.__type) {
      exceptionType = e.__type;
    }
    if (e?.code) {
      exceptionType = e.code;
    }

    switch (exceptionType) {
      case AuthEventException.NotAuthorized:
        return this.authErrorSubject.next(AuthErrorMessage.Generic);
      case AuthEventException.LimitExceeded:
        return this.authErrorSubject.next(AuthErrorMessage.LimitExceeded);
      case AuthEventException.CodeExpired:
        return this.authErrorSubject.next(AuthErrorMessage.CodeExpired);
      case AuthEventException.CodeMisMatch:
        return this.authErrorSubject.next(AuthErrorMessage.CodeMismatch);
      case AuthEventException.InvalidPassword:
        return this.authErrorSubject.next(AuthErrorMessage.InvalidPassword);
      case AuthEventException.PasswordResetRequired:
        return this.authErrorSubject.next(AuthErrorMessage.PasswordResetSent);
      default:
        return this.authErrorSubject.next(AuthErrorMessage.Generic);
    }
  }
}
