// This is the record in the db which authorises this single use

import { Address } from './address';

export interface SingleUseAction<T> {
  tenant: string;
  id: string;
  type: string;
  subTenant?: string;
  data: T;
  created_on?: string;
}

export interface RegisterSubTenantAction {
  srcTenant: string;
  targetTenant: string;
  installer: string;
  projectTypes: string[];
  addUserToTeam?: string;
}

export interface UpdateProjectAction {
  projectId: string;
  [key: string]: any;
}

export interface RegisterSubTenantPayload {
  address: Address;
  installer: string;
  installerContact: string;
  installerEmail: string;
  installerEvhs: string;
  userEmail: string;
  firstName: string;
  lastName: string;
  registeredCompanyName?: string;
  installerAccreditationBody?: string;
  installerPhone?: string;
  installerWebsite?: string;
  installerLogo?: string;
  smsSenderId?: string;
  smsMessage?: string;
  outgoingCustomerEmail?: string;
}

export interface RegisterSubTenantActionPayload {
  Body: {
    payload: RegisterSubTenantPayload;
    singleUseAction: SingleUseAction<RegisterSubTenantAction>;
  };
}
