import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private defaultLang = 'en';

  public currentLang = new BehaviorSubject(this.lang);

  set lang(value) {
    if (!value) {
      return;
    }
    localStorage.setItem('jt:lang', value);
    this.currentLang.next(value); // this will make sure to tell every subscriber about the change.
  }

  get lang(): string {
    return localStorage.getItem('jt:lang') || this.defaultLang;
  }
}
