import { Component, Input } from '@angular/core';
import { MyOrdersVm } from '../../my-orders.model';

@Component({
  selector: 'pathway-my-orders-custom-address',
  templateUrl: './custom-address.component.html',
  styleUrls: ['../../my-orders.component.scss']
})
export class MyOrdersCustomAddressComponent {
  @Input() vm: MyOrdersVm;
}
