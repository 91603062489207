<error-component></error-component>
<upload-progress-component></upload-progress-component>

<ng-container *ngIf="vm && vm.newStyle">
  <div class="relay-main">
    <div class="survey" [class.survey--complete]="vm.complete" [class.survey--branded]="vm.useBranding">

      <div *ngIf="vm.useBranding" class="survey__logo">
        <link *ngIf="vm?.customStyle" rel="stylesheet" [href]="vm.customStyle" type="text/css" />
        <link *ngIf="vm?.customFont" rel="stylesheet" [href]="vm.customFont" />
        <logo-component [showClose]="vm.showClose"></logo-component>
      </div>

      <div class="survey__progress" *ngIf="!vm.complete">
        <jui-progress-stepper color="primary" size="md" [total]="vm.total" [step]="vm.step"></jui-progress-stepper>
      </div>

      <ng-container>
        <div *ngIf="vm.card" class="survey__questions">
          <question-group [form]="asFormGroup(vm?.form?.controls[vm.card.key])" [card]="vm.card">
          </question-group>
        </div>
        <div class="survey__actions">
          <actions-component
            [readOnly]="vm.readOnly"
            [demo]="vm.demo"
            (next)="cardChanged()"
            (previous)="cardChanged()"
          ></actions-component>
        </div>
      </ng-container>
    </div>
  </div>
  <ngx-spinner
    name="relaySpinner"
    bdColor="rgba(51, 51, 51, 0.8)"
    size="large"
    color="var(--primary-color)"
    type="line-scale"
    [attr.data-qa]="'relaySpinner'"
  >
  </ngx-spinner>
</ng-container>


<ng-container *ngIf="vm?.form && vm?.card && vm?.i18nKeys && !vm.newStyle">
  <div class="box" #boxEl>
    <link *ngIf="vm?.customStyle" rel="stylesheet" [href]="vm.customStyle" type="text/css" />
    <link *ngIf="vm?.customFont" rel="stylesheet" [href]="vm.customFont" />
    <logo-component id="logoComponent" [showClose]="vm.showClose"></logo-component>
    <div class="jumpcontent">
      <ng-container *ngIf="vm?.form && vm?.card && vm?.i18nKeys">
        <div [@fixedProgress]="vm.state" class="fixedSummary">
          <summary-component></summary-component>
        </div>
        <div [@cardsTop]="vm.state" #cardsEl>
          <div class="relay-card-wrapper" [ngClass]="vm.wrapperClass">
            <crds-card [form]="vm.form" [card]="vm.card" [i18ns]="vm?.i18nKeys"></crds-card>
          </div>
          <actions-component
            [readOnly]="vm.readOnly"
            [demo]="vm.demo"
            (next)="cardChanged()"
            (previous)="cardChanged()"
          ></actions-component>
        </div>
      </ng-container>
    </div>
  </div>
  <ngx-spinner
    name="relaySpinner"
    bdColor="rgba(51, 51, 51, 0.8)"
    size="large"
    color="#ff8200"
    type="line-scale"
    [attr.data-qa]="'relaySpinner'"
  >
  </ngx-spinner>
</ng-container>

