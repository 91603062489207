<div class="linked-projects__links" *ngIf="vm.showLinks">
  <div class="linked-projects__assigned">
    <span class="linked-projects__group-name-prefix">{{ vm.numberInGroup }} {{ vm.linkedGroupNamePrefix }}</span>
    <span class="linked-projects__group-name">{{ vm.linkedGroupName }}</span>
  </div>
  <div [class.linked-projects__scroll]="vm.useScroll">
    <div class="linked-projects__link" *ngFor="let link of vm.linkedData">
      <div
        class="linked-projects__summary"
        [class.linked-projects__summary--actionable]="!link.isCurrentProject"
        [attr.data-qa]="link.qaLinkSummaryItem"
        (click)="linkClicked.emit(link)"
      >
        <div class="linked-projects__data">
          <span class="linked-projects__type">{{ link.projectType }}</span>
        </div>
        <div class="linked-projects__data">
          <span class="linked-projects__data-label">{{ vm.customerLabel }}</span>
          <span class="linked-projects__name">{{ link.name }}</span>
        </div>
        <div class="linked-projects__data">
          <span class="linked-projects__data-label">{{ vm.statusLabel }}</span>
          <span class="badge badge-secondary"> {{ link.status }}</span>
        </div>
        <div class="linked-projects__data">
          <span class="linked-projects__data-label">{{ vm.addressLabel }}</span>
          <span>{{ link.location }}</span>
        </div>
      </div>
      <div class="linked-projects__action">
        <jui-icon
          class="linked-projects__indicator"
          *ngIf="link.isCurrentProject"
          name="fiber_manual_record"
          size="xs"
          isButton="false"
        ></jui-icon>
        <jui-button
          size="xs"
          color="low"
          [attr.data-qa]="link.qaUnlinkButton"
          (click)="unlinkBtnClick.emit({ groupId: vm.groupId, projectId: link.projectId })"
          [disabled]="vm.inProgress"
          >{{ vm.unlinkButton }}</jui-button
        >
      </div>
    </div>
  </div>
</div>
