import { AbstractControl } from '@angular/forms';

export const validJsonValidator = () => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    try {
      return JSON.parse(control.value) && null;
    } catch (e) {
      return { invalidJson: { value: control.value } };
    }
  };
};
