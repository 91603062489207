import { Injectable } from '@angular/core';
import { MyOrdersRepository } from '../my-orders.repository';
import {OrderHistoryDm, OrderHistoryItemVm, OrderHistoryVm, ResourceOrderStatus} from '../my-orders.model';

@Injectable()
export class MyOrdersHistoryPresenter {
  constructor(private repository: MyOrdersRepository) {}

  load(vmSubject$): void {
    this.repository.loadOrderHistory((dataDm: OrderHistoryDm): void => {
      const viewModel: OrderHistoryVm = {
        titleHistory: dataDm.i18n.title.history,
        numberOfOrders: `(${dataDm.orders.length})`,
        thCreated: dataDm.i18n.table.created,
        thItems: dataDm.i18n.table.items,
        thShipping: dataDm.i18n.table.shippingDate,
        thStatus: dataDm.i18n.table.status,
        orders: dataDm.orders.map((o, i): OrderHistoryItemVm => {
          return {
            id: o.id,
            isActive: o.isActive || o.id === 'tempOrderId',
            createdOnDisplay: o.summary.createdOnDisplay,
            itemNameList: o.summary.itemNameList,
            orderStatus: o.summary.orderStatus,
            orderStatusDisplay: o.summary.orderStatusDisplay,
            inProgress: o.summary.orderStatus !== ResourceOrderStatus.CANCELLED,
            requestedShippingDate: o.summary.requestedShippingDate,
            isOptimistic: i === 0 && dataDm.isOptimistic,
            qaViewOrderAction: `moViewHistoryOrderAction--${o.id}`
          };
        })
      };
      vmSubject$.next(viewModel);
    });
  }

  viewOrder(order) {
    this.repository.viewOrder(order);
  }
}
