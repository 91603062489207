<ng-container *ngIf="vm.message">
  <div class="doc-manager-messages">
    <span [attr.data-qa]="vm.qaMessage">{{ vm.message }}</span>
    <crds-inline-spinner
      *ngIf="vm.showLoader"
      [qaHook]="vm.qaSpinner"
      name="dockPackMessagesSpinner"
      colour="white"
    ></crds-inline-spinner>
  </div>
</ng-container>
