import { ILinkedProjectsVm, ISummaryDataVm } from '../../linked-projects.model';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-linked-projects-summary',
  templateUrl: './linked-projects-summary.component.html',
  styleUrls: ['../../linked-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkedProjectsSummaryComponent {
  @Input() vm: ILinkedProjectsVm;
  @Output() linkClicked: EventEmitter<ISummaryDataVm> = new EventEmitter<ISummaryDataVm>();
  @Output() unlinkBtnClick: EventEmitter<{ groupId: string; projectId: string }> = new EventEmitter<{
    groupId: string;
    projectId: string;
  }>();
}
