<div class="login-card a-fade-in" [class.login-card--working]="submitInProgress">
  <div class="login-card__title">
    <img class="login-card__logo" alt="Jumptech" [src]="themeService.brandLogo$ | async" />
  </div>
  <div class="login-card__body">
    <!-- Auth Errors -->
    <ng-container *ngIf="authError$ | async as error">
      <div class="login-card__row" data-qa="authErrors">
        <!-- t(auth.error.generic, auth.error.passwordResetSent, auth.error.codeExpired, auth.error.codeMisMatch, auth.error.invalidPassword, auth.error.limitExceeded) -->
        <div class="login-card__error a-fade-in-fast">{{ error | transloco }}</div>
      </div>
    </ng-container>
    <!-- Auth Notifications -->
    <ng-container *ngIf="authNotification$ | async as message">
      <div class="login-card__row" data-qa="authNotifications">
        <!-- t(auth.notify.newResetEmailSent, auth.notify.newMfaCodeSent) -->
        <div class="login-card__notify a-fade-in-fast">{{ message | transloco }}</div>
      </div>
    </ng-container>
    <!-- Switch AuthStateEvent -->
    <ng-container *ngIf="currentAuthState$ | async as state">
      <!-- Start Sign in -->
      <ng-container *ngIf="state === AuthStateEvent.StartSignIn || state === AuthStateEvent.SignInFail">
        <app-sign-in-form
          [signInForm]="signInForm"
          [submitInProgress]="submitInProgress"
          (signInEvent)="signIn()"
          (setViewEvent)="setAuthState($event)"
        ></app-sign-in-form>
      </ng-container>

      <!-- Start Change Password -->
      <ng-container
        *ngIf="state === AuthStateEvent.StartChangePassword || state === AuthStateEvent.ChangePasswordSubmitFail"
      >
        <app-change-password-form
          [changePasswordForm]="changePasswordForm"
          [submitInProgress]="submitInProgress"
          (changePasswordEvent)="changePassword()"
        ></app-change-password-form>
      </ng-container>

      <!-- Start Forgot Password -->
      <ng-container *ngIf="state === AuthStateEvent.StartForgotPassword">
        <app-forgot-password-form
          [forgotPasswordForm]="forgotPasswordForm"
          [submitInProgress]="submitInProgress"
          (forgotPasswordEvent)="forgotPassword()"
          (setViewEvent)="setAuthState($event)"
        ></app-forgot-password-form>
      </ng-container>

      <!-- Forgot Password Submit -->
      <ng-container
        *ngIf="state === AuthStateEvent.ForgotPassword || state === AuthStateEvent.ForgotPasswordSubmitFail"
      >
        <app-forgot-password-submit-form
          [forgotPasswordSubmitForm]="forgotPasswordSubmitForm"
          [submitInProgress]="submitInProgress"
          [code]="code"
          [emailAddress]="forgotPasswordForm.get('username').value"
          [displaySplash]="initialState !== AuthStateEvent.ForgotPassword"
          (forgotPasswordSubmitEvent)="forgotPasswordSubmit()"
          (resendCodeEvent)="forgotPassword($event)"
          (notifyEvent)="notification($event)"
        ></app-forgot-password-submit-form>
      </ng-container>

      <!-- Forgot Password Submit Success -->
      <ng-container
        *ngIf="state === AuthStateEvent.ForgotPasswordSubmit || state === AuthStateEvent.ChangePasswordSubmit"
      >
        <app-reset-password-success></app-reset-password-success>
      </ng-container>

      <!-- New Password Required -->
      <ng-container *ngIf="state === AuthStateEvent.NewPasswordRequired">
        <app-new-password-required-form
          [newPasswordRequiredForm]="newPasswordRequiredForm"
          [submitInProgress]="submitInProgress"
          (newPasswordSubmitEvent)="newPasswordRequired()"
        ></app-new-password-required-form>
      </ng-container>

      <!-- SMS MFA -->
      <ng-container *ngIf="state === AuthStateEvent.SmsMfaRequired">
        <app-mfa-required-form
          [smsMfaRequiredForm]="smsMfaRequiredForm"
          [submitInProgress]="submitInProgress && !sendSmsInProgress"
          [sendSmsInProgress]="sendSmsInProgress"
          (smsMfaSubmitEvent)="confirmSmsMfa()"
          (setViewEvent)="setAuthState($event)"
          (resendMfaCodeEvent)="resendSmsMfa()"
          (notifyEvent)="notification($event)"
        ></app-mfa-required-form>
      </ng-container>

      <!-- Sign In Success -->
      <ng-container *ngIf="state === AuthStateEvent.SignIn">
        <app-sign-in-success></app-sign-in-success>
      </ng-container>

      <!-- Token Refresh -->
      <ng-container *ngIf="state === AuthStateEvent.TokenRefresh">
        <app-token-refresh (goToRouteEvent)="goBackToRoute()"></app-token-refresh>
      </ng-container>
    </ng-container>
  </div>
</div>
