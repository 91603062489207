import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { interval, Observable, Subscription } from 'rxjs';
import { LoggerService } from '../../error/logger.service';
import * as moment from 'moment';
import { AuditLog, AuditLogProgress } from '@jump-tech-frontend/domain';
import { TranslocoService } from '@ngneat/transloco';

export enum ProgressIndicatorStatus {
  todo = 'progress-todo',
  current = 'progress-current',
  progressing = 'progress-progressing',
  next = 'progress-next',
  completed = 'progress-completed',
  archived = 'progress-archived',
  skipped = 'progress-skipped'
}

export interface ProgressIndicatorState {
  label: string;
  status: ProgressIndicatorStatus;
  showProgress?: boolean;
  count?: number;
  statusData: any;
  showSkipped: boolean;
}

const icons = {
  [ProgressIndicatorStatus.todo]: 'radio_button_unchecked',
  [ProgressIndicatorStatus.current]: 'check_circle',
  [ProgressIndicatorStatus.next]: 'radio_button_unchecked',
  [ProgressIndicatorStatus.completed]: 'check_circle',
  [ProgressIndicatorStatus.archived]: 'cancel',
  [ProgressIndicatorStatus.skipped]: 'remove_circle'
};

@Component({
  selector: 'progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})
export class ProgressIndicatorComponent {
  private _auditLogs: AuditLog[] = [];
  private pollInterval = 5000; // 5 seconds
  private pollObservable: Observable<any>;
  private pollSubscription: Subscription;
  private polling = false;
  private currentStatusTypes = [ProgressIndicatorStatus.current, ProgressIndicatorStatus.progressing];

  public progress = 0;
  public progressDate: string = null;
  public cardLabel: string = null;
  public progressIndicatorStatus = ProgressIndicatorStatus;
  public hovering = false;

  public progressingStatus = ProgressIndicatorStatus.progressing;
  public skippedStatus = ProgressIndicatorStatus.skipped;

  @Input() states?: ProgressIndicatorState[];
  @Input() currentStatusPosition: number;
  @Input() projectId?: string;

  @Input() isArchived = false;

  @Input() set auditLogs(auditLogs) {
    this._auditLogs = (auditLogs || []).filter(auditLog => auditLog.eventName === 'RelayProgress');
    const { progress, progressDate, cardLabel } = (
      this._auditLogs.length
        ? this._auditLogs.sort((a, b) => a.created_on.localeCompare(b.created_on))[this._auditLogs.length - 1].data
        : {
            progress: '0',
            progressDate: null,
            cardLabel: this.translocoService.translate('progress.sentAwaiting')
          }
    ) as AuditLogProgress;
    this.progress = parseInt(progress);
    this.progressDate = progressDate
      ? moment(progressDate).format('DD/MM/YYYY HH:mm')
      : this.translocoService.translate('common.unknown');
    this.cardLabel = cardLabel;
  }

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  get auditLogs() {
    return this._auditLogs;
  }

  constructor(
    private translocoService: TranslocoService,
    private apiService: ApiService,
    private loggerService: LoggerService
  ) {
    this.pollObservable = interval(this.pollInterval);
  }

  ngOnDestroy() {
    this.polling = false;
    if (this.pollSubscription) {
      this.pollSubscription.unsubscribe();
    }
  }

  isProgressing(status: ProgressIndicatorStatus) {
    const isProgressing = status === ProgressIndicatorStatus.progressing;
    if (isProgressing) {
      this.startProgressPolling();
    }
    return isProgressing;
  }

  getIconForStatus(status: ProgressIndicatorStatus | string) {
    return icons[status];
  }

  shouldShowStatusData(state, pos) {
    return state && pos <= this.currentStatusPosition;
  }

  public isCurrentStatusType(status: ProgressIndicatorStatus): boolean {
    return this.currentStatusTypes.indexOf(status) !== -1;
  }

  getAuditLogProgress() {
    const search = [
      { key: 'projectId', value: this.projectId },
      { key: 'eventName', value: 'RelayProgress' }
    ];

    const searchOptions = {
      sort: 'created_on',
      dir: 'desc',
      max: '1'
    };
    return this.apiService.searchAuditLogs(search, searchOptions).toPromise();
  }

  private startProgressPolling() {
    if (this.polling) {
      return;
    }

    this.polling = true;
    this.pollSubscription = this.pollObservable.subscribe(
      async () => {
        this.auditLogs = (await this.getAuditLogProgress()).results;
      },
      error => {
        this.loggerService.error(error);
      }
    );
  }

  getColor(color) {
    return getComputedStyle(document.documentElement).getPropertyValue(color);
  }

  clicked(state) {
    if (this.selected && state.showProgress) {
      this.selected.emit(state);
    }
  }
}
