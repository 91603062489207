import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { tabs } from './configuration.module';
import { UserService } from '../../auth/services/user.service';
import { ConfigurationPresenter } from './configuration.presenter';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-configuration',
  template: ` <ul ngbNav class="nav-tabs" #configurationTabSet="ngbNav" (navChange)="navChanged($event)">
      <ng-container *ngFor="let tab of parsedTabs">
        <ng-container *ngIf="tab.show">
          <li [ngbNavItem]="tab.path">
            <a ngbNavLink [routerLink]="tab.path" [attr.data-qa]="tab.path + 'Tab'"
              ><i class="material-icons">{{ tab.icon }}</i
              ><b>{{ tab.label | transloco }}</b></a
            >
          </li>
        </ng-container>
      </ng-container>
    </ul>
    <div>
      <router-outlet></router-outlet>
    </div>`
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  tabs = tabs;
  parsedTabs = null;
  routeUrlSub: Subscription;
  activeTabId: string = null;
  @ViewChild('configurationTabSet') configurationTabSet;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private presenter: ConfigurationPresenter
  ) {
    this.routeUrlSub = this.route.url.subscribe(x => {
      if (x && this.parsedTabs) {
        this.openTab();
      }
    });
  }

  ngOnDestroy() {
    if (this.routeUrlSub) {
      this.routeUrlSub.unsubscribe();
    }
  }

  async ngOnInit() {
    this.userService.userObservable.subscribe(user => {
      this.presenter.currentUser = user;
      if (this.presenter.currentUser) {
        this.parsedTabs = this.parseTabs();
        this.activeTabId = this.getActiveTabId();
        this.openTab();
      }
    });
  }

  parseTabs() {
    return this.tabs.map(x => {
      return { ...x, show: this.presenter.show(x) };
    });
  }

  openTab() {
    let activeTab = this.activeTabId;
    const found = this.parsedTabs.find(tab => tab.path === activeTab && tab.show);
    if (!activeTab || !found) {
      const first = this.parsedTabs.find(tab => tab.show);
      if (!first) {
        return;
      }
      activeTab = first.path;
    }
    this.configurationTabSet?.select(activeTab);
    this.router.navigate([activeTab], { relativeTo: this.route }).then();
  }

  navChanged(event: NgbNavChangeEvent) {
    this.activeTabId = event.nextId;
  }

  getActiveTabId() {
    const tab = this.router.url.split('?')[0].split('/').pop();
    if (tab === 'configuration') {
      return null;
    }
    return tab;
  }
}
