import { BaseQuestionComponent } from './question.component';
import { ApiService } from './api.service';
import { Datasource } from '../domain/datasource';

export abstract class ListQuestionComponent extends BaseQuestionComponent {
  protected constructor(protected apiService: ApiService) {
    super();
  }

  abstract mapData(result: any[], datasource: Datasource);

  async getData(datasource: Datasource) {
    const url = new URL(`${datasource.url}/${datasource.type}`);

    if (datasource.tenant) {
      url.searchParams.append('tenant', datasource.tenant);
    }

    if (datasource.fields && datasource.fields.length > 0) {
      url.searchParams.append('fields', datasource.fields.join(','));
    }

    if (datasource.filters) {
      url.searchParams.append('filters', encodeURIComponent(JSON.stringify(datasource.filters)));
    }

    if (datasource.projectionList) {
      url.searchParams.append('projectionList', datasource.projectionList);
    }

    let result = await this.apiService.get(url.href);

    if (!result) {
      result = [];
    }

    this.mapData(result, datasource);
  }
}
