import { AddNoteModalComponent } from './add-note-modal/add-note-modal.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NotesComponent } from './notes.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [NotesComponent, AddNoteModalComponent],
  imports: [SharedModule, TranslocoModule, CoreModule],
  exports: [NotesComponent, AddNoteModalComponent]
})
export class NotesModule {}
