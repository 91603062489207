<div class="mappings-tester-component" *ngIf="vm" [attr.data-qa]="'mappings-tester-component'">
  <h2>{{ 'autoAssignment.testerTitle' | transloco }}</h2>
  <form class="">
    <div class="mappings-tester-component__form">
      <div class="form-group">
        <label>{{ 'common.postcode' | transloco }}</label>
        <input
          name="match"
          class="form-control form-control-sm match"
          (focusout)="changeMatch($event)"
          value="{{ vm.match }}"
          [attr.data-qa]="'mappings-tester-postcode'"
        />
      </div>
      <div class="form-group">
        <label>{{ 'common.projectType' | transloco }}</label>
        <app-multi-select
          [singleSelection]="true"
          [selectedItems]="[vm.projectType]"
          [items]="vm.projectTypes"
          (itemsChanged)="changeProjectType($event)"
          [allowSearchFilter]="false"
          [attr.data-qa]="'mappings-tester-project-type'"
          [alphaSort]="true"
        ></app-multi-select>
      </div>
    </div>
    <div class="button-list">
      <div class="button-list__btn">
        <jui-button size="sm" (click)="test()" [attr.data-qa]="'test-button'" [disabled]="!vm.isValid">
          {{ 'common.test' | transloco }}
        </jui-button>
      </div>
      <div class="button-list__btn">
        <jui-button size="sm" color="secondary" [attr.data-qa]="'clear-button'" (click)="clear()">{{
          'common.clear' | transloco
        }}</jui-button>
      </div>
    </div>
  </form>
  <div *ngIf="vm.matches" class="results" [attr.data-qa]="'tester-results'">
    <div *ngIf="vm.alert">
      <jui-alert-block>
        <div class="auto-assignment-alert" [attr.data-qa]="'tester-alert'">
          <i class="material-icons">info</i>
          <span>{{ vm.alert }}</span>
        </div>
      </jui-alert-block>
    </div>
    <ngx-datatable
      #assignmentMappingsTable
      [rows]="vm.matches"
      class="bootstrap"
      columnMode="force"
      reorderable="false"
      rowHeight="40"
      [messages]="{ emptyMessage: vm.noDataErrorMessage }"
      [attr.data-qa]="'tester-results-table'"
    >
      <ngx-datatable-column name="{{ 'common.tenant' | transloco }}" prop="tenantDisplay" [sortable]="false">
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ 'autoAssignment.rank' | transloco }}" prop="rank" [sortable]="false" maxWidth="80">
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
