import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { IContactLogModalVm } from '../contact-log.model';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactLogModalPresenter } from './contact-log-modal.presenter';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactLogRepository } from '../contact-log.repository';
import { AlertModule } from 'ngx-bootstrap/alert';

@Component({
  selector: 'pathway-contact-modal',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-log-modal.component.html',
  imports: [NgIf, AsyncPipe, CoreComponentsAngularModule, ReactiveFormsModule, JsonPipe, NgbModule, AlertModule],
  providers: [ContactLogModalPresenter, ContactLogRepository],
  styleUrls: ['./contact-log-modal.component.scss']
})
export class ContactLogModalComponent {
  public vm$ = new BehaviorSubject<IContactLogModalVm>(null);
  constructor(private presenter: ContactLogModalPresenter, private activeModal: NgbActiveModal) {}

  async ngOnInit(): Promise<void> {
    await this.presenter.load(this.vm$);
  }

  save() {
    this.activeModal.close(this.vm$.value.form.value);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  isSelected(field: string, value: string) {
    return this.vm$?.value?.form?.get(field)?.value === value ?? false;
  }
}
