import { Component } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { activeState$ } from '../utils/active-state';

@Component({
  selector: 'lib-formly-repeat-state',
  template: `
    <div class="repeating-block-wrapper" style="height: auto;">
      <div class="card-header d-flex flex-row justify-content-around align-items-center">
        <div
          (click)="toggleShow(i)"
          [class]="'pt-3 pb-3 flex-fill status ' + (show(i) ? 'btn-default' : '')"
          *ngFor="let field of field.fieldGroup; let i = index"
        >
          <span class="heading pl-2 pr-2" (click)="toggleShow(i)">
            {{ model[i][to.field] || model[i].key || model[i].label || model[i].groupLabel || '' }}
          </span>
        </div>

        <i class="ml-2 material-icons" (click)="addSection()">library_add</i>
      </div>
      <ng-container *ngFor="let field of field.fieldGroup; let i = index">
        <div *ngIf="show(i)" class="card-body">
          <div class="d-flex flex-row">
            <formly-field class="w-75" [field]="field"></formly-field>
            <div class="d-flex flex-row ml-auto mt-2">
              <div>
                <div>
                  <jui-icon
                    name="chevron_left"
                    color="high"
                    size="sm"
                    title="Move Left"
                    (click)="moveSection(i, 'up')"
                    *ngIf="i > 0"
                  ></jui-icon>
                </div>
              </div>
              <div [class]="i > 0 ? 'ml-2' : ''">
                <div>
                  <jui-icon
                    name="chevron_right"
                    color="high"
                    size="sm"
                    title="Move Right"
                    (click)="moveSection(i, 'down')"
                    *ngIf="i < model.length - 1"
                  ></jui-icon>
                </div>
              </div>
              <div class="ml-2">
                <div>
                  <jui-icon
                    color="high"
                    name="content_copy"
                    size="sm"
                    title="Copy Section"
                    (click)="addSection(i)"
                  ></jui-icon>
                </div>
              </div>
              <div class="ml-2">
                <div>
                  <jui-icon
                    type="outlined"
                    color="danger"
                    size="sm"
                    name="delete"
                    title="Delete Section"
                    (click)="removeSection(i)"
                  ></jui-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      i.material-icons,
      span.heading {
        cursor: pointer;
      }

      .card-header {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
      }

      .card-body {
        width: 50%;
        background-color: #00000015;
        color: #43425d;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }

      .status {
        word-wrap: break-word;
        text-align: center;
      }

      .status-selected {
        background-color: #94a2ff;
      }
    `
  ]
})
export class RepeatStateTypeComponent extends FieldArrayType {
  selectedItem = 0;

  show(index: number): boolean {
    return this.selectedItem === index;
  }

  toggleShow(index: number): void {
    const notify = this.selectedItem !== index;
    this.selectedItem = index;
    if (notify && this.field?.templateOptions?.onShowToggled) {
      this.field.templateOptions.onShowToggled(index);
    }
  }

  removeSection(index: number, force = false) {
    const confirmRemove = force || confirm(`Are you sure you want to remove this ${this.to.label}`);
    if (!confirmRemove) {
      return;
    }
    super.remove(index);
    if (this.field.templateOptions?.onDeleteItem) {
      this.field.templateOptions.onDeleteItem(this.field?.key, super.model[index]);
    }
    this.selectedItem = index - 1;
    this.updateStates();
  }

  private updateStates() {
    if (this.field?.templateOptions?.onStatesTouched) {
      this.field.templateOptions.onStatesTouched();
    }
  }

  addSection(index?: number, destIndex?: number) {
    if (index === undefined) {
      index = this.model.length || 0;
      this.addToFieldArray(index, this.getDefaultModelForSectionType(this.to.label));
      this.toggleShow(index);
    } else {
      const modelCopy = this.cloneModelItem(index);
      modelCopy.label = modelCopy.label + ' (Copy)';
      modelCopy.status = modelCopy.status + 'Copy';
      this.addToFieldArray(destIndex || index + 1, modelCopy);
      this.toggleShow(destIndex || index + 1);
    }
    this.updateStates();
  }

  private cloneModelItem(index: number) {
    return JSON.parse(JSON.stringify(this.model[index]));
  }

  moveSection(index: number, direction: 'up' | 'down'): void {
    setTimeout(() => {
      const modelCopy = this.cloneModelItem(index);
      this.removeSection(index, true);
      this.addToFieldArray(direction === 'up' ? index - 1 : index + 1, modelCopy);
      this.selectedItem = direction === 'up' ? index - 1 : index + 1;
    });
  }

  getDefaultModelForSectionType(sectionType: string) {
    return {
      status: 'NEW STATUS',
      label: 'New Status',
      tasksWorkflow: {}
    };
  }

  invalid(field: FormlyFieldConfig, index: number) {
    return field.form.controls[index].invalid;
  }

  addToFieldArray(index: number, value: unknown) {
    if (this.key === 'states') {
      activeState$.next({ updating: true });
      this.model.splice(index, 0, value);
      activeState$.next({ updating: false });
    } else {
      super.add(index, value);
    }
  }
}
