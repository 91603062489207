import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalConfig } from './confirm-modal.config';

@Component({
  selector: 'app-confirm-modal',
  template: `<div class="modal-wrapper modal--confirm">
    <div class="modal-header">
      <div>{{ config.title }}</div>
    </div>
    <div class="modal-body" *ngIf="config.messages">
      <div *ngFor="let message of config.messages">{{ message }}</div>
    </div>
    <div class="modal-body" *ngIf="config.requires">
      <div>{{ config.requires.failMessage }}</div>
    </div>
    <div class="modal-footer text-center">
      <jui-button
        [attr.data-qa]="'confirmModalBtn-cancel'"
        class="modal-footer__action-btn"
        color="low"
        size="sm"
        (click)="cancel($event)"
        role="button"
        >{{ config.cancel || ('common.no' | transloco) }}</jui-button
      >
      <jui-button
        [attr.data-qa]="'confirmModalBtn-confirm'"
        class="modal-footer__action-btn"
        size="sm"
        (click)="confirm($event)"
        role="button"
        >{{ config.confirm || ('common.yes' | transloco) }}</jui-button
      >
    </div>
  </div>`
})
export class ConfirmModalComponent {
  @Input() config: ConfirmModalConfig;
  @Input() parameters: any;

  constructor(public activeModal: NgbActiveModal) {}

  confirm($event) {
    $event.preventDefault();
    this.activeModal.close();
  }

  cancel($event) {
    $event.preventDefault();
    this.activeModal.dismiss();
  }
}
