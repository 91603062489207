import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AutoAssignmentRepository } from '../../auto-assignment.repository';
import { ProjectAssignmentDisplay } from '../../domain';

@Injectable()
export class ProjectTypeAssignmentPresenter {
  constructor(private repository: AutoAssignmentRepository, private translocoService: TranslocoService) {}

  async load(callback) {
    await this.repository.getAssignments(assignments => {
      const vm = {
        noDataErrorMessage: this.translocoService.translate('autoAssignment.noData'),
        projectAssignments: assignments
      };
      callback(vm);
    });
  }

  cleanup() {
    this.repository.cleanup();
  }

  async saveProjectAssignment(row: ProjectAssignmentDisplay): Promise<void> {
    await this.repository.saveProjectAssignment({
      id: row.id,
      tenant: row.tenant,
      enabled: row.enabled,
      actionId: row.actionId,
      tenants: row.tenants,
      projectType: row.projectType,
      strategy: row.strategy,
      context: row.context
    });
  }
}
