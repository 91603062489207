import { Injectable } from '@angular/core';
import { Project } from '../../core/domain/project';
import { TasksRepository } from './tasks.repository';
import { ITasksVm, TasksModel } from './task.model';

@Injectable()
export class TasksPresenter {
  constructor(private repository: TasksRepository) {}

  async load(project: Project, callback) {
    this.repository.getTasks((dataDm: TasksModel) => {
      const viewModel: ITasksVm = {
        ...dataDm,
        tasks: dataDm?.tasks.filter(task => task !== null),
        showLoader: dataDm?.showLoader ?? false,
        showTasks: dataDm?.shouldRender ?? false,
        isArchived: dataDm?.isArchived ?? false
      };
      callback(viewModel);
    });
    await this.repository.initialise(project);
  }

  getStateUpdates(callback) {
    this.repository.getStateUpdates(callback);
  }

  getInfoMessage(callback) {
    this.repository.getInfoMessage(callback);
  }

  getGoto(callback) {
    this.repository.getGoto(callback);
  }

  getDelegated(callback) {
    this.repository.getDelegated(callback);
  }

  getActioned(callback) {
    this.repository.getActioned(callback);
  }

  updateProject(project: Project) {
    this.repository.updateProject(project);
  }

  toggleTaskStatus(id: string) {
    this.repository.toggleTaskStatus(id);
  }

  actionTask(id: string) {
    this.repository.action(id);
  }

  cleanUp() {
    this.repository.unsubscribe();
  }
}
