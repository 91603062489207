import {
  Component,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
  EventEmitter,
  ChangeDetectorRef,
  OnInit,
  AfterViewInit
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BodyOutputType, Toast } from './toast.model';

@Component({
  selector: '[toastComponent]',
  templateUrl: './toast-messages.component.html'
})
export class ToastComponent implements OnInit, AfterViewInit {
  @Input() toast: Toast;
  @Input() iconClass: string;
  @Input() iconValue: string;
  @Input() titleClass: string;
  @Input() messageClass: string;
  @ViewChild('componentBody', { read: ViewContainerRef })
  componentBody: ViewContainerRef;

  safeCloseHtml: SafeHtml;
  safeBodyHtml: SafeHtml;

  public bodyOutputType = BodyOutputType;

  @Output()
  public clickEvent = new EventEmitter();

  constructor(private sanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.toast.closeHtml) {
      this.safeCloseHtml = this.toast.closeHtml;
    }
    if (this.toast.bodyOutputType === BodyOutputType.TrustedHtml) {
      this.safeBodyHtml = this.toast.body;
    }
  }

  ngAfterViewInit() {
    if (this.toast.bodyOutputType === this.bodyOutputType.Component) {
      const componentInstance: any = this.componentBody.createComponent(this.toast.body);
      componentInstance.instance.toast = this.toast;
      this.changeDetectorRef.detectChanges();
    }
  }

  click(event: MouseEvent, toast: Toast) {
    event.stopPropagation();
    this.clickEvent.emit({
      value: { toast: toast, isCloseButton: true }
    });
  }
}
