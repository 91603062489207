import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import 'brace';
import 'brace/mode/json';
import 'brace/mode/html';
import 'brace/mode/markdown';
import 'brace/theme/dracula';
import { LanguageService } from './language.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'i18n-language',
  template: `
    <div style="display: flex" *ngIf="!editing">
      <select
        class="form-control"
        style="width: auto"
        (change)="language = langSelect.value"
        name="lang"
        id="lang"
        [disabled]="disabled"
        #langSelect
      >
        <option [selected]="lang === language" [value]="lang" *ngFor="let lang of languages">
          {{ lang }}
        </option>
      </select>
      <i class="material-icons cursor-pointer" (click)="toggleEdit()" *ngIf="!disabled">edit</i>
    </div>
    <div style="display: flex" *ngIf="editing">
      <input class="form-control" style="width: auto" name="language" #language />
      <i class="material-icons cursor-pointer" (click)="addLanguage(language.value)">save</i>
      <i class="material-icons cursor-pointer" (click)="toggleEdit()">cancel</i>
      <div class="m-1 p-1 alert alert-info" role="alert">
        <span>Top level language code only. i.e. en, de (As opposed to en-GB / de-DE)</span>
      </div>
    </div>
  `,
  styles: [
    `
      .cursor-pointer {
        cursor: pointer;
      }
    `
  ]
})
export class I18nLanguageComponent implements OnInit {
  private _languages: string[];
  private _editing: boolean;
  private _language: string;

  @Input() disabled: boolean;

  @Output() addLanguageEvent: EventEmitter<string> = new EventEmitter<string>();

  get language() {
    return this._language;
  }

  set language(lang: string) {
    this.languageService.currentLanguage = lang;
  }

  get languages() {
    return this._languages;
  }

  get editing() {
    return this._editing;
  }

  toggleEdit() {
    this._editing = !this._editing;
  }

  constructor(private languageService: LanguageService) {}

  async ngOnInit() {
    this.languageService.currentLang.subscribe(lang => {
      this.getLanguages();
      this._language = lang;
    });
    this.getLanguages();
    this._language = this.languageService.currentLanguage;
  }

  getLanguages() {
    this._languages = this.languageService.languages;
  }

  addLanguage(value: string) {
    if (!value) {
      return;
    }

    const index = value.indexOf('-');
    if (index > -1) {
      value = value.substring(0, index);
    }

    if (this.addLanguageEvent) {
      this.addLanguageEvent.emit(value);
    }
    this.toggleEdit();
    this._languages = this.languageService.languages;
  }
}
