<div *ngIf="vm.shouldRender" class="reorder-list-actions">
  <jui-icon
    *ngIf="qaIndex"
    class="reorder-list__handle"
    cursor="move"
    name="drag_handle"
    [attr.data-qa]="'sortable-panel--' + qaIndex"
    cdkDragHandle
    [openDelay]="1000"
    ngbTooltip="{{ 'common.move' | transloco }} {{ 'common.actions' | transloco }}"
    placement="right"
    tooltipClass="jt-tooltip--secondary-darker"
  >
  </jui-icon>
  <div class="tasks card">
    <div>
      <div class="tasks__header card-header">
        <div class="tasks__header-title" [attr.data-qa]="'actionsPanel'">
          <div>{{ vm.label }}</div>
          <div class="edit-card text-right"></div>
        </div>
      </div>
      <div class="card-body tasks__body" *ngIf="vm.showLoader" [attr.data-qa]="'actionsSpinner'">
        <div class="task-group-wrapper">
          <div class="loader-spacer">
            <jui-skeleton-loader count="3" height="28px" color="primary"></jui-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="card-body tasks__body" *ngIf="vm.showTasks">
        <div class="task-group-wrapper">
          <ng-container *ngFor="let task of vm.tasks">
            <app-task [vm]="task" (toggleClick)="toggle($event)" (actionClick)="action($event)"></app-task>
          </ng-container>
          <ng-container *ngIf="!vm.isArchived">
            <pathway-contact-log-action *featureFlag="CONTACT_LOGGING_LD_FEATURE_KEY" [projectId]="project.id" [activeTheme]="vm.activeTheme"></pathway-contact-log-action>
          </ng-container>
        </div>
      </div>
      <task-invalid-items-component></task-invalid-items-component>
      <task-action-component></task-action-component>
      <task-error-component></task-error-component>
    </div>
  </div>
</div>
