import { Component, OnInit } from '@angular/core';

import 'brace';
import 'brace/mode/json';
import 'brace/mode/html';
import 'brace/mode/markdown';
import 'brace/theme/dracula';
import { I18nTypeComponent } from './i18n/i18n-type.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-i18n-text-type',
  template: `
    <form *ngIf="form" [formGroup]="form">
      <div class="i18n-wrapper">
        <div class="i18n-right">
          <textarea
            [cols]="to.cols || 5"
            [rows]="to.rows || 2"
            class="form-control i18n-save"
            type="input"
            [value]="value"
            (focusout)="setLanguageValue($event.target)"
            (change)="setLanguageValue($event.target)"
          ></textarea>
        </div>
      </div>
    </form>
  `,
  styles: [
    `
      .i18n-wrapper {
        display: flex;
      }

      .i18n-icon {
        padding-right: 2px;
      }

      .i18n-wrapper > .i18n-left {
        flex: 1;
      }

      .i18n-wrapper > .i18n-right {
        flex: 1;
      }

      .material-icons {
        cursor: pointer;
      }
    `
  ]
})
export class I18nTextTypeComponent extends I18nTypeComponent implements OnInit {}
