import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AssignmentMappingDisplay, IAutoAssignMappingsTestVm } from '../../domain';
import { AssignmentMappingsTesterPresenter } from './assignment-mappings-tester.presenter';

@Component({
  selector: 'app-assignment-mappings-tester',
  templateUrl: './assignment-mappings-tester.component.html',
  styleUrls: ['./assignment-mappings-tester.component.scss']
})
export class AssignmentMappingsTesterComponent implements OnInit {
  _mappings: AssignmentMappingDisplay[];
  vm: IAutoAssignMappingsTestVm;

  @Input() set mappings(mappings: AssignmentMappingDisplay[]) {
    this._mappings = mappings;
  }

  constructor(private presenter: AssignmentMappingsTesterPresenter) {}

  async ngOnInit() {
    await this.presenter.load(generatedVm => {
      this.vm = generatedVm;
    });
  }

  async test() {
    await this.presenter.test(this.vm, this._mappings);
  }

  changeProjectType(event: string[]) {
    this.presenter.changeProjectType(this.vm, event[0]);
  }

  changeMatch(event: FocusEvent) {
    this.presenter.changeMatch(this.vm, (event.target as HTMLInputElement).value);
  }

  clear() {
    this.presenter.clear(this.vm);
  }
}
