import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-modal',
  template: `<div class="modal-wrapper modal--confirm">
    <div class="modal-header">
      <div>{{ title }}</div>
    </div>
    <div class="modal-body">
      <div *ngFor="let message of messages">{{ message }}</div>
    </div>
    <div class="modal-footer text-center">
      <jui-button [attr.data-qa]="'alertModalBtn-ok'" size="sm" role="button" (click)="close($event)">
        {{ 'common.ok' | transloco }}
      </jui-button>
    </div>
  </div>`
})
export class AlertModalComponent {
  @Input() title: string;
  @Input() messages: string[];

  constructor(public activeModal: NgbActiveModal) {}

  close($event) {
    $event.preventDefault();
    this.activeModal.close();
  }
}
