import { Injectable } from '@angular/core';

interface ProjectOwnership {
  projectId: string;
  isPrimaryOwner: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectOwnerService {
  private projectOwnership: ProjectOwnership[] = [];

  public setForProjectId(projectId: string, isPrimaryOwner: boolean) {
    const projectOwnership: ProjectOwnership = { projectId, isPrimaryOwner };
    const existingProjectOwnershipIdx = this.projectOwnership.findIndex(po => po.projectId === projectId);
    if (existingProjectOwnershipIdx === -1) {
      this.projectOwnership.push(projectOwnership);
    } else {
      this.projectOwnership[existingProjectOwnershipIdx] = projectOwnership;
    }
  }

  public isPrimaryOwner(projectId: string) {
    const existingProjectOwnershipIdx = this.projectOwnership.findIndex(po => po.projectId === projectId);
    if (existingProjectOwnershipIdx === -1) {
      return false;
    }
    return (
      this.projectOwnership[existingProjectOwnershipIdx] &&
      this.projectOwnership[existingProjectOwnershipIdx].isPrimaryOwner
    );
  }
}
