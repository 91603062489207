import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalConfig } from '../../domain/confirm-modal.config';

// TODO : introduce presenter

@Component({
  selector: 'confirm-modal',
  templateUrl: 'confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input() config!: ConfirmModalConfig;
  @Input() parameters: any;

  constructor(public activeModal: NgbActiveModal) {}

  confirm($event: Event) {
    $event.preventDefault();
    this.activeModal.close(true);
  }

  cancel($event: Event) {
    $event.preventDefault();
    this.activeModal.dismiss();
  }
}
