import { Component, Input } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';

@Component({
  selector: 'crds-question-hint',
  template: `
    <div *ngIf="question.hint">
      <jui-alert-block size="sm" [iconName]="icon">
        <span [innerHtml]="question.hint"></span>
      </jui-alert-block>
    </div>
  `
})
export class QuestionHintComponent {
  @Input() question: QuestionBase<any>;

  get icon(): string {
    return this.question.icon || 'info';
  }
}
