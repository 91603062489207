import { Injectable } from '@angular/core';
import { RelayRepository } from '../../relay.repository';
import { ErrorViewModel } from './error.view.model';

@Injectable()
export class ErrorPresenter {
  constructor(private relayRepository: RelayRepository) {}

  load(callback) {
    const vm: ErrorViewModel = {
      error: null
    };
    this.relayRepository.getError(error => {
      vm.error = error;
      callback(vm);
    });
  }

  clearError() {
    this.relayRepository.clearError();
  }
}
