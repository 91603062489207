import { Injectable } from '@angular/core';
import { SectionQuestionViewModel } from './section.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SectionPresenter {
  load(vm$: BehaviorSubject<SectionQuestionViewModel | null>, hint: string, label: string): void {
    const viewModel: SectionQuestionViewModel = {
      hint: hint ?? '',
      label: label ?? ''
    };
    vm$.next(viewModel);
  }
}
