import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { NgModel } from '@angular/forms';

export interface DropDownElement {
  id: string;
  name?: string;
  label?: string;
  sticky?: boolean;
}

@Component({
  selector: 'app-multi-select',
  template: `
    <ng-select
      clearSearchOnAdd="false"
      bindLabel="item_text"
      bindValue="item_id"
      #multiSelectModelValue="ngModel"
      [appendTo]="appendTo"
      [labelForId]="labelForId"
      [clearable]="clearable"
      [class.expand]="itemsShowLimit > 2"
      [items]="dropdownList"
      [placeholder]="placeholder || ('common.pleaseSelect' | transloco)"
      [closeOnSelect]="singleSelection"
      [multiple]="!singleSelection"
      [clearOnBackspace]="true"
      [loading]="loading"
      [loadingText]="'common.loading' | transloco"
      [(ngModel)]="selectedListIds"
      (ngModelChange)="onItemChange($event)"
      [compareWith]="compareFn"
      [attr.data-qa]="'multiselectDropdown'"
      [disabled]="disabled"
    >
      <ng-container *ngIf="!singleSelection">
        <ng-template ng-header-tmp>
          <button (click)="onAllSelected(multiSelectModelValue)" class="btn btn-sm btn-outline-primary">
            {{ 'common.selectAll' | transloco }}
          </button>
          &nbsp;
          <button (click)="onAllDeselected(multiSelectModelValue)" class="btn btn-sm btn-outline-secondary">
            {{ 'common.unselectAll' | transloco }}
          </button>
        </ng-template>

        <ng-template *ngIf="itemsShowLimit" ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice: 0:itemsShowLimit">
            <span class="ng-value-label"> {{ item.item_text }} </span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </div>
          <div class="ng-value" *ngIf="items.length > itemsShowLimit">
            <span class="ng-value-label"
              >{{ items.length - itemsShowLimit }} / {{ items.length }} {{ 'common.results' | transloco }}</span
            >
          </div>
        </ng-template>

        <ng-template ng-footer-tmp>
          {{ 'common.selectedCount' | transloco }}: {{ selectedListIds.length || 0 }}
        </ng-template>
      </ng-container>
    </ng-select>
  `
})
export class MultipleSelectionDropdownComponent implements OnInit, OnChanges {
  dropdownList = [];
  selectedList = [];
  selectedListIds = [];
  dropdownSettings = {};

  _items: DropDownElement[] | null;
  _selectedItems: DropDownElement[];

  get items() {
    return this._items;
  }
  @Input() set items(items: any[]) {
    this._items = items || [];
  }
  get selectedItems() {
    return this._selectedItems;
  }
  @Input() set selectedItems(selectedItems: DropDownElement[]) {
    this._selectedItems = selectedItems || [];
  }
  @Input() bindId?: string;
  @Input() bindLabel?: string;
  @Input() appendTo?: string = '';
  @Input() labelForId?: string;
  @Input() alphaSort = false;
  @Input() placeholder?: string;
  @Input() singleSelection?: boolean = false;
  @Input() clearable?: boolean = true;
  @Input() allowSearchFilter?: boolean = true;
  @Input() loading?: boolean = false;
  @Input() itemsShowLimit?: number = 10;
  @Input() disabled = false;
  @Output() itemsChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  private getItemsList(selectedItems?: DropDownElement[]) {
    const items = this.items
      .filter(item => {
        return typeof item.name !== 'undefined' || typeof item[this.bindLabel] !== 'undefined';
      })
      .map(item => {
        return {
          item_id: item[this.bindId || 'id'],
          item_text: item[this.bindLabel || 'name'],
          sticky: item.sticky || false
        };
      })
      .sort((a, b) => {
        if (this.alphaSort) {
          return a.sticky ? -1 : a.item_text.localeCompare(b.item_text);
        } else {
          return a.sticky ? -1 : 1;
        }
      });

    if (!selectedItems) {
      return items;
    }

    return items.filter(item => selectedItems.indexOf(item.item_id) > -1);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (Object.hasOwnProperty.call(changes, propName)) {
        switch (propName) {
          case 'items':
            if (this.isValueChanged(changes[propName])) {
              this.dropdownList = [...this.getItemsList()];
            }
            break;
          case 'selectedItems':
            if (this.isValueChanged(changes[propName])) {
              this.selectedListIds = changes[propName].currentValue;
            }
            break;
        }
      }
    }
  }

  isValueChanged(changes: SimpleChange) {
    return !changes.isFirstChange() && JSON.stringify(changes.previousValue) !== JSON.stringify(changes.currentValue);
  }

  ngOnInit() {
    this.dropdownList = this.getItemsList();
    this.selectedList = this.selectedItems ? this.getItemsList(this.selectedItems) : [];
    this.selectedListIds = this.selectedItems?.length ? this.selectedList.map(x => x.item_id) : [];
  }

  onItemChange(selected) {
    if (typeof selected === 'string') {
      selected = [selected];
    }
    this.itemsChanged.emit(selected);
  }

  onAllSelected(select: NgModel) {
    this.selectedList = this.dropdownList.map(x => x.item_id);
    select.update.emit(this.selectedList);
    this.itemsChanged.emit(this.selectedList);
  }

  onAllDeselected(select: NgModel) {
    this.selectedList = [];
    select.update.emit(this.selectedList);
    this.itemsChanged.emit(this.selectedList);
  }

  compareFn(optionItem, selectionValue) {
    if (typeof selectionValue === 'string') {
      selectionValue = [selectionValue];
    }
    if (typeof optionItem === 'string') {
      return selectionValue.find(x => x === optionItem);
    } else {
      return selectionValue.find(x => x === optionItem.item_id);
    }
  }
}
