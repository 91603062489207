import { Injectable } from '@angular/core';
import { DocumentPackRepository } from '../document-pack.repository';
import { IDocumentProgress, IDocumentProgressVm } from '../document-pack.model';

@Injectable()
export class DocumentPackProgressPresenter {
  constructor(private repository: DocumentPackRepository) {}

  load(callback) {
    this.repository.getProgress((dataDm: IDocumentProgress) => {
      const viewModel: IDocumentProgressVm = {
        label: dataDm.progress ? `${dataDm.uploadingLabel} ${dataDm.fileName} ${dataDm.progress}%` : null,
        inProgress: dataDm.inProgress,
        isComplete: dataDm.progress ? dataDm.progress === 100 : null,
        percentageValue: dataDm.progress ? `${dataDm.progress}%` : null,
        qaProgressLbl: 'dmProgressLabel'
      };
      callback(viewModel);
    });
  }
}
