import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-info-group-wrapper-panel',
  template: `
    <div class="info-group">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
  styles: [
    `
      .info-group {
        padding-bottom: 5px;
      }
    `
  ]
})
export class InfoGroupWrapperComponent extends FieldWrapper {}
