<div class="user-data u-opacity-none a-fade-in--fast">
  <div class="user-history" [class.u-opacity-none]="!hasAppBooted" [class.a-fade-in--delayed]="!hasAppBooted">
    <jui-button
      size="xs"
      color="low"
      (click)="clearHistory.emit(true)"
      *ngIf="userHistory$ | async"
      class="user-history__clear"
      [attr.data-qa]="'clearUserHistoryButton'"
    >
      <span slot="icon" class="material-icons user-history__clear-icon">clear</span>
      <span> {{ 'Buttons.clear' | transloco }}</span>
    </jui-button>
    <div class="user-history__title">
      <span class="material-icons user-history__title-icon">history</span>
      {{ 'welcome.recentHistoryTitle' | transloco }}
    </div>
    <div
      class="user-history__items"
      [attr.data-qa]="'userHistoryResults'"
      *ngIf="userHistory$ | async as h; else noHistory"
    >
      <div class="user-history__item" *ngFor="let item of h" [attr.data-qa]="'userHistoryItem'">
        <span class="material-icons user-history__icon">pageview</span>
        <a [routerLink]="item.route">{{ item.title }}</a>
      </div>
    </div>
    <ng-template #noHistory>
      <div class="user-history__items">
        <div class="user-history__item" [attr.data-qa]="'userHistoryNoResults'">
          <em>{{ 'welcome.recentHistoryNoResults' | transloco }}</em>
        </div>
      </div>
    </ng-template>
  </div>
</div>
