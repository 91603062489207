<div class="doc-loaders" *ngFor="let loader of loaders">
  <div class="doc-loaders__item">
    <div class="doc-loaders__item--inline">
      <jui-skeleton-loader width="18px" color="info"></jui-skeleton-loader>
    </div>
    <div class="doc-loaders__item--inline">
      <jui-skeleton-loader class="doc-loaders__item--inline" [width]="loader.width"></jui-skeleton-loader>
    </div>
  </div>
  <div class="doc-loaders__item">
    <jui-skeleton-loader width="40px" height="18px" color="primary"></jui-skeleton-loader>
  </div>
</div>
