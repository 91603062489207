import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-address-field-wrapper',
  template: `
    <div class="d-flex align-content-stretch mb-1">
      <label [attr.for]="id" class="wrapper-label mr-1 col-form-label" *ngIf="to.label">
        {{ to.label }}
        <ng-container *ngIf="to.required === true">*</ng-container>
      </label>
      <div class="flex-grow-2">
        <ng-template #fieldComponent></ng-template>
        <div *ngIf="field.templateOptions.hint" class="mt-1 alert alert-info" role="alert">
          {{ field.templateOptions.hint }}
        </div>
      </div>
    </div>
    <div *ngIf="showError" class="mb-1 invalid-feedback d-block">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  `,
  styles: [
    `
      .wrapper-label {
        font-size: 11pt;
        width: 100px;
        word-break: break-word;
      }
      .form-group {
        background-color: #00000015;
      }
      .flex-grow-2 {
        flex-grow: 2;
      }
    `
  ]
})
export class AddressWrapperComponent extends FieldWrapper {}
