import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from './error-modal.component';
import { LOGIN_PATH } from '../app.routes';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../auth/services/authentication.service';

@Component({
  selector: 'app-error',
  template: ''
})
export class ErrorComponent {
  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) {
    if (!this.isUserSignedIn) {
      // re-route to login
      if (!environment.production) {
        console.log('Redirecting to log in because of error');
      }
      this.router.navigate([LOGIN_PATH], { replaceUrl: true });
    } else {
      this.openErrorModal();
    }
  }

  get isUserSignedIn() {
    return this.authenticationService.isUserSignedIn;
  }

  /**
   * Timeout prevents https://github.com/ng-bootstrap/ng-bootstrap/issues/1252
   */
  openErrorModal() {
    setTimeout(() => {
      this.modalService.open(ErrorModalComponent, { keyboard: false, backdrop: 'static' });
    });
  }
}
