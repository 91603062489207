<div class="assignment-projectTypes-component" *ngIf="vm" [attr.data-qa]="'assignment-projectTypes-component'">
  <ngx-datatable
    #subTenantTable
    [rows]="vm.projectAssignments"
    class="bootstrap"
    columnMode="force"
    reorderable="false"
    swapColumns="false"
    rowHeight="auto"
    [messages]="{ emptyMessage: vm.noDataErrorMessage }"
    [attr.data-qa]="'project-mappings-table'"
  >
    <ngx-datatable-column name="{{ 'common.projectType' | transloco }}" prop="projectType"></ngx-datatable-column>
    <ngx-datatable-column name="{{ 'common.tenants' | transloco }}" prop="tenantsDisplay"></ngx-datatable-column>
    <ngx-datatable-column name="{{ 'autoAssignment.autoAssignOn' | transloco }}" prop="actionId">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-idx="rowIndex">
        <app-multi-select
          [singleSelection]="true"
          [selectedItems]="[value]"
          [items]="row.actions"
          (itemsChanged)="updateAction(row, $event)"
          [allowSearchFilter]="false"
          [attr.data-qa]="'project-assignment-action-' + idx"
          [alphaSort]="true"
          appendTo="app-assignment-project-types"
          bindId="id"
          bindLabel="name"
        ></app-multi-select>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'autoAssignment.enabled' | transloco }}" prop="enabled" maxWidth="80">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-idx="rowIndex">
        <div ngbRadioGroup>
          <label ngbButtonLabel [class]="'btn btn-sm button-group-spaced'">
            <input
              type="checkbox"
              [checked]="value"
              [attr.data-qa]="'enabled-checkbox-' + idx"
              #buttonGroupInput
              (click)="updateEnabled(row, !value)"
            />
          </label>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
