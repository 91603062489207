import { Injectable, ErrorHandler } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(private errorHandler: ErrorHandler) {}

  log(error: Error | string) {
    if (typeof error === 'string') {
      error = new Error(error);
    }

    if (!environment.production) {
      console.log(JSON.stringify(error));
    }
  }

  error(error: unknown) {
    if (!error) {
      return; // there's no point in logging an empty error
    }
    if (typeof error === 'string') {
      error = new Error(error);
    }

    if (!environment.production) {
      console.error(error);
    }

    this.errorHandler.handleError(error);
  }
}
