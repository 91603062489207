import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface ExtendedQuestion {
  label: string;
  key: string;
  required?: boolean;
  options?: { key: string; value: string }[];
  singleSelection?: boolean;
}

interface Option {
  id: string;
  name: string;
}

@Component({
  selector: 'app-ng-select-dropdown',
  template: `
    <label *ngIf="question.label" class="form-label" [attr.for]="question.key"
      >{{ question.label }}
      <span *ngIf="question.required"> *</span>
    </label>
    <app-multi-select
      [items]="items"
      [selectedItems]="toSelectedItems(question)"
      (itemsChanged)="onSelectionChanged($event)"
      [singleSelection]="singleSelection"
    >
    </app-multi-select>
  `
})
export class NgSelectDropdownComponent {
  @Input() form: UntypedFormGroup;
  @Input() question: ExtendedQuestion;
  @Input() selectedItems?: string[];
  @Input() singleSelection?: boolean = true;

  private _items: Option[] = [];

  get items() {
    if (!this._items.length) {
      this._items = this.question.options.map(questionOption => ({
        id: questionOption.value,
        name: questionOption.key
      }));
    }
    return this._items;
  }

  onSelectionChanged($event: any[]) {
    const value = !$event.length ? null : this.singleSelection ? $event[0] : $event;
    this.form.get(this.question.key).patchValue(value);
  }

  toSelectedItems(question: ExtendedQuestion) {
    const formValue = this.form.get(question.key);
    if (!formValue.value) {
      return [];
    }
    return Array.isArray(formValue.value) ? formValue.value : [formValue.value];
  }
}
