const commonProperties: {
  subTenant?: string;
  tenant?: string;
} = {};

export function logEvent(event: string, data: any) {
  try {
    if (window?.['pendo']?.isReady()) {
      window['pendo'].track(event, {
        ...commonProperties,
        ...data
      });
    }
  } catch (e) {
    console.error(`PENDO - track event not working: ${e}`);
  }
}

export function logNavigation(url: string) {
  logEvent('Navigate', { url });
}

export function setTenant(tenant: string, subTenant?: string) {
  commonProperties.tenant = tenant;
  commonProperties.subTenant = subTenant;
}

// for now, we can enable tracking the answers of edit questions by adding the question ID to this list
const QuestionTrackingList = ['olevEvhsEligible'];

export function shouldTrackQuestion(questionId: string): boolean {
  if (!questionId) {
    return false;
  }
  return QuestionTrackingList.includes(questionId);
}
