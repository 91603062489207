import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AttachmentQuestionComponent } from './cardLayouts/attachment-question.component';
import { CardControlService } from './card-control.service';
import { EditLayoutQuestionsComponent } from './cardLayouts/edit-layout-questions.component';
import { GalleryLayoutComponent } from './cardLayouts/gallery-layout.component';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { LayoutUpdateService } from './layout-update.service';
import { ListLayoutComponent } from './cardLayouts/list-layout.component';
import { ModalLayoutComponent } from './cardLayouts/modal-layout.component';
import { MultiTextWithAttachment } from './cardLayouts/multi-text-with-attachment.component';
import { NgxLoadingModule } from 'ngx-loading';
import { NowDateQuestionComponent } from './cardLayouts/now-date-question.component';
import { PrintingService } from './print.service';
import { ProjectToDnoNotificationTransformer } from './transformers/project-to-dno-notification-transformer';
import { ProjectUpdateService } from './project-update.service';
import { SharedModule } from '../shared/shared.module';
import { S3Service } from './s3.service';
import { UploadFileInput } from './directives/upload-file-input';
import { W3wService } from './w3w.service';
import { ExportService } from '../dashboards/dashboard-export/export-service';
import { NgxCsvWrapper } from '../dashboards/dashboard-export/ngx-csv-wrapper';
import { AppDateTimeDurationSelectComponent } from './cardLayouts/date-time-selector.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NavbarComponent } from './navbar/navbar.component';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { RouterModule } from '@angular/router';
import { AvailabilityQuestionComponent } from './cardLayouts/availability-question.component';
import { AddProjectButtonComponent } from '../dashboards/buttons/add-project-button.component';
import { NewProjectModalComponent } from './new-project/new-project-modal.component';
import { JobTypeService } from './job-type.service';
import { ArrayQuestionComponent } from './cardLayouts/array-question.component';
import { FeatureFlagDirective } from './feature-flag/featureFlagDirective';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n } from './custom-ngb-datepicker-i18n.service';
import { CustomDateParserFormatter } from './custom-ngb-date.parser';
import { MenuItemComponent } from './navbar/menu-item/menu-item.component';
import { SearchBarComponent } from './search/search-bar/search-bar.component';
import { SearchResultsInlinePresenter } from './search/search-results-inline/search-results-inline-presenter';
import { SearchResultInlineComponent } from './search/search-results-inline/search-result-inline.component';
import { SearchResultsInlineComponent } from './search/search-results-inline/search-results-inline.component';
import { SearchBarPresenter } from './search/search-bar/search-bar.presenter';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { AddressLookupV2Component } from '@jump-tech-frontend/address-lookup-v2';

@NgModule({
  declarations: [
    AddProjectButtonComponent,
    AppDateTimeDurationSelectComponent,
    AttachmentQuestionComponent,
    EditLayoutQuestionsComponent,
    GalleryLayoutComponent,
    NewProjectModalComponent,
    NowDateQuestionComponent,
    ListLayoutComponent,
    ModalLayoutComponent,
    MultiTextWithAttachment,
    ArrayQuestionComponent,
    NavbarComponent,
    SearchBarComponent,
    SearchResultsInlineComponent,
    SearchResultInlineComponent,
    UploadFileInput,
    UserPanelComponent,
    AvailabilityQuestionComponent,
    FeatureFlagDirective,
    MenuItemComponent
  ],
  imports: [
    CommonModule,
    FullCalendarModule,
    JumptechCardsLibModule,
    NgxLoadingModule,
    RouterModule,
    SharedModule,
    TranslocoModule,
    CoreComponentsAngularModule,
    AddressLookupV2Component
  ],
  providers: [
    CardControlService,
    ExportService,
    LayoutUpdateService,
    JobTypeService,
    NgxCsvWrapper,
    PrintingService,
    ProjectUpdateService,
    ProjectToDnoNotificationTransformer,
    SearchBarPresenter,
    SearchResultsInlinePresenter,
    S3Service,
    W3wService,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ],
  exports: [
    AddProjectButtonComponent,
    AppDateTimeDurationSelectComponent,
    AttachmentQuestionComponent,
    EditLayoutQuestionsComponent,
    GalleryLayoutComponent,
    ListLayoutComponent,
    ModalLayoutComponent,
    MultiTextWithAttachment,
    ArrayQuestionComponent,
    NavbarComponent,
    NowDateQuestionComponent,
    SearchBarComponent,
    SearchResultsInlineComponent,
    SearchResultInlineComponent,
    TranslocoModule,
    UploadFileInput,
    UserPanelComponent,
    AvailabilityQuestionComponent,
    FeatureFlagDirective,
    CoreComponentsAngularModule
  ]
})
export class CoreModule {}
