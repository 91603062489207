import { Inject, Injectable, Optional } from '@angular/core';
import Bugsnag, { Client } from '@bugsnag/js';
import { AppVersion } from '../app-version-token';
import { EnvironmentToken } from '../environment/enviroment-token';
import { Environment } from '../environment/environment';
import { BugsnagToken } from './bugsnag-token';

@Injectable()
export class BugsnagClient {
  private _client: Client;

  constructor(
    @Inject(EnvironmentToken) private environment: Environment,
    @Optional() @Inject(BugsnagToken) private token: string,
    @Optional() @Inject(AppVersion) private appVersion: string
  ) {}

  getClient(): Client | undefined {
    if (!this._client && !!Bugsnag) {
      this._client = Bugsnag.start({
        appVersion: this.appVersion,
        apiKey: this.token,
        releaseStage: this.environment.name === 'dev' ? 'development' : this.environment.name
      });
    }
    return this._client;
  }
}
