import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {
  @Output() onFileDropped = new EventEmitter<any>();

  private classListDefault = 'image-drop__input';
  private classListDrag = 'image-drop__input image-drop__input--drag';

  @HostBinding('class') public classList = this.classListDefault;

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.classList = this.classListDrag;
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.classList = this.classListDefault;
  }

  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.classList = this.classListDefault;

    const files = evt.dataTransfer.files;
    const maxFileSizeBytes = 200000; // 200kB

    if (files.length === 0 || files[0].size > maxFileSizeBytes || files[0].type !== 'image/png') {
      this.onFileDropped.emit({ ok: false });
    } else {
      DragDropDirective.arrayBufferToBase64(files[0].arrayBuffer()).then(base64 =>
        this.onFileDropped.emit({ ok: true, base64: `data:image/png;base64,${base64}` })
      );
    }
  }

  private static async arrayBufferToBase64(buffer: Promise<Uint8Array>) {
    let binary = '';
    const bytes = new Uint8Array(await buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
