import { NgModule } from '@angular/core';

import { CamelToReadablePipe } from './camel-to-readable.pipe';
import { FirstCharsPipe } from './first-chars.pipe';
import { RemoveUnderscorePipe } from './remove-underscore.pipe';
import { ValueSanitiserPipe } from './value-sanitiser.pipe';

@NgModule({
  declarations: [CamelToReadablePipe, FirstCharsPipe, RemoveUnderscorePipe, ValueSanitiserPipe],
  exports: [CamelToReadablePipe, FirstCharsPipe, RemoveUnderscorePipe, ValueSanitiserPipe]
})
export class PipesModule {}
