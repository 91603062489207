<div class="modal-wrapper">
  <ngx-spinner bdColor="var(--jds-theme-spinner-bd-rgba)" size="large" type="line-scale" [attr.data-qa]="'spinner'">
  </ngx-spinner>
  <div class="modal-header-wrapper">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-banner">{{ 'delegation.headings.assignProject' | transloco }}</div>
  </div>
  <div class="modal-body">
    <label>{{ 'common.installer' | transloco }} *</label>
    <app-multi-select
      *ngIf="selectDelegateItems.length"
      [singleSelection]="true"
      [selectedItems]="selectedDelegateIds"
      [items]="selectDelegateItems"
      (itemsChanged)="setDelegate($event)"
      [attr.data-qa]="'delegateInstallerSelect'"
    ></app-multi-select>
    <div *ngIf="selectSubTenantItems?.length">
      <label class="mt-3">{{ 'dashboard.installerTeamOrSubTenant' | transloco }} *</label>
      <app-multi-select
        [singleSelection]="true"
        [selectedItems]="selectedSubTenantIds"
        [items]="selectSubTenantItems"
        (itemsChanged)="setSubTenants($event)"
        [attr.data-qa]="'delegateSubTenantSelect'"
      ></app-multi-select>
    </div>
    <jui-alert-block color="warning" [attr.data-qa]="'warning'">
      <span>{{ 'delegation.messages.assignProjectToInstaller' | transloco }}</span>
    </jui-alert-block>
  </div>
  <div class="modal-footer">
    <jui-button size="sm" [disabled]="disableSubmit()" (click)="submit()" [attr.data-qa]="'submitButton'">
      {{ 'common.submit' | transloco }}
    </jui-button>
  </div>
</div>
