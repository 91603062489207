<ng-container [formGroup]="vm.orderForm">
<!--  <span class="my-orders__sub-title">{{vm.packageName}}</span>-->
  <div class="my-orders__split-row">
    <div class="my-orders__split-ctrl">
      <label class="my-orders__label">{{ vm.labelProducts }}</label>
      <ng-select
        [items]="vm.productList"
        [placeholder]="vm.placeholderSelectProducts"
        formControlName="selectedProducts"
        [closeOnSelect]="true"
        [clearable]="false"
        [bindLabel]="'name'"
        [searchFn]="searchItems"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div class="my-orders__option-label" [ngOptionHighlight]="search">
            {{item.name}}
          </div>
          <div class="my-orders__option-desc" [ngOptionHighlight]="search">
            {{item.description}}
          </div>
        </ng-template>
      </ng-select>
      <span
        class="my-orders__error"
        *ngIf="vm.orderForm.get('selectedProducts').getError('required') && vm.orderForm.get('selectedProducts').touched"
      >{{ vm.validationRequired }}</span
      >
    </div>
    <div class="my-orders__split-ctrl">
      <label class="my-orders__label">{{ vm.labelAccessories }} {{ vm.validationOptional }}</label>
      <ng-select
        [items]="vm.accessoriesList"
        [multiple]="true"
        [placeholder]="vm.placeholderSelectAccessories"
        formControlName="selectedAccessories"
        [closeOnSelect]="false"
        [clearSearchOnAdd]="true"
        [bindLabel]="'name'"
        [searchFn]="searchItems"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div class="my-orders__option-label" [ngOptionHighlight]="search">
            {{item.name}}
          </div>
          <div class="my-orders__option-desc" [ngOptionHighlight]="search">
            {{item.description}}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>


  <div class="my-orders__split-row">
    <div class="my-orders__split-ctrl">
    <label class="my-orders__label">{{ vm.labelPreferredShipmentDate }}</label>
    <div class="input-group">
      <input
        class="form-control my-orders__ctrl"
        [placeholder]="vm.shipmentDatePlaceholder"
        name="dp"
        id="preferredDate"
        [markDisabled]="markDateDisabled"
        [minDate]="vm.shipmentDateEarliest"
        ngbDatepicker
        #preferredDate="ngbDatepicker"
        [attr.data-qa]="'preferredDateInput'"
        formControlName="preferredShipmentDate"
        container="body"
      />
      <div class="input-group-append my-orders__ctrl-append">
        <button
          class="my-orders__input-btn calendar"
          (click)="preferredDate.toggle()"
          type="button"
          [attr.data-qa]="'preferredDateButton'"
          #calendarButton
        >
          <span class="my-orders__input-btn-icon material-icons">event</span>
        </button>
      </div>
    </div>
    <span
      class="my-orders__error"
      *ngIf="
        vm.orderForm.get('preferredShipmentDate').errors?.required && vm.orderForm.get('preferredShipmentDate').touched
      "
    >
      {{ vm.validationRequired }}
    </span>
    <span
      class="my-orders__error"
      *ngIf="
        vm.orderForm.get('preferredShipmentDate').errors?.weekdayOnly &&
        vm.orderForm.get('preferredShipmentDate').touched
      "
    >
      {{ vm.validationWeekday }}
    </span>
    <span
      class="my-orders__error"
      *ngIf="
        vm.orderForm.get('preferredShipmentDate').errors?.ngbDate?.minDate &&
        vm.orderForm.get('preferredShipmentDate').touched
      "
    >
      {{ vm.validationMinShipDate }}</span
    >
    <span
      class="my-orders__error"
      *ngIf="
        vm.orderForm.get('preferredShipmentDate').errors?.ngbDate?.invalid &&
        vm.orderForm.get('preferredShipmentDate').touched
      "
    >
      {{ vm.validationInvalidDate }}</span
    >
    </div>
  </div>
</ng-container>
