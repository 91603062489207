<div [formGroup]="vm.orderForm" *ngIf="vm.showCustomAddress">
  <div formGroupName="addressForm">
    <div class="my-orders__custom">
      <!-- CUSTOMER INFO -->
      <div class="my-orders__user">
        <div class="my-orders__sub-title">{{ vm.titleContactDetails }}</div>
        <div class="my-orders__split-row">
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelName }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelName" formControlName="name" [attr.data-qa]="vm.qaCustomAddress_name" />
            <span
              class="my-orders__error"
              *ngIf="
                vm.orderForm.get('addressForm.name').getError('required') &&
                vm.orderForm.get('addressForm.name').touched
              "
              >{{ vm.validationRequired }}
            </span>
          </div>
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelCompany }} {{ vm.validationOptional }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelCompany" formControlName="company" [attr.data-qa]="vm.qaCustomAddress_company" />
          </div>
        </div>

        <div class="my-orders__split-row">
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelPhone }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelPhone" formControlName="phone" [attr.data-qa]="vm.qaCustomAddress_phone" />
            <span
              class="my-orders__error"
              *ngIf="
                vm.orderForm.get('addressForm.phone').getError('required') &&
                vm.orderForm.get('addressForm.phone').touched
              "
              >{{ vm.validationRequired }}
            </span>
          </div>
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelEmail }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelEmail" formControlName="email" [attr.data-qa]="vm.qaCustomAddress_email" />
            <span
              class="my-orders__error"
              *ngIf="
                vm.orderForm.get('addressForm.email').getError('required') &&
                vm.orderForm.get('addressForm.email').touched
              "
              >{{ vm.validationRequired }}
            </span>
            <span
              class="my-orders__error"
              *ngIf="
                vm.orderForm.get('addressForm.email').getError('email') && vm.orderForm.get('addressForm.email').touched
              "
              >{{ vm.validationInvalidEmail }}
            </span>
          </div>
        </div>
      </div>

      <!-- ADDRESS INFO -->
      <div class="my-orders__address">
        <div class="my-orders__sub-title">{{ vm.titleAddress }}</div>
        <div class="my-orders__split-row">
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelLine1 }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelLine1" formControlName="line1" [attr.data-qa]="vm.qaCustomAddress_line1" />
            <span
              class="my-orders__error"
              *ngIf="
                vm.orderForm.get('addressForm.line1').getError('required') &&
                vm.orderForm.get('addressForm.line1').touched
              "
              >{{ vm.validationRequired }}
            </span>
          </div>
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelLine2 }} {{ vm.validationOptional }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelLine2" formControlName="line2" [attr.data-qa]="vm.qaCustomAddress_line2" />
          </div>
        </div>

        <div class="my-orders__split-row">
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelTown }} {{ vm.validationOptional }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelTown" formControlName="town" [attr.data-qa]="vm.qaCustomAddress_town" />
          </div>
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelLocality }} {{ vm.validationOptional }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelLocality" formControlName="locality" [attr.data-qa]="vm.qaCustomAddress_locality" />
          </div>
        </div>

        <div class="my-orders__split-row">
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelCounty }} {{ vm.validationOptional }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelCounty" formControlName="county" [attr.data-qa]="vm.qaCustomAddress_county" />
          </div>
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelCountry }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelCountry" formControlName="country" [attr.data-qa]="vm.qaCustomAddress_country" readonly />
          </div>
        </div>

        <div class="my-orders__split-row">
          <div class="my-orders__split-ctrl">
            <label class="my-orders__label">{{ vm.labelPostcode }} {{ vm.validationOptional }}</label>
            <input class="form-control" type="text" [placeholder]="vm.labelPostcode" formControlName="postCode" [attr.data-qa]="vm.qaCustomAddress_postCode" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
