import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Project } from '../../core/domain/project';
import { ActionSubmitted, ITasksVm } from './task.model';
import { TasksPresenter } from './tasks.presenter';
import { CONTACT_LOGGING_LD_FEATURE_KEY } from '../../feature-modules/contact-logging/contact-log.model';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksComponent implements OnInit, OnChanges, OnDestroy {
  @Input() project: Project;
  @Input() qaIndex: number;

  @Output() stateUpdate: EventEmitter<void> = new EventEmitter();
  @Output() infoMessage: EventEmitter<string> = new EventEmitter();
  @Output() goto: EventEmitter<string> = new EventEmitter();
  @Output() delegated: EventEmitter<void> = new EventEmitter();
  @Output() actioned: EventEmitter<ActionSubmitted> = new EventEmitter();

  constructor(private presenter: TasksPresenter, private changeDetector: ChangeDetectorRef) {}

  vm: ITasksVm;
  CONTACT_LOGGING_LD_FEATURE_KEY = CONTACT_LOGGING_LD_FEATURE_KEY;

  async ngOnInit() {
    await this.presenter.load(this.project, vm => {
      this.vm = vm;
      this.changeDetector.markForCheck();
    });

    this.presenter.getStateUpdates(() => {
      if (this.stateUpdate) {
        this.stateUpdate.emit();
      }
    });

    this.presenter.getInfoMessage(message => {
      if (this.infoMessage) {
        this.infoMessage.emit(message);
      }
    });

    this.presenter.getGoto(goto => {
      if (this.goto) {
        this.goto.emit(goto);
      }
    });

    this.presenter.getDelegated(() => {
      if (this.delegated) {
        this.delegated.emit();
      }
    });

    this.presenter.getActioned(action => {
      if (this.actioned) {
        this.actioned.emit(action);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project && this.project) {
      this.presenter.updateProject(this.project);
    }
  }

  ngOnDestroy(): void {
    this.presenter.cleanUp();
  }

  toggle(id: string) {
    this.presenter.toggleTaskStatus(id);
  }

  action(id: string) {
    this.presenter.actionTask(id);
  }
}
