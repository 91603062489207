<div class="card-wrapper">
  <div class="card">
    <div class="card-body">
      <div class="card-text" [innerHTML]="description" [attr.data-qa]="'description'"></div>

      <input id="ocr" type="file" style="display: none" (change)="setFromOcr($event)" accept="image/*" />

      <crds-image-asset [images]="images" [key]="'ocr'"></crds-image-asset>

      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" type="line-scale" [attr.data-qa]="'spinner'">
      </ngx-spinner>
    </div>
  </div>
</div>
<div class="form-wrapper">
  <form [formGroup]="form">
    <crds-questions [form]="form" [card]="card" [i18ns]="i18ns"></crds-questions>
  </form>
  <crds-form-error
    *ngIf="uploadError"
    [message]="uploadError"
    [i18ns]="i18ns"
    class="file-upload-error"
  ></crds-form-error>
</div>
