import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { NewProjectLayout, ProjectConfiguration, ProjectTypeType } from './domain/project-configuration';
import { PathwayFeature } from '../auth/services/access.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root' // Ensures we get a singleton even with lazy-loading
})
export class ProjectConfigurationService {
  private projectTypes: { [key: string]: ProjectTypeType[] } = {};
  private projectConfigurations: {
    [configurationName: string]: ProjectConfiguration;
  } = {};

  constructor(private apiService: ApiService) {}

  async getProjectTypes(
    featureName: string = PathwayFeature.MainDashboard,
    projectionExpression = 'projectType'
  ): Promise<ProjectTypeType[]> {
    const key = `${featureName}_${projectionExpression}`;
    if (!this.projectTypes[key]) {
      this.projectTypes[key] = await this.apiService.getProjectConfigurationTypes(featureName, projectionExpression);
    }
    return this.projectTypes[key];
  }

  async getProjectConfiguration(projectType: string, nocache = false) {
    if (nocache) {
      return await firstValueFrom(this.apiService.getProjectConfiguration(projectType));
    }
    if (!this.projectConfigurations[projectType]) {
      this.projectConfigurations[projectType] = await firstValueFrom(
        this.apiService.getProjectConfiguration(projectType)
      );
    }
    return this.projectConfigurations[projectType];
  }

  async getNewProjectLayoutByProjectType(projectType: string): Promise<NewProjectLayout> {
    return (await this.getProjectConfiguration(projectType))?.newProjectLayout;
  }

  flush() {
    this.projectConfigurations = {};
    this.projectTypes = {};
  }
}
