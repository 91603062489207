<div class="order-view__fulfilment" *ngFor="let part of vm.fulfilmentParts">
  <div class="order-view__sub-title">{{ part.title }}:</div>
  <div class="order-view__data" *ngIf="part.fulfilmentOrderNumber">
    <div class="order-view__key">{{ vm.labelOrderNumber }}</div>
    <div class="order-view__val order-view__val--emphasis">{{ part.fulfilmentOrderNumber }}</div>
  </div>
  <div class="order-view__data">
    <div class="order-view__key">{{ vm.labelOrderStatus }}</div>
    <div class="order-view__val">
      <span class="order-view__status-chip">{{ part.fulfilmentOrderStatusDisplay }}</span>
    </div>
  </div>
  <div class="order-view__data" *ngIf="part.externalErrorCode">
    <div class="order-view__key order-view__key--error">{{ vm.labelExternalError }}</div>
    <div class="order-view__val">{{ part.externalErrorCode }}</div>
  </div>
  <ng-container *ngFor="let shipment of part.shipments">
    <div class="order-view__shipment">
      <div class="order-view__data">
        <div class="order-view__key">{{ vm.labelCarrier }}</div>
        <div class="order-view__val">{{ shipment.carrier }}</div>
      </div>
      <div class="order-view__data">
        <div class="order-view__key">{{ vm.labelShippedDate }}</div>
        <div class="order-view__val">{{ shipment.shippedDate }}</div>
      </div>
      <div class="order-view__data">
        <div class="order-view__key">{{ vm.labelItems }}</div>
        <div class="order-view__val order-view__val--items" *ngIf="!part.showPendingItems">
          {{ shipment.itemsDisplay }}
        </div>
        <div class="order-view__val order-view__val--pending-items" *ngIf="part.showPendingItems">
          {{ shipment.pendingItemsDisplay }}
        </div>
      </div>
      <div class="order-view__data">
        <div class="order-view__key">{{ vm.labelTracking }}</div>
        <div class="order-view__val">
          {{ shipment.trackingReference }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
