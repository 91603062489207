import { Injectable } from '@angular/core';
import { ProjectAction } from '../../task.model';
import { TasksRepository } from '../../tasks.repository';

@Injectable()
export class ActionPresenter {
  constructor(private repository: TasksRepository) {}
  load(callback) {
    this.repository.getAction(vm => {
      callback(vm);
    });
  }

  invokeAction(action: ProjectAction, data: Record<string, unknown>) {
    this.repository.invokeAction(action, data);
  }

  clearAction() {
    this.repository.clearAction();
  }
}
