<div class="address-editor" *ngIf="vm$ | async as vm">
  <div class="address-editor__questions">
    <question-textbox
      *ngFor="let params of vm.addressFieldParams"
      [params]="params"
      (formChange)="fieldChanged($event)"
    ></question-textbox>
  </div>
  <div class="address-editor__actions">
    <jui-button size="sm" color="secondary" (click)="addressUpdated.next(vm.originalAddress)">
      {{ vm.i18n.cancel }}</jui-button
    >
    <jui-button size="sm" [disabled]="vm.form.invalid" (click)="addressUpdated.next(vm.form.value)">
      {{ vm.i18n.ok }}</jui-button
    >
  </div>
</div>
