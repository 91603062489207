import { CardActionBase } from './card-action.base';
import { Datasource } from '../datasource';

export class CarouselMultipleAction extends CardActionBase {
  type: string;
  targetField?: string;
  mainSlides: {
    text: string;
  }[];
  dynamicSlides: { name: string; slides: { text: string }[] }[];
  assets: string;
  datasource?: Datasource;
}
