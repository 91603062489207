import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, NgIf } from '@angular/common';
import { ContactLogModalComponent } from '../contact-log-modal/contact-log-modal.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ContactLog, IContactLogActionVm } from '../contact-log.model';
import { ContactLogActionPresenter } from './contact-log-action.presenter';
import { BehaviorSubject } from 'rxjs';
import { ContactLogRepository } from '../contact-log.repository';

@Component({
  selector: 'pathway-contact-log-action',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<ng-container *ngIf="vm$ | async as vm">
    <jui-button size="sm" expand="true" [attr.data-qa]="'addContactLog'" (click)="openModal()" [display]="activeTheme === 'dark' ? 'ghost' : 'contained'">
      {{ vm.addContactLog }}
    </jui-button></ng-container
  >`,
  imports: [NgIf, AsyncPipe, ContactLogModalComponent, CoreComponentsAngularModule],
  providers: [ContactLogActionPresenter, ContactLogRepository]
})
export class ContactLogActionComponent implements OnInit {
  @Input() projectId: string;
  @Input() activeTheme: string;
  public vm$ = new BehaviorSubject<IContactLogActionVm>(null);
  constructor(private presenter: ContactLogActionPresenter, private modalService: NgbModal) {}

  async ngOnInit(): Promise<void> {
    await this.presenter.load(this.vm$);
  }

  openModal() {
    const modalRef = this.modalService.open(ContactLogModalComponent, { size: 'md' });
    modalRef.result
      .then((contactLog: ContactLog) => {
        this.presenter.saveContactLog(this.projectId, contactLog);
      })
      .catch(() => undefined);
  }
}
