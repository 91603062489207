import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NguCarousel } from '@ngu/carousel';
import * as JmesPath from 'jmespath';
import { ApiService } from '../../core/api.service';
import { Card } from '../../domain/card';
import { CarouselAction } from '../../domain/card-actions/carousel.action';
import { Datasource } from '../../domain/datasource';
import { I18nKeys } from '../../domain/i18n-keys';
import { AbstractCarouselComponent } from './abstract-carousel.component';

@Component({
  selector: 'crds-carousel-form',
  templateUrl: './carousel-form.component.html',
  styles: [
    `
      :host ::ng-deep ngu-item {
        margin-top: 0.25rem;
      }
    `
  ]
})
export class CarouselFormComponent extends AbstractCarouselComponent implements OnChanges, AfterViewInit {
  @Input() form: UntypedFormGroup;
  @Input() card: Card<CarouselAction>;
  @Input() i18ns: I18nKeys;
  @ViewChild('carouselMain') carouselMain: NguCarousel<any>;

  constructor(private sanitizer: DomSanitizer, protected apiService: ApiService) {
    super(apiService);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.action = this.card.action;

    if (this.action.datasource) {
      if (!this.action.datasource.transform) {
        this.action.datasource.transform = '[*].{ text: name, name: name, content: content }';
      }
      await this.getData(this.action.datasource);
    }

    this.setCurrentMainSlide(this.getCurrentMainSlideFromForm());
    this.updateTargetField();
    this.carouselMainTile = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      point: {
        visible: false
      },
      load: 5,
      touch: true,
      loop: false
    };
    this.initialisedSubject.next(true);
  }

  ngAfterViewInit() {
    const self = this;
    this.initialisedSubject.asObservable().subscribe(initialised => {
      if (initialised) {
        setTimeout(() => {
          self.carouselMain.moveTo(self.getCurrentMainSlideFromForm(), false);
        }, 10);
      }
    });
  }

  mapData(result: any[], datasource: Datasource) {
    const slides: [] = JmesPath.search(result, datasource.transform) || [];

    if (slides && slides.length) {
      this.card.action.mainSlides = slides;
    } else if (!this.card.action.mainSlides || !this.card.action.mainSlides.length) {
      this.card.action.mainSlides = [];
    }
  }

  updateMainSlide(currentSlide: number) {
    if (currentSlide !== this.currentMainSlide) {
      this.setCurrentMainSlide(currentSlide);
      this.updateTargetField();
    }
  }

  updateTargetField() {
    setTimeout(() => {
      this.form.get(this.action.targetField).patchValue(this.getCurrentMainSlideName());
    });
  }

  toSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
