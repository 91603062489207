import allLocales from '@fullcalendar/core/locales-all';
import * as moment from 'moment';
import { EventInput } from '@fullcalendar/core';
import { Job } from '../../core/domain/job';
import { DateRangeSelectInfo, JobStyle, ScheduleQuestion } from './schedule-types';
import { JOB_STYLES, TEMP_EVENT_ID } from './schedule-constants';
import { ProjectStatus } from '../../admin/user-management/domain/types';

export function resourcesFromUsers(users: { key: string; value: string }[]) {
  return users.map(user => ({
    ...user,
    id: user.value.split('|')[1],
    title: user.key
  }));
}

// NB this should control everything except the styles which put a thick line between the days
const workingHoursStart = 7;
const workingHoursEnd = 19;

const fullDayHoursStart = 0;
const fullDayHoursEnd = 23;
const fallbackEventDurationHours = 2;

export const standardCalendarConfiguration: any = {
  schedulerLicenseKey: '0173554956-fcs-1657206500',
  slotLabelInterval: '01:00',
  slotDuration: '00:30:00',
  dayHeaderFormat: 'ddd DD',
  slotLabelFormat: 'HH',
  locales: allLocales,
  locale: 'en-GB', // the initial locale
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,resourceTimelineWeek,resourceTimelineDay'
  },
  weekends: true,
  businessHours: {
    // days of week. an array of zero-based day of week integers (0=Sunday)
    daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Saturday
    startTime: `0${fullDayHoursStart}:00`,
    endTime: `${fullDayHoursEnd}:59`
  },
  slotMinTime: `0${fullDayHoursStart}:00:00`,
  slotMaxTime: `${fullDayHoursEnd}:59:00`,
  firstDay: 1,
  dayMaxEventRows: 4,
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  },
  views: {
    resourceTimelineWeek: {
      type: 'resourceTimeline',
      duration: { days: 7 },
      buttonText: 'week',
      slotLabelFormat: [
        'ddd Do', // top row
        'H:mm' // Second row
      ],
      expandRows: true
    },
    resourceTimelineMonth: {
      type: 'resourceTimeline',
      duration: { month: 1 },
      slotLabelFormat: ['ddd DD', 'a'],
      slotDuration: '06:00:00',
      expandRows: true
    }
  },
  resourceOrder: 'title'
};

export const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

/**
 * @return the style for the given status
 */
export function getStyleForStatus(status: string): JobStyle {
  return JOB_STYLES[status] || JOB_STYLES.CREATED;
}

export function generateEvent(evt: any, isExistingEvent?: boolean, bgColour = 'lightgrey', textColour = '#222') {
  if (evt.eventType === 'Job') {
    const j = evt.job as Job;
    const style = getStyleForStatus(j.status);
    const event: EventInput = {
      ...j,
      eventType: evt.eventType,
      title: j.type,
      resourceId: j.assignedTo,
      start: new Date(j.scheduledDate),
      end: j.endDate ? new Date(j.endDate) : moment(j.scheduledDate).add(4, 'hours').toDate(),
      borderColor: style.backgroundColor,
      backgroundColor: style.backgroundColor,
      textColor: style.color,
      classNames: ['jt-event']
    };
    if (isExistingEvent) {
      event.editable = true;
      event.durationEditable = true;
      event.resourceEditable = true;
      event.startEditable = true;
      event.borderColor = 'var(--jds-theme-schedule-dts-color-border)';
      // @ts-ignore it's always going to be an array because we've set it above
      event.classNames.push('jt-current-event');
    }
    return event;
  } else {
    let start;
    let end;
    if (evt.allDayEvent) {
      start = moment(evt.localStartDate).hours(0).toDate().toISOString();
      end = moment(evt.localEndDate).hours(23).toDate().toISOString();
    } else {
      start = moment(evt.startIso).toDate().toISOString();
      end = moment(evt.endIso).toDate().toISOString();
    }

    return {
      ...evt,
      id: evt.id,
      eventType: evt.eventType,
      title: evt.title ? evt.title : '',
      resourceId: evt.userIds?.[0],
      start,
      end,
      borderColor: bgColour,
      backgroundColor: bgColour,
      textColor: textColour ?? 'black',
      classNames: ['jt-event', 'jt-absence']
    };
  }
}

export function createTemporaryEvent(evt: any, newEventTranslation: string, jobType?: string): EventInput {
  const style = getStyleForStatus(ProjectStatus.SCHEDULED);
  return {
    id: TEMP_EVENT_ID,
    eventType: 'Job',
    title: jobType || newEventTranslation,
    resourceId: evt.resource.id,
    start: evt.start,
    end: evt.end,
    startDate: evt.startDate ?? null,
    endDate: evt.endDate ?? null,
    extendedProps: {
      assignedToDisplayName: evt.resource.title,
      background: evt.extendedProps.background,
      color: 'var(--jds-theme-schedule-dts-color-text)',
      eventType: 'Job',
      address: evt.extendedProps.address ?? null
    },
    editable: true,
    durationEditable: true,
    resourceEditable: true,
    startEditable: true,
    backgroundColor: style.backgroundColor,
    borderColor: 'var(--jds-theme-schedule-dts-color-border)',
    textColor: 'var(--jds-theme-schedule-dts-color-text)',
    classNames: ['jt-event', 'jt-current-event']
  };
}

export function renderEvent(evt, eventType?: any, engineerName?: string, translations?: any) {
  const { event, el, view } = evt;
  switch (event.extendedProps.eventType) {
    case 'Job': {
      renderJob(event, el, view);
      return;
    }
    default: {
      if (eventType) {
        renderAbsence(event, el, view, eventType, engineerName, translations);
      }
    }
  }
}

function renderJob(event, el, view) {
  const style = getStyleForStatus(event.extendedProps.status);
  const postcode = event.extendedProps.address ? event.extendedProps.address.postCode : '';
  let elements: HTMLElement[] = el.getElementsByClassName('fc-event-title fc-sticky');
  if (!elements?.length) {
    elements = el.getElementsByClassName('fc-event-title');
  }
  if (elements.length !== 1) {
    return;
  }

  const titleElement = elements[0];

  if (view.type.startsWith('dayGrid')) {
    el.style.backgroundColor = style.backgroundColor;
    el.style.color = event.extendedProps.color ? event.extendedProps.color : style.color;
    titleElement.outerHTML = `
    <div class="fc-content">
      <div class="item event-state">
        <div class="event-header-time">${moment(event.start).format('HH:mm')}</div>
        <div class="event-header-title">${event.title}</div>
        <div class="event-header-icon">
          <i class="material-icons">
            ${style.icon}
          </i>
        </div>
      </div>
      <div class="item extra-info">
        <div class="event-assigned"
             style="color: ${el.style.color}">
          ${event.extendedProps?.assignedToDisplayName || ''}
        </div>
        <div class="event-postcode">${postcode}</div>
      </div>
    </div>
  `;
  } else {
    // el.style.color = style.color;
    titleElement.closest('.fc-event-title-container').innerHTML = `
      <div class="week-day-view-event">
        <div class="item event-title">
          <span>${event.title}</span>
          <span class="event-postcode">
            <span>${postcode ? `(${postcode})` : ''}</span>
          </span>
        </div>
        <div class="item extra-info">
          <div class="event-time">${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}</div>
        </div>
      </div>
  `;
  }
}

function renderAbsence(event, el, view, eventType, engineerName, translations) {
  let titleElements: HTMLElement[] = el.getElementsByClassName('fc-event-title-container');
  let isContainer = true;
  if (!titleElements?.length) {
    isContainer = false;
    titleElements = el.getElementsByClassName('fc-event-title');
  }
  if (titleElements.length !== 1) {
    return;
  }
  const titleElement = titleElements[0];
  const title = event.title === 'null' || !event.title ? eventType.name : event.title;
  const allDayEvent = event.extendedProps.allDayEvent;
  const timeFrag = allDayEvent
    ? `
      <div class="event-header-time">
        ${translations.allDay}
      </div>
      `
    : `
      <div class="event-header-time">
        ${moment(event.extendedProps.startIso).format('HH:mm')}
      </div>
  `;
  let isContainerFrag = `<div class="item event-state" style="background-color: ${eventType.colour}; border-color: ${eventType.colour}">`;
  if (!isContainer) {
    isContainerFrag = `<div class="item event-state" style="padding: 2px; border-radius: 2px; color: ${eventType.textColour}; background-color: ${eventType.colour}; border-color: ${eventType.colour}">`;
  }
  titleElement.outerHTML = `
    ${isContainerFrag}
      ${timeFrag}
      <div class="event-header-title" data-qa-event-title="${title}">${engineerName}: ${title}</div>
      <div class="event-header-icon">
        <span class="material-icons">${eventType.icon}</span>
      </div>
    </div>`;
}

export function validateEvent(
  { start, end, resource }: DateRangeSelectInfo,
  existingEvent: EventInput,
  question: ScheduleQuestion
): DateRangeSelectInfo {
  // This is either the start and end of the clicked cell, or if click and drag, it's the start of the
  // first cell to the end of the last cell
  const mStart = moment(start);
  let mEnd = moment(end);
  const existingStart = existingEvent && moment(existingEvent.start);
  const existingEnd = existingEvent && moment(existingEvent.end);
  const duration = existingEvent
    ? moment.duration(existingEnd.diff(existingStart))
    : moment.duration(mEnd.diff(mStart)).asMinutes();

  if (mStart.diff(mEnd, 'hour') <= 1) {
    // if they're 1 hour apart or less, it probably means the user has just clicked in a cell
    if (existingEvent) {
      // let's default the duration to the same as before
      mEnd = mStart.clone().add(duration);
    } else if (question.defaultDuration) {
      const defaultDurationHours =
        typeof question.defaultDuration === 'string'
          ? Number.parseFloat(question.defaultDuration)
          : question.defaultDuration;
      mEnd = mStart.clone().add(defaultDurationHours, 'hours');
    } else {
      // set a fallback duration
      mEnd = mStart.clone().add(fallbackEventDurationHours, 'hours');
    }
  }
  return { start: mStart.toDate(), end: mEnd.toDate(), resource };
}
