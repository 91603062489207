import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';

import { ErrorComponent } from './error/error.component';
import {
  ERROR_PATH,
  SEARCH_PATH,
  RESET_PASSWORD_PATH,
  PROJECTS_PATH,
  REGISTER_PATH,
  MAIN_PATH,
  LOGIN_PATH,
  DASHBOARD_PATH,
  CHANGE_PASSWORD_PATH,
  PLANNING_MAP_PATH,
  CONFIGURATION_PATH,
  PROJECTS_DETAILS_PATH,
  FREE_LEADS_PATH,
  FREE_LEADS_REGISTER_PATH,
  APP_HOME_PATH
} from './app.routes';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { HomePageComponent } from './home/home-page.component';
import { LoginComponent } from './auth/login/login.component';
import { CanActivateRoute } from './auth/guards/can-activate-route';
import { FeatureGuard } from './core/feature-flag/feature.guard';
import { CanDeactivateRoute } from './auth/guards/can-deactivate-route';
import { CanActivateLeadsRoute } from './auth/guards/can-activate-leads-route';

const fallbackRoute: Route = {
  path: '**',
  redirectTo: APP_HOME_PATH
};

export const appRoutes: Routes = [
  { path: '', redirectTo: APP_HOME_PATH, pathMatch: 'full' },
  {
    path: RESET_PASSWORD_PATH,
    component: LoginComponent,
    data: { state: 'forgotPassword', title: 'pageTitles.resetPassword' }
  },
  {
    path: CHANGE_PASSWORD_PATH,
    component: LoginComponent,
    data: { state: 'startChangePassword', title: 'pageTitles.changePassword' }
  },
  { path: ERROR_PATH, component: ErrorComponent },
  {
    path: LOGIN_PATH,
    component: LoginComponent,
    data: { title: 'pageTitles.login' }
  },
  {
    path: MAIN_PATH,
    canActivate: [CanActivateRoute],
    component: HomePageComponent,
    data: { title: 'pageTitles.home' }
  },
  {
    path: PROJECTS_PATH,
    canActivate: [CanActivateRoute],
    loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule),
    data: { title: 'pageTitles.projects' }
  },
  {
    path: DASHBOARD_PATH,
    canActivate: [CanActivateRoute],
    loadChildren: () => import('./insight/insight.module').then(m => m.InsightModule),
    data: { title: 'pageTitles.dashboard' }
  },
  {
    path: SEARCH_PATH,
    canActivate: [CanActivateRoute],
    loadChildren: () => import('./projects-search/projects-search.module').then(m => m.ProjectsSearchModule),
    data: { title: 'pageTitles.search' }
  },
  {
    path: `${PROJECTS_DETAILS_PATH}/:projectId`,
    canActivate: [CanActivateRoute],
    component: ProjectDetailComponent, // @TODO: Refactor - DO NOT LAZY LOAD FOR NOW - RESULTS IN DATA LOSS
    data: { title: 'pageTitles.details' }
  },
  {
    path: 'schedule',
    canActivate: [CanActivateRoute],
    loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule),
    data: { title: 'pageTitles.schedule' }
  },
  {
    path: PLANNING_MAP_PATH,
    canActivate: [CanActivateRoute],
    loadChildren: () => import('./feature-modules/planning-map/planning-map.module').then(m => m.PlanningMapModule),
    data: { title: 'pageTitles.map' }
  },
  {
    path: 'reports',
    canActivate: [CanActivateRoute],
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    data: { title: 'pageTitles.reports' }
  },
  {
    path: 'reports-beta',
    canLoad: [FeatureGuard],
    canActivate: [CanActivateRoute],
    canDeactivate: [CanDeactivateRoute],
    loadChildren: () => import('./feature-modules/reports/reports.module').then(m => m.ReportsModule),
    data: { title: 'pageTitles.reports', feature: 'beta-reports' }
  },
  {
    path: 'user-management',
    canActivate: [CanActivateRoute],
    loadChildren: () => import('./admin/user-management/user-management.module').then(m => m.UserManagementModule),
    data: { allowAdmin: true, title: 'pageTitles.userManagement' }
  },
  {
    path: CONFIGURATION_PATH,
    canActivate: [CanActivateRoute],
    loadChildren: () => import('./feature-modules/configuration/configuration.module').then(m => m.ConfigurationModule),
    data: { title: 'pageTitles.configuration' }
  },
  {
    path: `${REGISTER_PATH}/:registrationForm/:suid`,
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
    data: { title: 'pageTitles.register' }
  },
  {
    path: FREE_LEADS_PATH,
    canActivate: [CanActivateLeadsRoute],
    loadChildren: () =>
      import('./feature-modules/leads-management/leads-management.module').then(m => m.LeadsManagementModule),
    data: { title: 'pageTitles.leadsManagement' }
  },
  {
    path: `${FREE_LEADS_REGISTER_PATH}/:id`,
    loadChildren: () =>
      import('./feature-modules/leads-management-register/leads-management-register.module').then(
        m => m.LeadsManagementRegisterModule
      ),
    data: { title: 'pageTitles.leadsManagementRegister' }
  },
  fallbackRoute
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {
  onSameUrlNavigation: 'reload'
});
