import { Component } from '@angular/core';

@Component({
  selector: 'app-document-pack-loader',
  templateUrl: './document-pack-loader.html',
  styleUrls: ['../../document-pack.component.scss']
})
export class DocumentPackLoaderComponent {
  loaders = [{ width: '150px' }, { width: '95px' }, { width: '110px' }];
}
