import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthStateEvent } from '../../../utils/auth-types';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['../../login.component.scss']
})
export class ForgotPasswordFormComponent implements AfterViewInit {
  @Input() forgotPasswordForm: UntypedFormGroup;
  @Input() submitInProgress: boolean;

  @Output() forgotPasswordEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() setViewEvent: EventEmitter<AuthStateEvent> = new EventEmitter<AuthStateEvent>();

  @ViewChild('usernameInput')
  public usernameInput: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.focusForm();
  }

  focusForm() {
    setTimeout(() => {
      this.usernameInput.nativeElement.focus();
    });
  }

  setView() {
    this.setViewEvent.emit(AuthStateEvent.StartSignIn);
  }

  forgotPassword() {
    this.forgotPasswordEvent.emit();
  }
}
