<div>
  <div style="display: flex">
    <div style="float: left">
      <label class="mr-1 col-form-label" *ngIf="to.label">{{ to.label }}</label>
      <p *ngIf="to.description">{{ to.description }}</p>
    </div>
    <div class="text-right p-1 options-add">
      <jui-icon size="sm" name="add" (click)="add()" title="Add"></jui-icon>
    </div>
  </div>

  <jui-alert-block role="alert" *ngIf="showError && formControl.errors" color="danger">
    <formly-validation-message [field]="field"></formly-validation-message>
  </jui-alert-block>

  <div class="mb-3" cdkDropList (cdkDropListDropped)="drop($event)" style="position: relative">
    <div *ngFor="let field of field.fieldGroup; let i = index" class="draggable-row" cdkDrag cdkDragLockAxis="y">
      <div class="drag-placeholder" *cdkDragPlaceholder></div>
      <div class="draggable-handle">
        <jui-icon size="sm" name="drag_handler" color="info" title="Move" cursor="move"></jui-icon>
      </div>
      <formly-field class="draggable-contents" [field]="field"></formly-field>
      <div class="draggable-actions text-right options-delete">
        <jui-icon size="sm" name="delete" type="outlined" color="danger" title="Delete" (click)="remove(i)"></jui-icon>
      </div>
    </div>

    <div class="d-flex flex-row-reverse"></div>
    <ngx-spinner size="large" type="line-scale" [fullScreen]="false" [name]="spinnerName"> </ngx-spinner>
  </div>
</div>
