import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';

import {
  BehaviorSubject,
  catchError,
  debounceTime,
  firstValueFrom,
  Observable,
  of,
  pairwise,
  startWith,
  Subject,
  Subscription
} from 'rxjs';
import { ProjectStatus } from '../../admin/user-management/domain/types';
import * as Analytics from '../../app.analytics';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { UserService } from '../../auth/services/user.service';
import { ApiService } from '../../core/api.service';
import { DelegationService } from '../../core/delegate/delegation.service';
import { Project, TasksWorkflowState } from '../../core/domain/project';
import { ProjectConfiguration, ProjectState } from '../../core/domain/project-configuration';
import { User } from '../../core/domain/user';
import { ProjectConfigurationService } from '../../core/project-configuration.service';
import { ProjectUpdateService } from '../../core/project-update.service';
import { UserThemeService } from '../../core/user-theme.service';
import { checkIfExpression } from '../../core/utils/filter';
import { ToasterService } from '../../toast/toast-service';
import { IDocumentPackManagerDm, VALID_GROUPED_DOCUMENT_STATES } from '../document-pack/document-pack.model';
import { DocumentPackRepository } from '../document-pack/document-pack.repository';
import {
  ActionModel,
  ActionSubmitted,
  ErrorModel,
  ProjectAction,
  ProjectTask,
  Task,
  TaskForm,
  TaskModel,
  TasksModel,
  TaskStatus,
  TasksWorkflow
} from './task.model';
import {
  filterForUser,
  getInvalidAttachmentLabels,
  getInvalidDataLabels,
  getInvalidOwnerTeamLabels,
  getProjectResources,
  isDifferent,
  projectDifferences,
  shouldShow
} from './utils';
import { FeatureFlagService } from '../../core/feature-flag/feature-flag.service';
import { CONTACT_LOGGING_LD_FEATURE_KEY } from '../../feature-modules/contact-logging/contact-log.model';

@Injectable({ providedIn: 'root' })
export class TasksRepository {
  tasksWorkflows$: BehaviorSubject<TasksWorkflow>;
  tasks$: BehaviorSubject<TasksModel>;
  tasksForms$: BehaviorSubject<TaskForm[]>;
  tasksWorkflowStates$: BehaviorSubject<TasksWorkflowState[]>;
  invalidItems$: Subject<string[]>;
  action$: Subject<ActionModel>;
  error$: Subject<ErrorModel>;
  infoMessage$: Subject<string>;
  goto$: Subject<string>;
  delegated$: Subject<boolean>;
  actioned$: Subject<ActionSubmitted>;

  private subscriptionList: Subscription[] = [];

  private formSubscriptions: Subscription[] = [];

  private _projectConfiguration: ProjectConfiguration;

  private _user: User;

  private _project: Project;

  private _outstandingDocuments = false;

  private readonly _reservedCheckedKey = '_CHECKED_';

  private readonly _archiveProject = 'archiveProject';

  private readonly _unArchiveProject = 'unArchiveProject';

  private readonly _delegateProject = 'delegateProject';

  private readonly _unDelegateProject = 'unDelegateProject';

  private readonly spinnerName = 'ProjectDetail';

  private _activeTheme: string;

  private featureEnabledActions = [];
  private hasFeatureEnabledActions = () => {
    return this._project?.status?.status !== ProjectStatus.ARCHIVED && this.featureEnabledActions.some(f => !!f);
  };

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private featureAccessService: AccessService,
    private projectUpdateService: ProjectUpdateService,
    private projectConfigurationService: ProjectConfigurationService,
    public toasterService: ToasterService,
    private delegationService: DelegationService,
    private documentPackRepository: DocumentPackRepository,
    private translationService: TranslocoService,
    private themeService: UserThemeService,
    private spinnerService: NgxSpinnerService,
    private featureFlagService: FeatureFlagService
  ) {
    this.tasks$ = new BehaviorSubject<TasksModel>(null);
    this.themeService.theme$.subscribe(theme => {
      this._activeTheme = theme;
      this.refreshTasks();
    });
  }

  async initialise(project: Project) {
    this._project = _.cloneDeep(project);
    this.featureEnabledActions = [
      !this.isArchived() && (await this.featureFlagService.isFeatureEnabled(CONTACT_LOGGING_LD_FEATURE_KEY))
    ];
    this.tasks$.next({
      label: this.hasFeatureEnabledActions() ? this.translationService.translate('common.actions') : null,
      tasks: [],
      showLoader: true,
      shouldRender: this.hasFeatureEnabledActions(),
      isArchived: this.isArchived(),
      activeTheme: this._activeTheme
    });

    this.tasksWorkflows$ = new BehaviorSubject<TasksWorkflow>(null);
    this.tasksForms$ = new BehaviorSubject<TaskForm[]>([]);

    this.invalidItems$ = new Subject<string[]>();
    this.action$ = new Subject();
    this.error$ = new Subject();
    this.infoMessage$ = new Subject();
    this.goto$ = new Subject();
    this.delegated$ = new Subject();
    this.actioned$ = new Subject();

    this.tasksWorkflowStates$ = new BehaviorSubject<TasksWorkflowState[]>(this._project.tasksWorkflowStates || []);
    this._projectConfiguration = await this.projectConfigurationService.getProjectConfiguration(project.type);

    this.subscribeToTasksWorkflows();
    this.subscribeToTasksWorkflowsStates();
    this.subscribeToTaskForms();
    this.subscribeToProjectUpdates();
    this.subscribeToDocumentManager();
    this.subscribeToUser();
    await this.hydrateTasksWorkflow();
  }

  getTasks(callback) {
    this.subscriptionList.push(this.tasks$.subscribe(callback));
  }

  getStateUpdates(callback) {
    this.subscriptionList.push(this.tasksWorkflowStates$.subscribe(callback));
  }

  updateProject(project: Project) {
    if (this._project) {
      this.checkForUpdates(project);
    }
  }

  toggleTaskStatus(taskId: string) {
    const tasksWorkflowStates = this.tasksWorkflowStates$.value;
    const tasksWorkflowState = tasksWorkflowStates.find(x => x.id === taskId);
    const currentValue = tasksWorkflowState.state[this._reservedCheckedKey];
    tasksWorkflowState.state[this._reservedCheckedKey] =
      currentValue === TaskStatus.CLOSED ? TaskStatus.OPEN : TaskStatus.CLOSED;
    this.tasksWorkflowStates$.next(tasksWorkflowStates);
  }

  action(id: string) {
    const tasks = this.tasksForms$.value || [];
    const found = tasks.find(task => task.task.id === id);
    if (!found) {
      return;
    }

    const projectAction = found.task as ProjectAction;
    Analytics.logEvent('ProjectAction', projectAction);

    // specific handling for delegation
    if (id === this._delegateProject) {
      this.delegateProject();
      return;
    }
    if (id === this._unDelegateProject) {
      this.unDelegateProject();
      return;
    }

    this.getInvalidLabels(projectAction).then(items => {
      const gotoItem = items.find(x => !!x.goto);
      if (gotoItem) {
        this.goto$.next(gotoItem.goto);
      }
      if (!items.length) {
        this.invalidItems$.next([]);
        this.initialiseAction(projectAction);
      } else {
        this.invalidItems$.next(items.map(item => item.label));
      }
    });
  }

  getInvalidItems(callback) {
    this.subscriptionList.push(
      this.invalidItems$.subscribe(items => {
        callback({
          title: this.translationService.translate('project.modals.invalidItems.title'),
          messages: items,
          activeTheme: this._activeTheme
        });
      })
    );
  }

  clearInvalidItems() {
    this.invalidItems$.next([]);
    this.refreshTasks();
  }

  getAction(callback) {
    this.subscriptionList.push(
      this.action$.subscribe(action => {
        callback(action);
      })
    );
  }

  invokeAction(projectAction: ProjectAction, data?: Record<string, unknown>) {
    this.showSpinner();
    data = data ?? projectAction.data;
    const projectAction$ = this.apiService.projectAction(this._project.id, projectAction, data).pipe(
      catchError(error => {
        this.error$.next({
          title: this.translationService.translate('project.modals.error.title'),
          errors: [
            error.error?.errorMessage || this.translationService.translate('project.modals.error.messages.unknown')
          ],
          activeTheme: this._activeTheme
        });
        return of(undefined);
      })
    );
    projectAction$.subscribe(() => {
      if (data) {
        this.actioned$.next({ transitionType: projectAction.action, data: data });
        this._project = { ...this._project, data: { ...this._project.data, ...data } };
      }

      if (projectAction.transition) {
        this._project.status.status = projectAction.transition;
        this.hydrateTasksWorkflow().then();
      }
      this.clearAction();
      this.hideSpinner();
    });
    if (projectAction?.toast) {
      this.toasterService.observe(projectAction$, projectAction?.toast);
    }
  }

  clearAction() {
    this.action$.next(null);
    this.refreshTasks();
  }

  getError(callback) {
    this.subscriptionList.push(
      this.error$.subscribe(error => {
        callback(error);
      })
    );
  }

  clearError() {
    this.error$.next(null);
    this.refreshTasks();
  }

  getInfoMessage(callback) {
    this.subscriptionList.push(this.infoMessage$.subscribe(callback));
  }

  getGoto(callback) {
    this.subscriptionList.push(this.goto$.subscribe(callback));
  }

  getDelegated(callback) {
    this.subscriptionList.push(this.delegated$.subscribe(callback));
  }

  getActioned(callback) {
    this.subscriptionList.push(this.actioned$.subscribe(callback));
  }

  public unsubscribe(): void {
    this.tasks$.next(null);
    this.subscriptionList.forEach(sub => sub.unsubscribe());
    this.subscriptionList = [];
    this.formSubscriptions.forEach(sub => sub.unsubscribe());
    this.formSubscriptions = [];
  }

  // Private

  private subscribeToUser() {
    this.subscriptionList.push(
      this.userService.userObservable.subscribe(user => {
        this._user = user;
      })
    );
  }

  private subscribeToTasksWorkflows() {
    this.subscriptionList.push(
      this.tasksWorkflows$.subscribe((tasksWorkflow: TasksWorkflow) => {
        if (tasksWorkflow) {
          this.formSubscriptions.forEach(sub => sub.unsubscribe());
          this.formSubscriptions = [];
          const taskForms = this.createTasks(tasksWorkflow);
          const sorted = taskForms.sort((a, b) => {
            if (a.task.position === 'top' && b.task.position !== 'top') {
              return -1;
            }

            if (a.task.position !== 'top' && b.task.position === 'top') {
              return 1;
            }
            return 0;
          });
          this.tasksForms$.next(sorted);
        }
      })
    );
  }

  private subscribeToTaskForms() {
    this.subscriptionList.push(
      this.tasksForms$.subscribe(taskForms => {
        this.hydrateTasksWorkflowStates();

        for (const taskForm of taskForms.filter(tf => tf !== null)) {
          taskForm.model.activeTheme = this._activeTheme;
          taskForm.model.visible = shouldShow(taskForm.task, taskForms, this._project);
          taskForm.model.isComplete = taskForm.model.form?.get(this._reservedCheckedKey).value === TaskStatus.CLOSED;
        }

        for (const taskForm of taskForms) {
          let outstandingDocuments = false;
          if (taskForm.task.requireDocumentCompletion) {
            outstandingDocuments = this._outstandingDocuments;
          }

          taskForm.model.enabled =
            taskForm.task.id === this._archiveProject ||
            (!outstandingDocuments &&
              taskForms.every(tf => {
                if (!tf.model.visible) {
                  return true;
                }
                const open = tf.model.form?.get(this._reservedCheckedKey).value === TaskStatus.OPEN || false;
                return !tf.model.form?.invalid || !open;
              }));
        }

        this.tasks$.next({
          label:
            taskForms?.length > 0 || this.hasFeatureEnabledActions()
              ? this.translationService.translate('common.actions')
              : null,
          tasks: taskForms.map(x => x.model),
          shouldRender: (taskForms?.length > 0 || this.hasFeatureEnabledActions()) ?? false,
          showLoader: false,
          isArchived: this.isArchived(),
          activeTheme: this._activeTheme
        });
      })
    );
  }

  private subscribeToTasksWorkflowsStates() {
    this.subscriptionList.push(
      this.tasksWorkflowStates$.pipe(debounceTime(1000)).subscribe((tasksWorkflowStates: TasksWorkflowState[]) => {
        if (!tasksWorkflowStates) {
          return;
        }
        this.updateRemoteTasksWorkflowStates();
      })
    );
  }

  private subscribeToProjectUpdates() {
    this.subscriptionList.push(
      this.projectUpdateService.projectUpdates.subscribe(project => {
        this.checkForUpdates(project);
      })
    );
  }

  private subscribeToDocumentManager() {
    this.subscriptionList.push(
      this.documentPackRepository.getDocumentPackManagerSubscription(dm => {
        this._outstandingDocuments = this.hasOutstandingDocuments(dm);
        this.refreshTasks();
      })
    );
  }

  private isArchived = () => {
    return this._project?.status?.status === ProjectStatus.ARCHIVED;
  };

  private async hydrateTasksWorkflow() {
    if (!this._projectConfiguration) {
      this._projectConfiguration = await this.projectConfigurationService.getProjectConfiguration(this._project.type);
    }
    const archiveWorkFlow: TasksWorkflow = await this.getArchiveSpec();
    const delegationWorkFlow: TasksWorkflow = await this.getDelegationSpec();

    const currentState = this.getProjectState(this._project);

    if (!this._project.tasksWorkflow || this._project.tasksWorkflow.id !== currentState?.tasksWorkflowId) {
      let tasksWorkFlowObservable: Observable<TasksWorkflow>;
      if (currentState?.tasks?.length || currentState?.actions?.length) {
        tasksWorkFlowObservable = of({ tasks: currentState.tasks || [], actions: currentState.actions || [] });
      } else {
        tasksWorkFlowObservable = this.apiService.getTaskWorkflow(currentState?.tasksWorkflowId, this._project.id);
      }
      tasksWorkFlowObservable.subscribe(tasksWorkflow => {
        if (
          !tasksWorkflow?.actions?.length &&
          !archiveWorkFlow?.actions?.length &&
          !delegationWorkFlow?.actions?.length
        ) {
          this.tasks$.next({
            label: this.hasFeatureEnabledActions() ? this.translationService.translate('common.actions') : null,
            tasks: [null],
            shouldRender: this.hasFeatureEnabledActions(),
            showLoader: true,
            isArchived: this.isArchived(),
            activeTheme: this._activeTheme
          });
          return;
        }
        tasksWorkflow = {
          ...(tasksWorkflow ?? {}),
          actions: [
            ...(tasksWorkflow?.actions ?? []),
            ...(archiveWorkFlow?.actions ?? []),
            ...(delegationWorkFlow?.actions ?? [])
          ]
        };
        this.tasks$.next(null);
        if (tasksWorkflow?.actions?.length || tasksWorkflow?.tasks?.length) {
          this._project.tasksWorkflow = tasksWorkflow;
          this.tasksWorkflows$.next(tasksWorkflow);
        }
      });
    }
  }

  private hydrateTasksWorkflowStates() {
    const taskForms = this.tasksForms$.value || [];
    const currentStates = this.tasksWorkflowStates$?.value || [];

    for (const taskForm of taskForms) {
      let currentState = currentStates.find(t => t.id === taskForm.task.id);
      if (!currentState) {
        currentState = { id: taskForm.task.id, state: null };
        currentStates.push(currentState);
      }

      currentState.state = { ...currentState.state, ...taskForm.model.form?.value };
    }

    this.tasksWorkflowStates$.next(currentStates);
  }

  private createTasks(tasksWorkflow: TasksWorkflow): TaskForm[] {
    const taskForms: TaskForm[] = [];
    if (!tasksWorkflow) {
      return taskForms;
    }

    for (const task of filterForUser(tasksWorkflow.tasks, this._user)) {
      taskForms.push({
        task: task,
        model: this.createTask(task, 'task')
      });
    }

    for (const task of filterForUser(tasksWorkflow.actions, this._user)) {
      taskForms.push({
        task: task,
        model: this.createTask(task, 'action')
      });
    }

    return taskForms;
  }

  private createTask(task: Task, type: string) {
    const form = this.createForm(task);
    const model: TaskModel = {
      id: task.id,
      type: type,
      typeClass: type,
      enabled: true,
      visible: true,
      project: this._project,
      form: form,
      questions: task.questions,
      showQuestions: task.questions?.length && !!form,
      isComplete: this.getStateData(task.id, this._reservedCheckedKey) === TaskStatus.CLOSED
    };

    if (type === 'task') {
      const projectTask = task as ProjectTask;
      model.label = projectTask.task;
      model.description = projectTask.description;
      model.showTick = true;
      model.showCheck = !form;
    }

    if (type === 'action') {
      const projectAction = task as ProjectAction;
      model.actionLabel = projectAction.label;
      model.actionable = !projectAction.dynamicSave;
    }

    return model;
  }

  private createForm(task: Task) {
    if (!task.questions?.length) {
      return null;
    }
    const formControls = {};
    task.questions.forEach(question => {
      const projectValue = this._project.data ? this._project.data?.[question.key] : null;
      const stateValue = this.getStateData(task.id, question.key);
      const value = stateValue ?? projectValue ?? question.value ?? null;
      formControls[question.key] = question.required
        ? new UntypedFormControl(value, [Validators.required])
        : new UntypedFormControl(value);
    });
    const formGroup = new UntypedFormGroup(formControls);
    const taskStatus = formGroup.valid ? TaskStatus.CLOSED : TaskStatus.OPEN;
    formGroup.addControl(this._reservedCheckedKey, new UntypedFormControl({ value: taskStatus, disabled: true }));
    this.formSubscriptions.push(
      formGroup.valueChanges
        .pipe(startWith(formGroup.value ?? null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (!_.isEqual(prev, next)) {
            formGroup.get(this._reservedCheckedKey).patchValue(formGroup.valid ? TaskStatus.CLOSED : TaskStatus.OPEN);
            this.hydrateTasksWorkflowStates();
            this.refreshTasks();
          }
        })
    );

    this.formSubscriptions.push(
      formGroup.valueChanges
        .pipe(debounceTime(1000), startWith(formGroup.value ?? null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (isDifferent(prev, next) && task.dynamicSave && formGroup) {
            const data = formGroup.value ?? {};
            this.invokeAction(task as ProjectAction, data);
            this.hydrateTasksWorkflowStates();
            this.refreshTasks();
          }
        })
    );

    return formGroup;
  }

  private refreshTasks(taskForms?: TaskForm[]) {
    taskForms = taskForms ?? this.tasksForms$?.value;
    if (taskForms) {
      this.tasksForms$.next(taskForms);
    }
  }

  private hasOutstandingDocuments(dm: IDocumentPackManagerDm) {
    if (!dm) {
      return false;
    }

    const documents = dm?.documentPack?.documents || [];

    const isGrouped = documents.filter(doc => {
      return doc.documentDefinition.requiredByProjectMetaStatus === this._project.status.metaStatus;
    });

    if (isGrouped.length) {
      const notCompleted = isGrouped.filter(doc => !VALID_GROUPED_DOCUMENT_STATES.includes(doc.state.currentState));
      return notCompleted.length > 0;
    }

    const notCompleted = documents.filter(doc => !VALID_GROUPED_DOCUMENT_STATES.includes(doc.state.currentState));
    return notCompleted.length > 0;
  }

  private getProjectState(project: Project) {
    return this._projectConfiguration?.states.find(state => state.status === project.status.status) || null;
  }

  public getStateByStatus(status: string): ProjectState | null {
    return this._projectConfiguration?.states.find(state => state.status === status) || null;
  }

  private getStateData(taskId: string, key: string) {
    const state = this._project.tasksWorkflowStates?.find(state => state.id === taskId);
    return state?.state?.[key] ?? null;
  }

  private updateRemoteTasksWorkflowStates() {
    if (this._project.status?.status === ProjectStatus.ARCHIVED) {
      return;
    }
    if (!isDifferent(this._project.tasksWorkflowStates, this.tasksWorkflowStates$.value)) {
      return;
    }

    firstValueFrom(
      this.apiService.updateProject(this._project, { tasksWorkflowStates: this.tasksWorkflowStates$.value })
    )
      .then(() => {
        this._project.tasksWorkflowStates = this.tasksWorkflowStates$.value;
      })
      .catch();
  }

  private checkForUpdates(project: Project) {
    const differences = projectDifferences(this._project, project);
    if (!differences?.length) {
      return;
    }

    this._project = _.cloneDeep(project);

    if (differences.find(x => ['type'].includes(x))) {
      this._projectConfiguration = null;
    }

    if (differences.find(x => ['id', 'tenant', 'status'].includes(x))) {
      this.hydrateTasksWorkflow().then();
      return;
    }

    if (differences.find(x => ['data'].includes(x))) {
      const taskForms = this.tasksForms$.value;
      taskForms.forEach(taskForm => {
        if (!taskForm.task.questions) {
          return;
        }
        taskForm.task.questions.forEach(question => {
          const projectValue = this._project.data ? this._project.data?.[question.key] : null;
          const stateValue = this.getStateData(taskForm.task.id, question.key);
          const value = stateValue ?? projectValue ?? question.value ?? null;
          taskForm.model.form?.get(question.key)?.patchValue(value);
        });
      });
    }
  }

  private updateProjectResource(projectAction: ProjectAction, resourceToUpdate: string) {
    this.apiService.updateProject(this._project, {}, resourceToUpdate).subscribe((updatedProject: Project) => {
      const resourceIndex = this._project.resources.findIndex(
        r => r.id === resourceToUpdate || r.type === resourceToUpdate
      );
      const updatedResource = updatedProject.resources.find(
        r => r.id === resourceToUpdate || r.type === resourceToUpdate
      );
      if (updatedResource) {
        if (resourceIndex > -1) {
          this._project.resources[resourceIndex] = updatedResource;
        } else {
          this._project.resources.push(updatedResource);
        }
      }
      this.openActionModal(projectAction);
    });
  }

  private async getInvalidLabels(projectAction: ProjectAction) {
    const ownerUser: User =
      !this._project.owner || this._project.owner === 'null'
        ? null
        : await this.userService.getUserById(this._project.owner.split('|')[1]);

    return [
      ...getInvalidDataLabels(projectAction, this._project),
      ...getInvalidAttachmentLabels(projectAction, this._project),
      ...getInvalidOwnerTeamLabels(projectAction, ownerUser)
    ];
  }

  private initialiseAction(projectAction: ProjectAction) {
    if (projectAction.infoMessage) {
      this.infoMessage$.next(projectAction.infoMessage);
    }

    if (projectAction.confirmation && projectAction.confirmation.type === 'modal') {
      if (projectAction.confirmation.updateResource) {
        this.updateProjectResource(projectAction, projectAction.confirmation.updateResource);
      } else {
        this.openActionModal(projectAction);
      }
    } else {
      this.invokeAction(projectAction);
    }
  }

  private openActionModal(projectAction: ProjectAction) {
    const defaultOptions = {
      windowClass: projectAction.action === 'ScheduleJob' ? 'status-transition-dialog--lg' : 'status-transition-dialog'
    };
    const options = projectAction.confirmation.options
      ? { ...defaultOptions, ...projectAction.confirmation.options }
      : defaultOptions;

    this.action$.next({
      options,
      projectAction: projectAction,
      project: this._project,
      resources: getProjectResources(this._project),
      activeTheme: this._activeTheme
    });
  }

  private async getArchiveSpec() {
    const canArchive =
      this._project.status.status !== 'ARCHIVED' &&
      this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.AllowArchive);
    const canUnArchive =
      this._project.status.status === 'ARCHIVED' &&
      this._project.status?.previousStatus &&
      this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.AllowUnarchive);

    const actions = [];

    if (canArchive) {
      const action = await this.getArchiveAction();
      if (!action) {
        return null;
      }

      actions.push(action);
    }

    if (canUnArchive) {
      const action = await this.getUnArchiveAction();
      if (!action) {
        return null;
      }

      actions.push(action);
    }

    return {
      actions: actions
    };
  }

  private async getArchiveAction(): Promise<ProjectAction> {
    let archivedSpec = this.getStateByStatus('ARCHIVED');
    const tasksLength = archivedSpec?.tasks?.length || 0;
    const actionsLength = archivedSpec?.actions?.length || 0;
    const requiresRemoteLookup = (tasksLength || actionsLength) === 0;
    if (!archivedSpec || requiresRemoteLookup) {
      archivedSpec = await firstValueFrom(
        this.apiService.getTaskWorkflow(archivedSpec?.tasksWorkflowId ?? 'ARCHIVED', this._project.id)
      );
    }

    if (!archivedSpec) {
      return null;
    }

    const action = archivedSpec?.actions?.[0] || {
      id: this._archiveProject,
      label: null,
      transition: 'ARCHIVED',
      confirmation: {
        layout: {
          label: this.translationService.translate('projects.archiveProject')
        },
        type: 'modal',
        alert: this.translationService.translate('projects.archiveProjectConfirmation')
      }
    };
    action.id = this._archiveProject;
    action.label = this.translationService.translate('projects.archiveProject');

    return action;
  }

  private async getUnArchiveAction(): Promise<ProjectAction> {
    const action = {
      id: 'unarchiveProject',
      label: null,
      transition: this._project.status.previousStatus,
      confirmation: {
        layout: {
          label: this.translationService.translate('project.modals.unarchiveProject.title')
        },
        type: 'modal',
        alert: this.translationService.translate('project.modals.unarchiveProject.messages.confirm')
      }
    };
    action.id = this._unArchiveProject;
    action.label = this.translationService.translate('project.modals.unarchiveProject.title');

    return action as ProjectAction;
  }

  private async getDelegationSpec() {
    if (
      this._project.status.status === 'ARCHIVED' ||
      !this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.DelegationV2)
    ) {
      return null;
    }

    let delegates = await this.delegationService.getDelegatesForProjectType(this._project.type);
    delegates = delegates?.filter(d => {
      return checkIfExpression(d.showIf, this._project);
    });

    const actions = [];

    const showDelegationAction = this.delegationService.showDelegationAction(
      true,
      delegates,
      this._user.tenant,
      this._project.tenant
    );

    if (showDelegationAction) {
      actions.push({
        id: this._delegateProject,
        label: this.translationService.translate('Buttons.assignToInstaller.text')
      });
    }

    const showUnDelegationAction = this.delegationService.showUnDelegationAction(
      true,
      this._project,
      this._user.tenant,
      delegates
    );

    if (showUnDelegationAction) {
      actions.push({
        id: this._unDelegateProject,
        label: this.translationService.translate('Buttons.unassignFromInstaller.text')
      });
    }

    return {
      actions: actions
    };
  }

  private delegateProject() {
    this.delegationService
      .delegateProject(this._project.id, this._project.type, this._project.data?.preAssignment?.target)
      .then(success => {
        if (success) {
          this.delegated$.next(success);
        }
        this.refreshTasks();
      });
  }

  private unDelegateProject() {
    this.delegationService.undelegateProject(this._project.id, this._project.tenant).then(success => {
      if (success) {
        this.delegated$.next(success);
      }
      this.refreshTasks();
    });
  }

  private showSpinner() {
    this.spinnerService.show(this.spinnerName).then();
  }

  private hideSpinner() {
    this.spinnerService.hide(this.spinnerName).then();
  }
}
