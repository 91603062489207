import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { I18nKeys } from '@jump-tech-frontend/cards';
import { CardsLibTranslationService } from '../cards-lib-translation.service';
import * as Analytics from '../../app.analytics';
import { shouldTrackQuestion } from '../../app.analytics';
import { IAddressV2 } from '@jump-tech-frontend/address-lookup-v2';

export const updateProjectAttachments = ($event: any[], project: any, key: string) => {
  if (!project) {
    return;
  }
  project.attachments = project.attachments || [];
  if ($event && $event.length) {
    project.attachments = project.attachments.concat($event);
  } else {
    const idx = project.attachments.findIndex(a => a.key.startsWith(`${key}.`));
    if (idx > -1) {
      project.attachments.splice(idx, 1);
    }
  }
};

@Component({
  selector: 'app-edit-layout-questions',
  template: `
    <div *ngIf="form" [ngSwitch]="question.controlType" [formGroup]="form" class="form-group edit-layout">
      <crds-question-postcode
        *ngSwitchCase="'PostcodeQuestion'"
        [question]="question"
        [i18ns]="i18ns"
        [form]="form"
      ></crds-question-postcode>
      <crds-question-v2-postcode
        *ngSwitchCase="'PostcodeV2Question'"
        [question]="question"
        [i18ns]="i18ns"
        [form]="form"
      >
      </crds-question-v2-postcode>
      <crds-google-places-autocomplete
        *ngSwitchCase="'GooglePlacesAddress'"
        [question]="question"
        [i18ns]="i18ns"
        [form]="form"
      >
      </crds-google-places-autocomplete>
      <jump-tech-frontend-address-lookup-v2
        *ngSwitchCase="'AddressTypeaheadQuestion'"
        [countryCodes]="question.countryCodes"
        [label]="question.label"
        [provider]="question.provider"
        [address]="form.get(question.key).value"
        (addressUpdate)="updateAddress($event, question.key)"
      >
      </jump-tech-frontend-address-lookup-v2>
      <crds-question-dropdown
        *ngSwitchCase="'DropdownQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-dropdown>
      <app-ng-select-dropdown
        *ngSwitchCase="'NgSingleselectDropdown'"
        [singleSelection]="true"
        [question]="question"
        [form]="form"
        (change)="handleChange($event)"
      ></app-ng-select-dropdown>
      <app-ng-select-dropdown
        *ngSwitchCase="'NgMultiselectDropdown'"
        [singleSelection]="false"
        [question]="question"
        [form]="form"
        (change)="handleChange($event)"
      ></app-ng-select-dropdown>
      <app-date-time-duration-select
        *ngSwitchCase="'DateTimeDurationSelect'"
        [question]="question"
        [form]="form"
        [project]="project"
      ></app-date-time-duration-select>
      <crds-question-multi-dropdown-value
        *ngSwitchCase="'MultiDropdownQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-multi-dropdown-value>
      <crds-address-list-question-dropdown
        *ngSwitchCase="'AddressDropdownQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      >
      </crds-address-list-question-dropdown>
      <ng-container *ngSwitchCase="'MapsMarkerQuestion'">
        <crds-question-google-maps-marker [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-google-maps-marker>
      </ng-container>
      <crds-question-general
        *ngSwitchCase="'GeneralText'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-general>
      <crds-question-image-upload
        *ngSwitchCase="'ImageUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-image-upload>
      <crds-question-multi-image-upload
        *ngSwitchCase="'MultiImageUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      >
      </crds-question-multi-image-upload>
      <crds-question-ocr-image-upload
        *ngSwitchCase="'OcrImageUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      >
      </crds-question-ocr-image-upload>
      <crds-question-signature
        *ngSwitchCase="'SignatureQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-signature>
      <crds-question-textbox
        *ngSwitchCase="'TextboxQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-textbox>
      <crds-question-date
        *ngSwitchCase="'DateQuestionComponent'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-date>
      <crds-question-textarea
        *ngSwitchCase="'TextareaQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-textarea>
      <crds-question-toggle
        *ngSwitchCase="'ToggleQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-toggle>
      <crds-question-button-group
        *ngSwitchCase="'ButtonGroupQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      >
      </crds-question-button-group>
      <crds-question-checkbox-button-group
        *ngSwitchCase="'CheckboxButtonGroupQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
        (change)="handleChange($event)"
      ></crds-question-checkbox-button-group>
      <app-attachment-question
        *ngSwitchCase="'AttachmentQuestion'"
        [question]="question"
        [project]="project"
        [form]="form"
        [notify]="isNewProject"
        (valueUpdated)="updateAttachments($event)"
      ></app-attachment-question>
      <app-now-date-question
        *ngSwitchCase="'NowDateQuestion'"
        [question]="question"
        [form]="form"
        setDefault="true"
      ></app-now-date-question>
      <app-multi-text-with-attachment
        *ngSwitchCase="'MultiTextAttachment'"
        [question]="question"
        [project]="project"
        [form]="form"
        limit="5"
      ></app-multi-text-with-attachment>
      <app-array-question
        *ngSwitchCase="'ArrayQuestion'"
        [question]="question"
        [project]="project"
        [form]="form"
      ></app-array-question>
      <crds-question-file-upload
        *ngSwitchCase="'FileUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      ></crds-question-file-upload>
      <crds-question-image-or-file-upload
        *ngSwitchCase="'ImageOrFileUploadQuestion'"
        [question]="question"
        [form]="form"
        [i18ns]="i18ns"
      >
      </crds-question-image-or-file-upload>
      <crds-question-google-maps-multi-marker
        *ngSwitchCase="'MultiMarkerQuestion'"
        [question]="question"
        [form]="form"
      ></crds-question-google-maps-multi-marker>
      <crds-availability
        *ngSwitchCase="'AvailabilityQuestion'"
        [question]="question"
        [form]="form"
        [project]="project"
        (change)="handleChange($event)"
      ></crds-availability>
      <crds-question-hint *ngSwitchCase="'QuestionHint'" [question]="question"></crds-question-hint>
    </div>
  `,
  styleUrls: ['edit-layout-questions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditLayoutQuestionsComponent implements OnInit {
  @Input() question: any;
  @Input() form: UntypedFormGroup;
  @Input() project?: any;
  i18ns: I18nKeys;

  constructor(private cardsLibI18nService: CardsLibTranslationService) {}

  get isNewProject(): boolean {
    return this.project && !this.project['tenant'];
  }

  ngOnInit() {
    this.i18ns = this.cardsLibI18nService.loadTranslations();
  }

  handleChange(evt) {
    if (shouldTrackQuestion(evt.target.id)) {
      Analytics.logEvent(`Question: ${evt.target.id}`, { answer: evt.target.value });
    }
  }

  updateAttachments($event: any[]) {
    updateProjectAttachments($event, this.project, this.question.key);
  }

  updateAddress($event: { address: IAddressV2; location: string }, key: string) {
    this.form.get(key)?.setValue($event.address);
    this.form.get('installationLatLng')?.setValue($event.location);
  }
}
