import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface TextDetection {
  DetectedText?: string;
  Type?: string;
  Id?: number;
  ParentId?: number;
  Confidence?: number;
  Geometry?: {
    BoundingBox?: {
      Width: number;
      Height: number;
      Left: number;
      Top: number;
    };
    Polygon: {
      X: number;
      Y: number;
    }[];
  };
}

@Injectable()
export class OcrService {
  private apiRecogniseUrl = 'https://api.jumptech.co.uk/core/recognise';

  constructor(private httpClient: HttpClient) {}

  recognise(image: any) {
    return this.httpClient
      .post<TextDetection[]>(
        this.apiRecogniseUrl,
        { image: image },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .toPromise();
  }
}
