import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AssignmentMappingDisplay, IAutoAssignMappingsVm } from '../../domain';
import { AssignmentMappingsPresenter } from './assignment-mappings.presenter';

@Component({
  selector: 'app-assignment-mappings-component',
  templateUrl: './assignment-mappings.component.html',
  styleUrls: ['./assignment-mappings.component.scss']
})
export class AssignmentMappingsComponent implements OnInit {
  vm: IAutoAssignMappingsVm;

  @Output() mappingsChanged: EventEmitter<AssignmentMappingDisplay[]> = new EventEmitter<AssignmentMappingDisplay[]>();

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private presenter: AssignmentMappingsPresenter) {}

  async ngOnInit() {
    await this.presenter.load(
      generatedModel => {
        this.vm = generatedModel;
        this.searchFilterChange();
        this.onMappingsChange();
      },
      topHit => {
        this.scrollToTopHit(topHit);
      }
    );
  }

  searchFilterChange() {
    if (!this.vm.searchFilter) {
      this.vm.filteredMappings = this.vm.mappings;
      return;
    }
    const filter = this.vm.searchFilter.toLocaleLowerCase();
    this.vm.filteredMappings = this.vm.mappings.filter(
      m =>
        m.match.toLocaleLowerCase().includes(filter) ||
        m.tenantDisplay.toLocaleLowerCase().includes(filter) ||
        m.rank.toString().includes(filter)
    );
  }

  async copy() {
    await this.presenter.copy(this.vm);
  }

  async paste() {
    await this.presenter.paste(this.vm, await navigator.clipboard.readText());
    this.searchFilterChange();
    this.onMappingsChange();
  }

  onMappingsChange() {
    if (this.mappingsChanged) {
      this.mappingsChanged.emit(this.vm.mappings);
    }
  }

  mappingsUpdated() {
    this.onMappingsChange();
  }

  async save() {
    await this.presenter.save(this.vm);
    this.onMappingsChange();
  }

  addRow() {
    this.presenter.addRow(this.vm);
    this.presenter.checkingMappingsValid(this.vm);
    this.searchFilterChange();
    this.onMappingsChange();
  }

  matchChange(row: AssignmentMappingDisplay, event: FocusEvent) {
    this.presenter.matchChange(row, (event.target as HTMLInputElement).value);
    this.presenter.checkingMappingsValid(this.vm);
    this.onMappingsChange();
  }

  rankChange(row: AssignmentMappingDisplay, event: FocusEvent) {
    this.presenter.rankChange(row, parseInt((event.target as HTMLInputElement).value));
    this.presenter.checkingMappingsValid(this.vm);
    this.onMappingsChange();
  }

  tenantChange(tenantIds: string[], row: AssignmentMappingDisplay) {
    this.presenter.tenantChange(row, tenantIds?.[0], this.vm.tenants, this.vm.subTenants);
    this.presenter.checkingMappingsValid(this.vm);
    this.onMappingsChange();
  }

  deleteRow(row: AssignmentMappingDisplay) {
    this.presenter.deleteRow(this.vm, row);
    this.presenter.checkingMappingsValid(this.vm);
    this.searchFilterChange();
    this.onMappingsChange();
  }

  getTestMatchClass(topHitId: string) {
    return (row: AssignmentMappingDisplay) => {
      if (row.id === topHitId) {
        return 'test-match';
      }
      return 'non-match';
    };
  }

  scrollToTopHit(topHitId: string) {
    if (!this.vm.mappings?.length || !topHitId) {
      return;
    }
    const index = this.vm.mappings.findIndex(x => x.id === topHitId);
    this.table.offset = Math.ceil((index + 1) / this.vm.pageSize) - 1 || 0;
  }
}
