<ng-container *ngIf="useLayoutGroups; else standardGallery">
  <div *ngFor="let item of layoutCollection">
    <div *ngFor="let layout of item.layouts">
      <div class="gallery" [attr.data-qa]="'galleryGroup_' + layout.label">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              {{ layout.label }}
            </div>
          </div>
          <div class="card-body">
            <div>
              <ng-container
                *ngTemplateOutlet="galleryLayout; context: { layout: layout, cssClass: 'gallery__group' }"
              ></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #standardGallery>
  <div class="gallery-col">
    <ng-container *ngFor="let item of layoutCollection; index as colIndex">
      <ng-container *ngIf="colIndex <= layoutCollection.length / 3">
        <ng-container *ngFor="let layout of item.layouts">
          <ng-container *ngTemplateOutlet="galleryLayout; context: { layout: layout }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="gallery-col">
    <ng-container *ngFor="let item of layoutCollection; index as colIndex">
      <ng-container *ngIf="colIndex > layoutCollection.length / 3 && colIndex <= (layoutCollection.length / 3) * 2">
        <ng-container *ngFor="let layout of item.layouts">
          <ng-container *ngTemplateOutlet="galleryLayout; context: { layout: layout }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="gallery-col">
    <ng-container *ngFor="let item of layoutCollection; index as colIndex">
      <ng-container *ngIf="colIndex > (layoutCollection.length / 3) * 2">
        <ng-container *ngFor="let layout of item.layouts">
          <ng-container *ngTemplateOutlet="galleryLayout; context: { layout: layout }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #galleryLayout let-layout="layout" let-cssClass="cssClass">
  <app-gallery-layout
    [class]="cssClass"
    [readOnly]="isGalleryReadOnly(layoutCollection)"
    [project]="project"
    [data]="project.data"
    [layout]="layout"
    [useLayoutGroups]="useLayoutGroups"
  >
  </app-gallery-layout>
</ng-template>
