import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { CardControlService } from '../card-control.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../domain/user';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import {
  DelegationShare,
  DelegationShareSubTenant,
  ProjectAssignmentTeam
} from '../../admin/user-management/domain/types';
import { v4 as uuidv4 } from 'uuid';
import { DashboardSearchSettings } from '../../dashboards/abstract.dashboard';
import { Project } from '../domain/project';
import * as Analytics from '../../app.analytics';
import { ProjectConfigurationService } from '../project-configuration.service';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { UserService } from '../../auth/services/user.service';
import { DelegationService } from '../delegate/delegation.service';

type PartialProject = Pick<Project, 'id' | 'attachments'>;

@Component({
  selector: 'app-new-project-modal',
  templateUrl: 'new-project-modal.component.html',
  styleUrls: ['new-project-modal.component.scss']
})
export class NewProjectModalComponent implements OnInit {
  partialProject: PartialProject;
  form: UntypedFormGroup;
  user: User;
  projectConfigurationTypes: { projectType: string }[];
  delegates: DelegationShare[] = [];
  assignments: ProjectAssignmentTeam[] = [];
  assignmentLabel: string;
  canDelegate = false;
  canAssign = false;
  loading = false;
  newProjectLayout: any;
  formInitialised = false;
  createInProgress = false;

  // Sub Tenants
  subTenants: DelegationShareSubTenant[] = null;
  selectDelegates: any[] = [];
  selectSubTenants: DelegationShareSubTenant[] = [];
  fullSubTenants: {
    [key: string]: DelegationShareSubTenant[];
  } = {};

  tenantConfig: any;
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;

  /**
   * This is the default used if there is no cognitoCountryConfig in tenantConfig
   */
  defaultAllowedIsoCountry = CountryISO.UnitedKingdom;

  allowedCountries = [];
  preferredCountries = [];
  defaultCountry = null;

  get showSubTenants() {
    return this.selectSubTenants && this.selectSubTenants.length;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private projectConfigurationService: ProjectConfigurationService,
    private cardControlService: CardControlService,
    private spinnerService: NgxSpinnerService,
    private featureAccessService: AccessService,
    private userService: UserService,
    private delegationService: DelegationService
  ) {}

  /**
   * Get the selection of Project Types available and initialise the form.
   * Subscribe to Project Type changes to update the form layout.
   */
  async ngOnInit() {
    this.partialProject = {
      id: uuidv4(),
      attachments: []
    };
    this.subscribeToUser();
    this.tenantConfig = await this.featureAccessService.getTenantConfig();
    await this.setProjectTypes();
    this.updateFormForNullProjectType();
  }

  private subscribeToUser() {
    this.userService.userObservable.subscribe((user: User) => {
      if (user !== null) {
        this.user = user;
      }
    });
  }

  private initialiseForm(projectType: string) {
    this.form = new UntypedFormGroup({
      projectType: new UntypedFormControl(projectType, Validators.required),
      team: new UntypedFormControl(null),
      delegate: new UntypedFormControl(null, Validators.required),
      subTenants: new UntypedFormControl(null, Validators.required)
    });
    this.form.get('projectType').valueChanges.subscribe(async projectType => {
      if (projectType === null || projectType === 'null') {
        this.updateFormForNullProjectType();
      } else {
        await this.updateFormForProjectType(projectType);
      }
      await this.setDelegates(projectType);
      await this.setTeams(projectType);
    });
  }

  private updateFormForNullProjectType() {
    this.formInitialised = false;
    this.newProjectLayout = [];
    this.initialiseForm(null);
    this.formInitialised = true;
  }

  private async updateFormForProjectType(projectType: string) {
    this.formInitialised = false;
    this.canDelegate = await this.featureAccessService.isDataSharingAccessAllowedForProject(
      this.user.tenant,
      projectType
    );

    this.canAssign = await this.featureAccessService.isTeamAssignmentAllowedForProject(this.user.tenant, projectType);
    this.newProjectLayout = await this.projectConfigurationService.getNewProjectLayoutByProjectType(projectType);
    this.initialiseForm(projectType);

    const customFormGroup = await this.cardControlService.toFormGroup(this.newProjectLayout?.items);
    if (this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.UserIsCustomer) && this.user) {
      const userDetailsForm = new UntypedFormBuilder().group({
        firstName: new UntypedFormControl({ value: this.firstName, disabled: true }),
        lastName: new UntypedFormControl({ value: this.lastName, disabled: true })
      });

      this.form = new UntypedFormBuilder().group({
        ...this.form.controls,
        ...customFormGroup.controls,
        ...userDetailsForm.controls
      });
    } else {
      this.form = new UntypedFormBuilder().group({
        ...this.form.controls,
        ...customFormGroup.controls
      });
    }

    if (this.form.controls['phoneNumber']) {
      this.setCognitoPhoneNumberPrefs();
      this.form.addControl('intlPhone', new UntypedFormControl(''));
      this.form.get('intlPhone').valueChanges.subscribe(change => {
        if (change) {
          this.form.get('phoneNumber').patchValue(change.internationalNumber.replace(/ |-/g, ''));
          this.form.get('phoneNumber').updateValueAndValidity({ onlySelf: true, emitEvent: false });
          this.form.get('phoneNumber').markAsDirty();
        }
      });
    }

    this.formInitialised = true;
  }

  private get firstName() {
    return this.user.label.split(' ')[0];
  }

  private get lastName() {
    return this.user.label.split(' ')[1];
  }

  private get email() {
    return this.user.email || null;
  }

  private async setProjectTypes() {
    this.projectConfigurationTypes = await this.projectConfigurationService.getProjectTypes(
      PathwayFeature.ProjectCreation
    );
  }

  private async setDelegates(projectType: string) {
    if (this.canDelegate) {
      this.loading = true;
      this.delegates = await this.delegationService.getDelegatesForProjectType(projectType, true);
      if (!this.delegates.length) {
        this.disableDelegate();
      } else {
        this.form.get('delegate').enable();
      }
      this.loading = false;
    } else {
      this.disableDelegate();
    }
    this.onDelegateChange();
    return true;
  }

  private async setTeams(projectType: string) {
    if (this.canAssign) {
      this.loading = true;
      this.assignments = await this.featureAccessService.getTeamsForProjectType(projectType, true);
      this.assignmentLabel = await this.featureAccessService.getAssignmentLabel();
      if (!this.assignments.length) {
        this.disableTeam();
      } else {
        this.form.get('team').enable();
      }
      this.loading = false;
    } else {
      this.disableTeam();
    }
    return true;
  }

  private disableDelegate() {
    if (!this.form || !this.form.get('delegate')) {
      return;
    }
    const formControl = this.form.get('delegate');
    formControl.patchValue('');
    formControl.disable();
  }

  private disableTeam() {
    if (!this.form || !this.form.get('team')) {
      return;
    }
    const formControl = this.form.get('team');
    formControl.patchValue('');
    formControl.disable();
  }

  createProject() {
    this.createInProgress = true;
    this.spinnerService.show();
    this.apiService
      .addProject({
        ...this.partialProject,
        type: this.form.value.projectType,
        owner: this.form.value.owner || null,
        owner_name: this.form.value.owner ? this.form.value.owner.split('|')[0] : null,
        assigned_to_team: this.form.value.team ? this.form.value.team : null,
        assigned_to_team_name: this.form.value.team
          ? this.assignments.find(x => x.id === this.form.value.team).name
          : null,
        delegate: this.form.value.delegate || null,
        subTenants: this.form.value.subTenants || null,
        data: this.form.getRawValue()
      })
      .subscribe(
        id => {
          console.log(JSON.stringify(id));
          this.activeModal.close({ id });
          this.createInProgress = false;
          this.spinnerService.hide();
          Analytics.logEvent('ProjectCreated', { projectType: this.form.value.projectType });
        },
        error => {
          this.spinnerService.hide();
          this.createInProgress = false;
          console.log(error);
        }
      );
  }

  isInvalid(field) {
    if (field === 'intlPhone') {
      return this.form.get(field).touched && this.form.get(field).dirty && this.form.get(field).invalid;
    }

    if (field === 'subTenants') {
      return this.showSubTenants && this.form.get(field).invalid;
    }

    return this.form.get(field).dirty && this.form.get(field).invalid;
  }

  private onDelegateChange() {
    const selectedDelegate = this.form.get('delegate').value;
    if (selectedDelegate === 'null') {
      this.form.controls.delegate.patchValue(null);
      this.form.controls.subTenants.patchValue(null);
    }
    this.selectDelegates = Object.assign(
      [],
      this.delegates.map(delegate => {
        return { id: delegate.tenant, name: delegate.tenant };
      })
    );
    for (const delegate of this.delegates) {
      this.fullSubTenants[delegate.tenant] = delegate.subTenants;
    }

    this.selectSubTenants = [];

    setTimeout(() => {
      this.selectSubTenants = selectedDelegate ? this.fullSubTenants[selectedDelegate] : [];
      if (this.selectSubTenants && this.selectSubTenants.length) {
        this.form.controls.subTenants.enable();
      } else {
        this.form.controls.subTenants.disable();
      }
    });

    if (!this.form || !this.form.get('projectOwner')) {
      return;
    }
    const formControl = this.form.get('delegate');
    if (formControl && formControl.value === null) {
      this.form.get('projectOwner').enable();
    } else {
      this.form.get('projectOwner').patchValue('');
      this.form.get('projectOwner').disable();
    }
  }

  setCognitoPhoneNumberPrefs() {
    this.allowedCountries = this.getAllowedCountries();
    this.preferredCountries = this.getPreferredCountries();
    this.defaultCountry = this.getDefaultCountry();
  }

  getAllowedCountries(): CountryISO[] {
    if (!this.tenantConfig.cognitoCountryConfig) {
      return [this.defaultAllowedIsoCountry];
    }

    const allowedCountries = this.tenantConfig.cognitoCountryConfig.allowedCountries;

    return allowedCountries.length
      ? allowedCountries.map(countryString => CountryISO[countryString])
      : [this.defaultAllowedIsoCountry];
  }

  getPreferredCountries(): CountryISO[] {
    if (!this.tenantConfig.cognitoCountryConfig) {
      return [];
    }
    return (this.tenantConfig.cognitoCountryConfig.preferredCountries || []).map(
      countryString => CountryISO[countryString]
    );
  }

  getDefaultCountry(): string | null {
    if (!this.tenantConfig.cognitoCountryConfig) {
      return this.defaultAllowedIsoCountry;
    }
    return this.tenantConfig.cognitoCountryConfig.defaultCountry
      ? CountryISO[this.tenantConfig.cognitoCountryConfig.defaultCountry] || null
      : null;
  }

  setSubTenants(subTenants: any[]) {
    const delegate = this.form.get('delegate').value;
    this.form.controls.subTenants.patchValue(
      delegate && subTenants.length
        ? this.fullSubTenants[delegate].filter(subTenant => subTenants.includes(subTenant.id))
        : null
    );
    this.form.controls.subTenants.markAsDirty();
  }
}
