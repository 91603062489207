import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { LOGIN_PATH } from '../../app.routes';
import { AuthenticationService } from './authentication.service';
import { ApiService } from '../../core/api.service';
import { User } from '../../core/domain/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public userObservable: Observable<User> = this.userSubject.asObservable();

  get currentUser() {
    return this.userSubject.value;
  }

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.authenticationService.authObservable.subscribe(user => {
      if (!user) {
        this.userSubject.next(null);
        return;
      }
      const userName = user?.username;
      if (userName != null) {
        this.updateUser(userName).then(() => {});
      }
    });
  }

  async updateUser(userName?: string) {
    this.apiService.getUser(userName || this.authenticationService.user.username).subscribe(async (user: any) => {
      if (!user) {
        await this.router.navigate([LOGIN_PATH], { replaceUrl: true });
      } else {
        this.userSubject.next(user);
      }
    });
  }

  resetPassword() {
    const userName = this.userSubject.getValue().userName;
    return this.apiService.resetPassword(userName);
  }

  async getUserById(id: string) {
    return await this.apiService.getUser(id).toPromise();
  }
}
