export class TimeSlotHelper {
  public static getStart(date: Date, timeSlot: string) {
    if (!timeSlot) {
      return date;
    }
    const parts = timeSlot.trim().split('-');
    if (!parts?.length) {
      return date;
    }
    return TimeSlotHelper.getDateTime(date, parts[0]);
  }

  public static getEnd(date: Date, timeSlot: string) {
    if (!timeSlot) {
      return date;
    }
    const parts = timeSlot.split('-');
    if (parts?.length < 2) {
      return date;
    }
    return TimeSlotHelper.getDateTime(date, parts[1]);
  }

  private static getDateTime(date: Date, slotPart: string): Date {
    let hours = 0;
    let minutes = 0;
    const isPm = slotPart.trim().endsWith('pm');

    slotPart = slotPart.replace(/am|pm/i, '');

    const parts = slotPart.trim().split(':');
    if (parts?.length) {
      hours = parseInt(parts[0].trim());
      if (parts?.length > 1) {
        minutes = parseInt(parts[1].trim());
      }
    }

    if (isPm && hours < 12) {
      hours = hours + 12;
    }

    date.setHours(hours, minutes, 0, 0);

    return date;
  }
}
