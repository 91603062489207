import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-document-pack-main-action',
  templateUrl: './document-pack-main-action.component.html',
  styleUrls: ['../../document-pack.component.scss']
})
export class DocumentPackMainActionComponent {
  @Input() inProgress = false;
  @Input() isDisabled = false;
  @Input() label = '';
  @Input() qaHook: string;
  @Output() actionClick: EventEmitter<void> = new EventEmitter();
}
