<div [formGroup]="form">
  <div class="image-file-upload">
    <div class="custom-file image-file-upload__input">
      <input
        class="custom-file-input"
        type="file"
        [placeholder]="placeholder"
        [accept]="accept"
        (change)="onFileChange($event.target.files)"
        [id]="id"
        (click)="upload($event)"
      />
      <label class="custom-file-label" #labelImport [for]="id">
        {{ placeholder }}
      </label>
    </div>

    <div *ngIf="hasImage" class="image-file-upload__preview">
      <div class="image-file-upload__thumb">
        <crds-inline-spinner
          *ngIf="!imageSrc"
          colour="var(--jds-theme-color-brand-primary)"
          name="imageUploadSpinner"
          center="true"
        ></crds-inline-spinner>
        <img *ngIf="imageSrc" [src]="imageSrc" alt="image" class="image-file-upload__img" />
      </div>
      <div class="image-file-upload__remove">
        <jui-icon color="danger" size="sm" name="delete" type="outlined" (click)="removeImage()"></jui-icon>
      </div>
    </div>
  </div>
</div>
