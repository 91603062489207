import { Component } from '@angular/core';

@Component({
  selector: 'app-reset-password-success',
  templateUrl: './reset-password-success.component.html',
  styleUrls: ['../../login.component.scss']
})
export class ResetPasswordSuccessComponent {
  constructor() {}
}
