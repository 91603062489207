<form [formGroup]="smsMfaRequiredForm">
  <p class="login-card__txt">
    <span>{{ 'auth.mfaCodeVerify' | transloco }}</span>
  </p>
  <label class="login-card__lbl" for="new-code">{{ 'auth.formFields.mfaCode.label' | transloco }}</label>
  <div class="login-card__row">
    <input
      #codeInput
      class="form-control login-card__ctrl"
      type="text"
      placeholder="{{ 'auth.formFields.mfaCode.placeholder' | transloco }}"
      formControlName="code"
      (keyup.enter)="smsMfaSubmit()"
      autocomplete="new-code"
      id="new-code"
      data-qa="codeInput"
    />
  </div>
  <div class="login-card__row">
    <div class="login-card__row-50" [class.login-card__btn--disabled]="smsMfaRequiredForm.invalid">
      <jui-button
        size="xl"
        expand
        data-qa="mfaRequiredButton"
        [disabled]="smsMfaRequiredForm.invalid || submitInProgress"
        (click)="smsMfaSubmit()"
        [loading]="submitInProgress"
      >
        <span *ngIf="!submitInProgress">{{ 'Buttons.mfaConfirmCode.text' | transloco }}</span>
        <span *ngIf="submitInProgress">{{ 'Buttons.mfaConfirmingCode.text' | transloco }}</span>
      </jui-button>
    </div>
    <div class="login-card__row-50">
      <jui-button
        size="xl"
        color="low"
        expand
        data-qa="mfaResendButton"
        [disabled]="sendSmsInProgress"
        (click)="resendCode()"
      >
        <span *ngIf="!sendSmsInProgress">{{ 'Buttons.mfaResendCode.text' | transloco }}</span>
        <span *ngIf="sendSmsInProgress">{{ 'Buttons.mfaResendingCode.text' | transloco }}</span>
      </jui-button>
    </div>
  </div>
  <jui-button size="sm" color="subtle" expand (click)="setView()">
    {{ 'Buttons.backToSignIn.text' | transloco }}
  </jui-button>
</form>
