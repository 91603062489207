import 'moment/min/moment-with-locales';

import * as moment from 'moment-timezone';
import * as Mustache from 'mustache-formats';

const DEFAULT_TIMEZONE = 'Europe/London';
const DEFAULT_LOCALE = 'en-gb';

export interface RenderOptions {
  timezone?: string;
  locale?: string;
}

export interface Renderer {
  formatDate: (date: any, format: string) => string | any;
  formatImage: (value: any, styling: string) => string | any;
  formatCurrency: (value: any, currencyCode?: string) => string | any;
  getTimezone: () => string;
  render: (template: string, view: unknown, partials?: any, tags?: string[]) => string;
  getLocale: () => string;
}

interface RendererFormatter {
  Formatters: { [name: string]: (...rest) => string };
}

/**
 * Create a new renderer with its own timezone and locale (otherwise defaults to London)
 * @param timezone
 * @param locale
 */
export const createRenderer = ({
  timezone = DEFAULT_TIMEZONE,
  locale = DEFAULT_LOCALE
}: RenderOptions = {}): Renderer => {
  if (!timezone) {
    timezone = DEFAULT_TIMEZONE;
  }
  if (!locale) {
    locale = DEFAULT_LOCALE;
  }

  /** format a date using the current locale and timezone */
  const formatDate = (date: any, format: string) => {
    if (!date) {
      return ''; // Don't display today's date if there isn't a value
    }
    moment.locale(locale);
    const tz = moment.tz(date, timezone);
    if (!tz.isValid()) {
      return '!!!!'; // Return a string that doesn't need localising
    }
    return tz.format(format);
  };

  /** format the image */
  const formatImage = (value: any, styling = 'height="40"') => {
    const rawTag = Array.isArray(value) && value.length ? value[value.length - 1] : value || '';
    return rawTag.replace('{{styling}}', styling);
  };

  const formatCurrency = (value: any, currencyCode?: string) => {
    // value could be number, string, undefined, null
    let numVal: number;
    switch (typeof value) {
      case 'number':
        numVal = value;
        break;
      case 'string':
        numVal = parseFloat(value);
        if (isNaN(numVal)) {
          numVal = 0;
        }
        break;
      default:
        return '';
    }
    return numVal.toLocaleString(locale, {
      style: 'currency',
      currency: currencyCode || 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const mustache = Mustache as unknown as RendererFormatter;
  mustache.Formatters['date'] = formatDate;
  mustache.Formatters['image'] = formatImage;
  mustache.Formatters['currency'] = formatCurrency;

  return {
    /** Call the Mustache.render, but ensure we use the default Europe/London timezone */
    render: (template: string, view: unknown, partials?: any, tags?: string[]) => {
      return Mustache.render(template, view, partials, tags);
    },

    getTimezone: () => {
      return timezone;
    },

    getLocale: () => {
      return locale;
    },

    formatDate,

    formatImage,

    formatCurrency
  };
};
