<div class="order-view__summary">
  <div class="order-view__title">
    {{ vm.labelSummary }}
    <span class="order-view__val--emphasis" [class.order-view__val--cancelled]="vm.isCancelled">
      <span *ngIf="vm.isOptimistic" class="order-view__title-loader"
        ><jui-skeleton-loader
          color="primary"
          width="180px"
          height="18px"
          inline="true"
        ></jui-skeleton-loader
      ></span>
      <span *ngIf="!vm.isOptimistic">{{ vm.summaryStatus }}</span>
    </span>
  </div>
  <div class="order-view__data">
    <div class="order-view__key">{{ vm.labelShippingAddress }}</div>
    <div class="order-view__val">{{ vm.summaryAddress }}</div>
  </div>
  <div class="order-view__data">
    <div class="order-view__key">{{ vm.labelPreferredShipmentDate }}</div>
    <div class="order-view__val">{{ vm.summaryPreferredShipDate }}</div>
  </div>
  <div class="order-view__data" *ngIf="vm.showPackage">
    <div class="order-view__key">{{ vm.labelPackage }}</div>
    <div class="order-view__val order-view__val--package">
      {{ vm.summaryDefaultPackage }} <span class="order-view__val--foc">{{ vm.labelFoC }}</span>
    </div>
  </div>
  <div class="order-view__data">
    <div class="order-view__key">{{ vm.labelHardware }}</div>
    <div class="order-view__val order-view__val--items">{{ vm.summaryHardwareOrdered }}</div>
  </div>
</div>
