import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IAddressV2 } from '../../domain/address-v2';
import { FormUpdate, TextboxComponent } from '@jump-tech-frontend/question-components';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { BehaviorSubject } from 'rxjs';
import { AddressEditorViewModel } from './address-editor.vm';
import { AddressEditorPresenter } from './address-editor.presenter';

@Component({
  selector: 'jump-tech-frontend-address-editor',
  standalone: true,
  templateUrl: './address-editor.component.html',
  imports: [CoreComponentsAngularModule, ReactiveFormsModule, TextboxComponent, NgIf, AsyncPipe, NgForOf],
  providers: [AddressEditorPresenter],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['address-editor.component.scss']
})
export class AddressEditorComponent implements OnChanges {
  @Input() countryCodes: string[];
  @Input() address?: IAddressV2;
  @Output() addressUpdated = new EventEmitter<IAddressV2>();

  private readonly addressEditorPresenter = inject(AddressEditorPresenter);
  public vm$ = new BehaviorSubject<AddressEditorViewModel | null>(null);

  ngOnChanges(changes: SimpleChanges): void {
    const adddressChange = changes?.['address'];
    if (adddressChange) {
      this.addressEditorPresenter.load(this.vm$, this.countryCodes, this.address);
    }
  }

  fieldChanged($event: FormUpdate) {
    this.addressEditorPresenter.fieldChanged(this.vm$ as BehaviorSubject<AddressEditorViewModel>, $event);
  }
}
