import { AccessInfo, User } from '../core/domain/user';
import { arraysIntersect } from './utils';

export interface RoleOrTeamInput {
  roleName?: string[];
  teamName?: string[];
  roleId?: string[];
  teamId?: string[];
  tenant?;
}

export class AccessInfoHelper {
  public static accessInfoHasRoleOrTeam(accessInfo: AccessInfo, input: RoleOrTeamInput) {
    if (!accessInfo) {
      return false;
    }

    if (
      input.roleName &&
      arraysIntersect(
        accessInfo.roles.map(r => r.name),
        input.roleName
      )
    ) {
      return true;
    }

    if (
      input.roleId &&
      arraysIntersect(
        accessInfo.roles.map(r => r.id),
        input.roleId
      )
    ) {
      return true;
    }

    if (
      input.teamName &&
      arraysIntersect(
        accessInfo.teams.map(t => t.name),
        input.teamName
      )
    ) {
      return true;
    }

    return (
      input.teamId != undefined &&
      arraysIntersect(
        accessInfo.teams.map(t => t.id),
        input.teamId || []
      )
    );
  }

  public static filterListForUser(item, user: User, customFilterField = 'filter') {
    if (!user.accessInfo) {
      return false;
    }

    if (!item[customFilterField] || !item[customFilterField]?.length) {
      return true;
    }

    const roleOrTeamInput: RoleOrTeamInput = {
      roleId: (user.accessInfo.roles || []).reduce((a, role) => {
        return [...a, role.id];
      }, []),
      roleName: (user.accessInfo.roles || []).reduce((a, role) => {
        return [...a, role.name];
      }, []),
      teamId: (user.accessInfo.teams || []).reduce((a, team) => {
        return [...a, team.id];
      }, []),
      teamName: (user.accessInfo.teams || []).reduce((a, team) => {
        return [...a, team.name];
      }, []),
      tenant: user.tenant
    };

    const filterSwitchForTenant = (operator, value) => {
      switch (operator) {
        case '$eq':
          return roleOrTeamInput.tenant === value || roleOrTeamInput.tenant === '*';
        case '$ne':
          return roleOrTeamInput.tenant !== value;
        case '$in':
          return (
            roleOrTeamInput.tenant
              .split(',')
              .map(valueItem => valueItem.trim())
              .indexOf(value) > -1
          );
        case '$nin':
          return (
            roleOrTeamInput.tenant
              .split(',')
              .map(valueItem => valueItem.trim())
              .indexOf(value) === -1
          );
      }
    };

    const filterSwitchForRoleOrTeam = (operator, value, filterField) => {
      switch (operator) {
        case '$eq':
          return roleOrTeamInput[filterField].indexOf(value) > -1 || value === '*';
        case '$ne':
          return roleOrTeamInput[filterField].indexOf(value) === -1;
        case '$in':
          return (
            roleOrTeamInput[filterField].filter(filterFieldItem => {
              return (
                value
                  .split(',')
                  .map(filterFieldItem => filterFieldItem.trim())
                  .indexOf(filterFieldItem) > -1
              );
            }).length > 0
          );
        case '$nin':
          return (
            roleOrTeamInput[filterField].filter(filterFieldItem => {
              return (
                value
                  .split(',')
                  .map(filterFieldItem => filterFieldItem.trim())
                  .indexOf(filterFieldItem) > -1
              );
            }).length === 0
          );
      }
    };

    const filterSwitch = (filterField, operator, value) => {
      if (filterField === 'tenant') {
        return filterSwitchForTenant(operator, value);
      } else {
        return filterSwitchForRoleOrTeam(operator, value, filterField);
      }
    };

    let allowItem = false;

    for (const taskComponentFilter of item[customFilterField]) {
      const filterField = Object.keys(taskComponentFilter)[0];
      const operator = Object.keys(taskComponentFilter[filterField])[0];
      const value = taskComponentFilter[filterField][operator];
      if (filterSwitch(filterField, operator, value) === true) {
        allowItem = true;
      }
    }
    return allowItem;
  }
}
