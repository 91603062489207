import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'lib-formly-horizontal-field-wrapper',
  template: `
    <div class="field-wrap">
      <label [attr.for]="id" class="wrapper-label mr-1 col-form-label" *ngIf="to.label">
        {{ to.label }}
        <ng-container *ngIf="to.required === true">*</ng-container>
      </label>
      <div class="flex-fill">
        <ng-template #fieldComponent></ng-template>
        <jui-alert-block size="sm" iconName="info" *ngIf="controlHint" clearable="true">{{
          controlHint
        }}</jui-alert-block>
      </div>
    </div>
    <div *ngIf="hint" class="mt-1 alert alert-info" role="alert">
      {{ hint }}
    </div>
    <div *ngIf="showError" class="mb-1 invalid-feedback d-block">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  `,
  styles: [
    `
      .field-wrap {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        padding-left: 0.5rem;
      }
      .wrapper-label {
        width: 100px;
        word-break: break-word;
      }
      .form-group {
        background-color: #00000015;
      }
    `
  ]
})
export class HorizontalFieldWrapperComponent extends FieldWrapper implements OnInit {
  public hint: string | undefined;
  public controlHint: string | undefined;
  ngOnInit() {
    if (this.field.key === 'controlType') {
      this.subscribeToControlTypeChanges();
    }
    if (this.field.key === 'hardwareOrderingMetaStatus') {
      this.controlHint = this.field?.templateOptions?.hint;
    }
  }
  subscribeToControlTypeChanges() {
    this.formControl.valueChanges.subscribe((value: any) => {
      switch (value) {
        case 'AddressTypeaheadQuestion':
          this.hint =
            'This Question type can safely be changed to from any other "Legacy" Address Question type. ' +
            'If moving from "Post Code Question - UK Only", please be sure to also remove the "Address Dropdown (use with Post Code - UK Only)" question.';
          break;
        case 'GooglePlacesAddress':
          this.hint = 'This Question type can safely be changed to "Address Lookup - Typeahead (NEW)"';
          break;
        case 'PostcodeQuestion':
          this.hint =
            'This Question type can safely be changed to "Address Lookup - Typeahead (NEW)". ' +
            'Please be sure to also remove the "Address Dropdown (use with Post Code - UK Only)" question.';
          break;
        case 'PostcodeV2Question':
          this.hint =
            'This Question type can normally safely be changed to "Address Lookup - Typeahead (NEW)". ' +
            'N.B Keep this question type in the specific use case of requiring dynamic GB/IE (Post code/Eircode) switching. ';
          break;
        default:
          this.hint = this.field?.templateOptions?.hint ?? '';
      }
    });
  }
}
