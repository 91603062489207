enum AccessLevel {
  READ_ONLY = 0,
  EDIT = 1,
  ADMIN = 99
}

interface PathAccess {
  path: string;
  filter?: { [key: string]: string }[];
  access?: AccessLevel;
}

export type ProjectFilter = { [key: string]: { [operator: string]: string } };

export interface Role {
  id?: string;
  name: string;
  paths: PathAccess[];
  features?: Feature[];
}

export interface Feature {
  id?: string;
  name: string;
  enabled: boolean;
  type?: string;
  path?: string;
  filter?: ProjectFilter[];
}

export interface DataSharing {
  id: string;
  name: string;
  enabled: boolean;
  type: string;
  data?: DelegationShare[];
  tenant: string;
}

export interface DelegationShare {
  projectType: string;
  tenant: string;
  subTenants: DelegationShareSubTenant[];
}

export interface DelegationShareSubTenant {
  id: string;
  name: string;
}
