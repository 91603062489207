import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import 'moment/min/moment-with-locales';
import * as moment from 'moment';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const momentDate = moment(value, 'L');
      return {
        day: momentDate.date(),
        month: momentDate.month() + 1,
        year: momentDate.year()
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date) {
      return moment()
        .year(date.year)
        .month(date.month - 1)
        .date(date.day)
        .format('L');
    }
    return '';
  }
}
