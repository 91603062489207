<div class="strength-check a-fade-in-fast" *ngIf="form.controls['newPassword'].dirty">
  <div class="login-card__row">
    <div class="login-card__col">
      <div class="input-test" [class.input-test--valid]="checkFormError('hasNumber')">
        <span class="input-test__icon material-icons">
          {{ checkFormError('hasNumber') ? 'check' : 'clear' }}
        </span>
        <span class="input-test__message">{{ 'auth.error.needsNumber' | transloco }}</span>
      </div>
      <div class="input-test" [class.input-test--valid]="checkFormError('hasUpperCase')">
        <span class="input-test__icon material-icons">
          {{ checkFormError('hasUpperCase') ? 'check' : 'clear' }}
        </span>
        <span class="input-test__message">{{ 'auth.error.needsUpper' | transloco }}</span>
      </div>
      <div class="input-test" [class.input-test--valid]="checkFormError('hasLowerCase')">
        <span class="input-test__icon material-icons">
          {{ checkFormError('hasLowerCase') ? 'check' : 'clear' }}
        </span>
        <span class="input-test__message">{{ 'auth.error.needsLower' | transloco }}</span>
      </div>
      <div class="input-test" [class.input-test--valid]="checkFormError('hasSymbol')">
        <span class="input-test__icon material-icons">
          {{ checkFormError('hasSymbol') ? 'check' : 'clear' }}
        </span>
        <span class="input-test__message">{{ 'auth.error.needsSymbol' | transloco }}</span>
      </div>
      <div class="input-test" [class.input-test--valid]="checkFormError('minlength')">
        <span class="input-test__icon material-icons">
          {{ checkFormError('minlength') ? 'check' : 'clear' }}
        </span>
        <span class="input-test__message">{{ 'auth.error.minLength' | transloco }}</span>
      </div>
    </div>
  </div>
</div>
