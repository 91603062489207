import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { DelegateProjectButtonComponent } from './delegate-project-button.component';
import { DelegateProjectModalComponent } from './delegate-project-modal.component';
import { UndelegateProjectButtonComponent } from './undelegate-project-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { DelegationService } from '../../core/delegate/delegation.service';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [DelegateProjectModalComponent, DelegateProjectButtonComponent, UndelegateProjectButtonComponent],
  imports: [SharedModule, TranslocoModule, CoreModule],
  exports: [DelegateProjectButtonComponent, UndelegateProjectButtonComponent],
  providers: [DelegationService]
})
export class DelegationModule {}
