import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ImageError, ImageManipulationService } from '@jump-tech-frontend/cards';
import { SafeUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../../core/api.service';
import { NgxSpinnerService } from 'ngx-spinner';

export interface ImageChanged {
  controlId: string;
  newBase64Content: string;
}

export interface UploadError {
  controlId: string;
  error: ImageError;
}

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() accept: string;

  imageSrc: string | SafeUrl;

  hasImage = false;

  fileToUpload: File;
  @ViewChild('labelImport')
  labelImport: ElementRef;
  imageError: ImageError;

  @Output() imageChanged: EventEmitter<ImageChanged> = new EventEmitter<ImageChanged>();

  @Output() uploadError: EventEmitter<UploadError> = new EventEmitter<UploadError>();

  constructor(
    private imageService: ImageManipulationService,
    private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private spinnerService: NgxSpinnerService
  ) {}

  upload(htmlFileInput) {
    if (htmlFileInput.target.files.length) {
      htmlFileInput.currentTarget.value = null;
    }
  }

  async onFileChange(files: FileList) {
    if (files.length) {
      this.labelImport.nativeElement.innerText = files[0].name;
      this.fileToUpload = files[0];
      await this.validateImage();
      this.displayImage();
    } else {
      this.removeImage();
    }
  }

  displayImage() {
    this.hasImage = true;
  }

  removeImage() {
    this.labelImport.nativeElement.innerText = this.placeholder;
    this.hasImage = false;
    this.imageSrc = null;
    this.form.get(this.id).patchValue(null);
    this.imageChanged.emit({
      controlId: this.id,
      newBase64Content: null
    });
  }

  ngOnInit(): void {
    if (this.value) {
      this.hasImage = true;
      this.spinnerService.show('imageUploadSpinner').then(() => {
        this.getImageContent(this.value).then(content => {
          this.imageSrc = content;
          this.spinnerService.hide('imageUploadSpinner').then();
        });
      });
    } else {
      this.hasImage = false;
      this.imageSrc = null;
    }
  }

  async getImageContent(imageUrl: string) {
    const imageContent: string = await firstValueFrom(this.apiService.getUserImageContent(imageUrl));
    return this.sanitizer.bypassSecurityTrustUrl(imageContent);
  }

  async validateImage() {
    if (!this.fileToUpload) {
      return; // User cancelled
    }
    this.imageError = this.imageService.checkFileTypeAndSize(this.fileToUpload);
    if (this.imageError) {
      this.uploadError.emit({
        controlId: this.id,
        error: this.imageError
      });
      return;
    }
    this.imageSrc = await this.imageService.resizeImageFromFile(this.fileToUpload);
    this.imageChanged.emit({
      controlId: this.id,
      newBase64Content: this.imageSrc as string
    });
  }
}
