import { Injectable } from '@angular/core';
import { UserPreferencesService } from './user-preferences.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserPreferenceType } from './domain/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserThemeService {
  themeOptions: ThemeOption[] = [
    { value: 'light', label: 'theme.light', icon: 'light_mode' },
    { value: 'dark', label: 'theme.dark', icon: 'dark_mode' },
    { value: 'system', label: 'theme.system', icon: 'computer' }
  ];
  themePreference = UserPreferenceType.Theme;
  userThemePreference: ThemeOption = null;
  theme: string;
  prefersDarkScheme: any;
  theme$: BehaviorSubject<string>;

  onDarkLogo = 'assets/Jumptech-Digital-Logo-V2-512px.png';
  onLightLogo = 'assets/Jumptech-Digital-Logo-V1-512px.png';
  brandLogo$: BehaviorSubject<string>;

  constructor(private userPrefs: UserPreferencesService, private transloco: TranslocoService) {
    this.translateLabels();
  }

  private translateLabels() {
    this.themeOptions = this.themeOptions.map(x => {
      return { ...x, label: this.transloco.translate(x.label) };
    });
  }

  public init() {
    this.prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
    const currentTheme = this.userPrefs.getUserPreference(this.themePreference);
    this.userThemePreference = this.themeOptions.find(x => x.value === currentTheme);
    if (!this.userThemePreference) {
      this.userThemePreference = this.themeOptions[0];
    }

    let initialTheme = null;
    if (this.userThemePreference.value === 'dark') {
      document.documentElement.classList.toggle(ThemeClass.darkTheme);
      initialTheme = 'dark';
    } else if (this.userThemePreference.value === 'light') {
      document.documentElement.classList.toggle(ThemeClass.lightTheme);
      initialTheme = 'light';
    } else if (this.userThemePreference.value === 'system') {
      document.documentElement.classList.remove(ThemeClass.lightTheme, ThemeClass.darkTheme);
      initialTheme = this.prefersDarkScheme.matches ? 'dark' : 'light';
    }

    this.initThemeSubject(initialTheme);
    this.notifyTheme();
  }

  private initThemeSubject(initialTheme) {
    this.theme$ = new BehaviorSubject<string>(initialTheme);

    const logo = initialTheme === 'dark' ? this.onDarkLogo : this.onLightLogo;
    this.brandLogo$ = new BehaviorSubject<string>(logo);

    this.prefersDarkScheme.addEventListener('change', event => {
      const logo = event.matches ? this.onDarkLogo : this.onLightLogo;
      this.brandLogo$.next(logo);
      this.theme$.next(event.matches ? 'dark' : 'light');
    });
  }

  public getAvailableThemes() {
    return this.themeOptions;
  }

  public getActiveTheme() {
    const currentTheme = this.userPrefs.getUserPreference(UserPreferenceType.Theme);
    this.userThemePreference = this.themeOptions.find(x => x.value == currentTheme);
    if (!this.userThemePreference) {
      this.userThemePreference = this.themeOptions[0];
    }
    return this.userThemePreference;
  }

  private notifyTheme() {
    if (this.userThemePreference.value === 'system') {
      const logo = this.prefersDarkScheme.matches ? this.onDarkLogo : this.onLightLogo;
      this.brandLogo$.next(logo);
      this.theme$.next(this.prefersDarkScheme.matches ? 'dark' : 'light');
    } else {
      const logo = this.userThemePreference.value === 'dark' ? this.onDarkLogo : this.onLightLogo;
      this.brandLogo$.next(logo);
      this.theme$.next(this.userThemePreference.value);
    }
  }

  public setTheme(theme) {
    this.userThemePreference = theme;
    document.documentElement.classList.remove(ThemeClass.lightTheme, ThemeClass.darkTheme, ThemeClass.systemTheme);
    document.documentElement.classList.add(`${this.userThemePreference.value}-theme`);

    this.userPrefs.setUserPreference(UserPreferenceType.Theme, this.userThemePreference.value);
    this.notifyTheme();
  }
}

enum ThemeClass {
  lightTheme = 'light-theme',
  darkTheme = 'dark-theme',
  systemTheme = 'system-theme'
}

export interface ThemeOption {
  value: string;
  label: string;
  icon: string;
}
