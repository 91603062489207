<div class="new-task-wrapper">
  <div [@openClose]="isCreating ? 'open' : 'closed'" class="card new-task">
    <div class="card-body">
      <div>{{ 'common.newTask' | transloco }}</div>
      <div [formGroup]="form">
        <label for="task" class="mt-3">{{ 'project.newProject.fields.taskTitle' | transloco }}</label>
        <input
          class="form-control"
          type="text"
          id="task"
          formControlName="task"
          placeholder="{{ 'project.newProject.placeholders.taskTitle' | transloco }}"
        />
        <div *ngIf="isInvalid('task')" class="form-error">
          {{ 'project.newProject.validation.taskTitle' | transloco }}
        </div>
        <label for="description" class="mt-3">{{ 'project.newProject.fields.description' | transloco }}</label>
        <textarea
          class="form-control"
          id="description"
          formControlName="description"
          placeholder="{{ 'project.newProject.placeholders.description' | transloco }}"
        >
        </textarea>
        <div *ngIf="isInvalid('description')" class="form-error">
          {{ 'project.newProject.validation.description' | transloco }}
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="btn-group">
        <button class="btn btn-sm btn-secondary" (click)="toggleIsCreating()">
          {{ 'common.cancel' | transloco }}
        </button>
        <button class="btn btn-sm btn-primary" [attr.data-qa]="'saveButton'" (click)="addTask()">
          {{ 'common.save' | transloco }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!isCreating" class="task-add-button">
    <i class="material-icons" (click)="toggleIsCreating()">playlist_add</i>
  </div>
</div>
