import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DelegationShare, DelegationShareSubTenant } from '../../admin/user-management/domain/types';
import { ApiService } from '../../core/api.service';
import { SearchResult } from '../search-result';
import { checkIfExpression } from '../../core/utils/filter';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { DelegationService } from '../../core/delegate/delegation.service';

export interface ProjectDelegation {
  projectId: string;
  tenant: string; // The tenant being delegated to
  subTenants?: DelegationShareSubTenant[];
}

@Component({
  selector: 'app-delegate-project-button',
  template: `
    <div *ngIf="this.showDelegationAction()">
      <button
        class="icon-button"
        type="button"
        [disabled]="submitInProgress"
        (click)="this.delegateProject()"
        (mouseenter)="hovering = true"
        (mouseleave)="hovering = false"
      >
        <i class="material-icons u-flip-lr">reply</i>
      </button>
      <div class="delegate-button-hover" *ngIf="hovering">
        <div>{{ 'Buttons.assignToInstaller.text' | transloco }}</div>
      </div>
    </div>
  `,
  styleUrls: ['delegate-project-button.component.scss']
})
export class DelegateProjectButtonComponent {
  private _project: SearchResult;
  private _userTenant: string;
  private _delegates: DelegationShare[];
  hovering = false;

  @Output()
  delegationSuccess: EventEmitter<string> = new EventEmitter(true);

  get project(): SearchResult {
    return this._project;
  }

  @Input() submitInProgress = false;

  @Input() set project(project: SearchResult) {
    this._project = project;
  }

  get userTenant(): string {
    return this._userTenant;
  }

  @Input() set userTenant(userTenant: string) {
    this._userTenant = userTenant;
  }

  get delegates(): DelegationShare[] {
    return this._delegates.filter(d => {
      return checkIfExpression(d.showIf, this.project);
    });
  }

  @Input() set delegates(delegates: DelegationShare[]) {
    this._delegates = delegates;
    this._delegates = this.delegates.filter(o => o.tenant !== 'ALL');
  }

  get delegationEnabled() {
    return this._delegationEnabled;
  }

  set delegationEnabled(value: boolean) {
    this._delegationEnabled = value;
  }

  private _delegationEnabled = false;

  constructor(
    private apiService: ApiService,
    private featureAccessService: AccessService,
    private delegationService: DelegationService
  ) {}

  async ngOnInit() {
    await this.initialise();
  }

  private async initialise() {
    this.delegationEnabled = this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.DelegationV2);
  }

  showDelegationAction(): boolean {
    return this.delegationEnabled && this.delegates.length > 0 && this.userTenant === this.project.tenant;
  }

  async delegateProject() {
    this.submitInProgress = true;
    this.hovering = false;
    const success = await this.delegationService.delegateProject(this.project.id, this.project.type);
    if (success) {
      this.delegationSuccess.emit();
    }
    this.submitInProgress = false;
  }
}
