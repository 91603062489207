<div class="card-wrapper">
  <div class="card">
    <div class="card-body">
      <p class="card-text" [innerHTML]="card.description" [attr.data-qa]="'description'"></p>
      <crds-image-asset [images]="images" [key]="'address_location'"></crds-image-asset>
    </div>
  </div>
</div>
<div class="form-wrapper">
  <form *ngIf="form" [formGroup]="form">
    <crds-questions [form]="form" [card]="card" [i18ns]="i18ns"></crds-questions>
  </form>
</div>
