/**
 * Tell whether a data item represents base64 encoded data.
 * The data item can be either a string or an object; a string represents
 * a base64 encoded image, whereas an object represents an attachment.
 * @param data
 */
import { FileResource, makeFileResource } from './file-resource';
import { decode } from 'base64-arraybuffer';

export class Base64EncodedData {
  private readonly _mimeType: string;
  private readonly _encodedData: string;
  private readonly _extension: string | undefined;

  constructor(mimeType: string, encodedData: string) {
    this._mimeType = mimeType;
    this._encodedData = encodedData;
    const parts = mimeType.split('/');
    this._extension = parts.pop();
  }

  get mimeType(): string {
    return this._mimeType;
  }

  get encodedData(): string {
    return this._encodedData;
  }

  get extension(): string | undefined {
    return this._extension;
  }

  get decodedData(): ArrayBuffer {
    return decode(this._encodedData);
  }
}

/**
 * Validate the source and return the relevant Base64EncodedData object.
 * @param source
 */
export const makeBase64EncodedData = (source: string | null): Base64EncodedData | null => {
  if (source === null) {
    return null;
  }

  const matches = source.match(/^data:([^/]+\/[^;]+);base64,(.+)$/);
  if (!matches) {
    console.warn(source, 'is not a valid base64 encoded data source');
    return null;
  }

  return new Base64EncodedData(matches[1], matches[2]);
};

export const extractBase64EncodedData = (value: string | null): string | null => {
  if (!value) {
    return null;
  }

  // This regex is a bit too loose, yet safe.
  const matches = value.match(/^data:\w+\/\w+;base64,.+$/);
  if (matches === null) {
    return null;
  }

  return matches[0];
};

/**
 * Given a base64 encoded data string, representing a file,
 * extract the file extension.
 * @param resource
 */
export const getFileResourceExtension = (resource: FileResource | null): string | null => {
  if (resource === null) {
    return null;
  }

  const base64Data = extractBase64EncodedData(resource.getValue());
  if (base64Data === null) {
    return null;
  }
  const parts = base64Data.split(';base64,');
  return parts[0].split(':')[1].split('/').pop() ?? null;
};

/**
 * Replace possible base64 encoded data (images or attachments) in a form
 * with the relevant file path.
 * @param source
 */
export const mapBase64EncodedDataStringsToPaths = (source: any) => {
  for (const [key, value] of Object.entries(source)) {
    if (value instanceof Array) {
      source[key] = value.map((item: any, index: number): any => {
        const fileResource = makeFileResource(item);
        if (fileResource === null) {
          // This is not a file resource
          return item;
        }

        const data = makeBase64EncodedData(fileResource.getValue());
        if (data === null) {
          // This is not a base64 encoded file
          return item;
        }

        return `unsigned:${key}${index}_${Date.now()}.${data.extension}`;
      });
    }
  }

  return source;
};
