import { Injectable } from '@angular/core';
import { DocumentPackRepository } from '../document-pack.repository';
import { DocumentStateTransition, IResponseDm, IResponseVm } from '../document-pack.model';

@Injectable()
export class DocumentPackResponsePresenter {
  constructor(private repository: DocumentPackRepository) {}

  load(callback) {
    this.repository.loadResponseModal((dataDm: IResponseDm) => {
      const viewModel: IResponseVm = {
        id: dataDm.id,
        title: `${dataDm.documentName} -`,
        stateLabel: dataDm.stateLabel,
        name: `${dataDm.documentName} ${dataDm.responseLabel}`,
        sentDocumentName: dataDm.mainDocumentFileName,
        message: dataDm.message,
        closeLabel: dataDm.closeLabel,
        uploadLabel: dataDm.uploadLabel,
        completeHint: dataDm.completeHint,
        completeLabel: dataDm.completeLabel,
        approvedLabel: `${dataDm.documentName} ${dataDm.approvedLabel}`,
        rejectedLabel: `${dataDm.documentName} ${dataDm.rejectedLabel}`,
        reviseLabel: dataDm.reviseLabel,
        actionType: dataDm.actionType,
        accept: dataDm.acceptedFileTypes.map(a => a.type).join(','),
        isUpdating: dataDm.isUploading || dataDm.isRevising || dataDm.isCompleting,
        isRevising: dataDm.isRevising,
        isCompleting: dataDm.isCompleting,
        qaCloseModal: `dmCloseButton--${dataDm.documentName}`,
        qaComplete: `dmCompleteButton--${dataDm.documentName}`,
        qaRevise: `dmReviseButton--${dataDm.documentName}`,
        qaUpload: `dmUploadButton--${dataDm.documentName}`
      };
      callback(viewModel);
    });
  }

  uploadEvidence(event, document, outputEvent) {
    document.stateTransition = DocumentStateTransition.COMPLETED;
    this.repository.uploadDocument(event, document, true, outputEvent).then();
  }

  revise(document) {
    this.repository.reviseDocument(document).then();
  }

  complete(document) {
    this.repository.completeDocument(document).then();
  }

  closeModal(reason) {
    this.repository.closeModal(reason);
  }
}
